/* eslint-disable react/jsx-key */
/* eslint-disable no-param-reassign */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Dialog } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { API } from '../../../api';
import { toast } from 'react-toastify';
import { getUserDetails } from '../../../utils/orgName';
import { userHobbiesUpdate } from '../../../redux/slices/user-profile/userProfieSlice';
import useProfileDetails from '../../../hooks/useProfileDetails';
import { popupActions } from '../../../redux/slices/onboardingPopupsSlice';
import HobbiesForm from './HobbiesForm';
import CommonModalHeader from '../../../components/DialogUi/CommonModalHeader';

interface HobbyAndInterest {
  _id: string;
  oid: string;
  value: string;
  type: string;
  approved: boolean;
  __v: number;
}

const HobbiesModal = () => {
  const open = useAppSelector((state) => state.onboardingPopups.data.hobbies);
  const hobbiesDisable = useAppSelector((state) => state.onboardingPopups.data.hobbiesDisable);
  const dispatch = useAppDispatch();

  const handleClose = () => {
    if(!hobbiesDisable){
      dispatch(popupActions.updatePopups({ hobbies: false, hobbiesDisable: false }));
    }
  };

  useEffect(() => {
    return () => {
      handleClose();
    };
  }, []);


  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
    >
      {/* Dialog Header */}
      <CommonModalHeader 
        title={`Hobbies`} 
        handleCloseModal={handleClose}
      />
      <HobbiesForm />
    </Dialog>
  );
};

export default HobbiesModal;
