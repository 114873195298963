import React from 'react';
import _ from 'lodash';
import {
  Box,
  Grid,
  Stack,
  Divider,
  IconButton,
  Typography,
} from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import GraduateIcon from '../../../assets/images/graduate.svg'
import BriefcaseIcon from '../../../assets/images/briefcase.svg'
import { EditIcon } from '../../../utils/Icons';

interface ICommonItem {
  company_name: string;
  roleType?: string[];
  title?: string;
  start_date?: string | null;
  end_date?: string | null;
  industry?: string;
  currentlyWorking?: boolean;
  university?: string;
  major?: string;
  minor?: string[] | null;
  graduation_date?: string;
  department?: string;
}

interface IWorkAndEduDesignProps {
  data?: ICommonItem[];
  itemType: 'work' | 'education';
  onEdit: (item: ICommonItem) => void;
  wherePrint?: string;
  remove?: any;
  loading?: boolean;
}

const WorkAndEduDesign: React.FC<IWorkAndEduDesignProps> = ({ data, itemType, onEdit, wherePrint, remove }) => {
  const logoArray: any[] = []; //"name: '', icon: "
  const gridSize = wherePrint === 'communityUserProfile' || 'chatProfile' ? '100%' : 'calc(100% - 44px)';
  /** Dot */
  const dotDesign = (each?:any) => (
    <CircleIcon
      color={each === 'work' ? 'primary' : 'secondary'}
      sx={{ width: '4px', height: '4px', mx: '4px' }}
    />
  );

  /** Company and university Logo print */
  const renderLogoIcon = (itemType: string, companyName?: string, universityName?: string) => {
    const defaultIcon = itemType === 'work' ? BriefcaseIcon : GraduateIcon;
    const specificLogo = logoArray.find(
      (logo) => (itemType === 'work' && logo.name === companyName) || (itemType === 'education' && logo.name === universityName)
    );
  
    return (
      <Box
        width={'49px'}
        height={'49px'}
        display={"flex"}
        minWidth={"49px"}
        alignItems={"center"}
        justifyContent={"center"}
        border={'1px solid #EFF0F4'}
      >
        <img
          src={ specificLogo ? specificLogo.icon : defaultIcon }
          alt={`${itemType === 'work' ? 'Company' : 'University'} logo`}
        />

      </Box>
    );
  };

  /** Added dot between two item */
  const renderItemsWithDots = (items?:any, each?:any) => {
    return items.map((item?:any, index?:any) => (
      <React.Fragment key={index}>
        {index > 0 && dotDesign(each)} {item}
      </React.Fragment>
    ));
  };

  /**
    For work : -
    image | Work title
          | Company name
          | Year
          | Industry 
          | Responsibilities . Responsibilities . Responsibilities ....
    ------------------------------------------------------------------------------------
    For Edu : -
    Image | University name
          | Major
          | Year
          | Department
          | Minor . Minor . Minor .......
   */

  const renderDetails = () => {
    return (
      <Box>
        {_.isEmpty(data) ? (
          <Typography>No {itemType === 'work' ? 'work' : 'education'} experience</Typography>
        ) : (
          data?.map((each: ICommonItem, index: number) => (
            <React.Fragment key={index}>
              <Stack
                direction={wherePrint === 'onBoarding' ? 'column' : 'row'}
                justifyContent="space-between"
                alignItems="flex-start"
                spacing="8px"
              >
                <Box width={gridSize}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Stack direction="row" spacing="10px">
                        {/* Logo section */}
                        {renderLogoIcon(itemType, each?.company_name, each?.university)}
                        {/* Right side */}
                        <Box width="calc(100% - 59px)">
                          {/* Work - Title & Edu - University */}
                          <Typography variant="h5">
                            {itemType === 'work' ? each?.title : each?.university}
                          </Typography>

                          {/* Work - Company Name & Edu - Mejor */}
                          <Typography variant="body1" color="secondary" mt={'4px'}>
                           { itemType === 'work' ? each?.company_name : each?.major }
                          </Typography>

                          {/* Year */}
                          <Typography color="secondary" mt={'4px'}>
                            {itemType === 'work'
                              ? `${each?.start_date} - ${each?.currentlyWorking ? 'Present' : each?.end_date}`
                              : each?.graduation_date}
                          </Typography>

                          {/* Work - Industry & Edu - Department */}
                          <Typography variant="body1" color={'primary'} mt={'4px'}>
                            { itemType === 'work' ? each?.industry : each?.department }
                          </Typography>

                          {/* Work - Responsibilities & Edu - Minor */}
                          <Typography variant="body1" color={'primary'} mt={'4px'}>
                            {itemType === 'work' && Array.isArray(each?.roleType) && each?.roleType.length > 0
                              ? renderItemsWithDots(each.roleType, each)
                              : Array.isArray(each?.minor) && each?.minor.length > 0
                              ? renderItemsWithDots(each.minor, each)
                              : null}
                          </Typography>
                        </Box>
                      </Stack>
                    </Grid>
                  </Grid>
                </Box>

                {/* Edit icon */}
                {wherePrint === 'profile' && (
                  <IconButton onClick={() => { onEdit(each) }}>
                    <EditIcon />
                  </IconButton>
                )}

                {/* Remove btn for onboarding */}
                {wherePrint === 'onBoarding' && (
                  <Box display={'flex'} justifyContent={'end'} width={'100%'}>
                    <Typography
                      color='error'
                      fontSize='10px'
                      fontWeight={'600'}
                      className='cursor-pointer'
                      onClick={() => { remove(true); onEdit(each); }}
                    >
                      Remove
                    </Typography>
                  </Box>
                )}
              </Stack>

              {index !== data.length - 1 && (
                <Divider sx={{ m: '15px 0', border: '1px dashed #c9cbd2 !important' }} />
              )}
            </React.Fragment>
          ))
        )}
      </Box>
    );
  };

  return <Box>{renderDetails()}</Box>;
};

export default WorkAndEduDesign;
