import React from "react";
import { 
    Box, 
    Grid, 
    Paper, 
    Stack, 
    Table, 
    TableRow, 
    TableBody, 
    TableCell, 
    TableHead, 
    Typography,
    TableContainer,  
} from "@mui/material";
import MySkeleton from "../components/Skeleton";

const GoalSettingsLoader = () => {
  return (
    <Box
      height={'100%'}
      bgcolor={'#FFFFFF'}
      overflow={'hidden auto'}
      borderRadius={'8px'}
      border={'1px solid #EFF0F4'}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center" p={2}>
        <MySkeleton width={150} />
      </Stack>
      <MySkeleton height={2} />

      <Box p={2}>
        <Grid container rowSpacing={2} columnSpacing={1}>
          <Grid item lg={4} md={6} sm={6}>
            <MySkeleton width={100} className="mb-10"/>
            <MySkeleton variant="rounded" height={42} />
          </Grid>
          <Grid item lg={8} md={6} sm={6}>
            <MySkeleton width={100} className="mb-10"/>
            <MySkeleton variant="rounded" height={42} />
          </Grid>
        </Grid>
      </Box>
      <MySkeleton height={2} />
      <Box>
        <Stack direction="row" justifyContent="space-between" alignItems="center" p={2}>
          <MySkeleton width={100} />
          <MySkeleton variant="rounded" height={20} width={20} />
        </Stack>
        <MySkeleton height={2} />
        <Paper elevation={0} sx={{ width: '100%', overflow: 'hidden' }}>
          <TableContainer sx={{ maxHeight: 400 }} className='b-none'>
            <Table width="100%">
              <TableHead>
                <TableRow>
                  {[...Array(4).keys()].map(index => (
                    <TableCell key={index}>
                      <MySkeleton width={70} />
                    </TableCell>
                  ))}
                  <TableCell>
                    <Typography className='last-cell-table-Loader'>
                        <MySkeleton width={70} />
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {[...Array(4).keys()].map((rowIndex) => (
                  <TableRow key={rowIndex}>
                    {[...Array(4).keys()].map((cellIndex) => (
                        <TableCell key={cellIndex}>
                            <MySkeleton width={cellIndex === 0 || cellIndex === 2 ? 10 : 180} />
                        </TableCell>
                    ))}
                    <TableCell>
                      <Typography className='last-cell-table-Loader'>
                        <MySkeleton variant='rounded' height={30} width={10} />
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        <Stack
          spacing={2}
          direction="row"
          textAlign="right"
          alignItems="center"
          justifyContent="flex-end"
        >
          <MySkeleton width={100} />
          <Box p="16px">
            <MySkeleton variant="rounded" className='w-150px h-50px'/>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
}

export default GoalSettingsLoader;
