/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Grid } from '@mui/material';
// import _ from "lodash";
import EngagementMetrics from './program tabs/EngagementMetrics';
import { getGoalProgressShowOrNot } from '../../../components/common';

const ProgramCards = () => {
  const showGoalProgress = getGoalProgressShowOrNot(false);

  return (
    <Grid container spacing={2}>
      <EngagementMetrics 
        showGoalProgress={showGoalProgress}
      />
    </Grid>
  );
};

export default ProgramCards;
