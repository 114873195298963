import React, { useState } from 'react';
import { Box, Stack, Divider, IconButton, Typography } from '@mui/material';
import MyChip from './MyChip';
import { dummyTopics } from './common';
import { EditIcon } from '../../../utils/Icons';
import MySkeleton from '../../../components/Skeleton';
import IcanHelpYouWithModal from './IcanHelpYouWithModal';

interface Props {
  heading: string;
  editing?: boolean;
  divider?: boolean;
  loading?: boolean;
  param?: string;
  placeName?: string;
  chipType?: 'fill' | 'fill-blue' | 'outline';
  data?: string[]; // Change the type to string[]
}

const TextAndChipSection: React.FC<Props> = ({
  heading,
  editing = false,
  divider = false,
  loading = false,
  chipType = 'fill',
  data = [],
  param,
  placeName
}) => {
  const chipData = data.length > 0 ? data : [];
  const [openPopup, setOpenPopup] = useState(false);
  const handleOpenPopup = (flag: boolean) => setOpenPopup(flag);

  const getChipColor = () => {
    if (chipType === 'outline') return '#68717A';
    return undefined;
  };

  const getChipBg = () => {
    if (chipType === 'outline') return '#FFFFFF';
    if (chipType === 'fill-blue') return '#0071A9';
    return undefined;
  };

  const getChipBorder = () => {
    if (chipType === 'outline') return '1px solid #CED4DA';
    if (chipType === 'fill-blue') return '1px solid #0071A9';
    return undefined;
  };

  const handleEditClick = () => {
    if (param === 'IcanHelpYouWith') {
      setOpenPopup(true);
    }
  };

  return (
    <>
      <Box pb={divider ? 0 : 2}>
        <Box px={divider ? 2 : 0} display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h5" pt={placeName === 'publicProfile' && param === 'IcanHelpYouWith' ? 0 : 2} pb={2}>
            {loading ? <MySkeleton variant="text" height={16} width={150} /> : heading}
          </Typography>
          {/* Edit icon */}
          {editing && (
            <IconButton size="small">
              <EditIcon onClick={handleEditClick} />
            </IconButton>
          )}
        </Box>
        {/* Divider for profile page */}
        {divider && <Divider />}

        <Stack p={divider ? 2 : 0} direction="row" useFlexGap flexWrap="wrap" spacing={1}>
          {loading ? (
            Array.from({ length: 4 }).map((_, index) => <MyChip key={index} loading />)
          ) : chipData.length > 0 ? (
            chipData.map((topic, index) => (
              <MyChip
                key={index}
                label={topic}
                color={getChipColor()}
                border={getChipBorder()}
                backgroundColor={getChipBg()}
              />
            ))
          ) : (
            <Typography>Nothing added yet</Typography>
          )}
        </Stack>
      </Box>

      {/* I can help you with Modal */}
      <IcanHelpYouWithModal openPopup={openPopup} handleOpenPopup={handleOpenPopup} />
    </>
  );
};

export default TextAndChipSection;
