import React, { useState } from 'react';
import {
  Box,
  Button,
  Typography
} from '@mui/material';
import { LiveChatStyle } from './LiveChatStyle';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from '../../../../redux/hooks';
import ChatAvatarComponent from '../chat/ChatAvatarComponent';
import circleicon from '../../../../assets/images/circleicon.svg';

const PromptChat: React.FC = (props) => {
  const location = useLocation();
  const conversationData = location.state.conversationData;
  const userProfileData = useAppSelector((state) => state.userProfile.data);

  const text = useAppSelector((state) => state.aiChatText);
  const [rightTopPop, setRightTopPop] = useState(null);
  const open = Boolean(rightTopPop);
  const classes = LiveChatStyle();
  const chatData = [
    {
      type: 'Dosen',
      text: `Hi, John! Let’s create a great resume together.\n\nFirst, I have a few questions to get us started.\n\nWhat is the seniority level of the type of role that you’re targeting with this resume?`,
      suggestions: ['Associate / Junior Level', 'Mid level', 'Senior Level', 'Management level', 'Executive level']
    },

    {
      type: 'Dosen',
      text: 'Please choose any one'
    }
  ];

  return (
    <Box className={classes.LiveChatBody}>
      {chatData.map((message: any, index: any) => (
        <Box key={index} className={message.type === 'user' ? classes.msgBox : classes.msgBox}>
          <ChatAvatarComponent
            variant='circle'
            width="34px"
            height="34px"
            image={ userProfileData ? userProfileData?.personal_details?.headshot : circleicon } // {message.type === 'Dosen' ? circleicon : userProfileData?.personal_details?.headshot}
            firstLetter={'Dosen'}
            border={'1px solid #000000'}
          />
          <Box>
            <Box display="flex" gap="8px">
              <Typography variant="h5">{message.type === 'Dosen' ? 'Dosen' : 'User'}</Typography>
              <Typography>
                {new Date().toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}
              </Typography>
            </Box>
            <Box>
              <Typography sx={{ whiteSpace: 'pre-line' }}>{message.text}</Typography>
            </Box>
            {message.suggestions && message.suggestions.length > 0 && (
              <Box display="flex" gap="8px" flexWrap="wrap" flexDirection={'column'} id={index}>
                {message.suggestions.map((suggestion: any, index: any) => (
                  <Button
                    key={index}
                    className={classes.btn}
                    sx={{
                      width: '300px',
                      height: '40px',
                      backgroundColor: '#EFF0F4',
                      justifyContent: 'left',
                      '&:hover': { backgroundColor: '#0071A9', color: 'white' }
                    }}
                  >
                    {suggestion}
                  </Button>
                ))}
              </Box>
            )}
            {message?.suggestions?.map((each: any, index: any) => {
              <Box display="flex" gap="8px" flexWrap="wrap" flexDirection={'column'} id={index}>
                <Button
                  className={classes.btn}
                  sx={{
                    width: '300px',
                    height: '40px',
                    backgroundColor: '#EFF0F4',
                    justifyContent: 'left',
                    '&:hover': { backgroundColor: '#0071A9', color: 'white' }
                  }}
                >
                  {each}
                </Button>
              </Box>;
            })}
          </Box>
        </Box>
      ))}
      {text && (
        <Box className={classes.msgBox}>
          <Box>
            {userProfileData &&
              <ChatAvatarComponent
                variant='circle'
                width="34px"
                height="34px"
                image={userProfileData?.personal_details?.headshot }
                firstLetter={userProfileData?.personal_details?.lastName}
              />
            }
          </Box>
          <Box>
            <Box display="flex" gap="8px">
              <Typography variant="h5">Member</Typography>
              <Typography>8:46 AM</Typography>
            </Box>
            <Box>
              <Typography>{text}</Typography>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default PromptChat;
