/* eslint-disable react-hooks/exhaustive-deps */
/**
 * Hook useAllGroupTasks
 */
import { useQuery } from '@tanstack/react-query';
import { API } from '../api';
import { queryKeys } from './queryKeys';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { useEffect } from 'react';
import _ from 'lodash';
import { taskActions } from '../redux/slices/goals-tasks/getTasksSlice';
/**
 * @function @name useAllGroupTasks
 * @returns object
 */
const useAllGroupTasks = (grpId: string, userId: string) => {
  const goalTasks = useAppSelector((state) => state.goalTasks);
  const selectedGroup = useAppSelector((state) => state.selectedGroup.data);
  const dispatch = useAppDispatch();
  /**
   * useQuery hook
   */

  // if(selectedGroup.role === 'mentor' && userId === ''){
  //   return {data:undefined}
  // }
  const data = useQuery({
    // queryKey => type string[]  contains unique keys (must have one static unique key from ./queryKeys file)
    queryKey: [queryKeys.GET_ALL_GROUP_TASKS, grpId, userId],
    // queryFunction => api call
    queryFn: () => {
      return API.getAllTasks({ grpId, userId });
    },
    staleTime: 10 * 10 * 1000
    // enabled, if true (api will trigger directly) else (api handled manually by refetch)
  });

  useEffect(() => {
    if (data.isSuccess) {
      dispatch(taskActions.updateData(data.data.data));
    }
  }, [data.isSuccess]);

  return data;

  // useEffect(() => {
  //   if (goalTasks.groupId && goalTasks.userId) {
  //     // refetch();
  //   }
  // }, [goalTasks.userId, goalTasks.groupId]);

  // /**
  //  * returns object with required params
  //  */
  // return {
  //   data: _.get(data?.data, 'data', []),
  //   isLoading,
  //   refetch,
  //   error,
  //   isFetching,
  //   queryKeys: [queryKeys.GET_ALL_GROUP_TASKS, goalTasks.groupId, goalTasks.userId]
  // };
};

export default useAllGroupTasks;
