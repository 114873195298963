import { Box, Typography } from '@mui/material';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';

const ChatCard = ({ title, describtion, bgColour, textColour, handlePromptTreeClick }: any) => {
  const textColorHere = textColour ? textColour : '#FFFFFF';

  return (
    <Box
      p={1.2}
      width={"100%"}
      borderRadius={'14px'}
      bgcolor={bgColour}
      minHeight={'110px'}
      className='cursor-pointer'
      display={'flex'}
      flexDirection={'column'}
      onClick={handlePromptTreeClick}
      justifyContent={'space-between'}
    >
      <Box>
        <Typography variant="h3" color={textColorHere} mb={1.2}>
          {title}
        </Typography>
        <Typography fontSize={'16px'} color={textColorHere} mb={2} sx={{opacity: '.7'}}>
          {describtion}
        </Typography>
      </Box>
      <Box textAlign={'right'}>
        <AutoFixHighIcon sx={{color:'#FFFFFF'}} />
      </Box>
    </Box>
  );
};

export default ChatCard;
