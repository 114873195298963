import React, { useState, useRef } from 'react';
import { Box } from '@mui/material';
import { PlayIcon } from '../../../utils/Icons';
// import useResponsiveScreenSwitch from '../../../utils/useResponsiveScreenSwitch';

interface VideoPlayerProps {
    src?: string; // Explicitly specify the type as string
    placeName?: string;
    width?: string;
    height?: string;
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({ src, placeName, width, height }) => {
    /** For responsive */
    // const { forMobile } = useResponsiveScreenSwitch(); // Use the hook

    /** Video play icon */
    const videoRef = useRef<HTMLVideoElement | null>(null);
    const [showControls, setShowControls] = useState(false);

    const videoId = `video-${src?.replace(/[^a-zA-Z0-9]/g, '_')}`; // Replace non-alphanumeric characters with underscores

    const handlePlay = () => {
        const video = videoRef.current;
        if (video) {
        video.play();
        const playIcon = document.querySelector(`#${videoId}-playIcon`) as HTMLElement | null;
        if (playIcon) {
            playIcon.style.display = 'none';
            setShowControls(true);
        }
        }
    };

    const handlePause = () => {
        const playIcon = document.querySelector(`#${videoId}-playIcon`) as HTMLElement | null;
        if (playIcon) {
        playIcon.style.display = 'block'; // Show the play icon when video is paused
        setShowControls(false);
        }
    };

    const handleEnded = () => {
        const playIcon = document.querySelector(`#${videoId}-playIcon`) as HTMLElement | null;
        if (playIcon) {
        playIcon.style.display = 'block'; // Show the play icon when video has ended
        setShowControls(false);
        }
    };

    return (
        <Box
            borderRadius={'8px'}
            position={'relative'}
            width={width ? width : '100%' }
            height={height ? height : '100%' }
            sx={{
                cursor: 'pointer',
                aspectRatio: 1.81
            }}
        >
            <video
                src={src}
                key={src}
                controls={showControls}
                width={'100%'}
                height={'100%'}
                style={{
                    objectFit: 'cover',
                    borderRadius: '8px',
                }}
                ref={videoRef}
                onPlay={handlePlay}
                onPause={handlePause}
                onEnded={handleEnded}
                id={videoId}
            />
            <Box
                top={'50%'}
                left={'50%'}
                zIndex={1}
                position={'absolute'}
                sx={{transform: 'translate(-50%, -50%)',}}
                id={`${videoId}-playIcon`}
                onClick={handlePlay}
            >
                <PlayIcon size={52} />
            </Box>
        </Box>
    );
};

export default VideoPlayer;
