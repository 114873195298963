import { Box, Typography, Divider } from '@mui/material';
import AreaLineChart from './AreaLineChart';

interface FrequencyCommonProps {
  heading?: string;
  value?: string;
  respondents?: string;
  minHeight?: string;
}

const CommonFrequency: React.FC<FrequencyCommonProps> = ({ heading, value, respondents,minHeight }) => {

  const chartHeight = minHeight === '211px' ? '66px' : '86px';
  const chartData = [20, 40, 35, 60, 49, 80, 130, 91, 125];
  const chartCategories = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'];

  return (
    <Box className="CardBox">
      <Box p={2}>
        <Typography variant='h6'>{heading}</Typography>
      </Box>
      <Divider />
      <Box p={2} minHeight={minHeight} display={'flex'} flexDirection={'column'} justifyContent={'flex-end'} gap={1}>
        <Box flexGrow={1} display={'flex'} flexDirection={'column'} justifyContent={'center'}>
          <Typography variant={'h1'} fontSize={'60px'} noWrap textAlign={'center'}>{value}</Typography>
          <Typography textAlign={'center'}>{respondents}</Typography>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="center">
          <AreaLineChart data={chartData} categories={chartCategories} height={chartHeight}/> 
        </Box>
      </Box>
    </Box>
  )
}

export default CommonFrequency;
