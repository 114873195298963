import React from 'react';
import * as ChartJS from 'chart.js';
import { Box } from '@mui/material';
import { Line } from 'react-chartjs-2';
import {
  Title,
  Filler,
  Legend,
  Tooltip,
  LineElement,
  LinearScale,
  PointElement,
  CategoryScale,
} from 'chart.js';

ChartJS.registry.add([CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend]);

interface AreaLineChartProps {
  data: number[];
  categories: string[];
  height?: string;
}

const AreaLineChart: React.FC<AreaLineChartProps> = ({ data, categories, height }) => {
  const setHeight = height;

  const chartData = {
    labels: categories,
    datasets: [
      {
        label: 'Valuation',
        data: data,
        fill: true,
        backgroundColor: 'rgba(121, 223, 193, 0.5)',
        borderColor: '#79DFC1',
        tension: 0.4,
        pointRadius: 0,
        pointHoverRadius: 0,
      },
    ],
  };

  const chartOptions = {
    devicePixelRatio: 2,
    maintainAspectRatio: false,
    responsive: true,

    scales: {
      x: {
        ticks: {
          display: false,
        },
        grid: {
          display: false,
        },
      },
      y: {
        display: false,
      },
    },

    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
      export: {
        resolution: 4,
      },
    },
  };

  return (
    <Box width="100%" height={setHeight} m={0} p={0}>
      <Line data={chartData} options={chartOptions} height={setHeight}/>
    </Box>
  );
};

export default AreaLineChart;
