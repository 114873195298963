import React, { FC, ReactNode } from 'react';
import { Tooltip } from '@mui/material';
import { LoadingButton, LoadingButtonProps } from '@mui/lab';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

interface TooltipButtonProps extends LoadingButtonProps {
  tooltipTitle?: string;
  disabled: boolean;
  loading: boolean;
  children: ReactNode;
}

const TooltipButton: FC<TooltipButtonProps> = ({
  tooltipTitle = '',
  disabled,
  onClick,
  loading,
  children,
  ...props
}) => (
  <Tooltip title={tooltipTitle} placement='top'>
    <span>
      <LoadingButton
        disabled={disabled}
        onClick={onClick}
        loading={loading}
        {...props}
      >
        {children} <InfoOutlinedIcon sx={{ width: '16px', marginLeft: '2px' }} />
      </LoadingButton>
    </span>
  </Tooltip>
);

export default TooltipButton;
