/* eslint-disable no-case-declarations */
import React, { ChangeEvent, SyntheticEvent, useEffect, useState } from 'react';
import {
  Box,
  Radio,
  Grid,
  Button,
  Divider,
  Typography,
  RadioGroup,
  FormControlLabel,
  Autocomplete,
  TextField,
  FormControl
} from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import GradeIcon from '@mui/icons-material/Grade';
import { API } from '../../../../api';
import _ from 'lodash';
import AppLoader from '../../../../components/AppLoader';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { mentorAnswerActions } from '../../../../redux/slices/mentorTasksSlice';
import useProfileDetails from '../../../../hooks/useProfileDetails';
import useResponsiveScreenSwitch from '../../../../utils/useResponsiveScreenSwitch';
export const GetMenteeOptions = ({
  type,
  ansOptions,
  answerGiven,
  maxMenteeAns,
  minMenteeAns,
  setMultipleAnswers,
  multipleAnswers,
  setInputValue,
  inputValue,
  setAnswers,
  answers,
  handleClick,
  taskId,
  takeAnswers,
  asmtAnswers,
  levelId,
  index
}: any) => {
  let disablePointers;
  // console.log({ taskId, levelId });
  const [industryAnswers, setIndustryAnswers] = useState<any>(answerGiven || []);
  const [roleAnswers, setRoleAnswers] = useState<any>(answerGiven || []);
  const [isInputFocused, setIsInputFocused] = useState<any>(false);
  const [checkIsSucess, setCheckSuccess] = useState<any>(answerGiven[0] || []);
  const dispatch = useAppDispatch();
  const { ftue } = useProfileDetails();
  /** For responsive */
  const { forMobile } = useResponsiveScreenSwitch(); // Use the hook

  useEffect(() => {
    if (type === 'List of industries') {
      setIndustryAnswers(answerGiven || []);
    } else if (type === 'List of roles') {
      setRoleAnswers(answerGiven || []);
    }
  }, [type, answerGiven]);

  const handleYesNoChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(mentorAnswerActions.updateAnswer({ index, ans: [event.target.value] }));
  };

  const data = useAppSelector((state) => state.mentorTasks.data.data);

  const handleMultiSelect = (event: SyntheticEvent<Element, Event>, value: string[]) => {
    dispatch(mentorAnswerActions.updateAnswer({ index, ans: value }));
  };

  /** Onboarding and profile page */
  const widthSet = ftue || forMobile ? "100%" : '50%';

  switch (type.toLowerCase()) {
    case '1-5 rating': {
      // console.log('dv');
      return (
        <Box>
          {_.map(ansOptions, (a) => {
            // const checkIsSuccess = _.parseInt(a) <= _.parseInt(_.get(checkIsSucess, [0], ''));
            // const checkIsAnswerGiven = _.parseInt(a) <= _.parseInt(_.get(answerGiven, [0], ''));
            const checkIsSuccess = Number(a) <= Number(data[index].ans[0]);
            return (
              <span
                key={index}
                // onClick={() => {
                //   setCheckSuccess([a]);
                //   takeAnswers(a, type, taskId, levelId) || setAnswers([a]);
                // }}
                onClick={() => dispatch(mentorAnswerActions.updateAnswer({ index, ans: [a] }))}

                // onClick={() => setAnswers([a])}
              >
                {' '}
                <GradeIcon color={checkIsSuccess ? 'success' : 'secondary'} />
              </span>
            );
          })}
        </Box>
      );
    }
    case 'list of industries':
    case 'list of roles':
      const sortedOptions = [...ansOptions].sort();
      return (
        <Box className="">
          <Autocomplete
            className="AutocompleteMenteetasksFieldSize"
            disableClearable
            multiple
            // disabled={disablePointers}
            // options={disablePointers ? [] : ansOptions.sort()}
            options={sortedOptions}
            getOptionLabel={(option) => option}
            value={data[index].ans}
            // onChange={(e: any, value: any) => {
            //   // Use the correct setAnswers function based on the type
            //   if (type === 'List of industries') {
            //     setIndustryAnswers(value);
            //   } else if (type === 'List of roles') {
            //     setRoleAnswers(value);
            //   }
            //   // Call takeAnswers with the correct type
            //   takeAnswers(value, type, taskId, levelId) || setMultipleAnswers(value);
            // }}\
            onChange={handleMultiSelect}
            disableCloseOnSelect
            filterOptions={(options, state) =>
              options.filter((option) => option.toLowerCase().startsWith(state.inputValue.toLowerCase()))
            }
            getOptionDisabled={(option) =>
              answerGiven.length
                ? answerGiven.length >= maxMenteeAns && !answerGiven.includes(option)
                : answerGiven.length >= maxMenteeAns && !answerGiven.includes(option)
            }
            renderInput={(params) => (
              <TextField {...params} variant="outlined" placeholder={`Select Minimum ${minMenteeAns} options`} />
            )}
          />
        </Box>
      );
    case 'yes, no':
      return (
        <FormControl sx={{ width : widthSet }}>
          <RadioGroup
            sx={{ gap: '10px', pointerEvents: disablePointers ? 'none' : 'all' }}
            // onChange={(e) => setAnswers([e.target.value])}
            aria-labelledby="demo-radio-buttons-group-label1"
            value={answerGiven[0] ? answerGiven[0] : ''}
            name="radio-buttons-group"
            // onChange={(e) => takeAnswers(e.target.value, type, taskId, levelId) || setAnswers([e.target.value])}
            onChange={handleYesNoChange}
          >
            {_.map(ansOptions, (each) => (
              <Box className="industries-sec">
                <FormControlLabel
                  value={each}
                  // checked={answerGiven.length > 0 && answerGiven.includes(each)}
                  control={<Radio />}
                  label={each}
                />
              </Box>
            ))}
          </RadioGroup>
        </FormControl>
      );
    case "yes, no, i don't know":
      return (
        <FormControl sx={{ width : widthSet }}>
          <RadioGroup
            sx={{ gap: '10px', pointerEvents: disablePointers ? 'none' : 'all' }}
            // onChange={(e) => setAnswers([e.target.value])}
            aria-labelledby="demo-radio-buttons-group-label2"
            value={answerGiven[0] ? answerGiven[0] : ''}
            name="radio-buttons-group"
            // onChange={(e) => takeAnswers(e.target.value, type, taskId, levelId) || setAnswers([e.target.value])}
            onChange={handleYesNoChange}
          >
            {_.map(ansOptions, (each) => (
              <Box className="industries-sec">
                <FormControlLabel
                  value={each}
                  // checked={answerGiven.includes(each)}
                  control={<Radio />}
                  label={each}
                />
              </Box>
            ))}
          </RadioGroup>
        </FormControl>
      );
    case 'open input field':
      return (
        <FormControl fullWidth>
          <TextField
            fullWidth
            style={{ pointerEvents: disablePointers ? 'none' : 'all' }}
            // onChange={(e) => takeAnswers(e.target.value, type, taskId, levelId) || setInputValue(e.target.value)}
            onChange={handleYesNoChange}
            placeholder="Please input your answer..."
            value={isInputFocused ? inputValue : answerGiven}
            onFocus={() => setIsInputFocused(true)}
            onBlur={() => setIsInputFocused(false)}
          />
        </FormControl>
      );
    default:
      return null;
  }
};
