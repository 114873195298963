import React from 'react';
import { Box } from '@mui/material';
import MySkeleton from '../../components/Skeleton';
import ChatAvatarComponent from '../../pages/new-designs/tabs/chat/ChatAvatarComponent';

/** Chat left side loader */
export const ChatLeftSideHeaderLoader = () => {
    return (
        <Box px={2} pt={2}>
            <Box pb={2}>
                <MySkeleton width={150} className='mb-10'/>
                <MySkeleton variant='rounded' height={37} />
            </Box>
            <Box pb={2}>
                <MySkeleton width={70} />
            </Box>
            <Box display='flex' justifyContent='space-between' pb={2}>
                <Box display='flex' alignItems={'center'} gap={1}>
                    <MySkeleton variant='rounded' height={50} width={50} />
                    <Box>
                        <MySkeleton width={150} />
                        <MySkeleton width={70} />
                    </Box>
                </Box>
                <MySkeleton variant='circular' height={44} width={44} />
            </Box>
        </Box>
    )
}

/** Chat leftside header avatar */
export const ChatLeftSideHeaderAvatar = () => {
    return (
        <Box display='flex' justifyContent='space-between'>
            <Box display='flex' alignItems={'center'} gap={1}>
                <MySkeleton variant='rounded' height={50} width={50} />
                <Box>
                    <MySkeleton width={150} />
                    <MySkeleton width={70} />
                </Box>
            </Box>
            <MySkeleton variant='circular' height={44} width={44} />
        </Box>
    )
}

/** Chat channels loader design  */
export const ChannelLoader = ({ channelCount }: { channelCount: number }) => {
    return (
        <Box>
            <Box display='flex' alignItems="center" justifyContent="space-between" p={2}>
                <Box display="flex" gap="8px" alignItems="center">
                    <MySkeleton width={100} />
                </Box>
                <MySkeleton variant='rounded' height={10} width={15} />
            </Box>
            {Array.from({ length: channelCount }).map((_, index, array) => (
                <React.Fragment key={index}>
                    <Box display="flex" gap={2} p={2}>
                        <MySkeleton variant='rounded' height={48} width={48} />
                        <Box display="flex" flexDirection={'column'} width="calc(100% - 60px)">
                            <Box display="flex" gap={1}>
                                <MySkeleton width={195} />
                                <MySkeleton width={30} />
                            </Box>
                            <MySkeleton width={235} />
                        </Box>
                    </Box>
                    {index !== array.length - 1 && <MySkeleton height={2} />}
                </React.Fragment>
            ))}
        </Box>
    );
};

/** Chat right side loader  */
export const ChatMainHeaderLoader = () => {
    return (
        <>
            <Box p={2}>
                <Box display={'flex'} gap={2} alignItems="center" justifyContent={'space-between'}>
                    <Box display="flex" alignItems="center">
                        <Box gap={2} display="flex">
                            <MySkeleton variant='rounded' height={50} width={50} />
                            <Box>
                                <MySkeleton height={30} width={150} />
                                <MySkeleton width={70} />
                            </Box>
                        </Box>
                    </Box>
                    <Box display={'flex'} gap={1}>
                        <MySkeleton variant='circular' height={22} width={22} />
                        <MySkeleton variant='circular' height={22} width={22} />
                    </Box>
                </Box>
            </Box>
            <MySkeleton height={2}/>
        </>
    );
};

/** Chat msg content loader */
export const ChatMsgContentLoader = () => {
    return (
        <Box p={2}>
            <Box display={'flex'} justifyContent={'center'}>
                <MySkeleton variant='rounded' width={100} height={25} />
            </Box>
            {Array.from({ length: 3 }).map((_, index) => (
                <Box pt={2} display={'flex'} gap={'10px'} alignItems={'flex-start'} width={'100%'} key={index}>
                    <ChatAvatarComponent loading={true} width='34px' height='34px' />
                    <Box width={'100%'}>
                        <Box display={'flex'} gap={'8px'} mb={'8px'}>
                            <MySkeleton variant='rounded' width={79} height={19} />
                            <MySkeleton variant='rounded' width={38} height={14} />
                        </Box>
                        <MySkeleton variant='rounded' width={'100%'} height={38} />
                    </Box>
                </Box>
            ))}
        </Box>
    );
};

/** Chat editor loader */
export const ChatEditorLoader = () => {
    return (
        <Box p={2}>
            <MySkeleton variant='rounded' height={103}/>
        </Box>
    );
};
