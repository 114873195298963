import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from '../../../api';
import { getUserDetails } from '../../../utils/orgName';
import _ from 'lodash';

interface IInitialState {
  loading: boolean;
  error: boolean;
  data: any[];
  updatedData: any[];
  errorText: string;
}

const initialState: IInitialState = {
  loading: false,
  error: false,
  data: [],
  updatedData: [],
  errorText: ''
};

export const fetchUserWorkHistory = createAsyncThunk('user/workHistory', async (userId: string) => {
  const response = await API.getWorkHistory({ location: getUserDetails().location, userId: userId });
  const sortedResponse =
    ( _.isArray(response?.data) && !_.isEmpty(response?.data))
      ? response.data.sort((a: any, b: any) => parseInt(b?.start_date) - parseInt(a?.start_date))
      : [];
  return sortedResponse;
});

const userWorkHistorySlice = createSlice({
  name: 'user-work-history',
  initialState,
  reducers: {
    clearWorkHistory: (state, action) => {
      state.loading = false;
      state.error = false;
      state.errorText = '';
      state.data = [];
    },
    updateWorkHistory: (state, action) => {
      state.loading = false;
      state.error = false;
      state.errorText = '';
      state.updatedData = action.payload.sort((a: any, b: any) => parseInt(b?.start_date) - parseInt(a?.start_date));
    },
    addWorkHistory: (state, action) => {
      state.updatedData = [...state.updatedData, action.payload].sort(
        (a: any, b: any) => parseInt(b?.start_date) - parseInt(a?.start_date)
      );
    },
    editWorkHistory: (state, action) => {
      const filteredData = state.updatedData.map((item) => {
        if (item._id === action.payload._id) {
          return action.payload;
        }
        return item;
      });
      return {
        ...state,
        updatedData: filteredData.sort((a: any, b: any) => parseInt(b?.start_date) - parseInt(a?.start_date))
      };
    },
    deleteWorkHistory: (state, action) => {
      const filteredData = state.updatedData.filter((item) => item._id !== action.payload._id);
      state.updatedData = filteredData.sort((a: any, b: any) => parseInt(b?.start_date) - parseInt(a?.start_date));
    },
    deleteAllWorkHistory: (state) => {
      state.updatedData = [];
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserWorkHistory.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.errorText = '';
      })
      .addCase(fetchUserWorkHistory.fulfilled, (state, action) => {
        state.loading = false;
        // state.data = action.payload;
        state.updatedData = action.payload;
        state.error = false;
        state.errorText = '';
      })
      .addCase(fetchUserWorkHistory.rejected, (state, action) => {
        state.loading = false;
        state.data = [];
        state.updatedData = [];
        state.error = true;
        state.errorText = action.error.message || '';
      });
  }
});

export default userWorkHistorySlice.reducer;
export const userWorkHistoryActions = userWorkHistorySlice.actions;
