import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from '../../api';

export interface IChatUsers {
  communicationId: string;
  name: string;
  userId: string;
}

interface IInitialState {
  loading: boolean;
  data: IChatUsers[];
  error: boolean;
  errorText: string;
}

const initialState: IInitialState = {
  loading: false,
  data: [],
  error: false,
  errorText: ''
};

export const fetchChatUsers = createAsyncThunk('getChatUsers', (query: string) => {
  return API.getChatUsers(query).then((response) => response.data);
});

const getChatUsersSlice = createSlice({
  name: 'all chat users',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchChatUsers.pending, (state, action) => {
      state.loading = true;
      state.data = [];
      state.error = false;
      state.errorText = '';
    });
    builder.addCase(fetchChatUsers.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = false;
      state.errorText = '';
    });
    builder.addCase(fetchChatUsers.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = true;
      state.errorText = action.error.message || 'Something went wrong';
    });
  }
});

export default getChatUsersSlice.reducer;
