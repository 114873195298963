/* eslint-disable guard-for-in */
/* eslint-disable max-depth */

import { useAuth0 } from '@auth0/auth0-react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAppSelector } from '../redux/hooks';
import ROLES, { rolesArray } from '../utils/roles';
import _ from 'lodash';
import { Box, Dialog, Typography, LinearProgress } from '@mui/material';
import AppLoader from '../components/AppLoader';
import ErrorPage from '../components/ErrorPage';
import EditProfileForm from '../pages/new-designs/components/EditProfileForm';
import HobbiesForm from '../pages/new-designs/components/HobbiesForm';
import SocialHandlesForm from '../pages/new-designs/components/SocialHandlesForm';
import DisabilityForm from '../pages/new-designs/components/DisabilityForm';
import DigitalIntroForm from '../pages/new-designs/components/DigitalIntroForm';
import EducationForm from '../pages/new-designs/components/EducationForm';
import WorkHistoryForm from '../pages/new-designs/components/WorkHistoryForm';
import useProfileDetails from '../hooks/useProfileDetails';
import DosenDarkLogoImport from '../assets/images/brand/dosen/dark-logo.png';
import { makeStyles } from '@mui/styles';
import OnboardingQuestions from '../pages/new-designs/components/OnboardingQuestions';
import { AGILE_ORG, RDP_CREATIVE } from '../utils/constants';
import useResponsiveScreenSwitch from '../utils/useResponsiveScreenSwitch';
import OnboardingProcessNew from '../pages/new-designs/tabs/profile/OnboardingProcessNew';
import useOrgSettings from '../hooks/useOrgSettings';
import OnboardingProcessIndustry from '../pages/new-designs/tabs/profile/OnboardingProcessIndustry';
import { popupCountActions } from '../redux/slices/poupsCountsSlice';
import { useDispatch } from 'react-redux';
import { isMobile } from './helpers';
import Company from '../pages/new-designs/components/Company';
import { checkOrfium } from '../utils/orfiumFunctions';

const useStyles = makeStyles({
  logoBox: {
    textAlign: 'left',
    paddingTop: '20px',
    paddingBottom: '20px',
    '@media (min-width: 600px)': {
      textAlign: 'center',
      paddingBottom: '40px'
    }
  }
});

const Redirect = () => {
  const { isAuthenticated, isLoading, error } = useAuth0();
  const classes = useStyles();
  const count = useAppSelector((state) => state.popupsCount.count);
  const { loading, data } = useAppSelector((state) => state.mentorTasks);
  const user = useAppSelector((state) => state.userProfile);
  let role = localStorage.getItem('role') || '';
  const isAuth = localStorage.getItem('isAuthenticated');
  const isLoggedIn = isAuth ? JSON.parse(isAuth) : false;
  const { ftue, mentee, mentors } = useProfileDetails();
  const { settings, firstName, category, lastseen } = useProfileDetails() || {
    settings: { disabilityType: undefined }
  };
  const oid = useAppSelector((state) => state.orgDetails.data?.oid);
  const { forMobile } = useResponsiveScreenSwitch();
  const dispatch = useDispatch();
  const isOrfium = checkOrfium(oid, lastseen?.id || '');

  interface Popup {
    component: React.ReactNode;
    name: string;
    heading: string;
  }

  const isOrgSettings = useOrgSettings();
  /** Profile Information */
  const newPopups = [
    {
      component: <EditProfileForm />,
      name: 'Profile Information',
      heading: 'Profile Information'
    }
  ];
  if (settings?.videoIntro !== undefined) {
    newPopups.push({
      component: <DigitalIntroForm />,
      name: 'Video Intro',
      heading: 'Video Intro'
    });
  }
  // console.log(settings);

  if (isOrgSettings) {
    if (settings?.company !== undefined) {
      /** Company Form */
      newPopups.push({
        component: <Company />,
        name: 'Company',
        heading: 'Company'
      });
    }
    /** Social handles */
    if (!isOrfium) {
      newPopups.push({
        component: <SocialHandlesForm />,
        name: 'Social Handles',
        heading: 'Social Handles'
      });
    }

    /** Hobbies Form */
    if (oid !== AGILE_ORG && settings && settings.hobbiesInterests !== undefined) {
      newPopups.push({
        component: <HobbiesForm />,
        name: 'Hobbies and interests',
        heading: 'Hobbies and Interests'
      });
    }

    /** Topics & Industry */
    if ((mentee.length > 0 || mentors.length > 0) && oid !== RDP_CREATIVE) {
      newPopups.push({
        component: <OnboardingProcessNew />,
        name: 'Select the topics most relevant to you',
        heading: 'Select the topics most relevant to you'
      });
      newPopups.push({
        component: <OnboardingProcessIndustry />,
        name: 'Select the industries most relevant to you',
        heading: 'Select the industries most relevant to you'
      });
    }

    /** Education Form */
    if (oid !== AGILE_ORG && settings && settings.educationHistory !== undefined) {
      newPopups.push({
        component: <EducationForm />,
        name: 'Education History',
        heading: 'Education History'
      });
    }

    /** Work History Form */
    if (settings && settings.workHistory !== undefined) {
      newPopups.push({
        component: <WorkHistoryForm />,
        name: 'Work History',
        heading: 'Work History'
      });
    }

    /** Disability Form */
    if (settings.disabilityType !== undefined) {
      newPopups.push({
        component: <DisabilityForm />,
        name: 'Disability',
        heading: 'Disability'
      });
    }

    /** Onboarding Questions */
    if (data.data.length > 0) {
      newPopups.push({
        component: <OnboardingQuestions />,
        name: 'Assement',
        heading: 'Assesment'
      });
    }
  }

  let totalSteps: any = newPopups.length;
  dispatch(popupCountActions.setPopupLength(newPopups.length));
  const showPopups = () => {
    return (
      <Dialog
        open={ftue}
        onClose={() => {}}
        aria-labelledby="onboardingProcess-dialog-title"
        aria-describedby="onboardingProcess-dialog-description"
        disableEnforceFocus
        fullWidth
        fullScreen={forMobile}
        BackdropProps={{
          sx: { backgroundColor: '#F9FAFC' }
        }}
        PaperProps={{
          sx: {
            padding: '20px',
            boxShadow: 'none',
            border: '1px solid #EFF0F4',
            height: '100%',
            '@media (max-width: 600px)': {
              margin: '0px',
              maxWidth: 'calc(100% - 0px) !important',
              width: '100%',
              padding: '0px',
              height: '100%'
            }
          }
        }}
      >
        <Box px={2}>
          <Box className={classes.logoBox}>
            <img alt="" width="130" src={DosenDarkLogoImport} />
          </Box>
          <LinearProgress
            color="success"
            variant="determinate"
            value={(count / (totalSteps + (data.data.length > 0 ? data.data.length - 1 : 0))) * 100}
          />
          <Box textAlign="right" mt={forMobile ? '-35px' : 1} mb={1}>
            {isOrgSettings ? (
              <Typography variant="h6">{`Step ${count + 1} of ${
                totalSteps + (data.data.length > 0 ? data.data.length - 1 : 0)
              }`}</Typography>
            ) : (
              <Typography variant="h6">Step 1 of 1</Typography>
            )}
          </Box>
        </Box>
        {/* hiding the heading from onboarding onwards as every question has it's onw heading */}
        {!isOrgSettings ? (
          <Box px={2} pt={2}>
            <Typography variant="h2">{newPopups[count]?.heading}</Typography>
          </Box>
        ) : (
          count < newPopups.length && (
            <Box px={2} pt={2}>
              <Typography variant="h2">{newPopups[count]?.heading}</Typography>
            </Box>
          )
        )}

        {/* the last step is onboarding questions, so only one component(OnboardingQuestions) is displayed and steps are managed inside it */}
        {!isOrgSettings ? (
          newPopups[count]?.component
        ) : count >= newPopups.length ? (
          <OnboardingQuestions />
        ) : (
          newPopups[count]?.component
        )}
      </Dialog>
    );
  };

  const checkUserProfile = () => {
    if (role && rolesArray.includes(role)) {
      // to get the no of steps we need the response, that's why loader added for tasks
      if (user.loading || (ftue && loading)) return <AppLoader />;
      if (user.error) return <ErrorPage message={user.errorText} />;
      if (user.data) {
        if (role !== ROLES.platAdmin && ftue) {
          console.log('ftue', ftue);
          if (role === ROLES.orgAdmin && !isOrgSettings && firstName !== '') {
            return <Navigate to="/redirect" />;
          }
          return showPopups();
        } else {
          return <Navigate to="/redirect" />;
        }
      }
    }
    if (role && !rolesArray.includes(role)) {
      return <ErrorPage message="This member does not have a role associated" />;
    }
    // if (user.data) return <h1>Done</h1>;
    // return <ErrorPage message="Something went wrong, please try again" />;
    return null;
  };
  if (isLoading) return <AppLoader />;
  // if (error) return <ErrorPage message={error.message}/>;
  if (error) {
    if (error.message === 'the specified account is not allowed to accept the current invitation') {
      return <ErrorPage message="You can only activate your account with the email address used on your invitation" />;
    } else {
      return <ErrorPage message={error.message} />;
    }
  }
  const mobileAppLinkPage = localStorage.getItem('mobileAppLinkPage');
  const invitation = localStorage.getItem('invitation');

  if (mobileAppLinkPage !== 'true' && isMobile() && (!invitation || (isAuth && ftue))) {
    return <Navigate to="/download-app" />;
  }
  if (isAuthenticated || isLoggedIn) return checkUserProfile();
  return <Navigate to="/login" replace={true} />;
};

export default Redirect;
