import { Box, Stack } from "@mui/material"
import ChatAvatarComponent from "../../chat/ChatAvatarComponent";
import MySkeleton from "../../../../../components/Skeleton";

/** Left side Loader */
export const LibraryLeftBarLoader = () => {
  return (
    <Box>
      {[...Array(5).keys()].map((each, idx) => {
        return (
          <Box key={idx}>
            <Stack direction="row" spacing={2} p={2}>
              <Box>
                <MySkeleton variant="rounded" height={79} width={118} />
              </Box>
              <Box width={'calc(100% - 138px)'}>
                <MySkeleton height={24} width="100%" />
                <MySkeleton height={24} width="100%" />
                <MySkeleton height={16} width="40%" />
              </Box>
            </Stack>
            <MySkeleton height={1} width="100%" />
          </Box>
        );
      })}
    </Box>
  );
};

/** Right side Loader */
export const LibraryRightBarLoader = ({ display }: { display?: boolean | string }) => {
  const isDisplay = display === 'popup' ? false : true;
  return (
    <>
      <Box className={isDisplay ? 'CardBox' : ''}>
        <Box display={'flex'} flexDirection={'column'} p={isDisplay ? 2 : 0} gap={'15px'}>
          <Box display={'flex'} flexDirection={'column'} gap={'10px'}>
            <Box>
              <MySkeleton variant='text' width={250}/>
              <MySkeleton variant='rounded' width={180} height={20} className="my-10"/>
              <Box display={'flex'} flexDirection={'row'} gap={'5px'}>
                <MySkeleton variant='text' height={14} width={150}/>
                <MySkeleton variant='text' height={14} width={50}/>
              </Box>  
            </Box>
            <Box>
              <Stack direction='row' spacing={1}>
                {Array.from({ length: 3 }, (_, idx) => (
                  <MySkeleton variant='rounded' height={32} width={100}/>
                ))}
              </Stack>
            </Box>
          </Box>
          <Box>
            <Stack direction='row' alignItems='center' spacing={1}>
              <ChatAvatarComponent height='28px' width='28px' loading={true} type='noStatus' />
              <MySkeleton variant='text' height={16} width={100}/>
            </Stack>
          </Box>
        </Box>
        <MySkeleton variant='rectangular' height={1} className={isDisplay ? '' : 'my-20'}/>
        <Box className='LibraryVideoAndImage' p={isDisplay ? 2 : ''}>
          <MySkeleton variant='rounded' height='100%' width='100%'/>
        </Box>
        <Box px={isDisplay ? 2 : 0} pt={isDisplay ? 0 : 2}>
          {Array.from({ length: 5 }, (_, idx) => (
            <MySkeleton key={idx} variant='text' height={16} width='100%' />
          ))}
        </Box>
        {isDisplay && (
          <>
            <Box p={2} display={'flex'}  gap={'10px'}>
              {Array.from({ length: 3 }, (_, idx) => (
                <MySkeleton variant='rounded' height={30} width={70}/>
              ))}
            </Box>
            <MySkeleton variant='rectangular' height={1} />
            <Box p={2} display={'flex'} width={'100%'}>
              <MySkeleton variant='text' height={16} width={'100%'} />
            </Box>
          </>
        )}
      </Box>
    </>
  );
};
