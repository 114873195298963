/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-undef */
import React, { useCallback, useContext, useEffect, useState, useRef } from 'react';
import produce from 'immer';
import { Input, Button } from 'antd';
import { ChatPrivilege } from '@zoom/videosdk';
import ZoomContext from '../../context/zoom-context';
import { ChatReceiver, ChatRecord } from './chat-types';
import { useParticipantsChange } from './hooks/useParticipantsChange';
import ChatMessageItem from './component/chat-message-item';
import ChatReceiverContainer from './component/chat-receiver';
import { useMount } from '../../hooks';
import './chat.scss';
import { useAppSelector } from '../../../../../../redux/hooks';
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react-hooks/exhaustive-deps */
// import IconChat from '../../component/svgs/icon-chat.svg';
import { fetchCommunityMembers } from '../../../../../../redux/slices/acs-community-members/acsCommunityMembers';
import { getUserDetails } from '../../../../../../utils/orgName';
import { HiOutlineChat } from 'react-icons/hi';
import chatIcon from '../../component/svgs/chat.svg';
import VideoFooter from '../video/components/video-footer';
import { Box, Divider, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
const { TextArea } = Input;
const Videocallchat = ({ onChatClick, chatData }: any) => {
  const zmClient = useContext(ZoomContext);
  const chatClient = zmClient.getChatClient();
  const chatRecords = chatData;
  const [currentUserId, setCurrentUserId] = useState<number>(0);
  const [chatReceivers, setChatReceivers] = useState<ChatReceiver[]>([]);
  const [chatPrivilege, setChatPrivilege] = useState<ChatPrivilege>(ChatPrivilege.All);
  const [chatUser, setChatUser] = useState<ChatReceiver | null>(null);
  const [isHost, setIsHost] = useState<boolean>(false);
  const [isManager, setIsManager] = useState<boolean>(false);
  const [chatDraft, setChatDraft] = useState<string>('');
  const chatWrapRef = useRef<HTMLDivElement | null>(null);
  
  const communityMembers = useAppSelector((state) => state.acsCommunityUsers.data);
  const userData = useAppSelector((state) => state.userProfile.data);

  const onChatPrivilegeChange = useCallback(
    (payload: { chatPrivilege: React.SetStateAction<ChatPrivilege> }) => {
      setChatPrivilege(payload.chatPrivilege);
      if (chatClient) {
        setChatReceivers(chatClient.getReceivers());
      }
    },
    [chatClient]
  );
  const onChatInput = useCallback((event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setChatDraft(event.target.value);
  }, []);

  useEffect(() => {
    zmClient.on('chat-privilege-change', onChatPrivilegeChange);
    return () => {
      zmClient.off('chat-privilege-change', onChatPrivilegeChange);
    };
  }, [zmClient, onChatPrivilegeChange]);
  useParticipantsChange(zmClient, () => {
    if (chatClient) {
      setChatReceivers(chatClient.getReceivers());
    }
    setIsHost(zmClient.isHost());
    // setIsManager(zmClient.isManager());
  });
  useEffect(() => {
    if (chatUser) {
      const index = chatReceivers.findIndex((user) => user.userId === chatUser.userId);
      if (index === -1) {
        setChatUser(chatReceivers[0]);
      }
    } else {
      if (chatReceivers.length > 0) {
        setChatUser(chatReceivers[0]);
      }
    }
  }, [chatReceivers, chatUser]);
  const setChatUserId = useCallback(
    (userId: number) => {
      const user = chatReceivers.find((u) => u.userId === userId);
      if (user) {
        setChatUser(user);
      }
    },
    [chatReceivers]
  );
  const sendMessage = useCallback(
    (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
      event.preventDefault();
      if (chatUser && chatDraft) {
        chatClient?.send(chatDraft, chatUser?.userId);
        setChatDraft('');
      }
    },
    [chatClient, chatDraft, chatUser]
  );
  useMount(() => {
    setCurrentUserId(zmClient.getSessionInfo().userId);
    if (chatClient) {
      setChatPrivilege(chatClient.getPrivilege());
    }
  });

  const handleSendMessage = useCallback(() => {
    if (chatUser && chatDraft) {
      chatClient?.send(chatDraft, chatUser?.userId);
      setChatDraft('');
    }
  }, [chatClient, chatDraft, chatUser]);
  const [closeButton, setCloseButton] = useState(onChatClick);

  const handleClose = () => {
    setCloseButton(false);
  };
  return (
    <>
      {closeButton === true && (
        <div className="videocall-chat-mainbox">
          <div className="chat-wrap">
            <Box className="VideoChatHeader">
              <Typography variant="h5">Meeting Chat</Typography>
              <CloseIcon onClick={handleClose} className="cursor-pointer" />
            </Box>
            <Divider />
            <div className="chat-message-wrap" ref={chatWrapRef}>
              {chatRecords?.map((record: ChatRecord) => (
                <ChatMessageItem
                  record={record}
                  currentUserId={currentUserId}
                  setChatUser={setChatUserId}
                  key={record.timestamp}
                />
              ))}
            </div>
            {ChatPrivilege.NoOne !== chatPrivilege || isHost || isManager ? (
              <>
                <div className="chat-box">
                  <ChatReceiverContainer
                    chatUsers={chatReceivers}
                    selectedChatUser={chatUser}
                    isHostOrManager={isHost || isManager}
                    chatPrivilege={chatPrivilege}
                    setChatUser={setChatUserId}
                  />
                  <div className="chat-message-box">
                    <TextArea
                      onPressEnter={handleSendMessage}
                      onChange={onChatInput}
                      value={chatDraft}
                      placeholder="Type message here"
                    />
                    <SendIcon className="send-message-button" onClick={handleSendMessage} />
                  </div>
                </div>
              </>
            ) : (
              <div className="chat-disabled">Chat disabled</div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Videocallchat;
