import React from 'react';
import { Box } from "@mui/material";
import MySkeleton from "../../../../components/Skeleton";

// Separate functional component SearchBar
export const SearchBar = () => {
  return (
    <Box pb={1}>
      <MySkeleton variant='rounded' height={30} />
    </Box>
  );
};

// Separate functional component CheckBoxRow 
export const CheckBoxRow = () => {
  return (
    <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}py={1}>
      <MySkeleton width={100} />
      <MySkeleton variant="rectangular" height={18} width={18} />
    </Box>
  );
};

// Separate functional component Another Filters
export const FilterRow = () => {
  return (
    <Box py={1}>
      <MySkeleton variant='rectangular' height={'1px'}/>
      <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} pt={2}>
        <MySkeleton width={150} />
        <MySkeleton variant="rectangular" height={12} width={12} />
      </Box>
    </Box>
  );
};

const FilterLoader = () => {
  return (
    <>
      <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} pb={2}>
        <MySkeleton width={150} />
        <MySkeleton variant="rectangular" height={10} width={10} />
      </Box>
      {SearchBar()}
      {CheckBoxRow()}
      {FilterRow()}
      {FilterRow()}
    </>
  );
};

export default FilterLoader;
