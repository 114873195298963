/* eslint-disable guard-for-in */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactNode, useEffect, useState } from 'react';
import {
  Box,
  Stack,
  Divider,
  IconButton,
  Typography
} from '@mui/material';
import _ from 'lodash';
import { GetSocialIcon } from './SocialIcon';
import { EditIcon } from '../../../utils/Icons';
import { useAppDispatch } from '../../../redux/hooks';
import MySkeleton from '../../../components/Skeleton';
import { popupActions } from '../../../redux/slices/onboardingPopupsSlice';

interface Props {
  socialData?: any;
  loading?: boolean;
  editing?: boolean;
  divider?: boolean;
}
        
const SocialHandles = ({
  socialData,
  editing = false,
  divider = false,
  loading = false,
}: Props) => {
  const [data, setData] = useState<any>(socialData);
  const dispatch = useAppDispatch();

  useEffect(() => {
    setData(socialData);
  }, [socialData]);

  const handleClickOpenSocial = () => {
    dispatch(popupActions.updatePopups({ social: true }));
  };

  return (
    <Box pb={divider ? 0 : 2}>
      <Box px={divider ? 2 : 0} display='flex' alignItems='center' justifyContent='space-between'>
        <Typography variant="h5" py={2}>
          {loading ? (<MySkeleton variant="text" height={16} width={150} />) : 'Social handles'}
        </Typography>
        {/* edit icon for profile page */}
        {editing &&
          <IconButton size='small' onClick={handleClickOpenSocial}>
            <EditIcon />
          </IconButton>
        }
      </Box>
      {/* divider for profile page */}
      {divider && <Divider /> }

      <Box p={divider ? 2 : 0}>
        {loading ? (
          <Stack direction="row" spacing={1}>
            {Array.from({ length: 5 }, (_, idx) => (
              <MySkeleton variant="rounded" height={30} width={30} key={idx} />
            ))}
          </Stack>
        ) : (
          <GetSocialIcon data={data} />
        )}
      </Box>
    </Box>
  );
};

export default SocialHandles;
