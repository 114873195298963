/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-undef */
/* eslint-disable no-param-reassign */
import { ChangeEvent, useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Controller, FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import {
  Box,
  Dialog,
  Button,
  Checkbox,
  IconButton,
  Typography,
  DialogContent,
  DialogActions,
  FormControlLabel
} from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { API, Education1 } from '../../../api';
import { getUserDetails } from '../../../utils/orgName';
import { useAuth0 } from '@auth0/auth0-react';
import _ from 'lodash';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { popupActions } from '../../../redux/slices/onboardingPopupsSlice';
import { fetchUserEducation, userEducationActions } from '../../../redux/slices/user-education/userEducationSlice';
import useProfileDetails from '../../../hooks/useProfileDetails';
import { popupCountActions } from '../../../redux/slices/poupsCountsSlice';
import CommonModalHeader from '../../../components/DialogUi/CommonModalHeader';
import WorkAndEduDesign from './WorkAndEduDesign';
import EducationDailogBox from './EducationDailogBox';
import GraduateIcon from '../../../assets/images/graduate.svg';
import IfNoEduWork from './IfNoEduWork';
import { userActions } from '../../../redux/slices/user-profile/userProfieSlice';
import { companyActions } from '../../../redux/slices/companySlice';

export interface Education {
  [key: string]: string | string[] | null;
  minor: string[] | null;
  major: string;
  graduation_date: string;
  university: string;
  department: string;
}

export interface EducationError {
  minor: string;
  major: string;
  graduation_date: string;
  university: string;
  department: string;
}

export interface graduationDateError {
  graduation_date?: string;
}

interface FilmOptionType {
  inputValue?: string;
  value?: string;
  type?: string;
}

const schema = yup.object({
  university: yup
    .string()
    .required('College/University is required')
    .test('no-empty-spaces', 'College/University cannot be all empty spaces', (value) => {
      if (value) {
        return !/^[\s]+$/.test(value);
      }
      return true;
    }),
  major: yup
    .string()
    .required('Major/Degree is required')
    .test('no-empty-spaces', 'Major cannot be all empty spaces', (value) => {
      if (value) {
        return !/^[\s]+$/.test(value);
      }
      return true;
    }),
  minor: yup.mixed().nullable(),
  department: yup
    .string()
    .required('Department/School is required')
    .test('no-empty-spaces', 'Minor cannot be all empty spaces', (value) => {
      if (value) {
        return !/^[\s]+$/.test(value);
      }
      return true;
    }),
  graduation_date: yup
    .number()
    .transform((value, originalValue) => {
      if (originalValue === '') {
        return null;
      }
      return value;
    })
    .typeError('Please Enter a Year')
    .test('is-four-digits', 'Please enter a valid year', (value) => {
      if (value) {
        return /^\d{4}$/.test(String(value));
      }
      return false;
    })
    .required('Graduation year is required')
  // .max(new Date().getFullYear(), `Must be less than ${new Date().getFullYear()}`)
});

interface AllListData {
  approved: boolean;
  oid: string;
  department: string;
  region: string;
  type: string;
  value: string;
  _id: string;
}

const EducationForm = () => {
  const { user } = useAuth0();
  const { orgId } = getUserDetails();
  const { userId, location } = getUserDetails();
  // if we are keeping || false, then true || flase evaluated to true
  const { ftue, settings } = useProfileDetails();
  const [data, setData] = useState<Education[]>([]);
  const [allListDepartment, setAllListDepartment] = useState<AllListData[]>([]);
  const [uniqueDeptArr, setUniqueDeptArr] = useState<string[]>([]);
  const [allListUniversity, setAllListUniversity] = useState<AllListData[]>([]);
  const [allListMajorDegree, setAllListMajorDegree] = useState<AllListData[]>([]);
  const [allListMinor, setAllListMinor] = useState<AllListData[]>([]);

  const [submitLoader, setSubmitLoader] = useState(false);
  const { open, action, item, educationPopUpDisable } = useAppSelector(
    (state) => state.onboardingPopups.data.education
  );
  const [openDelete, setDelete] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [universityValue, setUniversityValue] = useState('');
  const [activeId, setActiveId] = useState('');
  const [majorDegreeValue, setMajorDegreeValue] = useState('');
  const [minorValue, setMinorValue] = useState('');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [allFieldsFilled, setAllFieldsFilled] = useState(false);
  const [dept, setDept] = useState('');
  const [majorNewValue, setMajorNewValue] = useState('');
  const dispatch = useAppDispatch();
  const [addMoreEducation, setAddMoreEducation] = useState(false);
  const education = useAppSelector((state) => state.onboardingPopups.data.education);
  const getAllEducationDetails: any = useAppSelector((state) => state.userEducation?.updatedData);

  const typeU = 'University Name';
  const typeMD = 'Degree';
  const typeM = 'Minors';
  const valueU = universityValue;
  const valueMD = majorDegreeValue;
  const valueM = minorValue;
  const getDeptObj = (newValue: string) => {
    setMajorNewValue(newValue);
  };

  const UniversityList: string[] = allListUniversity ? allListUniversity?.map((item) => item.value) : [];
  const majorDegreeList: string[] = allListMajorDegree ? allListMajorDegree?.map((item) => item.value) : [];
  const minorList: string[] = allListMinor ? allListMinor?.map((item) => item.value) : [];
  const popuplength = useAppSelector((state) => state.popupsCount.popuplength);
  const count = useAppSelector((state) => state.popupsCount.count);
  const nextPopUpOrNot = popuplength - count;
  const [checked, setChecked] = useState(false);
  const userData = useAppSelector((state) => state.userProfile.data);
  const userProfile = useAppSelector((state) => state.userProfile.data?.personal_details);
  const userWorkHistory = useAppSelector((state) => state.userWorkHistory);
  const workHistoryData = userWorkHistory?.data;
  const optionalFields = _.get(userData, 'settings', {
    gender: false,
    ethnicity: false,
    firstGenerationStudent: false,
    disabilityType: false,
    dob: false,
    country: false,
    category: false,
    timezone: false,
    workHistory: false,
    educationHistory: false,
    hobbiesInterests: false
  });
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    getValues
  } = useForm({
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    const flag = localStorage.getItem('edFlag');
    const edFlag = flag ? JSON.parse(flag) : false;
    setChecked(edFlag);
  }, []);
  const checkError = (fieldName: string) => Boolean(errors[fieldName]);
  const getError = (fieldName: string) => errors[fieldName]?.message;

  useEffect(() => {
    fetchDataDepartment();
  }, []);

  useEffect(() => {
    setUniqueDeptArr(allListDepartment?.map((each: AllListData) => each.value));
  }, [allListDepartment]);

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      fetchDataUnivercity();
    }, 100);
    return () => {
      clearTimeout(debounceTimer);
    };
  }, [universityValue]);

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      fetchDataMajorDegree();
    }, 100);
    return () => {
      clearTimeout(debounceTimer);
    };
  }, [majorDegreeValue]);

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      fetchDataMinor();
    }, 100);
    return () => {
      clearTimeout(debounceTimer);
    };
  }, [minorValue]);

  useEffect(() => {
    const data = localStorage.getItem('education');
    if ((action === 'Add' || ftue) && !data) {
      const deptObject = allListMajorDegree?.find((deg) => deg?.value === majorNewValue);
      setDept(deptObject?.department || '');
      setValue('department', deptObject?.department || '');
    }
  }, [majorNewValue]);

  const handleCloseEducationDetailed = () => {
    if (!educationPopUpDisable) {
      setUniversityValue('');
      setMajorDegreeValue('');
      setMinorValue('');
      setDept('');
      reset();
      dispatch(
        popupActions.updatePopups({
          education: { ...education, open: false, educationPopUpDisable: false, action: 'Add' }
        })
      );
    }
  };

  useEffect(() => {
    if (open && action === 'Edit') {
      reset(item);
      // dispatch(popupActions.updatePopups({ education: { ...education, open: false, action: 'Add' } }));
    } else {
      const data = localStorage.getItem('education');
      const itemData = ftue && data ? JSON.parse(data) : {};
      reset({
        minor: [],
        major: '',
        graduation_date: '',
        university: '',
        department: ''
      });
    }
  }, [open, action, item, ftue, reset]);

  const handleDelete = async () => {
    setDeleteLoader(true);
    try {
      const response = await API.deleteEducation({
        userId,
        location,
        id: item._id
      });
      if (response.status === 200 && response.statusText === 'OK') {
        const newData = Array.isArray(data) && data?.length > 0 ? data?.filter((each) => each._id !== activeId) : [];
        setData(newData);
        setDelete(false);
        setActiveId('');
        setDeleteLoader(false);
        localStorage.removeItem('education');

        if (!ftue) {
          toast.success('Education details deleted successfully');
        }
        dispatch(userEducationActions.deleteEducation(item));
        handleCloseEducationDetailed();
      }
    } catch (e) {
      toast.error(_.get(e, 'response.data.message', 'Education details failed to update'));
      setDeleteLoader(false);
    }
  };

  useEffect(() => {
    return () => {
      handleCloseEducationDetailed();
    };
  }, []);

  const updateFtue = async () => {
    setSubmitLoader(true);
    try {
      const response = await API.updateUserFtue({ ftue: false, userId });
      if (response.status === 200 && response.statusText === 'OK') {
        dispatch(userActions.userFtueUpdate(0));
        setSubmitLoader(false);
      }
    } catch (e) {
      toast.error(_.get(e, 'response.data.message', `Education details failed to update`));
      setSubmitLoader(false);
    }
  };
  const updatePopups = () => {
    if (getAllEducationDetails?.length !== 0 && checked) {
      dispatch(popupCountActions.increment(0));
      try {
        const response = API.deleteAllEducationAndWorkHistory({
          userId,
          deleteType: 'education'
        });
        dispatch(userEducationActions.deleteAllEducation());
      } catch (error) {
        console.log('Error', error);
      }
    } else if (getAllEducationDetails?.length !== 0 && !checked) {
      dispatch(popupCountActions.increment(0));
    } else if (getAllEducationDetails?.length === 0 && checked) {
      dispatch(popupCountActions.increment(0));
    } else {
      dispatch(companyActions.update(true));
      updateFtue();
    }
  };

  const onSubmit: SubmitHandler<any> = async (values) => {
    console.log('Submit button clicked');
    const requiredData: Education1 = {
      university: values.university,
      major: values.major,
      minor: values.minor,
      graduation_date: values.graduation_date.toString(),
      department: values.department
    };
    setSubmitLoader(true);
    const submitData = {
      userId: getUserDetails()?.userId,
      location: getUserDetails()?.location,
      formData: requiredData
    };
    if (action === 'Add') {
      try {
        const response = await API.addEducationAndSkills(submitData);
        if (response.status === 200 && response.statusText === 'OK') {
          setSubmitLoader(false);
          if (response.data.response) {
            dispatch(userEducationActions.addEducation(response?.data.response));
          }
          reset();
          localStorage.removeItem('education');
          setIsDialogOpen(false);
          setAddMoreEducation(true);
          handleCloseEducationDetailed();
          if (!ftue) {
            dispatch(popupActions.updatePopups({ education: { open: false, educationPopUpDisable: false } }));
            toast.success('Education history added successfully');
          }
          if (
            !ftue &&
            optionalFields.workHistory &&
            userData?.workHistory.length === 0 &&
            workHistoryData.length === 0
          ) {
            dispatch(
              popupActions.updatePopups({
                workHistory: { open: true, workHistoryDisable: true, action: 'Add', item: {} }
              })
            );
          }
        }
      } catch (e) {
        toast.error(_.get(e, 'response.data.message', 'Education details failed to update'));
        setSubmitLoader(false);
      }
    }
    if (action === 'Edit') {
      const data2 = data?.map((each) => {
        if (each._id === activeId) {
          return { ...requiredData, _id: each._id };
        }
        return each;
      });
      const withoutId: any = {};
      const newData: any = submitData?.formData;
      for (let i in newData) {
        if (i !== '_id') {
          withoutId[i] = newData[i];
        }
      }
      try {
        const response = await API.updateEducationAndSkills({
          ...submitData,
          formData: withoutId,
          id: item._id
        });
        if (response.status === 200 && response.statusText === 'OK') {
          setSubmitLoader(false);
          setData(data2);
          localStorage.removeItem('education');
          dispatch(userEducationActions.editEducation(values));
          toast.success('Education details updated successfully');
        }
        reset();
        handleCloseEducationDetailed();
        setIsDialogOpen(false);
      } catch (e) {
        toast.error('Education details failed to update');
        setSubmitLoader(false);
      }
    }
  };

  const fetchDataUnivercity = async () => {
    try {
      const responseUniversity = await API.getTagsListnew(orgId, location, typeU, valueU);
      setAllListUniversity(responseUniversity?.data?.tagListResponse);
    } catch (error) {
      toast.error("University list failed to load");
    }
  };

  const fetchDataMajorDegree = async () => {
    try {
      const responseMajorDegree = await API.getTagsListnew(orgId, location, typeMD, valueMD);
      setAllListMajorDegree(responseMajorDegree?.data?.tagListResponse);
    } catch (error) {
      toast.error("XP points failed to update");
    }
  };

  const fetchDataMinor = async () => {
    try {
      const responseMinor = await API.getTagsListnew(orgId, location, typeM, valueM);
      setAllListMinor(responseMinor?.data?.tagListResponse);
    } catch (error) {
      toast.error("Minors list failed to load");
    }
  };

  const fetchDataDepartment = async () => {
    try {
      const responseDepartment = await API.getTagsListnew(orgId, location, 'department', '');
      setAllListDepartment(responseDepartment?.data?.tagListResponse);
    } catch (error) {
      toast.error("Department list failed to load");
    }
  };

  const handleMinor = (event: React.SyntheticEvent<Element, Event>, value: string[]) => {
    setValue('minor', value);
    errors['minor'] = undefined;
  };

  const handleCheckbox = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    if (checked) {
      localStorage.removeItem('education');
      setDept('');
      reset({
        department: '',
        graduation_date: '',
        major: '',
        minor: [],
        university: ''
      });
    }
    localStorage.setItem('edFlag', JSON.stringify(checked));
    setChecked(checked);
  };

  // useEffect(() => {
  //   if (getAllEducationDetails && getAllEducationDetails?.length < 1) {
  //     dispatch(popupActions.updatePopups({ education: { open: true, action: 'Add', item: {} } }));
  //   }
  // }, [action, dispatch, getAllEducationDetails]);

  const handleClickOpenEducationDetailed = () => {
    setIsDialogOpen(true);
    dispatch(popupActions.updatePopups({ education: { open: false, action: 'Add', item: {} } }));
  };

  const handleEditEducation = (education: any) => {
    setActiveId(education._id);
    dispatch(popupActions.updatePopups({ education: { item: education, open: true, action: 'Edit' } }));
  };

  const handleClose = () => {
    setIsDialogOpen(false);
  };

  return (
    <>
      {/* This plus icon for Add Education */}
      {ftue && getAllEducationDetails?.length !== 0 && (
        <Box display={'inline-block'} pr={2} textAlign={'right'} position={'relative'} top={'-28px'}>
          <IconButton
            size="small"
            onClick={() => {
              handleClickOpenEducationDetailed();
            }}
          >
            <AddCircleOutlineIcon className="cursor-pointer" color="info" fontSize="small" />
          </IconButton>
        </Box>
      )}
      {/* <Typography px={2} pt={2}>Add your Education History</Typography> */}

      <DialogContent>
        {checked ? (
          <IfNoEduWork EducationHistory />
        ) : (
          <>
            {ftue ? (
              <>
                <Dialog open={isDialogOpen} onClose={handleClose}>
                  <CommonModalHeader handleCloseModal={handleClose} title={'Add your Education history'} />
                  <DialogContent>
                    <EducationDailogBox
                      UniversityList={UniversityList}
                      setUniversityValue={setUniversityValue}
                      control={control}
                      handleSubmit={handleSubmit}
                      errors={errors}
                      setValue={setValue}
                      checkError={checkError}
                      getError={getError}
                      getDeptObj={getDeptObj}
                      setMajorDegreeValue={setMajorDegreeValue}
                      majorDegreeList={majorDegreeList}
                      action={action}
                      minorList={minorList}
                      ftue={ftue}
                      setMinorValue={setMinorValue}
                      handleMinor={handleMinor}
                      dept={dept}
                      uniqueDeptArr={uniqueDeptArr}
                    />{' '}
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose} variant="outlined" fullWidth>
                      Cancel
                    </Button>
                    <LoadingButton
                      onClick={() => handleSubmit(onSubmit)()}
                      variant="contained"
                      loading={submitLoader}
                      fullWidth
                    >
                      Save
                    </LoadingButton>
                  </DialogActions>
                </Dialog>
                {getAllEducationDetails?.length !== 0 ? (
                  <>
                    <WorkAndEduDesign
                      data={getAllEducationDetails}
                      itemType="education"
                      wherePrint="onBoarding"
                      onEdit={handleEditEducation}
                      remove={setDelete}
                    />
                  </>
                ) : (
                  <EducationDailogBox
                    UniversityList={UniversityList}
                    setUniversityValue={setUniversityValue}
                    control={control}
                    handleSubmit={handleSubmit}
                    errors={errors}
                    setValue={setValue}
                    checkError={checkError}
                    getError={getError}
                    getDeptObj={getDeptObj}
                    setMajorDegreeValue={setMajorDegreeValue}
                    majorDegreeList={majorDegreeList}
                    action={action}
                    minorList={minorList}
                    ftue={ftue}
                    setMinorValue={setMinorValue}
                    handleMinor={handleMinor}
                    dept={dept}
                    uniqueDeptArr={uniqueDeptArr}
                  />
                )}
              </>
            ) : (
              <EducationDailogBox
                UniversityList={UniversityList}
                setUniversityValue={setUniversityValue}
                control={control}
                handleSubmit={handleSubmit}
                errors={errors}
                setValue={setValue}
                checkError={checkError}
                getError={getError}
                getDeptObj={getDeptObj}
                setMajorDegreeValue={setMajorDegreeValue}
                majorDegreeList={majorDegreeList}
                action={action}
                minorList={minorList}
                ftue={ftue}
                setMinorValue={setMinorValue}
                handleMinor={handleMinor}
                dept={dept}
                uniqueDeptArr={uniqueDeptArr}
              />
            )}
          </>
        )}
      </DialogContent>
      {/* No education history Checkbox */}
      {!settings.educationHistory && count > 0 && (
        <Box pl={2} pb={1}>
          <Box>
            <FormControlLabel
              control={<Checkbox checked={checked} name="currentlyWorking" onChange={handleCheckbox} />}
              label="No education history"
            />
          </Box>
          {/* <Typography variant="body1" mt={1}>
      Note: You can add more experience from your profile section after sign up.
    </Typography> */}
        </Box>
      )}

      <DialogActions>
        {(ftue || action === 'Edit') && (
          <Button
            variant="outlined"
            color="secondary"
            fullWidth
            onClick={() => (ftue ? dispatch(popupCountActions.decrement(0)) : setDelete(true))}
          >
            {ftue ? 'Back' : 'Remove'}
          </Button>
        )}

        {!ftue && submitLoader ? (
          <LoadingButton loading={submitLoader} sx={{ width: 500 }}>
            Save
          </LoadingButton>
        ) : (
          <>
            {ftue ? (
              <LoadingButton
                color="primary"
                variant="contained"
                fullWidth
                loading={submitLoader}
                onClick={() => {
                  !checked && getAllEducationDetails?.length === 0
                    ? handleSubmit(onSubmit)()
                    : !checked && getAllEducationDetails?.length !== 0 && nextPopUpOrNot === 1
                    ? updatePopups()
                    : !checked && getAllEducationDetails?.length !== 0 && nextPopUpOrNot > 1
                    ? updatePopups()
                    : checked && nextPopUpOrNot === 1
                    ? updatePopups()
                    : checked && nextPopUpOrNot > 1
                    ? updatePopups()
                    : updatePopups();
                }}
              >
                {!checked && getAllEducationDetails?.length === 0
                  ? 'Add'
                  : !checked && getAllEducationDetails?.length !== 0 && nextPopUpOrNot === 1
                  ? 'Finish'
                  : !checked && getAllEducationDetails?.length !== 0 && nextPopUpOrNot > 1
                  ? 'Next'
                  : checked && nextPopUpOrNot === 1
                  ? 'Finish'
                  : checked && nextPopUpOrNot > 1
                  ? 'Next'
                  : 'Finish'}
              </LoadingButton>
            ) : (
              <Button
                color="primary"
                variant="contained"
                fullWidth
                onClick={() => handleSubmit(onSubmit)()}
                id="educationHistorySubmit"
              >
                Save
              </Button>
            )}
          </>
        )}
      </DialogActions>

      {/* Remove Confirmation Popup */}
      <Dialog open={openDelete} onClose={() => setDelete(false)}>
        {/* Dialog Header */}
        <CommonModalHeader title={`Confirmation`} handleCloseModal={() => setDelete(false)} />
        <DialogContent>
          <Typography variant="h5" textAlign="center">
            Are you sure to remove this <br /> education details?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button fullWidth variant="outlined" color="secondary" onClick={() => setDelete(false)}>
            No
          </Button>

          {deleteLoader ? (
            <LoadingButton loading={deleteLoader} fullWidth>
              Yes, Remove
            </LoadingButton>
          ) : (
            <Button
              color="primary"
              variant="contained"
              fullWidth
              onClick={() => {
                handleDelete();
              }}
            >
              Yes, Remove
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EducationForm;
