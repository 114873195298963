import React from 'react';
import { Box } from '@mui/material';
import MySkeleton from '../components/Skeleton';
import useResponsiveScreenSwitch from '../utils/useResponsiveScreenSwitch';

const CallBookingPopupLoader = () => {
    const { forMobile } = useResponsiveScreenSwitch();
    return (
        <Box>
            {[...Array(3).keys()].map((_, index) => (
                <Box key={index} pb={2}>
                    <MySkeleton  width={index === 1 ? 70 : 100} sx={{ marginBottom: '10px' }} />
                    <MySkeleton variant='rounded' height={36} width={'100%'}/>
                </Box>
            ))}
            <MySkeleton variant='rectangular' height={1} /> 
            <Box display={'flex'} gap={'20px'} py={2} flexDirection={forMobile ? 'column' : 'row'} >
                <Box>
                    <MySkeleton variant='rounded' width={150} sx={{ marginBottom: '20px' }} />
                    <Box display="flex" flexDirection={'column'} justifyContent={'center'}  pr={2}>
                        <Box display="flex">
                            {[...Array(7).keys()].map((_, index) => (
                                <Box key={index} display={'flex'} margin={'4px'} flexDirection="row">
                                    <MySkeleton width={32} />
                                </Box>
                            ))}
                        </Box>
                        <Box display="flex" flexDirection="column" gap={'4px'}>
                            <Box display="flex" gap={'4px'}>
                                {[...Array(3).keys()].map((_, index) => (
                                    <Box key={index} display={'flex'} flexDirection="row" justifyContent="center" alignItems="center" width={32} />
                                ))}
                            </Box>
                            <Box display="flex" flexDirection="column">
                                {[...Array(5).keys()].map((weekIndex) => (
                                    <Box key={weekIndex} display="flex">
                                        {[...Array(7).keys()].map((dayIndex) => {
                                            const dayNumber = weekIndex * 7 + dayIndex  
                                            if (dayNumber > 31) return null;
                                            return (
                                                <Box key={dayNumber} display="flex" flexDirection="row" margin={'4px'}>
                                                    <MySkeleton variant='circular' height={32} width={32} />
                                                </Box>
                                            );
                                        })}
                                    </Box>
                                ))}
                            </Box>
                        </Box>
                    </Box>
                </Box>
                {forMobile ? (
                    <MySkeleton variant='rectangular' height={1}/> 
                ) : (
                    <MySkeleton variant='rectangular' height={340} width={1} /> 
                )}
                <Box display={'flex'} flexDirection={'column'} justifyContent={'center'}  px={forMobile ? 2 : 0}>
                    <MySkeleton variant='rounded' width={forMobile ? '100%' : 175} sx={{ marginBottom: '20px' }} />
                    {[...Array(6).keys()].map((_, index) => (
                        <Box key={index} mb={1} >
                            <MySkeleton variant='rounded' height={40} width={forMobile ? '100%' : 175} />
                        </Box>
                    ))}
                </Box>
            </Box>

            <MySkeleton variant='rectangular' height={1} /> 
            <Box py={2}>
                <MySkeleton variant='rounded' width={150} sx={{ marginBottom: '20px' }} />
                <MySkeleton variant='rounded' height={100} width={'100%'} />
            </Box>
            <Box pb={2}>
                <MySkeleton variant='rounded' width={200} sx={{ marginBottom: '20px' }} />
                <MySkeleton variant='rounded' height={36} width={'100%'} />
            </Box>
            <MySkeleton variant='text' width={'100%'}/>
        </Box>
    );
};

export default CallBookingPopupLoader;
