import { createSlice, current } from '@reduxjs/toolkit';
import acsChannelParticipants from '../acs-channel-participants/acsChannelParticipants';

interface IPersonalThreads {
  displayNames: string[];
  id: string;
  lastMessageReceivedOn: string;
  topic: string;
  lastmessage: string;
  msgCount: any;
  msgId: string;
  seqId: string;
  type: string;
}
interface IGroupMessage {
  // displayNames: string[];
  id: string;
  topic: string;
  lastMessageReceivedOn: string;
  image: string;
  lastmessage: string;
  msgCount: any;
  senderCommunicationId: any;
  msgId: string;
  seqId: string;
  deleted?: boolean;
  type: string;
}

interface IChannels {
  groupThreads: IGroupMessage[];
  personalThreadsArr: IPersonalThreads[];
}

const initialState: IChannels = {
  groupThreads: [],
  personalThreadsArr: []
};

export const acsChannels = createSlice({
  name: 'acsChatActivities',
  initialState,
  reducers: {
    atnSaveChannels: (state, action: { type: string; payload: IChannels }) => {
      localStorage.removeItem('chatAccessed');
      return action.payload;
    },
    atnSaveChannels2: (state, action: { type: string; payload: IChannels }) => {
      return action.payload;
    },
    updateAcsGroupChannesls: (state, action) => {
      return { ...state, groupThreads: action.payload };
    },
    updateAcsDirectChannesls: (state, action) => {
      return { ...state, personalThreadsArr: action.payload };
    },
    updateGroupInfo: (state, action) => {
      return {
        ...state,
        groupThreads: state.groupThreads.map((each) => {
          if (each.id === action.payload.threadId) {
            return { ...each, ...action.payload.info };
          }
          return each;
        })
      };
    },
    createGroupThread: (state, action) => {
      const index = state.groupThreads.find((each) => each.id === action.payload.id);
      if (index) {
        return {
          ...state,
          groupThreads: state.groupThreads.map((each) => {
            if (each.id === action.payload.id) {
              return { ...each, image: action.payload.image };
            }
            return each;
          })
        };
      }
      return { ...state, groupThreads: [...state.groupThreads, action.payload] };
    },
    updateLastPersonalMessage: (state, action) => {
      const { payload } = action;
      const isFile = payload?.metadata?.file;
      const isGroupThread = current(state.groupThreads).some((each) => each.id === payload.threadId);
      if (isGroupThread) {
        state.groupThreads = current(state.groupThreads).map((each) => {
          if (each.id === payload.threadId) {
            return {
              ...each,
              lastmessage: isFile ? '<p>Sent an attachment</p>' : payload.message,
              displayNames: [payload.sender.communicationUserId],
              lastMessageReceivedOn: payload.createdOn,
              msgId: payload.id,
              msgCount: each.msgCount + 1,
              type: 'text',
              file: payload?.metadata?.file || null,
              senderCommunicationId: payload.sender.communicationUserId
            };
          }
          return each;
        });
      } else {
        state.personalThreadsArr = current(state.personalThreadsArr).map((each) => {
          if (each.id === payload.threadId) {
            return {
              ...each,
              lastmessage: isFile ? '<p>Sent an attachment</p>' : payload.message,
              lastMessageReceivedOn: payload.createdOn,
              msgId: payload.id,
              msgCount: each.msgCount + 1,
              type: 'text',
              file: payload?.metadata?.file || null
            };
          }
          return each;
        });
      }
    },
    updateLastMessageEdited: (state, action) => {
      const { payload } = action;
      const isFile = payload?.metadata?.file;
      const isGroupThread = current(state.groupThreads).some((each) => each.id === payload.threadId);
      if (isGroupThread) {
        state.groupThreads = current(state.groupThreads).map((each) => {
          if (each.id === payload.threadId && each.msgId === payload.id) {
            return { ...each, lastmessage: isFile ? '<p>Sent an attachment</p>' : payload.message };
          }
          return each;
        });
      } else {
        state.personalThreadsArr = current(state.personalThreadsArr).map((each) => {
          if (each.id === payload.threadId && each.msgId === payload.id) {
            return { ...each, lastmessage: isFile ? '<p>Sent an attachment</p>' : payload.message };
          }
          return each;
        });
      }
    }
  }
});

export const acsChannelActions = acsChannels.actions;
export default acsChannels.reducer;
