import React from 'react';
import { Box, Typography } from '@mui/material';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';

function LinearProgressWithLabel(props: LinearProgressProps & { value: number, width?: string }) {
  const progressingColor = props.value >= 33 ? (props.value >= 66 ? 'success' : 'warning') : 'error';
  const setwidth = props.width ? props.width : 'auto'

  return (
    <Box display="flex" alignItems="center" gap="8px" width={setwidth}>
      <Box width="100%">
        <LinearProgress variant="determinate" color={progressingColor} {...props} />
      </Box>
      <Box minWidth={'35px'}>
        <Typography>{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

function LinearWithValueLabel() {
  const [progress, setProgress] = React.useState(10);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 10 : prevProgress + 10));
    }, 800);
    return () => {
      clearInterval(timer);
    };
  }, []);
}
export default LinearProgressWithLabel;
