import { createSlice } from "@reduxjs/toolkit"

const initialState: any = {
    data: '',
    loading: false,
    error: false,
    errorText: ''
}

const userIdSlice = createSlice({
    name: 'user Id',
    initialState,
    reducers: {
        userIdUpdate: (state, action) => {
            state.data = action.payload;
        }
    }
})

export default userIdSlice.reducer;

export const { userIdUpdate } = userIdSlice.actions;