import React, { useState } from 'react';
import {
    Button,
    Dialog,
    Typography,
    DialogContent,
    DialogActions,
} from '@mui/material';
import CommonModalHeader from '../../../../../components/DialogUi/CommonModalHeader';

interface RemoveUserFromGroupModalProps {
   RemoveUser: boolean;
    handleRemoveUser: (flag: boolean) => void;
}

const RemoveUserFromGroupModal: React.FC<RemoveUserFromGroupModalProps> = ({
   RemoveUser,
    handleRemoveUser
}) => {
    return(
        <Dialog
            open={RemoveUser} 
            onClose={() => handleRemoveUser(false)} 
            fullWidth
        >
            <CommonModalHeader 
                title={`Confirmation`}
                handleCloseModal={() => handleRemoveUser(false)}
            />
            <DialogContent>
                <Typography variant='h5' textAlign={'center'}>
                    Are you sure to remove <br /> this member?
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button fullWidth variant='outlined' color='secondary'>No</Button>
                <Button fullWidth variant='contained'>Yes, Remove</Button>
            </DialogActions>
        </Dialog>
    )
};

export default RemoveUserFromGroupModal;
