import React, { useEffect } from 'react';
import { Box, useTheme, Typography, useMediaQuery } from '@mui/material';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  profileBack: {
    cursor: 'pointer',
    '& svg':{ height: '20px' },
  }
});

function useResponsiveScreenSwitch() {
  const classes = useStyles();
  const navigate = useNavigate();
  const theme = useTheme();
  /** (sm) => (max-width: 600px) */
  const forMobile = useMediaQuery(theme.breakpoints.down('sm'));
  /** (sm) => (max-width: 900px) */
  // const forTablet = useMediaQuery(theme.breakpoints.down('md'));

  const [showContainerBoxRight, setShowContainerBoxRight] = React.useState(true);
  const [showContainerBoxLeft, setShowContainerBoxLeft] = React.useState(true);
  
  /** Mobile left On */
  const mobileLeftOn = () => {
    if (forMobile) {
      setShowContainerBoxLeft(true);
      setShowContainerBoxRight(false);
    } else {
      normalLeftRightOn();
    }
  };

  /** Mobile left On Right off */
  const mobileLeftOff = () => {
    if (forMobile) {
      setShowContainerBoxRight(true);
      setShowContainerBoxLeft(false);
    } else {
      normalLeftRightOn();
    }
  };

  /** Normal screen Left Right always On */
  const normalLeftRightOn = () => {
    setShowContainerBoxRight(true);
    setShowContainerBoxLeft(true);
  };

  /** Mobile to back button */
  const mobileBackBtn = (backTo, mobileClickBack) => {
    return (
      <>
        {forMobile ?
          <Box display={'flex'} mb={.5} alignContent={'center'} className={classes.profileBack} onClick={() => mobileClickBack(backTo)}>
            <ArrowBackIosNewOutlinedIcon />
            <Typography variant='h5' lineHeight={'normal'}>Back</Typography>
          </Box>
        : null}
      </>
    );
  };

  /** Mobile click Back */
  const mobileClickBack = (backTo) => {
    if (backTo === "home") {
      navigate("/app/redirect");
    } else if (backTo === "back") {
      navigate(-1);
    } else {
      mobileLeftOn();
    }
  };

  useEffect(() => {
    if (forMobile) {
      // If it's for mobile, set the appropriate state
      mobileLeftOn();
    } else {
      // If it's not for mobile, set the appropriate state
      normalLeftRightOn();
    }
  }, [forMobile]);

  return {
    forMobile,
    mobileLeftOn,
    mobileLeftOff,
    // mobileBackBtn,
    mobileClickBack,
    showContainerBoxRight,
    showContainerBoxLeft,
    mobileBackBtn: (backTo) => mobileBackBtn(backTo, mobileClickBack),
  }; // Return the values and functions
}

export default useResponsiveScreenSwitch;
