import React from 'react';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ProgressBar from 'react-customizable-progressbar';

interface ValuationProgressProps {
  score: number;
  height?: string;
}

const useStyles = makeStyles({
  progressContainer: {
    position: 'relative',
  }
});

const ValuationProgress: React.FC<ValuationProgressProps> = ({ score, height }) => {

  const classes = useStyles();
  const setWidth = '100%';
  const setHeight = height ? height : '100%';

  return (
    <Box
     className={classes.progressContainer}
      width={setWidth}
      height={setHeight}
    >
      <ProgressBar
        progress={score}
        radius={100}
        steps={100}
        rotate={160}
        cut={140}
        strokeWidth={20}
        trackStrokeWidth={20}
        trackStrokeColor="#c2edda"
        trackStrokeLinecap={'round'}
        pointerRadius={12}
        pointerStrokeWidth={3}
        pointerFillColor="#79DFC1"
        pointerStrokeColor="#FFFFFF"
        strokeColor="#1AA179"
      />

      <Box position={"absolute"} textAlign={"center"} top={"60%"} left={"50%"}
        sx={{ transform: 'translate(-50%, -50%)'}}
      >
        <Typography variant={'h1'} fontWeight={'600'} fontSize="60px" lineHeight={'normal'}>
          {score}
        </Typography>
        <Typography fontWeight={'600'} mt={-1}>
          Avg.Score
        </Typography>
      </Box>
    </Box>
  );
};

export default ValuationProgress;
