import React, { useState } from 'react';
import {
  Box,
  Dialog,
  Divider,
  Typography,
  DialogContent,
} from '@mui/material';
import ChatAvatarComponent from '../chat/ChatAvatarComponent';
import _ from 'lodash'; // Import lodash
import RoleTypeTag from '../../components/RoleTypeTag';
import CallBookingModal from '../CallBookingModal';
import CommonModalHeader from '../../../../components/DialogUi/CommonModalHeader';
import { useAppSelector } from '../../../../redux/hooks';
import { CalendarIcon, ChatIcon } from '../../../../utils/Icons';
import { formatNumberWithCommas } from '../../components/common';
import { checkOrfium } from '../../../../utils/orfiumFunctions';

interface UserPeersPopupProps {
  open: boolean;
  onClose: () => void;
  peersList: any[];
  onProfileClick: (mid: string) => void;
  onChatClick: (each: any) => void;
  onCalendarClick: (each: any) => void; // Pass the correct function
  communityMembers: any[]; // Add communityMembers as a prop
  navigate: (path: string, state: any) => void; // Add navigate function as a prop
}

const UserPeersPopup: React.FC<UserPeersPopupProps> = ({
  open,
  onClose,
  peersList,
  onProfileClick,
  onChatClick,
  onCalendarClick, // Ensure the correct function is passed
  communityMembers,
  navigate,
}) => {
  const [openCalendarModal, setOpenCalendarModal] = useState(false);
  const [displayName, setDisplayName] = useState('');
  const [participantUser, setParticipantUser] = useState<any[]>([]); // Specify the type as an array of any
  const [bookedCallDetails, setBookedCallDetails] = useState<any>('');
  const commList = useAppSelector((state) => state.getCommunityMembers.data);
  const selectedGroupId = useAppSelector((state) => state.selectedGroup.data);
  const groupId = selectedGroupId.id || '';
  const oid = useAppSelector((state) => state.orgDetails.data?.oid);

  const handleCalendarOpen = (each: any) => {
    // Implement the logic to open the calendar modal and set relevant state here
    setDisplayName(each?.displayName); // Set the display name
    setParticipantUser([each]); // Set participant user(s)
    setBookedCallDetails(''); // Set booked call details (if needed)
    setOpenCalendarModal(true); // Open the calendar modal
  };

  const handleCalendarClose = () => {
    // Implement the logic to close the calendar modal and reset state here
    setDisplayName('');
    setParticipantUser([]); // Reset the participantUser state to an empty array
    setBookedCallDetails('');
    setOpenCalendarModal(false);
  };

  const getBio = (mem: any) => {
    const { major = '', university = '' } = _.get(mem, 'bio.education', {});
    const { role = '', companyName = '' } = _.get(mem, 'bio.workHistory', {});
    return mem?.category === 'Student' ? formatBio(major, university) : formatBio(role, companyName);
  };

  const formatBio = (name1: string, name2: string) => {
    if (!name1 && !name2) {
      return '';
    }
    return name1?.concat(', ').concat(name2);
  };
  return (
    <>
      <Dialog open={open} onClose={onClose} fullWidth>
        {/* Dialog Header */}
        <CommonModalHeader
          title={`Peers in program (${formatNumberWithCommas(peersList?.length)})`}
          handleCloseModal={onClose}
        />
        <DialogContent>
          {peersList?.map((each, index) => {
            const bio = getBio(each);
            const cat = each?.category;

            const moveToUsercommunityProfile = (userId: string) => {
              const mem = communityMembers.find((item: { userId: string }) => item.userId === userId);
              const modifiedMem = { ...mem, id: _.get(mem, 'id.coId', '') };
              const allCommunityMembersList = communityMembers;
              if (mem) {
                navigate('/app/communitymember', {
                  state: { mem: modifiedMem, allCommunityMembersList: commList },
                });
              }
            };

            return (
              <React.Fragment key={index}>
                <Box display="flex" gap="8px" justifyContent="space-between">
                  <Box
                    flex={1}
                    gap={"8px"}
                    display={"flex"}
                    overflow={'hidden'}
                    alignItems={"center"}
                    className={"cursor-pointer"}
                    onClick={() => onProfileClick(each.mid)}
                  >
                    <Box>
                      <ChatAvatarComponent
                        height="48px"
                        width="48px"
                        firstLetter={each?.displayName?.slice(0, 1)}
                        image={each?.headshot}
                        type="noStatus"
                        onClickOnUserIcon={() => moveToUsercommunityProfile(each.mid)}
                      />
                    </Box>
                    <Box flex={1} overflow={'hidden'}>
                      <Box display="flex" gap="8px" alignItems="center">
                        <Typography variant="h5" noWrap>
                          {each?.displayName}
                        </Typography>
                        <RoleTypeTag category={cat} />
                      </Box>
                      <Box>
                        <Typography noWrap>{getBio(each)}</Typography>
                      </Box>
                    </Box>
                  </Box>
                  {!checkOrfium(oid, selectedGroupId?.id) && (
                    <Box display="flex" alignItems="center" gap="8px">
                      <ChatIcon size={21} onClick={() => onChatClick(each)} />
                      <CalendarIcon size={21} onClick={() => handleCalendarOpen(each)} />
                    </Box>
                  )}
                </Box>
                {index !== peersList.length - 1 && <Divider sx={{ my: 2 }} />}
              </React.Fragment>
            );
          })}
        </DialogContent>
      </Dialog>

      {/* Call booking modal */}
      <CallBookingModal
        handleCloseModal={handleCalendarClose}
        openFlag={openCalendarModal}
        chatProfileName={displayName}
        threadParticipantsUsers={participantUser}
        chatType="Chats"
        pageName="Mentor"
        bookedCallDetails={bookedCallDetails}
      />
    </>
  );
};

export default UserPeersPopup;
