import React, { useEffect, useState } from 'react';
import { Box, Grid, Switch, Checkbox, Typography } from '@mui/material';
import { lockIcon, formatCapRoleFromDisplayRoles } from '../../../../../components/common';
import MyTooltip from '../../../../../components/MyTooltip';

const requiredFields = [
  { label: 'First name' },
  { label: 'Last name' },
  { label: 'Email' },
  { label: 'Member type' },
  { label: 'Time zone' }
  // { label: 'Industries' },
  // { label: 'Topics' },
];

const switchHeadings = ['Mentors', 'Mentees', 'Admin']; // 'Administrators' I'm using 'Admin' for the space
const checkboxHeadings = ['Include', 'Mandatory'];

interface Props {
  setFieldValues: React.Dispatch<
    React.SetStateAction<
      {
        label: string;
        name: string;
        mentor: boolean;
        mentee: boolean;
        admin: boolean;
        mandatory: boolean;
      }[]
    >
  >;
  fieldValues: {
    label: string;
    name: string;
    mentor: boolean;
    mentee: boolean;
    admin: boolean;
    mandatory: boolean;
  }[];
  setOptionalFieldsUpdateFlag: any;
  groupDisplayRoles: any;
}

const Fields = ({ fieldValues, setFieldValues, groupDisplayRoles, setOptionalFieldsUpdateFlag }: Props) => {
  const [switchStates, setSwitchStates] = useState<boolean[][]>(() => generateSwitchStates());

  useEffect(() => {
    setSwitchStates(generateSwitchStates());
  }, [fieldValues, switchHeadings]);

  function generateSwitchStates() {
    return switchHeadings.map((_, columnIndex) => {
      return fieldValues.map((fieldValue, rowIndex) => {
        return (
          (fieldValue.mentor && columnIndex === 0) ||
          (fieldValue.mentee && columnIndex === 1) ||
          (fieldValue.admin && columnIndex === 2)
        );
      });
    });
  }

  /** Switch off/on */
  const handleSwitchChange = (columnIndex: number, switchIndex: number) => {
    setSwitchStates((prevStates) => {
      const newSwitchStates = [...prevStates];
      newSwitchStates[columnIndex][switchIndex] = !newSwitchStates[columnIndex][switchIndex];
      const updatedFieldValues = fieldValues.map((field, idx) => {
        if (idx === switchIndex) {
          const updatedField = {
            ...field,
            mentor: columnIndex === 0 ? newSwitchStates[columnIndex][switchIndex] : field.mentor,
            mentee: columnIndex === 1 ? newSwitchStates[columnIndex][switchIndex] : field.mentee,
            admin: columnIndex === 2 ? newSwitchStates[columnIndex][switchIndex] : field.admin
          };
          return updatedField;
        }
        return field;
      });
      const updatedMandatoryValues = updatedFieldValues.map((field, index) => {
        const switchesForRow = newSwitchStates.map((column) => column[index]);
        const anySwitchOn = switchesForRow.some((switchState) => switchState);

        if (anySwitchOn) {
          return field;
        }
        return { ...field, mandatory: false };
      });
      setFieldValues(updatedMandatoryValues);
      return newSwitchStates;
    });
  };

  /** Check mandatory fields and toggle all switches */
  const handleMandatoryChange = ({ name, value }: { name: string; value: boolean }) => {
    setFieldValues((prevFieldValues) =>
      prevFieldValues.map((each, index) => {
        if (each.name === name) {
          if (!value) {
            const updatedField = { ...each, mandatory: false };
            return updatedField;
          } else {
            const switchesForRow = switchStates.map((column) => column[index]);
            const anySwitchEnabled = switchesForRow.some((field) => field);
            if (!anySwitchEnabled) {
              return each;
            } else {
              const updatedField = {
                ...each,
                mentor: switchStates[0][index],
                mentee: switchStates[1][index],
                admin: switchStates[2][index],
                mandatory: true
              };
              return updatedField;
            }
          }
        }
        return each;
      })
    );
  };

  return (
    <Box>
      <Typography variant="h5">Fields</Typography>
      <Grid container>
        {/* Switch heading */}
        <Grid item xs={4} />
        {switchHeadings.map((heading, columnIndex) => (
          <Grid item xs={2} key={columnIndex} textAlign="center">
            <Typography noWrap>{formatCapRoleFromDisplayRoles(heading, groupDisplayRoles)}</Typography>
          </Grid>
        ))}
        {/* Mandatory heading */}
        <Grid item xs={2} textAlign="center">
          <Typography noWrap>{checkboxHeadings[1]}</Typography>
        </Grid>
      </Grid>

      {/* Required fields */}
      {requiredFields.map((option, index) => (
        <Grid container key={index} alignItems="center">
          <Grid item xs={4}>
            <Box display="flex" alignItems="center" gap={0.5}>
              <Typography variant="body1" color="primary" noWrap>
                {option.label}
              </Typography>
              {lockIcon({})}
            </Box>
          </Grid>
          {/* Switchs */}
          {switchStates.map((_column, columnIndex) => (
            <Grid item xs={2} key={columnIndex} textAlign="center">
              <Switch checked={true} disabled={true} />
            </Grid>
          ))}

          {/* Mandatory checkbox */}
          <Grid item xs={2} textAlign="center">
            <Checkbox defaultChecked={true} disabled={true} />
          </Grid>
        </Grid>
      ))}

      {/* More fields */}
      {fieldValues.map((each, rowIndex) => (
        <Grid container key={each.name} alignItems="center">
          <Grid item xs={4} container alignItems="center" gap={0.5}>
            <Typography variant="body1" color="primary" noWrap>
              {each.label}
            </Typography>

            {/* Check if field value is 'company' */}
            {each.label === 'Company' && (
              <MyTooltip
                title="Only select field if your members are signing up to your program as part of a company"
                placement="bottom"
              />
            )}
          </Grid>

          {/* Switchs */}
          {switchStates.map((_column, columnIndex) => (
            <Grid item xs={2} key={columnIndex} textAlign="center">
              <Switch
                checked={switchStates[columnIndex][rowIndex]}
                onChange={() => {
                  handleSwitchChange(columnIndex, rowIndex);
                  setOptionalFieldsUpdateFlag(true);
                }}
              />
            </Grid>
          ))}

          <Grid item xs={2} textAlign="center">
            <Checkbox
              checked={each.mandatory}
              disabled={!switchStates.some((column) => column[rowIndex])} // Disable if no switch is enabled
              onChange={(event) => {
                handleMandatoryChange({ name: each.name, value: event.target.checked });
                setOptionalFieldsUpdateFlag(true);
              }}
            />
          </Grid>
        </Grid>
      ))}
    </Box>
  );
};

export default Fields;
