import { ORFIUM, ORFIUM_GROUP_ID } from './constants';

// Reusable function to check oid and currentGroupId
export const checkOrfium = (oid: string | undefined, currentGroupId: string | undefined): boolean => {
  return oid === ORFIUM && currentGroupId === ORFIUM_GROUP_ID;
};

export function getOrfiumLevelName(
  oid: string | undefined,
  currentGroupId: string | undefined,
  levelIndex: number
): string {
  if (checkOrfium(oid, currentGroupId)) {
    switch (levelIndex) {
      case 1:
        return 'Overview';
      case 2:
        return 'Part 1 - November 2024';
      case 3:
        return 'Part 2 - February 2025';
      case 4:
        return 'Part 3 - April 2025';
      case 5:
        return 'Part 4 - July 2025';
      case 6:
        return 'Part 5 - September 2025';
      case 7:
        return 'Bonus Content';
      default:
        return `Level ${levelIndex}`;
    }
  }
  // If oid and currentGroupId don't match, return default format
  return `Level ${levelIndex}`;
}
