/* eslint-disable prefer-destructuring */
/* eslint-disable radix */
/* eslint-disable no-duplicate-imports */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import _ from 'lodash';
import { Box, Radio, Switch, Typography, FormControlLabel } from '@mui/material';

import { useSearchParams } from 'react-router-dom';

import { getUserDetails } from '../../../../../../../utils/orgName';
import ROLES from '../../../../../../../utils/roles';

const BasicSettingsTasks = ({
  includeValue,
  setIncludeValue,
  allowEditing,
  setAllowEditing,
  setBasicUpdateFlag
}: any) => {
  const [searchParams] = useSearchParams();

  const role = localStorage.getItem('role') || '';
  let locValue = getUserDetails().location;
  if (role === ROLES.platAdmin) {
    locValue = searchParams.get('loc') || '';
  }

  // const [includeValue, setIncludeValue] = React.useState(false);
  // const [allowEditing, setAllowEditing] `= React.useState(false);
  const [selectedTaskIncludeValue, setSelectedTaskIncludeValue] = useState<string | null>(null);
  const handleRadioChangeTaskInclude = (value: string | null | undefined = null) => {
    setSelectedTaskIncludeValue(value === selectedTaskIncludeValue ? null : value);
  };

  return (
    <Box>
      <Typography variant="h5" mb={2}>
        Development Plan
      </Typography>
      <Box display="flex" flexDirection="row">
        <Box display="flex" alignItems="center" width="25%">
          <Switch
            checked={includeValue}
            onChange={(e) => {
              setIncludeValue(e.target.checked);
              setBasicUpdateFlag(true);
            }}
            name="include"
          />
          <Typography pl={1} color="primary">
            Include
          </Typography>
        </Box>
        {/* <Box>
          <FormControlLabel
            value="1"
            control={
              <Radio checked={selectedTaskIncludeValue === '1'} onChange={() => handleRadioChangeTaskInclude('1')} />
            }
            label={<Typography color="primary">Allow editing</Typography>}
          />
          <FormControlLabel
            value="2"
            control={
              <Radio checked={selectedTaskIncludeValue === '2'} onChange={() => handleRadioChangeTaskInclude('2')} />
            }
            label={<Typography color="primary">Do not allow editing</Typography>}
          />
        </Box> */}
      </Box>

      <Box display="flex" alignItems="center" width="30%" mt={2}>
        <Switch
          checked={allowEditing}
          onChange={(e) => {
            setAllowEditing(e.target.checked);
            setBasicUpdateFlag(true);
          }}
          name="allowEditing"
        />
        <Typography pl={1} color="primary">
          Allow Editing
        </Typography>
      </Box>
    </Box>
  );

  /** End Date section */

  /** loader */
};

export default BasicSettingsTasks;
