import { Tooltip, TooltipProps } from "@mui/material";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

type MyTooltipProps = {
    title?: string;
    iconSize?: string;
    iconColor?: string;
    gapleft?: number;
    placement?: TooltipProps['placement'];
};

const MyTooltip: React.FC<MyTooltipProps> = ({ title, iconColor, iconSize, gapleft, placement }) => {
    const tooltipPlacement = placement ? placement : 'top';
    const color = iconColor ? iconColor :'#ABB5BE';
    const size = iconSize ? iconSize : '14px';

    return (
        <Tooltip title={title} placement={tooltipPlacement} arrow>
            <InfoOutlinedIcon
                sx={{
                    color: color,
                    width: size,
                    cursor: 'pointer',
                    marginLeft: gapleft,
                }}
            />
        </Tooltip>
    );
}

export default MyTooltip;

