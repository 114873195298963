import { useAppSelector } from '../redux/hooks';

const useGoalProgress = (levels: any) => {
  let taskCompleted = 0;
  let totalTasks = 0;
  if (levels) {
    levels.forEach((level: any) => {
      if (level.tasks) {
        level.tasks?.forEach((task: any) => {
          if (task.menteeAsmtAns === 'Yes') {
            taskCompleted += 1;
          }
          totalTasks += 1;
        });
      }
    });
  }
  const value = Math.round((taskCompleted / totalTasks) * 100);
  return isNaN(value) ? 0 : value;
};

export default useGoalProgress;
