import _ from 'lodash';
import { useAppSelector } from '../redux/hooks';
import ROLES from '../utils/roles';

interface IsocialMediaData {
  [key: string]: string;
  facebook: string;
  instagram: string;
  youtube: string;
  twitter: string;
  linkedIn: string;
}

interface ISettings {
  gender?: boolean;
  country?: boolean;
  firstGenerationStudent?: boolean;
  ethnicity?: boolean;
  dob?: boolean;
  disabilityType?: boolean;
  category?: boolean;
  timezone?: boolean;
  workHistory?: boolean;
  hobbiesInterests?: boolean;
  educationHistory?: boolean;
  company?: boolean;
  videoIntro?: boolean;
}

const useProfileDetails = (): {
  admins: string[];
  mentors: string[];
  videoIntro: string;
  bio: Object;
  mentee: string[];
  settings: ISettings;
  role: Number;
  personalDetails: any;
  email: string;
  country: string;
  firstName: string;
  lastName: string;
  timezone: string;
  headshot: string;
  dob: string;
  phoneNumber: string;
  phonePrefix: string;
  gender: string;
  ethnicity: string;
  firstGenStudent: string;
  isActive: boolean;
  disability: string[];
  category: string;
  hobbies: string[];
  social: IsocialMediaData;
  communicationId: string;
  ftue: boolean;
  bioData: string;
  lastseen: { id: string; name: string; role: string; active: boolean } | undefined;
  upVote: number;
  topics: string[];
  selectedTopics: string[];
  industries: string[];
  selectedIndustries: string[];
  isSettingsAllowed: boolean;
  gettingStarted: {
    editTasks: boolean;
    nonEditTasks: boolean;
  };
} => {
  const userData = useAppSelector((state) => state.userProfile.data);
  const personalDetails = userData?.personal_details;
  const email = personalDetails?.email || '';
  const country = personalDetails?.country || '';
  const firstName = personalDetails?.firstName || '';
  const lastName = personalDetails?.lastName || '';
  const timezone = personalDetails?.timezone || '';
  const headshot = personalDetails?.headshot || '';
  const dob = personalDetails?.dob || '';
  const phoneNumber = personalDetails?.phoneNumber || '';
  const phonePrefix = personalDetails?.phonePrefix || '';
  const gender = personalDetails?.gender || '';
  const ethnicity = personalDetails?.ethnicity || '';
  const firstGenStudent = personalDetails?.firstGenStudent || '';
  const isActive = personalDetails?.isActive || false;
  const disability = personalDetails?.disability || [];
  const category = personalDetails?.category || '';
  const hobbies = personalDetails?.hobbies || [];
  const social = personalDetails?.social || {
    facebook: '',
    instagram: '',
    youtube: '',
    twitter: '',
    linkedIn: ''
  };
  const bioData = personalDetails?.bio || '';
  const communicationId = personalDetails?.communicationId || '';
  const settings = userData?.settings || {
    gender: false,
    country: false,
    firstGenerationStudent: false,
    ethnicity: false,
    dob: false,
    disabilityType: false,
    category: false,
    timezone: false,
    workHistory: false,
    educationHistory: false,
    hobbiesInterests: false,
    company: false
  };
  const bio = userData?.bio || {};
  const role = userData?.role || 0;
  const admins = userData?.admins || [];
  const mentee = userData?.mentees || [];
  const mentors = userData?.mentors || [];
  const videoIntro = userData?.videoIntro || '';
  const topics = userData?.topics || [];
  const selectedTopics = userData?.selectedTopics || [];
  const industries = userData?.industries || [];
  const selectedIndustries = userData?.selectedIndustries || [];
  /** Onboarding popups */
  const ftue = _.get(personalDetails, 'ftue', true); // Comment for onboarding popups
  // const ftue = true; // Uncomment for onboarding popups

  const lastseenObj = userData?.lastseen;
  let lastseen;
  if (lastseenObj && Object.keys(lastseenObj).length > 0) {
    lastseen = lastseenObj;
  } else {
    lastseen = undefined;
  }
  const upVote = userData?.upVote || 0;
  const userRole = localStorage.getItem('role') || '';
  const isSettingsAllowed = !(userRole === ROLES.platAdmin || userRole === ROLES.orgAdmin);
  const gettingStarted = userData?.gettingStarted || {
    editTasks: false,
    nonEditTasks: false
  };
  return {
    admins: admins,
    bio: bio,
    mentee: mentee,
    mentors: mentors,
    videoIntro: videoIntro,
    role: role,
    settings: settings,
    personalDetails: personalDetails,
    email: email,
    country: country,
    firstName: firstName,
    lastName: lastName,
    timezone: timezone,
    headshot: headshot,
    dob: dob,
    phoneNumber: phoneNumber,
    phonePrefix: phonePrefix,
    gender: gender,
    ethnicity: ethnicity,
    firstGenStudent: firstGenStudent,
    isActive: isActive,
    disability: disability,
    category: category,
    hobbies: hobbies,
    social: social,
    communicationId,
    ftue,
    bioData,
    lastseen,
    upVote,
    topics,
    selectedTopics,
    industries,
    selectedIndustries,
    isSettingsAllowed,
    gettingStarted
  };
};

export default useProfileDetails;
