import React from 'react';
import { Box, Stack } from '@mui/material';
import ChatAvatarComponent from '../chat/ChatAvatarComponent';
import MySkeleton from '../../../../components/Skeleton';

/** Left side Loader */
export const CommunityLoaderLeft = () => {
  return (
    <Box>
      {[...Array(8).keys()].map((each, idx) => {
        return (
          <Box display={'flex'} alignItems={'center'} gap={'8px'} borderBottom='1px solid #EFF0F4' p={2} key={idx}>
            <Box>
              <ChatAvatarComponent height='48px' width='48px' loading={true} type='noStatus' />
            </Box>
            <Box width='calc(100% - 56px)'>
              <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} gap={'5px'}>
                <MySkeleton variant="text" height={'16px'} width={150} />
                <MySkeleton variant='rounded' height={16} width={70}/>
              </Box>
              <Box width={'100%'}>
                <MySkeleton variant="text" height={'14px'} width={'100%'} />
              </Box>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

/** Right side Loader */
export const CommunityLoaderRight = () => {
  return (
    <>
      <Box className="ProfileBox">
        <Box className="CommunityProfileBox">
          <Box className="ProfileCard">
            <Stack direction="row" alignItems="center" spacing={1}>
              <ChatAvatarComponent height='96px' width='96px' loading={true} type='noStatus' />
            </Stack>
            <Box>
              <MySkeleton variant="text" width={150} />
              <MySkeleton variant="text" width={200} />
              <MySkeleton variant="rounded"  height={16} width={70}/>
            </Box>
          </Box>

          <Box sx={{ textAlign: 'right' }}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <MySkeleton variant="rounded" height={30} width={122} />
              <MySkeleton variant="rounded" height={30} width={122} />
            </Stack>
          </Box>
        </Box>
        {/* (DA-2053) This part is hiodden to match the original content */}
        {/* <MySkeleton variant="rectangular" height={1} />
        <Box p={2}>
          <Box className='ProfileImageVideoSize'>
            <MySkeleton variant="rounded" height={'100%'} width={'100%'} />
          </Box>
        </Box> */}
      </Box>

      {Array.from({ length: 6 }, (_, index) => (
        <Box my={{ xs: 1, sm: 2 }} className="ProfileBox">
          <Box p={2}>
            <MySkeleton width={100} />
          </Box>
          <MySkeleton variant="rounded" height={1} width={'100%'} />
          <Box p={2}>
            <MySkeleton width={'100%'} />
          </Box>
        </Box>
      ))}
    </>
  );
};
