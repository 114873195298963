import { Typography } from '@mui/material';
import _ from "lodash";

export const formatBioFunction = (mem: any) => {
    
    const { category = '' } = _.get(mem, 'personal_details', {});

    const { major = '', university = '' } = _.get(mem, 'bio.education', {});

    const { role = '', companyName = '' } = _.get(mem, 'bio.workHistory', {});

    const formatBio = (name1: string, name2: string) => {
        if (!name1 && !name2) {
            return '';
        }
        return <Typography>{name1?.concat(', ').concat(name2)}</Typography>;
    };
    return category === 'Student' ? formatBio(major, university) : formatBio(role, companyName);
}
