import { Box, Typography } from "@mui/material";
import BriefcaseIcon from '../../../assets/images/briefcase.svg';
import GraduateIcon from '../../../assets/images/graduate.svg';

const IfNoEduWork = ({ WorkHistory = false, EducationHistory = false }) => {
    return (
        <Box py={2} textAlign="center" height='100%' display={'flex'} flexDirection={'column'} justifyContent={'center'}>
            <Box height='109px'>
                <img src={ WorkHistory ? BriefcaseIcon : GraduateIcon } alt="Briefcase Icon" width='109px' height='109px' />
            </Box>

            <Typography>No {WorkHistory ? 'work' : 'education'} history to display.</Typography>
        </Box>
    );
};

export default IfNoEduWork;
