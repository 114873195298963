import { useQuery, useQueryClient } from '@tanstack/react-query';
import { API } from '../api';
import { queryKeys } from './queryKeys';
import { useAppSelector } from '../redux/hooks';
import { getUserDetails } from '../utils/orgName';
// import { QueryKey } from "@tanstack/react-query";
import _ from 'lodash';
const useCallDetails = (page: number, pageSize: number) => {
  const { orgId } = getUserDetails();
  const queryClient = useQueryClient();
  const user_id = getUserDetails().userId;
  const { location } = getUserDetails();
  const userCommunicationId = getUserDetails().communicationUserId;
  const { data, isLoading, error, refetch } = useQuery({
    queryKey: [queryKeys.GET_USER_CALLS_DETAILS, user_id, userCommunicationId, page, pageSize],
    queryFn: () => {
      if (user_id && userCommunicationId) {
        return API.getScheduleCall(user_id, userCommunicationId, orgId, location, page, pageSize);
      } else {
        return null;
      }
    },

    enabled: false
  });

  const invalidateQuery = () =>
    queryClient.invalidateQueries({
      queryKey: [queryKeys.GET_USER_CALLS_DETAILS, user_id, userCommunicationId, page, pageSize]
    });
  const allTotelCount = _.get(data, 'data.allTotelCount', 0);
  return { data: _.get(data, 'data.data', []), allTotelCount, isLoading, error, refetch, invalidateQuery };
};
export default useCallDetails;
