import React from 'react';
import { Box, Typography } from '@mui/material';
import { roundOfNumber } from '../../components/common';
import { makeStyles } from '@mui/styles';
import ProgressBar from 'react-customizable-progressbar';

interface ProgressChartProps {
  percentage: number;
}

const useStyles = makeStyles({
  progressContainer: {
    position: 'relative',
    backgroundColor: '#11895E', //"#1AA179"
    boxShadow: '0px 2px 1px rgba(0, 0, 0, 0.20)',
    border: '2px solid #FFFFFF',
    borderRadius: "50%",
    overflow: "hidden"
  },
  progress: {
    marginTop: '5px',
    marginLeft: '1px'
  },
  secondProgress: {
    position: 'absolute',
    bottom: '8%',
    left: '124%',
    transform: 'translate(-100%, -90%)',
  },
});

const ProgressChart: React.FC<ProgressChartProps> = ({ percentage }) => {

  const classes = useStyles();
  let percent = roundOfNumber(percentage);
  const percentageValue = percent < 1 ? '0' : percent; //'-'
  const setWidth = '160px';
  const setHeight = '160px';

  return (
    <Box
      className={classes.progressContainer}
      width={setWidth}
      height={setHeight}
    >
      <ProgressBar
        progress={percentage}
        radius={65}
        steps={100}
        rotate={160}
        cut={140}
        strokeWidth={10}
        trackStrokeWidth={10}
        trackStrokeColor="#FFFFFF61"
        trackStrokeLinecap={'round'}
        pointerRadius={5}
        pointerStrokeWidth={3}
        pointerFillColor='#11895E' //"#1AA179"
        pointerStrokeColor="#FFFFFF"
        strokeColor="#FFFFFF"
        className={classes.progress}
      />

      <Box position={"absolute"} textAlign={"center"} top={"50%"} left={"50%"}
        sx={{ transform: 'translate(-50%, -50%)' }}
      >
        <Typography color="#FFFFFF" fontWeight={'600'} fontSize="34px" lineHeight={'normal'}>
          {percentageValue}%
        </Typography>
        <Typography fontWeight={'600'} color="#FFFFFF" noWrap lineHeight={'normal'}>
          Goal progress
        </Typography>
        {/* <Typography fontSize="12px" fontWeight={'600'} color="#FFFFFF" noWrap>
          51 days
        </Typography> */}
      </Box>

      {/* <ProgressBar
        progress={51}
        radius={95}
        steps={100}
        rotate={433}
        cut={325}
        strokeWidth={5}
        trackStrokeWidth={5}
        trackStrokeColor="#FFFFFF61"
        trackStrokeLinecap={'round'}
        strokeColor="#FFFFFF"
        counterClockwise
        className={classes.secondProgress} 
      /> */}
    </Box>
  );
};

export default ProgressChart;
