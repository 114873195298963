import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from '../../../api';

interface IInitialState {
  loading: boolean;
  data: number | undefined;
  error: boolean;
  errorText: string;
}

const initialState: IInitialState = {
  loading: false,
  data: undefined,
  error: false,
  errorText: ''
};
export const fetchMenteeXpPoints = createAsyncThunk('mentee/xppoints', async (data: { orgId: string, location: string, groupId: string }) => {
  const { orgId, location, groupId } = data;
  const response = await API.getMenteeInsights(orgId, location, groupId);
  return response.data?.xpPoints;
});

const menteeXpPointsSlice = createSlice({
  name: 'menteeXpPoints',
  initialState,
  reducers: {
    updateXpPoints: (state, action) => {
      state.data = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMenteeXpPoints.pending, (state) => {
        state.loading = true;
        state.data = undefined;
        state.error = false;
        state.errorText = '';
      })
      .addCase(fetchMenteeXpPoints.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
        state.error = false;
        state.errorText = '';
      })
      .addCase(fetchMenteeXpPoints.rejected, (state, action) => {
        state.loading = false;
        state.data = undefined;
        state.error = true;
        state.errorText = action.error.message || '';
      });
  }
});

export default menteeXpPointsSlice.reducer;
export const { updateXpPoints } = menteeXpPointsSlice.actions;
