// SatisfactionRating.jsx
import React from 'react';
import { Box, Typography, Divider, LinearProgress, Rating } from '@mui/material';
import filledstar from '../../../../../assets/images/filledratingstar.svg';
import { IRatingData } from '../../Program/components/program tabs/UserInsightsCards';

interface SatisfactionRatingProps {
  data: IRatingData | undefined; // Send the value like this [80, 60, 40, 20, 10];
}

const SatisfactionRating: React.FC<SatisfactionRatingProps> = ({ data }) => {
  const maxRating = 5;
  const totalRatings = 0;
  // const averageRating = data?.averageRating || 0;

  const defaultProgressData = Array.from({ length: maxRating }, () => 0);
  // const truncatedProgressData = data.slice(0, maxRating);

  // const paddedProgressData = [
  //   ...truncatedProgressData,
  //   ...Array.from({ length: maxRating - truncatedProgressData.length }, () => 0)
  // ];

  const ratings: any =
    Object.keys(data?.ratings || {}).length > 0
      ? data?.ratings
      : {
          '5stars': 0,
          '4stars': 0,
          '3stars': 0,
          '2stars': 0,
          '1stars': 0
        };

  // Convert the number to a string with one decimal point
  let roundedNumber: string | number = (data?.averageRating || 0).toFixed(1);

  // Convert the string back to a number if needed
  roundedNumber = parseFloat(roundedNumber);

  return (
    <Box className="CardBox">
      <Box p={2}>
        <Typography variant="h6">Satisfaction Rating</Typography>
      </Box>
      <Divider />
      <Box p={2} height="298px" display="flex" flexDirection="column" justifyContent="center">
        <Box display="flex" justifyContent="space-evenly" alignItems="center">
          <Box>
            <Typography variant="h1" fontSize="60px" noWrap textAlign="center">
              {roundedNumber}
            </Typography>
            <Typography textAlign="center">Out of {maxRating}</Typography>
          </Box>
          <Box display="flex" flexDirection="column" alignItems="flex-end" gap="20px">
            {Object.keys(ratings).map((progress, index) => (
              <Box key={index} display="flex" alignItems="center" justifyContent="space-between" gap="8px">
                <Box display="flex" flexDirection="row-reverse">
                  <Rating
                    value={maxRating - index}
                    max={maxRating - index}
                    icon={<img src={filledstar} alt={`Filled Star for ${maxRating - index} rating`} />}
                    readOnly
                    sx={{ gap: '8px' }}
                  />
                </Box>
                <Box width="100px">
                  <LinearProgress
                    color="secondary"
                    variant="determinate"
                    value={data?.totalRatings === 0 ? 0 : (ratings[progress] / (data?.totalRatings || 1)) * 100}
                    sx={{ '& .MuiLinearProgress-bar': { backgroundColor: '#FEB272' } }}
                  />
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
        <Box pt={2}>
          <Typography textAlign="center">{data?.totalRatings || '0'} Total Rating(s)</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default SatisfactionRating;
