import React from 'react';
import { Box, Grid } from '@mui/material';
import useResponsiveScreenSwitch from '../../../../utils/useResponsiveScreenSwitch';
import MySkeleton from '../../../../components/Skeleton';

const CalendarLoader = () => {
    const { forMobile } = useResponsiveScreenSwitch();
    return (
        <Box className="CallsTableBox">
            <Grid container spacing={{ xs: 2, md: 1 }} className="CallsTableRow">
                {/* Calendar icon */}
                <Grid item xs={12} md={1}>
                    {forMobile ? <MySkeleton width={60} height={18} bg='#FFFFFF'/> :
                        <MySkeleton variant="rounded" height={'80px'} width={'60px'} bg='#FFFFFF'/>
                    }
                </Grid>
                {/* User name */}
                <Grid item xs={12} md={3} display={"flex"} gap={"5px"}>
                    <Box>
                        <MySkeleton variant="rounded" height={48} width={48} bg='#FFFFFF'/>
                    </Box>
                    <Box display="flex" flexDirection={"column"} gap="5px" pt={.5} pr={2} alignItems="center" width={'100%'}>
                        <Box display="flex" gap="5px" alignItems="center" width={'100%'}>
                            <MySkeleton width={'100%'} height={16} bg='#FFFFFF'/>
                            <MySkeleton variant="rounded" width={'50%'} bg='#FFFFFF'/>
                        </Box>
                        <MySkeleton width={'100%'} height={16} bg='#FFFFFF'/>
                    </Box>
                </Grid>
                {/* Time */}
                <Grid item xs={12} sm={6} md={1.25}>
                    <Box display="flex" gap="8px" alignItems="center" width={'100%'}>
                        <MySkeleton variant="circular" width={20} height={20} bg='#FFFFFF'/>
                        <MySkeleton width={'80%'} bg='#FFFFFF' />
                    </Box>
                </Grid>
                {/* Call duration */}
                <Grid item xs={12} sm={6} md={1.25}>
                    <Box display="flex" gap="8px" alignItems="center" width={'100%'}>
                        {/* <img src={VideoIcon} /> */}
                        <MySkeleton variant="circular" width={20} height={20} bg='#FFFFFF'/>
                        <MySkeleton width={'80%'} bg='#FFFFFF'/>
                    </Box>
                </Grid>
                {/* Attendees */}
                <Grid item xs={12} md={2} display={"flex"} alignItems={"center"} gap={"10px"}>
                    <Box display={"flex"} >
                        <MySkeleton variant="rounded" width={25} height={25} bg='#FFFFFF' />
                        <MySkeleton variant="rounded" width={25} height={25} bg='#FFFFFF' sx={{ marginLeft:"-10px"}} />
                        <MySkeleton variant="rounded" width={25} height={25} bg='#FFFFFF' sx={{ marginLeft:"-10px"}} />
                    </Box>
                    <MySkeleton variant='text' height={16} width={'50%'} bg='#FFFFFF' />
                </Grid>
                {/* Btn */}
                <Grid item xs={12} md={3}>
                    <Box display="flex" gap="8px" alignItems="center" width={'100%'}>
                        <MySkeleton variant="rounded" width={'100%'} height={35} bg='#FFFFFF'/>
                        <MySkeleton variant="rounded" width={'100%'} height={35} bg='#FFFFFF'/>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default CalendarLoader;
