import React, { useCallback } from 'react';
import { Button } from 'antd';
import classNames from 'classnames';
import { ChatRecord } from '../chat-types';
import './chat-message-item.scss';
import { Avatar } from '@mui/material';
interface ChatMessageItemProps {
  record: ChatRecord;
  currentUserId: number;
  setChatUser: (userId: number) => void;
}
const ChatMessageItem = (props: ChatMessageItemProps) => {
  const { record, currentUserId, setChatUser } = props;
  const { message, sender, receiver, timestamp } = record;
  const { avatar } = sender;
  const isCurrentUser = currentUserId === sender.userId;
  const onAvatarClick = useCallback(() => {
    if (!isCurrentUser) {
      setChatUser(sender.userId);
    }
  }, [isCurrentUser, sender, setChatUser]);
  const chatMessage = Array.isArray(message) ? message : [message];

  const formattedTime = new Date(timestamp).toLocaleTimeString([], {
    hour: 'numeric',
    minute: '2-digit',
    //hour12: true, // Use 12-hour format with AM/PM
  });

  return (
    <div className={classNames('chat-message-item', { myself: isCurrentUser })}>
      {/* <Button className="chat-message-avatar" onClick={onAvatarClick} ghost shape="circle" size="large"> */}
      {/* avatar ? <img src={avatar} className="chat-message-avatar-img" alt="" /> : */}
      <Avatar sx={{width:'34px', height:'34px'}} src=''/>
      {/* </Button> */}
      <div className="chat-message-content">
        <div className={classNames('chat-message-info', { myself: isCurrentUser })}>
          <p className="noWrap-oneLine">{isCurrentUser ? 'You' : sender.name}</p>

          <p className="chat-message-receiver">
           {/* {isCurrentUser ? '' : sender.name} */}
            <span>To</span>
            <a href="#"
              onClick={(event) => {
                event.preventDefault();
                setChatUser(receiver.userId);
              }}
            >
              {receiver.userId === currentUserId ? 'me' : receiver.name}
            </a>
          </p>
          {/* <p className="chat-message-time">{new Date(timestamp).toLocaleTimeString()}</p> */}
          <p className="chat-message-time">{formattedTime}</p>
        </div>
        <ul className={classNames('chat-message-text-list', { myself: isCurrentUser })}>
          {chatMessage.map((text, index) => (
            <li className={classNames('chat-message-text')} key={index}>
              {text}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
export default ChatMessageItem;
