import React from 'react';
import { Stack, Tooltip, Typography } from '@mui/material';
import { formatNumberWithCommas } from './common';
import MySkeleton from '../../../components/Skeleton';
import upvotes from '../../../assets/images/upvotes.svg';
import dosenfav from '../../../assets/images/favicon.svg';
import thumbsdownimg from '../../../assets/images/thumbsdownimg.svg';
import GroupChatUsers from '../../../assets/images/GroupChatUsers.svg';
import greenthumbsup from '../../../assets/images/greenthumbsup.svg';
import useResponsiveScreenSwitch from '../../../utils/useResponsiveScreenSwitch';

interface UpvotesProps {
  likes?: number;
  icon?: string;
  width?: string;
  maxWidth?: string;
  printArea?: string;
  toolTip?: string;
  usertype?: string;
  loading?: boolean;
}

const Upvotes: React.FC<UpvotesProps> = ({
  likes,
  icon,
  width,
  maxWidth,
  printArea,
  toolTip,
  usertype,
  loading
}) => {
  /** For responsive */
  const { forMobile } = useResponsiveScreenSwitch(); // Use the hook

  const prinIcon = icon === 'xpPoint' ? dosenfav : icon === 'group' ? GroupChatUsers : icon === 'greenThumbsup' ? greenthumbsup : icon === 'dislike' ? thumbsdownimg : upvotes;
  const fontColor = printArea === 'myMentorCard' ? '#11895E' : printArea === 'header' && forMobile ? '#FFFFFF' : 'secondary';
  const boxBorder = printArea === 'myMentorCard' ? '1px solid #11895E' : printArea === 'header' && forMobile ? '1px solid transparent' : '1px solid #EFF0F4';

  /** If tooltip require */
  let toolTipText = '';
  if (toolTip === 'true') {
    toolTipText =
      icon === 'xpPoint'
        ? 'These are your experience points (XP) - You earn them by using your Dosen account eg setting up your account, sending messages, having calls, reading library articles'
        : 'These are your Upvotes - Other people in your program can recognize your work by ‘upvoting’ you after calls or from your profile';
  }

  return (
    loading ? 
      <MySkeleton variant='rectangular'  width={50} height={16} sx={{borderRadius: '5px'}}/>
    :
    <Tooltip title={toolTipText} arrow>
      <Stack
        direction="row"
        alignItems="center"
        border={boxBorder}
        spacing={0.5}
        px={0.5}
        borderRadius={0.5}
        width={width ? width : 'fit-content'}
        maxWidth={maxWidth ? maxWidth : 'fit-content'}
      >
        <img alt='' src={prinIcon} width="11" height="11" />
        <Typography fontWeight="600" fontSize="12px" color={fontColor} noWrap>
          {formatNumberWithCommas(likes)}
        </Typography>
      </Stack>
    </Tooltip>
  );
};

export default Upvotes;
