import React from 'react';
import { Dialog, DialogActions, DialogContent, Typography, Button } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import CommonModalHeader from '../../../components/DialogUi/CommonModalHeader';

interface ConfirmationModalProps {
    open: boolean;
    onClose: () => void;
    title?: string;
    message?: string;
    onConfirm: (flag: boolean) => void;
    confirmText?: string;
    cancelText?: string;
    confirmLoading?: boolean;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
    open,
    onClose,
    title = "Confirmation",
    message = "Are you sure you want to perform this </br> action?",
    onConfirm,
    confirmText = "Yes, Remove",
    cancelText = "Cancel",
    confirmLoading = false,
}) => {
    return (
        <Dialog open={open} onClose={onClose}>
            {/* Common Modal Header */}
            <CommonModalHeader
                title={title}
                handleCloseModal={onClose}
            />

            {/* Modal Content */}
            <DialogContent>
                <Typography
                    variant='h5'
                    textAlign={'center'}
                    dangerouslySetInnerHTML={{ __html: message }}
                />
            </DialogContent>

            {/* Modal Actions */}
            <DialogActions>
                <Button fullWidth color="secondary" variant="outlined" onClick={onClose}>
                    {cancelText}
                </Button>
                <LoadingButton
                    color="primary"
                    variant="contained"
                    fullWidth
                    loading={confirmLoading}
                    onClick={() => onConfirm(true)}
                >
                    {confirmText}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmationModal;
