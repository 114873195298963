/* eslint-disable prefer-destructuring */
/* eslint-disable react-hooks/exhaustive-deps */
import AppRoutes from './routes/AppRoutes';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from './utils/theme';
import './App.css';
// import './custom.css';
import { Auth0Provider } from '@auth0/auth0-react';
import axios from 'axios';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from './redux/hooks';
import AppLoader from './components/AppLoader';
import { fetchOrgId } from './redux/slices/orgId/orgIdSlice';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { fetchAcsToken } from './redux/slices/acs-token/acsTokenSlice';
import { getUserDetails } from './utils/orgName';
import _ from 'lodash';
import { persistor, store } from './redux/store';
import DialogSelector from './components/DialogUi/DialogSelector';
import { checkSession } from './utils/checkSeesion';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';

export const queryClient = new QueryClient();
const role = localStorage.getItem('role');

axios.interceptors.request.use((request: any) => {
  const tokens = localStorage.getItem('tokens') ? JSON.parse(localStorage.getItem('tokens') || '') : {};
  const { location = 'us' } = request.headers;
  if (!request.url.includes('/organization/') && checkSession()) {
    localStorage.clear();
    window.location.replace('/');
  }

  const orgLocation = localStorage.getItem('orgLocation') || '';

  const req = {
    ...request,
    headers: {
      ...request.headers,
      authorization: `Bearer ${tokens.access_token}`,
      idtoken: tokens.id_token,
      location: orgLocation ? orgLocation : location
      // location: orgLocation?.toLowerCase()
    }
  };
  return req;
});

axios.interceptors.response.use(
  (response: any) => {
    return response;
  },
  async (error) => {
    const messages = ['Session has expired', 'Unauthorized', 'Unauthorized Access'];
    if (_.get(error, 'response.data.code') === 401 && messages.includes(_.get(error, 'response.data.message'))) {
      localStorage.removeItem('persist:root');
      persistor.purge();
      localStorage.removeItem('lastseen');
      // TODO: need to handle this separately
      // window.location.replace('/');
      return;
    }
    const state = store.getState();
    const acsTokenLoading = state.acsToken.loading;

    if (!acsTokenLoading) {
      if (
        _.get(error, 'response.request.responseURL').includes('/api/azureToken') &&
        _.get(error, 'response.status') === 401
      ) {
        const { communicationUserId } = getUserDetails();
        if (communicationUserId) {
          store.dispatch(fetchAcsToken({ communicationId: communicationUserId, tag: '#self' }));
        }
      } else if (
        _.get(error, 'response.request.responseURL').includes('/api/getChatThread') &&
        _.get(error, 'response.status') === 401
      ) {
        const { communicationUserId } = getUserDetails();
        if (communicationUserId) {
          store.dispatch(fetchAcsToken({ communicationId: communicationUserId, tag: '#self' }));
        }
      }
    }

    throw error;
  }
);

function App() {
  const urlParams = new URLSearchParams(window.location.search);
  const invitation = urlParams.get('invitation');
  const orgName = urlParams.get('organization_name') || '';
  const organization = urlParams.get('organization');
  let domain = urlParams.get('org') || '';
  const hashURL = window.location.hash;

  /**
   * this if condition is to logout the previous user in case of new invitation
   */
  if (localStorage.getItem('tokens') && invitation) {
    localStorage.clear();
    window.location.reload();
  }

  if (hashURL.includes('org=')) {
    const hashIndex: number = hashURL.indexOf('#');
    const hashPart: string = hashIndex !== -1 ? hashURL.slice(hashIndex + 1) : '';
    const orgRegex = /org=([^&]*)/;
    const match = hashPart.match(orgRegex);
    domain = match ? match[1] : '';
  }
  let userType = '';
  const orgDetails = useAppSelector((state) => state.orgIdReducer);
  const dispatch = useAppDispatch();

  const isInvited = invitation && orgName && organization;

  if (!domain && !isInvited) {
    userType = 'platAdmin';
  }

  if (invitation) {
    localStorage.setItem('invitation', JSON.stringify(invitation));
    localStorage.setItem('orgName', orgName);
  }

  const providerConfig = {
    domain: process.env.REACT_APP_AUTH0_DOMAIN || '',
    redirectUri: window.location.origin,
    clientId: process.env.REACT_APP_AUTH0_CLIENT_ID || '',
    audience: process.env.REACT_APP_AUTH0_AUDIENCE || ''
  };

  const config2 = {
    ...providerConfig,
    organization: orgDetails.data?.authOrgId || ''
  };

  const inviteConfig = {
    ...providerConfig,
    invitation,
    organization
  };

  const render = (config: any) => {
    return (
      <ThemeProvider theme={theme}>
        <Auth0Provider cacheLocation="localstorage" useCookiesForTransactions={true} {...config}>
          <QueryClientProvider client={queryClient}>
            <DndProvider backend={HTML5Backend}>
              <AppRoutes />
              <DialogSelector />
            </DndProvider>
            {/* <ReactQueryDevtools /> */}
          </QueryClientProvider>
        </Auth0Provider>
      </ThemeProvider>
    );
  };

  useEffect(() => {
    if (userType === '' && !isInvited) {
      localStorage.setItem('orgName', domain);
      dispatch(fetchOrgId(domain));
    }
  }, [orgName]);

  const checkRole = () => {
    if (userType === 'platAdmin') {
      return render(providerConfig);
    } else {
      if (orgDetails.error) {
        return <div>{orgDetails.errorText}</div>;
      }
      if (orgDetails.loading) {
        return null;
      }
      if (orgDetails.data) {
        return render(config2);
      }
    }
  };

  return (
    <>
      {isInvited ? render(inviteConfig) : orgDetails.loading ? <AppLoader /> : checkRole()}
      <ToastContainer />
    </>
  );
}

export default App;
