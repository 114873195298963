/* eslint-disable @typescript-eslint/prefer-optional-chain */
import {
  Grid,
  Chip,
  Checkbox,
  TextField,
  InputLabel,
  FormControl,
  Autocomplete,
  FormHelperText,
  FormControlLabel,
  createFilterOptions
} from '@mui/material';
import _, { isEmpty } from 'lodash';

interface FilmOptionType {
  inputValue?: string;
  value?: string;
  type?: string;
}

const filter = createFilterOptions<FilmOptionType>();

const WorkHistoryDailogBox = ({
  values,
  setCompanyKeyword,
  setValues,
  setIWorkHistoryError,
  iWorkHistoryError,
  allCompanyList,
  setIndustryKeyword,
  industryUniqueList,
  handleChange,
  allRoleValues,
  setWorkHistoryState,
  workHistoryState,
  handleRoleType,
  handleCheckbox,
  openCompanyAutocomplete,
  setOpenCompanyAutocomplete,
  handleInputChange,
  filteredCompanies,
  companyInput,
  openRoleAutocomplete,
  setOpenRoleAutocomplete
}: any) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <InputLabel>Company *</InputLabel>
        <FormControl fullWidth>
          <Autocomplete
            value={_.get(values, 'company_name', '')}
            open={openCompanyAutocomplete}
            onInputChange={(event, newInputValue) => {
              setOpenCompanyAutocomplete(true);
              const isInputEmpty = !newInputValue || newInputValue === '';
              if (isInputEmpty) {
                setOpenCompanyAutocomplete(false); // Close the dropdown when the input is empty
              }
              handleInputChange(event, newInputValue);
              setValues({
                ...values,
                company_name: newInputValue || ''
              });
            }}
            onChange={(event, newValue) => {
              setOpenCompanyAutocomplete(false);
              localStorage.removeItem('work');
              if (typeof newValue === 'string') return;

              if (newValue && newValue.value) {
                setValues({
                  ...values,
                  company_name: newValue?.value || ''
                });
              } else {
                setValues({
                  ...values,
                  company_name: newValue?.value || ''
                });
              }

              setIWorkHistoryError({
                ...iWorkHistoryError,
                company_name: undefined
              });
            }}
            filterOptions={(options, params) => {
              const filtered = _.filter(options, (option) =>
                option.value.toLowerCase().includes(params.inputValue.toLowerCase())
              );

              if (params.inputValue !== '' && filtered.length === 0) {
                filtered.push({
                  inputValue: params.inputValue,
                  value: `${params.inputValue}`
                });
              }

              return _.uniq(filtered);
            }}
            id="companyName"
            options={_.uniqBy(_.sortBy(filteredCompanies, 'value'), 'value')}
            getOptionLabel={(option) => {
              if (!option) {
                return '';
              } else if (typeof option === 'string') {
                return option;
              } else {
                return _.get(option, 'inputValue', _.get(option, 'value', ''));
              }
            }}
            selectOnFocus
            clearOnBlur={false}
            autoHighlight
            autoSelect
            handleHomeEndKeys
            renderOption={(props, option) => (
              <li key={_.get(option, 'value', '')} {...props}>
                {_.get(option, 'value', '')}
              </li>
            )}
            freeSolo={true}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                size="small"
                placeholder="Type or select your company"
                error={Boolean(_.get(iWorkHistoryError, 'company_name', false))}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    const newCompany = { inputValue: companyInput, value: companyInput };
                    setValues({
                      ...values,
                      company_name: newCompany.value || ''
                    });
                  }
                }}
              />
            )}
          />
        </FormControl>
        <FormHelperText error>{_.get(iWorkHistoryError, 'company_name', '')}</FormHelperText>
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputLabel>Industry *</InputLabel>
        <FormControl fullWidth>
          <Autocomplete
            value={_.get(values, 'industry', '')}
            onInputChange={(event, newInputValue) => {
              setIndustryKeyword(newInputValue || '');
              setValues({ ...values, industry: newInputValue || '' });
            }}
            onChange={(event, newValue) => {
              if (typeof newValue === 'string') return;
              if (newValue && newValue.value) {
                setValues({ ...values, industry: newValue?.value || '' });
              } else {
                const ind = _.find(industryUniqueList, { value: newValue?.value });
                setValues({ ...values, industry: ind });
              }
              setIWorkHistoryError({
                ...iWorkHistoryError,
                industry: undefined
              });
            }}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);
              if (params.inputValue !== '') {
                let existingOptionArr = options.filter((each) => {
                  return each.value.toLowerCase().startsWith(params.inputValue.toLowerCase());
                });
                if (existingOptionArr.length === 0) {
                  filtered?.push({
                    inputValue: params.inputValue,
                    value: `${params.inputValue}`
                  });
                }
              }
              return _.uniq(filtered);
            }}
            id="industryName"
            options={_.uniqBy(_.sortBy(industryUniqueList, 'value'), 'value')}
            getOptionLabel={(option) => {
              if (!option) {
                return '';
              } else if (typeof option === 'string') {
                return option;
              } else {
                return _.get(option, 'inputValue', _.get(option, 'value', ''));
              }
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            renderOption={(props, option) => (
              <li key={_.get(option, 'value', '')} {...props}>
                {_.get(option, 'value', '')}
              </li>
            )}
            freeSolo
            // className={industryKeyword ? 'MuiOutlinedInput-root Mui-focused' : ''}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                placeholder="Select One from the list *"
                error={Boolean(_.get(iWorkHistoryError, 'industry', false))}
              />
            )}
          />
        </FormControl>
        <FormHelperText error>{_.get(iWorkHistoryError, 'industry', '')}</FormHelperText>
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={<Checkbox checked={values.currentlyWorking} name="currentlyWorking" onChange={handleCheckbox} />}
          label="I currently work here"
        />
        {/* <FormGroup
                style={{
                  fontSize: '14px',
                  fontFamily: 'Open Sans',
                  fontWeight: '400',
                  color: '#68717A'
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox checked={values.currentlyWorking} name="currentlyWorking" onChange={handleCheckbox} />
                  }
                  label="I currently work here"
                />
              </FormGroup> */}
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputLabel>Start year (YYYY) *</InputLabel>
        <TextField
          name="start_date"
          type="text"
          placeholder="YYYY"
          variant="outlined"
          size="small"
          inputProps={{
            maxLength: 4,
            ninLength: 4
          }}
          value={_.get(values, 'start_date', '')}
          id="startYear"
          onChange={handleChange}
          error={Boolean(_.get(iWorkHistoryError, 'start_date', false))}
        />
        <FormHelperText error>{_.get(iWorkHistoryError, 'start_date', '')}</FormHelperText>
      </Grid>
      {!values?.currentlyWorking && (
        <Grid item xs={12} sm={6}>
          <InputLabel>End year (YYYY) *</InputLabel>
          <TextField
            name="end_date"
            type="text"
            placeholder="YYYY"
            variant="outlined"
            size="small"
            inputProps={{
              maxLength: 4,
              ninLength: 4
            }}
            value={values.end_date || ''}
            id="endYear"
            onChange={handleChange}
            error={Boolean(iWorkHistoryError?.end_date)}
          />
          <FormHelperText error>{iWorkHistoryError && iWorkHistoryError?.end_date}</FormHelperText>
        </Grid>
      )}
      <Grid item xs={12}>
        <InputLabel>Title *</InputLabel>
        <TextField
          name="title"
          type="text"
          placeholder="Enter Title"
          variant="outlined"
          size="small"
          value={_.get(values, 'title', '')}
          id="title"
          onChange={handleChange}
          error={Boolean(_.get(iWorkHistoryError, 'title', false))}
        />
        <FormHelperText error>{_.get(iWorkHistoryError, 'title', '')}</FormHelperText>
      </Grid>

      <Grid item xs={12}>
        <InputLabel>Responsibilities *</InputLabel>
        <FormControl fullWidth>
          <Autocomplete
            multiple
            clearOnBlur={true}
            selectOnFocus
            autoHighlight
            handleHomeEndKeys
            options={_.uniq(_.sortBy(allRoleValues, [(option) => _.toLower(option)]))}
            // filterOptions={(options, state) =>
            //   _.uniq(
            //     _.filter(options, (option) =>
            //       _.startsWith(_.toLower(option), _.toLower(_.get(state, 'inputValue', '')))
            //     )
            //   )
            // }
            filterOptions={(options, params) => {
              if (!Array.isArray(options)) {
                // eslint-disable-next-line no-param-reassign
                options = [];
              }
              const filtered = options.filter((option) =>
                option.toLowerCase().includes(params.inputValue.toLowerCase())
              );

              if (params.inputValue !== '') {
                filtered.push(params.inputValue);
              }
              return _.uniq(filtered);
            }}
            getOptionLabel={(option) => option}
            value={_.get(values, 'roleType', [])}
            id="responsibilities"
            open={openRoleAutocomplete}
            // onInputChange={(event, newInputValue) => {
            //   setOpenRoleAutocomplete(true);
            //   const isInputEmpty = !newInputValue || newInputValue === '';
            //   if (isInputEmpty) {
            //     setOpenRoleAutocomplete(false); // Close the dropdown when the input is empty
            //   }
            //   setWorkHistoryState({ ...workHistoryState, roleKeyword: newInputValue || '' });
            // }}
            onBlur={(event: any) => {
              if (event.target.value && !_.get(values, 'roleType').includes(event.target.value || '')) {
                setValues({ ...values, roleType: [..._.get(values, 'roleType', []), event.target.value] });
              }
            }}
            onInputChange={(event, newInputValue) => {
              const keyboardEvent = event as React.KeyboardEvent<HTMLInputElement>;

              setOpenRoleAutocomplete(true);
              const isInputEmpty = !newInputValue || newInputValue === '';
              if (isInputEmpty) {
                setOpenRoleAutocomplete(false); // Close the dropdown when the input is empty
              }

              if (keyboardEvent.key === 'Enter') {
                // Handle Enter key press to add a new value
                if (newInputValue && !_.get(values, 'roleType').includes(newInputValue || '')) {
                  setValues({ ...values, roleType: [..._.get(values, 'roleType', []), newInputValue] });
                  setWorkHistoryState({ ...workHistoryState, roleKeyword: '' }); // Clear the input after adding a new value
                }
              } else {
                // Handle other cases (typing without pressing Enter)
                setWorkHistoryState({ ...workHistoryState, roleKeyword: newInputValue || '' });
              }
            }}
            onChange={(event, newValue) => {
              setOpenCompanyAutocomplete(false);
              handleRoleType(event, newValue);
            }}
            disableCloseOnSelect
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                size="small"
                placeholder={isEmpty(_.get(values, 'roleType')) ? 'Select Role' : ''}
                error={Boolean(_.get(iWorkHistoryError, 'roleType', false))}
              />
            )}
            freeSolo
            renderTags={(value, getTagProps) =>
              _.isArray(value) && _.map(value, (option, index) => <Chip label={option} {...getTagProps({ index })} />)
            }
          />
        </FormControl>
        <FormHelperText error>{_.get(iWorkHistoryError, 'roleType', '')}</FormHelperText>
      </Grid>
    </Grid>
  );
};

export default WorkHistoryDailogBox;
