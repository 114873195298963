import { useQuery } from '@tanstack/react-query';
import { queryKeys } from './queryKeys';
import { API } from '../api';

const useMenteePeers = ({ grpId, orgId, location }: { grpId: string; location: string; orgId: string }) => {
  return useQuery({
    queryKey: [queryKeys.GET_MENTEE_PEERS, grpId],
    queryFn: () => {
      return API.getMenteesPeersHomePage(orgId, location, grpId);
    },
    staleTime: 10 * 10 * 1000
  });
};

export default useMenteePeers;
