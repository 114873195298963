import React, { useEffect, useState } from 'react';
import { Box, Stack, Divider, Collapse, Typography } from '@mui/material';
import _ from 'lodash';
import ChatPerson from './ChatPerson';
import RenderChats from './RenderChats';
import { useAppSelector } from '../../../../../redux/hooks';
import { notificationCount } from '../../../components/common';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { ChannelLoader } from '../../../../../Loader/chat/ChatLoader';
import ChatUnreadNotificationsBox from '../ChatUnreadNotificationsBox';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

interface IChatChannelsProps {
  activeChat: string;
  filteredDirectMessages: IThread[];
  threadValue: string;
  formattedAcsOrgUsers: IFormattedAcsOrgUser[];
  commId: string;
  activeItemRef: React.MutableRefObject<any>;
  filterParticipantsFromDirectThreads: IChatThread[];
  groupMessages: IGroupMessage[];
  activeMessageId: any;
  accessUserChatShow?: any;
  loading?: boolean;
}

const ChatChannelsContainer: React.FC<IChatChannelsProps> = ({
  activeChat,
  filteredDirectMessages,
  threadValue,
  formattedAcsOrgUsers,
  commId,
  activeItemRef,
  filterParticipantsFromDirectThreads,
  groupMessages,
  activeMessageId,
  accessUserChatShow,
  loading
}) => {
  const acsChannels = useAppSelector((state) => state.acsChannels);
  const msgCount = { personal: 0, group: 0 };

  const groupThreadsMsgCount = useAppSelector((state) => state?.acsChannels.groupThreads) || [];
  const personalThreadMsgCount: any = useAppSelector((state) => state?.acsChannels.personalThreadsArr) || [];

  groupThreadsMsgCount.forEach((each) => {
    msgCount.group += each.msgCount;
  });
  personalThreadMsgCount.forEach((each: any) => {
    msgCount.personal += each.msgCount;
  });
  /** Combine both arrays */
  /** Calculate total message count */

  const [isOpenGroup, setIsOpenGroup] = useState(true);
  const [isOpenPerson, setIsOpenPerson] = useState(true);
  const handleCollapseToggleGroup = () => setIsOpenGroup(!isOpenGroup);
  const handleCollapseTogglePerson = () => setIsOpenPerson(!isOpenPerson);
  const acsToken = useAppSelector((state) => state.acsToken?.data?.token);
  /** Set height */
  const boxHeight = accessUserChatShow ? 'calc(100% - 220px)' : 'calc(100% - 135px)';

  useEffect(() => {
    setIsOpenGroup(true);
    setIsOpenPerson(true);
  }, [acsToken]);

  const renderGroupChatPersons = () => {
    if (groupMessages.length === 0) {
      return (
        <>
          <Box p={2}>
            <Typography textAlign="center">No chat groups</Typography>
          </Box>
          <Divider />
        </>
      );
    }

    return groupMessages.map((each, idx) => {
      const lastMessageUserName = formattedAcsOrgUsers[each?.senderCommunicationId];
      let message = '';
      if (each?.deleted) {
        message = `This message was deleted`;
      } else if (each.type !== 'text') {
        message = 'Group details updated';
      } else if (each.file) {
        message = 'Sent an attachment';
      } else {
        message = each?.lastmessage || '';
      }
      return (
        <ChatPerson
          key={idx}
          lstMessage={message}
          lstMessageUserName={lastMessageUserName?.displayName}
          type="group-thread"
          activeThreadId={threadValue}
          unReadMessageCount={each?.msgCount}
          activeCommId={commId}
          threadId={_.get(each, 'id', '')}
          name={_.get(each, 'topic') || ''}
          image={_.get(each, 'image', '')}
          lastMessageReceivedOn={_.get(each, 'lastMessageReceivedOn', '')}
          index={groupMessages}
          loading={loading}
          deleted={each.deleted}
          messageType={each.type}
        />
      );
    });
  };

  return (
    <Box width="100%" height={boxHeight} overflow={'hidden auto'}>
      {/* Group chat */}
      <Box width="100%">
        {loading ? (
          <ChannelLoader channelCount={0} />
        ) : (
          <>
            <Stack direction="row" alignItems="center" justifyContent="space-between" p={2}>
              <Box display="flex" gap="8px" alignItems="center">
                <Typography
                  variant="h5"
                  color={'secondary'}
                  lineHeight={'normal'}
                  className={'cursor-pointer'}
                  onClick={isOpenGroup ? handleCollapseToggleGroup : handleCollapseToggleGroup}
                >
                  Groups
                </Typography>

                {/* Unread Notifications */}
                {msgCount.group > 0 && (
                  <ChatUnreadNotificationsBox count={msgCount.group} printAres="besideChatHeading" />
                )}
              </Box>
              {isOpenGroup ? (
                <KeyboardArrowUpIcon
                  id={'group-up-arrow-icon'}
                  onClick={handleCollapseToggleGroup}
                  className="cursor-pointer secondary-light"
                />
              ) : (
                <KeyboardArrowDownIcon
                  id={'group-down-arrow-icon'}
                  onClick={handleCollapseToggleGroup}
                  className="cursor-pointer secondary-light"
                />
              )}
            </Stack>
            <Collapse in={isOpenGroup}>
              <Box>{renderGroupChatPersons()}</Box>
            </Collapse>
          </>
        )}
      </Box>

      {isOpenGroup ? null : <Divider />}

      {/* Person Chat */}
      <Box width="100%">
        {loading ? (
          <ChannelLoader channelCount={5} />
        ) : (
          <>
            <Stack direction="row" alignItems="center" justifyContent="space-between" p={2}>
              <Box display="flex" gap="8px" alignItems="center">
                <Typography
                  variant="h5"
                  color={'secondary'}
                  lineHeight={'normal'}
                  className={'cursor-pointer'}
                  onClick={isOpenPerson ? handleCollapseTogglePerson : handleCollapseTogglePerson}
                >
                  Direct Messages
                </Typography>

                {/* Unread Notifications */}
                {msgCount.personal > 0 && (
                  <ChatUnreadNotificationsBox count={msgCount.personal} printAres="besideChatHeading" />
                )}
              </Box>

              {isOpenPerson ? (
                <KeyboardArrowUpIcon
                  id="person-up-arrow-icon"
                  onClick={handleCollapseTogglePerson}
                  className="cursor-pointer secondary-light"
                />
              ) : (
                <KeyboardArrowDownIcon
                  id="person-down-arrow-icon"
                  onClick={handleCollapseTogglePerson}
                  className="cursor-pointer secondary-light"
                />
              )}
            </Stack>

            <Collapse in={isOpenPerson}>
              <Box>
                <RenderChats
                  activeItemRef={activeItemRef}
                  commId={commId}
                  filterParticipantsFromDirectThreads={filterParticipantsFromDirectThreads}
                  filteredDirectMessages={filteredDirectMessages}
                  formattedAcsOrgUsers={formattedAcsOrgUsers}
                  threadValue={threadValue}
                  activeMessageId={activeMessageId}
                  loading={loading}
                />
              </Box>
            </Collapse>
          </>
        )}
      </Box>
    </Box>
  );
};

export default ChatChannelsContainer;
