// selectedValueSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SelectedValueState {
  selectedValue: string;
}

const initialState: SelectedValueState = {
  selectedValue: '' // Initial value
};

const selectedValueSlice = createSlice({
  name: 'selectedValue',
  initialState,
  reducers: {
    setSelectedValue: (state, action: PayloadAction<string>) => {
      state.selectedValue = action.payload;
    }
  }
});

export const { setSelectedValue } = selectedValueSlice.actions;

export default selectedValueSlice.reducer;
