import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from '../../api';
import { getUserDetails } from '../../utils/orgName';

export interface IRoleObj {
  id: string;
  role: string;
  name: string;
  active: boolean;
  displayRole?: string;
}
interface IInitialState {
  loading: boolean;
  error: boolean;
  errorText: string;
  data: IRoleObj[] | undefined;
  loader2: boolean;
}

const initialState: IInitialState = {
  loading: false,
  data: undefined,
  error: false,
  errorText: '',
  loader2: false
};

export const fetchUserGroupList = createAsyncThunk('groupListForUser', () => {
  const { orgId } = getUserDetails();
  return API.groupsListForUser(orgId).then((response) => response.data.data);
});

const groupRolesListSlice = createSlice({
  name: 'groupRoleList',
  initialState,
  reducers: {
    update: (state, action) => {
      state.data = action.payload;
    },
    upadateState: (state, action) => {
      return { ...state, ...action.payload };
    },
    removeRole: (state, action) => {
      const data = state.data?.filter((each) => each.id !== action.payload.id && each.role !== action.payload.role);
      return { ...state, data };
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserGroupList.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.data = undefined;
        state.errorText = '';
      })
      .addCase(fetchUserGroupList.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        state.data = action.payload;
        state.errorText = '';
      })
      .addCase(fetchUserGroupList.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
        state.data = undefined;
        state.errorText = action.error.message || 'Something went wrong';
      });
  }
});

export default groupRolesListSlice.reducer;
export const groupRoleListUpdate = groupRolesListSlice.actions;
