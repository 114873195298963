import { createSlice } from '@reduxjs/toolkit';

interface Idata {
  selectedRole: string[];
  selectedUserType: string[];
  selectedIndustry: string[];
  selectedSkillType: string[];
  selectedHobbies: string[];
  selectedRoleType: string[];
  selectedBsIndustries: string[];
  selectedCountries: string[];
}

interface initialState {
  data: Idata;
  loading: boolean;
  error: boolean;
  errorText: string;
}
const initialState: initialState = {
  data: {
    selectedRole: [],
    selectedUserType: [],
    selectedIndustry: [],
    selectedSkillType: [],
    selectedHobbies: [],
    selectedRoleType: [],
    selectedBsIndustries: [],
    selectedCountries: []
  },
  loading: false,
  error: false,
  errorText: ''
};
const communityFilterSlice = createSlice({
  name: 'CommunityFilter',
  initialState,
  reducers: {
    updateCommunityFilter: (state, action) => {
      state.data = action.payload;
    }
  }
});

export default communityFilterSlice.reducer;

export const communityFilterAction = communityFilterSlice.actions;
