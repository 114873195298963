import React from 'react';
import { Box } from '@mui/material';
import MySkeleton from '../components/Skeleton';
import useProfileDetails from '../hooks/useProfileDetails';
import useResponsiveScreenSwitch from '../utils/useResponsiveScreenSwitch';

const AssessmentQuestionsLoader = () => {
    const { ftue } = useProfileDetails();
    const { forMobile } = useResponsiveScreenSwitch();
    const widthSetQuestion = ftue || forMobile ? '100%' : '80%';
    const widthSet = ftue || forMobile ? '100%' : '50%';

    return (
        <Box className='CardBox' minHeight={'100%'} width={'100%'}>
            <Box p={2}>
                <MySkeleton width={200}/>
            </Box>
            <MySkeleton height={2}/>
            <Box px={2} pb={2}>
                {[...Array(3).keys()].map((_, index) => (
                    <Box mt={2} key={index}>
                        <MySkeleton width={widthSetQuestion}/>
                        <Box display={'flex'} flexDirection={'column'} my={2} gap={1}>
                            <MySkeleton variant='rounded' height={50} width={widthSet}/>
                            <MySkeleton variant='rounded' height={50} width={widthSet}/>
                        </Box>
                        <MySkeleton height={2} />
                    </Box>
                ))}
                
                <Box mt={2}>
                    <MySkeleton variant='rounded' height={50} width={246}/>
                </Box>
            </Box>
        </Box>
    );
};

export default AssessmentQuestionsLoader;
