/**
 * This file includes all lazy components for optimizing the build performance
 */
import { lazy } from 'react';

export const OrganizationsElement = lazy(() =>
  import('../pages/organizations').then((module) => ({
    default: module.Organizations
  }))
);

export const LoginElement = lazy(() =>
  import('../pages/login/Login').then((module) => ({
    default: module.default
  }))
);
