import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

interface IInitialState {
  data: {
    id: string;
    name: string;
    role: string;
    active: boolean;
  };
}

const initialState: IInitialState = {
  data: {
    id: '',
    name: '',
    role: '',
    active: true
  }
};

const selectedGroupSlice = createSlice({
  name: 'selected group role',
  initialState,
  reducers: {
    updateSelectedId: (state, action) => {
      state.data = {
        id: _.get(action.payload, 'id', ''),
        name: _.get(action.payload, 'name', ''),
        role: _.get(action.payload, 'role', ''),
        active: _.get(action.payload, 'active', true)
      };
    }
  }
});

export default selectedGroupSlice.reducer;
export const { updateSelectedId } = selectedGroupSlice.actions;
