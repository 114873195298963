export const alphaColorsNew = {
  A: '#6EA8FE', // Blue 300
  B: '#A370F7', // Indigo 300
  C: '#a98eda', // Purple 300
  D: '#E685B5', // Pink 300
  E: '#58151c', // Red 300
  F: '#FEB272', // Orange 300
  G: '#ffda6a', // Yellow 300
  H: '#75b798', // Green 300
  I: '#79DFC1', // Teal 300
  J: '#6edff6', // Cyan 300
  K: '#6EA8FE', // Blue 300
  L: '#A370F7', // Indigo 300
  M: '#a98eda', // Purple 300
  N: '#E685B5', // Pink 300
  O: '#58151c', // Red 300
  P: '#FEB272', // Orange 300
  Q: '#ffda6a', // Yellow 300
  R: '#75b798', // Green 300
  S: '#79DFC1', // Teal 300
  T: '#6edff6', // Cyan 300
  U: '#6EA8FE', // Blue 300
  V: '#A370F7', // Indigo 300
  W: '#a98eda', // Purple 300
  X: '#E685B5', // Pink 300
  Y: '#58151c', // Red 300
  Z: '#FEB272', // Orange 300
  '1': '#6EA8FE', // Blue 300
  '2': '#A370F7', // Indigo 300
  '3': '#a98eda', // Purple 300
  '4': '#E685B5', // Pink 300
  '5': '#58151c', // Red 300
  '6': '#FEB272', // Orange 300
  '7': '#ffda6a', // Yellow 300
  '8': '#75b798', // Green 300
  '9': '#79DFC1', // Teal 300
  '0': '#6edff6', // Cyan 300
  '!': '#6EA8FE',
  '@': '#A370F7',
  '#': '#a98eda',
  $: '#E685B5',
  '%': '#58151c',
  '^': '#6EA8FE',
  '&': '#6EA8FE',
  '*': '#6EA8FE',
  '(': '#6EA8FE',
  ')': '#6EA8FE',
  _: '#6EA8FE',
  '+': '#A370F7',
  '-': '#a98eda',
  '=': '#E685B5',
  '{': '#58151c',
  '}': '#6EA8FE',
  '[': '#6EA8FE',
  ']': '#6EA8FE',
  '|': '#6EA8FE',
  ';': '#6EA8FE',
  ':': '#A370F7',
  "'": '#a98eda',
  '"': '#E685B5',
  '<': '#58151c',
  '>': '#6EA8FE',
  ',': '#6EA8FE',
  '.': '#6EA8FE',
  '?': '#6EA8FE',
  '/': '#6EA8FE',
  '`': '#58151c',
  '~': '#6edff6'
};

export const alphaColors = {
  A: 'rgba(0,0,180)',
  B: 'rgba(175, 13, 102)',
  C: 'rgba(146,248,70)',
  D: 'rgba(255, 200, 47)',
  E: 'rgba(255,118,0)',
  F: 'rgba(185,185,185)',
  G: 'rgba(235,235,222)',
  H: 'rgba(100,100,100)',
  I: 'rgba(255,255,0)',
  J: 'rgba(55,19,112)',
  K: 'rgba(255,255,150)',
  L: 'rgba(202,62,94)',
  M: 'rgba(205,145,63)',
  N: 'rgba(12,75,100)',
  O: 'rgba(255,0,0)',
  P: 'rgba(175,155,50)',
  Q: 'rgba(0,0,0)',
  R: 'rgba(255,0,0)',
  S: 'rgba(121,33,135)',
  T: 'rgba(83,140,208)',
  U: 'rgba(0,154,37)',
  V: 'rgba(178,220,205)',
  W: 'rgba(255,152,213)',
  X: 'rgba(0,0,74)',
  Y: 'rgba(175,200,74)',
  Z: 'rgba(63,25,12)',
  '1': 'rgba(0,0,180)',
  '2': 'rgba(175, 13, 102)',
  '3': 'rgba(146,248,70)',
  '4': 'rgba(255, 200, 47)',
  '5': 'rgba(255,118,0)',
  '6': 'rgba(185,185,185)',
  '7': 'rgba(235,235,222)',
  '8': 'rgba(100,100,100)',
  '9': 'rgba(255,255,0)',
  '0': 'rgba(55,19,112)',
  '!': 'rgba(255, 0, 0)',
  '@': 'rgba(255, 0, 0)',
  '#': 'rgba(255, 0, 0)',
  $: 'rgba(255, 0, 0)',
  '%': 'rgba(255, 0, 0)',
  '^': 'rgba(255, 0, 0)',
  '&': 'rgba(255, 0, 0)',
  '*': 'rgba(255, 0, 0)',
  '(': 'rgba(255, 0, 0)',
  ')': 'rgba(255, 0, 0)',
  _: 'rgba(255, 0, 0)',
  '+': 'rgba(255, 0, 0)',
  '-': 'rgba(255, 0, 0)',
  '=': 'rgba(255, 0, 0)',
  '{': 'rgba(255, 0, 0)',
  '}': 'rgba(255, 0, 0)',
  '[': 'rgba(255, 0, 0)',
  ']': 'rgba(255, 0, 0)',
  '|': 'rgba(255, 0, 0)',
  ';': 'rgba(255, 0, 0)',
  ':': 'rgba(255, 0, 0)',
  "'": 'rgba(255, 0, 0)',
  '"': 'rgba(255, 0, 0)',
  '<': 'rgba(255, 0, 0)',
  '>': 'rgba(255, 0, 0)',
  ',': 'rgba(255, 0, 0)',
  '.': 'rgba(255, 0, 0)',
  '?': 'rgba(255, 0, 0)',
  '/': 'rgba(255, 0, 0)',
  '`': 'rgba(255, 0, 0)',
  '~': 'rgba(255, 0, 0)'
};
