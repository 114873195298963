import React, { useState } from 'react';
import { Dialog, TextField, Typography, DialogContent, DialogActions } from '@mui/material';
import { getUserDetails } from '../../../../utils/orgName';
import { API } from '../../../../api';
import { toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';
import useCallDetails from '../../../../queries/useCallDetails';
import CommonModalHeader from '../../../../components/DialogUi/CommonModalHeader';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { callsCountActions } from '../../../../redux/slices/callCountSlice';

interface CancelationCallModalProps {
  openCancelationCall: boolean;
  closeCancelModal: () => void;
  callMeetingId: any;
  onCancelSuccess: () => void;
}

const CancelationCallModal: React.FC<CancelationCallModalProps> = ({
  openCancelationCall,
  closeCancelModal,
  callMeetingId,
  onCancelSuccess
}) => {
  const page = 1; // Set your desired page size
  const [pageSize, setPageSize] = useState(10);
  const userCallsData = useCallDetails(page, pageSize);
  const callsCount = useAppSelector((state) => state.callsCount.callsCount);
  const [cancelationReason, setCancelationReason] = useState('');
  const [isLoader, setIsLoader] = useState(false);
  const handleCancelationReasonChange = (event: { target: { value: React.SetStateAction<string> } }) => {
    setCancelationReason(event.target.value);
  };
  const dispatch = useAppDispatch();

  const handleSendClick = async () => {
    if (!cancelationReason) {
      toast.error('Please add a cancelation reason');
      return;
    }
    const userDetails = getUserDetails();
    const callCancelReason = { callCancelReason: cancelationReason };
    try {
      setIsLoader(true);
      const response = await API.cancelScheduleCall(
        userDetails.userId,
        callMeetingId,
        userDetails.orgId,
        userDetails.location,
        callCancelReason
      );
      if (response) {
        dispatch(callsCountActions.updateCount(callsCount - 1));
        // refetch .....
        await userCallsData
          .invalidateQuery()
          .then((res) => {})
          .catch((err) => {})
          .finally(() => {
            userCallsData.refetch();
          });
        toast.success('Meeting cancelled sucessfully');
        onCancelSuccess();
      }
    } catch (e) {
      toast.error('Something went wrong, please try again');
    } finally {
      closeCancelModal();
      setCancelationReason('');
      setIsLoader(false);
    }
  };
  return (
    <Dialog open={openCancelationCall} onClose={closeCancelModal} maxWidth="sm" fullWidth>
      {/* Dialog Header */}
      <CommonModalHeader title={`Cancelation Reason`} handleCloseModal={closeCancelModal} />
      <DialogContent>
        <Typography variant="h5" mb={1}>
          Reason
        </Typography>
        <TextField
          multiline
          rows={4}
          placeholder="Write your Cancelation reason, so student have idea about next other meets."
          onChange={handleCancelationReasonChange}
        />
      </DialogContent>
      <DialogActions className="pt-0">
        <LoadingButton loading={isLoader} fullWidth disabled={isLoader} variant="contained" onClick={handleSendClick}>
          Send
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default CancelationCallModal;
