import {
  Box,
  Stack,
  Button,
  Dialog,
  Popover,
  IconButton,
  Typography,
  DialogContent,
  DialogActions
} from '@mui/material';
import React, { useState } from 'react';
import { appColors } from '../../../../../utils/theme';
import ChatAvatarComponent from '../ChatAvatarComponent';
import _ from 'lodash';
import Text from '../../../../../components/ui/Typography/Text';
// import { alphaColors } from '../../../../../utils/alphabates-colors';
import { PersonAddAlt } from '@mui/icons-material';
// import StarIcon from '../../../../../assets/images/starimage.png';
import BookIcon from '../../../../../assets/images/bookimage.png';
// import VideoIcon from '../../../../../assets/images/videoimage.png';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
// import { useAppSelector } from "../../../../../redux/hooks";
import { styled } from '@mui/material/styles';
import { ChatStyle } from '../ChatStyling';
// import { getUserDetails } from "../../../../../utils/orgName";
// import instantCallEvent from "../../instantCallEvent";
// import LaunchZoomCall from "../../../../LaunchZoomCall";
// import CallBookingCalendar from '../../CallBookingCalendar';
import CallBookingModal from '../../CallBookingModal';
// import GroupChatUsers from '../../../../../assets/images/GroupChatUsers.svg';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import GroupDescription from '../../../../../assets/images/GroupDescription.svg';
import Leave from '../../../../../assets/images/Leave.svg';
// import Mute from '../../../../../assets/images/Mute.svg';
// import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import CommonModalHeader from '../../../../../components/DialogUi/CommonModalHeader';
import { useAppSelector } from '../../../../../redux/hooks';
import { getUserDetails } from '../../../../../utils/orgName';
import Upvotes from '../../../components/Upvotes';
import { BlueCircleCalendarIcon } from '../../../../../utils/Icons';
import { ChatMainHeaderLoader } from '../../../../../Loader/chat/ChatLoader';

interface IChatMainHeader {
  chatType: string;
  activeChat: string;
  setChatInfoForm: React.Dispatch<boolean>;
  channelImage: string;
  acsChannelParticipants: any;
  formattedAcsOrgUsers: any;
  handleAddPersonToThread: any;
  channel: any;
  isLoading: boolean;
  onClickOnUserIcon: () => void;
  setopenGroupDetails: any;
  removeParticipantFromThread: () => void;
}

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 300
  }
});

const ChatMainHeader: React.FC<IChatMainHeader> = ({
  acsChannelParticipants,
  activeChat,
  channelImage,
  chatType,
  formattedAcsOrgUsers,
  handleAddPersonToThread,
  setChatInfoForm,
  channel,
  isLoading,
  onClickOnUserIcon,
  setopenGroupDetails,
  removeParticipantFromThread
}) => {
  const bookedCallDetails: any = '';
  const classes = ChatStyle();
  const selectedChatUser = useAppSelector((state) => state.selectedChatUser.data);
  const handleBookIconClick = async (param: any) => {
    // console.log('Clicked BookIcon with parameter:', param);
    if (param === 'BookIcon') {
      // Perform specific action when param is equal to BookIcon
      setCalendarOpen(true);
    } else if (param === 'VideoIcon') {
      // console.log(acsChannelParticipants);
      // const baseUrl = window.location.origin;
      // const meetingId = Math.random().toString(36).substr(2, 9);
      // const currentTimestamp = new Date().getTime();
      // const currentUnixTime = Math.floor(currentTimestamp / 1000);
      // const userName = getUserDetails().name;
      // instantCallEvent(channel, acsChannelParticipants, getUserDetails().communicationUserId, baseUrl, meetingId, currentUnixTime);
      // setZoomCallProps({
      //   base_url: baseUrl,
      //   meetingId: meetingId,
      //   unix_time: currentUnixTime,
      //   userName: userName
      // });
      // setCallActive(true);
    }
  };

  const iconMap = {
    // StarIcon: StarIcon,
    BookIcon: BookIcon
    // VideoIcon: VideoIcon
  };

  const [isCalendarOpen, setCalendarOpen] = useState(false);
  const handleCalendar = (flag: boolean) => setCalendarOpen(flag);

  /** Popover */
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  /** Popover end */

  const [openLeaveModal, setOpenLeaveModal] = React.useState(false);

  const handleClickOpenLeaveModal = () => {
    setOpenLeaveModal(true);
  };

  const handleCloseLeaveModal = () => {
    setOpenLeaveModal(false);
  };

  /** How many group members */
  const HowManyuUsers = () => {
    const numOfUsers = acsChannelParticipants.length;
    return (
      <Upvotes likes={numOfUsers} icon={'group'} />
    );
  };

  return (
    isLoading ? <ChatMainHeaderLoader /> :
      <>
        <Box className={classes.headerContainer}>
          <Stack
            direction="row"
            className={chatType === 'Groups' ? 'cursor-pointer' : ''}
            gap={2}
            alignItems="center"
          // onClick={() => {
          //   if (activeChat === 'Groups') {
          //     setChatInfoForm(true);
          //   }
          // }}
          >
            <Box display="flex" alignItems="center">
              {/* Back btn for mobile */}
              {/* {forMobile ?
              <Box pr={'10px'}>
                <ArrowBackIosNewOutlinedIcon />
              </Box>
            :null} */}

              <ChatAvatarComponent
                image={
                  selectedChatUser?.communicationId
                    ? channelImage
                      ? channelImage
                      : chatType !== 'Groups'
                        ? getUserDetails()?.picture
                        : channelImage
                    : channelImage
                      ? channelImage
                      : null
                }
                firstLetter={channel.slice(0, 1)}
                onClickOnUserIcon={() => {
                  onClickOnUserIcon();
                  if (chatType === 'Groups') {
                    setopenGroupDetails(true);
                  }
                }}
                type="noStatus"
              // onClickOnUserIcon={() => onClickOnUserIcon()}
              />
            </Box>
            <Stack spacing={0.5}>
              <Box>
                <Typography
                  variant="h5"
                  textTransform={'capitalize'}
                  className='cursor-pointer'
                  onClick={() => {
                    onClickOnUserIcon();
                    if (chatType === 'Groups') {
                      setopenGroupDetails(true);
                    }
                  }}
                >
                  {selectedChatUser?.communicationId
                    ? channel
                      ? channel
                      : getUserDetails()?.firstName + ' ' + getUserDetails()?.lastName
                    : channel}
                </Typography>
              </Box>
              <Box
                onClick={() => {
                  chatType === 'Groups' ? setopenGroupDetails(true) : null;
                }}
              >
                {chatType === 'Groups' ? <HowManyuUsers /> : <Typography>Available</Typography>}
              </Box>
            </Stack>
            {/* {chatType === 'Groups' ? <KeyboardArrowDownIcon fontSize="small" /> : null} */}
          </Stack>
          <Stack direction="row" className='cursor-pointer'>
            {/* <CustomWidthTooltip
            title={
              <Text
                content={acsChannelParticipants.map((each: any) => _.get(each, 'displayName')).join(', ')}
                type="T5"
                color={appColors.white}
              />
            }
          >
            <Box position="relative" marginRight={10} padding="8px">
              {chatType === 'Groups'
                ? acsChannelParticipants.slice(0, 3).map((each: any, idx: number) => (
                    <React.Fragment key={idx}>
                      {_.get(formattedAcsOrgUsers[_.get(each, 'id.communicationUserId', '')], 'id.headshot') ? (
                        <Box
                          key={each}
                          sx={{
                            borderRadius: '50%',
                            borderWidth: 1,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            position: 'absolute',
                            left: 17 * (idx + 1)
                          }}
                        >
                          <img
                            style={{
                              height: '34px',
                              width: '34px',
                              borderRadius: '50%',
                              objectFit: 'cover'
                            }}
                            src={_.get(formattedAcsOrgUsers[_.get(each, 'id.communicationUserId', '')], 'id.headshot')}
                            alt=""
                          />
                        </Box>
                      ) : (
                        <Box
                          key={each}
                          sx={{
                            borderRadius: '50%',
                            borderWidth: 1,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '35px',
                            width: '35px',
                            backgroundColor: _.get(
                              alphaColors,
                              _.get(each, 'displayName', '').slice(0, 1).toUpperCase(),
                              ''
                            ),
                            position: 'absolute',
                            left: 18 * (idx + 1)
                          }}
                        >
                          <Text
                            content={_.get(each, 'displayName', '').slice(0, 1).toUpperCase()}
                            type="T5"
                            color={appColors.black}
                          />
                        </Box>
                      )}
                    </React.Fragment>
                  ))
                : null}
            </Box>
          </CustomWidthTooltip> */}
            {!selectedChatUser.communicationId &&
              (chatType === 'Groups' ? (
                <IconButton
                  disableRipple
                  onClick={handleAddPersonToThread}
                >
                  <PersonAddAlt color='primary' fontSize='small' />
                </IconButton>
              ) : null)}
            {!selectedChatUser.communicationId &&
              Object.entries(iconMap).map(([key, value], idx) => (
                <IconButton
                  key={idx}
                  disableRipple
                  onClick={() => handleBookIconClick(key)} // Wrap the function call in an arrow function
                >
                  <BlueCircleCalendarIcon size={22} />
                </IconButton>
              ))}

            {chatType === 'Groups' ? (
              <Box m={'auto'}>
                <IconButton onClick={handleClick} disableRipple>
                  <MoreVertIcon color="primary" />
                </IconButton>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                  }}
                >
                  <Box
                    className="PopoverListStyle"
                    onClick={() => {
                      if (chatType === 'Groups') {
                        setopenGroupDetails(true);
                        setAnchorEl(null);
                      }
                    }}
                  >
                    <img alt='' width="20px" height="20px" src={GroupDescription} />
                    <Typography>Group details</Typography>
                  </Box>
                  {/* <Box className='PopoverListStyle'>
                  <img width='20px' height='20px' src={Mute} />
                  <Typography> Mute Chat </Typography>
                </Box> */}
                  <Box className="PopoverListStyle" onClick={handleClickOpenLeaveModal}>
                    <img alt='' width="20px" height="20px" src={Leave} />
                    <Typography>Leave Group</Typography>
                  </Box>
                </Popover>
              </Box>
            ) : null}
          </Stack>
        </Box>

        {/* chat page booking modal */}
        <CallBookingModal
          handleCloseModal={() => handleCalendar(false)}
          openFlag={isCalendarOpen}
          chatProfileName={channel}
          threadParticipantsUsers={acsChannelParticipants}
          chatType={chatType}
          pageName="Chat"
          bookedCallDetails={bookedCallDetails}
        />

        {/* Leave Group Confirmation */}
        <Dialog open={openLeaveModal} onClose={handleCloseLeaveModal} fullWidth>
          <CommonModalHeader title="Confirmation" handleCloseModal={handleCloseLeaveModal} />
          <DialogContent>
            <Typography variant="h5" textAlign="center">
              Are you sure you want to leave <br /> this group?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button fullWidth variant="outlined" color="secondary" onClick={handleCloseLeaveModal}>
              No
            </Button>
            <Button
              fullWidth
              variant="contained"
              onClick={() => {
                removeParticipantFromThread();
                handleCloseLeaveModal();
              }}
            >
              Yes, leave
            </Button>
          </DialogActions>
        </Dialog>

        {/* <Dialog open={isVideoCall} onClose={handleVideoCallClose} scroll="body">   
        <CommonModalHeader
          handleCloseModal={handleVideoCallClose}
          title={`Schedule Time with ${channel}`}
        />
        <ZoomCall/>
      </Dialog> */}
      </>
  );
};

export default ChatMainHeader;
