/* eslint-disable prefer-destructuring */
/* eslint-disable prettier/prettier */
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from '../../../api';
import _ from 'lodash';

export interface ITaskInterface {
  taskPosition: number;
  name: string;
}

export interface ILevelInterface {
  level: number;
  tasks: ITaskInterface;
  name: string;
}

export interface IAllTaskInterface {
  levels: ILevelInterface[];
}

export interface ITaskDetails {
  tags: string[];
  taskName: string;
  taskDescription: string;
  menteeQuestions: string[];
  menteeAnsType: string;
  mentorQues: string;
  mentorAnsType: string;
  mentorAnswers: string[];
  taskPosition: number;
  menteeAsmtQues: string;
  menteeAsmtAnsType: string;
  menteeAsmtAnsOptions: string[];
  libraryArticles: string[];
  _id: string;
  menteeAnsOptions: string[];
  activeMentors: string[];
  recommendedArticles: any[];
  menteeAnswers: string[];
  menteeAsmtAns: string;
  taskColor: string;
  answers: string[];
  menteeAsmtAnswers: string;
}

export interface ITaskLevels {
  hexColor: string;
  level: number;
  levelColor: string;
  levelName: string;
  tasks: ITaskDetails[];
}

export interface IAllTaskData {
  MyMentors: Object[];
  data: [
    {
      goalName: string;
      grpId: string;
      levels: ITaskLevels[];
      orgId: string;
      _id: string;
    }
  ];
}

interface IInitialState {
  loading: boolean;
  data: any | undefined;
  error: boolean;
  errorText: string;
  groupId: string;
  userId: string;
}

const initialState: IInitialState = {
  loading: false,
  data: undefined,
  error: false,
  errorText: '',
  groupId: '',
  userId: ''
};

export const fetchAllTasks = createAsyncThunk(
  'goalTasks',
  async ({ grpId, userId }: { grpId: string; userId: string }) => {
    const response = await API.getAllTasks({ grpId, userId });
    return response.data;
  }
);

const TasksSlice = createSlice({
  name: 'goal Tasks',
  initialState,
  reducers: {
    setGroupId: (state, action: PayloadAction<{ groupId: string }>) => {
      state.groupId = action.payload.groupId;
    },
    setUserId: (state, action: PayloadAction<{ userId: string }>) => {
      state.userId = action.payload.userId;
    },
    updateTask: (state, action) => {
      // const requiredData = action.payload.map((each: any) => {
      //   let answeredCount = 0;
      //   let completedCount = 0;

      //   each.tasks.forEach((task: any) => {
      //     if (task.menteeAnswers?.length > 0 || task.menteeAsmtAns?.length > 0) {
      //       answeredCount += 1;
      //     }

      //     if (task.menteeAsmtAns && task.menteeAsmtAns === 'Yes') {
      //       completedCount += 1;
      //     }
      //   });
      //   // console.log(completedCount, each.tasks.length);
      //   if (completedCount === each.tasks.length) {
      //     return { ...each, hexColor: 'green' };
      //   } else if (answeredCount >= 1) {
      //     return { ...each, hexColor: 'gold' };
      //   } else {
      //     return each;
      //   }
      // });
      state.data.data[0].levels = action.payload;
    },
    addLevel: (state, action) => {
      const levels = state.data.data[0].levels;
      state.data.data[0].levels = [...levels, action.payload];
    },
    deleteLevel: (state, action) => {
      const levels = state.data.data[0].levels.filter((each: any) => each._id !== action.payload);
      state.data.data[0].levels = levels;
    },
    updateData: (state, action) => {
      state.data = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllTasks.pending, (state, action) => {
      state.loading = true;
      state.data = undefined;
      state.error = false;
      state.errorText = '';
    });
    builder.addCase(fetchAllTasks.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = false;
      state.errorText = '';
    });
    builder.addCase(fetchAllTasks.rejected, (state, action) => {
      state.loading = false;
      state.data = undefined;
      state.error = true;
      state.errorText = action.error.message || 'Something went wrong';
    });
  }
});
export const taskActions = TasksSlice.actions;
export default TasksSlice.reducer;
