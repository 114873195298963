/* eslint-disable prefer-destructuring */
/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable no-undef */
/* eslint-disable radix */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import {
  Box
  // Typography
} from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import moment from 'moment';
import { ACS_CREATE_CHANNEL_DIALOG, ACS_CREATE_DIRECT_MESSAGE_DIALOG } from '../../../../constants';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import {
  filterTheAcsUsersWithPersonalThreadsWithCommId,
  formatAcsUserWithCommunicationId,
  getCurrentChannelName
} from '../../../../routes/helpers';
import { API } from '../../../../api';
import { fetchAcsToken } from '../../../../redux/slices/acs-token/acsTokenSlice';
import { getUserDetails } from '../../../../utils/orgName';
import { fetchAcsChannelParticipants } from '../../../../redux/slices/acs-channel-participants/acsChannelParticipants';
import { dialogActions } from '../../../../redux/slices/dialog-slice/dialogSlice';
import { fetchCommunityMembers } from '../../../../redux/slices/acs-community-members/acsCommunityMembers';
import { acsChannelActions } from '../../../../redux/slices/acs-channels/acsChannels';
import { acsMessageActions } from '../../../../redux/slices/acs-message/acsMessage';
import ChatSideBar from './chat-sidebar/ChatSideBar';
import { acsMessageNotificationActions } from '../../../../redux/slices/acs-message-notifications/acsMessageNotificationsSlice';
import useProfileDetails from '../../../../hooks/useProfileDetails';
import useResponsiveScreenSwitch from '../../../../utils/useResponsiveScreenSwitch';
import { useXpPoints } from '../../components/menteeXpPoints';
import AppLoader from '../../../../components/AppLoader';
import { fetchChatUsers } from '../../../../redux/slices/getChatUsersSlice';
import { selectedChatUserActions } from '../../../../redux/slices/selectedChatUserSlice';
import ChatBody from './ChatBody';

/**
 * Chat
 */
let lastSeqId: any;
let threadValue = '';
const Chat = () => {
  const activeItemRef = useRef<any>(null);
  const navigate = useNavigate();
  const chatKind = useAppSelector((state) => state.messageNotifications.chatKind);
  const { forMobile } = useResponsiveScreenSwitch(); // Use the hook
  // console.log(chatKind, forMobile);
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();
  const getXpPoints = useXpPoints();
  const selectedChatUser = useAppSelector((state) => state.selectedChatUser.data);
  const dispatch = useAppDispatch();
  const [activeChat, setActiveChat] = useState<string>('');

  const [messageId, setMessageId] = useState('');
  const [updateMessageLoading, setUpdateMessageLoading] = useState(false);

  const acsChannels = useAppSelector((state) => state.acsChannels);
  // const channelValues = useMemo(() => acsChannels, [acsChannels]);
  // console.log(acsChannels);
  const acsCommunityUsers = useAppSelector((state) => state.acsCommunityUsers);
  const { headshot, firstName } = useProfileDetails();
  const [openGroupDetails, setopenGroupDetails] = useState(false);
  const [openChatProfile, setOpenChatProfile] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const chatLoading = queryClient.getQueryState(['getAcsChatThreads'])?.status === 'pending';
  const threadLoading = queryClient.getQueryState(['getAcsChatThreads'])?.fetchStatus === 'fetching';

  const acsTokenLoading = useAppSelector((state) => state.acsToken.loading);
  const chatAccessed = localStorage.getItem('chatAccessed');
  const chatUser = localStorage.getItem('user');

  const arrangedAcsCommunityMembers =
    _.isArray(acsCommunityUsers?.data) && !_.isEmpty(acsCommunityUsers?.data) ? acsCommunityUsers?.data : [];

  // Extract the communicationUserId values using map

  const acsToken = useAppSelector((state) => state.acsToken.data.token);

  const formattedAcsOrgUsers: any = formatAcsUserWithCommunicationId(arrangedAcsCommunityMembers);

  const threadId = searchParams.get('threadid') || '';
  const urlSearchParams = new URLSearchParams(document.location.hash);
  const chatType = urlSearchParams.get('type') || 'Chats';
  threadValue = threadId || '';
  const commId = searchParams.get('commId') || '';

  const isChatNavigated = localStorage.getItem('user');
  const {
    data,
    isLoading,
    error,
    isError,
    isSuccess,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    refetch,
    isFetching
  } = useInfiniteQuery({
    queryKey: ['recieveMessages', threadValue],
    queryFn: () => {
      return API.readMessagesFromThreadsByNextLink({
        link: `${process.env.REACT_APP_API}/receiveMessages?nextLink=${process.env.REACT_APP_AZURE_ENDPOINT}/chat/threads/${threadValue}/messages?maxPageSize=30&api-version=2021-09-07&acsToken=${acsToken}`
      });
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage, allPages) => {
      return _.get(lastPage, 'nextLink');
    }
  });

  useEffect(() => {
    if (isSuccess) {
      dispatch(acsMessageNotificationActions.atnRemoveNotifications(threadValue));
      const allPages = _.map(_.get(data, 'pages'), (each) => _.get(each, 'value'));
      const sortPages = allPages.sort(
        (a, b) => _.parseInt(_.get(a, 'sequenceId')) - _.parseInt(_.get(b, 'sequenceId'))
      );
      const seqID1 = isNaN(parseInt(_.get(_.last(sortPages), 'sequenceId')))
        ? 0
        : parseInt(_.get(_.last(sortPages), 'sequenceId'));
      if (hasNextPage) {
        dispatch(acsMessageActions.atnClearMessage([]));
      }
      const seqID2 = parseInt(_.get(_.last(data.pages[0].value || []), 'sequenceId', ''));
      const checkIsNaN = isNaN(seqID2) ? 0 : seqID2;
      lastSeqId = Math.max(seqID1, checkIsNaN);
      setUpdateMessageLoading(false);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      if (_.get(error, 'response.status') === 401) {
        dispatch(
          fetchAcsToken({
            communicationId: getUserDetails().communicationUserId,
            tag: selectedChatUser.communicationId ? '#admin' : '#self'
          })
        );
      }
    }
  }, [isError]);

  const clearNotifications = () => {
    if (chatType === 'Groups') {
      const newChannels = acsChannels.groupThreads.map((each) => {
        if (each.id === threadId) {
          return { ...each, msgCount: 0 };
        }
        return each;
      });
      dispatch(acsChannelActions.updateAcsGroupChannesls(newChannels));
    } else {
      const newChannels = acsChannels.personalThreadsArr.map((each) => {
        if (each.id === threadId) {
          return { ...each, msgCount: 0 };
        }
        return each;
      });
      dispatch(acsChannelActions.updateAcsDirectChannesls(newChannels));
    }
    // deleteMessage();
  };
  useEffect(() => {
    setActiveChat(chatType);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      clearNotifications();
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [data, threadId, chatType]);
  // console.log(data?.pages);
  const directMessages: IThread[] = _.filter(
    _.get(acsChannels, 'personalThreadsArr'),
    (each) => !_.has(each, 'deletedOn')
  ).sort((a: any, b: any) => moment(_.get(b, 'lastMessageReceivedOn')).diff(moment(_.get(a, 'lastMessageReceivedOn'))));
  const filteredDirectMessages = directMessages.filter((each) =>
    _.size(formattedAcsOrgUsers[_.head(_.get(each, 'displayNames', [])) || ''])
  );

  const groupMessages = _.filter(_.get(acsChannels, 'groupThreads'), (each) => !_.has(each, 'deletedOn')).sort(
    (a: any, b: any) => moment(_.get(b, 'lastMessageReceivedOn')).diff(moment(_.get(a, 'lastMessageReceivedOn')))
  );

  useEffect(() => {
    dispatch(acsMessageActions.atnClearMessage([]));
  }, []);

  const filterParticipantsFromDirectThreads: IChatThread[] = filterTheAcsUsersWithPersonalThreadsWithCommId(
    arrangedAcsCommunityMembers,
    _.get(acsChannels, 'personalThreadsArr')
  );
  const channelUser =
    formattedAcsOrgUsers[getCurrentChannelName(acsChannels, threadValue, selectedChatUser?.communicationId)];

  const chatPopupClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const chatPopupClose = () => {
    setAnchorEl(null);
  };

  const chatPopupOpen = Boolean(anchorEl);
  const chatPopup = chatPopupOpen ? 'simple-popover' : undefined;

  const getChannelParticipants = () => {
    dispatch(
      fetchAcsChannelParticipants({
        acsToken: acsToken,
        threadId: threadValue
      })
    );
  };

  useEffect(() => {
    if (threadValue && acsToken) {
      refetch();
      getChannelParticipants();
      dispatch(acsMessageActions.atnClearMessage([]));
      // queryClient.fetchQuery({ queryKey: ['getAcsChatThreads'] });
    }
  }, [acsToken, threadValue]);

  const navigateToTheChat = () => {
    if (!threadId && !commId) {
      if (_.size(filteredDirectMessages)) {
        const personalChat = _.head(filteredDirectMessages);
        if (personalChat) {
          navigate(`/app/chat/?threadid=${_.get(personalChat, 'id')}&type=Chats&name=${_.get(personalChat, 'topic')}`);

          API.deleteMsgCount({ threadId: _.get(personalChat, 'id') }).then((res) => {});
        }
      } else {
        if (filterParticipantsFromDirectThreads.length) {
          // Sort the participants array alphabetically by display name
          filterParticipantsFromDirectThreads.sort((a, b) => {
            const displayNameA = _.get(a, 'displayName', '').toLowerCase();
            const displayNameB = _.get(b, 'displayName', '').toLowerCase();
            return displayNameA.localeCompare(displayNameB);
          });

          const firstUser = filterParticipantsFromDirectThreads[0];
          const roleTypeOfFirstUser = _.get(firstUser, 'roleType');

          // Check the roleType of the first user
          if (
            roleTypeOfFirstUser &&
            roleTypeOfFirstUser === 'Platform Admin' &&
            filterParticipantsFromDirectThreads.length > 1
          ) {
            const secondUser = filterParticipantsFromDirectThreads[1];
            const communicationUserIdOfSecondUser = _.get(secondUser, 'id.communicationUserId', '');
            navigate(`/app/chat/?commId=${communicationUserIdOfSecondUser}`);
          } else {
            const communicationUserIdOfFirstUser = _.get(firstUser, 'id.communicationUserId', '');
            navigate(`/app/chat/?commId=${communicationUserIdOfFirstUser}`);
          }
        }
      }
    }
  };

  useEffect(() => {
    // dispatch(fetchUserProfile());
    dispatch(
      fetchCommunityMembers({
        orgId: getUserDetails().orgId,
        location: getUserDetails().location
      })
    );
  }, []);

  useEffect(() => {
    if (!threadLoading && !chatUser && !chatLoading) {
      navigateToTheChat();
    }
  }, [threadLoading]);

  const handleCreateGroup = () => {
    dispatch(
      dialogActions.atnOpenDialog({
        dialogName: ACS_CREATE_CHANNEL_DIALOG,
        title: 'Create Group'
      })
    );
  };

  const handleCreateNewChat = () => {
    dispatch(
      dialogActions.atnOpenDialog({
        dialogName: ACS_CREATE_DIRECT_MESSAGE_DIALOG,
        title: 'Select Member'
      })
    );
  };

  useEffect(() => {
    const chatUser = localStorage.getItem('user');

    if (activeChat !== '' && !chatUser) {
      queryClient.fetchQuery({ queryKey: ['getAcsChatThreads'] });
    }
    if (activeChat === 'Chats' && chatType === 'Groups') {
      const personalChat = _.head(filteredDirectMessages);
      if (personalChat) {
        navigate(`/app/chat/?threadid=${_.get(personalChat, 'id')}&type=Chats&name=${_.get(personalChat, 'topic')}`);
      }
    }
    if (activeChat === 'Groups' && chatType === 'Chats') {
      if (groupMessages.length) {
        const firstGroup = _.head(groupMessages);
        navigate(`/app/chat/?threadid=${_.get(firstGroup, 'id')}&type=Groups&name=${_.get(firstGroup, 'topic')}`);
      }
    }
  }, [activeChat]);

  useEffect(() => {
    if (isChatNavigated) {
      const naviagtedUser = JSON.parse(isChatNavigated);
      dispatch(fetchChatUsers(naviagtedUser.name)).then((response) => {
        const requiredUser = response.payload.find((each: any) => each.userId === naviagtedUser.userId);
        if (requiredUser !== undefined) {
          // console.log('fetchAcsToken');
          dispatch(selectedChatUserActions.update(requiredUser));

          dispatch(fetchAcsToken({ communicationId: requiredUser.communicationId, tag: '#admin' })).then(() => {
            // dispatch(
            //   acsChannelActions.atnSaveChannels({
            //     groupThreads: [],
            //     personalThreadsArr: []
            //   })
            // );

            queryClient.fetchQuery({ queryKey: ['getAcsChatThreads'] });
            localStorage.removeItem('user');
          });
        } else {
          localStorage.removeItem('acsToken');
          localStorage.removeItem('user');
          localStorage.setItem('chatAccessed', JSON.stringify(true));
          dispatch(
            fetchAcsToken({
              communicationId: getUserDetails().communicationUserId,
              tag: '#admin'
            })
          ).then(() => {
            dispatch(
              acsChannelActions.atnSaveChannels2({
                groupThreads: [],
                personalThreadsArr: []
              })
            );
            queryClient.fetchQuery({ queryKey: ['getAcsChatThreads'] });
          });
          dispatch(
            selectedChatUserActions.update({
              communicationId: '',
              userId: '',
              name: ''
            })
          );
          let searchTerm = '';
          dispatch(fetchChatUsers(searchTerm));
          navigate('/app/chat', { replace: true });
        }
      });
    }
  }, []);

  const chatSideBar = () => {
    return (
      <ChatSideBar
        loading={chatLoading || acsTokenLoading || (chatAccessed ? threadLoading : false)}
        activeChat={activeChat}
        loginUserProfileImage={headshot}
        loginUserFName={firstName}
        handleCreateGroup={handleCreateGroup}
        handleCreateNewChat={handleCreateNewChat}
        chatType={chatType}
        activeMessageId={messageId}
        chatPopupClick={chatPopupClick}
        chatPopupClose={chatPopupClose}
        chatPopup={chatPopup}
        anchorEl={anchorEl}
        setActiveChat={setActiveChat}
        filteredDirectMessages={filteredDirectMessages}
        formattedAcsOrgUsers={formattedAcsOrgUsers}
        threadValue={threadValue}
        commId={commId}
        activeItemRef={activeItemRef}
        filterParticipantsFromDirectThreads={filterParticipantsFromDirectThreads}
        groupMessages={groupMessages}
      />
    );
  };

  /** Return cases */
  if (isChatNavigated) {
    return <AppLoader />;
  }

  if (forMobile) {
    if (chatKind === 'chat') {
      return <Box className="containerBox">{chatSideBar()}</Box>;
    } else if (chatKind === 'chatDetails') {
      return (
        <>
          {/* Back for mobile */}
          {/* <Box display={'flex'} mb={.5} alignContent={'center'} sx={{cursor: 'pointer', '& svg':{ height: '20px' },}} onClick={() => dispatch(acsMessageNotificationActions.atnSetChatKind('chat'))}>
            <ArrowBackIosNewOutlinedIcon />
            <Typography variant='h5' lineHeight={'normal'}>Back</Typography>
          </Box> */}
          <Box className="containerBox chat-containerBox">
            <ChatBody
              data={data}
              isLoading={isLoading}
              error={error}
              hasNextPage={hasNextPage}
              fetchNextPage={fetchNextPage}
              isFetchingNextPage={isFetchingNextPage}
            />
          </Box>
        </>
      );
    } else return null;
  } else if (!forMobile) {
    return (
      <Box className="containerBox">
        {chatSideBar()}

        <ChatBody
          data={data}
          isLoading={isLoading}
          error={error}
          hasNextPage={hasNextPage}
          fetchNextPage={fetchNextPage}
          isFetchingNextPage={isFetchingNextPage}
        />
      </Box>
    );
  } else return null;
};

export default Chat;
