interface IRole {
  [key: string]: string;
}
interface SideBarComponents {
  [key: string]: IRole;
}

const groupRoleMappings: SideBarComponents = {
  starventure: {
    mentee: 'Entrepreneur',
    Mentees: 'Entrepreneurs',
    admin: 'Admin',
    mentor: 'Mentor',
    Mentors: 'Mentors',
    programFriend: ' Program Friend',
    defaultTimezone: ''
  },
  pepperdineuniversity: {
    mentee: 'Mentee',
    Mentees: 'Mentees',
    admin: 'Admin',
    mentor: 'Coach',
    Mentors: 'Coaches',
    programFriend: ' Program Friend',
    defaultTimezone: ''
  },
  theagileexecutive: {
    mentee: 'Client',
    Mentees: 'Clients',
    admin: 'Admin',
    mentor: 'Consultant',
    Mentors: 'Consultants',
    programFriend: ' Program Friend',
    defaultTimezone: 'UTC'
  },
  default: {
    mentee: 'Mentee',
    Mentees: 'Mentees',
    admin: 'Admin',
    mentor: 'Mentor',
    Mentors: 'Mentors',
    programFriend: ' Program Friend',
    defaultTimezone: ''
  }
};

export default groupRoleMappings;
