import React, { useState } from 'react';
import {
  Chip,
  Grid,
  TextField,
  InputLabel,
  FormControl,
  Autocomplete,
  DialogActions,
  DialogContent,
  FormHelperText
  // FormHelperText,
} from '@mui/material';
import _ from 'lodash';
// import MyTooltip from './MyTooltip';
import { LoadingButton } from '@mui/lab';
import useProfileDetails from '../../../hooks/useProfileDetails';
import { camelCaseToCapital, capitalToCamelCaseTopics } from '../../../utils/topicsMapping';
import { camelCaseToCapitalIndustries, capitalToCamelCaseIndustries } from '../../../utils/industriesMapping';
import { getUserDetails } from '../../../utils/orgName';
import { API } from '../../../api';
import { toast } from 'react-toastify';
import { useAppDispatch } from '../../../redux/hooks';
import { userActions } from '../../../redux/slices/user-profile/userProfieSlice';

interface IProps {
  handleOpenPopup: any;
}
const IcanHelpYouWithForm = ({ handleOpenPopup }: IProps) => {
  const { ftue, industries, topics, selectedTopics, selectedIndustries } = useProfileDetails();
  const { orgId } = getUserDetails();

  const getDialogActions = () => (
    <DialogActions>
      {ftue && (
        <LoadingButton
          variant="outlined"
          color="secondary"
          fullWidth
          id="i-can-help-you-with-submit-back"
          onClick={() => {}}
        >
          Back
        </LoadingButton>
      )}
      <LoadingButton
        color="primary"
        variant="contained"
        fullWidth
        // onClick={onSubmit}
        id="i-can-help-you-with-Submit"
        onClick={handleSave}
      >
        Save
        {/* {nextPopUpOrNot === 1 && ftue ? 'Finish' : nextPopUpOrNot > 1 && ftue ? 'Next' : 'Save'} */}
      </LoadingButton>
    </DialogActions>
  );
  const [topicValues, setTopics] = useState(camelCaseToCapital(selectedTopics));
  const [industryValues, setIndustries] = useState(camelCaseToCapitalIndustries(selectedIndustries));
  const convertedTopics = camelCaseToCapital(topics);
  const convertedIndustries = camelCaseToCapitalIndustries(industries);
  const dispatch = useAppDispatch();
  const [error, setError] = useState({
    topicError: false,
    industryError: false
  });
  const handleTopicsChange = (event: any, value: any) => {
    if (value.length !== 0) {
      setError({ ...error, topicError: false });
    } else {
      setError({ ...error, topicError: true });
    }
    setTopics(value);
  };
  const handleSave = async () => {
    if (topicValues.length === 0) {
      setError({ ...error, topicError: true });
      return;
    }
    if (industryValues.length === 0) {
      setError({ ...error, industryError: true });
      return;
    }
    const convertedIndustries = capitalToCamelCaseIndustries(industryValues);
    const convertedTopics = capitalToCamelCaseTopics(topicValues);
    dispatch(userActions.userIndustriesUpdate(convertedIndustries));
    dispatch(userActions.userTopicsUpdate(convertedTopics));
    handleOpenPopup(false);

    try {
      await Promise.all([
        API.saveIndustriesForUser({
          orgId,
          data: { industries: convertedIndustries }
        }),
        API.saveTopicsForUser({ orgId, data: { topics: convertedTopics } })
      ]);
    } catch (e) {
      toast.error(_.get(e, 'response.data.messgae', `Couldn't update topics/industries`));
    }
  };
  const handleIndustryChange = (event: any, value: any) => {
    if (value.length !== 0) {
      setError({ ...error, industryError: false });
    } else {
      setError({ ...error, industryError: true });
    }
    setIndustries(value);
  };
  return (
    <>
      <DialogContent>
        <Grid item xs>
          <InputLabel>Topics *{/* <MyTooltip gapleft={0.5} title=" " /> */}</InputLabel>
          <FormControl fullWidth>
            <Autocomplete
              multiple
              clearOnBlur
              selectOnFocus
              handleHomeEndKeys
              id="i-can-help-you-with"
              options={_.sortBy(convertedTopics)}
              renderInput={(params) => <TextField {...params} placeholder={'Select topics'} />}
              renderTags={(value, getTagProps) =>
                value?.map((option, index) => {
                  return <Chip label={option} {...getTagProps({ index })} />;
                })
              }
              value={topicValues}
              onChange={handleTopicsChange}
              disableCloseOnSelect
            />
          </FormControl>

          {error.topicError && (
            <FormHelperText error sx={{ mt: 1 }}>
              Please select at least one topic
            </FormHelperText>
          )}
        </Grid>
        <Grid item xs sx={{ marginTop: '20px' }}>
          <InputLabel>Industries *{/* <MyTooltip gapleft={0.5} title=" " /> */}</InputLabel>
          <FormControl fullWidth>
            <Autocomplete
              multiple
              clearOnBlur
              selectOnFocus
              handleHomeEndKeys
              id="i-can-help-you-with"
              options={_.sortBy(convertedIndustries)}
              renderInput={(params) => <TextField {...params} placeholder={'Select industries'} />}
              renderTags={(value, getTagProps) =>
                value?.map((option, index) => {
                  return <Chip label={option} {...getTagProps({ index })} />;
                })
              }
              disableCloseOnSelect
              onChange={handleIndustryChange}
              value={industryValues}
            />
          </FormControl>
          {error.industryError && (
            <FormHelperText error sx={{ mt: 1 }}>
              Please select at least one industry
            </FormHelperText>
          )}
        </Grid>
      </DialogContent>
      {getDialogActions()}
    </>
  );
};

export default IcanHelpYouWithForm;
