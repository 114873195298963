import React, { useEffect, useRef, useState } from 'react';
import { Box, Link, Typography } from '@mui/material';
import { ITaskData } from '../../../../../../../redux/slices/tasksSlice';
import LevelItem from './LevelItem';
import SectionLevel from './SectionLevel';
import uuid from 'react-uuid';

interface Props {
  setSelectedTask: any;
  selectedTaskId: string;
  tempTasks: ITaskData | null;
  setTempTasks: any;
  selectedLevelId: string;
  setLevelId: any;
}

const TaskPreviewLeftBar: React.FC<Props> = ({
  setSelectedTask,
  selectedTaskId,
  tempTasks,
  setTempTasks,
  selectedLevelId,
  setLevelId
}) => {
  const handleAddLevels = (id?: string) => {
    if (id && tempTasks && tempTasks.levels) {
      const newTasks = tempTasks.levels.map((level) => {
        if (level._id === id) {
          return { ...level, isLevelAdd: true };
        }
        return level;
      });
      setTempTasks({ ...tempTasks, levels: newTasks });
      setFocusFlag(true);
    } else if (!id && tempTasks) {
      const newTasks = [
        ...tempTasks.levels,
        {
          _id: uuid(),
          levelName: '',
          tasks: [],
          isLevelAdd: true,
          level: tempTasks.levels.length + 1,
          isNewLevel: true
        }
      ];
      setTempTasks({ ...tempTasks, levels: newTasks });
      setFocusFlag(true);
    }
  };
  const handleDeleteLevel = (levelId: string) => {
    if (levelId && tempTasks && tempTasks.levels) {
      const newTasks = tempTasks?.levels.filter((level) => level._id !== levelId) || [];
      setTempTasks({ ...tempTasks, levels: newTasks });
    }
    if (selectedLevelId === levelId) {
      setSelectedTask({});
    }
  };

  const levelRef = useRef<HTMLInputElement | null>(null);
  const [focusFlag, setFocusFlag] = useState(false);
  const moveLevel = (fromIndex: number, toIndex: number) => {
    setTempTasks((prev: any) => {
      const newLevels = [...prev.levels];
      const [removedLevel] = newLevels.splice(fromIndex, 1);
      newLevels.splice(toIndex, 0, removedLevel);
      return { ...prev, levels: newLevels };
    });
  };

  useEffect(() => {
    if (levelRef.current && focusFlag) {
      levelRef.current.focus();
    }
  }, [focusFlag]);

  return (
    <Box className="CardBox" pb={2} height={"100%"} overflow={'auto'}>
      <Box display="flex" alignItems="center" p={2} pb={0}>
        <Typography variant="h5" pr={1}>
          Levels
        </Typography>
        <Link color="#0071A9" variant="caption" onClick={() => handleAddLevels()}>
          + Add Level
        </Link>
      </Box>

      {tempTasks?.levels.length !== 0 ? (
        <Box>
          {tempTasks?.levels.map((level, levelIndex) => {
            return (
              <React.Fragment key={level._id}>
                <SectionLevel moveLevel={moveLevel} levelIndex={levelIndex} />
                <LevelItem
                  setSelectedTask={setSelectedTask}
                  level={level}
                  selectedTaskId={selectedTaskId}
                  tempTasks={tempTasks}
                  setTempTasks={setTempTasks}
                  levelIndex={levelIndex}
                  levelRef={levelRef}
                  setLevelFlag={setFocusFlag}
                  handleDeleteLevel={handleDeleteLevel}
                  setLevelId={setLevelId}
                  moveLevel={moveLevel}
                />
                {tempTasks.levels.length - 1 === levelIndex && (
                  <SectionLevel moveLevel={moveLevel} levelIndex={levelIndex} />
                )}
              </React.Fragment>
            );
          })}
        </Box>
      ) : (
        <Box flex={1} pt={3}>
          <Typography textAlign={'center'}>No Levels Found</Typography>
        </Box>
      )}
    </Box>
  );
};

export default TaskPreviewLeftBar;
