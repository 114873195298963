import { useRef, useState } from 'react';
import { PlayIcon } from '../../../../../utils/Icons';
import { Box } from '@mui/material';
interface Props {
  videoUrl: string;
  parentComponent: string;
  autoPlay?: boolean;
}
const VideoSection = ({ videoUrl, parentComponent, autoPlay = true }: Props) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [showControls, setShowControls] = useState(false);
  const handlePlay = () => {
    const video = videoRef.current;
    if (video) {
      video.play();
      const playIcon = document.querySelector('.play-icon') as HTMLElement | null;
      if (playIcon) {
        playIcon.style.display = 'none';
        setShowControls(true);
      }
    }
  };
  const handlePause = () => {
    const playIcon = document.querySelector('.play-icon') as HTMLElement | null;
    if (playIcon) {
      playIcon.style.display = 'block'; // Show the play icon when video is paused
      setShowControls(false);
    }
  };
  const handleEnded = () => {
    const playIcon = document.querySelector('.play-icon') as HTMLElement | null;
    if (playIcon) {
      playIcon.style.display = 'block'; // Show the play icon when video has ended
      setShowControls(false);
    }
  };

  return (
    <>
      <video
        src={videoUrl}
        key={videoUrl}
        className="Profile-video-element"
        ref={videoRef}
        controls={showControls}
        onPlay={handlePlay}
        onPause={handlePause}
        onEnded={handleEnded}
        autoPlay={autoPlay}
      />
      <Box className="play-icon" onClick={handlePlay}>
        {parentComponent === 'chatMain' ? <PlayIcon size={30} /> : <PlayIcon size={52} />}
      </Box>
    </>
  );
};

export default VideoSection;
