import { Box, Stack } from "@mui/material";
import MySkeleton from "../components/Skeleton";

const ChatGroupDetailsLoader = () => (
  <Box className="GroupDetailsBox" overflow={'hidden'} height={'100%'}>
    <Stack direction="row" alignItems="center" justifyContent="space-between" p={2}>
      <MySkeleton width={150} />
      <MySkeleton variant='rounded' height={25} width={25}/>
    </Stack>
    <MySkeleton height={2} />
    <Box height={'calc(100% - 126px)'} overflow={'auto'} p={2}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center" gap="8px" width="calc(100% - 30px)">
          <MySkeleton variant='rounded' height={50} width={50} />
          <MySkeleton width={150} />
        </Box>
        <MySkeleton variant='rounded' height={25} width={25}/>
      </Box>
      <MySkeleton height={2} className="my-20" />

      <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
        <MySkeleton width={150} />
        <MySkeleton variant='circular' height={20} width={20}/>
      </Box>
      {[...Array(5).keys()].map((_, index, array) => (
        <>
          <Box display={'flex'} gap={'8px'}>
            <MySkeleton variant='rounded' height={48} width={48}/>
            <Box
              display="flex"
              alignItems="center"
              whiteSpace="nowrap"
              justifyContent="space-between"
              width="calc(100% - 58px)"
              gap="8px"
            >
              <MySkeleton width={150} />
              <MySkeleton variant='rounded' height={20} width={50}/>
            </Box>
          </Box>
          {index !== array.length - 1 && <MySkeleton height={2} className="my-10"/>}
        </>
      ))}
    </Box>
    <Box p={2}>
      <MySkeleton width={150} />
    </Box>
  </Box>
);

export default ChatGroupDetailsLoader;
