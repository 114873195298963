/* eslint-disable guard-for-in */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-param-reassign */
/* eslint-disable radix */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { Controller, FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { Dialog } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { API, Education1 } from '../../../api';
import { getUserDetails } from '../../../utils/orgName';
import { useAuth0 } from '@auth0/auth0-react';
import _ from 'lodash';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { popupActions } from '../../../redux/slices/onboardingPopupsSlice';
import { userEducationActions } from '../../../redux/slices/user-education/userEducationSlice';
import EducationForm from './EducationForm';
import CommonModalHeader from '../../../components/DialogUi/CommonModalHeader';

export interface Education {
  [key: string]: string | string[] | null;
  minor: string[] | null;
  major: string;
  graduation_date: string;
  university: string;
  department: string;
}

export interface EducationError {
  minor: string;
  major: string;
  graduation_date: string;
  university: string;
  department: string;
}

export interface graduationDateError {
  graduation_date?: string;
}

interface FilmOptionType {
  inputValue?: string;
  value?: string;
  type?: string;
}

const schema = yup.object({
  university: yup
    .string()
    .required('College/University is required')
    .test('no-empty-spaces', 'College/University cannot be all empty spaces', (value) => {
      if (value) {
        return !/^[\s]+$/.test(value);
      }
      return true;
    }),
  major: yup
    .string()
    .required('Major/Degree is required')
    .test('no-empty-spaces', 'Major cannot be all empty spaces', (value) => {
      if (value) {
        return !/^[\s]+$/.test(value);
      }
      return true;
    }),
  minor: yup.mixed().nullable(),
  department: yup
    .string()
    .required('Department is required')
    .test('no-empty-spaces', 'Minor cannot be all empty spaces', (value) => {
      if (value) {
        return !/^[\s]+$/.test(value);
      }
      return true;
    }),
  graduation_date: yup
    .number()
    .transform((value, originalValue) => {
      if (originalValue === '') {
        return null;
      }
      return value;
    })
    .typeError('Please Enter a Year')
    .test('no-empty-spaces', 'Graduation Year cannot be all empty spaces', (value) => {
      if (value) {
        return !/^[\s]+$/.test(String(value));
      }
      return true;
    })
    .required('Graduation date is required')
    .min(1900, 'Must be greater than 1900')
    .max(new Date().getFullYear(), `Must be less than ${new Date().getFullYear()}`)
});

interface AllListData {
  approved: boolean;
  oid: string;
  department: string;
  region: string;
  type: string;
  value: string;
  _id: string;
}

const EducationModal = () => {
  const { user } = useAuth0();
  const { userId, location, orgId } = getUserDetails();

  const [data, setData] = useState<Education[]>([]);
  const [allListDepartment, setAllListDepartment] = useState<AllListData[]>([]);
  const [uniqueDeptArr, setUniqueDeptArr] = useState<string[]>([]);
  const [allListUniversity, setAllListUniversity] = useState<AllListData[]>([]);
  const [allListMajorDegree, setAllListMajorDegree] = useState<AllListData[]>([]);
  const [allListMinor, setAllListMinor] = useState<AllListData[]>([]);

  const [submitLoader, setSubmitLoader] = useState(false);
  const { open, action, item, educationPopUpDisable } = useAppSelector(
    (state) => state.onboardingPopups.data.education
  );
  const [openDelete, setDelete] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);

  const [universityValue, setUniversityValue] = useState('');
  const [activeId, setActiveId] = useState('');
  const [majorDegreeValue, setMajorDegreeValue] = useState('');
  const [minorValue, setMinorValue] = useState('');
  const [dept, setDept] = useState('');
  const [majorNewValue, setMajorNewValue] = useState('');
  const dispatch = useAppDispatch();
  const education = useAppSelector((state) => state.onboardingPopups.data.education);

  const typeU = 'University Name';
  const typeMD = 'Degree';
  const typeM = 'Minors';
  const valueU = universityValue;
  const valueMD = majorDegreeValue;
  const valueM = minorValue;
  const getDeptObj = (newValue: string) => {
    setMajorNewValue(newValue);
  };

  const UniversityList: string[] = allListUniversity ? allListUniversity?.map((item) => item.value) : [];
  const majorDegreeList: string[] = allListMajorDegree ? allListMajorDegree?.map((item) => item.value) : [];
  const minorList: string[] = allListMinor ? allListMinor?.map((item) => item.value) : [];

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    reset
  } = useForm({
    resolver: yupResolver(schema)
  });

  const checkError = (fieldName: string) => Boolean(errors[fieldName]);
  const getError = (fieldName: string) => errors[fieldName]?.message;

  useEffect(() => {
    fetchDataDepartment();
  }, []);

  useEffect(() => {
    setUniqueDeptArr(allListDepartment?.map((each: AllListData) => each.value));
  }, [allListDepartment]);

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      fetchDataUnivercity();
    }, 100);
    return () => {
      clearTimeout(debounceTimer);
    };
  }, [universityValue]);

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      fetchDataMajorDegree();
    }, 100);
    return () => {
      clearTimeout(debounceTimer);
    };
  }, [majorDegreeValue]);

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      fetchDataMinor();
    }, 100);
    return () => {
      clearTimeout(debounceTimer);
    };
  }, [minorValue]);

  useEffect(() => {
    if (action === 'Add') {
      const deptObject = allListMajorDegree?.find((deg) => deg?.value === majorNewValue);
      setDept(deptObject?.department || '');
      setValue('department', deptObject?.department || '');
    }
  }, [majorNewValue]);

  const handleCloseEducationDetailed = () => {
    if (!educationPopUpDisable) {
      dispatch(
        popupActions.updatePopups({ education: { ...education, open: false, educationPopUpDisable: false, action } })
      );
    }
  };

  useEffect(() => {
    if (open && action === 'Edit') {
      reset(item);
    } else {
      reset({});
    }
  }, [open, action]);

  const handleDelete = async () => {
    setDeleteLoader(true);
    try {
      const response = await API.deleteEducation({
        userId,
        location,
        id: item._id
      });
      if (response?.status === 200 && response?.statusText === 'OK') {
        const newData = Array.isArray(data) && data?.length > 0 ? data?.filter((each) => each._id !== activeId) : [];
        setData(newData);
        setDelete(false);
        setActiveId('');
        setDeleteLoader(false);
        toast.success('Education details deleted successfully');
        dispatch(userEducationActions.deleteEducation(item));
        handleCloseEducationDetailed();
      }
    } catch (e) {
      toast.error('Education details failed to update');
      setDeleteLoader(false);
    }
  };

  useEffect(() => {
    return () => {
      handleCloseEducationDetailed();
    };
  }, []);

  const onSubmit: SubmitHandler<FieldValues> = async (values) => {
    const requiredData: Education1 = {
      university: values.university,
      major: values.major,
      minor: values.minor,
      graduation_date: values.graduation_date.toString(),
      department: values.department
    };

    setSubmitLoader(true);
    const submitData = {
      userId: getUserDetails()?.userId,
      location: getUserDetails()?.location,
      formData: requiredData
    };
    if (action === 'Add') {
      try {
        const response = await API.addEducationAndSkills(submitData);
        if (response?.status === 200 && response?.statusText === 'OK') {
          setSubmitLoader(false);
          if (response?.data.response) {
            dispatch(userEducationActions.addEducation(response?.data.response));
          }
          handleCloseEducationDetailed();
          toast.success('Education history added successfully');
        }
      } catch (e) {
        toast.error(`Education details failed to add`);
        setSubmitLoader(false);
      }
    }
    if (action === 'Edit') {
      const data2 = data?.map((each) => {
        if (each._id === activeId) {
          return { ...requiredData, _id: each._id };
        }
        return each;
      });
      const withoutId: any = {};
      const newData: any = submitData?.formData;
      for (let i in newData) {
        if (i !== '_id') {
          withoutId[i] = newData[i];
        }
      }
      try {
        const response = await API.updateEducationAndSkills({
          ...submitData,
          formData: withoutId,
          id: item._id
        });
        if (response?.status === 200 && response?.statusText === 'OK') {
          setSubmitLoader(false);
          setData(data2);
          dispatch(userEducationActions.editEducation(values));
          toast.success('Education details updated successfully');
        }
        handleCloseEducationDetailed();
      } catch (e) {
        toast.error('Education details failed to update');
        setSubmitLoader(false);
      }
    }
  };

  const fetchDataUnivercity = async () => {
    try {
      const responseUniversity = await API.getTagsListnew(orgId, location, typeU, valueU);
      setAllListUniversity(responseUniversity?.data?.tagListResponse);
    } catch (error) {
      toast.error("University list failed to load");
    }
  };

  const fetchDataMajorDegree = async () => {
    try {
      const responseMajorDegree = await API.getTagsListnew(orgId, location, typeMD, valueMD);
      setAllListMajorDegree(responseMajorDegree?.data?.tagListResponse);
    } catch (error) {
      toast.error("Majors list failed to load");
    }
  };

  const fetchDataMinor = async () => {
    try {
      const responseMinor = await API.getTagsListnew(orgId, location, typeM, valueM);
      setAllListMinor(responseMinor?.data?.tagListResponse);
    } catch (error) {
      toast.error("Minors list failed to load");
    }
  };

  const fetchDataDepartment = async () => {
    try {
      const responseDepartment = await API.getTagsListnew(orgId, location, 'department', '');
      setAllListDepartment(responseDepartment?.data?.tagListResponse);
    } catch (error) {
      toast.error("Department list failed to load");
    }
  };

  const handleMinor = (event: React.SyntheticEvent<Element, Event>, value: string[]) => {
    setValue('minor', value);
    errors['minor'] = undefined;
  };
  return (
    <>
      <Dialog open={open} onClose={handleCloseEducationDetailed}>
        <CommonModalHeader title={`${action} Education`} handleCloseModal={handleCloseEducationDetailed} />
        <EducationForm />
      </Dialog>
    </>
  );
};

export default EducationModal;
