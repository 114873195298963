/* eslint-disable prefer-destructuring */
/* eslint-disable react/jsx-no-useless-fragment */
import React, { useState } from 'react';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { API } from '../../../../api';
import { Box, Typography } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import bigchaticon from '../../../../assets/images/bigchaticon.svg';
import { useAuth0 } from '@auth0/auth0-react';
import { useAppSelector } from '../../../../redux/hooks';
import { getAcsUserByCommunicationId } from '../../../../routes/helpers';
import { LoadingButton } from '@mui/lab';
import { useQueryClient } from '@tanstack/react-query';
// import { dialogActions } from '../../../../redux/slices/dialog-slice/dialogSlice';
// import { ACS_CREATE_CHANNEL_DIALOG } from '../../../../constants';
import { useXpPoints } from '../../components/menteeXpPoints';
// // import ROLES from '../../../../utils/roles';
// import useProfileDetails from '../../../../hooks/useProfileDetails';
import MySkeleton from '../../../../components/Skeleton';
import { showMyLoader } from '../../components/common';
import { getUserDetails } from '../../../../utils/orgName';

// const handleScrollChannels = () => {
//   const collection = document.getElementsByClassName('scroll-channel')[0];
//   collection.scrollTo(0, 0);
// };

interface INoChats {
  activeChat?: string;
}

interface ChatBoxProps {
  icon?: string;
  heading: string;
  subheading?: string;
  buttonText?: string;
  onClick?: () => void;
  btnLoading?: boolean;
  loading?: boolean;
  disabled?: boolean;
}

const NoChats: React.FC<INoChats> = ({ activeChat }) => {
  // const role = localStorage.getItem('role');
  const selectedChatUser = useAppSelector((state) => state.selectedChatUser.data);
  const getXpPoints = useXpPoints();
  // const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();
  const commId = searchParams.get('commId') || '';
  // const acsOrgUsers = useAppSelector(state => state.acsOrgUsers.data)
  const acsCommunityUsers = useAppSelector((state) => state.acsCommunityUsers.data);
  const { user } = useAuth0();
  const [channelCreateLoading, setChannelCreateLoading] = useState(false);
  const acsToken = useAppSelector((state) => state.acsToken.data.token);
  const participant = getAcsUserByCommunicationId(acsCommunityUsers, commId);
  // const { mentee } = useProfileDetails();
  const selectedGroup = useAppSelector((state) => state.selectedGroup.data);
  const urlSearchParams = new URLSearchParams(document.location.hash);
  // const chatType = urlSearchParams.get('type') || 'Chats';
  const loading = showMyLoader();
  const navigate = useNavigate();

  const handleClickOnStartChat = async () => {
    const { orgId } = getUserDetails();
    const modifiedValues = {
      participants: [
        {
          ...participant,
          displayName: _.get(participant, 'id.communicationUserId', ''),
          id: {
            communicationUserId: _.get(participant, 'id.communicationUserId', '')
          }
        }
      ],
      topic: '#personal',
      azureToken: acsToken
    };
    setChannelCreateLoading(true);
    try {
      const { status, data } = await API.createACSThread(acsToken, modifiedValues, orgId);
      if (status === 200) {
        setTimeout(async () => {
          await queryClient.fetchQuery({ queryKey: ['getAcsChatThreads'] }).then((res) => {
            setChannelCreateLoading(false);
            navigate(
              `/app/chat/?threadid=${data.threadId}&name=${_.get(participant, 'id.communicationUserId', '')}&type=Chats`
            );
          });
        }, 500);

        if (selectedGroup.role === 'mentee') {
          getXpPoints('mentee');
        }
      }
    } catch (err) {
      setChannelCreateLoading(false);
      toast.error(_.get(err, 'response.data', ''));
    }
  };

  // const handleCreateGroup = () => {
  //   dispatch(
  //     dialogActions.atnOpenDialog({
  //       dialogName: ACS_CREATE_CHANNEL_DIALOG,
  //       title: 'Create a Channel'
  //     })
  //   );
  // };

  /** Start chat box design */
  const renderChatBox: React.FC<ChatBoxProps> = ({
    icon,
    heading,
    subheading,
    buttonText,
    onClick,
    btnLoading,
    loading,
    disabled
  }) => {
    return (
      <Box gap={'20px'} height="215px" display={'flex'} alignItems={'center'} flexDirection={'column'}>
        {/* Icon */}
        {icon &&
          (loading ? (
            <MySkeleton variant="circular" width={104} height={104} minHeight="104px" />
          ) : (
            <Box
              width={'104px'}
              height={'104px'}
              minHeight={'104px'}
              bgcolor={'#FFFFFF'}
              display="flex"
              alignItems="center"
              justifyContent="center"
              borderRadius={'50%'}
              boxShadow={'0px 8px 20px rgba(0, 0, 0, 0.16)'}
            >
              <img src={icon} alt="chaticon" height={'66'} width={'66'} />
            </Box>
          ))}

        {/* Heading & Subheading */}
        <Box textAlign="center">
          <Typography variant="h5" color="secondary">
            {loading ? <MySkeleton width={200} /> : heading}
          </Typography>

          {subheading && <Typography mt={1}>{loading ? <MySkeleton width={200} /> : subheading}</Typography>}
        </Box>

        {/* Button */}
        {buttonText &&
          onClick &&
          (loading ? (
            <MySkeleton variant="rounded" width={162} minHeight={50} />
          ) : (
            <Box>
              <LoadingButton
                loading={btnLoading}
                color="primary"
                variant="contained"
                onClick={onClick}
                className="ChatNowButton"
                disabled={disabled}
                id="startAChatButton"
              >
                {buttonText}
              </LoadingButton>
            </Box>
          ))}
      </Box>
    );
  };

  return (
    <Box p={3} height={'100%'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
      {commId
        ? renderChatBox({
            icon: bigchaticon,
            heading: 'To start a chat conversation click on the button below.',
            buttonText: 'Start a chat',
            onClick: handleClickOnStartChat,
            btnLoading: channelCreateLoading,
            loading: loading,
            disabled: selectedChatUser.communicationId ? true : false
          })
        : renderChatBox({
            icon: bigchaticon,
            heading: 'Start a chat',
            subheading: 'Click on a Member to start a conversation with them',
            loading: loading
          })}
    </Box>
  );
};

export default NoChats;
