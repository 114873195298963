/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import ProgramTable from '../ProgramTable';
import ProgramCards from '../ProgramCards';
import { Box, Typography } from '@mui/material';
import { lockIcon } from '../../../../components/common';
import { useAppSelector } from '../../../../../../redux/hooks';
import useResponsiveScreenSwitch from '../../../../../../utils/useResponsiveScreenSwitch';
import useProfileDetails from '../../../../../../hooks/useProfileDetails';
import OrganizationSettings from '../../../profile/OrganizationSettings';
import useOrgSettings from '../../../../../../hooks/useOrgSettings';
import { adminPanel } from '../../../../../../utils/muiStyle';
import ROLES from '../../../../../../utils/roles';

const ProgramOverview = () => {
  const mainGroup = useAppSelector((state) => state.allGroups.data?.mainGroup);
  const groupDetails = useAppSelector((state) => state.groupDetails);
  const { forMobile } = useResponsiveScreenSwitch();
  const { firstName } = useProfileDetails();
  const [active, setActive] = useState('');
  const role = localStorage.getItem('role') || '';
  const isAdmin = role === ROLES.platAdmin || role === ROLES.orgAdmin;
  const activeTab = window.location.href.split('/').find((each) => ['overview', 'settings'].includes(each));
  const isOrgSettings = useOrgSettings();
  const classs = adminPanel();

  // Define dynamic tabs
  const tabs = [
    { name: 'Overview', key: 'overview', disabled: !isOrgSettings },
    { name: 'Settings', key: 'settings', disabled: false }
  ];

  useEffect(() => {
    // if (groupDetails?.data?.type && groupDetails?.data?.type !== 'level' && role !== ROLES.platAdmin) {
    //   setActive('overview');
    // } else {
    // normal groups
    if (!isOrgSettings) {
      setActive('settings');
    } else {
      setActive('overview');
    }
    // }
  }, [isOrgSettings, groupDetails?.data?.type]);

  /** Tabs */
  function createTabWithLockIcon(disabled: boolean, tabName?: string, tabKey?: any, isActive?: any, onClick?: any) {
    return (
      <Box
        pb={1}
        gap="3px"
        key={tabKey}
        display="flex"
        alignItems="center"
        borderBottom={isActive && '3px solid #DF6438'}
        onClick={disabled ? undefined : () => setActive(tabKey)}
        className={`cursor-${disabled ? 'not-allowed' : 'pointer'}`}
      >
        {disabled && lockIcon({})}
        <Typography
          lineHeight="normal"
          fontWeight={isActive ? '700' : '400'}
          className={disabled ? 'opacity-25' : ''}
          color={isActive ? '#DF6438' : disabled ? 'grey' : 'primary'}
        >
          {tabName}
        </Typography>
      </Box>
    );
  }

  return (
    <>
      {/* New design */}
      {mainGroup && isAdmin && (
        <Box borderBottom="1px solid #EFF0F4" mb={2}>
          <Box display={forMobile ? 'block' : 'flex'} alignItems="center" justifyContent="space-between" gap="8px">
            <Box display="flex" my={1} gap="4px">
              <Typography noWrap variant="h3" fontWeight={400}>
                Welcome,
              </Typography>
              <Typography noWrap variant="h3">
                {firstName}
              </Typography>
            </Box>
            <Box display="flex" columnGap="40px">
              {tabs.map((tab) => createTabWithLockIcon(tab.disabled, tab.name, tab.key, active === tab.key, () => {}))}
            </Box>
          </Box>
        </Box>
      )}

      {active === 'settings' ? (
        <Box className={mainGroup && isAdmin ? classs.mainGroupBody : classs.mainGroup}>
          <OrganizationSettings />
        </Box>
      ) : (
        <Box className={mainGroup && isAdmin ? classs.mainGroupBody : mainGroup ? classs.mainGroup : classs.mainBody}>
          <ProgramCards />
          <ProgramTable />
        </Box>
      )}
    </>
  );
};

export default ProgramOverview;
