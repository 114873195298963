import React from 'react';
import { Box, Grid } from '@mui/material';
import MySkeleton from '../components/Skeleton';

/** Member section loader */
export const MemberLoader = ({ arrayLength }: { arrayLength: number }) => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <MySkeleton width={150} />
            </Grid>
            {[...Array(arrayLength).keys()].map((_, index) => (
                <Grid item xs={12} key={index}>
                    <MySkeleton width={'80%'} className='mb-10' />
                    <MySkeleton variant='rounded' height={37} />
                </Grid>
            ))}
        </Grid>
    );
};

/** Topics & Industry section loader */
export const TopicsAndIndustryLoader = () => {
    return (
        <Box>
            <Box display="flex" pb={2}>
                <MySkeleton width={150} />
            </Box>
            <Grid container spacing={2}>
                {[...Array(20).keys()].map((_, index) => (
                    <Grid item xs={12} md={6} key={index} display="flex" alignItems="center" gap="8px">
                        <MySkeleton variant='rounded' height={25} width={43} />
                        <MySkeleton width={150} />
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

/** Community section loader */
export const CommunityLoader = () => {
    return (
        <Box>
            <MySkeleton width={150} className='mb-20'/>
            <Grid container spacing={2}>
                {[...Array(3).keys()].map((_, index) => (
                    <Grid item xs={6} md={4} key={index} display="flex" alignItems="center" gap="8px">
                        <MySkeleton variant='rounded' height={25} width={43} />
                        <MySkeleton width={120} />
                    </Grid>
                ))}
            </Grid>
        </Box>
    )
}
/** Currency section loader */
export const CurrencyLoader = () => {
    return (
        <Box>
            <MySkeleton width={150} className='mb-20'/>
            <Grid container spacing={2}>
                {[...Array(3).keys()].map((_, index) => (
                <Grid item xs={2} key={index}>
                    <Box display={'flex'} alignItems={'center'} gap={1}>
                        <MySkeleton variant='circular' height={24} width={24} />
                        <MySkeleton width={30} />
                    </Box>
                </Grid>
                ))}
            </Grid>
        </Box>
    )
}

/** Fields section loader */
export const FieldsLoader = () => {
    return (
        <Box>
            <MySkeleton width={80} />
            <Grid container>
                <Grid item xs={4} />
                    {[...Array(4).keys()].map((_, index) => (
                        <Grid item xs={2} key={index} textAlign="center" pb={1}>
                            <MySkeleton width={40} />
                        </Grid>
                    ))}
            </Grid>
            {[...Array(14).keys()].map((_, index, array) => (
                <Grid container key={index} alignItems="center" pb={index === array.length - 1 ? 0 : 1}>
                    <Grid item xs={4}>
                        <MySkeleton width={100} />
                    </Grid>
                    {[...Array(3).keys()].map((_, index) => (
                        <Grid item xs={2} textAlign="center">
                            <MySkeleton variant='rounded' height={25} width={43} />
                        </Grid>
                    ))}
                    <Grid item xs={2} pl={'8px'}>
                        <MySkeleton variant='rounded' height={25} width={25} />
                    </Grid>
                </Grid>
            ))}
        </Box>
    )
}

/** Task section loader */
export const TaskLoader = () => {
    return (
        <Box>
            <MySkeleton width={100} className='mb-20'/>
            {[...Array(2).keys()].map((_, index) => (
                <Box display="flex" flexDirection="row" key={index} pb={index === 1 ? 0 : 2}>
                    <Box display="flex" alignItems="center" width="25%" gap={1}>
                        <MySkeleton variant='rounded' height={25} width={43} />
                        <MySkeleton width={100} />
                    </Box>
                </Box>
            ))}
        </Box>
    )
}

/** Matches settings section loader */
export const MatchesSettingsLoader = ({ display = true }) => {
    return (
        <Box>
            {display && <MySkeleton width={150} />}
            <Grid container spacing={2} pt={2}>
                {[...Array(2).keys()].map((_, index) => (
                    <Grid item xs={12} md={6} key={index}>
                        <MySkeleton width={150} className='mb-10'/>
                        <MySkeleton variant='rounded' height={38} />
                    </Grid>
                ))}
            </Grid>
        </Box>
    )
}

/** Signup and login link section loader */
export const LoginSignupLinksLoader = () => {
    return (
        <Box>
            <MySkeleton width={150} />
            {/* Signup */}
            <Box mb={2}>
                <MySkeleton width={100} className='mt-20'/>
                <MySkeleton width={300} className='my-10'/>
                {[...Array(3).keys()].map((_, index) => (
                    <MySkeleton key={index} width={350} className='ml-20 mb-10'/>
                ))}
                <MySkeleton variant='rounded' height={37} className='mt-20'/>
            </Box>
            <MySkeleton height={2}/>
            {/* Login */}
            <Box mt={2}>
                <MySkeleton width={100}/>
                <MySkeleton width={300} className='my-10'/>
                <MySkeleton width={350} className='ml-20 mb-20'/>
                <MySkeleton variant='rounded' height={37}/>
            </Box>
        </Box>
    );
};

/** Basic settings full loader */
const BasicSettingsLoader = () => {
    return (
        <Grid container bgcolor="#FFFFFF" border="1px solid #EFF0F4" borderRadius="8px">
            <Grid item xs={12} md={8}>
                <Box borderRight="1px solid #EFF0F4">
                    <Box p={2}>
                        <MySkeleton width={150} />
                    </Box>
                    <MySkeleton height={2} />
                    <Box p={2}>
                        <Box>
                            <Grid container spacing={2} className="basicSettings">
                                {[...Array(2).keys()].map((_, index) => (
                                    <Grid item xs={12}>
                                        <MySkeleton width={150} className='mb-10'/>
                                        <MySkeleton variant='rounded' height={37}/>
                                    </Grid>
                                ))}
                            </Grid>
                        </Box>

                        {/* Members */}
                        <MySkeleton height={2} className='my-20' />
                        {MemberLoader({arrayLength:2})}

                        {/* Topics */}
                        <MySkeleton height={2} className='my-20' />
                        {TopicsAndIndustryLoader()}

                        {/* Industries */}
                        <MySkeleton height={2} className='my-20' />
                        {TopicsAndIndustryLoader()}

                        {/* Community */}
                        {/* <MySkeleton height={2} className='my-20' />
                        {CommunityLoader()} */}

                        {/* Currency */}
                        {/* <MySkeleton height={2} className='my-20' />
                        {CurrencyLoader()} */}

                        {/* Fields */}
                        <MySkeleton height={2} className='my-20' />
                        {FieldsLoader()}

                        {/* Task */}
                        <MySkeleton height={2} className='my-20' />
                        {TaskLoader()}
            
                        {/* Matches Settings */}
                        <MySkeleton height={2} className='my-20' />
                        {MatchesSettingsLoader({ display: true })}

                        {/* Login Signup Links */}
                        <MySkeleton height={2} className='my-20' />
                        {LoginSignupLinksLoader()}
                    </Box>
                </Box>
            </Grid>

        {/* Right side */}
            <Grid item xs={12} md={4}>
                <Box position="relative" height="100%">
                    <Box p={2}>
                        <MySkeleton width={150} />
                    </Box>
                    <MySkeleton height={2} />
                    <Box 
                        p={2} 
                        display="flex" 
                        flexDirection="column" 
                        height="calc(100% - 66px)" 
                        justifyContent="space-between"
                    >
                        <Box>
                            <Box display="flex" alignItems="center" columnGap="8px">
                                <Box className="profileImageBox">
                                    <Box position="relative">
                                        <MySkeleton variant='rounded' height={80} width={80} />
                                    </Box>
                                </Box>
                                <MySkeleton width={150} />
                            </Box>
                            <Box mt={2} p={2} borderRadius="8px" border="1px dashed #DEDFDF" >
                                <Box display='flex' flexDirection='column' alignItems='center'>
                                    <MySkeleton variant='rounded' height={25} width={25}/>
                                    <MySkeleton width={150}/>
                                    <MySkeleton variant='rounded' height={30} width={105}/>
                                </Box>
                            </Box>
                        </Box>
                        <Box mt={2} display={'flex'} justifyContent={'flex-end'}>
                            <MySkeleton variant='rounded' height={50} width={146} />
                        </Box>
                    </Box>
                </Box>
            </Grid>
        </Grid>
    );
};

export default BasicSettingsLoader;
