import React from 'react';
import { Box, Divider, Grid, Link, Typography } from '@mui/material';
import ChatAvatarComponent from '../../../chat/ChatAvatarComponent';
import smileimg from '../../../../../../assets/images/smileimg.svg';
import redsmileimg from '../../../../../../assets/images/redsmileimg.svg';
import Upvotes from '../../../../components/Upvotes';

interface EngagedTableProps {
  heading?: string;
  engagedData?: {
    name?: string;
    department?: string;
    userImage?: string;
    likes?: number;
  }[];
}

const EngagedTable: React.FC<EngagedTableProps> = ({ heading, engagedData }) => {
  return (
    <Box className="CardBox">
      <Box p={2} display="flex" alignItems="center" justifyContent={'space-between'}>
        <Typography variant="h5">{heading}</Typography>
        <Link href="#" color="primary">View</Link>
      </Box>
      <Divider />
      <Box p={2}>
        {/* Heading */}
        <Grid container width={'100%'} alignItems={'center'} spacing={2} mb={2}>
          <Grid item xs={6} />
          <Grid item xs={4} textAlign={'center'}>
            <Typography variant="h6" color={'grey'}>
              ENGAGEMENT
            </Typography>
          </Grid>
          <Grid item xs={2} />
        </Grid>
        {/* Body */}
        {engagedData?.map((item, index) => (
          <React.Fragment key={index}>
            <Grid container width={'100%'} alignItems={'center'} spacing={2}>
              <Grid item xs={6} display="flex" gap={1}>
                <ChatAvatarComponent
                  image={item.userImage}
                  firstLetter={item.name?.slice(0, 1)}
                  width={'40px'}
                  height={'40px'}
                  type="noStatus"
                />
                <Box>
                  <Typography variant="h5" noWrap>
                    {item.name}
                  </Typography>
                  <Typography variant="body1" noWrap>{item.department}</Typography>
                </Box>
              </Grid>
              <Grid item xs={4} textAlign={'center'}>
                <img src={ heading === 'Most Engaged' ? smileimg : redsmileimg} alt="smile" width={'24px'} height={'24px'} />
              </Grid>
              <Grid item xs={2}>
                <Upvotes likes={item.likes} icon={'xpPoint'} />
              </Grid>
            </Grid>
            {index !== engagedData?.length - 1 && <Divider sx={{ my: 2 }} />}
          </React.Fragment>
        ))}
        {/* <Typography textAlign={'center'}>No date found</Typography> */}
      </Box>
    </Box>
  );
}

export default EngagedTable;
