/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable prettier/prettier */
/* eslint-disable react/jsx-key */
// eslint-disable-next-line react/jsx-no-useless-fragment
import React, { useEffect, useState } from 'react';
import ChatCard from './ChatCard';
import { API } from '../../../../api';
import { useDispatch } from 'react-redux';
import {Box,Grid,Link} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import PromptGroupChip from './PromptGroupChip';
import {
  setHomePage,
  clearAllConversations,
  setCurrentConversationID,
} from '../../../../redux/slices/ai-chat/aiChatMessages';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { ChatCardsLoader, TopChipsLoader } from '../../../../Loader/AiMentorLoader';

const PromptCards = () => {
  const [rightTopPop, setRightTopPop] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const getPrompts = async () => {
    setLoading(true);
    const response = await API.getPrompts();
    if (response.status === 200) {
      setData(response.data.data);
      setLoading(false);
    }
  };

  useEffect(() => {
    getPrompts();
  }, []);
  const navigate = useNavigate();

  const handlePromptTreeClick = async (ele: any) => {
    dispatch(clearAllConversations());
    dispatch(setHomePage(false));
    if (ele?.has_tree === true) {
      navigate('/app/askDosen/');
      const response = await API.newConversationHashtreeTrue(ele?.id);
      if (response.status === 200) {
        const conversationData = response.data.data;
        const promptId = ele?.id;
        if(conversationData){
          navigate('/app/askDosen/', { state: { conversationData, promptId , has_tree : true } });
          setRightTopPop(null);
        }
      }
    }
    if (ele?.has_tree === false) {
      const promptId = ele?.id;
      navigate('/app/askDosen/', { state: {  promptId} });
      const response = await API.newConversationHashtreeFalse({ promptID: ele?.id  });
      if (response.status === 200) {
        dispatch(setCurrentConversationID(response.data.data.id));
        setRightTopPop(null);
      }
    }
  };

  const promptGroups = [...new Set(data?.map((item: any) => item.group_tag))];
  const featuredPrompts = [...new Set(data?.filter((item: any) => item.featured === true))];
  const [currentTab, setCurrentTab] = useState('Featured');

  /** Loading design */
  if (loading) {
    return (
      <>
        <Box pb={1} display="flex" gap="8px" overflow={'auto hidden'}>
          <TopChipsLoader />
        </Box>
        <Box pt={1} flex={1} overflow={'hidden auto'}>
          <ChatCardsLoader />
        </Box>
      </>
    );
  }

  return (
    <>
      <Box pb={1} display="flex" gap="8px" overflow={'auto hidden'}>
        <PromptGroupChip
          groupName="Featured"
          onClick={() => setCurrentTab('Featured')}
          selected={currentTab === 'Featured'}
        />
        {promptGroups.map((group, index) => (
          <PromptGroupChip
            key={index}
            groupName={group}
            onClick={() => setCurrentTab(group)}
            selected={currentTab === group}
          />
        ))}
      </Box>
      <Box pt={1} flex={1} overflow={'hidden auto'}>
        <Grid container spacing={2}>
          {currentTab === 'Featured'
            ? featuredPrompts?.map((ele: any, index) => (
                <Grid item xs={12} md={4} display={'flex'} alignItems={'stretch'}>
                  <ChatCard
                    key={index}
                    title={ele.title}
                    describtion={ele.description}
                    // groupTag={ele.group_tag}
                    bgColour={ele.colour}
                    handlePromptTreeClick={() => handlePromptTreeClick(ele)}
                  />
                </Grid>
              ))
            : data?.map((ele: any, index) =>
                ele.group_tag === currentTab && (
                  <>
                    <Grid item xs={12} md={12} key={index} display={'flex'} gap={'8px'} alignItems={'end'}>
                      <Link
                        color={'#0071A9'}
                        fontSize={'16px'}
                        lineHeight={'normal'}
                        onClick={() => handlePromptTreeClick(ele)}
                      >
                        {ele.title} <ArrowForwardIcon />
                      </Link>
                    </Grid>
                  </>
                )
              )}
        </Grid>
      </Box>
    </>
  );
};

export default PromptCards;
