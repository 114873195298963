import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from '../../../api';
import { getUserDetails } from '../../../utils/orgName';
import { each } from 'immer/dist/internal';
import _ from 'lodash';

interface IInitialState {
  loading: boolean;
  error: boolean;
  data: any[];
  updatedData: any[];
  errorText: string;
}

const initialState: IInitialState = {
  loading: false,
  error: false,
  data: [],
  updatedData: [],
  errorText: ''
};

export const fetchUserEducation = createAsyncThunk('user/education', async (userId: string) => {
  const response = await API.getEducationAndSkills({ location: getUserDetails().location, userId: userId });
  const sortedResponse = (_.isArray(response?.data) && !_.isEmpty(response?.data))  ? response?.data?.sort((a:any, b:any) => parseInt(b?.graduation_date) - parseInt(a?.graduation_date)) : [];
  return sortedResponse;
});

const userEducationSlice = createSlice({
  name: 'user-education',
  initialState,
  reducers: {
    clearEducation: (state, action) => {
      state.loading = false;
      state.error = false;
      state.errorText = '';
      state.data = [];
    },
    updateEducation: (state, action) => {
      return { ...state, updatedData: action.payload.sort((a:any, b:any) => parseInt(b?.graduation_date) - parseInt(a?.graduation_date)) };
    },
    addEducation: (state, action) => {
      state.updatedData = [...state.updatedData, action.payload].sort((a:any, b:any) => parseInt(b?.graduation_date) - parseInt(a?.graduation_date));
    },
    editEducation: (state, action) => {
      const filteredData = state.updatedData.map((item) => {
        if (item._id === action.payload._id) {
          return action.payload;
        }
        return item;
      });
      return { ...state, updatedData: filteredData.sort((a:any, b:any) => parseInt(b?.graduation_date) - parseInt(a?.graduation_date)) };
    },
    deleteEducation: (state, action) => {
      const filteredData = state.updatedData.filter((item) => item._id !== action.payload._id);
      state.updatedData = filteredData.sort((a:any, b:any) => parseInt(b?.graduation_date) - parseInt(a?.graduation_date));
    },
    deleteAllEducation: (state) => {
      state.updatedData = [];
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserEducation.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.errorText = '';
      })
      .addCase(fetchUserEducation.fulfilled, (state, action) => {
        state.loading = false;
        // state.data = action.payload;
        state.updatedData = action.payload;
        state.error = false;
        state.errorText = '';
      })
      .addCase(fetchUserEducation.rejected, (state, action) => {
        state.loading = false;
        state.data = [];
        state.updatedData = [];
        state.error = true;
        state.errorText = action.error.message || '';
      });
  }
});

export default userEducationSlice.reducer;
export const userEducationActions = userEducationSlice.actions;
