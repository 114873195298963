import React from 'react';
import { Box } from '@mui/material';
import MySkeleton from '../components/Skeleton';
import {
    MemberLoader,
    CurrencyLoader,
    MatchesSettingsLoader,
} from './BasicSettingsLoader';

const OrganizationInformationLoader = () => {
    return (
        <Box bgcolor={'#FFFFFF'} borderRadius={'8px'} border={'1px solid #EFF0F4'}>
            <Box p={2}>
                <MySkeleton width={200} />
            </Box>
            <MySkeleton height={2}/>
            <Box p={2}>
                {/* Profile Image */}
                <Box display="flex" gap={2} alignItems="center">
                    <Box display="flex" gap={'8px'} alignItems={'center'}>
                        <MySkeleton variant='rounded' height={80} width={80} />
                        <Box display="flex" flexDirection={'column'}>
                            <MySkeleton width={150} />
                            <MySkeleton width={250} />
                        </Box>
                    </Box>
                </Box>

                {/* Organization */}
                <MatchesSettingsLoader display={false}/>

                {/* DesCription */}
                <Box pt={2}>
                    <Box display="flex" justifyContent={'space-between'} mb={1}>
                        <MySkeleton width={100} />
                        <MySkeleton width={100} />
                    </Box>
                    <MySkeleton variant='rounded' height={85} />
                </Box>

                {/* Members */}
                <MySkeleton height={2} className='my-20'/>
                <MemberLoader arrayLength={3}/>
                
                {/* Currency */}
                <MySkeleton height={2} className='my-20'/>
                <CurrencyLoader/>
                
                {/* Button */}
                <Box mt={2} display={'flex'} justifyContent={'flex-end'}>
                    <MySkeleton variant='rounded' height={50} width={146} />
                </Box>
            </Box>
        </Box>
    );
};
  
  export default OrganizationInformationLoader;
