import { Box, Grid, Divider } from "@mui/material";
import MySkeleton from "../components/Skeleton";

const QuestionAndAnswersLoader = () => {
  return ( 
    <Box gap={2} display={'flex'} flexDirection={'column'}>
        <Box border={`1px solid #ABB5BE`} borderRadius='8px' p={2}>
            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                <Box>
                    <MySkeleton width={100} />
                    <MySkeleton width={150} />
                </Box>
                <MySkeleton variant='rectangular' height={5} width={10} />
            </Box>
            <MySkeleton variant='rectangular' height={1} className='my-20' /> 
            <Box>
                <MySkeleton width={'30%'} />
                <MySkeleton width={'60%'} />
                <Grid container spacing={2} pt={1}>
                    {[...Array(3).keys()].map((_, index) => (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                            <MySkeleton variant='rounded' height={50} width={155} />
                        </Grid>
                    ))}
                </Grid>
            </Box>
            <MySkeleton variant='rectangular' height={1} className='my-20' />
            <Box>
                <MySkeleton width={'30%'} className="mb-10"/>
                <MySkeleton width={'60%'} />
                <Grid container spacing={2} pt={1}>
                    {[...Array(3).keys()].map((_, index) => (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                            <MySkeleton variant='rounded' height={50} width={155} />
                        </Grid>
                    ))}
                </Grid>
            </Box>
            <MySkeleton variant='rectangular' height={1} className='my-20' />
            <Box>
                <MySkeleton width={'30%'} className="mb-10"/>
                <MySkeleton width={'60%'} className="mb-10"/>
                <MySkeleton width={50} className="ml-10"/>
            </Box>
            {[...Array(2).keys()].map((_, index) => (
                <>
                    <MySkeleton variant='rectangular' height={1} className='my-20' />
                    <Box>
                        <MySkeleton width={'60%'} className="mb-10" />
                        <MySkeleton width={50} />
                    </Box>
                </>
            ))}

        </Box>
        {[...Array(3).keys()].map((_, index) => (
            <Box key={index} border={`1px solid #EFF0F4`} borderRadius='8px' p={2}>
                <Box key={index} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                    <Box>
                        <MySkeleton width={100} />
                        <MySkeleton width={150} />
                    </Box>
                    <MySkeleton variant='rectangular' height={5} width={10} />
                </Box>
            </Box>
        ))}
    </Box>
  );
}

export default QuestionAndAnswersLoader;
