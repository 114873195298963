import { 
    Box,
    Table,
    TableRow,
    TableBody,
    TableCell,
    TableHead,
    Typography,
    TableContainer,
 } from "@mui/material";
import MySkeleton from "../components/Skeleton";
import { adminPanel } from "../utils/muiStyle";

const ProgramUsersLoader = () => {
    const classs = adminPanel();
    
    return (
        <Box className={classs.mainBody}>
            <TableContainer>
                <Table width="100%">
                    <TableHead>
                    <TableRow>
                        <TableCell colSpan={4} width="50%" align="left">
                            <Box display={'flex'} gap={'8px'} alignItems={'center'}>
                                <Typography>
                                    <MySkeleton width={150} />
                                </Typography>
                                {/* <MySkeleton variant='rounded' width={290} height={40} /> */}
                            </Box>
                        </TableCell>
                        <TableCell colSpan={4} width="50%" align="right" className="py-0">
                            <Box className='last-cell-table-Loader'>
                                <MySkeleton variant='circular' height={34} width={34} />
                            </Box>
                        </TableCell>
                    </TableRow>
                        <TableRow>
                            <TableCell>
                                <MySkeleton width={100} />
                            </TableCell>
                            <TableCell>
                                <MySkeleton width={50} />
                            </TableCell>
                            <TableCell>
                                <MySkeleton width={80} />
                            </TableCell>
                            {[...Array(4).keys()].map((_, index) => ( 
                                <TableCell>
                                    <MySkeleton width={120} />
                                </TableCell>
                            ))}
                            <TableCell>
                                <Typography className='last-cell-table-Loader'>
                                    <MySkeleton width={70} />
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {[...Array(10).keys()].map((_, index) => ( 
                            <TableRow key={index}>
                                <TableCell>
                                    <Box display={'flex'} gap={'8px'} alignItems={'center'}>
                                        <MySkeleton variant='rounded' height={34} width={34}/>
                                        <MySkeleton width={150}/>
                                    </Box>    
                                </TableCell>
                                <TableCell>
                                    <Box display={'flex'} gap={'8px'}>
                                        <MySkeleton variant='rounded' height={20} width={60}/>
                                        <MySkeleton variant='rounded' height={20} width={60}/>
                                    </Box>
                                </TableCell>
                                <TableCell>
                                    <MySkeleton width={150} />
                                </TableCell>
                                <TableCell>
                                    <MySkeleton width={100} />
                                </TableCell>
                                <TableCell>
                                    <MySkeleton width={50} />
                                </TableCell>
                                <TableCell>
                                    <Box display={'flex'} gap={.5}>
                                        <MySkeleton width={100} />
                                        <MySkeleton width={10} />
                                    </Box>
                                </TableCell>
                                <TableCell>
                                    <MySkeleton variant='rounded' height={20} width={60}/>
                                </TableCell>
                                <TableCell>
                                    <Typography className='last-cell-table-Loader'>
                                        <MySkeleton variant="rounded" height={15} width={3.5}/>
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}

export default ProgramUsersLoader
