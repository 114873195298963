import React from 'react';
import RoleTypeTag from './RoleTypeTag';
import MySkeleton from '../../../components/Skeleton';
import { Box, Tooltip, Typography } from '@mui/material';
import ChatAvatarComponent from '../tabs/chat/ChatAvatarComponent';

type CardType = {
  headshot?: string;
  displayName?: string;
  UserType?: string;
  bio?: string | JSX.Element; // Allow for both string and JSX
};

type UserinfoCardProps = {
  UserinfoCardDetails: CardType;
  loading?: boolean;
};

const UserinfoCard: React.FC<UserinfoCardProps> = ({ UserinfoCardDetails, loading }) => {
  const { headshot, displayName, UserType, bio } = UserinfoCardDetails;

  return (
    <Box display="flex" gap="8px" alignItems="center" width='100%'>
      <Box>
        <ChatAvatarComponent
          image={headshot}
          type="noStatus"
          firstLetter={displayName ? displayName.charAt(0) : ''}
          width="48px"
          height="48px"
          loading={loading}
        />
      </Box>
      <Box width='calc(100% - 56px)'>
        <Box display="flex" gap="8px" alignItems="center">
          <Typography variant="h5" noWrap>{loading ? (<MySkeleton variant='text' height={16} width={100}/>) : displayName}</Typography>
          {UserType && <RoleTypeTag category={UserType} />}
        </Box>
        <Box>
        <Tooltip title={bio} arrow>
          <Typography noWrap>{loading ? (<MySkeleton variant='text' height={16} width={130}/>) : bio}</Typography>
        </Tooltip>
        </Box>
      </Box>
    </Box>
  );
};

export default UserinfoCard;
