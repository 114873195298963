import './richEditor.scss';

/** with no options */
export const reactDraftWysiwygToolbarOptions = (customUploadCallback: any, embedCallback: any) => ({
  options: []
});

/** For chat page */
export const reactDraftWysiwygToolbarOptionsChat = (customUploadCallback: any, embedCallback: any) => ({
  options: ['emoji', 'inline', 'list'],
  inline: {
    options: ['bold', 'italic', 'strikethrough'],
    bold: { className: 'iconBorder myCustombold' },
    italic: { className: 'iconBorder myCustomItalic' },
    strikethrough: { className: 'iconBorder myCustomStrikethrough' }
  },
  emoji: {
    className: 'iconBorder myCustomEmoji',
    popupClassName: 'emoji_class',
    emojis: [
      '😀',
      '😁',
      '😂',
      '😃',
      '😉',
      '😋',
      '😎',
      '😍',
      '😗',
      '🤗',
      '🤔',
      '😣',
      '😫',
      '😴',
      '😌',
      '🤓',
      '😛',
      '😜',
      '😠',
      '😇',
      '😷',
      '😈',
      '👻',
      '😺',
      '😸',
      '😹',
      '😻',
      '😼',
      '😽',
      '🙀',
      '🙈',
      '🙉',
      '🙊',
      '👼',
      '👮',
      '🕵',
      '💂',
      '👳',
      '🎅',
      '👸',
      '👰',
      '👲',
      '🙍',
      '🙇',
      '🚶',
      '🏃',
      '💃',
      '⛷',
      '🏂',
      '🏌',
      '🏄',
      '🚣',
      '🏊',
      '⛹',
      '🏋',
      '🚴',
      '👫',
      '💪',
      '👈',
      '👉',
      '👉',
      '👆',
      '🖕',
      '👇',
      '🖖',
      '🤘',
      '🖐',
      '👌',
      '👍',
      '👎',
      '✊',
      '👊',
      '👏',
      '🙌',
      '🙏',
      '🐵',
      '🐶',
      '🐇',
      '🐥',
      '🐸',
      '🐌',
      '🐛',
      '🐜',
      '🐝',
      '🍉',
      '🍄',
      '🍔',
      '🍤',
      '🍨',
      '🍪',
      '🎂',
      '🍰',
      '🍾',
      '🍷',
      '🍸',
      '🍺',
      '🌍',
      '🚑',
      '⏰',
      '🌙',
      '🌝',
      '🌞',
      '⭐',
      '🌟',
      '🌠',
      '🌨',
      '🌩',
      '⛄',
      '🔥',
      '🎄',
      '🎈',
      '🎉',
      '🎊',
      '🎁',
      '🎗',
      '🏀',
      '🏈',
      '🎲',
      '🔇',
      '🔈',
      '📣',
      '🔔',
      '🎵',
      '🎷',
      '💰',
      '🖊',
      '📅',
      '✅',
      '❎',
      '💯'
    ]
  },
  list: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ['unordered', 'ordered'],
    unordered: { className: 'iconBorder' },
    ordered: { className: 'iconBorder' }
  }
  // image: {
  //   className: 'iconBorder',
  //   component: undefined,
  //   popupClassName: undefined,
  //   urlEnabled: true,
  //   uploadEnabled: true,
  //   alignmentEnabled: true,
  //   alignOptions: 'LEFT',
  //   uploadCallback: customUploadCallback,
  //   previewImage: true,
  //   inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg/',
  //   // inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg+xml,application/pdf',
  //   alt: { present: true, mandatory: false },
  //   defaultSize: {
  //     height: 'auto',
  //     width: 'auto'
  //   }
  // },
  // embedded: {
  //   // icon: embedded,
  //   className: undefined,
  //   component: undefined,
  //   popupClassName: undefined,
  //   embedCallback: embedCallback,
  //   defaultSize: {
  //     height: 'auto',
  //     width: 'auto'
  //   }
  // }
});

/** For article page */
export const reactDraftWysiwygToolbarOptionsarticle = (imageUpload: any, embedCallback: any) => ({
  options: ['inline', 'list', 'image', 'embedded', 'emoji', 'textAlign', 'colorPicker', 'link'], //'fontSize',
  inline: {
    options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace'],
    bold: { className: 'iconBorder myCustombold' },
    italic: { className: 'iconBorder myCustomItalic' },
    strikethrough: { className: 'iconBorder myCustomStrikethrough' },
    underline: { className: 'iconBorder myCustomunderline' },
    monospace: { className: 'iconBorder myCustommonospace' }
    // fontSize: {
    //   options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36],
    //   className: 'bordered-option-classname',
    // },
  },
  list: {
    options: ['unordered', 'ordered', 'indent', 'outdent'],
    unordered: { className: 'iconBorder' },
    ordered: { className: 'iconBorder' }
  },
  textAlign: {
    options: ['left', 'center', 'right', 'justify'],
    left: { className: 'iconBorder' },
    center: { className: 'iconBorder' },
    right: { className: 'iconBorder' },
    justify: { className: 'iconBorder' }
  },
  colorPicker: {
    popupClassName: 'articletoolbar-modal',
    className: 'iconBorder'
  },
  image: {
    className: 'iconBorder',
    urlEnabled: true,
    uploadEnabled: true,
    alignmentEnabled: true,
    uploadCallback: imageUpload,
    previewImage: true,
    inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
    alt: { present: false, mandatory: false },
    defaultSize: {
      height: 'auto',
      width: 'auto'
    },
    popupClassName: 'articletoolbar-modal'
  },
  embedded: {
    embedCallback: embedCallback,
    popupClassName: 'articletoolbar-modal',
    className: 'iconBorder'
  },
  emoji: {
    popupClassName: 'articletoolbar-modal',
    className: 'iconBorder myCustomEmoji'
  },
  link: {
    options: ['link', 'unlink'],
    defaultTargetOption: '_blank',
    popupClassName: 'articletoolbar-modal height-auto'
  }
});

/** For Task page */
export const reactDraftWysiwygToolbarOptionstask = (imageUpload: any, embedCallback: any) => ({
  options: ['inline', 'list', 'image', 'embedded', 'emoji', 'textAlign', 'colorPicker', 'link'], //'fontSize',
  inline: {
    options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace'],
    bold: { className: 'iconBorder myCustombold' },
    italic: { className: 'iconBorder myCustomItalic' },
    strikethrough: { className: 'iconBorder myCustomStrikethrough' },
    underline: { className: 'iconBorder myCustomunderline' },
    monospace: { className: 'iconBorder myCustommonospace' }
    // fontSize: {
    //   options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36],
    //   className: 'bordered-option-classname',
    // },
  },
  list: {
    options: ['unordered', 'ordered', 'indent', 'outdent'],
    unordered: { className: 'iconBorder' },
    ordered: { className: 'iconBorder' }
  },
  textAlign: {
    options: ['left', 'center', 'right', 'justify'],
    left: { className: 'iconBorder' },
    center: { className: 'iconBorder' },
    right: { className: 'iconBorder' },
    justify: { className: 'iconBorder' }
  },
  colorPicker: {
    popupClassName: 'tasktoolbar-modal',
    className: 'iconBorder'
  },

  /**for img upload */
  image: {
    inputAccept: 'image/jpeg,image/jpg,image/png',
    uploadCallback: imageUpload,
    previewImage: true,
    popupClassName: 'tasktoolbar-modal',
    className: 'iconBorder'
  },
  embedded: {
    popupClassName: 'taskEmbedded-modal',
    embedCallback: embedCallback,
    className: 'iconBorder'
  },
  emoji: {
    className: 'iconBorder myCustomEmoji',
    popupClassName: 'tasktoolbar-modal'
  },
  link: {
    options: ['link', 'unlink'],
    defaultTargetOption: '_blank',
    popupClassName: 'tasktoolbar-modal height-auto'
  }
});
