import React from 'react';
import { Box, Divider, Typography } from '@mui/material';
import { Chat1Icon, ExploreIcon, CommunityIcon, TasksListIcon } from '../../../../utils/Icons';
import { appColors } from '../../../../utils/theme';
import MySkeleton from '../../../../components/Skeleton';
import { CardStyle } from '../../style-components/CardStyling';
import useResponsiveScreenSwitch from '../../../../utils/useResponsiveScreenSwitch';

interface gettingStartedProps {
  loading: boolean;
  handleClickGettingStartedModal: any;
  setSelectedStep: any;
}

const cardContent = [
  {
    icon: <ExploreIcon />,
    text: 'Familiarize yourself with the Dosen app'
  },
  {
    icon: <Chat1Icon />,
    text: 'Introduce yourself to your mentor'
  },
  {
    icon: <CommunityIcon />,
    text: 'Get to know your peers'
  },
  {
    icon: <TasksListIcon />,
    text: 'Start your Level 1 tasks'
  }
];

const GettingStartedCard: React.FC<gettingStartedProps> = ({
  loading,
  handleClickGettingStartedModal,
  setSelectedStep
}) => {
  const classes = CardStyle();
  const { forMobile } = useResponsiveScreenSwitch();

  return (
    <Box className={classes.cardBox} minHeight={forMobile ? 'auto' : '240px'}>
      <Box p={2}>
        <Typography variant="h5">{loading ? <MySkeleton width={150} /> : `Getting Started`}</Typography>
      </Box>
      <Divider />
      <Box p={2} display="flex" flexDirection="column" gap={2}>
        {cardContent.map((item, index) => (
          <Box
            gap={1}
            key={index}
            display="flex"
            alignItems="center"
            onClick={() => {
              setSelectedStep(index);
              handleClickGettingStartedModal(true);
            }}
            className="cursor-pointer"
          >
            {loading ? <MySkeleton variant="rectangular" height={20} width={20} /> : item.icon}
            <Typography noWrap variant="subtitle1" color={appColors.blue1}>
              {loading ? <MySkeleton width={250} /> : item.text}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default GettingStartedCard;
