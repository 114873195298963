import react from "react";
import {
    Box,
    Radio,
    Tooltip,
    Typography,
    FormControlLabel
} from "@mui/material";
import { Controller } from 'react-hook-form';
import { lockIcon, currencyArray } from "../../../../../components/common";

const CurrencySelect = ({ control }: any) => {
    const disabled = false;
    const currencies = currencyArray();
    return (
        <Box className={disabled ? 'opacity-25' : ''}>
            <Box mb={1.5} gap="8px" display="flex" alignItems="center">
                <Typography variant="h5">
                    Currency
                </Typography>
                {disabled && lockIcon({})}
            </Box>

            <Controller
                name="currency"
                control={control}
                render={({ field: { onChange, value } }) => (
                    <Box gap={2} display={'flex'} alignItems={'center'}>
                        {currencies.map((currency) => (
                            <FormControlLabel
                                disabled={disabled}
                                key={currency.value}
                                value={currency.value}
                                control={
                                    <Radio
                                        checked={value === currency.value}
                                        onChange={() => onChange(currency.value)}
                                        name="currency"
                                    />
                                }
                                label={
                                    <Tooltip title={currency.value} arrow>
                                        <Typography color="primary">{currency.symbol}</Typography>
                                    </Tooltip>
                                }
                            />
                        ))}
                    </Box>
                )}
            />
        </Box>
    );
};

export default CurrencySelect;
