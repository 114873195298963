import { useQuery } from '@tanstack/react-query';
import { queryKeys } from './queryKeys';
import { API } from '../api';

const useMyMentors = ({ grpId, orgId }: { grpId: string; location: string; orgId: string }) => {
  return useQuery({
    queryKey: [queryKeys.GET_MY_MENTORS, grpId],
    queryFn: () => {
      return API.getMyMentors({ orgId, groupId: grpId });
    },
    staleTime: 10 * 10 * 1000
  });
};

export default useMyMentors;
