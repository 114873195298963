/* eslint-disable prettier/prettier */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from '../../../api';

export interface ICommunityMember {
  bio: Record<string, any>; // Placeholder for bio properties, can be more specific
  category: string;
  communicationId: string;
  displayName: string;
  groups: string[];
  headshot: string;
  id: string;
  mid: string;
  roleType: string;
  hobbies: string[];
  industries: string[];
  userType: string[];
  company: {
    name: string;
    location: string;
  };
}

interface IInitialState {
  data: ICommunityMember[] | undefined; // An array of CommunityMember objects
  error: boolean;
  errorText: string;
  loading: boolean;
}

// Example usage
// const exampleData: CommunityData = {
//   data: [
//     { id: 1, name: "John Doe" },
//     { id: 2, name: "Jane Smith" },
//     // ... other community member objects
//   ],
//   error: false,
//   errorText: "",
//   loading: false,
// };

// interface IInitialState {
//   [x: string]: any;
//   loading: boolean;
//   data: any;
//   error: boolean;
//   errorText: string;
// }

const initialState: IInitialState = {
  loading: false,
  data: undefined,
  error: false,
  errorText: ''
};

export const fetchCommunityMember = createAsyncThunk(
  'getCommunityMembers',
  ({
    orgId,
    location,
    role,
    category,
    industry,
    hobbies,
    roleType
  }: {
    orgId: string;
    location: string;
    role?: string[];
    category?: string[];
    industry?: string[];
    hobbies?: string[];
    roleType?: string[];
  }) => {
    return API.getCommunityGroupsUsersListNew(orgId, location, role, category, industry, hobbies, roleType).then(
      (response) => response.data.memberResponse
    );
  }
);

const communityMembersSlice = createSlice({
  name: 'community member',
  initialState,
  reducers: {
    update: (state, action) => {
      state.data = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCommunityMember.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.errorText = '';
        state.data = undefined;
      })
      .addCase(fetchCommunityMember.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        state.errorText = '';
        state.data = action.payload;
      })
      .addCase(fetchCommunityMember.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
        state.errorText = action.error.message || '';
        state.data = undefined;
      });
  }
});

export default communityMembersSlice.reducer;

// export const userInfoUpdate = communityMembersSlice.actions.update;
export const communityMembersUpdate = communityMembersSlice.actions.update;
