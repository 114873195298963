import React from 'react';
import { Box, Divider, Typography } from '@mui/material';
import ValuationProgress from './ValuationProgress';

interface ValuationProps {
  heading: string;
  progressValue: number;
}

const Valuation: React.FC<ValuationProps> = ({ heading, progressValue }) => {
  return (
    <Box className="CardBox">
      <Box p={2}>
        <Typography variant='h6'>{heading}</Typography>
      </Box>
      <Divider />
      <Box p={2} minHeight={'211px'} sx={{ textAlign: '-webkit-center' }} >
        <ValuationProgress score={86} height='170px' />
      </Box>
    </Box>
  );
}

export default Valuation;
