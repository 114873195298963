import { Box, Typography, Button } from '@mui/material';
import favicon from '../assets/images/favicon.svg';

const PlannedMaintenance: React.FC = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems={{ xs: 'top', sm: 'center' }}
      height="100vh"
    >
      <Box
        width="480px"
        border={{ xs: 'none', sm: '1px solid #e7e3e3' }}
        borderRadius="8px"
        bgcolor={'#FFFFFF'}
      >
        <Box
          mx={{ xs: 3, sm: 10 }}
          my={6}
          display="flex"
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
        >
          <Box p={2} borderRadius={'50%'} bgcolor={'#EFF0F4'}>
            <img src={favicon} alt="error" height="40" width="40" />
          </Box>
          <Typography textAlign="center" variant="h3" pt={3}>
            Planned maintenance
          </Typography>
          <Typography textAlign="center" fontSize="16px" pt={2}>
            We’re offline for planned maintenance, we’ll be right back
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default PlannedMaintenance;
