/* eslint-disable no-empty */
/* eslint-disable prettier/prettier */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import { Box, Grid } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import LibraryDetails from './../Library/components/LibraryDetails';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
// import RecommendedArticle from '../Library/components/RecommendedArticle';
import { fetchAllArticlesUser } from '../../../../redux/slices/article/getAllArticleUserSlice';
import { API } from '../../../../api';
import { useParams, useLocation } from 'react-router-dom';
import { getUserDetails } from '../../../../utils/orgName';
import LibraryFilter from './../Library/components/LibraryFilter';
import HelpSidebar from './components/HelpSidebar';
import useResponsiveScreenSwitch from '../../../../utils/useResponsiveScreenSwitch';
import { updateInsightDataByArticleId } from '../../components/common';
import { LibraryRightBarLoader } from '../Library/components/LibraryLoader';

export interface IArticleData {
  [key: string]: any;
  articleId: string;
  location: string;
  orgId: string;
  groups: string[];
  articleName: string;
  type: string;
  coverImageUrl: string;
  fileUrl: string;
  authorName: string;
  authorId: string;
  createdBy?: string;
  readTime: string;
  accessFor: string;
  topics: string;
  tags: string[];
  industries: string[];
  articleDescription: string;
  embeddedLinks: string;
  approved: boolean;
  upVotes: number;
  downVotes: number;
  views: number;
  isPublished: boolean;
  createdAt: number;
  __v: number;
  isVoted: boolean;
  isViewed: boolean;
}

const HelpTab = () => {
  // const activeItemRef = useRef<any>(null);
  // const location = useLocation();
  // const navigatedArticleId = location.state?.navigatedArticleId;
  const dispatch = useAppDispatch();
  const [articleDetails, setArticleDetails] = useState<IArticleData | undefined>();
  const [articleDetailsLoading, setArticleDetailsLoading] = useState(false);
  const allArticleData = useAppSelector((state) => state.allArticleUser?.data?.ArticleList) ?? [];
  // const firstArticleLocation = useAppSelector((state) => state.allArticleUser?.data?.ArticleList[0][""][0]?.location);
  // const firstArticleLocation = useAppSelector((state) => {
  //   const firstArticleList = state.allArticleUser?.data?.ArticleList[0];
  //   if (firstArticleList?.[""]?.[0]) {
  //     return firstArticleList[""][0]?.location;
  //   }
  //   return undefined; // or handle the case when data is not available
  // });

  const loading11 = useAppSelector((state) => state?.allArticleUser?.loading);
  const error = useAppSelector((state) => state?.allArticleUser?.error);
  const locationOfArticle = useLocation();
  const navigatedArticleId = locationOfArticle.state?.navigatedArticleId;
  const navigatedArticleLocation = locationOfArticle.state?.navigatedArticleLocation;

  const [selectedArticleId, setSelectedArticleId] = useState(navigatedArticleId || '');
  const [likeStatus, setLikeStatus] = useState('');
  let [isvote, setIsvote] = useState(null);
  let [isconcernData, setConcernData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingDown, setLoadingDown] = useState(false);
  const params = useParams();
  const grpId = params.id || '';
  const { orgId } = getUserDetails();
  let tags: string[] = [];
  let types: string[] = [];
  let industries: string[] = [];

  const { user } = useAuth0();
  const { location1, orgId: userOrgId } = getUserDetails();

  const [tagList, setTagList] = useState<string[]>([]);
  const [industryTags, setIndustryTags] = useState<string[]>([]);
  const [selectedIndustryTags, setSelectedIndustryTags] = useState<string[]>([]);

  /** For responsive */
  const { mobileBackBtn, mobileLeftOff, showContainerBoxRight, showContainerBoxLeft } = useResponsiveScreenSwitch(); // Use the hook

  useEffect(() => {
    Promise.all([
      fetchTagList('FYE'),
      fetchTagList('Careers'),
      fetchTagList('Consulting'),
      fetchTagList('industry')
    ]).then(([tagList1, tagList2, tagList3, tagList4]) => {
      setTagList(tagList1.concat(tagList2).concat(tagList3));
      setIndustryTags(tagList4);
    });
  }, []);

  const fetchTagList = async (type: string) => {
    try {
      const responseFYE = await API.getTagsListnew(userOrgId, location1, type, '', true);
      if (responseFYE.status === 200 && responseFYE.statusText === 'OK') {
        return responseFYE?.data?.tagListResponse.map((each: any) => each.value) || [];
      } else {
        return [];
      }
    } catch {
      return [];
    }
  };

  const handleIndustryChange = (industry: string) => {
    setSelectedIndustryTags((prevIndustryTags) =>
      prevIndustryTags.includes(industry)
        ? selectedIndustryTags.filter((ind) => ind !== industry)
        : [...prevIndustryTags, industry]
    );
  };

  const getArticleList = async () => {
    try {
      const response = await API.getAllArticleUser({
        orgId,
        tags,
        type: types,
        industries: industries,
        helpSettings: true
      });
      if (response.status === 200) {
        for (const data of response.data.ArticleList) {
          const key = Object.keys(data)[0];
          const articleArray = data[key];
          setSelectedArticleId(navigatedArticleId ? navigatedArticleId : response.data.ArticleList[0]?.articleId);
          setUserLocation(navigatedArticleLocation ? navigatedArticleLocation : response.data.ArticleList[0]?.location);
          // if (articleArray.length > 0 && articleArray[0].hasOwnProperty('articleId')) {
          //   setSelectedArticleId(articleArray[0].articleId);
          //   setUserLocation(response.data.ArticleList[0][""][0]?.location);
          //   return;
          // }
        }
      }
    } catch (error) {}
  };

  const [selectedTypes, setSelectedTypes] = useState<string[]>([]);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);

  const handleTypeChange = (type: string) => {
    setSelectedTypes((prevTypes) =>
      prevTypes.includes(type) ? prevTypes.filter((t) => t !== type) : [...prevTypes, type]
    );
  };

  const handleTagChange = (type: string) => {
    setSelectedTags((prevTypes) =>
      prevTypes.includes(type) ? prevTypes.filter((t) => t !== type) : [...prevTypes, type]
    );
  };

  const [openFilter, setOpenFilter] = useState(false);
  const handleClickOpenFilter = () => {
    // setOpenFilter(true);
  };

  const handleClickCloseIcon = () => {
    setOpenFilter(false);
    setUserLocation('');
  };

  const handleClickApply = () => {
    setArticleDetails(undefined);
    dispatch(
      fetchAllArticlesUser({
        orgId: orgId,
        type: selectedTypes,
        tags: selectedTags,
        industries: selectedIndustryTags,
        helpSettings: true,
        useCache: true
      })
    );
    setOpenFilter(false);
  };

  const handleresetFilter = () => {
    setSelectedTypes([]);
    setSelectedTags([]);
    setSelectedIndustryTags([]);
    setOpenFilter(false);
    dispatch(
      fetchAllArticlesUser({
        orgId: orgId,
        type: types,
        tags: tags,
        industries: industries,
        helpSettings: true,
        useCache: true
      })
    );
  };

  useEffect(() => {
    dispatch(
      fetchAllArticlesUser({
        orgId: orgId,
        type: types,
        tags: tags,
        industries: industries,
        helpSettings: true,
        useCache: true
      })
    );
  }, []);

  const [userLocation, setUserLocation] = useState<any>(
    navigatedArticleLocation ? navigatedArticleLocation : allArticleData[0]?.location
  );

  const handleArticle = async (ele: any, concernData: any) => {
    if (ele && ele.articleId !== selectedArticleId) {
      setSelectedArticleId(ele.articleId);
      setLikeStatus('');
      mobileLeftOff(); // Call the mobile Left Off function
      setUserLocation(ele?.location);
    }
    setConcernData(concernData);
    mobileLeftOff();
  };

  useEffect(() => {
    getArticleList();
  }, []);

  useEffect(() => {
    setUserLocation(allArticleData[0]?.location);
  }, [allArticleData]);

  useEffect(() => {
    async function fetchArticle() {
      try {
        setArticleDetailsLoading(true);
        const response = await API.getAllArticleById({
          orgId: orgId,
          articleId: selectedArticleId,
          groupId: grpId,
          isContentSettings: false,
          articleLocation: userLocation ? userLocation : '',
          useCache: true
        });

        if (response && response?.status === 200) {
          setArticleDetails(response.data.article);
          setIsvote(response.data.isVoted);
          setLikeStatus(response.data.isVoted ? 'upVote' : response.data.isVoted === false ? 'downVote' : '');
          setArticleDetailsLoading(false);
        } else {
        }
      } catch (error) {
        // console.error(error);
      }
      updateInsightDataByArticleId(orgId, selectedArticleId, userLocation);
    }

    if (selectedArticleId) {
      fetchArticle();
    }
  }, [selectedArticleId, orgId]);

  const handleLikeUp = async () => {
    setLoading(true);
    if (likeStatus === 'upVote') {
      const response = await API.articleAction({
        orgId: orgId,
        articleId: selectedArticleId,
        data: { action: 'upVote' }
      });
      if (response?.status === 200) {
        setLikeStatus('');
        setArticleDetails(response.data.getArticle);
        setLoading(false);
      }
    } else {
      const response = await API.articleAction({
        orgId: orgId,
        articleId: selectedArticleId,
        data: { action: 'upVote' }
      });

      if (response?.status === 200) {
        setArticleDetails(response.data.getArticle);
        setLikeStatus('upVote');
        setLoading(false);
      }
    }
  };

  const handleLikeDown = async () => {
    setLoadingDown(true);
    if (likeStatus === 'downVote') {
      const response = await API.articleAction({
        orgId: orgId,
        articleId: selectedArticleId,
        data: { action: 'downVote' }
      });

      if (response?.status === 200) {
        setArticleDetails(response.data.getArticle);

        setLikeStatus('');
        setLoadingDown(false);
      }
    } else {
      const response = await API.articleAction({
        orgId: orgId,
        articleId: selectedArticleId,
        data: { action: 'downVote' }
      });

      if (response?.status === 200) {
        setLikeStatus('downVote');
        setArticleDetails(response.data.getArticle);
        setLoadingDown(false);
      }
    }
  };

  if (error) {
    return <Box>Error occurred while fetching data.</Box>;
  }

  return (
    <>
      <Box className="containerBox">
        {showContainerBoxLeft && (
          <Box className="containerBoxLeft">
            <HelpSidebar
              allArticleData={allArticleData ?? []}
              onClick={handleArticle}
              selectedArticleId={selectedArticleId}
              handleClickOpenFilter={handleClickOpenFilter}
              allArticleLoading={loading11}
            />
          </Box>
        )}

        {showContainerBoxRight && (
          <Box className="containerBoxRight">
            {/* For Mobile -> details to back button */}
            {mobileBackBtn()}

            <Grid container width={'100%'}>
              <Grid item xs={12} md={12}>
                <Box className="CardBox">
                  {loading11 ? (
                    <LibraryRightBarLoader />
                  ) : (
                    <LibraryDetails
                      articleDetails={articleDetails}
                      selectedArticleId={selectedArticleId}
                      handleLikeUp={handleLikeUp}
                      handleLikeDown={handleLikeDown}
                      likeStatus={likeStatus}
                      isVoted={isvote}
                      loading={loading && loading11}
                      loadingDown={loadingDown}
                      isconcernData={isconcernData}
                      articleDetailsLoading={articleDetailsLoading}
                    />
                  )}
                </Box>
              </Grid>
              {/* <Grid item xs={12} md={4}>
                <RecommendedArticle
                  direction="column"
                  place="help"
                  data={articleData}
                  handleArticleClick={() => console.log('Clicked on article with ID:')}
                />
              </Grid> */}
            </Grid>
          </Box>
        )}
      </Box>

      {/* Filter */}
      <LibraryFilter
        loading={loading11}
        openFilter={openFilter}
        handleClickApply={handleClickApply}
        handleTypeChange={handleTypeChange}
        selectedTypes={selectedTypes}
        selectedTags={selectedTags}
        handleTagChange={handleTagChange}
        handleresetFilter={handleresetFilter}
        handleClickCloseIcon={handleClickCloseIcon}
        tagList={tagList}
        industryTags={industryTags}
        selectedIndustryTags={selectedIndustryTags}
        handleIndustryChange={handleIndustryChange}
      />
    </>
  );
};

export default HelpTab;
