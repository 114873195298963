import { Box, Typography, Button } from '@mui/material';
import connection from '../assets/images/connection-problem.svg';
import { useLogout } from '../hooks';

const ConnectionProblem: React.FC = () => {
  const { doLogout } = useLogout();
  function handleRedirect() {
    doLogout();
    // window.location.href = '/';
  }
  return (
    <Box display="flex" justifyContent="center" alignItems={{ xs: 'top', sm: 'center' }} height="100vh">
      <Box width="480px" border={{ xs: 'none', sm: '1px solid #e7e3e3' }} borderRadius="8px" bgcolor={'#FFFFFF'}>
        <Box
          mx={{ xs: 3, sm: 10 }}
          my={6}
          display="flex"
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
        >
          <img src={connection} alt="error" height="101px" width="86px" />
          <Typography variant="h3" pt={3}>
            Somethings gone wrong
          </Typography>
          <Typography fontSize="16px" pt={1} pb={6}>
            [INSERT COPY FROM AUTH0]
          </Typography>
          <Button color="primary" variant="contained" fullWidth sx={{ height: '50px' }} onClick={handleRedirect}>
            Back to Dosen
          </Button>
          <Typography fontSize="16px" my={1}>
            or
          </Typography>
          <Typography fontSize="16px">Contact us at support@dosen.io</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ConnectionProblem;
