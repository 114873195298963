/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable react/jsx-key */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Stack,
  Radio,
  Dialog,
  TextField,
  Typography,
  DialogActions,
  DialogContent,
} from '@mui/material';
import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { LoadingButton } from '@mui/lab';
import MyChip from '../../../components/MyChip';
import SearchIcon from '@mui/icons-material/Search';
import { appColors } from '../../../../../utils/theme';
import RoleTypeTag from '../../../components/RoleTypeTag';
import ChatAvatarComponent from '../../chat/ChatAvatarComponent';
import { camelCaseToCapital } from '../../../../../utils/topicsMapping';
import CommonModalHeader from '../../../../../components/DialogUi/CommonModalHeader';
import { camelCaseToCapitalIndustries } from '../../../../../utils/industriesMapping';
import { GroupBasedRoleFormatter, roleNameFormatter } from '../../../components/common';
// import { featchMatches } from "../../../../redux/slices/getAllMatches/getAllMatchesSlice";
// import AppLoader from "../../../../components/AppLoader";
// import { useSelector } from "react-redux";

interface AssignMatchPopup2Props {
  openAssignMentorSecond: any;
  handelCloseAssineMatch: any;
  action: any;
  recommendations: any;
  setSelectedRecommendation: (rec: any) => void;
  suggestions: any;
  handleSubmitAssignMatch: any;
  loaderFirstPopup: any;
}

function AssignMatchPopup2({
  openAssignMentorSecond,
  handelCloseAssineMatch,
  action,
  recommendations,
  suggestions,
  setSelectedRecommendation,
  handleSubmitAssignMatch,
  loaderFirstPopup
}: AssignMatchPopup2Props) {
  const [selectedRadioValue, setSelectedRadioValue] = useState<string | null>(null);

  const useStyles = makeStyles({
    memberCircleImage: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '50px',
      height: '50px',
      position: 'relative'
    },
    memberCircleInner: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '50px',
      height: '50px',
      background: '#fff',
      borderRadius: '50%'
    }
  });
  
  const mentorRoleText = GroupBasedRoleFormatter('mentor', 'Mentor'); // Mentor
  const menteeRoleText = GroupBasedRoleFormatter('mentee', 'Mentee'); // Mentee
  const mentorRoleTextLowerCase = mentorRoleText.toLowerCase(); // mentor
  const menteeRoleTextLowerCase = menteeRoleText.toLowerCase(); // mentee
  const classes = useStyles();
  // if (!suggestions) {
  //   return <div> No Matching Found</div>;
  // }
  // if (!recommendations) {
  //   return <div> No Matching Found</div>;
  // }
  // console.log(recommendations, "recommendations");

  const formatBio = (name1: string, name2: string) => {
    if (!name1 || !name2) {
      return ''; // Return an empty string if major is undefined or null
    }
    return (
      <>
        {name1
          ?.split(' ')
          ?.map((each: any) => each.charAt(0).toUpperCase().concat(each?.slice(1)))
          .join(' ')
          .concat(', ')}
        {name2
          ?.split(' ')
          ?.map((each: any) => each.charAt(0).toUpperCase().concat(each?.slice(1)))
          ?.join(' ')}
      </>
    );
  };

  const getBio = (mem: any) => {
    const category: any = mem?.id?.category;
    const major: string = mem?.bio?.education?.major;
    const university: string = mem?.bio?.education?.university;
    const role: any = mem?.bio?.workHistory?.role;
    const company: any = mem?.bio?.workHistory?.companyName;

    return formatBio(major, university) || formatBio(role, company);
  };

  const renderTopics = (topics: string[]) => (
    <Stack direction="row" useFlexGap flexWrap="wrap" spacing={.5} alignItems="center">
      {topics.map((ele, index) => (
        <React.Fragment key={index}>
          <MyChip label={ele} backgroundColor="#FFFFFF" color="#68717A" border="1px Solid #CED4DA" />
        </React.Fragment>
      ))}
    </Stack>
  );

  return (
    <Dialog open={openAssignMentorSecond} onClose={handelCloseAssineMatch} fullWidth>
      <CommonModalHeader
        title= {action === 'Assign Mentor' ? 'Assign a ' + mentorRoleTextLowerCase : 'Assign a ' + menteeRoleTextLowerCase}
        handleCloseModal={handelCloseAssineMatch}
      />
      <DialogContent>
        <Box mb={2}>
          <Box
            border={`1px solid ${appColors.gray1}`}
            borderRadius={2}
            display="flex"
            alignItems="center"
            width="100%"
            height="40px"
            padding={1.2}
          >
            <SearchIcon
              sx={{
                color: appColors.gray4,
                marginRight: '7px',
                width: '16.6px',
                height: '16.6px'
              }}
            />
            <TextField
              variant="standard"
              placeholder={`Search ${mentorRoleTextLowerCase}`}
              style={{
                borderColor: 'transparent',
                padding: '0px !important',
                margin: 0,
                paddingBottom: 0,
                color: '#68717A'
              }}
              size="small"
              type="text"
              InputProps={{
                disableUnderline: true
              }}
            />
          </Box>
        </Box>

        <Typography variant="h5" mb={1}>
          Recommended
        </Typography>

        {recommendations &&
          recommendations?.map((ele: any, index: any) => {
            const allTopics = [
              ...camelCaseToCapital(ele.topics || []),
              ...camelCaseToCapitalIndustries(ele.industries || []),
            ];

            return (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                gap="8px"
                py={2}
                borderBottom="1px solid #EFF0F4"
              >
                <Box>
                  <Box display="flex" gap="8px" alignItems="center">
                    <ChatAvatarComponent
                      height="48px"
                      width="48px"
                      image={ele?.headshot}
                      firstLetter={ele?.displayName.slice(0, 1)}
                    />
                    <Box>
                      <Box display="flex" alignItems="center" gap="8px">
                        <Typography variant="h5">{ele?.displayName}</Typography>
                        <RoleTypeTag category={roleNameFormatter(ele?.role, mentorRoleText, menteeRoleText)} />
                      </Box>
                      <Box>
                        <Typography>{getBio(ele)}</Typography>
                      </Box>
                    </Box>
                  </Box>
                  {allTopics.length > 0 &&
                    <Box mt={1} ml={5.6}>
                      {renderTopics(allTopics)}
                    </Box>
                  }
                </Box>
                {/* Radio */}
                <Box>
                  {/* <input
                    type="radio"
                    id={`radio-${index}`}
                    name="selectedRecommendation"
                    value={ele.mentorId}
                    onChange={() => setSelectedRecommendation(ele)}
                  /> */}
                  <Radio
                    id={`radio-${index}`}
                    name="selectedRecommendation"
                    value={ele.mentorId}
                    checked={selectedRadioValue === ele.mentorId}
                    onChange={() => {
                      setSelectedRadioValue(ele.mentorId);
                      setSelectedRecommendation(ele);
                    }}
                  />
                </Box>
              </Box>
            );
          })
        }

        {!recommendations ? <Typography>No Matching Found</Typography> : ''}

        {/* <Divider sx={{my:2}} /> */}

        {/* {suggestions && (
          <Typography variant='h5'>
            All {recommendations[0]?.role === 'Mentor' ? 'Mentor' : 'Mentee'}
          </Typography>
        )} */}

        {suggestions &&
          suggestions?.map((ele: any, index: any) => {
            const allTopics = [
              ...camelCaseToCapital(ele.topics || []),
              ...camelCaseToCapitalIndustries(ele.industries || []),
            ];

            return (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                gap="8px"
                py={2}
                borderBottom={index < suggestions.length - 1 ? "1px solid #EFF0F4" : "none"}
              >
                <Box>
                  <Box display="flex" gap="8px" alignItems="center">
                    <ChatAvatarComponent
                      height="48px"
                      width="48px"
                      image={ ele?.headshot }
                      firstLetter={ ele?.displayName.slice(0, 1) }
                    />
                    <Box>
                      <Box display="flex" alignItems="center" gap="8px">
                        <Typography variant="h5">{ele?.displayName}</Typography>
                        <RoleTypeTag category={roleNameFormatter(ele?.role, mentorRoleText, menteeRoleText)} />
                      </Box>
                      <Box>
                        <Typography>{getBio(ele)}</Typography>
                      </Box>
                    </Box>
                  </Box>
                  {suggestions.length > 0 &&
                    <Box mt={1} ml={5.6}>
                      {renderTopics(allTopics)}
                    </Box>
                  }
                </Box>
                {/* Radio */}
                <Box>
                  {/* <input
                    type="radio"
                    id={`radio-${index}`}
                    name="selectedRecommendation"
                    value={ele.mentorId}
                    onChange={() => setSelectedRecommendation(ele)}
                  /> */}
                  <Radio
                    id={`radio-${index}`}
                    name="selectedRecommendation"
                    value={ele.mentorId}
                    checked={selectedRadioValue === ele.mentorId}
                    onChange={() => {
                      setSelectedRadioValue(ele.mentorId);
                      setSelectedRecommendation(ele);
                    }}
                  />
                </Box>
              </Box>
            );
          })
        }
      </DialogContent>

      <DialogActions>
        <LoadingButton
          fullWidth
          color="primary"
          variant="contained"
          loading={loaderFirstPopup}
          onClick={handleSubmitAssignMatch}
        >
          Select
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

export default AssignMatchPopup2;
