import React, { useEffect } from 'react';
import { Box, Stack, Popover, IconButton, Typography } from '@mui/material';
import ChatAvatarComponent from '../ChatAvatarComponent';
// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { fetchAcsToken } from '../../../../../redux/slices/acs-token/acsTokenSlice';
import { getUserDetails } from '../../../../../utils/orgName';
import { useQueryClient } from '@tanstack/react-query';
import { acsChannelActions } from '../../../../../redux/slices/acs-channels/acsChannels';
import { useNavigate } from 'react-router-dom';
import { selectedChatUserActions } from '../../../../../redux/slices/selectedChatUserSlice';
import { fetchChatUsers } from '../../../../../redux/slices/getChatUsersSlice';
import { Smallchat, ChatEditIcon, ProfileIcon } from '../../../../../utils/Icons';

interface IChatAvatarIcon {
  loginUserFName: string;
  loginUserProfileImage: string;
  activeChat: string;
  handleCreateGroup: () => void;
  handleCreateNewChat: () => void;
  chatPopupClick: (event: any) => void;
  chatPopupClose: () => void;
  anchorEl: any;
  chatPopup: any;
}

const ChatAvatarIcon: React.FC<IChatAvatarIcon> = ({
  loginUserFName,
  loginUserProfileImage,
  activeChat,
  handleCreateGroup,
  handleCreateNewChat,
  chatPopupClick,
  chatPopupClose,
  anchorEl,
  chatPopup
}) => {
  const selectedChatUser = useAppSelector((state) => state.selectedChatUser.data);

  const chatPopupOpen = Boolean(anchorEl);

  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const acsTokenLoading = useAppSelector((state) => state.acsToken.loading);

  const exitChat = () => {
    localStorage.removeItem('acsToken');
    localStorage.setItem('chatAccessed', JSON.stringify(true));
    dispatch(
      fetchAcsToken({
        communicationId: getUserDetails().communicationUserId,
        tag: '#admin'
      })
    ).then(() => {
      dispatch(
        acsChannelActions.atnSaveChannels2({
          groupThreads: [],
          personalThreadsArr: []
        })
      );
      queryClient.fetchQuery({ queryKey: ['getAcsChatThreads'] });
    });
    dispatch(
      selectedChatUserActions.update({
        communicationId: '',
        userId: '',
        name: ''
      })
    );
    let searchTerm = '';
    dispatch(fetchChatUsers(searchTerm));
    navigate('/app/chat', { replace: true });
  };

  return (
    <Box display='flex' alignItems='center' justifyContent='space-between'>
      <Stack direction="row" alignItems="center" spacing={1}>
        <ChatAvatarComponent
          image={loginUserProfileImage}
          firstLetter={loginUserFName.slice(0, 1)}
          width='50px'
          height='50px'
          type="noStatus"
        />
        <Box>
          <Typography variant="body2" color="primary" lineHeight={'1'}>
            Status :
          </Typography>
          <Typography variant="h5" color="primary" lineHeight={'normal'}>
            Active
            {/* <KeyboardArrowDownIcon className='secondary-light' /> */}
          </Typography>
        </Box>
      </Stack>

      {!selectedChatUser?.communicationId && (
        <Box>
          <IconButton className='p-0' id='StartChatIcon' aria-describedby={chatPopup}>
            <ChatEditIcon size={44} onClick={chatPopupClick} />
          </IconButton>
          <Popover
            id={chatPopup}
            open={chatPopupOpen}
            anchorEl={anchorEl}
            onClose={chatPopupClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
          >
            <Box className="PopoverListStyle" onClick={handleCreateGroup}>
              <Smallchat />
              <Typography color="primary">Start Group chat</Typography>
            </Box>
            <Box className="PopoverListStyle" onClick={handleCreateNewChat}>
              <ProfileIcon />
              <Typography color="primary">Start Individual chat</Typography>
            </Box>
          </Popover>
        </Box>
      )}
    </Box>
  );
};

export default ChatAvatarIcon;
