const ROLES = {
  member: 'Member',
  orgAdmin: 'OrgAdmin',
  platAdmin: 'Platform Admin',
  grpAdmin: 'groupAdmin'
};

export const rolesArray = Object.values(ROLES);

export default ROLES;
