import React, { useState, useCallback } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { InputNumber, Radio, RadioChangeEvent } from 'antd';
import { SubsessionAllocationPattern } from '@zoom/videosdk';
import './subsession-create.scss';

interface SubsessionCreateProps {
  onCreateSubsession: (subsessionNumber: number, pattern: SubsessionAllocationPattern) => void;
  totalParticipantsSize: number;
}
const SubsessionCreate = (props: SubsessionCreateProps) => {
  const { onCreateSubsession, totalParticipantsSize } = props;
  const [subsessionNumber, setSubsessionNumber] = useState<number>(1);
  const [pattern, setPattern] = useState<SubsessionAllocationPattern>(SubsessionAllocationPattern.Automatically);
  let subsessionSize = ``;
  if (pattern === SubsessionAllocationPattern.Automatically) {
    const quotient = (totalParticipantsSize - 1) / subsessionNumber;
    if (Math.floor(quotient) === Math.ceil(quotient)) {
      subsessionSize = `${quotient}`;
    } else {
      subsessionSize = `${Math.floor(quotient)}-${Math.ceil(quotient)}`;
    }
  }
  const onRadioChange = useCallback((event: RadioChangeEvent) => {
    setPattern(event.target.value);
  }, []);

  const onInputChange = useCallback((value: number | null) => {
    // Use a default value (1) if value is null
    setSubsessionNumber(value ?? 1);
  }, []);
  const onCreateClick = useCallback(() => {
    onCreateSubsession(subsessionNumber, pattern);
  }, [subsessionNumber, pattern, onCreateSubsession]);
  return (
    <Box>
      <Box mb={2}>
        <Typography variant='h5'>
          Create <InputNumber min={1} value={subsessionNumber} onChange={onInputChange} /> subsessions
        </Typography>
      </Box>
      <Radio.Group value={pattern} onChange={onRadioChange} className="room-create-option">
        <Radio value={SubsessionAllocationPattern.Automatically} key={SubsessionAllocationPattern.Automatically}>
          Assign automatically
        </Radio>
        <Radio value={SubsessionAllocationPattern.Manually} key={SubsessionAllocationPattern.Manually}>
          Assign manually
        </Radio>
      </Radio.Group>
      <Box my={2}>
        {pattern === SubsessionAllocationPattern.Automatically && (
          <Typography fontStyle={'italic'} color={'primary'} fontSize={'12px'}>{subsessionSize} participants per subsession</Typography>
        )}
      </Box>
      <Box>
        <Button color="primary" variant='contained' onClick={onCreateClick} fullWidth sx={{height: '50px'}}>
          Create
        </Button>
      </Box>
    </Box>
  );
};
export default SubsessionCreate;
