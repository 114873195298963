import React from 'react';
import { Dialog, DialogContent, Typography } from '@mui/material';
import CommonModalHeader from '../../../components/DialogUi/CommonModalHeader';

interface DescriptionModalProps {
    open: boolean;
    onClose: () => void;
    title?: string;
    message?: string;
}

const DescriptionModal: React.FC<DescriptionModalProps> = ({
    open,
    onClose,
    title = "",
    message = "",
}) => {
    return (
        <Dialog open={open} onClose={onClose}>
            {/* Common Modal Header */}
            <CommonModalHeader
                title={title}
                handleCloseModal={onClose}
            />

            {/* Modal Content */}
            <DialogContent>
                <Typography color={'primary'} fontSize={'16px'} whiteSpace={'pre-wrap'}>
                    {message}
                </Typography>
            </DialogContent>
        </Dialog>
    );
};

export default DescriptionModal;
