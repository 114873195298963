/* eslint-disable no-param-reassign */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable @typescript-eslint/no-invalid-this */
/* eslint-disable react-hooks/exhaustive-deps */
import react from 'react';
import _ from 'lodash';
import { Box, Dialog } from '@mui/material';
import DisabilityForm from './DisabilityForm';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import CommonModalHeader from '../../../components/DialogUi/CommonModalHeader';
import { disabilityPopupActions } from '../../../redux/slices/disability/disabilityPopupSlice';

const DisabilityModal = () => {
  const { open, disable } = useAppSelector((state) => state.disabilityPopup);

  const dispatch = useAppDispatch();

  const handleClose = () => {
    if (!disable) {
      dispatch(
        disabilityPopupActions.handleDisabilityPopup({
          open: false,
          disable: false
        })
      );
    }
  };
  return (
    <Box>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
      >
        {/* Dialog Header */}
        <CommonModalHeader 
          title={`Disability`} 
          handleCloseModal={handleClose}
        />
        <DisabilityForm />
      </Dialog>
    </Box>
  );
};

export default DisabilityModal;
