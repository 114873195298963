import React from 'react';
import { Grid, Dialog, TextField, InputLabel, DialogContent, DialogActions } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Controller, FieldValues } from 'react-hook-form';
import { useAppSelector } from '../../../../../redux/hooks';
import CommonModalHeader from '../../../../../components/DialogUi/CommonModalHeader';

interface Props {
  handleAddProgramDialog: (flag: boolean) => void;
  addProgramDialogFlag: boolean;
  onSubmit: (data: FieldValues) => void;
  addGrpLoader: boolean;
  control: any;
  errors: any;
  handleSubmit: any;
  reset: any;
  dialogType?: string;
}

const AddProgram = ({
  handleAddProgramDialog,
  addProgramDialogFlag,
  onSubmit,
  addGrpLoader,
  control,
  errors,
  handleSubmit,
  reset,
  dialogType // Add this prop
}: Props) => {
  const handleClose = () => {
    reset();
    handleAddProgramDialog(false);
  };

  const allGroupData = useAppSelector((state) => state.allGroups.data);
  const checkError = (fieldName: string) => Boolean(errors[fieldName]);
  const getError = (fieldName: string) => errors[fieldName]?.message;
  const dialogTitle = `Add a ${
    dialogType === 'level'
      ? 'Level'
      : dialogType === 'program'
      ? 'Program'
      : allGroupData?.mainGroup
      ? 'Program'
      : 'Group'
  }`;

  return (
    <Dialog open={addProgramDialogFlag} onClose={handleClose} fullWidth>
      <CommonModalHeader title={dialogTitle} handleCloseModal={handleClose} />
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <InputLabel>
              {dialogType === 'level' ? 'Level' : allGroupData?.mainGroup ? 'Program' : 'Group'} name *
            </InputLabel>
            <Controller
              name="name"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  required
                  name="name"
                  value={value || ''}
                  id="groupName"
                  type="text"
                  placeholder={`Enter ${
                    dialogType === 'level' ? 'level' : allGroupData?.mainGroup ? 'program' : 'group'
                  } name`}
                  variant="outlined"
                  onChange={onChange}
                  error={checkError('name')}
                  helperText={getError('name')?.toString()}
                />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <LoadingButton fullWidth variant="contained" onClick={handleSubmit(onSubmit)} loading={addGrpLoader}>
          Add
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default AddProgram;
