import React from 'react';
import {
  Box,
  Divider,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  cardDesign: {
    background: "#fff",
    borderRadius: "8px",
    border: "1px solid #EFF0F4",
  },
})

const ProfileSettings = () => {
  const classes = useStyles();

  return (
    <Box width='100%' m={2} 
      sx={{
        flexGrow: 1,
        height: 'calc(100vh - 115px)',
        overflowY: 'auto',
        borderRadius: '8px'
      }}
    >
      {/* <Box className={classes.cardDesign}>
        <Box p={2}>
          <Typography variant='h5'>Password & Security</Typography>
        </Box>
        <Divider />
        <Box p={2}>
          <Typography>Coming Soon...</Typography>
        </Box>
      </Box> */}
    </Box>
  );
};

export default ProfileSettings;
