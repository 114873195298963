import React from 'react';
import _ from 'lodash';
import { Dialog } from '@mui/material';
import './../../../assets/style/CallBookingCalendar.css';
import CallBookingCalendar from './CallBookingCalendar';
import CommonModalHeader from '../../../components/DialogUi/CommonModalHeader';

interface CallBookingModalProps {
  handleCloseModal: () => void;
  openFlag: boolean;
  chatProfileName: string;
  chatType: string;
  threadParticipantsUsers: any[];
  pageName: string;
  bookedCallDetails: any;
  isCallEditing?: boolean;
}

const CallBookingModal: React.FC<CallBookingModalProps> = ({
  handleCloseModal,
  openFlag,
  chatProfileName,
  threadParticipantsUsers,
  chatType,
  pageName,
  bookedCallDetails,
  isCallEditing = false
}) => {
  return (
    <Dialog open={openFlag} onClose={() => handleCloseModal()} scroll="body">
      <CommonModalHeader
        handleCloseModal={handleCloseModal}
        title={pageName === 'Calendar' ? 'Schedule New Meeting' : `Book time with ${chatProfileName}`}
      />

      <CallBookingCalendar
        onClose={() => handleCloseModal()}
        channelNaame={chatProfileName}
        threadParticipantsUsers={threadParticipantsUsers}
        chatType={chatType}
        pageName={pageName}
        bookedCallDetails={bookedCallDetails}
        isCallEditing={isCallEditing}
      />
    </Dialog>
  );
};

export default CallBookingModal;
