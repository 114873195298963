import React, { useState } from 'react';
import { Box, Divider, Typography } from '@mui/material';

interface SummaryData {
  label?: string;
  value?: number;
}

const UsageSummary: React.FC<{ dynamicData?: SummaryData[]; tabName: any; metricsValue: any }> = ({
  dynamicData,
  tabName,
  metricsValue
}) => {
  // console.log(metricsValue, tabName + ' - param');

  const totalCount =
    (metricsValue?.onboarded?.[tabName]?.count || 0) +
    (metricsValue?.matched?.[tabName]?.count || 0) +
    (metricsValue?.engaged?.[tabName]?.count || 0) +
    (metricsValue?.live?.[tabName]?.count || 0);

  // console.log(totalCount, 'total count');

  const staticData: SummaryData[] = [
    { label: 'Onboarded', value: metricsValue?.onboarded?.[tabName]?.count || 0 },
    { label: 'Matched', value: metricsValue?.matched?.[tabName]?.count || 0 },
    { label: 'Engaged', value: metricsValue?.engaged?.[tabName]?.count || 0 },
    { label: 'Live', value: metricsValue?.live?.[tabName]?.count || 0 }
  ];

  const data = dynamicData || staticData;

  const [selectedGrid, setSelectedGrid] = useState(0);

  const toggleGrid = (index: React.SetStateAction<number>) => {
    // Add logic to handle page change
    setSelectedGrid(index);
  };

  return (
    <Box className="CardBox">
      <Box display="flex" justifyContent="space-between" alignItems="center" p={2}>
        <Typography variant="h6">Usage Summary</Typography>
      </Box>
      <Divider />
      <Box pt={2} minHeight="298px">
        <Box px={2} pb={2}>
          <Typography fontSize="60px" textAlign="center" variant="h1">
            {totalCount}
          </Typography>
          <Typography fontStyle="italic" textAlign="center">
            Total {tabName}
          </Typography>
        </Box>
        <Box overflow="auto" px={2} pb={2} height="167px">
          {data.map((item, index) => (
            <React.Fragment key={index}>
              <Box display="flex" justifyContent="space-between">
                <Typography>{item.label}</Typography>
                <Typography variant="h6">{item.value}</Typography>
              </Box>
              {index < data.length - 1 && <Divider sx={{ my: 1.5 }} />}
            </React.Fragment>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default UsageSummary;
