/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState, useCallback } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { convertToRaw, EditorState, convertFromHTML, ContentState, SelectionState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { ChatStyle } from '../../../../chat/ChatStyling';
import imageCompression from 'browser-image-compression';
import { blobToFile, lockIcon, topicArray } from '../../../../../components/common';
import { API } from '../../../../../../../api';
import htmlToDraft from 'html-to-draftjs';
import { stateToHTML } from 'draft-js-export-html';
import {
  Box,
  Grid,
  Link,
  Radio,
  Tooltip,
  Divider,
  TextField,
  IconButton,
  InputLabel,
  RadioGroup,
  Typography,
  Autocomplete,
  FormControlLabel,
  FormHelperText,
  Button
} from '@mui/material';
import { toast } from 'react-toastify';
import ContentsModal from './ContentsModal';
import { AdminTaskPreview } from '../../../../../../../utils/muiStyle';
import { EditIcon, PlusCircleIcon } from '../../../../../../../utils/Icons';
import personmentor from '../../../../../../../assets/images/personmentor.svg';
import { useAppDispatch, useAppSelector } from '../../../../../../../redux/hooks';
import { IAdminTask, ITaskData, MenteeQuestion } from '../../../../../../../redux/slices/tasksSlice';
import { IArticleData, fetchAllArticles } from '../../../../../../../redux/slices/article/getAllArticleSlice';
import { useParams } from 'react-router-dom';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { reactDraftWysiwygToolbarOptionstask } from '../../../../../../../utils/react-draft-wysiwyg-options';
import _ from 'lodash';
import moment from 'moment';
import { isDueDateValid } from '../../../../../../../utils/commonFunctions';
import { AtomicBlockUtils, Modifier } from 'draft-js';


interface Row {
  id: number;
}
interface Props {
  selectedTask: IAdminTask;
  setTempTasks?: any;
  levelId?: string;
  tempTasks: ITaskData | null;
  questions: MenteeQuestion[];
  setQuestions: any;
}

export const ImageBlock = ({
  block,
  contentState,
  blockProps
}: {
  block: any;
  contentState: any;
  blockProps: any
}) => {
  const [error, setError] = useState<string | null>(null);
  const [isResizing, setIsResizing] = useState(false);
  const [dimensions, setDimensions] = useState({ width: 400, height: 'auto' });
  const resizeRef = useRef<HTMLDivElement>(null);
  const startResizeRef = useRef({ x: 0, y: 0, width: 0, height: 0 });
  const hasResized = useRef(false);

  const entityKey = block.getEntityAt(0);
  const { setEditorState, descriptionkey, onImageResize } = blockProps.blockProps;
  const entity = entityKey !== null ? contentState.getEntity(entityKey) : null;

  useEffect(() => {
    if (entity && entity.getType() === 'IMAGE') {
      const { width, height } = entity.getData();
      const numericWidth = typeof width === 'string' ?
        parseInt(width.replace('px', ''), 10) :
        width || 400;
      const numericHeight = typeof height === 'string' ?
        parseInt(height.replace('px', ''), 10) :
        height || 'auto';
      setDimensions({
        width: numericWidth,
        height: numericHeight
      });
    }
  }, [entity]);

  useEffect(() => {
    const handleBlur = async (e: MouseEvent) => {
      if (hasResized.current && entity && entityKey && !resizeRef.current?.contains(e.target as Node)) {
        await saveImageDimensions();
        hasResized.current = false;
      }
    };

    document.addEventListener('mousedown', handleBlur);
    return () => {
      document.removeEventListener('mousedown', handleBlur);
      if (hasResized.current) {
        saveImageDimensions();
      }
    };
  }, [dimensions, entity, entityKey]);

  const handleMouseDown = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsResizing(true);

    if (resizeRef.current) {
      const rect = resizeRef.current.getBoundingClientRect();
      startResizeRef.current = {
        x: e.clientX,
        y: e.clientY,
        width: rect.width,
        height: rect.height
      };
    }
  };

  const handleMouseMove = (e: MouseEvent) => {
    if (!isResizing) return;

    const deltaX = e.clientX - startResizeRef.current.x;
    const newWidth = Math.max(100, startResizeRef.current.width + deltaX);
    const aspectRatio = startResizeRef.current.height / startResizeRef.current.width;
    const newHeight: any = Math.round(newWidth * aspectRatio);

    setDimensions({ width: newWidth, height: newHeight });
    hasResized.current = true;
  };

  const handleMouseUp = async () => {
    if (!isResizing) return;
    setIsResizing(false);
    if (hasResized.current) {
      await saveImageDimensions();
      hasResized.current = false;
    }
  };

  useEffect(() => {
    if (isResizing) {
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
      return () => {
        window.removeEventListener('mousemove', handleMouseMove);
        window.removeEventListener('mouseup', handleMouseUp);
      };
    }
  }, [isResizing]);

  const saveImageDimensions = async () => {
    if (entity && entityKey) {
      const numericWidth = parseInt(dimensions.width.toString(), 10);
      const numericHeight = dimensions.height === 'auto' ?
        'auto' :
        parseInt(dimensions.height.toString(), 10);

      const newData = {
        ...entity.getData(),
        width: numericWidth,
        height: numericHeight,
        style: `width: ${numericWidth}px; height: ${numericHeight === 'auto' ? 'auto' : numericHeight + 'px'}; margin: 20px auto; display: block;`
      };

      const newContentState = contentState.replaceEntityData(entityKey, newData);
      const newEditorState = EditorState.push(
        blockProps.blockProps.editorState,
        newContentState,
        'apply-entity'
      );

      setEditorState(newEditorState);

      if (onImageResize) {
        onImageResize(newContentState);
      }

      const htmlContent = stateToHTML(newContentState, {
        entityStyleFn: (entity) => {
          if (entity.getType() === 'IMAGE') {
            const { src, width, height } = entity.getData();
            return {
              element: 'img',
              attributes: {
                src: src,
                alt: 'Resized Image',
                style: `width: ${width}px; height: ${height === 'auto' ? 'auto' : height + 'px'}; margin: 20px auto; display: block;`
              }
            };
          }
          return {};
        }
      });

      await updateContent(newContentState);
      if (blockProps.blockProps.setDescription) {
        blockProps.blockProps.setDescription(htmlContent);
      }
    }
  };

  const updateContent = async (updatedContentState: ContentState) => {
    const html = stateToHTML(updatedContentState, {
      entityStyleFn: (entity) => {
        if (entity.getType() === 'IMAGE') {
          const { src, width, height } = entity.getData();
          return {
            element: 'img',
            attributes: {
              src: src,
              alt: 'Resized Image',
              style: `width: ${width}px; height: ${height === 'auto' ? 'auto' : height + 'px'}; margin: 20px auto; display: block;`
            }
          };
        }
        return {};
      }
    });
  };

  const removeBlock = async () => {
    if (entity) {
      const imageData = entity.getData();
      const entityType = entity.getType();

      if (entityType === 'IMAGE') {
        const blobUrl = imageData.src;
        const fileName = decodeURIComponent(blobUrl.split('/').pop().split('?')[0]);

        try {
          const deletedFiles = JSON.parse(localStorage.getItem('deletedFiles') || '[]');
          localStorage.setItem('deletedFiles', JSON.stringify([...deletedFiles, { fileName, entityKey }]));
          await removeBlockFromEditor();
        } catch (error) {
          console.error('Error deleting file:', error);
          setError('Failed to delete image');
        }
      } else {
        await removeBlockFromEditor();
      }
    }
  };

  const removeBlockFromEditor = async () => {
    const { editorState } = blockProps.blockProps;
    const selectionState = editorState.getSelection();
    const blockKey = block.getKey();

    const blockSelection = selectionState.merge({
      anchorKey: blockKey,
      focusKey: blockKey,
      anchorOffset: 0,
      focusOffset: block.getLength(),
    });

    const contentStateWithoutBlock = Modifier.removeRange(contentState, blockSelection, 'backward');
    const newBlockMap = contentStateWithoutBlock.getBlockMap().toSeq();
    const nextBlockKey = contentStateWithoutBlock.getKeyAfter(blockKey);
    const nextSelection = selectionState.merge({
      anchorKey: nextBlockKey || newBlockMap.first().getKey(),
      focusKey: nextBlockKey || newBlockMap.first().getKey(),
      anchorOffset: 0,
      focusOffset: 0,
    });

    const newEditorState = EditorState.push(editorState, contentStateWithoutBlock, 'remove-range');
    setEditorState(EditorState.forceSelection(newEditorState, nextSelection));
    await updateContent(contentStateWithoutBlock);
  };

  return (
    <div className="image-block" ref={resizeRef}>
      {entityKey !== null && (
        <>
          {entity.getType() === 'IMAGE' ? (
            <div className="image-container">
              <img
                src={entity.getData().src}
                alt="Inserted content"
                className="resizable-image"
                style={{
                  width: `${dimensions.width}px`,
                  height: dimensions.height === 'auto' ? 'auto' : `${dimensions.height}px`,
                }}
              />
              <div
                className="resize-handle"
                onMouseDown={handleMouseDown}
                style={{
                  position: 'absolute',
                  right: '0',
                  bottom: '0',
                  width: '20px',
                  height: '20px',
                  cursor: 'se-resize',
                  background: 'rgba(0, 0, 0, 0.2)',
                  borderRadius: '50%'
                }}
              />
              <button
                onClick={removeBlock}
                className="delete-button"
                // style={{
                //   position: 'absolute',
                //   top: '5px',
                //   right: '5px',
                //   background: 'rgba(255, 0, 0, 0.7)',
                //   color: 'white',
                //   border: 'none',
                //   borderRadius: '50%',
                //   width: '24px',
                //   height: '24px',
                //   cursor: 'pointer',
                //   display: 'flex',
                //   alignItems: 'center',
                //   justifyContent: 'center',
                //   fontSize: '16px'
                // }}
                aria-label="Delete image"
              >
                ×
              </button>
            </div>
          ) : (
            <div className="iframe-container">
              <iframe
                src={entity.getData().src}
                title="Inserted content"
                className="embedded-iframe"
                // style={{
                //   width: '100%',
                //   height: '400px',
                //   border: 'none'
                // }}
              />
              <button
                onClick={removeBlock}
                className="delete-button"
                // style={{
                //   position: 'absolute',
                //   top: '5px',
                //   right: '5px',
                //   background: 'rgba(255, 0, 0, 0.7)',
                //   color: 'white',
                //   border: 'none',
                //   borderRadius: '50%',
                //   width: '24px',
                //   height: '24px',
                //   cursor: 'pointer'
                // }}
                aria-label="Delete iframe"
              >
                ×
              </button>
            </div>
          )}
          {error && (
            <div
              className="error-message"
              style={{
                color: 'red',
                marginTop: '5px',
                fontSize: '14px'
              }}
            >
              {error}
            </div>
          )}
        </>
      )}
    </div>
  );
};


const TaskPreviewRightBar: React.FC<Props> = ({
  selectedTask,
  setTempTasks,
  levelId,
  tempTasks,
  questions,
  setQuestions
}) => {
  const isPublished = useAppSelector((state) => state.groupDetails.data?.goalPublished) || false;
  const menteeAnsOptions = [
    { label: 'Text', value: 'Open input field' },
    { label: '1-5 Rating', value: '1-5 rating' },
    { label: 'Yes or No', value: 'Yes, No' },
    { label: 'List of Industries', value: 'List of industries' },
    { label: 'List of Roles', value: 'List of roles' }
  ];
  const [editing, setEditing] = useState(false);
  const classes = ChatStyle();
  const params = useParams();
  const grpId = params.id || '';
  const orgId = params.orgId || '';
  const [selectedArticles, setSelectedArticles] = useState<IArticleData[]>([]);
  const groupTopics = useAppSelector((state) => state.groupDetails.data?.topics) || [];
  const [taskTopics, setTaskTopics] = useState<string[]>([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [menteeQuestions, setMenteeQuestions] = useState<MenteeQuestion[]>([]);
  const [description, setDescription] = useState('');
  const [taskName, setTaskName] = useState('');
  const data = useAppSelector((state) => state.tasks.data);
  const task = data?.levels.find((level) => level._id === levelId)?.tasks.find((task) => task._id === selectedTask._id);
  const [isTaskNameEditing, setIsTaskNameEditing] = useState(false);
  const topics = topicArray();
  const mappedTopics = groupTopics.map((topic: string) => {
    const index = topics.find((each) => each.name === topic);
    if (index) {
      return index.label;
    }
    return topic;
  });
  const articleData = useAppSelector((state) => state.allArticle.data);
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  const editorRef = useRef<any>(null);
  const acceptedImages = 'image/jpeg,image/jpg,image/png';
  const schema = yup.object().shape({
    taskDescription: yup.string().required('Task Description is required')
  });

  const checkError = (fieldName: string) => Boolean(errors[fieldName]);
  const getError = (fieldName: string) => errors[fieldName]?.message;

  const formData = new FormData();

  const uploadTaskDescriptionFile = async (file: File) => {
    formData.append('file', file);
    try {
      const result = await API.saveTaskDescriptionFiles(formData);
      const blobUrl = _.get(_.head(_.get(result, 'data.data')), 'Bloburl', '');
      return { data: { link: blobUrl } };
    } catch {
      return Error('Failed to upload');
    }
  };

  const handleImageCompression = async (imageFile: any) => {
    const options = {
      maxSizeMB: 1,
      // maxWidthOrHeight: 1920,
      useWebWorker: true
    };
    try {
      const compressedFile = await imageCompression(imageFile, options);
      const blobToImageFile = blobToFile(compressedFile, imageFile?.name, imageFile?.type);
      return blobToImageFile;
    } catch (error) { }
  };

  const {
    control,
    formState: { errors }
  } = useForm({ resolver: yupResolver(schema) });

  const insertImage = (editorState: EditorState, imageUrl: string, altImage: string) => {
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity('IMAGE', 'IMMUTABLE', { src: imageUrl, alt: altImage });
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

    const newEditorState = AtomicBlockUtils.insertAtomicBlock(editorState, entityKey, ' ');

    return newEditorState;
  };

  const handlePaste = useCallback(
    (event: ClipboardEvent) => {
      const items = event.clipboardData?.items;
      if (!items) return;
      for (const item of items) {
        if (item.type.indexOf('image') === 0) {
          const file = item.getAsFile();
          if (file) {
            imageUpload(file, 400, 400, 0.5)
              .then((response: any) => {
                if (response.data.link) {
                  const imageUrl = response.data.link;
                  const altImage = 'image';
                  const width = '400px';
                  const newEditorState = insertImage(editorState, imageUrl, altImage);
                  setEditorState(newEditorState);
                  event.preventDefault();
                  return;
                }
              })
              .catch((error: any) => {
                console.error('Image upload error:', error);
              });
          }
        }
      }
    },
    [editorState]
  );

  useEffect(() => {
    const editorContainer = document.querySelector('.taskEditorSize');
    if (editorContainer) {
      editorContainer.addEventListener('paste', handlePaste as any);
    }

    return () => {
      if (editorContainer) {
        editorContainer.removeEventListener('paste', handlePaste as any);
      }
    };
  }, [handlePaste]);

  const handleEditorStateChange = (newEditorState: EditorState) => {
    setEditorState(newEditorState);
    let contentState = newEditorState.getCurrentContent();
    const ensurePx = (value:any) => (typeof value === 'number' ? `${value}px` : value.endsWith('px') ? value : `${value}px`);
    contentState.getBlockMap().forEach((block) => {
      if (block) {
        const entityKey = block.getEntityAt(0);
        if (entityKey) {
          const entity = contentState.getEntity(entityKey);
          if (entity.getType() === 'IMAGE') {
            const data = entity.getData();
            const width = ensurePx(data.width || 'auto');
            const height = ensurePx(data.height || 'auto');
            const updatedData = { ...data, width, height };
            contentState = contentState.replaceEntityData(entityKey, updatedData);
          }
        }
      }
    });
    const hasContent =
      contentState.hasText() ||
      contentState.getBlockMap().some((block) => block ? block.getType() !== 'unstyled' : false);
  
    let newDescription = '';
    if (hasContent) {
      newDescription = draftToHtml(convertToRaw(contentState));
    }
    setDescription(newDescription);
    updateTask(newDescription);
  };

  const embedCallback = (url: any) => {
    if (url.indexOf('youtu.be/') >= 0) {
      // Extract the video ID from the URL
      const videoId = url.match(/youtu\.be\/([^?]+)/);
      if (videoId) {
        // Construct the new embed URL for youtu.be format
        return `https://www.youtube.com/embed/${videoId[1]}`;
      }
    } else if (url.indexOf('youtube') >= 0) {
      // Convert the 'watch' URL to the 'embed' format for youtube.com
      url = url.replace('watch?v=', 'embed/');
      url = url.replace('/watch/', '/embed/');
      url = url.replace('youtu.be/', 'youtube.com/embed/');
    }
    // If the URL doesn't match either format, return the original URL
    return url;
  };

  // this is for img upload
  const imageUpload = async (file: any, maxWidth: any = 400, maxHeight: any = 400, quality: any = 1.0) => {
    if (!acceptedImages.includes(file.type)) {
      return { error: 'Image upload failed' };
    } else {
      try {
        if (file.size <= 1024 * 1024) {
          const result = await uploadTaskDescriptionFile(file);
          return result;
        } else {
          const reSizedFile = await handleImageCompression(file);
          if (reSizedFile) {
            const result = await uploadTaskDescriptionFile(reSizedFile);
            return result;
          }
        }

        // formData.append("file", file)
        // const result = await API.saveArticleDescriptionFiles(formData)
        // const test =  _.get(_.head(_.get(result,'data.data')),'Bloburl','');
        // const imageDataUrl = await handleImageCompression(file)
        // const tt =  await readAsDataURL(imageDataUrl)
        // return {data: {link: test}}
        // const imageDataUrl = await compressImage(file, maxWidth, maxHeight, quality);
      } catch (error) {
        return { error: 'Image upload failed' };
      }
    }
  };
  const extendedToolbarOptions = reactDraftWysiwygToolbarOptionstask(imageUpload, embedCallback);

  const blockRender = (contentBlock: any, descriptionkey: string) => {
    const entityKey = contentBlock.getEntityAt(0);
    const contentState = editorState.getCurrentContent();

    if (contentBlock.getType() === 'atomic' || contentBlock.text === '📷') {
      return {
        component: ImageBlock,
        editable: false,
        props: {
          block: contentBlock,
          contentState: contentState, // Add this line to pass contentState
          blockProps: {
            editorState,
            setEditorState,
            descriptionkey: descriptionkey,
            onImageResize: (newContentState: any) => {
              // Handle content update when image is resized
              const newEditorState = EditorState.push(
                editorState,
                newContentState,
                'apply-entity'
              );
              setEditorState(newEditorState);
            }
          }
        }
      };
    }
    return null;
  };

  useEffect(() => {
    const savedDescription = selectedTask.taskDescription || '';
    setDescription(savedDescription);

    const contentBlock = htmlToDraft(savedDescription);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks, contentBlock.entityMap);
      const editorStateWithContent = EditorState.createWithContent(contentState);
      const editorStateWithCursorAtEnd = EditorState.moveFocusToEnd(editorStateWithContent);
      setEditorState(editorStateWithCursorAtEnd);
    }
    setMenteeQuestions(selectedTask.menteeQuestions || []);
    setQuestions(selectedTask?.menteeQuestions || []);
    setSelectedArticles(selectedTask.libraryArticles || []);
    setTaskTopics(selectedTask.tags || []);
    // works when start or end date is zero also
    setStartDate(selectedTask?.startDate ? moment.unix(selectedTask.startDate).format('YYYY-MM-DD') : '');
    setEndDate(selectedTask?.endDate ? moment.unix(selectedTask.endDate).format('YYYY-MM-DD') : '');
    setTaskName(selectedTask.taskName || '');
    setIsTaskNameEditing(false);
    setEditing(false);
  }, [selectedTask._id, levelId]);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [scrollRef.current, selectedTask._id, levelId]);

  const updateTask = (newDescription?: string) => {
    const tags = taskTopics.map((topic: string) => {
      const index = topics.find((each) => each.label === topic);
      if (index) {
        return index.name;
      }
      return topic;
    });
    if (tempTasks && tempTasks.levels.length > 0) {
      const newTasks = tempTasks?.levels.map((level) => {
        if (level._id === levelId) {
          return {
            ...level,
            tasks: level.tasks.map((task, index) => {
              if (task._id === selectedTask._id) {
                const isValid = task?.menteeQuestions?.every(
                  (item) => (!item.menteeQues && !item.menteeAnsType) || (item.menteeQues && item.menteeAnsType)
                );

                const updatedDescription = newDescription !== undefined ? newDescription : task.taskDescription;

                const validData =
                  task.taskName &&
                  isValid &&
                  task.taskDescription?.trim() !== '' &&
                  tags.length !== 0 &&
                  isDueDateValid(
                    startDate ? moment(startDate, 'YYYY-MM-DD').unix() : '',
                    endDate ? moment(endDate, 'YYYY-MM-DD').unix() : ''
                  );
                return {
                  ...task,
                  taskDescription: updatedDescription,
                  menteeQuestions,
                  libraryArticles: selectedArticles,
                  tags,
                  startDate: startDate ? moment(startDate, 'YYYY-MM-DD').unix() : '',
                  endDate: endDate ? moment(endDate, 'YYYY-MM-DD').unix() : '',
                  taskName: taskName.trim() ? taskName : selectedTask.taskName,
                  error: validData ? false : task.error
                };
              }
              return task;
            })
          };
        }
        return level;
      });
      setTempTasks({ ...tempTasks, levels: newTasks });
    }
  };

  // const convertLineBreaksToBr = (text: string) => {
  //   return text.replace(/\n/g, '<br/>');
  // };

  // const convertBreaksToLine = (text: string) => {
  //   return text.split('<br />').join('\n');
  // };

  useEffect(() => {
    const timer = setTimeout(() => {
      updateTask();
    }, 500);
    return () => clearTimeout(timer);
  }, [description, questions, selectedArticles, taskTopics, taskName, startDate, endDate]);

  const startDueDate = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Box display="flex" alignItems="center">
            <InputLabel>Start date
              {selectedTask?.error &&
                !isDueDateValid(
                  moment(startDate, 'YYYY-MM-DD').unix() || '',
                  moment(endDate, 'YYYY-MM-DD').unix() || ''
                ) && (
                  <Tooltip title='The start date should be before the due date.'>
                    <ErrorOutlineIcon style={{width:'14px', height:'14px', marginLeft: '5px'}} color="error" />
                  </Tooltip>
              )}
            </InputLabel>
          </Box>
          <TextField
            type="date"
            name="endDate"
            placeholder="Select Date"
            id="endDate"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            variant="outlined"
            InputLabelProps={{
              shrink: true
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <InputLabel>Due date</InputLabel>
          <TextField
            type="date"
            name="endDate"
            id="endDate"
            variant="outlined"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            placeholder="Select Date"
            InputLabelProps={{
              shrink: true
            }}
          />
        </Grid>
      </Grid>
    );
  };

  const isQuestion = menteeQuestions.every(
    (item) => (!item.menteeQues && !item.menteeAnsType) || (item.menteeQues && item.menteeAnsType)
  );

  const addTaskQuestions = () => {
    const handleAddQuestion = () => {
      setQuestions((prev: any) => [
        ...prev,
        { _id: prev.length + 1, menteeQues: '', menteeAnsType: '', isAddedNewly: true }
      ]);
      // setShowPlus(false); // Hide the "+ Add questions" link after adding
    };

    const handleRemoveRow = (idToRemove?: any) => {
      setQuestions(questions.filter(({ _id }) => _id !== idToRemove));
    };

    const handleChangeQuestionAndAnswerType = (
      field: 'menteeQues' | 'menteeAnsType',
      value: string,
      id?: any,
      selectedTaskId?: string
    ) => {
      // Ensure we only update the questions if selectedTask?._id matches selectedTaskId
      if (selectedTask?._id === selectedTaskId) {
        setQuestions((prev: any) => {
          // First, update the questions array
          const updatedQuestions = prev.map((q: any) => (q._id === id ? { ...q, [field]: value } : q));

          // Remove the 'id' and 'isAddedNewly' properties while setting menteeQuestions
          // const updatedMenteeQuestions = updatedQuestions.map(({ _id, isAddedNewly, ...rest }: any) => rest);

          // Update the menteeQuestions state with the cleaned-up data
          setMenteeQuestions(updatedQuestions);

          return updatedQuestions; // Return updated questions to be set in state
        });
      }
    };

    // console.log('questions', questions);
    // console.log('selectedTask', selectedTask);

    /** Add questions */
    return (
      <>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box gap={0.5} display="flex" alignItems="center">
            <Typography variant="h5">Add questions</Typography>
            <Typography fontWeight={600}>(optional)</Typography>
            {selectedTask.error && !isQuestion && (
              <IconButton style={{ marginLeft: '10px' }} title="Required">
                <ErrorOutlineIcon fontSize="small" color="error" />
              </IconButton>
            )}
          </Box>
          {/* {showPlus && ( */}
          <IconButton
            size="small"
            onClick={
              // () => setShowPlus(false)
              handleAddQuestion
            }
          >
            <PlusCircleIcon size={34} />
          </IconButton>
          {/* )} */}
        </Box>

        {/* {showPlus ? (
          <Box textAlign="center" pb={2}>
            <Link color="#0071A9" variant="caption" onClick={() => setShowPlus(false)}>
              + Add questions
            </Link>
          </Box>
        ) : (
          // addTaskRows.map((row) => ( */}
        {questions.map(({ _id, menteeQues, menteeAnsType, isAddedNewly }) => (
          <Box pb={2} key={_id}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} lg={7} md={8} sm={12}>
                <InputLabel>Question title</InputLabel>
                <TextField
                  fullWidth
                  placeholder="Question title"
                  value={menteeQues}
                  onChange={(e) =>
                    // setMenteeQues(e.target.value)
                    handleChangeQuestionAndAnswerType('menteeQues', e.target.value, _id, selectedTask?._id)
                  }
                  disabled={
                    isPublished && !isAddedNewly
                    // && (task?.menteeQues ? true : false)
                  }
                />
              </Grid>
              <Grid item xs={12} lg={4} md={4} sm={12}>
                <InputLabel>Answer format </InputLabel>
                <Autocomplete
                  options={menteeAnsOptions}
                  getOptionLabel={(option) => option.label}
                  value={menteeAnsOptions.find((option) => option.value === menteeAnsType) || null}
                  renderInput={(params) => <TextField {...params} fullWidth placeholder="Answer Format" />}
                  onChange={(e, value) => {
                    // setMenteeAnsType(value?.value || '');
                    handleChangeQuestionAndAnswerType('menteeAnsType', value?.value || '', _id, selectedTask?._id);
                  }}
                  disabled={
                    isPublished && !isAddedNewly
                    //  (task?.menteeAnsType ? true : false)
                  }
                />
              </Grid>
              {isAddedNewly && (
                //  && (task?.menteeQues || task?.menteeAnsType)
                <Grid item xs={12} lg={1} md={12} sm={12} mt={{ xs: 0, lg: 2 }} textAlign="right">
                  <Typography
                    color="error"
                    variant="caption"
                    className="cursor-pointer"
                    onClick={() => handleRemoveRow(_id)}
                  >
                    Remove
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Box>
        ))}
        <Box textAlign="center" pb={2}>
          <Link
            color="#0071A9"
            variant="caption"
            onClick={
              // () => setShowPlus(false)
              handleAddQuestion
            }
          >
            + Add questions
          </Link>
        </Box>
        {/* // ))
        )} */}
      </>
    );
  };

  const taskComplete = ({ disabled = false }) => {
    return (
      <Box>
        <Box py={2} display="flex" alignItems="center" gap={0.5} className={disabled ? 'opacity-25' : ''}>
          <Typography variant="h5">Have you completed this task?</Typography>
          {disabled && lockIcon({})}
        </Box>

        <Box pb={2}>
          <RadioGroup name="use-radio-group" defaultValue="yes" id="">
            <Box border="1px solid #EFF0F4" borderRadius="8px" width="50%" mb={1} px={2}>
              <FormControlLabel
                value="yes"
                label="Yes"
                control={<Radio />}
                disabled={disabled}
                className={disabled ? 'opacity-25' : ''}
              />
            </Box>
            <Box border="1px solid #EFF0F4" borderRadius="8px" width="50%" px={2}>
              <FormControlLabel
                value="no"
                label="No"
                control={<Radio />}
                disabled={disabled}
                className={disabled ? 'opacity-25' : ''}
              />
            </Box>
          </RadioGroup>
        </Box>
      </Box>
    );
  };

  const noArticle = (index: any) => (
    <Box className="second-resume-sec" key={index}>
      <Box borderRadius="8px" bgcolor="#EFF0F4" height="79px" minWidth="118px" />
      <Box width="160px">
        <Typography className="onlyShowTwoLine" variant="h5">
          Article Title
        </Typography>
        <Typography>Read Time</Typography>
      </Box>
    </Box>
  );

  const buildUpYourKnowledge = () => {
    const articles = selectedArticles?.map((articleId: any) => {
      const index = articleData?.ArticleList.find((each: any) => each.articleId === articleId);
      return index || { articleId: '', articleName: '', coverImageUrl: '', readTime: '' };
    });
    return (
      <Box className="CardBox" my={2}>
        <Box gap={1} display="flex" justifyContent="space-between" textAlign="center" alignItems="center" px={2}>
          <Typography variant="h5" py={2}>
            Build up your knowledge
          </Typography>
          <IconButton size="small" onClick={handleClickOpenContents}>
            <EditIcon />
          </IconButton>
        </Box>
        <Divider />
        <Box className="resume-main-sec">
          {articles.length > 0
            ? articles.map((article: any, index: number) => {
              if (article.coverImageUrl) {
                return (
                  <Box className="second-resume-sec" key={index}>
                    {/* <img borderRadius="8px" bgcolor="#EFF0F4" height="79px" minWidth="118px" /> */}

                    <Box borderRadius="8px" bgcolor="#EFF0F4" minWidth="118px">
                      <img src={article.coverImageUrl} alt="error" height="79px" width="100%" className="br-8" />
                    </Box>
                    <Box width="160px">
                      <Tooltip title={article.articleName}>
                        <Typography className="onlyShowTwoLine" variant="h5">
                          {article.articleName}
                        </Typography>
                      </Tooltip>
                      <Typography>{article.readTime}</Typography>
                    </Box>
                  </Box>
                );
              }
              return noArticle(index);
            })
            : [1, 2, 3].map((index) => noArticle(index))}
        </Box>
      </Box>
    );
  };

  const getAdviceFrom = () => {
    return (
      <Box className="CardBox">
        <Box p={2}>
          <Typography variant="h5">Get advice from people in your community</Typography>
        </Box>
        <Divider />
        <Box display="flex" width="100%" minHeight="auto" overflow="auto">
          <Box width="400px" minWidth="40%">
            <Box height="100%">
              <Box display="flex" pt={2} px={2}>
                <img src={personmentor} alt="Trophybadge" width="24" height="24" />
                <Typography className="my-mentor-text">My mentors</Typography>
              </Box>
              <Box display="flex" p={2} gap={1}>
                <Box borderRadius="8px" bgcolor="#EFF0F4" height="50px" minWidth="50px" />
                <Box>
                  <Typography variant="h5">Name</Typography>
                  <Typography>Member will be display here...</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Divider sx={{ height: '100px', my: 2 }} />
          <Box>
            <Box display="flex" pt={2} px={2}>
              <img src={personmentor} alt="Trophybadge" width="24" height="24" />
              <Typography className="my-mentor-text">Others with relevant experience</Typography>
            </Box>
            <Box display="flex">
              {Array.from({ length: 4 }, (_, index) => (
                <Box key={index} display="flex" p={2} gap={1} minWidth="300px">
                  <Box borderRadius="8px" bgcolor="#EFF0F4" height="50px" minWidth="50px" />
                  <Box>
                    <Typography variant="h5">Name</Typography>
                    <Typography>Member will be display here...</Typography>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  const selectTopics = () => {
    return (
      <Box className="CardBox" my={2}>
        <Box px={2} pt={2} gap={0.5} display="flex" alignItems="center">
          <Typography variant="h5">Select topics relevant to this task</Typography>
          <Typography fontWeight={600}>(only visible to admin)</Typography>
          {selectedTask.error && taskTopics?.length === 0 && (
            <IconButton style={{ marginLeft: '10px' }} title="Required">
              <ErrorOutlineIcon fontSize="small" color="error" />
            </IconButton>
          )}
        </Box>
        <Box p={2}>
          <Autocomplete
            multiple
            options={mappedTopics}
            getOptionLabel={(option: string) => option}
            id="taskTopics"
            renderInput={(params) => (
              <TextField {...params} variant="outlined" size="small" placeholder="Select topics" />
            )}
            onChange={(_event, value) => setTaskTopics(value)}
            value={taskTopics.map((each) => {
              return topics.find((topic) => topic.name === each)?.label || each;
            })}
          />
        </Box>
      </Box>
    );
  };

  const handleEditClick = () => {
    setEditing(true);
    setTimeout(() => {
      const editorElement = document.querySelector('.public-DraftEditor-content') as HTMLDivElement;
      if (editorElement) {
        editorElement.focus();
      }
    }, 100);
  };

  
  const handleEditExit = async (event: React.FocusEvent) => {
    const imageDialogOpen = document.querySelector('.rdw-image-modal');
  
    if (
      !event.currentTarget.contains(event.relatedTarget) &&
      !document.querySelector('.toolBarStyleTask')?.contains(event.relatedTarget) &&
      !imageDialogOpen
    ) {
      const contentState = editorState.getCurrentContent();
      const blockMap = contentState.getBlockMap();
  
      const hasContent = blockMap.some((block) => {
        if (!block) return false;
        return block.getType() !== 'unstyled' || block.getText().trim() !== '';
      });
  
      if (!hasContent) {
        setDescription('');
      } else {
        const rawContentState = convertToRaw(contentState);
        Object.values(rawContentState.entityMap).forEach((entity: any) => {
          if (entity.type === 'IMAGE') {
            const width = entity.data.width?.toString().includes("px") ? entity.data.width : `${entity.data.width}px`;
            const height = entity.data.height?.toString().includes("px") ? entity.data.height : `${entity.data.height}px`;
            
            entity.data = {
              ...entity.data,
              width: width,
              height: height,
              style: `width: ${width}; height: ${height}; margin: 20px auto; display: block;`
            };
          }
        });
        const htmlContent = draftToHtml(rawContentState);
        setDescription(htmlContent);
        const editorStateWithContent = EditorState.createWithContent(contentState);
        const editorStateWithCursorAtEnd = EditorState.moveFocusToEnd(editorStateWithContent);
        setEditorState(editorStateWithCursorAtEnd);
      }
  
      setEditing(false);
    }
  };
  
  

  /** Contents Modal */
  const [openContents, setOpenContents] = React.useState(false);
  const dispatch = useAppDispatch();

  const handleClickOpenContents = () => {
    setOpenContents(true);
  };
  const handleCloseContents = () => {
    setOpenContents(false);
  };

  const handleTaskTitleClick = () => {
    setIsTaskNameEditing(true);
  };
  const taskNameRef = useRef<HTMLInputElement | null>(null);
  useEffect(() => {
    if (isTaskNameEditing && taskNameRef.current) {
      taskNameRef.current.focus();
      // to keep the cursor at the end of the text
      if (taskNameRef.current) {
        const { length } = taskNameRef.current.value;
        taskNameRef.current.setSelectionRange(length, length);
      }
    }
  }, [isTaskNameEditing]);

  const descriptionRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (editing && descriptionRef.current) {
      descriptionRef.current.focus();
      // to keep the cursor at the end of the text
      if (descriptionRef.current) {
        const { length } = descriptionRef.current.value;
        descriptionRef.current.setSelectionRange(length, length);
      }
    }
  }, [editing]);

  const updateHTMLWithCorrectLink = (t: any) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(t, 'text/html');

    const link = doc.querySelector('a[href="app.joinhandshake.com"]');
    if (link) {
      let href: any = link.getAttribute('href');
      if (!href.startsWith('http') && !href.startsWith('//')) {
        href = 'https://' + href;
        link.setAttribute('href', href);
      }
    }
    if (link) {
      if (!link.hasAttribute('target')) {
        link.setAttribute('target', '_blank');
      }
    }

    return doc.body.innerHTML;
  };

  return (
    <Box height="100%">
      <Box px={2} className="CardBox">
        <Box gap={1} display="flex" justifyContent="space-between" textAlign="center" alignItems="center">
          {!isTaskNameEditing ? (
            <>
              <Typography variant="h5" py={2} ref={scrollRef}>
                {taskName || 'Add task title'}
              </Typography>
              {selectedTask?.error && taskName === '' && (
                <IconButton style={{ marginLeft: '10px' }} title="Required">
                  <ErrorOutlineIcon fontSize="small" color="error" />
                </IconButton>
              )}
              <IconButton size="small" onClick={handleTaskTitleClick}>
                <EditIcon />
              </IconButton>
            </>
          ) : (
            <TextField
              variant="outlined"
              size="small"
              className="w-100"
              placeholder="Add TasK Title"
              value={taskName}
              onChange={(e) => setTaskName(e.target.value)}
              style={{ margin: '10px 0px 10px 0px' }}
              onBlur={() => {
                if (taskName.trim() === '') {
                  setTaskName(selectedTask?.taskName || 'Add Task Title');
                }
                setIsTaskNameEditing(false);
              }}
              inputRef={taskNameRef}
            />
          )}
        </Box>
        <Box position="relative" onClick={() => handleEditClick()} style={{ width: '100%' }} bgcolor={'error'}>
          {editing && (
            <Grid item xs={12}>
              <Box className={classes.chatTextBox}>
                <Controller
                  name="taskDescription"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <>
                      <Editor
                        editorState={editorState}
                        ref={editorRef}
                        customBlockRenderFunc={(contentBlock) => blockRender(contentBlock, 'task')}
                        onEditorStateChange={handleEditorStateChange}
                        editorStyle={{ fontFamily: 'Open Sans' }}
                        toolbar={extendedToolbarOptions}
                        placeholder="Add Task Description here "
                        toolbarClassName="toolBarStyle toolBarStyleTask"
                        wrapperClassName="wrapperClassName p-0 b-0"
                        editorClassName="taskEditorSize word-wrap"
                        onBlur={handleEditExit}
                      />
                      <FormHelperText color="error" className="text-danger">
                        {checkError('taskDescription') && getError('taskDescription')?.toString()}
                      </FormHelperText>
                    </>
                  )}
                />
              </Box>
            </Grid>
          )}
          {/* For placeholder */}
          {!editing && (
             <Box style={{ display: 'flex' }} alignItems="center">
            <Box
              className="taskDescriptionBody"
              dangerouslySetInnerHTML={{
                __html:
                  description?.replace(/<p><\/p>/g, '').trim() !== ''
                    ? description // .replace(/<p><\/p>/g, '<br/>')
                    : '<span style="color: grey;">Add Task Description</span>'
              }}
              width={'100%'}
            />
          
            {selectedTask.error && (!description || description.replace(/<p><\/p>/g, '').trim() === '') && (
              <IconButton style={{ marginLeft: '10px' }} title="Required">
                <ErrorOutlineIcon fontSize="small" color="error" />
              </IconButton>
            )}
          </Box>
          )}
        </Box>
        <Divider className="my-20" />

        {/* Start Due date */}
        {startDueDate()}
        <Divider className="my-20" />

        {/* Add Task Questions */}
        {addTaskQuestions()}
        <Divider />

        {/* Have you completed this task */}
        {taskComplete({ disabled: true })}
      </Box>

      {/* Build up your knowledge */}
      {buildUpYourKnowledge()}

      {/* Get advice from people in your community */}
      {getAdviceFrom()}

      {/* Select topics relevant to this task */}
      {selectTopics()}

      {/* Contents Modal */}
      <ContentsModal
        openContents={openContents}
        onClose={handleCloseContents}
        setArticles={setSelectedArticles}
        articlesSelected={selectedArticles}
      />
    </Box>
  );
};

export default TaskPreviewRightBar;
