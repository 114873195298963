import React from 'react';
import { useDrop } from 'react-dnd';

const SectionLevel = ({ moveLevel, levelIndex }: any) => {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: 'LEVEL',
    collect: (monitor) => ({
      isOver: !!monitor.isOver()
    }),
    drop: (item: any) => {
      moveLevel(item.levelIndex, levelIndex);
    }
  }));
  return (
    <div
      ref={drop}
      style={{
        height: isOver ? '40px' : '15px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: isOver ? '1px dashed grey' : 'none'
      }}
    >
      {isOver ? 'Drop here' : ''}
    </div>
  );
};

export default SectionLevel;
