import { Box, Stack } from "@mui/material"
import MySkeleton from "../components/Skeleton";
import useResponsiveScreenSwitch from "../utils/useResponsiveScreenSwitch";

export const GetTasksLoader = () => {
    const { forMobile } = useResponsiveScreenSwitch();
    return (
        <Box px={2} pb={2}>
            <Box className="MylevelsCardMain">
                {[...Array(3).keys()].map((_, index) => {
                    return (
                        <Box className="MylevelsCard" key={index} pb={.5}>
                            <Box
                                borderTop='10px solid #ABB5BE'
                                borderRadius='8px 8px 0 0'
                            />
                            <Stack
                                direction="row"
                                spacing={2}
                                p={2}
                                justifyContent={"space-between"}
                            >
                                <Box display={"flex"} flexDirection={"column"}>
                                    <MySkeleton variant='text' width={60}/>
                                    <MySkeleton variant='text' width={120}/>
                                </Box>
                                <Box display={"flex"} gap={1} alignItems={'center'}>
                                    <MySkeleton variant='rounded' height={35} width={35}/>
                                    <Box>
                                        <MySkeleton variant='rectangular' height={20} width={3}/>
                                    </Box>
                                </Box>
                            </Stack>
                            <MySkeleton variant='rectangular' height={1} /> 
                            <Box p={2} gap={'10px'} display={"flex"} flexDirection={"column"}>
                                {[...Array(5).keys()].map((_, idx) => (
                                    <Stack
                                        key={idx}
                                        direction="row"
                                        spacing={1}
                                        alignItems={'center'}
                                    >
                                        <MySkeleton variant='circular' height={20} width={20}/>
                                        <MySkeleton height={19} width={200} />
                                    </Stack>
                                ))}
                            </Box>
                        </Box>
                    );
                })}
            </Box>
        </Box>
    );
};
