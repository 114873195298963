import React from 'react';
import { Button } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { appColors } from '../../../../../../utils/theme';

interface ExportButtonProps {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  isClicked: boolean;
  disabled?: boolean;
}

const ExportButton: React.FC<ExportButtonProps> = ({ onClick, isClicked, disabled = false }) => {
  return (
    <Button
      variant="outlined"
      onClick={onClick}
      disabled={disabled}
      sx={{
        width: '86px',
        height: '36px',
        fontSize: '12px',
        fontWeight: 700,
        border: '1px solid',
        borderRadius: '8px',
        color: isClicked ? appColors.blue1 : appColors.gray4,
        borderColor: isClicked ? appColors.blue1 : appColors.gray4
      }}
    >
      Export
      <KeyboardArrowDownIcon
        sx={{
          fontSize: '19px',
          color: isClicked ? appColors.blue1 : appColors.gray4
        }}
      />
    </Button>
  );
};

export default ExportButton;
