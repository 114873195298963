import React from 'react';
import { makeStyles } from '@mui/styles';
import delecticon from '../assets/images/delecticon.svg';
import pencilicon from '../assets/images/pencil.svg';
import profileicon from '../assets/images/profileIcon.svg';
import signOuticon from '../assets/images/signOutIcon.svg';
import pluscircle from '../assets/images/pluscircle.svg';
import chatedit from '../assets/images/chatedit.svg'
import calendardark from '../assets/images/calendardark.svg'
import filter from '../assets/images/Filter.svg';
import previewicon from '../assets/images/previewicon.svg';
import clockicon from '../assets/images/clockicon.svg';
import profileperson from '../assets/images/profileperson.svg';
import CheckListSvg from '../assets/images/check-list.svg';
import smallchat from '../assets/images/smallchat.svg';
import chaticon from '../assets/images/chaticon.svg';
import Play from '../assets/images/Play.svg';
import celendaricon from '../assets/images/celendericon.svg';
import shareicon from '../assets/images/ShareIcon.svg';
import fluentwand from '../../../../assets/images/fluentwand.svg';
import profileiconFFF from '../assets/images/profileiconFFF.svg';
import addLevel from '../assets/images/addLevel.svg'
import circleAdd from '../assets/images/circleAdd.svg'
import uploadIcon from '../assets/images/upload.svg';
import iconview from '../assets/images/iconview.svg';
import thumbsupgreyimg from '../assets/images/thumbsupgreyimg.svg';
import answer from '../assets/images/answer.svg';
import downloadPdf from '../assets/images/download-Pdf.svg';
import exportPlan from '../assets/images/exportPlan.svg';
import excelFile from '../assets/images/microsoft-Excel-file.svg';
import blueCircleCalendar from '../assets/images/book.svg';
import Explore from '../assets/images/explore.svg';
import Chat1 from '../assets/images/chat1.svg';
import Community from '../assets/images/community.svg';
import TasksList from '../assets/images/tasksList.svg';
import SetTimeData from '../assets/images/settimedata.svg';

interface IconProps {
  size?: number | string;
  height?: number | string;
  width?: number | string;
  color?: string;
  onClick?: (event: React.MouseEvent<HTMLImageElement>) => void;
  style?: React.CSSProperties;
}

const DEFAULT_SIZE = 20;

const useStyles = makeStyles({
  icon: (props: IconProps) => ({
    padding: '0',
    margin: '0',
    cursor: props.onClick ? 'pointer' : '',
    ...props.style,  // Spread the style prop here
  }),
});

const Icon: React.FC<IconProps & { icon: string }> = ({
  size = DEFAULT_SIZE,
  height = size,
  width = size,
  icon,
  onClick,
  style,
}) => {
  const classes = useStyles({ onClick, style });

  return (
    <img
      src={icon}
      alt={"icon"}
      width={width}
      height={height}
      onClick={onClick}
      className={classes.icon}
    />
  );
};

/** Icons */
export const DeleteIcon: React.FC<IconProps> = (props) => <Icon {...props} icon={delecticon} />;
export const EditIcon: React.FC<IconProps> = (props) => <Icon {...props} icon={pencilicon} />;
export const ProfileIcon: React.FC<IconProps> = (props) => <Icon {...props} icon={profileicon} />;
export const SignOutIcon: React.FC<IconProps> = (props) => <Icon {...props} icon={signOuticon} />;
export const PlusCircleIcon: React.FC<IconProps> = (props) => <Icon {...props} icon={pluscircle} />;
export const ChatEditIcon: React.FC<IconProps> = (props) => <Icon {...props} icon={chatedit} />;
export const CalendardarkIcon: React.FC<IconProps> = (props) => <Icon {...props} icon={calendardark} />;
export const FilterIcon: React.FC<IconProps> = (props) => <Icon {...props} icon={filter} />;
export const PreviewIcon: React.FC<IconProps> = (props) => <Icon {...props} icon={previewicon} />;
export const ClockIcon: React.FC<IconProps> = (props) => <Icon {...props} icon={clockicon} />;
export const ProfilePersonIcon: React.FC<IconProps> = (props) => <Icon {...props} icon={profileperson} />;
export const CheckListIcon: React.FC<IconProps> = (props) => <Icon {...props} icon={CheckListSvg} />;
export const Smallchat: React.FC<IconProps> = (props) => <Icon {...props} icon={smallchat} />;
export const ChatIcon: React.FC<IconProps> = (props) => <Icon {...props} icon={chaticon} />;
export const PlayIcon: React.FC<IconProps> = (props) => <Icon {...props} icon={Play} />;
export const CalendarIcon: React.FC<IconProps> = (props) => <Icon {...props} icon={celendaricon} />;
export const ShareIcon: React.FC<IconProps> = (props) => <Icon {...props} icon={shareicon} />;
export const UserIcon: React.FC<IconProps> = (props) => <Icon {...props} icon={profileiconFFF} />;
export const AddLevelIcon: React.FC<IconProps> = (props) => <Icon {...props} icon={addLevel} />;
export const CircleAddIcon: React.FC<IconProps> = (props) => <Icon {...props} icon={circleAdd} />;
export const UploadIcon: React.FC<IconProps> = (props) => <Icon {...props} icon={uploadIcon} />;
export const EyeIcon: React.FC<IconProps> = (props) => <Icon {...props} icon={iconview} />;
export const ThumbsupFullGreyIcon: React.FC<IconProps> = (props) => <Icon {...props} icon={thumbsupgreyimg} />;
export const AnswerIcon: React.FC<IconProps> = (props) => <Icon {...props} icon={answer} />;
export const DownloadPdfIcon: React.FC<IconProps> = (props) => <Icon {...props} icon={downloadPdf} />;
export const ExportPlanIcon: React.FC<IconProps> = (props) => <Icon {...props} icon={exportPlan} />;
export const ExcelFileIcon: React.FC<IconProps> = (props) => <Icon {...props} icon={excelFile} />;
export const BlueCircleCalendarIcon: React.FC<IconProps> = (props) => <Icon {...props} icon={blueCircleCalendar} />;
export const ExploreIcon: React.FC<IconProps> = (props) => <Icon {...props} icon={Explore} />;
export const Chat1Icon: React.FC<IconProps> = (props) => <Icon {...props} icon={Chat1} />;
export const CommunityIcon: React.FC<IconProps> = (props) => <Icon {...props} icon={Community} />;
export const TasksListIcon: React.FC<IconProps> = (props) => <Icon {...props} icon={TasksList} />;
export const SetTimeDataIcon: React.FC<IconProps> = (props) => <Icon {...props} icon={SetTimeData} />;