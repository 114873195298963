import React from 'react';
import { getUserDetails } from '../../../../utils/orgName';

export const callActionFlag = (callData: any, userId: any) => {
  // checking either the logged member has cancelled or rejected the call
  let flag = 1;
  if (GoToCall(callData) === 'hide') {
    return 0;
  }
  if (callData?.callStatus === 'Canceled') {
    return 0;
  }
  const userRow = callData.participantsUser?.find((user: { mid: string }) => user.mid === userId);
  if (userRow?.callRejectStatus === true) {
    return 0;
  }
  return flag;
};

export const callEditableFlag = (callData: any, userId: any) => {
  let flag = 1;
  if (callData?.callBookedBy?.id !== userId) {
    return 0;
  }
  if (callActionFlag(callData, userId) === 0) {
    return 0;
  }
  const currentTimestamp = new Date().getTime();
  const currentUnixTime = Math.floor(currentTimestamp / 1000);
  const callStartTimestamp = new Date(callData.callDate + 'T' + callData.callTime + 'Z').getTime();
  const callStartUnixTime = Math.floor(callStartTimestamp / 1000);
  if (currentUnixTime + 300 > callStartUnixTime) {
    flag = 0;
  }
  return flag;
};

function GoToCall(callsData: any) {
  const callStartTimestamp = new Date(callsData.callDate + 'T' + callsData.callTime + 'Z').getTime();
  const callStartUnixTime = Math.floor(callStartTimestamp / 1000);
  const currentTimestamp = new Date().getTime();
  const currentUnixTime = Math.floor(currentTimestamp / 1000);
  const callEndUnixTime = callStartUnixTime + callsData.callTimeInterval * 60;
  if (currentUnixTime > callEndUnixTime) {
    return 'hide'; // hide 'Join' button when it exceeds call time
  } else {
    return 'show';
  }
}
