import React from 'react';

interface AudioPlayerProps {
  src: string; // Explicitly specify the type as string
}

const AudioPlayer: React.FC<AudioPlayerProps> = ({ src }) => {
  return (
    <div className="w-100">
      <audio controls preload="auto">
        <source src={src} type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
    </div>
  );
};

export default AudioPlayer;
