/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Box, Grid, Stack, Divider, Typography, IconButton } from '@mui/material';
import profilebgimg from '../../../assets/images/profilebgimg.png';
// import EditProfile from './EditProfile';
import { FormValues, initialValues } from '../tabs/ProfileLayout';
import _ from 'lodash';
import { countries } from '../../profile-page/countryData';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import ChatAvatarComponent from '../tabs/chat/ChatAvatarComponent';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import useProfileDetails from '../../../hooks/useProfileDetails';
import { popupActions } from '../../../redux/slices/onboardingPopupsSlice';
import RoleTypeTag from './RoleTypeTag';
import Upvotes from './Upvotes';
import useResponsiveScreenSwitch from '../../../utils/useResponsiveScreenSwitch';
import { EditIcon } from '../../../utils/Icons';

interface Props {
  formData: FormValues;
  setFormData: React.Dispatch<React.SetStateAction<FormValues>>;
}

const ProfileHeader = ({ formData, setFormData }: Props) => {
  const { forMobile } = useResponsiveScreenSwitch();

  const data = useAppSelector((state) => state.userProfile.data?.personal_details) || initialValues;

  const [open, setOpen] = React.useState(false);

  const { firstName, lastName, headshot, dob, upVote } = useProfileDetails();
  const dispatch = useAppDispatch();

  // const timezone = userProfile?.timezone || "";

  const handleClickOpen = () => {
    dispatch(popupActions.updatePopups({ profile: { open: true, disable: false } }));
  };

  const editProfileForm = () => {
    return null;
    // return <EditProfile data={data} open={open} setOpen={setOpen} disableClose={disableClose} />;
  };

  const getCountry = () => {
    const value = countries.find((each) => each.code === data.country);
    if (value) {
      return value.label;
    }
    return '-/-';
  };

  const formattedDob = dob ? moment(dob, 'MM-DD').format('MM/DD') : '-/-';

  const useStyles = makeStyles({
    profileHeaderImage: {
      backgroundImage: `url(${profilebgimg})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    }
  });

  const classes = useStyles();
  return (
    <>
      {data ? (
        <>
          <Box p={2} className={classes.profileHeaderImage}>
            <Box className="profilePageHead">
              <Box>
                <ChatAvatarComponent
                  height="95px"
                  width="95px"
                  firstLetter={firstName.slice(0, 1)}
                  image={data.headshot}
                  type="noStatus"
                />
              </Box>
              <Box className="userDetails">
                <Box className="NameTagEditIcon">
                  <Box className="NameTag">
                    <Typography variant="h1" noWrap lineHeight='normal'>
                      {firstName ? firstName : _.startCase(data.firstName)}
                      <span> </span>
                      {lastName ? lastName : _.startCase(data.lastName)}
                    </Typography>
                    {upVote > 0 ? <Upvotes likes={upVote} toolTip="true" /> : null}
                  </Box>
                  <IconButton size='small' onClick={handleClickOpen}>
                    <EditIcon />
                  </IconButton>
                </Box>
                <Stack
                  direction={{ xs: 'column', sm: 'row' }}
                  spacing={{ xs: 0.5, sm: 2 }}
                  divider={
                    data.phoneNumber && (
                      <Divider className="d-desktop" sx={{ color: '#FFF' }} orientation="vertical" flexItem />
                    )
                  }
                  alignItems={forMobile? ' ' : "center"}
                >
                  <Typography noWrap>{data.email}</Typography>
                  <Typography noWrap>
                    {data.phonePrefix} {data.phoneNumber}
                  </Typography>
                  <Typography fontWeight={600} fontSize="10px" color="#262738" noWrap className="d-desktop">
                    {data.country} ({data.timezone})
                  </Typography>
                </Stack>
                <Box className="countryTimezone">
                  <RoleTypeTag category={data?.category} />
                  <Stack direction="column" justifyContent="center" alignItems="flex-end" spacing={3}>
                    <Typography fontWeight={600} fontSize="10px" color="#262738" className="d-mobile">
                      {data.country} ({data.timezone})
                    </Typography>
                  </Stack>
                </Box>
              </Box>
            </Box>
          </Box>
          <Divider />
          <Box
            p={2}
            display={'flex'}
            alignItems={'flex-start'}
          >
            <Grid container spacing={1}>
              <Grid item xs={6} md={4} lg={2}>
                <Typography variant="body2" mb={1} noWrap>
                  Gender
                </Typography>
                <Typography variant="subtitle2" color="primary" noWrap>
                  {data && data.gender ? data.gender : '-/-'}
                </Typography>
              </Grid>
              <Grid item xs={6} md={4} lg={2}>
                <Typography variant="body2" mb={1} noWrap>
                  Birthday
                </Typography>
                <Typography variant="subtitle2" color="primary" noWrap>
                  {formattedDob}
                </Typography>
              </Grid>
              <Grid item xs={6} md={4} lg={2}>
                <Typography variant="body2" mb={1} noWrap>
                  Country
                </Typography>
                <Typography variant="subtitle2" color="primary" noWrap>
                  {getCountry()}
                </Typography>
              </Grid>
              <Grid item xs={6} md={4} lg={2}>
                <Typography variant="body2" mb={1} noWrap>
                  Ethnicity
                </Typography>
                <Typography variant="subtitle2" color="primary" noWrap>
                  {_.get(data, 'ethnicity', '')}
                </Typography>
              </Grid>
              <Grid item xs={6} md={4}>
                <Typography variant="body2" mb={1} noWrap>
                  First generation student
                </Typography>
                <Typography variant="subtitle2" color="primary">
                  {_.get(data, 'firstGenStudent', '') ? _.get(data, 'firstGenStudent', '') : '-/-'}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </>
      ) : null}

      {editProfileForm()}
    </>
  );
};

export default ProfileHeader;
