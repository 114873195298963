/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable no-constant-binary-expression */
/* eslint-disable no-undef */
import _ from 'lodash';
import { ChatClient } from '@azure/communication-chat';
import { AzureCommunicationTokenCredential } from '@azure/communication-common';
import React, { useEffect, useState } from 'react';
import { getUserDetails } from '../../../../../utils/orgName';
import ChatPerson from './ChatPerson';
import { useAppSelector } from '../../../../../redux/hooks';

interface IRenderChats {
  filteredDirectMessages: IThread[];
  formattedAcsOrgUsers: IFormattedAcsOrgUser[];
  threadValue: string;
  commId: string;
  filterParticipantsFromDirectThreads: IChatThread[];
  activeItemRef: React.MutableRefObject<any>;
  activeMessageId: any;
  loading?: boolean;
}

interface FormattedMessage {
  conversationId: string;
  messageId: string;
  displayName: string;
  content: string;
  // Add more message details if needed
}

const RenderChats: React.FC<IRenderChats> = ({
  filteredDirectMessages,
  formattedAcsOrgUsers,
  threadValue,
  commId,
  filterParticipantsFromDirectThreads,
  activeItemRef,
  activeMessageId,
  loading
}) => {
  const [userMessage, setUserMessage] = useState<any>([]);
  const personalThreadsArr = useAppSelector((state) => state.acsChannels.personalThreadsArr);

  const endpoint = process.env.REACT_APP_COMMUNICATION_SERVICES_ENDPOINT || '';
  const token = localStorage.getItem('acsToken') || '';
  const cred: any = token ? new AzureCommunicationTokenCredential(token) : null;
  const chatClient = token ? new ChatClient(endpoint, cred) : null;
  const selectedChatUser = useAppSelector((state) => state.selectedChatUser.data);

  // const retrieveMessages = async (personalThreadsArr: any) => {
  //   const allMessages: FormattedMessage[] = [];

  //   const fetchPromises = personalThreadsArr.map(async (conversation: any) => {
  //     const chatThreadClient = chatClient.getChatThreadClient(conversation.id);

  //     try {
  //       const iterator = chatThreadClient.listMessages().byPage({ maxPageSize: 1 });
  //       const page = await iterator.next();
  //       const message = page.value[0];

  //       if (message && message.type === 'text') {
  //         const formattedMessage: FormattedMessage = {
  //           conversationId: conversation.id,
  //           messageId: message.id,
  //           displayName: '',
  //           content: message.content?.message || ''
  //           // Add more message details if needed
  //         };
  //         allMessages.push(formattedMessage);
  //       }
  //     } catch (error) {
  //       console.error('Error retrieving messages:', error);
  //     }
  //   });

  //   await Promise.all(fetchPromises);

  //   return allMessages;
  // };

  // useEffect(() => {
  //   async function fetchMessages() {
  //     try {
  //       // Fetch messages and set them in state
  //       const fetchedMessages = await retrieveMessages(personalThreadsArr);
  //       setUserMessage((prevMessages: any) => [...prevMessages, ...fetchedMessages]);
  //     } catch (error) {
  //       console.error('Error retrieving messages:', error);
  //     }
  //   }

  //   fetchMessages();
  // }, [personalThreadsArr, userMessage]);

  const chatList = _.orderBy(
    filterParticipantsFromDirectThreads.filter((each) => !each.isPlatformAdmin),
    [(messages) => _.toLower(_.get(messages, 'displayName', ''))],
    ['asc']
  );

  return (
    <>
      {_.map(filteredDirectMessages, (each, idx) => {
        const userIndexToShow = selectedChatUser?.communicationId === each.displayNames[0] ? 1 : 0;

        const user = _.size(each.displayNames)
          ? _.get(formattedAcsOrgUsers, `${each.displayNames[userIndexToShow]}`, '')
          : '';

        const fullName = _.size(user)
          ? _.get(user, 'displayName', '')
          : getUserDetails()?.firstName + ' ' + getUserDetails()?.lastName;
        const userIdPath = `${each.displayNames[userIndexToShow]}.id.headshot`;
        const headshot = _.size(user) ? _.get(formattedAcsOrgUsers, userIdPath, '') : getUserDetails().picture;
        let message = '';
        if (each?.deleted) {
          message = `This message was deleted`;
        } else if (each.file) {
          message = 'Sent an attachment';
        } else {
          message = each?.lastmessage || '';
        }
        return (
          <div
            key={idx}
            ref={
              _.get(each, 'id', '') === threadValue || getUserDetails().communicationUserId === commId
                ? activeItemRef
                : null
            }
          >
            <ChatPerson
              type="personal-thread"
              lstMessageUserName=""
              activeThreadId={threadValue}
              activeCommId={commId}
              threadId={_.get(each, 'id', '')}
              image={headshot}
              lastMessageReceivedOn={_.get(each, 'lastMessageReceivedOn', '')}
              name={fullName}
              index={idx}
              lstMessage={message}
              unReadMessageCount={each?.msgCount}
              loading={loading}
              deleted={each.deleted}
            />
          </div>
        );
      })}
      {selectedChatUser.communicationId === '' && _.size(filterParticipantsFromDirectThreads)
        ? _.map(
            chatList,

            (each, idx) => {
              return (
                <ChatPerson
                  lstMessage=""
                  lstMessageUserName=""
                  key={idx}
                  activeThreadId={threadValue}
                  activeCommId={commId}
                  type="personal-user"
                  name={_.get(each, 'displayName', '') || ''}
                  image={_.get(each, 'id.headshot', '')}
                  lastMessageReceivedOn={_.get(each, 'lastMessageReceivedOn', '')}
                  communicationUserId={_.get(each, 'id.communicationUserId', '')}
                  index={idx}
                  unReadMessageCount={each?.msgCount}
                  loading={loading}
                />
              );
            }
          )
        : null}
    </>
  );
};

export default RenderChats;
