import { createSlice } from "@reduxjs/toolkit";

const aiChatSlice = createSlice({
    name: 'aiChatText',
    initialState: '',
    reducers: {
        setText: (state, action) => {
            return action.payload
        }
    }
})

export const { setText } = aiChatSlice.actions
export default aiChatSlice.reducer;














