import { industryArray } from '../pages/new-designs/components/common';

const industries = industryArray();
export const camelCaseToCapitalIndustries = (industriesArray: string[]) => {
  return industriesArray.map((tag: string) => {
    const index = industries.findIndex((each) => each.name === tag);
    if (index !== -1) {
      return industries[index].label;
    } else {
      return tag;
    }
  });
};

export const capitalToCamelCaseIndustries = (industriesArray: string[]) => {
  return industriesArray.map((tag: string) => {
    const index = industries.find((each) => each.label === tag);
    if (index) {
      return index.name;
    } else {
      return tag;
    }
  });
};
