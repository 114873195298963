import React from 'react';
import upvotes from '../../../assets/images/upvotes.svg';
import downvotes from '../../../assets/images/thumbsdownimg.svg';
import upvotesNotSelected from '../../../assets/images/upvotesNotSelected.svg';
import downvotesNotSelected from '../../../assets/images/downvotesNotSelected.svg';

interface UpvotesProps {
  likes?: number;
  icon?: string;
  voteValue?: number;
}

const VoteIcons: React.FC<UpvotesProps> = ({ likes, icon, voteValue }) => {
  const upIcon = voteValue === 1 ? upvotes : upvotesNotSelected;
  const downIcon = voteValue === 2 ? downvotes : downvotesNotSelected;

  return (
    <img alt='' src={icon === 'dislike' ? downIcon : upIcon} width="28" height="28" /> 
  );
};

export default VoteIcons;
