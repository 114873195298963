/* eslint-disable radix */
/* eslint-disable no-unreachable */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-restricted-globals */
import { useEffect, useState, useMemo } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import ZoomVideo from '@zoom/videosdk';
import 'antd/dist/antd.min.css';
import { toast } from 'react-toastify';
import { getExploreName } from './utils/platform';
import './index.css';
import ZoomVideoWindow from './ZoomVideoWindow';
import ZoomContext from './context/zoom-context';
import { devConfig } from './config/dev';
import { b64DecodeUnicode, generateVideoToken } from './utils/util';
import { useAppSelector } from '../../../../redux/hooks';
import { API } from '../../../../api';
import { getUserDetails } from '../../../../utils/orgName';
import AppLoader from '../../../../components/AppLoader';

const ZoomIndex = () => {
  const { topic, role, password, startTime, endTime } = useParams();
  const [loading, setLoading] = useState<boolean>(true);
  const [isParticipantEligible, setParticipantEligibility] = useState(false);
  const [checkTimeflag, setCheckTimeflag] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const userData = useAppSelector((state) => state.userProfile.data);
  const name = userData?.personal_details?.name;

  const meetingArgs = useMemo(() => {
    const devConfig = {
      sdkKey: process.env.REACT_APP_ZOOM_SDK_KEY,
      sdkSecret: process.env.REACT_APP_ZOOM_SDK_SECRET_KEY,
      webEndpoint: 'zoom.us',
      topic: '',
      name: `${getExploreName()}-${Math.floor(Math.random() * 1000)}`,
      password: '',
      signature: '',
      sessionKey: '',
      userIdentity: userData?.personal_details?.email,
      // role = 1 to join as host, 0 to join as attendee. The first member must join as host to start the session
      role: 1
    };

    const urlArgs = { topic, name, role, password, startTime, endTime };

    let meetingArgs: any = Object.fromEntries(new URLSearchParams(location.search));
    // Add enforceGalleryView to turn on the gallery view without SharedAddayBuffer
    if (!meetingArgs.sdkKey || !meetingArgs.topic || !meetingArgs.name || !meetingArgs.signature) {
      meetingArgs = { ...devConfig, ...meetingArgs, ...urlArgs };
      meetingArgs.enforceGalleryView = !window?.crossOriginIsolated;
    }
    if (meetingArgs.web) {
      if (meetingArgs.topic) {
        try {
          meetingArgs.topic = b64DecodeUnicode(meetingArgs.topic);
        } catch (e) { }
      } else {
        meetingArgs.topic = '';
      }

      if (meetingArgs.name) {
        try {
          meetingArgs.name = b64DecodeUnicode(meetingArgs.name);
        } catch (e) { }
      } else {
        meetingArgs.name = '';
      }

      if (meetingArgs.password) {
        try {
          meetingArgs.password = b64DecodeUnicode(meetingArgs.password);
        } catch (e) { }
      } else {
        meetingArgs.password = '';
      }

      if (meetingArgs.sessionKey) {
        try {
          meetingArgs.sessionKey = b64DecodeUnicode(meetingArgs.sessionKey);
        } catch (e) { }
      } else {
        meetingArgs.sessionKey = '';
      }

      if (meetingArgs.userIdentity) {
        try {
          meetingArgs.userIdentity = b64DecodeUnicode(meetingArgs.userIdentity);
        } catch (e) { }
      } else {
        meetingArgs.userIdentity = '';
      }

      if (meetingArgs.role) {
        meetingArgs.role = parseInt(meetingArgs.role, 10);
      } else {
        meetingArgs.role = 1;
      }
    }

    if (!meetingArgs?.cloud_recording_option) {
      meetingArgs.cloud_recording_option = '0';
    }
    if (!meetingArgs?.cloud_recording_election) {
      meetingArgs.cloud_recording_election = '';
    }

    if (meetingArgs?.telemetry_tracking_id) {
      try {
        meetingArgs.telemetry_tracking_id = b64DecodeUnicode(meetingArgs.telemetry_tracking_id);
      } catch (e) { }
    } else {
      meetingArgs.telemetry_tracking_id = '';
    }
    return meetingArgs;
  }, [topic, name, role, password, startTime, endTime]);

  const zmClient = ZoomVideo.createClient();

  useEffect(() => {
    const handleBeforeUnload = async (event: any) => {
      await zmClient.leave();
    };

    // Attach the event listener when the component mounts
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Detach the event listener when the component unmounts
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    if (!meetingArgs.signature && meetingArgs.sdkSecret && meetingArgs.topic) {
      meetingArgs.signature = generateVideoToken(
        meetingArgs.sdkKey,
        meetingArgs.sdkSecret,
        meetingArgs.topic,
        meetingArgs.password,
        meetingArgs.sessionKey,
        meetingArgs.userIdentity,
        parseInt(meetingArgs.role, 10),
        meetingArgs.cloud_recording_option,
        meetingArgs.cloud_recording_election,
        meetingArgs.telemetry_tracking_id
      );
    }
  }, [meetingArgs]);

  const checkCallParticipantUser = async (meetingId: string, user_id: string, orgId: string, location: string) => {
    try {
      const response = await API.checkCallParticipant(meetingId, user_id, orgId, location);
      if (response?.data?.message === 'You are eligible to Join Meeting') {
        setParticipantEligibility(true);
        setLoading(false);
      } else {
        setErrorMessage('unfortunately you’re not eligible to join this meeting');
        setLoading(false);
        setParticipantEligibility(false);
      }
    } catch (e) {
      setCheckTimeflag(true);
      setParticipantEligibility(false);
      setLoading(false);
    }
  };

  const checkCallParticipantExitOrNot = async () => {
    let meetingId = topic ?? '';
    let user_id = getUserDetails().userId;
    const location = getUserDetails().location;
    const userDetails = getUserDetails();
    const { orgId } = userDetails;
    const SECONDS_IN_MINUTE = 60;
    const MILLISECONDS_IN_SECOND = 1000;
    const DEFAULT_CALL_TIME_INTERVAL = 300;
    const DEFAULT_CALL_START_TIME = 10;
    const callStartUnixTime = Number(startTime) || DEFAULT_CALL_START_TIME;
    const callTimeInterval = parseInt(endTime ?? '') || DEFAULT_CALL_TIME_INTERVAL;
    const currentTimestamp = new Date().getTime();
    const currentUnixTime = Math.floor(currentTimestamp / MILLISECONDS_IN_SECOND);
    const callEndUnixTime = currentUnixTime + callTimeInterval * SECONDS_IN_MINUTE;

    if (currentUnixTime + 300 >= callStartUnixTime) {
      // If the current time is greater than or within 5 minutes of the call start time
      if (currentUnixTime > callEndUnixTime) {
        setErrorMessage('This meeting has already taken place');
        setParticipantEligibility(false);
        setLoading(false);
      } else {
        checkCallParticipantUser(meetingId, user_id, orgId, location);
        setParticipantEligibility(true);
        setLoading(false);
      }
    } else {
      // setErrorMessage('You can join from 5 minutes before the meeting');
      setParticipantEligibility(false);
      setLoading(false);
      setCheckTimeflag(true);
    }
  };

  useEffect(() => {
    checkCallParticipantExitOrNot();
  }, []);

  /** Loader design */
  const loaderDesign = <AppLoader />;

  /** Loading */
  if (loading) {
    return loaderDesign;
  }

  /** If participant not eligible */
  if (!isParticipantEligible) {
    if (!checkTimeflag) {
      toast.error(errorMessage);
    }
    return <Navigate to="/app/calendar" replace />;
  }

  // E

  return (
    <ZoomContext.Provider value={zmClient}>
      {zmClient && meetingArgs ? <ZoomVideoWindow meetingArgs={meetingArgs as any} /> : loaderDesign}
    </ZoomContext.Provider>
  );
};

export default ZoomIndex;
