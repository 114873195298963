import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API } from '../../api';

interface IGroup {
  enrolledCount: number;
  logo: string;
  name: string;
  grpId: string;
}

interface IGroupDataState {
  groups: IGroup[];
}

interface IInitialState {
  groupData: IGroupDataState;
}

const initialState: IInitialState = {
  groupData: {
    groups: [],
  },
};

export const fetchAllGroupsList: any = createAsyncThunk(
  'groupData/fetchAllGroupsList', 
  async ({ orgId, groupId }: { orgId: string; groupId: string }) => {
    try {
      const response = await API.fetchAllGroupsList(orgId, groupId);
      return response.data.groups || [];
    } catch (error) {
      throw error;
    }
  }
);

const groupDataSlice = createSlice({
  name: 'groupData',
  initialState: initialState.groupData,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAllGroupsList.fulfilled, (state, action) => {
      state.groups = action.payload;
    });
  },
});

export default groupDataSlice.reducer;
