import React from 'react';
import { Box } from '@mui/material';
import MySkeleton from '../components/Skeleton';
import WorkAndEducationLoader from './WorkAndEducationLoader';

interface ProfileDetailsLoaderProps {
  chatbtn: boolean;
  callBookingBtn: boolean;
}

const ProfileDetailsLoader: React.FC<ProfileDetailsLoaderProps> = ({ chatbtn, callBookingBtn }) => {
  return (
    <Box height="100%" className="chatProfileBox">
      <Box p={2}>
        <MySkeleton width={150}/>
      </Box>
      <MySkeleton height={2} /> 
      <Box height="calc(100% - 65px)" p={2} overflow="auto">
        <Box display="flex" gap={0.5} pb={2}>
          <MySkeleton variant='rounded' height={48} width={48} />
          <Box alignItems='center'>
            <Box display="flex" gap={7.5}>
              <MySkeleton variant='text' width={100} />
              <MySkeleton variant='rounded' height={20} width={50} />
            </Box>
            <MySkeleton variant='text' width={150} />
            <MySkeleton variant='rounded' height={25} width={80} className='mt-10' />
            <Box display='flex' gap={1} pt={1}>
              {[...Array(3).keys()].map((_, index) => (
                <MySkeleton key={index} variant='rounded' height={30} width={30} />   
              ))} 
            </Box>  
          </Box>
        </Box>
        <MySkeleton height={2}/> 
        {((chatbtn && callBookingBtn) || chatbtn || callBookingBtn) &&  
          <Box pt={2}>
            {[...Array((chatbtn && callBookingBtn) ? 2 : 1)].map((_, index) => (
              <Box pb={1} key={index}>
                <MySkeleton variant='rounded' height={30} width='100%' />   
              </Box>
            ))}
          </Box>
        }
        <Box py={2}>
          <MySkeleton width={150}/>
          <Box display='flex' gap={1} pt={2}>
            {[...Array(3).keys()].map((_, index) => (
              <MySkeleton key={index} variant='rounded' height={30} width={100} />   
            ))} 
          </Box>
        </Box>
        <WorkAndEducationLoader/>
        <WorkAndEducationLoader/>
      </Box> 
    </Box>
  );
};

export default ProfileDetailsLoader;
