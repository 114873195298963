/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable prettier/prettier */
import _ from 'lodash';
import React from 'react';
import { useLogout } from '../hooks';
import { useAuth0 } from '@auth0/auth0-react';
import { Box, Typography, Button } from '@mui/material';
import connection from '../assets/images/connection-problem.svg';

interface IUnAuthorized {
  http: number;
}

const UnAuthorized: React.FC<IUnAuthorized> = ({ http }) => {
  const { loginWithRedirect, isAuthenticated } = useAuth0();
  const { doLogout } = useLogout();
  const exceptionTitle = {
    402: 'UnAuthorized',
    404: 'Not Found'
  };

  const exceptionSubTitle = {
    402: 'You are not authorized to access this page',
    404: 'This page is not found'
  };

  function handleRedirect() {
    // isAuthenticated ? doLogout() : loginWithRedirect();
    doLogout();
    // window.location.href = '/';
  }

  return (
    <Box display="flex" justifyContent="center" alignItems={{ xs: 'top', sm: 'center' }} height="100vh">
      <Box width="480px" border={{ xs: 'none', sm: '1px solid #e7e3e3' }} borderRadius="8px" bgcolor={'#FFFFFF'}>
        <Box
          mx={{ xs: 3, sm: 10 }}
          my={6}
          display="flex"
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
        >
          <img src={connection} alt="error" height="101px" width="86px" />
          <Typography variant="h3" pt={3}>
            {_.get(exceptionTitle, [http])}
          </Typography>
          <Typography fontSize="16px" pt={1} pb={6}>
            {_.get(exceptionSubTitle, [http])}
          </Typography>
          <Button color="primary" variant="contained" fullWidth sx={{ height: '50px' }} onClick={handleRedirect}>
            Back to Dosen
          </Button>
          <Typography fontSize="16px" my={1}>
            or
          </Typography>
          <Typography fontSize="16px">Contact us at support@dosen.io</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default UnAuthorized;
