/* eslint-disable max-nested-callbacks */
/* eslint-disable react-hooks/exhaustive-deps */
/**
 * AppRoutes
 * @author Chavan Dinesh <chavan.dinesh@ahex.co.in>
 */
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import React, { Suspense, useCallback, useEffect, useRef, useState } from 'react';
import ProtectedRoute from './ProtectedRoutes';
import { useAuth0 } from '@auth0/auth0-react';
import AppLoader from '../components/AppLoader';
import ZoomIndex from '../pages/new-designs/tabs/zoom/ZoomIndex';
import { LoginElement, OrganizationsElement } from './lazyElements';
import Exception from '../components/Exception';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { API } from '../api';
import { getUserDetails } from '../utils/orgName';
import { acsChannelActions } from '../redux/slices/acs-channels/acsChannels';
import Layout from '../pages/new-designs/layout/Layout';
import HomeTab from '../pages/new-designs/tabs/HomeTab';
import Calendar from '../pages/new-designs/tabs/calendar/Calendar';
import CommunityTab from '../pages/new-designs/tabs/Community';
import ChatTab from '../pages/new-designs/tabs/chat/Chat';
import { acsTokenActions, fetchAcsToken } from '../redux/slices/acs-token/acsTokenSlice';
import _ from 'lodash';
import ProfileLayout from '../pages/new-designs/tabs/ProfileLayout';
import CommunityMember from '../pages/new-designs/tabs/CommunityMember';
import ProgramDetails from '../pages/new-designs/tabs/Program/components/program tabs/ProgramDetails';
import ProgramOverview from '../pages/new-designs/tabs/Program/components/program tabs/ProgramOverview';
import ProgramUsers from '../pages/new-designs/tabs/Program/components/program tabs/ProgramUsers';
import ProgramSettings from '../pages/new-designs/tabs/Program/components/program tabs/ProgramSettings';
import ProgramInsights from '../pages/new-designs/tabs/Program/components/program tabs/ProgramInsights';
import LibraryTab from '../pages/new-designs/tabs/Library/LibraryTab';
import LibraryIndustries from '../pages/new-designs/tabs/LibraryIndustries';
import ROLES, { rolesArray } from '../utils/roles';
import Mentee from '../pages/new-designs/tabs/home/Mentee';
import Mentor from '../pages/new-designs/tabs/home/Mentor';
import MatchesMainPage from '../pages/new-designs/tabs/Matches/MatchesMainPage';
import ProfileSettings from '../pages/new-designs/tabs/profile/ProfileSettings';
import OrganizationSettings from '../pages/new-designs/tabs/profile/OrganizationSettings';
/** Assessment Questions added by Raj */
import AssessmentQuestions from '../pages/new-designs/tabs/profile/AssessmentQuestions';
import Profile from '../pages/new-designs/tabs/profile/Profile';
import PasswordAndSecurity from '../pages/new-designs/tabs/profile/PasswordAndSecurity';
import SecuredRoutes from './SecuredRoutes';
import Error from '../components/ErrorPage';
import {
  communityUsersActions,
  fetchCommunityMembers,
  modifyCommunity
} from '../redux/slices/acs-community-members/acsCommunityMembers';
import { ChatClient } from '@azure/communication-chat';
import { AzureCommunicationTokenCredential } from '@azure/communication-common';
// import Subsession from "../pages/new-designs/tabs/zoom/feature/subsession/subsession";
import { acsMessageNotificationActions } from '../redux/slices/acs-message-notifications/acsMessageNotificationsSlice';
import Knowledge from '../pages/new-designs/tabs/Knowledge';
import Redirect from './Redirect';
import RoleRedirect from './RoleRedirect';
import useProfileDetails from '../hooks/useProfileDetails';
import TemplePage from '../constants/TemplePage';
import { store } from '../redux/store';
import MenteeTask from '../pages/new-designs/tabs/home/MenteeTask';
/** OnboardingProcessNew Extra page by Raj  */
import OnboardingProcessNew from '../pages/new-designs/tabs/profile/OnboardingProcessNew';

import HelpTab from '../pages/new-designs/tabs/Help/HelpTab';
import { useLogout } from '../hooks';
import moment from 'moment';
import { fetchOrgLevelGroup } from '../redux/slices/org-level-group/orgLevelGroupSlice';
import { fetchUserProfile } from '../redux/slices/user-profile/userProfieSlice';
import { fetchOrgDetails } from '../redux/slices/orgDetails/orgDetailsSlice';
import { popupCountActions } from '../redux/slices/poupsCountsSlice';
import jwt_decode from 'jwt-decode';
/** Account deactivated page By Raj */
import DeactivatedUser from '../pages/new-designs/tabs/DeactivatedUser';
import { fetchUserGroupList } from '../redux/slices/groupRolesListSlice';
import { fetchMentorTasks } from '../redux/slices/mentorTasksSlice';
import ConnectionProblem from '../components/ConnectionProblem';
import PlannedMaintenance from '../components/PlannedMaintenance';
import NotFoundv2 from '../components/NotFoundv2';
import ChatBot from '../pages/new-designs/tabs/livechat/ChatBot';
import AskDosen from '../pages/new-designs/tabs/livechat/AskDosen';
import LiveChat from '../pages/new-designs/tabs/livechat/LiveChat';
import AIChatThanksPage from '../pages/new-designs/tabs/livechat/ThanksPage';
import { AGILE_ORG } from '../utils/constants';
import { fetchUserEducation } from '../redux/slices/user-education/userEducationSlice';
import { fetchUserWorkHistory } from '../redux/slices/user-work/userWorkHistorySlice';
import DirectLiveChat from '../pages/new-designs/tabs/livechat/DirectLiveChat';
import { ChatBotHistory } from '../pages/new-designs/tabs/livechat/ChatBotHistory';
import { NewChatBot } from '../pages/new-designs/tabs/livechat/NewChatBot';
import { ChatBotHistoryComponent } from '../pages/new-designs/tabs/livechat/ChatBotHistoryComponent';
import PromptCards from '../pages/new-designs/tabs/livechat/PrompCards';
import Livechat from '../pages/new-designs/tabs/livechat/LiveChat';
import NewChat from '../pages/new-designs/tabs/livechat/NewChat';
import PromptChat from '../pages/new-designs/tabs/livechat/PromptChat';
import { toast } from 'react-toastify';
import { fetchChatUsers } from '../redux/slices/getChatUsersSlice';
import { selectedChatUserActions } from '../redux/slices/selectedChatUserSlice';
import { fetchAllArticlesUser } from '../redux/slices/article/getAllArticleUserSlice';
import { fetchAllTasks } from '../redux/slices/goals-tasks/getTasksSlice';
import AiChatMain from '../pages/new-designs/tabs/livechat/AiChatMain';
import useCallDetails from '../queries/useCallDetails';
import { fetchCommunityMember } from '../redux/slices/communityMembers/communityMembersSlice';
import { fetchAllGroupsList } from '../redux/slices/getAllGroupsListSlice';
import LogoutComponent from '../components/LogoutComponent';
import { checkSession } from '../utils/checkSeesion';
import useGroupSettings from '../hooks/useGroupSettigs';
import MobileAppLinkPage from './MobileAppLinkPage';
import { fetchCallsCount } from '../redux/slices/callCountSlice';
import { companyActions } from '../redux/slices/companySlice';

/**
 * AdminRoutes for Admins (Platform or Organization)
 * @returns jsx
 */
const NewPlatformAdminRoutes = (
  <Route path="/app" element={<ProtectedRoute permissions={[ROLES.platAdmin]} />}>
    <Route element={<SecuredRoutes />}>
      <Route path="" element={<Layout />}>
        <Route path="organizations" element={<OrganizationsElement />} />
      </Route>
    </Route>
  </Route>
);

/**
 * UserRoutes for normal member (Other than admin)
 * @returns jsx
 */

/**
 * NewDesign Routes
 */

/**
 * @function @name AppRoutes
 * @description It contains all the routes used in the application
 * @returns {React.ReactElement}
 */
const AppRoutes: React.FC = () => {
  const { isLoading, isAuthenticated, getAccessTokenSilently, getIdTokenClaims, user } = useAuth0();
  const dispatch = useAppDispatch();
  const urlLocation = useLocation();
  const urlParams = new URLSearchParams(urlLocation.search);
  const isPromptLogin = urlParams.get('prompt') || '';
  const promptOrg = urlParams.get('org') || '';
  const { admins, mentee, mentors, ftue } = useProfileDetails();
  const { orgName } = getUserDetails();
  const selectedChatUser = useAppSelector((state) => state.selectedChatUser.data);
  const queryClient = useQueryClient();
  const acsToken = useAppSelector((state) => state.acsToken?.data?.token);
  const acsTokenLoading = useAppSelector((state) => state.acsToken.loading);
  const role = localStorage.getItem('role') || '';
  const [roleLoading, setRoleLoading] = useState(false);
  const loginUser = useAppSelector((state) => state.userProfile.data?.personal_details);
  const userProfile = useAppSelector((state) => state.userProfile);
  const orgDetails = useAppSelector((state) => state.orgDetails);
  const selectedGroupId = useAppSelector((state) => state.selectedGroup.data);
  const groupId: any = selectedGroupId.id || '';
  const selectedGroup = useAppSelector((state) => state.selectedGroup.data);
  const allArticleData = useAppSelector((state) => state.allArticleUser?.data?.ArticleList) ?? [];
  const groupSettings = useGroupSettings();
  const connection = userProfile.data?.connection;

  // dynamic redirection based on group based roles
  const getHomeTab = () => {
    const role = localStorage.getItem('role') || '';
    if (role === ROLES.platAdmin) {
      return null;
    }
    // if (
    //   (role === ROLES.member || role === ROLES.grpAdmin) &&
    //   admins.length > 0 &&
    //   mentee.length === 0 &&
    //   mentors.length === 0
    // ) {
    //   return null;
    // }

    return (
      <Route path="home" element={<HomeTab />}>
        <Route element={<SecuredRoutes />}>
          {orgName !== AGILE_ORG && (
            <Route
              path="mentee"
              element={
                mentee?.length > 0 ? (
                  selectedGroup.active ? (
                    <Mentee />
                  ) : (
                    <DeactivatedUser />
                  )
                ) : (
                  <Navigate to="/app/home" />
                )
              }
            />
          )}
          <Route
            path="mentor"
            element={
              mentors?.length > 0 ? (
                selectedGroup.active ? (
                  <Mentor />
                ) : (
                  <DeactivatedUser />
                )
              ) : (
                <Navigate to="/app/home" />
              )
            }
          />
        </Route>
      </Route>
    );
  };
  const tokens = localStorage.getItem('tokens');
  const { exp, userId } = getUserDetails();
  const currentTime = moment();
  const expiryTime = moment.unix(exp);

  const getChannels = async () => {
    // if (acsToken && role !== ROLES.platAdmin) {
    const tokens = localStorage.getItem('tokens');
    const isLoggedIn = localStorage.getItem('isAuthenticated');
    if (role && tokens && !currentTime.isAfter(expiryTime) && acsToken && isAuthenticated && isLoggedIn) {
      dispatch(
        fetchCommunityMembers({
          orgId: getUserDetails().orgId,
          location: getUserDetails().location
        })
      );
    }
  };

  const groupRoleList = useAppSelector((state) => state.groupRoleList);
  const { doLogout } = useLogout();

  const fetchProgressChartValue = async (orgId: string, grpId: string, loc: string) => {
    // setProgramRoiLoading(true);
    try {
      const data = { orgId, grpId, loc };
      const response = await API.getGroupsOverview(data); // Replace with your API call
    } catch (e) {
      //
    } finally {
      // setProgramRoiLoading(false);
    }
  };

  /**
   * Preload queries Section
   */
  // useEffect(() => {

  //   if (acsToken && selectedChatUser.communicationId === '') {
  //     getChannels();
  //     console.log('getChannels');

  //     dispatch(
  //       fetchCommunityMembers({
  //         orgId: getUserDetails().orgId,
  //         location: getUserDetails().location
  //       })
  //     );

  //     dispatch(
  //       fetchAllArticlesUser({ orgId: getUserDetails().orgId, type: [], tags: [], industries: [], helpSettings: false })
  //     );

  //     // getChatThread();

  //     if (allArticleData[0]?.articleId) {
  //       fetchArticle();
  //     }

  //   }
  // }, [acsToken]);

  // const userCallsDataRefetch = () => {
  //   const userCallsData = useCallDetails(1, 10);
  //   userCallsData.refetch();
  // };
  // userCallsDataRefetch();
  const { data, error, refetch, allTotelCount } = useCallDetails(1, 10);
  const isLoggedIn = localStorage.getItem('isAuthenticated');

  useEffect(() => {
    const delay = 5000; // 5 seconds in milliseconds
    const timerId = setTimeout(() => {
      if (acsToken && selectedChatUser.communicationId === '') {
        getChannels();
      }

      if (isLoggedIn) {
        refetch();
        dispatch(fetchCommunityMember({ orgId: getUserDetails().orgId, location: getUserDetails().location }));

        dispatch(
          fetchCommunityMembers({
            orgId: getUserDetails().orgId,
            location: getUserDetails().location
          })
        );

        if (window.performance) {
          const navigationEntries = performance.getEntriesByType('navigation');
          if (navigationEntries.length > 0) {
            const navigationEntry = navigationEntries[0] as PerformanceNavigationTiming;
            if (navigationEntry.type === 'reload') {
              // Handle the page reload scenario
            } else {
              if (role !== ROLES.platAdmin) {
                dispatch(
                  fetchAllArticlesUser({
                    orgId: getUserDetails().orgId,
                    type: [],
                    tags: [],
                    industries: [],
                    helpSettings: false,
                    useCache: true
                  })
                );
                dispatch(
                  fetchAllArticlesUser({
                    orgId: getUserDetails().orgId,
                    type: [],
                    tags: [],
                    industries: [],
                    helpSettings: true,
                    useCache: true
                  })
                );
              }

              // Handle normal app navigation scenario
            }
          }
        }

        // getChatThread();
        if (selectedGroupId?.role === 'admin') {
          fetchProgressChartValue(getUserDetails().orgId, groupId, getUserDetails().location);
        }
      }
    }, delay);
    // Cleanup function to clear the timeout in case the component is unmounted before the timeout completes
    return () => clearTimeout(timerId);
  }, [isLoggedIn]);

  const {
    isError,
    error: chatThreadError,
    data: threadData,
    isSuccess
  } = useQuery({
    queryKey: ['getAcsChatThreads'],
    queryFn: async () => {
      const token = window.localStorage.getItem('acsToken');
      const { orgId } = getUserDetails();
      const tag = selectedChatUser.communicationId ? '#admin' : '#self';
      const communicationId = selectedChatUser.communicationId
        ? selectedChatUser.communicationId
        : getUserDetails().communicationUserId;

      const [personalThreadsArr, groupThreads] = await Promise.all([
        API.getPersonalThreads({ orgId, acsToken: acsToken || token || '', tag, communicationId }),
        API.getGroupThreads({ orgId, acsToken: acsToken || token || '', tag, communicationId })
      ]);

      return { personalThreadsArr: personalThreadsArr.data, groupThreads: groupThreads.data };
    },
    retry: 0,

    enabled: false
  });

  useEffect(() => {
    if (isSuccess) {
      dispatch(acsChannelActions.atnSaveChannels(threadData));
    }
  }, [isSuccess, threadData]);

  useEffect(() => {
    if (isError) {
      if (!acsTokenLoading) {
        if (getUserDetails().communicationUserId) {
          dispatch(
            fetchAcsToken({
              communicationId: getUserDetails().communicationUserId,
              tag: selectedChatUser.communicationId ? '#admin' : '#self'
            })
          );
        }
        if (_.get(chatThreadError, 'response.status') === 401) {
          dispatch(
            fetchAcsToken({
              communicationId: getUserDetails().communicationUserId,
              tag: selectedChatUser.communicationId ? '#admin' : '#self'
            })
          );
        }
      }
    }
  }, [isError]);

  /**
   * End of preload queries
   */

  useEffect(() => {
    if (!checkSession() && acsToken) {
      queryClient.fetchQuery({ queryKey: ['getAcsChatThreads'] });
    }
  }, [acsToken]);

  // **** the tab is not open or the opened tab is not doing anything
  const lastInteractionTimeRef = useRef<number>(Date.now());
  const intervalRef = useRef<number | null>(null);
  const timeLimit = 20 * 60 * 1000;
  const intervalDuration = 5 * 10 * 1000;
  // if (document.hidden) {
  //   lastInteractionTimeRef.current = Date.now();
  // }

  // const handleVisibilityChange = () => {
  //   if (document.hidden) {
  //     const inactiveDuration = Date.now() - lastInteractionTimeRef.current;
  //     if (inactiveDuration >= timeLimit) {
  //       window.location.reload();
  //       console.log('away from tab for ' + timeLimit + ' seconds or more');
  //     } else {
  //       console.log('User just moved to other tab within ' + timeLimit + ' seconds');
  //     }
  //   }
  // };

  const handleInteraction = () => {
    lastInteractionTimeRef.current = Date.now();
  };

  useEffect(() => {
    // document.addEventListener('visibilitychange', handleVisibilityChange);
    window.addEventListener('mousemove', handleInteraction);
    window.addEventListener('keydown', handleInteraction);
    intervalRef.current = window.setInterval(() => {
      const inactiveDuration = Date.now() - lastInteractionTimeRef.current;
      if (inactiveDuration >= timeLimit && !location.pathname.includes('video-call')) {
        window.location.reload();
      }
    }, intervalDuration);

    return () => {
      // document.removeEventListener('visibilitychange', handleVisibilityChange);
      window.removeEventListener('mousemove', handleInteraction);
      window.removeEventListener('keydown', handleInteraction);

      if (intervalRef.current !== null) {
        window.clearInterval(intervalRef.current);
      }
    };
  }, []);

  const chatClientRef = useRef<ChatClient | null>(null);

  chatClientRef.current = acsToken
    ? new ChatClient(
        process.env.REACT_APP_COMMUNICATION_SERVICES_ENDPOINT || '',
        new AzureCommunicationTokenCredential(acsToken)
      )
    : null;

  const handleMessageEdited = async (e: any) => {
    const senderCommunicationId = _.get(e, 'sender.communicationUserId');
    if (store.getState().userProfile.data?.personal_details?.communicationId !== senderCommunicationId) {
      const queryData: any = queryClient.getQueryData(['recieveMessages', e.threadId]);
      if (queryData) {
        const message = queryData.pages[0].value.find((each: any) => each.id === e.id);
        if (message) {
          queryClient.setQueryData(['recieveMessages', e.threadId], (prevData: any) => {
            const value = prevData.pages[0].value;
            const newValues = value.map((each: any) => {
              if (each.id === e.id) {
                return {
                  ...each,
                  editedOn: new Date(e.editedOn),
                  content: { message: e.message },
                  metadata: e.metadata
                };
              }
              return each;
            });
            dispatch(acsChannelActions.updateLastMessageEdited(e));
            return { ...prevData, pages: [{ value: newValues }] };
          });
        }
      }
    }
  };
  const handleMessageReceived = async (e: any) => {
    const senderCommunicationId = _.get(e, 'sender.communicationUserId');
    const result = senderCommunicationId === store.getState().userProfile.data?.personal_details?.communicationId;
    if (!result) {
      const queryData = queryClient.getQueryData(['recieveMessages', e.threadId]);
      if (queryData) {
        queryClient.setQueryData(['recieveMessages', e.threadId], (prevData: any) => {
          const value = prevData.pages[0].value;
          const sequenceId = String(Number(value[0].sequenceId) + 1);
          const { id, type, version, metadata, createdOn, senderDisplayName } = e;
          const senderCommunicationIdentifier = {
            rawId: e.sender.communicationUserId,
            communicationUser: e.sender.communicationUserId
          };
          const newValue = [
            {
              id,
              type: type.toLowerCase(),
              sequenceId,
              version,
              metadata,
              createdOn,
              senderDisplayName,
              content: { message: e.message },
              senderCommunicationIdentifier
            },
            ...value
          ];
          // updates notification count and last message
          dispatch(acsChannelActions.updateLastPersonalMessage(e));
          return { ...prevData, pages: [{ value: newValue }] };
        });
      } else {
        // updates notification count and last message
        dispatch(acsChannelActions.updateLastPersonalMessage(e));
      }
    }
  };
  const handleThreadCreated = async (e: any) => {
    if (e.createdBy.id.communicationUserId !== store.getState().userProfile.data?.personal_details?.communicationId) {
      try {
        const response = await API.getCommunityGroupsUsersListNewDuplicate({
          orgId: getUserDetails().orgId,
          location: getUserDetails().location
        });
        if (response.status === 200 && response.statusText === 'OK') {
          const data = modifyCommunity(response);
          dispatch(communityUsersActions.updateData(data));
        }
      } catch (e) {
        toast.error(_.get(e, 'response.data.message', `Couldn't fetch community users`));
      }

      await queryClient.fetchQuery({ queryKey: ['getAcsChatThreads'] }).then((res) => {});
    }
  };

  const handleThreadPropertiesUpdate = async (e: any) => {
    queryClient.fetchQuery({ queryKey: ['recieveMessages', e.threadId] });
  };
  const handleMessageDeleted = async (e: any) => {
    if (e?.sender?.communicationUserId !== getUserDetails().communicationUserId) {
      queryClient.fetchQuery({
        queryKey: ['recieveMessages', e.threadId]
      });
    }
  };
  const handleParticipantsRemoved = async (e: any) => {
    queryClient.fetchQuery({
      queryKey: ['recieveMessages', e.threadId]
    });
  };

  const chatClient = chatClientRef.current;

  useEffect(() => {
    if (acsToken) {
      chatClient?.startRealtimeNotifications();
      chatClient?.on('chatMessageEdited', handleMessageEdited);
      chatClient?.on('chatMessageReceived', handleMessageReceived);
      chatClient?.on('chatThreadCreated', handleThreadCreated);
      chatClient?.on('participantsRemoved', handleParticipantsRemoved);
      chatClient?.on('chatThreadPropertiesUpdated', handleThreadPropertiesUpdate);
      chatClient?.on('chatMessageDeleted', handleMessageDeleted);
    }
    return () => {
      chatClient?.off('chatMessageEdited', handleMessageEdited);
      chatClient?.off('chatMessageReceived', handleMessageReceived);
      chatClient?.off('chatThreadCreated', handleThreadCreated);
      chatClient?.off('participantsRemoved', handleParticipantsRemoved);
      chatClient?.off('chatThreadPropertiesUpdated', handleThreadPropertiesUpdate);
      chatClient?.off('chatMessageDeleted', handleMessageDeleted);
    };
  }, [acsToken]);

  const allowedAdmins = [ROLES.orgAdmin, ROLES.platAdmin];

  const navigate = useNavigate();
  const [settingsLoader, setSettingsLoader] = useState(false);
  const companyFlag = useAppSelector((state) => state.company.flag);

  const getToken = async () => {
    setRoleLoading(true);
    const token = await getAccessTokenSilently();
    const claims = await getIdTokenClaims();
    const id_token = claims?.__raw;
    localStorage.setItem('tokens', JSON.stringify({ access_token: token, id_token }));
    const data: any = jwt_decode(token);
    const idData = jwt_decode(id_token || '');
    const getRole = data['https://dosen.io/role'];
    const orgDetails = data['https://dosen.io/organization'];
    const firstName = _.get(idData, 'user_metadata.firstName', '');
    const lastName = _.get(idData, 'user_metadata.lastName', '');
    const fullName = `${firstName} ${lastName}`.trim();
    // const ftue = _.get(idData, 'user_metadata.ftue', '');
    localStorage.setItem('username', fullName);

    const roleMappings: Record<number, string> = {
      1: ROLES.member,
      2: ROLES.orgAdmin,
      3: ROLES.platAdmin,
      4: ROLES.grpAdmin
    };

    // console.log('test')
    let role = roleMappings[getRole?.role];
    role !== ROLES.platAdmin ? localStorage.setItem('orgLocation', _.get(orgDetails, 'metadata.location', '')) : null;
    await localStorage.setItem('role', role);
    setRoleLoading(false);
    // console.log('role',role);
    try {
      setSettingsLoader(true);
      const response = await API.userBasicSettings({ userId: _.get(idData, 'sub', '') });
      if (response.status === 200 && response.statusText === 'OK') {
        const { orgId, location } = getUserDetails();
        if (role && rolesArray.includes(role)) {
          localStorage.setItem('isAuthenticated', JSON.stringify(true));
          dispatch(fetchUserProfile()).then((res: any) => {
            setSettingsLoader(false);
            const getMatches = async () => {
              try {
                const response = await API.bulkMatches({ orgId, location });
              } catch (e) {}
            };
            // if (role !== ROLES.platAdmin) {
            //   getMatches();
            // }
            // console.log('dv0', res?.payload);
            if (allowedAdmins.includes(role) || (res?.payload && res?.payload?.data?.admins.length > 0)) {
              dispatch(fetchChatUsers(''));
            }
            if (ftue && userId && role !== ROLES.platAdmin) {
              dispatch(fetchUserEducation(userId));
              dispatch(fetchUserWorkHistory(userId));
            }
            if (res?.payload && res?.payload?.data?.mentors.length > 0) {
              const ftue = res?.payload?.data?.personal_details?.ftue;
              if (ftue || (groupId && selectedGroupId?.role === 'mentor')) {
                // console.log('selectedGroupId',selectedGroupId,'ftue',ftue,'groupId',groupId)
                dispatch(fetchMentorTasks({ groupId }));
              }
            }
          });

          // dispatch(
          //   fetchCommunityMembers({
          //     orgId: getUserDetails().orgId,
          //     location: getUserDetails().location
          //   })
          // );
          if (selectedChatUser.communicationId === '') {
            dispatch(
              fetchAcsToken({
                communicationId: getUserDetails().communicationUserId,
                tag: selectedChatUser.communicationId ? '#admin' : '#self'
              })
            );
          }

          if (role === ROLES.orgAdmin) {
            dispatch(fetchOrgLevelGroup(_.get(idData, 'user_metadata.coId', '')));
          }
          if (role !== ROLES.platAdmin) {
            dispatch(fetchUserGroupList());
            // console.log('dispatched grupList')
            dispatch(fetchOrgDetails(_.get(orgDetails, 'id', '')));
          }
          dispatch(fetchCallsCount(location));
        }
      }
    } catch (e) {
      setSettingsLoader(false);
      navigate('/errorpage', { state: { message: _.get(e, 'response.data.message', 'User basic settings failed') } });
    }
  };

  const updateCompanyList = async () => {
    const company = localStorage.getItem('company');
    if (company) {
      const { name, location } = JSON.parse(company);
      try {
        const response = await API.addCompany({ name, location });
        if (response.status === 200 && response.statusText === 'OK') {
          dispatch(companyActions.update(false));
          localStorage.removeItem('company');
        }
      } catch (e) {
        toast.error(_.get(e, 'response.data.message', `Couldn't add company`));
      }
    }
  };

  useEffect(() => {
    if (companyFlag) {
      updateCompanyList();
    }
  }, [companyFlag]);
  // useEffect(()=>{
  //   dispatch(fetchUserGroupList());

  // },[groupId])

  const location = useLocation();
  const { orgId } = getUserDetails();
  const currentLocation = useLocation();
  useEffect(() => {
    if (
      !checkSession() &&
      !orgDetails.loading &&
      (role === ROLES.orgAdmin || role === ROLES.platAdmin) &&
      currentLocation.pathname.includes('/app/programdetails')
    ) {
      const paOrgId = localStorage.getItem('orgId') || '';
      dispatch(fetchOrgDetails(role === ROLES.platAdmin ? paOrgId : orgId));
    }
  }, []);

  useEffect(() => {
    const clearAll = () => {
      if (selectedChatUser.communicationId !== '') {
        localStorage.removeItem('user');
        localStorage.removeItem('chatAccessed');
        localStorage.removeItem('acsToken');
        dispatch(acsTokenActions.updateAcsToken(0));
        dispatch(
          fetchAcsToken({
            communicationId: getUserDetails().communicationUserId,
            tag: selectedChatUser.communicationId ? '#admin' : '#self'
          })
        ).then(() => {
          queryClient.fetchQuery({ queryKey: ['getAcsChatThreads'] });
        });
        dispatch(communityUsersActions.update(0));
        // dispatch(
        //   fetchCommunityMembers({
        //     orgId: getUserDetails().orgId,
        //     location: getUserDetails().location
        //   })
        // );
        dispatch(
          acsChannelActions.atnSaveChannels2({
            groupThreads: [],
            personalThreadsArr: []
          })
        );
        dispatch(
          selectedChatUserActions.update({
            communicationId: '',
            userId: '',
            name: ''
          })
        );
      }
    };
    if (!location.pathname.includes('chat')) {
      clearAll();
    }
    // };
  }, [location]);

  useEffect(() => {
    dispatch(popupCountActions.reset(0));

    const keysToRemove = [
      'education',
      'work',
      'profileData',
      'hobbies',
      'social',
      'disability',
      'digitalIntro',
      'edFlag',
      'workFlag'
    ];
    keysToRemove.map((key) => localStorage.removeItem(key));
    if (isAuthenticated) {
      getToken();
    }
  }, [isAuthenticated]);

  /** Full page loader here */
  // const shouldShowGroupLoader = role !== ROLES.platAdmin && groupRoleList.loading; // Old condition 1
  // const isAnyLoading = isLoading || roleLoading || userProfile.loading || settingsLoader; // Old condition 2

  const isAnyLoading =
    (role !== ROLES.platAdmin && groupRoleList.loading) ||
    isLoading ||
    roleLoading ||
    userProfile.loading ||
    settingsLoader;
  if (isAnyLoading) {
    return <AppLoader />;
  }

  const NewDesignRoutes = (
    <Route
      path="/app"
      element={<ProtectedRoute permissions={[ROLES.orgAdmin, ROLES.platAdmin, ROLES.member, ROLES.grpAdmin]} />}
    >
      <Route element={<SecuredRoutes />}>
        <Route path="onboardingprocess" element={<OnboardingProcessNew />} />
        {/* Connection Problem */}
        <Route path="connectionproblem" element={<ConnectionProblem />} />
        <Route path="" element={<Layout />}>
          {getHomeTab()}
          <Route path="redirect" element={<RoleRedirect />} />
          <Route path="profile" element={<ProfileLayout />}>
            <Route path="" element={role !== ROLES.platAdmin ? <Profile /> : <PasswordAndSecurity />} />
            <Route path="assessment" element={selectedGroup.active ? <AssessmentQuestions /> : <DeactivatedUser />} />
            <Route path="" element={<ProtectedRoute permissions={[ROLES.orgAdmin]} />}>
              <Route path="orgsettings" element={<OrganizationSettings />} />
              <Route path="settings" element={<ProfileSettings />} />
            </Route>
            {connection === 'Username-Password-Authentication' && (
              <Route path="security" element={<PasswordAndSecurity />} />
            )}
          </Route>

          <Route path="askDosen/" element={<AskDosen />}>
            <Route path="" element={<AiChatMain />} />
            <Route path="promtChat" element={<PromptChat />} />
          </Route>
          <Route path="liveChat" element={<Livechat />} />

          <Route path="aithanks" element={<AIChatThanksPage />} />

          <Route path="aichatbot/" element={<ChatBot />}>
            <Route path="" element={<PromptCards />} />
            <Route path="chatBothistory" element={<ChatBotHistoryComponent />} />
            <Route path="newChatBot" element={<NewChatBot />} />
          </Route>

          {selectedGroup.active || role === ROLES.platAdmin ? (
            <>
              <Route path="calendar" element={<Calendar />} />
              <Route path="knowledge" element={<Knowledge />} />
              <Route path="community" element={<CommunityTab />} />
              <Route path="library" element={<LibraryTab />} />
              <Route path="help" element={<HelpTab />} />
              <Route path="livechat" element={<LiveChat />} />
              <Route path="aithanks" element={<AIChatThanksPage />} />
              <Route path="aichatbot" element={<ChatBot />} />
              <Route path="libraryindustries" element={<LibraryIndustries />} />
              <Route path="communitymember" element={<CommunityMember />} />
              <Route path="chat" element={<ChatTab />} />
              <Route path="temple" element={<TemplePage />} />
              <Route path="/app/home/mentee/level/:levelId/task/:taskId" element={<MenteeTask />} />
            </>
          ) : (
            <Route path="*" element={<Navigate to="/" />} />
          )}
          <Route path="deactivated" element={<DeactivatedUser />} />
        </Route>
      </Route>
    </Route>
  );

  const ProgramRoutes = (
    <Route
      path="/app"
      element={<ProtectedRoute permissions={[ROLES.orgAdmin, ROLES.platAdmin, ROLES.grpAdmin, ROLES.member]} />}
    >
      <Route element={<SecuredRoutes />}>
        <Route path="" element={<Layout />}>
          <Route path="programdetails/:orgId/:coId/:id" element={<ProgramDetails />}>
            {selectedGroup.active || role === ROLES.platAdmin ? (
              <>
                <Route path="overview" element={<ProgramOverview />} />
                <Route path="users" element={<ProgramUsers />} />
                <Route path="insights" element={<ProgramInsights />} />
                <Route path="matches" element={<MatchesMainPage />} />
                <Route path="settings" element={<ProgramSettings />} />
              </>
            ) : (
              <Route path="*" element={<DeactivatedUser />} />
            )}
          </Route>
        </Route>
      </Route>
    </Route>
  );

  return (
    <Suspense fallback={<AppLoader />}>
      <Routes>
        <Route path="/" element={<Redirect />} />
        <Route element={<SecuredRoutes />}>
          <Route path="/redirect" element={<RoleRedirect />} />
        </Route>
        <Route path="/download-app" element={<MobileAppLinkPage />} />
        {userProfile.data ? (
          <>
            {NewPlatformAdminRoutes}
            {NewDesignRoutes}
            {(admins?.length > 0 || role === ROLES.orgAdmin || role === ROLES.platAdmin) && ProgramRoutes}
            <Route path="/video-call" element={<ZoomIndex />} />
            <Route path="/video-call/:topic/:role/:password/:startTime/:endTime" element={<ZoomIndex />} />
          </>
        ) : (
          <Route path="/login" element={<LoginElement />} />
        )}
        <Route path="/unauthorized" element={<Exception http={402} />} />
        <Route path="/not-found" element={<Exception http={404} />} />
        <Route
          path="*"
          element={
            isPromptLogin ? (
              <Navigate to={`/login?${promptOrg ? `org=${promptOrg}&` : ''}prompt=${isPromptLogin}`} replace />
            ) : (
              <Navigate to="/login" replace />
            )
          }
        />
        <Route path="/login" element={<LoginElement />} />
        <Route path="/logout" element={<LogoutComponent />} />
        <Route path="/error" element={<ConnectionProblem />} />
        <Route path="/errorPage" element={<Error message="" />} />
        <Route path="/maintenance" element={<PlannedMaintenance />} />
        <Route path="/not-foundv2" element={<NotFoundv2 />} />
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;
