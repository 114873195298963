// menteeSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Task {
  id: number;
  title: string;
  description: string;
  tags: any;
}

interface Level {
  id: number;
  name: string;
  tasks: Task[];
}

interface MenteeState {
  levels: Level[];
  isOpen: any;
  mode: string;
  selectedLevel: string;
  selectedLevelId: string;
}

const initialState: MenteeState = {
  levels: [],
  isOpen: false,
  mode: '',
  selectedLevel: '',
  selectedLevelId: ''
};

const menteeSlice = createSlice({
  name: 'mentee',
  initialState,
  reducers: {
    setSelectedLevel: (state, action) => {
      state.selectedLevel = action.payload.selectedLevel;
      state.selectedLevelId = action.payload.selectedLevelId;
    },
    openDialog: (state, action) => {
      state.isOpen = action.payload.isOpen;
      state.mode = action.payload.mode;
    },
    closeDialog: (state) => {
      state.isOpen = false;
      state.mode = '';
    },
    addLevel: (state, action: PayloadAction<{ id: number; name: string; tasks: any }>) => {
      // console.log('ac', action.payload)
      state.levels.push({
        id: action.payload.id,
        name: action.payload.name,
        tasks: action.payload.tasks || []
      });
    },
    removeLevel: (state, action: PayloadAction<number>) => {
      state.levels = state.levels.filter((level) => level.id !== action.payload);
    },
    editLevel: (state, action: PayloadAction<{ id: number; name: string }>) => {
      const { id, name } = action.payload;
      const level = state.levels.find((level) => level.id === id);
      if (level) {
        level.name = name;
      }
    },
    addTask: (state, action: PayloadAction<{ levelId: number; task: Task }>) => {
      const { levelId, task } = action.payload;
      const level = state.levels.find((level) => level.id === levelId);
      if (level) {
        level.tasks.push(task);
      }
    },
    removeTask: (state, action: PayloadAction<{ levelId: number; taskId: number }>) => {
      const { levelId, taskId } = action.payload;
      const level = state.levels.find((level) => level.id === levelId);
      if (level) {
        level.tasks = level.tasks.filter((task) => task.id !== taskId);
      }
    },
    editTask: (state, action: PayloadAction<{ levelId: number; taskId: number; updatedTask: Task }>) => {
      const { levelId, taskId, updatedTask } = action.payload;
      const level = state.levels.find((level) => level.id === levelId);
      if (level) {
        const task = level.tasks.find((task) => task.id === taskId);
        if (task) {
          Object.assign(task, updatedTask);
        }
      }
    }
  }
});

export default menteeSlice.reducer;

export const menteeTaskSlicAction = menteeSlice.actions;
