/**
 * @constant @name queryKeys
 * Keys and values must be same
 */
export const queryKeys = {
  GET_ACS_TOKEN: 'GET_ACS_TOKEN',
  GET_USER_DETAILS_BY_ID: 'GET_USER_DETAILS_BY_ID',
  GET_USER_CALLS_DETAILS: 'GET_USER_CALLS_DETAILS',
  GET_ALL_GROUP_TASKS: 'GET_ALL_GROUP_TASKS',
  GET_REC_ARTICLES: 'GET_REC_ARTICLES',
  GET_REC_MENTORS: 'GET_REC_MENTORS',
  GET_MY_MENTORS: 'GET_MY_MENTORS',
  GET_MENTEE_PEERS: 'GET_MENTEE_PEERS'
};
