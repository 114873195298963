import React from 'react';
import _ from 'lodash';
import { IconButton, Typography } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import TwitterIcon from '@mui/icons-material/Twitter';
import MySkeleton from '../../../components/Skeleton';
// import favicon from '../../../assets/images/favicon.svg';

export interface Social {
  facebook: string;
  instagram: string;
  youtube: string;
  linkedIn: string;
  twitter: string;
}

interface Props {
  loading?: boolean;
  location?: string;
  data: Record<string, string>;
}

const defaultSize = { width: '20px', height: '20px' };

export const socialIconsArray = [  
  {
    id: 1,
    name: 'linkedIn',
    label: 'Linkedin',
    icon: <LinkedInIcon sx={{ ...defaultSize, color: '#0072b1' }} />,
  },
  {
    id: 2,
    name: 'facebook',
    label: 'Facebook',
    icon: <FacebookIcon sx={{ ...defaultSize, color: '#475993' }} />,
  },
  {
    id: 3,
    name: 'instagram',
    label: 'Instagram',
    icon: <InstagramIcon sx={{ ...defaultSize, color: '#F44336' }} />,
  },
  {
    id: 4,
    name: 'twitter',
    label: 'Twitter',
    icon: <TwitterIcon sx={{ ...defaultSize, color: '#03A9F4' }} />,
  },
  {
    id: 5,
    name: 'youtube',
    label: 'Youtube',
    icon: <YouTubeIcon sx={{ ...defaultSize, color: '#FF0000' }} />,
  },
  // {
  //   id: 6,
  //   name: 'dosen',
  //   label: 'Dosen URL',
  //   icon: <img src={favicon} style={{ ...defaultSize }} />,
  // },
];

const getMessage = ({ location }: { location?: string }) => {
  let message;
  switch (location) {
    case 'adminUserInfo':
      message = '-';
      break;
    case 'publicProfile':
      message = '';
      break;
    default:
      message = 'No Social Handles';
  }
  return <Typography>{message}</Typography>;
};

const handleOpenSocialSites = (event: React.SyntheticEvent<Element, Event>, linkURL: string) => {
  event.preventDefault();
  window.open(linkURL, '_blank');
};

const SocialIcon: React.FC<{ icon: React.ReactNode; linkURL: string }> = ({ icon, linkURL }) => (
  <IconButton onClick={(event) => handleOpenSocialSites(event, linkURL)}>
    {icon}
  </IconButton>
);

export const GetSocialIcon: React.FC<Props> = ({ loading, location = '', data }): JSX.Element | null => {
  const newArray = socialIconsArray
    ?.filter((social) => _.isPlainObject(data) && _.includes(Object.keys(data), social.name) && !_.isEmpty(_.get(data, social.name)))
    ?.map((social) => {
      const linkURL = _.get(data, social.name, '').includes('https://')
        ? _.get(data, social.name, '')
        : `https://${_.get(data, social.name, '')}`;

      return (
        <SocialIcon
          key={social.id}
          icon=
          {loading ? 
            (<MySkeleton variant='rounded' height={25} width={25} sx={{ borderRadius: '5px' }} />)
            : 
            (social.icon)
          }
          linkURL={linkURL}
        />
      );
    });

  return newArray.length > 0 ? <>{newArray}</> : <>{getMessage({ location })}</>;
};
