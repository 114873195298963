import React from 'react';
import { Box, Grid, Stack } from '@mui/material';
import MySkeleton from '../components/Skeleton';

export const ProfilePageLoader = () => {
    const widths = [120, 80, 100, 150];

    return ( 
        <Box
          top={'0'}
          maxHeight={'100%'}
          position={'relative'}
          overflow={'hidden auto'}
          gap={1}
        >
            {/* Header */}
            <Box className='CardBox'>
                <Box p={2}>
                    <MySkeleton width={150} />
                </Box>
                <MySkeleton height={2} />

                {/* Profile Image */}
                <Box p={2} bgcolor='#EFF0F4'>
                    <Box className="profilePageHead">
                        <Box>
                            <MySkeleton variant='rounded' height={95} width={95} bg={'#FFFFFF'}/>
                        </Box>
                        <Box className="userDetails">
                            <Box className="NameTagEditIcon">
                                <Box className="NameTag">
                                    <MySkeleton width={150} bg={'#FFFFFF'}/>
                                </Box>
                                <MySkeleton variant='rounded' height={20} width={20} className='m-10' bg={'#FFFFFF'}/>
                            </Box>
                            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 0.5, sm: 2 }}>
                                {[...Array(3).keys()].map((_, index) => (
                                    <MySkeleton width={120} bg={'#FFFFFF'} />
                                ))}
                            </Stack>
                            <Box className="countryTimezone">
                                <MySkeleton variant='rounded' height={20} width={50} bg={'#FFFFFF'}/>
                                <Stack direction="column" justifyContent="center" alignItems="flex-end" spacing={3}>
                                    <MySkeleton width={150} bg={'#FFFFFF'}/>
                                </Stack>
                            </Box>
                        </Box>
                    </Box>
                </Box>

                {/* Info */}
                <MySkeleton height={2} />
                <Box p={2} display={'flex'} alignItems={'flex-start'} >
                    <Grid container spacing={1}>
                        {[...Array(5).keys()].map((_, index) => (
                            <Grid item xs={6} md={4} lg={2}>
                                <MySkeleton width={120} />
                                <MySkeleton width={100} />
                            </Grid>
                        ))}
                    </Grid>
                </Box>

                {/* Video Intro */}
                <MySkeleton height={2} />
                <Box>
                    <Box p={2} pb={0} display="flex" justifyContent="space-between" alignItems="center">
                        <MySkeleton width={150} />
                        <MySkeleton variant='rounded' height={20} width={20} className='m-10' />
                    </Box>
                    <Grid container direction="row" justifyContent="center" alignItems="center" p={2}>
                        <Grid md={6} display={'flex'} flexDirection={'column'} alignItems={'center'}>
                            <MySkeleton width={300} className='mb-20'/>
                            <MySkeleton variant='rounded' height={40} width={100} />
                        </Grid>
                    </Grid>
                </Box>
            </Box>

            {/* Bio */}
            <Box className='CardBox' my={2}>
                <Box p={2}>
                    <MySkeleton width={150} />
                </Box>
                <MySkeleton height={2} />
                <Box p={2}>
                    <MySkeleton />
                    <MySkeleton />
                </Box>
            </Box>

            {/* Work and Education History */}
            {[...Array(2).keys()].map((_, index) => (
                <Box className='CardBox' my={index === 1 ? 2 : 0} key={index}>
                    <Box display='flex' justifyContent='space-between' p={2}>
                        <MySkeleton width={150} />
                        <MySkeleton variant='circular' height={20} width={20} />
                    </Box>
                    <MySkeleton height={2} />
                    <Box display="flex" justifyContent={'space-between'} p={2} >
                        <Box display="flex" gap={1}>
                            <MySkeleton variant="rounded" height={49} width={49} />
                            <Box alignItems={"center"}>
                                <Box display="flex" flexDirection={"column"}>
                                    <MySkeleton variant="text" height={25} width={200} />
                                    {[...Array(4).keys()].map((_, index) => (
                                        <Box key={index}>
                                            <MySkeleton variant="text" width={widths[index]} />
                                        </Box>
                                    ))}
                                </Box>
                            </Box>
                        </Box>
                        <MySkeleton variant='rounded' height={20} width={20} />
                    </Box>
                </Box>
            ))}

            {/* Hobbies */}
            <Box className='CardBox'>
                <Box display='flex' justifyContent='space-between' p={2}>
                    <MySkeleton width={150} />
                    <MySkeleton variant='rounded' height={20} width={20} />
                </Box>
                <MySkeleton height={2} />
                <Box display="flex" gap={1} p={2} >
                    {[...Array(3).keys()].map((_, index) => (
                        <Box key={index}>
                            <MySkeleton variant='rounded' height={30} width={100} />
                        </Box>
                    ))}
                </Box>
            </Box>

            {/* Social */}
            <Box className='CardBox' my={2}>
                <Box display='flex' justifyContent='space-between' p={2}>
                    <MySkeleton width={150} />
                    <MySkeleton variant='rounded' height={20} width={20} />
                </Box>
                <MySkeleton height={2} />
                <Box display="flex" gap={1} p={2} >
                    {[...Array(4).keys()].map((_, index) => (
                        <Box key={index}>
                            <MySkeleton variant='rounded' height={30} width={30} />
                        </Box>
                    ))}
                </Box>
            </Box>
            
            {/* Disability */}
            <Box className='CardBox' my={2}>
                <Box display='flex' justifyContent='space-between' p={2}>
                    <MySkeleton width={150} />
                    <MySkeleton variant='rounded' height={20} width={20} />
                </Box>
                <MySkeleton height={2} />
                <Box p={2} >
                    <MySkeleton width={250} />
                </Box>
            </Box>
        </Box>
    );
};
