import {
  Box,
  Grid,
  Button,
  // IconButton,
  Typography,
  IconButton
} from '@mui/material';
import _ from 'lodash';
import MyTooltip from './MyTooltip';
import VideoPlayer from './VideoPlayer';
// import { EditIcon } from '../../../utils/Icons';
import { useAppDispatch } from '../../../redux/hooks';
import useProfileDetails from '../../../hooks/useProfileDetails';
import { popupActions } from '../../../redux/slices/onboardingPopupsSlice';
import useResponsiveScreenSwitch from '../../../utils/useResponsiveScreenSwitch';
import { EditIcon } from '../../../utils/Icons';
import { toast } from 'react-toastify';

const DigitalIntro = () => {
  const { videoIntro } = useProfileDetails();
  /** For responsive */
  const { forMobile } = useResponsiveScreenSwitch(); // Use the hook
  const dispatch = useAppDispatch();
  const handleClickOpenDigital = () => {
    if (videoIntro.includes('blob:')) {
      toast.warning('Upload is in progress');
      return;
    }
    dispatch(popupActions.updatePopups({ digitalIntro: true }));
  };

  const IfNoVideo = () => {
    return (
      <Grid container direction="row" justifyContent="center" alignItems="center" p={2}>
        <Grid md={6} className="text-center">
          <Typography mb={2}>
            {/* <span className="color-primary">Coming Soon —</span> */}
            Video introductions will allow you to introduce yourself to your community and build a network.
          </Typography>
          <Button variant="contained" sx={{ px: 4 }} onClick={handleClickOpenDigital}>
            Add
          </Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <Box>
      <Box p={2} pb={0} display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h5">
          Video Introduction
          <MyTooltip
            gapleft={0.5}
            iconColor="#152536"
            iconSize="16px"
            title="Your video introduction is displayed on your profile. Members with a video introduction get more profile views than those without"
          />
        </Typography>
        <IconButton
          size="small"
          onClick={handleClickOpenDigital}
          title={videoIntro.includes('blob:') ? 'Upload in progress' : ''}
        >
          <EditIcon />
        </IconButton>
      </Box>
      <Box p={2}>
        {videoIntro ? (
          <Box display={'flex'} justifyContent={'space-between'}>
            <Box width="calc(100% - 40px)">
              <VideoPlayer
                src={videoIntro}
                width={forMobile ? '98%' : '560px'}
                height={forMobile ? '150px' : '310px'}
              />
            </Box>
          </Box>
        ) : (
          <IfNoVideo />
        )}
      </Box>
    </Box>
  );
};

export default DigitalIntro;
