import React from "react";
import {
  Box,
  Grid,
  Divider,
} from "@mui/material";
import MySkeleton from "../../../../../components/Skeleton";
import UserinfoCard from "../../../components/UserinfoCard";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const MatchDetailsPopupLoader = () => {
  return (
    <>
      <Grid container justifyContent={'space-between'}>
        <Grid item xs={4.5}>
          {/* Left Box */}
          <UserinfoCard UserinfoCardDetails={{}} loading={true} />
        </Grid>
        <Grid item xs={3} container justifyContent="center" alignItems="center">
          {/* Middle Box */}
          <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
            <Box className="ManualMatchIcon">
              <ChevronLeftIcon className="ChevronLeftIconLeft" sx={{ color: '#ABB5BE' }} />
              <Box className="ManualMatchtextBox">
                <MySkeleton variant="text" height={16} width={100} sx={{ marginTop: '8px' }} />
                <span className="lineDotted" />
              </Box>
              <ChevronRightIcon className="ChevronLeftIconRight" sx={{ color: '#ABB5BE' }} />
            </Box>
            <MySkeleton variant="text" height={16} width={100} />
          </Box>
        </Grid>
        <Grid item xs={4.5}>
          {/* Right Box */}
          <UserinfoCard UserinfoCardDetails={{}} loading={true} />
        </Grid>
      </Grid>

      <Divider className="my-2" />

      <Grid container spacing={2}>
        {Array.from({ length: 3 }, (_, idx) => (
          <Grid key={idx} item lg={4} md={4} sm={6}>
            <MySkeleton variant="text" height={14} width={100} />
            <MySkeleton variant="text" height={14} width={50} />
          </Grid>
        ))}
      </Grid>

      <Divider className="my-2" />

      <Box pb={1}>
        <MySkeleton variant="text" width={150} />
      </Box>
      <Grid container spacing={2}>
        {Array.from({ length: 3 }, (_, idx) => (
          <Grid item lg={4} md={4} sm={6} key={idx}>
            <Box>
              <MySkeleton variant="text" height={14} width={100} />
              <MySkeleton variant="text" height={14} width={50} />
            </Box>
          </Grid>
        ))}
      </Grid>

      <Divider className="my-2" />
      <Box mb={1}>
        {Array.from({ length: 6 }, (_) => (
          <>
            <MySkeleton variant="text" width={120} />
            <Box display="flex" flexWrap="wrap" gap="8px" my={1}>
              {Array.from({ length: 3 }, (_, idx) => (
                <MySkeleton variant='rounded' height={35} width={100} />
              ))}
            </Box>
          </>
        ))}
      </Box>
    </>
  )
};

export default MatchDetailsPopupLoader;