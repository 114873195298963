import React, { useEffect, useState } from 'react';
import { Box, Badge, Dialog, Divider, Checkbox, Typography, DialogContent, DialogActions } from '@mui/material';
import { toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';
import RoleTypeTag from '../../../../../components/RoleTypeTag';
import { topicArray } from '../../../../../components/common';
import { useAppSelector } from '../../../../../../../redux/hooks';
import AppLoader from '../../../../../../../components/AppLoader';
import { FilterSearch } from '../../../../../components/filter/FilterProperty';
// import { camelCaseToCapital } from '../../../../../../../utils/topicsMapping';
import CommonModalHeader from '../../../../../../../components/DialogUi/CommonModalHeader';

interface ContentsModalProps {
  openContents: boolean;
  onClose: () => void;
  setArticles: any;
  articlesSelected: any[];
}

const ContentsModal: React.FC<ContentsModalProps> = ({ openContents, onClose, setArticles, articlesSelected }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [checkedItems, setCheckedItems] = useState<Array<string>>([]);

  const allArticleData = useAppSelector((state) => state.allArticle);

  const [selectedArticles, setSelectedArticles] = useState<any[]>(articlesSelected);
  const [allArticles, setAllArticles] = useState<any[]>(allArticleData.data?.ArticleList || []);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  useEffect(() => {
    setSelectedArticles(articlesSelected);
  }, [articlesSelected]);

  // if required later can directly use the selected groupTopics from the store
  const groupTopics = useAppSelector((state) => state.groupDetails.data?.topics);
  // const mappedTopics = camelCaseToCapital(groupTopics || []);
  const topics = topicArray();
  const saveArticles = () => {
    setArticles(selectedArticles);
    onClose();
  };
  // const groupTopics = useAppSelector((state)=>state.groupDetails.data?.topics)

  const handleTagChange = (tag: string) => {
    setSelectedTags((prevTypes) =>
      prevTypes.includes(tag) ? prevTypes.filter((t) => t !== tag) : [...prevTypes, tag]
    );
  };

  const handleSelectAll = () => {
    if (selectedTags.length === groupTopics.length) {
      setSelectedTags([]);
    } else {
      setSelectedTags(groupTopics);
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const handleSelectArtilce = (articleId: any) => {
    if (selectedArticles.includes(articleId)) {
      setSelectedArticles(selectedArticles.filter((item) => item !== articleId));
    } else {
      if (selectedArticles.length >= 5) {
        toast.warning('You can select up to 5 articles');
      } else {
        setSelectedArticles([...selectedArticles, articleId]);
      }
    }
  };

  const filteredResults = allArticles.filter((item: any) => {
    return (
      item.articleName.toLowerCase().includes(searchQuery.toLowerCase()) &&
      (selectedTags.length === 0 ||
        item.tags.some((tag: string) => selectedTags.length === groupTopics.length || selectedTags.includes(tag)))
    );
  });

  return (
    <Dialog open={openContents} onClose={onClose} fullWidth PaperProps={{ sx: { height: '100%' } }}>
      <CommonModalHeader title="Contents" handleCloseModal={onClose} />
      {allArticleData.loading ? (
        <AppLoader />
      ) : allArticleData.data ? (
        <>
          <DialogContent>
            <Box pb={2}>
              <FilterSearch
                value={searchQuery}
                onChange={handleSearchChange}
                userTypeDate={{ placeholderText: 'Search Content' }}
              />
            </Box>
            <Box overflow="auto" gap={1} pb={1} mb={2} display="flex">
              <RoleTypeTag
                category="All"
                onClick={handleSelectAll}
                selected={selectedTags.length === groupTopics.length}
              />
              {groupTopics.map((topic: string) => {
                let tag = '';
                const index = topics.findIndex((each) => each.name === topic);
                if (index !== -1) {
                  tag = topics[index].label;
                }
                return (
                  <RoleTypeTag
                    key={tag}
                    category={tag}
                    onClick={() => handleTagChange(topic)}
                    selected={selectedTags.includes(topic)}
                  />
                );
              })}
            </Box>

            {filteredResults.length > 0 ? (
              filteredResults.map((item, index, array) => (
                <React.Fragment key={item.articleId}>
                  <Box gap="8px" display="flex" alignItems="center">
                    <Checkbox
                      checked={selectedArticles.some((id) => id === item.articleId)}
                      onChange={() => handleSelectArtilce(item.articleId)}
                    />
                    <Box borderRadius="8px" bgcolor="#EFF0F4">
                      <img src={item.coverImageUrl} alt="error" height="50px" width="80px" />
                    </Box>
                    <Box display="flex" flexDirection="column">
                      <Typography color="#0071A9" variant="subtitle2">
                        {item.articleName}
                      </Typography>
                      {item.accessFor && (
                        <Box>
                          <Badge
                            sx={{
                              mt: 1,
                              color: '#E99940',
                              fontSize: '12px',
                              fontWeight: '600',
                              padding: '2px 9px',
                              borderRadius: '5px',
                              fontFamily: 'Open Sans',
                              textTransform: 'capitalize',
                              border: '1px solid #E99940'
                            }}
                          >
                            Community Exclusive Content
                          </Badge>
                        </Box>
                      )}
                    </Box>
                  </Box>
                  {index !== array.length - 1 && <Divider sx={{ my: 2 }} />}
                </React.Fragment>
              ))
            ) : (
              <Box textAlign="center" py={2}>
                <Typography variant="body1">No data found</Typography>
              </Box>
            )}
          </DialogContent>
          <DialogActions sx={{ p: 2 }}>
            <LoadingButton fullWidth color="primary" variant="contained" onClick={saveArticles}>
              Save
            </LoadingButton>
          </DialogActions>
        </>
      ) : null}
    </Dialog>
  );
};

export default ContentsModal;
