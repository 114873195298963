import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  flag: false
};

const companySlice = createSlice({
  name: 'companyList',
  initialState,
  reducers: {
    update: (state, action) => {
      state.flag = action.payload;
    }
  }
});

export default companySlice.reducer;
export const companyActions = companySlice.actions;
