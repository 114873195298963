import React from 'react';
import { Button, Dialog, Typography, DialogActions, DialogContent } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import CommonModalHeader from '../../../../../components/DialogUi/CommonModalHeader';

interface Props {
  openDelete: boolean;
  setDelete: any;
  deleteLoader: boolean;
  handleDelete: any;
  text: string;
  btnText?: string;
}

const ProgramDeletDialog = ({ openDelete, setDelete, deleteLoader, handleDelete, text, btnText = 'Remove' }: Props) => {
  const closeModal = () => {
    if (deleteLoader) return;
    setDelete(false);
  };
  return (
    <Dialog
      open={openDelete}
      onClose={closeModal}
      fullWidth
      disableEnforceFocus // prevents from Range Error
    >
      <CommonModalHeader title={`Confirmation`} handleCloseModal={closeModal} />
      <DialogContent>
        <Typography variant="h5" textAlign={'center'}>
          {text}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="secondary" fullWidth onClick={closeModal} disabled={deleteLoader}>
          Cancel
        </Button>

        <LoadingButton color="primary" variant="contained" fullWidth loading={deleteLoader} onClick={handleDelete}>
          {btnText}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ProgramDeletDialog;
