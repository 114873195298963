import { useQuery } from '@tanstack/react-query';
import { queryKeys } from './queryKeys';
import { API } from '../api';

const useRecMentors = ({
  grpId,
  location,
  orgId,
  userId,
  levelId,
  taskId
}: {
  grpId: string;
  location: string;
  orgId: string;
  userId: string;
  levelId: string;
  taskId: string;
}) => {
  return useQuery({
    queryKey: [queryKeys.GET_REC_MENTORS, grpId, orgId, userId],
    queryFn: () => {
      return API.getRecommendedMentors({ grpId, location, orgId, userId, levelId, taskId });
    },
    staleTime: 10 * 10 * 1000
  });
};

export default useRecMentors;
