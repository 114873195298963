/* eslint-disable react-hooks/exhaustive-deps */
import { Navigate, Outlet } from 'react-router-dom';
import { getUserDetails } from '../utils/orgName';
import moment from 'moment';
import { useLogout } from '../hooks';
import { useAppSelector } from '../redux/hooks';

import ROLES from '../utils/roles';
import useProfileDetails from '../hooks/useProfileDetails';
import _ from 'lodash';
import useOrgSettings from '../hooks/useOrgSettings';

const SecuredRoutes = () => {
  const tokens = localStorage.getItem('tokens');
  const isLoggedIn = localStorage.getItem('isAuthenticated') || false;
  const { doLogout } = useLogout();
  const role = localStorage.getItem('role');
  const { exp } = getUserDetails();
  const currentTime = moment();
  const expiryTime = moment.unix(exp);
  const userData = useAppSelector((state) => state.userProfile.data);
  const { ftue, firstName } = useProfileDetails();
  const isOrgSettings = useOrgSettings();

  const checkSession = () => {
    if (currentTime.isAfter(expiryTime)) {
      doLogout();
      return null; // Return null since the navigation will handle the rendering
    } else {
      if (userData && ftue && role !== ROLES.platAdmin) {
        if (role === ROLES.orgAdmin && !isOrgSettings && firstName !== '') {
          return <Outlet />;
        }
        window.location.replace('/');
        return null;
      }
      return <Outlet />; // Render the nested routes
    }
  };
  return role && (tokens || isLoggedIn) ? checkSession() : <Navigate to="/login" />;
};

export default SecuredRoutes;
