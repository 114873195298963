import React from "react";
import {
  Box,
  Checkbox, 
  TextField,
  InputAdornment,
  FormControlLabel
} from '@mui/material';
import SearchSvg from '../../../../assets/images/smallSearch.svg';

const SearchIcon = () => <img src={SearchSvg} alt="Search Icon" />;

interface FilterCheckFieldProps {
    index?: number;
    label?: string;
    onChange?: (value: string) => void;
    checked?: boolean;
    width?: string; // Add width prop
}

interface FilterSearchProps {
  userTypeDate: {
    placeholderText: string;
  };
  value: string | string[];
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  width?: string; // Add width prop
}

/** Checkbox field */
export const FilterCheckField: React.FC<FilterCheckFieldProps> = ({ index, label, onChange, checked, width }) => {
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(event.target.value);
    }
  };

  return (
    <FormControlLabel
        key={index}
        sx={{ width: width || '100%', justifyContent: 'space-between', ml: '0' }}
        value={label}
        control={
          <Checkbox 
              onChange={handleCheckboxChange}
              checked={checked}
          />
        }
        label={label}
        labelPlacement="start"
    />
  );
};

/** Search field */
export const FilterSearch: React.FC<FilterSearchProps> = (props) => {
  const { userTypeDate, value, onChange, width } = props;

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event);
  };

  return (
    <TextField
      size="small"
      fullWidth={!width} // If width is not specified, use fullWidth
      placeholder={userTypeDate.placeholderText}
      value={value}
      onChange={handleInputChange}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Box>
              <SearchIcon />
            </Box>
          </InputAdornment>
        ),
      }}
      sx={{ width: width || '100%' }} // Apply custom width or default to 100%
    />
  );
};
