import React, { useState } from 'react';
import {
  Box,
  Divider,
  Popover,
  Typography
} from '@mui/material';
import { LiveChatStyle } from './LiveChatStyle';
import { Outlet, useNavigate } from 'react-router-dom';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import circleicon from '../../../../assets/images/circleicon.svg';
import ChatAvatarComponent from '../chat/ChatAvatarComponent';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';

interface askDosenProps {}

const AskDosen: React.FC<askDosenProps> = ({}) => {
  // const userProfileData = useAppSelector((state) => state.userProfile.data);
  const classes = LiveChatStyle();
  const navigate = useNavigate();
  const [rightTopPop, setRightTopPop] = useState(null);
  const rightTopDotsClick = (event: any) => {
    setRightTopPop(event.currentTarget);
  };
  const handleClose = () => {
    setRightTopPop(null);
  };
  const open = Boolean(rightTopPop);
  const rightTopPopover = open ? 'simple-popover' : undefined;
  const openNewChat = () => {
    setRightTopPop(null);
  };
  const openchatBothistory = () => {
    navigate('/app/aichatbot/chatBothistory');
    setRightTopPop(null);
  };
  const onClickBack = () => {
    navigate('/app/aichatbot');
  };

  return (
    <Box className="bodyBox">
      <Box className={classes.MainBox} height="100%">
        <Box className={classes.Container}>
          {/* Header */}
          <Box p={2}>
            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
              <Box display={'flex'} alignItems={'center'}>
                <ArrowBackIosIcon className='cursor-pointer' onClick={onClickBack} />
                <ChatAvatarComponent
                  variant='circle'
                  width="44px"
                  height="44px"
                  image={circleicon}
                  firstLetter={'Dosen'}
                  border={'1px solid #000000'}
                />
                <Typography variant="h4" ml={1}>Dosen</Typography>
              </Box>
              <MoreVertIcon
                color='primary'
                className='cursor-pointer'
                onClick={rightTopDotsClick}
              />
              <Popover
                id={rightTopPopover}
                open={open}
                anchorEl={rightTopPop}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              >
                <Box className="PopoverListStyle" onClick={openNewChat}>
                  <OpenInNewIcon />
                  <Typography color="primary">New chat</Typography>
                </Box>
                <Box className="PopoverListStyle" onClick={openchatBothistory}>
                  <SettingsBackupRestoreIcon />
                  <Typography color="primary">History</Typography>
                </Box>
              </Popover>
            </Box>
          </Box>
          <Divider />
          {/* Outlet */}
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};

export default AskDosen;
