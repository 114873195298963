import React, { useEffect, useState } from 'react';
import { Box, Grid, Button, Tooltip, Typography, DialogActions, DialogContent } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedOutlinedIcon from '@mui/icons-material/RadioButtonUncheckedOutlined';
import useProfileDetails from '../../../../hooks/useProfileDetails';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { popupCountActions } from '../../../../redux/slices/poupsCountsSlice';
import { API } from '../../../../api';
import { getUserDetails } from '../../../../utils/orgName';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { topicArray } from '../../components/common';
import { userActions } from '../../../../redux/slices/user-profile/userProfieSlice';
import { companyActions } from '../../../../redux/slices/companySlice';

const OnboardingProcessNew: React.FC = () => {
  const { topics, selectedTopics } = useProfileDetails();
  const topicsList = topicArray();
  const [userSelectedTopics, setSelectedTopics] = useState<string[]>([]);
  const dispatch = useAppDispatch();
  const { orgId, userId } = getUserDetails();
  const popuplength = useAppSelector((state) => state.popupsCount.popuplength);
  const count = useAppSelector((state) => state.popupsCount.count);
  const nextPopUpOrNot = popuplength - count;
  const { ftue } = useProfileDetails();
  // const [selectedTopics, setSelectedTopics] = useState<string[]>([]);

  const handleTopicClick = (topic: string) => {
    let updatedList = [];
    if (userSelectedTopics.includes(topic)) {
      updatedList = userSelectedTopics.filter((each) => topic !== each);
    } else {
      updatedList = [...userSelectedTopics, topic];
    }
    setSelectedTopics(updatedList);
  };

  const handleBoxClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    /** Prevent the dialog from closing when clicking the box (dialog body) */
    event.stopPropagation();
  };

  const saveTopics = async () => {
    try {
      const response = await API.saveTopicsForUser({ orgId, data: { topics: userSelectedTopics } });
    } catch (e) {
      toast.error(_.get(e, 'response.data.messgae', `Topics failed to update`));
    }
  };

  const updateFtue = async () => {
    //setLoading(true);
    try {
      const response = await API.updateUserFtue({ ftue: false, userId });
      if (response.status === 200 && response.statusText === 'OK') {
        dispatch(userActions.userFtueUpdate(0));
        //setLoading(false)
      }
    } catch (e) {
      toast.error(_.get(e, 'response.data.message', `Couldn't update profile details`));
      //setLoading(false);
    }
  };

  const handleNextStep = async () => {
    if (userSelectedTopics.length === 0) {
      toast.error('Please select at least one topic');
      return;
    }
    dispatch(userActions.userTopicsUpdate(userSelectedTopics));
    saveTopics();
    if (ftue && nextPopUpOrNot !== 1) {
      dispatch(popupCountActions.increment(0));
    }
    if (nextPopUpOrNot === 1) {
      dispatch(companyActions.update(true));

      await updateFtue();
    }
  };

  const handleBack = () => {
    dispatch(popupCountActions.decrement(0)); // Move to the previous step
  };

  /** Show questions 3 */

  /** Helper function to calculate the progress percentage */

  useEffect(() => {
    setSelectedTopics(selectedTopics);
  }, []);

  return (
    <>
      {/* Dialog */}

      <DialogContent onClick={handleBoxClick} sx={{ height: '90vh' }}>
        <Box>
          <Typography mb={2}>Choose any areas of interest and relevance to you:</Typography>
          <Box>
            <Grid container spacing={2}>
              {topics.map((each) => {
                const topic = topicsList.find((topics) => topics.name === each) || { name: '', label: '' };

                return (
                  <Grid item xs={6} sm={4} key={topic?.label}>
                    <Box
                      className={`CheckBtnChip ${userSelectedTopics.includes(topic?.name) ? 'checked' : ''}`}
                      onClick={() => handleTopicClick(topic?.name)}
                    >
                      {userSelectedTopics.includes(topic?.name) ? (
                        <CheckCircleIcon />
                      ) : (
                        <RadioButtonUncheckedOutlinedIcon />
                      )}
                      <Tooltip title={topic?.label}>
                        <Typography noWrap>{topic?.label}</Typography>
                      </Tooltip>
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button fullWidth variant="outlined" color="secondary" onClick={handleBack}>
          Back
        </Button>

        <Button fullWidth variant="contained" onClick={handleNextStep}>
          {nextPopUpOrNot === 1 ? 'Finish' : 'Next'}
        </Button>
      </DialogActions>
    </>
  );
};

export default OnboardingProcessNew;
