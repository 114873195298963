import { createSlice } from '@reduxjs/toolkit';
import { IChatUsers } from './getChatUsersSlice';

interface IData {
  data: IChatUsers;
}

const initialState: IData = {
  data: {
    communicationId: '',
    userId: '',
    name: ''
  }
};

const selectedChatUserSlice = createSlice({
  name: 'selectedChatUser',
  initialState,
  reducers: {
    update: (state, action) => {
      state.data = action.payload;
    }
  }
});

export default selectedChatUserSlice.reducer;
export const selectedChatUserActions = selectedChatUserSlice.actions;
