import React from 'react';
import { Box, Divider, Typography } from '@mui/material';
import ChatAvatarComponent from '../../../chat/ChatAvatarComponent';
import { unixToHumanReadable } from '../../../../components/common';

interface Review {
  name?: string;
  avatarSrc?: string;
  comment?: string;
  date?: string;
}

interface ReviewTableProps {
  reviews: { name: string; headshot: string; review: string; createdAt: number }[];
}

function ReviewTable({ reviews }: ReviewTableProps) {
  return (
    <Box className="CardBox">
      <Box p={2}>
        <Typography variant="h5">Review</Typography>
      </Box>
      <Divider />
      <Box p={2} display="flex" flexDirection="column" gap={2}>
        {reviews?.length ? (
          reviews.map((review, index) => (
            <React.Fragment key={index}>
              <Box display="flex" alignItems="center" gap={2}>
                <ChatAvatarComponent
                  image={review.headshot}
                  firstLetter={review.name?.slice(0, 1)}
                  width="40px"
                  height="40px"
                  type="noStatus"
                />
                <Box display="flex" flexDirection="column" flex="1" color="#FFFFFF">
                  <Typography variant="h5">{review.name}</Typography>
                  <Typography>{review.review}</Typography>
                </Box>
                <Typography>{unixToHumanReadable(review.createdAt)}</Typography>
              </Box>
              {index < reviews.length - 1 && <Divider />}
            </React.Fragment>
          ))
        ) : (
          <Typography textAlign="center">No reviews yet</Typography>
        )}
      </Box>
    </Box>
  );
}

export default ReviewTable;
