import _ from 'lodash';
import {
  Dialog,
  DialogContent,
  // IconButton,
  // DialogTitle
} from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { dialogActions } from '../../redux/slices/dialog-slice/dialogSlice';
// import { Close } from '@mui/icons-material';
// import { styled } from '@mui/material/styles';
import DialogConditions from './DialogConditions';
import CommonModalHeader from './CommonModalHeader';

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

// const BootstrapDialog = styled(Dialog)(({ theme }) => ({
//   '& .MuiDialogContent-root': {
//     padding: theme.spacing(2)
//   },
// }));

// function BootstrapDialogTitle(props: DialogTitleProps) {
//   const { children, onClose, ...other } = props;

//   return (

//     <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
//       {children}
//       {onClose ? (
//         <IconButton
//           aria-label="close"
//           onClick={onClose}
//           sx={{
//             position: 'absolute',
//             right: 8,
//             top: 8,
//             color: (theme) => theme.palette.grey[500]
//           }}
//         >
//           <Close />
//         </IconButton>
//       ) : null}
//     </DialogTitle>
//   );
// }

const DialogSelector = () => {
  const dialogName = useAppSelector((state) => state.dialogActions.dialogName);
  const dialogTitle = useAppSelector((state) => state.dialogActions.title);
  const dispatch = useAppDispatch();

  const closeDialog = () => dispatch(dialogActions.atnCloseDialog());
  if (_.isEmpty(dialogName)) return null;
  return (
    <>
      <Dialog
        open={!_.isEmpty(dialogName)}
        onClose={closeDialog}
      >
        <CommonModalHeader
          handleCloseModal={closeDialog}
          title={dialogTitle || ''}
        />
        <DialogContent>
          <DialogConditions />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default DialogSelector;
