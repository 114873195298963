import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  flag: false
};

const saveAnswersSlice = createSlice({
  name: 'companyList',
  initialState,
  reducers: {
    update: (state, action) => {
      state.flag = action.payload;
    }
  }
});

export default saveAnswersSlice.reducer;
export const saveAnswerActions = saveAnswersSlice.actions;
