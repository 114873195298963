/* eslint-disable no-irregular-whitespace */
/* eslint-disable prettier/prettier */
/* eslint-disable no-useless-catch */
/* eslint-disable no-param-reassign */
// import axios, { AxiosRequestConfig } from 'axios';
import _ from 'lodash';
import { FieldValues } from 'react-hook-form';
import { IWorkHistory } from '../pages/new-designs/components/WorkHistory';
import { getUserDetails } from '../utils/orgName';
import axios from 'axios';
import { setupCache, type HeaderInterpreter } from 'axios-cache-interceptor';
import { Social } from '../pages/new-designs/components/SocialIcon';
import { ITaskData } from '../redux/slices/tasksSlice';

// axios.interceptors.request.use((config) => {
//   // Generate a unique key based on URL, method, and relevant data parameters
//   const customKey = `${config.url}_${config.method}_${JSON.stringify(config.data)}`;
//   config.headers['X-Custom-Cache-Key'] = customKey;

//   return config;
// });

const axiosCached = setupCache(axios);

const getAllArticle = ({
  orgId,
  groupId,
  helpSettings,
  useCache, // Add this parameter to control caching,
  role
}: {
  orgId: string;
  groupId: string;
  helpSettings: boolean;
  useCache?: boolean; // Add this to the parameter type definition
  role?: string;
}) => {
  const param = role ? `&role=${role}` : '';

  const url = `${process.env.REACT_APP_API}/group/${groupId}/getArticleList?helpSettings=${helpSettings}${param}`;

  // Set cache options based on the useCache parameter
  const cacheOptions = useCache
    ? {
        ttl: 1000 * 60 * 5 // cache for 5 minutes
      }
    : false; // bypass cache

  return axiosCached.get(url, {
    headers: {
      orgId: orgId
    },
    cache: cacheOptions
  });
};

const updateBioData = ({ orgId, data }: { orgId: string; data: any }) => {
  // return axios({
  //   method: 'patch',
  //   url: `${process.env.REACT_APP_API}/updateBio`,
  //   headers: {
  //     orgId: orgId
  //   },
  //   data
  // });

  return axiosCached.patch(`${process.env.REACT_APP_API}/updateBio`, data, {
    headers: {
      orgId: orgId
    },
    cache: false
  });
};

const getTagsListnew = (
  orgId: string,
  location: string,
  type?: string | undefined,
  value?: string | undefined,
  isFullList?: boolean
) => {
  if (typeof isFullList === 'undefined') {
    isFullList = false;
  }
  if (typeof type === 'undefined') {
    type = '';
  }
  if (typeof value === 'undefined') {
    value = '';
  }

  // const url = `${process.env.REACT_APP_API}/getTagsList?type=${type}&value=${value}&isFullList=${isFullList}`;

  // return axios({
  //   method: 'get',

  //   url: `${process.env.REACT_APP_API}/getTagsList?type=${type}&value=${value}&isFullList=${isFullList}`,
  //   headers: {
  //     orgId: orgId,

  //     location: location
  //   }
  // });

  return axiosCached.get(
    `${process.env.REACT_APP_API}/getTagsList?type=${type}&value=${value}&isFullList=${isFullList}`,
    {
      headers: {
        orgId: orgId,
        location: location
      },
      cache: {
        ttl: 1000 * 30
      }
    }
  );
};

const getAllTopicsForUser = (location: string) => {
  return axiosCached.get(`${process.env.REACT_APP_API}/getAllTopicsForUser`, {
    headers: { location },
    cache: { ttl: 1000 * 60 * 15 }
  });
};

const getCommunityGroupsUsersListNew = (
  orgId: string,
  location: string,
  role?: string[],
  category?: string[],
  industry?: string[],
  hobbiesAndInterest?: string[],
  roleType?: string[]
  // useCache?:boolean
) => {
  // console.log('getCommunityGroupsUsersListNew called')
  let url = `${process.env.REACT_APP_API}/getCommunityGroupsUsersListNew`;

  if (role && role?.length > 0) {
    url += `?role=${role}`;
  }

  if (category && category?.length > 0) {
    if (role && role?.length > 0) {
      url += `&category=${category}`;
    } else {
      url += `?category=${category}`;
    }
  }

  if (hobbiesAndInterest && hobbiesAndInterest?.length > 0) {
    if (
      url.includes('role') ||
      url.includes('category') ||
      url.includes('industry') ||
      url.includes('roleType') ||
      url.includes('hobbiesAndInterest')
    ) {
      url += `&hobbiesAndInterest=${hobbiesAndInterest}`;
    } else {
      url += `?hobbiesAndInterest=${hobbiesAndInterest}`;
    }
  }

  if (industry && industry?.length > 0) {
    if (
      url.includes('role') ||
      url.includes('category') ||
      url.includes('hobbiesAndInterest') ||
      url.includes('roleType') ||
      url.includes('industry')
    ) {
      url += `&industry=${industry}`;
    } else {
      url += `?industry=${industry}`;
    }
  }

  if (roleType && roleType?.length > 0) {
    if (
      url.includes('role') ||
      url.includes('category') ||
      url.includes('industry') ||
      url.includes('hobbiesAndInterest') ||
      url.includes('roleType')
    ) {
      url += `&roleType=${roleType}`;
    } else {
      url += `?roleType=${roleType}`;
    }
  }

  // return axios({
  //   method: 'post',
  //   url: url,
  //   headers: {
  //     orgId: orgId,
  //     location: location
  //   }
  // });

  // const cacheOptions = useCache ? {
  //   ttl: 1000 * 60 * 15 // cache for 5 minutes
  // } : false; // bypass cache

  return axiosCached.post(
    url,
    {
      orgId: orgId,
      location: location
    },
    {
      cache: {
        methods: ['post'],
        ttl: 1000 * 60 * 15 // Setting ttl to 0 to disable caching
      }
      // cache: {ttl: 1000 * 60 * 15}ttl: 1000 * 60 * 15
    }
  );
};

const getCommunityGroupsUsersListNewDuplicate = ({ orgId, location }: { orgId: string; location: string }) => {
  let url = `${process.env.REACT_APP_API}/getCommunityGroupsUsersListNew`;

  return axiosCached.post(
    url,
    {
      orgId: orgId,
      location: location
    },
    {
      cache: false
    }
  );
};

const getAllArticleUser = ({
  orgId,
  tags,
  type,
  industries,
  helpSettings,
  useCache // Add this parameter to control caching
}: {
  orgId: string;
  tags?: string[];
  type?: string[];
  industries?: string[];
  helpSettings: boolean;
  useCache?: boolean; // Add this to the parameter type definition
}) => {
  let url = `${process.env.REACT_APP_API}/getLibraryArticlesList?helpSettings=${helpSettings}`;
  // Construct the URL with query parameters
  if (industries && industries.length > 0) {
    url += `&industries=${encodeURIComponent(industries.join(','))}`;
  }
  if (tags && tags.length > 0) {
    url += `&tags=${encodeURIComponent(tags.join(','))}`;
  }
  if (type && type.length > 0) {
    url += `&type=${encodeURIComponent(type.join(','))}`;
  }

  // Set cache options based on useCache parameter
  const cacheOptions = useCache
    ? {
        ttl: 1000 * 60 * 5 // cache for 5 minutes
      }
    : false; // bypass cache

  return axiosCached.get(url, {
    headers: {
      orgId: orgId
    },
    // cache: cacheOptions
    cache: {
      ttl: 1000 * 60 * 5 // cache for 5 minutes
    }
  });
};

const getOrganizations = (token: string, currentPageNumber: number, perPage: number) => {
  // return axios({
  //   method: 'get',
  //   // url: `${process.env.REACT_APP_API}/organizationsListGet?page=${currentPageNumber}&per_page=${perPage}`,
  //   url: `${process.env.REACT_APP_API}/organizationsListGet?page=0&per_page=100`,
  //   headers: {
  //     authorization: `Bearer ${token}`
  //   }
  // });

  return axiosCached.get(`${process.env.REACT_APP_API}/organizationsListGet?page=0&per_page=100`, {
    headers: {
      authorization: `Bearer ${token}`
    },
    cache: false
  });
};

const updateHobbies = ({
  orgId,
  data,
  idtoken,
  token
}: {
  orgId: string;
  idtoken: string;
  token: string;
  data: string[];
}) => {
  // return axios({
  //   method: 'post',
  //   url: `${process.env.REACT_APP_API}/createHobies`,
  //   headers: {
  //     orgId: orgId,
  //     idtoken: idtoken,
  //     token: token
  //   },
  //   data: { hobbies: data }
  // });

  return axiosCached.post(
    `${process.env.REACT_APP_API}/createHobies`,
    {
      hobbies: data
    },
    {
      headers: {
        orgId: orgId,
        idtoken: idtoken,
        token: token
      },
      cache: false
    }
  );
};

const getTagsList = (orgId: string, location: string) => {
  // return axios({
  //   method: 'get',
  //   url: `${process.env.REACT_APP_API}/getTagsList?type=Hobbies and Interests&isFullList=true`,
  //   headers: {
  //     orgId: orgId,
  //     location: location
  //   }
  // });

  return axiosCached.get(`${process.env.REACT_APP_API}/getTagsList`, {
    params: {
      type: 'Hobbies and Interests',
      isFullList: true
    },
    headers: {
      orgId: orgId,
      location: location
    },
    cache: {
      ttl: 1000 * 60 * 15
    }
  });
};

const getCommunityMembers = (orgId: string, location: string, role?: string, category?: string) => {
  let url = `${process.env.REACT_APP_API}/getCommunityGroupsUsersList`;

  if (role) {
    url += `?role=${role}`;
  }

  if (category) {
    if (role) {
      url += `&category=${category}`;
    } else {
      url += `?category=${category}`;
    }
  }

  return axios({
    method: 'POST',
    url: url,
    headers: {
      orgId: orgId,
      location: location
    }
  });
};

const removeOrganization = (token: string, orgId: string, dataLocation: string) => {
  return axios({
    method: 'delete',
    url: `${process.env.REACT_APP_API}/organizationDelete/${orgId}`,
    headers: {
      authorization: `Bearer ${token}`,
      location: dataLocation
    }
  });
};

const getUsersMetaByOrgId = (token: string, orgId: string) => {
  // return axios({
  //   method: 'get',
  //   url: `${process.env.REACT_APP_API}/getUsersWithMetadata/${orgId}`,
  //   headers: {
  //     orgId: orgId,
  //     authorization: `Bearer ${token}`
  //   }
  // });

  return axiosCached.get(`${process.env.REACT_APP_API}/getUsersWithMetadata/${orgId}`, {
    headers: {
      orgId: orgId,
      authorization: `Bearer ${token}`
    },
    cache: {
      ttl: 0 // Setting ttl to 0 to bypass caching
    }
  });
};

const getUsersByOrgId = (token: string, orgId: string) => {
  // return axios({
  //   method: 'get',
  //   url: `${process.env.REACT_APP_API}/getUsersByOrg/${orgId}`,

  //   headers: {
  //     authorization: `Bearer ${token}`,
  //     orgId: orgId !== '' ? orgId : null
  //   }
  // });

  return axiosCached.get(`${process.env.REACT_APP_API}/getUsersByOrg/${orgId}`, {
    headers: {
      authorization: `Bearer ${token}`,
      orgId: orgId !== '' ? orgId : null
    },
    cache: {
      ttl: 0 // Setting ttl to 0 to bypass caching
    }
  });
};

// const getMentorTask = ({ id }: { id: string }) => {
//   return axios({
//     method: 'get',
//     url: `${process.env.REACT_APP_API}/mentorTasks?id=${id}`
//   });
// };

const getMentorTask = ({ id }: { id?: any }) => {
  return axiosCached.get(`${process.env.REACT_APP_API}/mentorTasks?id=${id}`, {
    cache: {
      ttl: 0 // Set TTL to 0 for no caching
    }
  });
};

const saveMentorAnswers = ({
  groupId,
  levelId,
  taskId,
  ans
}: {
  groupId: any;
  levelId: any;
  taskId: any;
  ans: any;
}) => {
  // return axios({
  //   method: 'post',
  //   url: `${process.env.REACT_APP_API}/group/${groupId}/level/${levelId}/task/${taskId}/answers`,
  //   data: {
  //     ans
  //   }
  // });

  return axiosCached.post(
    `${process.env.REACT_APP_API}/group/${groupId}/level/${levelId}/task/${taskId}/answers`,
    {
      ans
    },
    {
      cache: {
        ttl: 0 // Setting ttl to 0 to bypass caching
      }
    }
  );
};

const saveMentorMultiAnswers = ({ groupId, tasks }: { groupId: any; tasks: any }) => {
  // return axios({
  //   method: 'post',
  //   url: `${process.env.REACT_APP_API}/group/${groupId}/multiAnswers`,
  //   data: {
  //     tasks: tasks
  //   }
  // });

  return axiosCached.post(
    `${process.env.REACT_APP_API}/group/${groupId}/multiAnswers`,
    {
      tasks
    },
    {
      cache: {
        ttl: 0 // Setting ttl to 0 to bypass caching
      }
    }
  );
};

const getUserDetailsById = (id: string) => {
  // return axios({
  //   method: 'GET',
  //   url: `${process.env.REACT_APP_API}/getUserDetailsById/${id}`,
  //   headers: {
  //     location: getUserDetails().location
  //     // idtoken:idtoken,
  //   }
  // });

  return axiosCached.get(`${process.env.REACT_APP_API}/getUserDetailsById/${id}`, {
    headers: {
      location: getUserDetails().location
      // idtoken: idtoken,
    },
    cache: false
  });
};

const getUserMetadData = (token: string, orgId: string) => {
  // return axios({
  //   method: 'get',
  //   url: `${process.env.REACT_APP_API}/createUserMetadata`,
  //   headers: {
  //     authorization: `Bearer ${token}`,
  //     orgId: orgId !== '' ? orgId : null
  //   }
  // });

  return axiosCached.get(`${process.env.REACT_APP_API}/createUserMetadata`, {
    headers: {
      authorization: `Bearer ${token}`,
      orgId: orgId !== '' ? orgId : null
    },
    cache: false // Disabling caching for this specific request
  });
};

const updateUserFtue = ({ ftue, userId }: { ftue: boolean; userId: string }) => {
  const body = {
    ftue
  };
  // return axios({
  //   method: 'put',
  //   url: `${process.env.REACT_APP_API}/${userId}/ftue`,
  //   data: body
  // });

  return axiosCached.put(`${process.env.REACT_APP_API}/${userId}/ftue`, body, { cache: false });
};

const getOrgName = (orgId: string) => {
  // return axios({
  //   method: 'get',
  //   url: `${process.env.REACT_APP_API}/organizationGetById/${orgId}`
  // });

  return axiosCached.get(`${process.env.REACT_APP_API}/organizationGetById/${orgId}`, {
    cache: false
    // cache: { ttl: 1000 * 60 * 5 }
  });
};

const addOrganization = (token: string, data: IOrgSubmitValues) => {
  const data2 = {
    display_name: data.name,
    // oid: data.orgId,
    location: data.dataLocation
  };
  // return axios({
  //   method: 'post',
  //   url: `${process.env.REACT_APP_API}/organizationCreate`,
  //   headers: {
  //     authorization: `Bearer ${token}`,
  //     location: data.dataLocation
  //   },
  //   data: data2
  // });

  return axiosCached.post(`${process.env.REACT_APP_API}/organizationCreate`, data2, {
    headers: {
      authorization: `Bearer ${token}`,
      location: data.dataLocation
    },
    cache: false
  });
};

// eslint-disable-next-line no-undef
const updateOrganization = (token: string, orgId: string, data: IOrgSubmitValues) => {
  // return axios({
  //   method: 'patch',
  //   url: `${process.env.REACT_APP_API}/organizationUpdate/${orgId}`,
  //   headers: {
  //     authorization: `Bearer ${token}`
  //   },
  //   data: data
  // });

  return axiosCached.patch(`${process.env.REACT_APP_API}/organizationUpdate/${orgId}`, data, {
    headers: {
      authorization: `Bearer ${token}`
    },
    cache: false
  });
};

// eslint-disable-next-line no-undef
const inviteOrgAdmin = (token: string, orgId: string, data: IInviteOrgAdmin) => {
  // return axios({
  //   method: 'POST',
  //   url: `${process.env.REACT_APP_API}/inviteOrgAdmin/${orgId}`,
  //   headers: {
  //     authorization: `Bearer ${token}`
  //   },
  //   data: data
  // });

  return axiosCached.post(`${process.env.REACT_APP_API}/inviteOrgAdmin/${orgId}`, data, {
    headers: {
      authorization: `Bearer ${token}`
    },
    cache: false
  });
};

// eslint-disable-next-line no-undef
const inviteMember = (token: string, orgId: string, data: IInviteOrgAdmin) => {
  // return axios({
  //   method: 'POST',
  //   url: `${process.env.REACT_APP_API}/inviteMembers/${orgId}`,
  //   headers: {
  //     authorization: `Bearer ${token}`
  //   },
  //   data: data
  // });

  return axiosCached.post(`${process.env.REACT_APP_API}/inviteMembers/${orgId}`, data, {
    headers: {
      authorization: `Bearer ${token}`
    },
    cache: false
  });
};
const getOrgId = (orgName: string) => {
  // return axios({
  //   method: 'get',
  //   url: `${process.env.REACT_APP_API}/organization/${orgName}`,
  //   headers: {}
  // });

  return axiosCached.get(`${process.env.REACT_APP_API}/organization/${orgName}`, {
    headers: {},
    cache: false
  });
};

// eslint-disable-next-line no-undef
const inviteApi = (token: string, orgId: string, data: IInviteOrgAdmin) => {
  // return axios({
  //   method: 'post',
  //   url: `${process.env.REACT_APP_API}/invitations/${orgId}`,
  //   headers: {
  //     authorization: `Bearer ${token}`,
  //     orgId: orgId
  //   },
  //   data: data
  // });

  return axiosCached.post(`${process.env.REACT_APP_API}/invitations/${orgId}`, data, {
    headers: {
      authorization: `Bearer ${token}`,
      orgId: orgId
    },
    cache: false
  });
};

// const getMemberRoles = (token:string, orgId: string, userId:string)=>{
//   return axios({
//     method:'GET',
//     url:`${process.env.REACT_APP_API}/getOrgMemRoles/${orgId}/${userId}`,
//     headers:{
//       authorization:`Bearer ${token}`
//     }
//   })
// }

const getMemberRoles = (token: string, orgId: string, userId: string) => {
  let url = `${process.env.REACT_APP_API}/user-role`;
  // return axios({
  //   method: 'GET',
  //   url,
  //   headers: {
  //     authorization: `Bearer ${token}`,
  //     orgId: orgId ? orgId : null
  //   }
  // });

  return axiosCached.get(url, {
    headers: {
      authorization: `Bearer ${token}`,
      orgId: orgId ? orgId : null
    },
    cache: false
  });
};

const getACSToken = ({ communicationId, tag }: { communicationId: string; tag: string }) => {
  // return axios({
  //   method: 'POST',
  //   url: `${process.env.REACT_APP_API}/azureToken`,
  //   data: {
  //     communicationId: communicationId,
  //     tag
  //   }
  // });

  return axiosCached.post(
    `${process.env.REACT_APP_API}/azureToken`,
    {
      communicationId: communicationId,
      tag
    },
    {
      cache: false
    }
  );
};

const getACSThreads = (acsToken: string, orgId: string) => {
  // return axios({
  //   method: 'POST',
  //   url: `${process.env.REACT_APP_API}/getChatThread`,
  //   headers: {
  //     orgId: orgId
  //   },
  //   data: {
  //     azureToken: acsToken
  //   }
  // });

  return axiosCached.post(
    `${process.env.REACT_APP_API}/getChatThread`,
    {
      azureToken: acsToken
    },
    {
      headers: {
        orgId: orgId
      },
      cache: false
    }
  );
};

const deleteACSThread = (acsToken: string, threadId: string, orgId: string) => {
  // return axios({
  //   method: 'DELETE',
  //   url: `${process.env.REACT_APP_API}/deleteChatThread`,
  //   headers: {
  //     orgId: orgId
  //   },
  //   data: {
  //     threadId: threadId,
  //     azureToken: acsToken
  //   }
  // });

  return axiosCached.delete(`${process.env.REACT_APP_API}/deleteChatThread`, {
    headers: {
      orgId: orgId
    },
    data: {
      threadId: threadId,
      azureToken: acsToken
    },
    cache: {
      ttl: 0
    }
  });
};

const deleteMsgCount = ({ threadId }: { threadId: any }) => {
  return axiosCached.delete(`${process.env.REACT_APP_API}/deleteThreadMsgCount?threadId=${threadId}`);
};

// const getACSOrgUsers = (orgId: string) => {
//   return axios({
//     method: "GET",
//     url: `${process.env.REACT_APP_API}/ACSusers/${orgId}`,
//     headers: {
//       orgId: orgId,
//     },
//   });
// };

const getACSOrgUsers = (
  orgId: string
  // location: string,
) => {
  // return axios({
  //   method: 'POST',
  //   url: `${process.env.REACT_APP_API}/getCommunityGroupsUsersList`,
  //   headers: {
  //     orgId: orgId,
  //     location: getUserDetails().location
  //   }
  // });

  return axiosCached.post(`${process.env.REACT_APP_API}/getCommunityGroupsUsersList`, null, {
    headers: {
      orgId: orgId,
      location: getUserDetails().location
    },
    cache: {
      ttl: 0
    }
  });
};

const createACSThread = (acsToken: string, data: any, orgId: string) => {
  // return axios({
  //   method: 'POST',
  //   url: `${process.env.REACT_APP_API}/createChatThread`,
  //   // headers: {
  //   //   orgid: orgId,
  //   // },
  //   data
  //   // data: {
  //   //   ...data,
  //   //   participants: [
  //   //     { id: data?.participants[0]?.id, displayName: data?.participants[0]?.id?.communicationUserId},
  //   //   ],
  //   //   azureToken: acsToken,
  //   // },
  // });

  return axiosCached.post(`${process.env.REACT_APP_API}/createChatThread`, data, {
    // Uncomment the following lines if you need to include additional headers
    // headers: {
    //   orgid: orgId,
    // },
    cache: {
      ttl: 0
    }
  });
};

const getACSThreadParticipants = (acsToken: string, threadId: string) => {
  // return axios({
  //   method: 'POST',
  //   url: `${process.env.REACT_APP_API}/getParticipantsFromThreads`,
  //   data: {
  //     threadId: threadId,
  //     azureToken: acsToken
  //   }
  // });

  return axiosCached.post(
    `${process.env.REACT_APP_API}/getParticipantsFromThreads`,
    {
      threadId: threadId,
      azureToken: acsToken
    },
    {
      cache: false
    }
  );
};

const sendMessageToACSThread = (
  acsToken: string,
  threadId: string,
  message: string,
  file: any,
  contentId: any,
  topic: string,
  displayNames: any
) => {
  const formData = new FormData();
  formData.append('threadId', threadId);
  formData.append('message', message);
  formData.append('file', file); // Assuming 'file' is a File object
  formData.append('contentId', contentId.toString());
  formData.append('azureToken', acsToken);
  formData.append('topic', topic);
  formData.append('displayNames', displayNames);
  // return axios({
  //   method: 'POST',
  //   url: `${process.env.REACT_APP_API}/sendMessages`,
  //   data: formData,
  //   headers: {
  //     'Content-Type': 'multipart/form-data'
  //   }
  // });

  return axiosCached.post(`${process.env.REACT_APP_API}/sendMessages`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    cache: false
  });
};

const readMessagesFromThreads = ({
  orgId,
  threadId,
  acsToken,
  idtoken
}: {
  orgId: string;
  threadId: string;
  acsToken: string;
  idtoken: string;
}) => {
  // return axios({
  //   method: 'GET',
  //   url: `${process.env.REACT_APP_API}/receiveMessages?azureToken=${acsToken}&threadId=${threadId}&pageSize=50&pageNumber=4`,
  //   headers: {
  //     orgId: orgId,
  //     idtoken: idtoken
  //   }
  // }).then((res) => {
  //   return _.reverse(_.get(res, 'data.recieved Messages', []));
  // });

  return axiosCached
    .get(`${process.env.REACT_APP_API}/receiveMessages`, {
      params: {
        azureToken: acsToken,
        threadId: threadId,
        pageSize: 50,
        pageNumber: 4
      },
      headers: {
        orgId: orgId,
        idtoken: idtoken
      },
      cache: false
    })
    .then((res) => {
      return _.reverse(_.get(res, 'data.recieved Messages', []));
    });
};

// const readMessagesFromThreadsByNextLink = ({ link, azureToken }: { link: string; azureToken: string }) => {
//   return fetch(`${link}`, {
//     headers: { authorization: `Bearer ${azureToken}` }
//   }).then((res) => res.json());
// };

// const readMessagesFromThreadsByNextLink = ({ link }: { link: string}) => {
//   return fetch(`${link}`).then((res) => res.json());
// };

const readMessagesFromThreadsByNextLink = ({ link }: { link: string }) => {
  // return axios.get(link).then((res) => res.data);

  return axiosCached
    .get(link, {
      cache: false
    })
    .then((res) => res.data);
};

const readMessagesFromThreadsByPage = ({
  threadId,
  acsToken,
  pageSize,
  pageNumber
}: {
  threadId: string;
  acsToken: string;
  pageSize: string;
  pageNumber: string;
}) => {
  // return axios({
  //   method: 'GET',
  //   url: `${process.env.REACT_APP_API}/receiveMessages?azureToken=${acsToken}&threadId=${threadId}&pageSize=${pageSize}&pageNumber=${pageNumber}`
  // }).then((res) => {
  //   return _.reverse(_.get(res, 'data.recieved Messages', []));
  // });

  return axiosCached
    .get(`${process.env.REACT_APP_API}/receiveMessages`, {
      params: {
        azureToken: acsToken,
        threadId: threadId,
        pageSize: pageSize,
        pageNumber: pageNumber
      },
      cache: false
    })
    .then((res) => {
      return _.reverse(_.get(res, 'data.recieved Messages', []));
    });
};

const addParticipantsToExistingChannel = ({ idtoken, orgId, data }: { idtoken: string; orgId: string; data: any }) => {
  // return axios({
  //   method: 'POST',
  //   url: `${process.env.REACT_APP_API}/addParticipants`,
  //   headers: {
  //     orgId: orgId,
  //     idtoken: idtoken
  //   },
  //   data: data
  // });

  return axiosCached.post(`${process.env.REACT_APP_API}/addParticipants`, data, {
    headers: {
      orgId: orgId,
      idtoken: idtoken
    },
    cache: false
  });
};

const updateGroupChatName = ({
  orgId,
  acsToken,
  topic,
  threadId
}: {
  orgId: string;
  acsToken: string;
  topic: string;
  threadId: string;
}) => {
  // return axios({
  //   method: 'patch',
  //   url: `${process.env.REACT_APP_API}/renameGroupChat`,
  //   headers: {
  //     orgId: orgId
  //   },
  //   data: {
  //     azureToken: acsToken,
  //     topic,
  //     threadId
  //   }
  // });

  return axiosCached.patch(
    `${process.env.REACT_APP_API}/renameGroupChat`,
    {
      azureToken: acsToken,
      topic,
      threadId
    },
    {
      headers: {
        orgId: orgId
      },
      cache: false
    }
  );
};

export const uploadGroupChatPic = async ({
  orgId,
  tokens,
  values,
  threadId
}: {
  orgId: string;
  tokens: {
    access_token: string;
    id_token: string;
  };
  values: {
    image: File[];
  };
  threadId: string;
}) => {
  const url = `${process.env.REACT_APP_API}/groupChatPicUpload`;
  const formData = new FormData();
  formData.append('image', values.image[0]);
  formData.append('threadId', threadId);
  const headers = {
    orgId: orgId,
    Authorization: `Bearer ${tokens.access_token}`,
    idtoken: tokens.id_token,
    location: 'us'
  };
  // const response = await axios.post(url, formData, { headers });
  const response = await axiosCached.post(url, formData, { headers, cache: false });

  return response.data;
};

// const removeParticipantFromThread = ({
//   orgId,
//   acsToken,
//   commId,
//   threadId,
// }: {
//   orgId: string;
//   acsToken: string;
//   commId: string;
//   threadId: string;
// }) => {
//   return axios({
//     method: "delete",
//     url: `${process.env.REACT_APP_API}/removeParticipantsFromThreads`,
//     headers: {
//       orgId: orgId,
//     },
//     data: {
//       azureToken: acsToken,
//       threadId,
//     },
//   });
// };
const removeParticipantFromThread = ({
  acsToken,
  threadId,
  communicationId,
  tag
}: {
  acsToken: string;
  communicationId: string;
  threadId: string;
  tag: string;
}) => {
  return axios({
    method: 'delete',
    url: `${process.env.REACT_APP_API}/removeParticipantsFromThreads`,

    data: {
      azureToken: acsToken,
      threadId,
      communicationId,
      tag
    }
  });
};

const editMessage = ({
  orgId,
  acsToken,
  messageId,
  content,
  file,
  threadId
}: {
  orgId: string;
  content: string;
  file: string;
  acsToken: string;
  messageId: string;
  threadId: string;
}) => {
  const formData = new FormData();
  formData.append('threadId', threadId);
  formData.append('messageId', messageId);
  formData.append('file', file);
  formData.append('content', content.toString());
  formData.append('azureToken', acsToken);
  // return axios({
  //   method: 'patch',
  //   url: `${process.env.REACT_APP_API}/updateChatMessage`,
  //   data: formData,
  //   headers: {
  //     'Content-Type': 'multipart/form-data'
  //   }
  // });
  return axiosCached.patch(`${process.env.REACT_APP_API}/updateChatMessage`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    cache: false
  });
};

const deleteMessage = ({
  orgId,
  acsToken,
  messageId,
  threadId,
  tag
}: {
  orgId: string;
  acsToken: string;
  messageId: string;
  threadId: string;
  tag: string;
}) => {
  return axios({
    method: 'delete',
    url: `${process.env.REACT_APP_API}/deleteChatMessage`,
    headers: {
      orgId: orgId
    },
    data: {
      azureToken: acsToken,
      messageId,
      threadId,
      tag
    }
  });
};

const getACSChatThreads = ({
  acsToken,
  orgId,
  tag,
  communicationId
}: {
  acsToken: string;
  orgId: string;
  tag: string;
  communicationId: string;
}) => {
  const requestData = {
    tag,
    communicationId
  };
  // return axios({
  //   method: 'GET',
  //   url: `${process.env.REACT_APP_API}/getChatThread?azureToken=${acsToken}`,

  //   headers: {
  //     orgId: orgId,

  //   },
  //   params: requestData,
  // }).then((res) => res?.data);

  // const requestData = {
  //   tag,
  //   communicationId
  // };

  return axiosCached
    .get(`${process.env.REACT_APP_API}/getChatThread`, {
      params: {
        azureToken: acsToken,
        ...requestData
      },
      headers: {
        orgId: orgId
      },
      cache: false
    })
    .then((res) => res?.data);
};

const createUserProfile = ({ data, orgId }: { data: FormData; orgId: string }) => {
  // return axios({
  //   method: 'post',
  //   url: `${process.env.REACT_APP_API}/createUserProfile`,
  //   headers: {
  //     orgId: orgId
  //   },
  //   data
  // });

  return axiosCached.post(`${process.env.REACT_APP_API}/createUserProfile`, data, {
    headers: {
      orgId: orgId
    },
    cache: false
  });
};

const createArticle = ({
  data,
  orgId,
  groupId,
  helpSettings
}: {
  data: FormData;
  orgId: string;
  groupId: string;
  helpSettings: boolean;
}) => {
  // return axios({
  //   method: 'post',
  //   url: `${process.env.REACT_APP_API}/group/${groupId}/createArticle?helpSettings=${helpSettings}`,
  //   headers: {
  //     orgId: orgId
  //   },
  //   data
  // });

  return axiosCached.post(
    `${process.env.REACT_APP_API}/group/${groupId}/createArticle?helpSettings=${helpSettings}`,
    data,
    {
      headers: {
        orgId: orgId
      },
      cache: false
    }
  );
};

// const getAllArticle = ({ orgId, groupId, helpSettings }: { orgId: string; groupId: string; helpSettings: boolean }) => {
// const url = `${process.env.REACT_APP_API}/group/${groupId}/getArticleList?helpSettings=${helpSettings}`;
// return axios({
//   method: 'get',
//   url: `${process.env.REACT_APP_API}/group/${groupId}/getArticleList?helpSettings=${helpSettings}`,
//   headers: {
//     orgId: orgId
//   }
// });

//   return axiosCached.post(
//     url,
//     {
//         orgId,
//         // location
//         // Add other headers if needed
//     },{
//       cache: {
//         ttl: 1000 * 60 * 15,
//         interpretHeader: false,
//         methods: ['post']
//       },
//       statusCheck: (status: any) =>
//           [200, 203, 300, 301, 302, 404, 405, 410, 414, 501, 500, 502, 503].includes(status)

//       } as any
//     // }

//   );
// };

const getAllArticleById = ({
  orgId,
  articleId,
  groupId,
  isContentSettings,
  articleLocation,
  useCache
}: {
  orgId: string;
  articleId: string;
  groupId?: string;
  isContentSettings: boolean;
  articleLocation: any;
  useCache?: boolean;
}) => {
  // return axios({
  //   method: 'get',
  //   url: `${process.env.REACT_APP_API}/getArticleById/${articleId}?isContentSettings=${isContentSettings}`,
  //   headers: {
  //     orgId: orgId,
  //     location: 'us'
  //   }
  // });

  // const url = `${process.env.REACT_APP_API}/getArticleById/${articleId}?isContentSettings=${isContentSettings}`;

  // return axiosCached.get(
  //   url,
  //   {
  //     headers: {
  //       location: getUserDetails().location,
  //       orgId: orgId,
  //     },
  //     cache: {
  //       ttl: 1000 * 60 * 5, // Set TTL to 0 for no caching
  //     }
  //   },
  // )

  // return axios({
  //   method: 'get',
  //   url: `${process.env.REACT_APP_API}/getArticleById/${articleId}?isContentSettings=${isContentSettings}&articleLocation=${articleLocation}`,
  //   headers: {
  //     orgId: orgId,
  //     location: 'us'
  //   }
  // });
  const cacheOptions = useCache
    ? {
        ttl: 1000 * 60 * 5 // cache for 5 minutes
      }
    : false; // bypass cache

  return axiosCached.get(
    `${process.env.REACT_APP_API}/getArticleByIdNew/${articleId}?isContentSettings=${isContentSettings}&articleLocation=${articleLocation}`,
    {
      headers: {
        orgId: orgId,
        location: 'us'
      },
      cache: false // Setting cache to false to disable caching
    }
  );
};

const updateInsightByArticleId = ({
  orgId,
  articleId,
  isContentSettings,
  articleLocation
}: {
  orgId: string;
  articleId: string;
  isContentSettings: boolean;
  articleLocation: any;
}) => {
  return axiosCached.post(
    `${process.env.REACT_APP_API}/updateInsightByArticleId/${articleId}?isContentSettings=${isContentSettings}&articleLocation=${articleLocation}`,
    {
      headers: {
        orgId: orgId,
        location: 'us'
      },
      cache: false // Setting cache to false to disable caching
    }
  );
};

const articleAction = async ({ orgId, articleId, data }: { orgId: string; articleId: string; data: object }) => {
  try {
    // const response = await axios({
    //   method: 'post',
    //   url: `${process.env.REACT_APP_API}/articleAction?articleId=${articleId}`,
    //   headers: {
    //     orgId: orgId,
    //     location: 'us'
    //   },
    //   data
    // });

    const response = await axiosCached.post(`${process.env.REACT_APP_API}/articleAction?articleId=${articleId}`, data, {
      headers: {
        orgId: orgId,
        location: 'us'
      },
      cache: false
    });
    return response;
  } catch (error) {
    throw error;
  }
};

const DeleteArticle = async ({ orgId, articleId, groupId }: { orgId: string; articleId: string; groupId: string }) => {
  try {
    const response = await axios({
      method: 'delete',
      url: `${process.env.REACT_APP_API}/group/${groupId}/deleteArticle?articleId=${articleId}`,
      headers: {
        orgId: orgId,
        location: 'us'
      }
    });
    return response;
  } catch (error) {
    throw error;
  }
};

const getMentorHelpfulTips = async ({ orgId }: { orgId: string }) => {
  // try {
  //   const response = await axios({
  //     method: 'get',
  //     url: `${process.env.REACT_APP_API}/getMentorHelpfulTips?helpSettings=false`,
  //     headers: {
  //       orgId: orgId,
  //       location: 'us'
  //     }
  //   });
  //   return response;
  // } catch (error) {
  //   throw error;
  // }

  try {
    const response = await axiosCached.get(`${process.env.REACT_APP_API}/getMentorHelpfulTips?helpSettings=false`, {
      headers: {
        orgId: orgId,
        location: 'us'
      },
      cache: {
        ttl: 1000 * 60 * 5
      }
    });
    return response;
  } catch (error) {
    throw error;
  }
};

const editArticle = async ({
  orgId,
  articleId,
  data,
  groupId,
  helpSettings,
  location
}: {
  orgId: string;
  articleId: string;
  data: FormData;
  groupId: string;
  helpSettings: boolean;
  location?: string;
}) => {
  try {
    // const response = await axios({
    //   method: 'patch',
    //   url: `${process.env.REACT_APP_API}/group/${groupId}/updateArticle?articleId=${articleId}&helpSettings=${helpSettings}`,
    //   headers: {
    //     orgId: orgId,
    //     location: 'us'
    //   },
    //   data
    // });

    const response = await axiosCached.patch(
      `${process.env.REACT_APP_API}/group/${groupId}/updateArticle?articleId=${articleId}&helpSettings=${helpSettings}&articleLocation=${location}`,
      data,
      {
        headers: {
          orgId: orgId,
          location: 'us'
        },
        cache: false
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

// Matches

const getListToAssignMatch = ({
  orgId,
  userId,
  grpId,
  data,
  isMatchingFields
}: {
  orgId: string;
  userId: string;
  grpId: string;
  data: object;
  isMatchingFields: boolean;
}) => {
  // return axios({
  //   method: 'post',
  //   url: `${process.env.REACT_APP_API}/${userId}/group/${grpId}/matches/recommendations?isMatchingFields=${isMatchingFields}`,
  //   headers: {
  //     orgId: orgId
  //   },
  //   data
  // });

  return axiosCached.post(
    `${process.env.REACT_APP_API}/${userId}/group/${grpId}/matches/recommendations?isMatchingFields=${isMatchingFields}`,
    data,
    {
      headers: {
        orgId: orgId
      },
      cache: false
    }
  );
};

const assignAMatch = ({ orgId, grpId, data }: { orgId: string; grpId: string; data: object }) => {
  // return axios({
  //   method: 'post',
  //   url: `${process.env.REACT_APP_API}/group/${grpId}/matches/assignAMatch`,
  //   headers: {
  //     orgId: orgId
  //   },
  //   data
  // });

  return axiosCached.post(`${process.env.REACT_APP_API}/group/${grpId}/matches/assignAMatch`, data, {
    headers: {
      orgId: orgId
    },
    cache: false
  });
};
const autoMatch = ({ orgId, grpId, data }: { orgId: string; grpId: string; data: object }) => {
  // return axios({
  //   method: 'post',
  //   url: `${process.env.REACT_APP_API}/group/${grpId}/matches/autoMatch`,
  //   headers: {
  //     orgId: orgId
  //   },
  //   data
  // });

  return axiosCached.post(`${process.env.REACT_APP_API}/group/${grpId}/matches/autoMatch`, data, {
    headers: {
      orgId: orgId
    },
    cache: false
  });
};

const confirmAllMatches = ({ orgId, grpId }: { orgId: string; grpId: string }) => {
  // return axios({
  //   method: 'post',
  //   url: `${process.env.REACT_APP_API}/group/${grpId}/confirmAllMatches`,
  //   headers: {
  //     orgId: orgId
  //   }
  // });

  return axiosCached.post(
    `${process.env.REACT_APP_API}/group/${grpId}/confirmAllMatches`,
    {},
    {
      headers: {
        orgId: orgId
      },
      cache: false // Setting cache to false to disable caching
    }
  );
};

const confirmMatch = ({ orgId, grpId, data }: { orgId: string; grpId: string; data: object }) => {
  // return axios({
  //   method: 'post',
  //   url: `${process.env.REACT_APP_API}/group/${grpId}/confirmMatch`,
  //   headers: {
  //     orgId: orgId
  //   },
  //   data
  // });

  return axiosCached.post(`${process.env.REACT_APP_API}/group/${grpId}/confirmMatch`, data, {
    headers: {
      orgId: orgId
    },
    cache: false // Setting cache to false to disable caching
  });
};

const getAllMatchesSetting = ({ orgId, grpId }: { orgId: string; grpId: string }) => {
  // return axios({
  //   method: 'get',
  //   url: `${process.env.REACT_APP_API}/group/${grpId}/getAllMatchesSetting`,
  //   headers: {
  //     orgId: orgId
  //   }
  // });

  return axiosCached.get(`${process.env.REACT_APP_API}/group/${grpId}/getAllMatchesSetting`, {
    headers: {
      orgId: orgId
    },
    cache: false // Setting cache to false to disable caching
  });
};

const removeMatch = ({ orgId, confirmMatchId, grpId }: { orgId: string; confirmMatchId: string; grpId: string }) => {
  // return axios({
  //   method: 'patch',
  //   url: `${process.env.REACT_APP_API}/group/${grpId}/endMatch/${confirmMatchId}`,
  //   headers: {
  //     orgId: orgId
  //   }
  // });

  return axiosCached.patch(`${process.env.REACT_APP_API}/group/${grpId}/endMatch/${confirmMatchId}`, null, {
    headers: {
      orgId: orgId
    },
    cache: false // Setting cache to false to disable caching
  });
};

const getMatchProfile = ({ orgId, confirmMatchId }: { orgId: string; confirmMatchId: string }) => {
  // return axios({
  //   method: 'get',
  //   url: `${process.env.REACT_APP_API}/getMatchProfile/${confirmMatchId}`,
  //   headers: {
  //     orgId: orgId
  //   }
  // });

  return axiosCached.get(`${process.env.REACT_APP_API}/getMatchProfile/${confirmMatchId}`, {
    headers: {
      orgId: orgId
    },
    cache: false // Setting cache to false to disable caching
  });
};

// -----------
const getUserProfile = () => {
  // return axios({
  //   method: 'GET',
  //   url: `${process.env.REACT_APP_API}/getUserProfile`
  // });

  return axiosCached.get(`${process.env.REACT_APP_API}/getUserProfile`, {
    cache: false
  });
};

const updateUserProfile = ({ data, orgId }: { data: FormData; orgId: string }) => {
  // return axios({
  //   method: 'patch',
  //   url: `${process.env.REACT_APP_API}/UpdateUserProfile`,
  //   headers: {
  //     orgId: orgId
  //   },
  //   data
  // });

  return axiosCached.patch(`${process.env.REACT_APP_API}/UpdateUserProfile`, data, {
    headers: {
      orgId: orgId
    },
    cache: false // Setting cache to false to disable caching
  });
};

const updateSocialLinks = ({ orgId, data }: { orgId: string; data: Social }) => {
  return axios({
    method: 'patch',
    url: `${process.env.REACT_APP_API}/updateSocialLinks`,
    headers: {
      orgId: orgId
    },
    data
  });
};

const updateDisability = ({ orgId, data }: { orgId: string; data: string[] }) => {
  // return axios({
  //   method: 'patch',
  //   url: `${process.env.REACT_APP_API}/updateUserDisability`,
  //   headers: {
  //     orgId: orgId
  //   },
  //   data: { disability: data }
  // });

  return axiosCached.patch(
    `${process.env.REACT_APP_API}/updateUserDisability`,
    { disability: data },
    {
      headers: {
        orgId: orgId
      },
      cache: false // Setting cache to false to disable caching
    }
  );
};

const getEducationAndSkills = ({ userId, location }: { userId: string; location: string }) => {
  // return axios({
  //   method: 'get',
  //   url: `${process.env.REACT_APP_API}/getUserEducation?userId=${userId}`,
  //   headers: {
  //     location
  //   }
  // });

  return axiosCached.get(`${process.env.REACT_APP_API}/getUserEducation?userId=${userId}`, {
    headers: {
      location
    },
    cache: false
  });
};

export interface Education1 {
  [key: string]: string | string[] | null;
  minor: string[] | null;
  major: string;
  graduation_date: string;
  university: string;
  department: string;
}

const addEducationAndSkills = ({
  userId,
  location,
  formData
}: {
  userId: string;
  location: string;
  formData: FieldValues;
}) => {
  // return axios({
  //   method: 'post',
  //   url: `${process.env.REACT_APP_API}/addUserEducation?userId=${userId}`,
  //   headers: {
  //     location
  //   },
  //   data: formData
  // });

  return axiosCached.post(`${process.env.REACT_APP_API}/addUserEducation?userId=${userId}`, formData, {
    headers: {
      location: location
    },
    cache: false // Setting cache to false to disable caching
  });
};

const updateEducationAndSkills = ({
  userId,
  location,
  formData,
  id
}: {
  userId: string;
  location: string;
  formData: Education1;
  id: string;
}) => {
  // return axios({
  //   method: 'patch',
  //   url: `${process.env.REACT_APP_API}/updateUserEducation?userId=${userId}&educationId=${id}`,
  //   headers: {
  //     location
  //   },
  //   data: formData
  // });

  return axiosCached.patch(
    `${process.env.REACT_APP_API}/updateUserEducation?userId=${userId}&educationId=${id}`,
    formData,
    {
      headers: {
        location: location
      },
      cache: false // Setting cache to false to disable caching
    }
  );
};

const deleteEducation = ({ userId, location, id }: { userId: string; location: string; id: string }) => {
  return axios({
    method: 'delete',
    url: `${process.env.REACT_APP_API}/deleteUserEducation?userId=${userId}&educationId=${id}`,
    headers: {
      location
    }
  });
};

// const getWorkHistory = ({ userId, location }: { userId: string; location: string }) => {
//   return axios({
//     method: 'get',
//     url: `${process.env.REACT_APP_API}/getUserWorkHistory?userId=${userId}`,
//     headers: {
//       location
//     }
//   });

// };

const getWorkHistory = ({ userId, location }: { userId: string; location: string }) => {
  return axiosCached.get(`${process.env.REACT_APP_API}/getUserWorkHistory?userId=${userId}`, {
    headers: {
      location
    },
    cache: false
  });
};

const deleteAllEducationAndWorkHistory = ({ userId, deleteType }: { userId: string; deleteType: string }) => {
  return axios({
    method: 'delete',
    url: `${process.env.REACT_APP_API}/deleteAllWorkHistoryAndEducation?userId=${userId}&deleteType=${deleteType}`
  });
};

const addWorkHistory = ({
  userId,
  location,
  formData
}: {
  userId: string;
  location: string;
  formData: IWorkHistory;
}) => {
  // return axios({
  //   method: 'post',
  //   url: `${process.env.REACT_APP_API}/addUserWorkHistory?userId=${userId}`,
  //   headers: {
  //     location
  //   },
  //   data: formData
  // });

  return axiosCached.post(`${process.env.REACT_APP_API}/addUserWorkHistory?userId=${userId}`, formData, {
    headers: {
      location: location
    },
    cache: false // Setting cache to false to disable caching
  });
};

const updateWorkHistory = ({
  userId,
  location,
  formData,
  id
}: {
  userId: string;
  location: string;
  formData: IWorkHistory;
  id: string;
}) => {
  // return axios({
  //   method: 'patch',
  //   url: `${process.env.REACT_APP_API}/updateUserWorkHistory?userId=${userId}&workHistoryId=${id}`,
  //   headers: {
  //     location
  //   },
  //   data: formData
  // });

  return axiosCached.patch(
    `${process.env.REACT_APP_API}/updateUserWorkHistory?userId=${userId}&workHistoryId=${id}`,
    formData,
    {
      headers: {
        location
      },
      cache: false
    }
  );
};

const deleteWorkHistory = ({ userId, location, id }: { userId: string; location: string; id: string }) => {
  // return axios({
  //   method: 'delete',
  //   url: `${process.env.REACT_APP_API}/deleteUserWorkHistory?userId=${userId}&workHistoryId=${id}`,
  //   headers: {
  //     location
  //   }
  // });

  return axiosCached.delete(`${process.env.REACT_APP_API}/deleteUserWorkHistory?userId=${userId}&workHistoryId=${id}`, {
    headers: {
      location
    },
    cache: false
  });
};

const getTags = (value?: string) => {
  if (typeof value === 'undefined') {
    value = '';
  }
  // return axios({
  //   method: 'get',
  //   url: `${process.env.REACT_APP_API}/getTagsList?value=${value}`,
  //   headers: {
  //     location: getUserDetails().location
  //   }
  // });

  return axiosCached.get(`${process.env.REACT_APP_API}/getTagsList?value=${value}`, {
    headers: {
      location: getUserDetails().location
    },
    cache: false
  });
};

const updateGroupPic = ({ orgId, location, formData }: { orgId: string; location: string; formData: FormData }) => {
  // return axios({
  //   method: 'post',
  //   url: `${process.env.REACT_APP_API}/groupChatPicUpload`,
  //   headers: {
  //     orgId,
  //     location
  //   },
  //   data: formData
  // });

  return axiosCached.post(`${process.env.REACT_APP_API}/groupChatPicUpload`, formData, {
    headers: {
      orgId,
      location
    },
    cache: false
  });
};

const addUserVideoUpload = ({ orgId, data }: { orgId: string; data: FormData }) => {
  return axiosCached.post(`${process.env.REACT_APP_API}/updateUserVideoIntro`, data, {
    headers: {
      orgId: orgId
    },
    cache: false
  });
};

const deleteVideoIntro = ({ userId }: { userId: string }) => {
  // return axios({
  //   method: 'delete',
  //   url: `${process.env.REACT_APP_API}/deleteVideoBio/${userId}`
  // });

  return axiosCached.delete(`${process.env.REACT_APP_API}/deleteVideoBio/${userId}`, {
    cache: false
  });
};

const getGroupByOrgId = (orgId: string) => {
  // return axios({
  //   method: 'get',
  //   url: `${process.env.REACT_APP_API}/group/org/${orgId}`,
  //   headers: {}
  // });

  return axiosCached.get(`${process.env.REACT_APP_API}/group/org/${orgId}`, {
    cache: false
  });
};

const getAllGroups = ({
  orgId,
  grpId,
  loc,
  useCache
}: {
  orgId: string;
  grpId: string;
  loc?: string;
  useCache?: boolean;
}) => {
  const url = `${process.env.REACT_APP_API}/groups/getAll`;
  // return axios({
  //   method: 'post',
  //   url: `${process.env.REACT_APP_API}/groups/getAll`,
  //   headers: { location: loc },
  //   data: {
  //     orgId,
  //     grpId,
  //     pagination: {
  //       pageIndex: 1,
  //       pageSize: 100,
  //       sort: { direction: 'asc', column: 'name' }
  //     }
  //   }
  // });
  const data = {
    orgId,
    grpId,
    pagination: {
      pageIndex: 1,
      pageSize: 100,
      sort: { direction: 'asc', column: 'name' }
    }
  };

  const cacheOptions = useCache
    ? {
        ttl: 1000 * 60 * 5 // cache for 5 minutes
      }
    : false; // bypass cache

  return axiosCached.post(url, data, {
    // headers: {
    //   location: loc
    // },
    cache: { ttl: 1000 * 60 * 5 }
  });
};

const getGroupsOverview = ({ orgId, grpId, loc }: { orgId: string; grpId: string; loc?: string }) => {
  const url = `${process.env.REACT_APP_API}/groups/overview`;
  // return axios({
  //   method: 'post',
  //   url: `${process.env.REACT_APP_API}/groups/overview`,
  //   headers: { location: loc },
  //   data: {
  //     grpId,
  //     orgId
  //   }
  // });

  return axiosCached.post(
    url,
    {
      data: {
        grpId,
        orgId
      }
    },
    {
      cache: false
    }
  );
};

const fetchMembersInsights = (grpId: string) => {
  // return axios({
  //   method: 'get',
  //   url: `${process.env.REACT_APP_API}/group/${grpId}/insights/metrics`,
  //   headers: {}
  // });
  return axiosCached.get(`${process.env.REACT_APP_API}/group/${grpId}/insights/metrics`, {
    headers: {},
    cache: {
      ttl: 1000 * 60 * 1
    }
  });
};

const fetchChartInsights = (grpId: string) => {
  // return axios({
  //   method: 'post',
  //   url: `${process.env.REACT_APP_API}/group/${grpId}/chartInsights`,
  //   headers: {}
  // });

  // return axios({
  //   method: 'post',
  //   url: `${process.env.REACT_APP_API}/group/${grpId}/chartInsights`,
  //   headers: {}
  // });

  return axiosCached.post(
    `${process.env.REACT_APP_API}/group/${grpId}/chartInsights`,
    {},
    {
      headers: {},
      cache: {
        ttl: 1000 * 60 * 1
      } // Setting cache to false to disable caching
    }
  );
};

const fetchInsightsMetricsCount = (grpId: string) => {
  // return axios({
  //   method: 'get',
  //   url: `${process.env.REACT_APP_API}/group/${grpId}/insights/metrics/count`,
  //   headers: {}
  // });

  return axiosCached.get(`${process.env.REACT_APP_API}/group/${grpId}/insights/metrics/count`, {
    headers: {},
    cache: {
      ttl: 1000 * 60 * 1
    } // Setting cache to false to disable caching
  });
};

const createLevel = ({ name, orgId, parent }: { name: string; orgId: string; parent: string }) => {
  // return axios({
  //   method: 'post',
  //   url: `${process.env.REACT_APP_API}/level/create`,
  //   headers: {},
  //   data: { orgId, name, parent }
  // });

  return axiosCached.post(
    `${process.env.REACT_APP_API}/level/create`,
    { orgId, name, parent },
    {
      cache: false
    }
  );
};

const createGroup = ({ name, orgId, parent }: { name: string; orgId: string; parent: string }) => {
  // return axios({
  //   method: 'post',
  //   url: `${process.env.REACT_APP_API}/group/create`,
  //   headers: {},
  //   data: { orgId, name, parent }
  // });

  return axiosCached.post(
    `${process.env.REACT_APP_API}/group/create`,
    { orgId, name, parent },
    {
      cache: false
    }
  );
};

const deleteGroup = (grpId: string) => {
  // return axios({
  //   method: 'delete',
  //   url: `${process.env.REACT_APP_API}/group/${grpId}`
  // });

  return axiosCached.delete(`${process.env.REACT_APP_API}/group/${grpId}`, {
    cache: false
  });
};

// const getGroupDetails = (grpId: string, loc?: string) => {
//   // return axios({
//   //   method: 'get',
//   //   headers: loc ? { location: loc } : {},
//   //   url: `${process.env.REACT_APP_API}/group/${grpId}`
//   // });

//   return axiosCached.get(`${process.env.REACT_APP_API}/group/${grpId}`, {
//     // headers: loc ? { location: loc } : {},
//     cache: {
//       ttl: 1000 * 1 * 1
//     } // Setting TTL to 0 to force fetching from the network
//   });

// };

const getGroupDetails = (grpId: string, loc?: string, useCache?: boolean) => {
  // Set the headers based on whether `loc` is provided
  const headers = loc ? { location: loc } : {};

  // Set cache options based on the useCache parameter
  const cacheOptions = useCache
    ? {
        ttl: 1000 * 60 * 15 // cache for 5 minutes
      }
    : { ttl: 1000 * 1 * 1 }; // bypass cache

  // const cacheOptions =  {
  //   ttl: 1000 * 60 * 15 // cache for 5 minutes
  // } // bypass cache

  return axiosCached.get(`${process.env.REACT_APP_API}/group/${grpId}`, {
    headers: headers,
    cache: cacheOptions
  });
};

const getGroupUsers = (grpId: string) => {
  // return axios({
  //   method: 'get',
  //   url: `${process.env.REACT_APP_API}/group/${grpId}/users/getAll`
  // });

  return axiosCached.get(`${process.env.REACT_APP_API}/group/${grpId}/users/getAll`, {
    cache: { ttl: 1000 * 1 * 1 }
  });
};

interface IGroupUserData {
  firstName: string;
  lastName: string;
  mobileNumber: number;
  role: string;
  email: string;
}

const inviteUserToAGroup = ({ formData, grpId, orgId }: { formData: IGroupUserData; grpId: string; orgId: string }) => {
  // return axios({
  //   method: 'post',
  //   url: `${process.env.REACT_APP_API}/${orgId}/group/${grpId}`,
  //   data: formData
  // });

  return axiosCached.post(`${process.env.REACT_APP_API}/${orgId}/group/${grpId}`, formData, {
    cache: false
  });
};

// For Bulk upload Files
const bulkFilesUpload = ({
  // tokens,
  orgId,
  grpId,
  data
}: {
  // tokens: { id_token: string };
  orgId: string;
  grpId: string;
  data: FormData;
}) => {
  // return axios({
  //   method: 'POST',
  //   url: `${process.env.REACT_APP_API}/${orgId}/group/${grpId}/bulkUpload`,
  //   headers: {},
  //   data
  // });

  return axiosCached.post(`${process.env.REACT_APP_API}/${orgId}/group/${grpId}/bulkUploadMembers`, data, {
    headers: {},
    cache: false
  });
};

// Basic Settings Page............//
interface IGroupBasicSettingsData {
  name: string;
  endDate: Date;
  programType: string;
  logo: File;
}

const addBasicSettings = ({ formData, grpId }: { formData: IGroupBasicSettingsData; grpId: string }) => {
  // return axios({
  //   method: 'post',
  //   url: `${process.env.REACT_APP_API}/group/${grpId}/basicSettings`,
  //   data: formData
  // });

  return axiosCached.post(`${process.env.REACT_APP_API}/group/${grpId}/basicSettings`, formData, {
    cache: false
  });
};

const removeUserFromGroup = ({ grpId, userId }: { grpId: string; userId: string }) => {
  // return axios({
  //   method: 'delete',
  //   url: `${process.env.REACT_APP_API}/group/${grpId}/removeParticipant/${userId}`
  // });

  return axiosCached.delete(`${process.env.REACT_APP_API}/group/${grpId}/removeParticipant/${userId}`, {
    cache: false
  });
};

const updateGroupInfo = ({ orgId, grpId, formData }: { orgId: string; grpId: string; formData: FormData }) => {
  // return axios({
  //   method: 'patch',
  //   url: `${process.env.REACT_APP_API}/group/${grpId}/basicSettings`,
  //   headers: {
  //     orgId
  //   },
  //   data: formData
  // });

  return axiosCached.patch(`${process.env.REACT_APP_API}/group/${grpId}/basicSettings`, formData, {
    headers: {
      orgId: orgId
    },
    cache: false
  });
};

const getAllMatches = ({ orgId, groupId }: { orgId: string; groupId: string }) => {
  // return axios({
  //   method: 'get',
  //   url: `${process.env.REACT_APP_API}/group/${groupId}/matches/getAll`,
  //   headers: {
  //     orgId: orgId
  //   }
  // });

  return axiosCached.get(`${process.env.REACT_APP_API}/group/${groupId}/matches/getAll`, {
    headers: {
      orgId: orgId
    },
    cache: false
  });
};
export interface IOptionalFields {
  gender: boolean;
  ethnicity: boolean;
  firstGenerationStudent: boolean;
  disabilityType: boolean;
  dob: boolean;
  country: boolean;
  workHistory: boolean;
  educationHistory: boolean;
  hobbiesInterests: boolean;
}

const updateOptionalFields = ({ grpId, data }: { grpId: string; data: any }) => {
  // return axios({
  //   method: 'post',
  //   url: `${process.env.REACT_APP_API}/group/${grpId}/optionalFields`,
  //   headers: {},
  //   data
  // });

  return axiosCached.post(`${process.env.REACT_APP_API}/group/${grpId}/optionalFields`, data, {
    headers: {},
    cache: false
  });
};

const updateAllMatches = ({ grpId, orgId, data }: { grpId: string; orgId: string; data: object }) => {
  // return axios({
  //   method: 'patch',
  //   url: `${process.env.REACT_APP_API}/group/${grpId}/updateMatchingSettings`,
  //   headers: {
  //     orgId: orgId
  //   },
  //   data
  // });

  return axiosCached.patch(`${process.env.REACT_APP_API}/group/${grpId}/updateMatchingSettings`, data, {
    headers: {
      orgId: orgId
    },
    cache: false
  });
};

const getAllMatchesSettings = ({ grpId, orgId }: { grpId: string; orgId: string }) => {
  // return axios({
  //   method: 'get',
  //   url: `${process.env.REACT_APP_API}/group/${grpId}/getAllMatchesSetting`,
  //   headers: {
  //     orgId: orgId
  //   }
  // });

  return axiosCached.get(`${process.env.REACT_APP_API}/group/${grpId}/getAllMatchesSetting`, {
    headers: {
      orgId: orgId
    },
    cache: false
  });
};

const revokeGroupInvitation = ({ orgId, invitedId }: { orgId: string; invitedId: string }) => {
  // return axios({
  //   method: 'delete',
  //   url: `${process.env.REACT_APP_API}/${orgId}/user/${invitedId}`,
  //   headers: {}
  // });

  return axiosCached.delete(`${process.env.REACT_APP_API}/${orgId}/user/${invitedId}`, {
    headers: {},
    cache: false
  });
};

const getUserProfileInAGroup = (grpId: string, userId: string) => {
  // return axios({
  //   method: 'get',
  //   url: `${process.env.REACT_APP_API}/group/${grpId}/user/${userId}`,

  //   headers: {
  //     // authorization: `Bearer ${token}`,
  //     // orgId: orgId !== "" ? orgId : null,
  //   }
  // });

  return axiosCached.get(`${process.env.REACT_APP_API}/group/${grpId}/user/${userId}`, {
    headers: {
      // authorization: `Bearer ${token}`,
      // orgId: orgId !== "" ? orgId : null,
    },
    cache: false
  });
};

const getAllGroupsByUserId = (userId: string) => {
  // return axios({
  //   method: 'get',
  //   url: `${process.env.REACT_APP_API}/getAllGroupsByUserId?userId=${userId}`,

  //   headers: {
  //     // authorization: `Bearer ${token}`,
  //     // orgId: orgId !== "" ? orgId : null,
  //   }
  // });

  return axiosCached.get(`${process.env.REACT_APP_API}/getAllGroupsByUserId?userId=${userId}`, {
    headers: {
      // authorization: `Bearer ${token}`,
      // orgId: orgId !== "" ? orgId : null,
    },
    cache: false
  });
};

const getUserCommunityPeersList = (orgId: string, location: string) => {
  // return axios({
  //   method: 'get',
  //   url: `${process.env.REACT_APP_API}/getUserCommunityPeersList`,
  //   headers: {
  //     // authorization: `Bearer ${token}`,
  //     orgId: orgId,
  //     location: location
  //   }
  // });

  return axiosCached.get(`${process.env.REACT_APP_API}/getUserCommunityPeersList`, {
    headers: {
      // authorization: `Bearer ${token}`,
      orgId: orgId,
      location: location
    },
    cache: false
  });
};

const getMentorsPeersHomePage = (orgId: string, location: string, groupId: string) => {
  // return axios({
  //     method: 'get',
  //     url: `${process.env.REACT_APP_API}/group/${groupId}/mentorsPeers`,
  //     headers: {
  //       // authorization: `Bearer ${token}`,
  //       orgId: orgId,
  //       location: location
  //     }
  //   });
  return axiosCached.get(`${process.env.REACT_APP_API}/group/${groupId}/mentorsPeers`, {
    headers: {
      orgId: orgId,
      location: location
      // Add other headers if needed
    },
    cache: {
      ttl: 1000 * 60 * 5 // Set TTL to 0 for no caching
    }
  });
};

const getMenteesPeersHomePage = (orgId: string, location: string, groupId: string) => {
  return axiosCached.get(`${process.env.REACT_APP_API}/group/${groupId}/MenteesPeers`, {
    headers: {
      orgId: orgId,
      location: location
      // Add other headers if needed
    },
    cache: {
      ttl: 1000 * 1 * 1 // Set TTL to 0 for no caching
    }
  });
};

const getMenteeInsights = (orgId: string, location: string, groupId: string) => {
  return axiosCached.get(`${process.env.REACT_APP_API}/group/${groupId}/menteeInsights`, {
    headers: {
      orgId: orgId,
      location: location
      // Add other headers if needed
    },
    cache: false
    // cache: {
    //   ttl: 1000 * 1 * 1 // Set TTL to 0 for no caching
    // }
  });
};

const getMyMentorsForTask = ({
  orgId,
  groupId,
  level,
  task
}: {
  orgId: string;
  groupId: string;
  level: string;
  task: string;
}) => {
  // return axios({
  //   method: 'get',
  //   url: `${process.env.REACT_APP_API}/group/${groupId}/level/${level}/task/${task}/mentors`,
  //   headers: {
  //     orgId: orgId
  //   }
  // });

  return axiosCached.get(`${process.env.REACT_APP_API}/group/${groupId}/level/${level}/task/${task}/mentors`, {
    headers: {
      orgId: orgId
    },
    cache: {
      ttl: 1000 * 1 * 1 // Set TTL to 0 for no caching
    }
  });
};

const getMyMentors = ({ orgId, groupId }: { orgId: string; groupId: string }) => {
  // return axios({
  //   method: 'get',
  //   url: `${process.env.REACT_APP_API}/group/${groupId}/myMentors`,
  //   headers: {
  //     orgId: orgId
  //   }
  // });
  return axiosCached.get(`${process.env.REACT_APP_API}/group/${groupId}/myMentors`, {
    headers: {
      orgId: orgId
      // Add other headers if needed
    },
    cache: false
  });
};

const getMyMentees = ({ orgId, groupId }: { orgId: string; groupId: string }) => {
  // return axios({
  //   method: 'get',
  //   url: `${process.env.REACT_APP_API}/group/${groupId}/myMentees`,
  //   headers: {
  //     orgId: orgId
  //   }
  // });
  return axiosCached.get(`${process.env.REACT_APP_API}/group/${groupId}/myMentees`, {
    headers: {
      orgId: orgId
      // Add other headers if needed
    },
    cache: false
  });
};

const updateOrganizationSettings = ({ orgId, formData }: { orgId: string; formData: FormData }) => {
  // return axios({
  //   method: 'POST',
  //   url: `${process.env.REACT_APP_API}/org/${orgId}/settings`,
  //   headers: {},
  //   data: formData
  // });

  return axiosCached.post(`${process.env.REACT_APP_API}/org/${orgId}/settings`, formData, { cache: false });
};

const saveAnswers = ({
  groupId,
  levelId,
  taskId,
  menteeAsmtAnswer,
  answers,
  cancelToken
}: {
  groupId: string;
  levelId: string;
  taskId: string;
  menteeAsmtAnswer: string;
  answers: any;
  cancelToken: any;
}) => {
  // return axios({
  //   method: 'POST',
  //   url: `${process.env.REACT_APP_API}/group/${groupId}/level/${levelId}/task/${taskId}/saveAnswers`,
  //   data: {
  //     menteeAsmtAnswer,
  //     menteeAns
  //   },
  //   cancelToken: cancelToken
  // });

  return axiosCached.post(
    `${process.env.REACT_APP_API}/group/${groupId}/level/${levelId}/task/${taskId}/saveAnswers`,
    {
      menteeAsmtAnswer,
      answers
    },
    {
      cancelToken: cancelToken,
      cache: false // Setting cache to false to disable caching
    }
  );
};

const getAllTasks = ({ grpId, userId = '' }: { grpId: string; userId?: string }) => {
  // console.log('called from index');
  // return axios.get(userId
  //   ? `${process.env.REACT_APP_API}/group/${grpId}/getAllTasks?userId=${userId}`
  //   : `${process.env.REACT_APP_API}/group/${grpId}/getAllTasks`
  // );

  return axiosCached.get(
    userId
      ? `${process.env.REACT_APP_API}/group/${grpId}/getAllTasks?userId=${userId}`
      : `${process.env.REACT_APP_API}/group/${grpId}/getAllTasks`,
    { cache: false }
  );
};

const getTask = ({
  groupId,
  levelId,
  taskId,
  userId
}: {
  groupId: string;
  levelId: string;
  taskId: string;
  userId: string;
}) => {
  // return axios({
  //   method: 'get',
  //   url: `${process.env.REACT_APP_API}/group/${groupId}/level/${levelId}/task/${taskId}?userId=${userId}`
  // });

  return axiosCached.get(
    `${process.env.REACT_APP_API}/group/${groupId}/level/${levelId}/task/${taskId}?userId=${userId}`,
    { cache: false }
  );
};

const getAllTemplates = ({ grpId }: { grpId: string }) => {
  // return axios({
  //   method: 'get',
  //   url: `${process.env.REACT_APP_API}/group/${grpId}/getAllTemplates`
  // });
  return axiosCached.get(`${process.env.REACT_APP_API}/group/${grpId}/getAllTemplates`, { cache: false });
};

const getAllLevels = ({ grpId, goalId }: { grpId: string; goalId: string }) => {
  // return axios({
  //   method: 'get',
  //   url: `${process.env.REACT_APP_API}/group/${grpId}/template/${goalId}/getAllLevels`
  // });

  return axiosCached.get(`${process.env.REACT_APP_API}/group/${grpId}/template/${goalId}/getAllLevels`, {
    cache: false
  });
};

export interface IGoalData {
  programType: string;
  programKPIS: string;
  goalName: string;
  new: boolean;
}

const createGoal = ({ grpId, goalData }: { grpId: string; goalData: IGoalData }) => {
  // return axios({
  //   method: 'post',
  //   url: `${process.env.REACT_APP_API}/group/${grpId}/createGoal`,
  //   data: goalData
  // });

  return axiosCached.post(`${process.env.REACT_APP_API}/group/${grpId}/createGoal`, goalData, { cache: false });
};

const createCall = (callData: any, orgId: string, location: string, currentUserId: string) => {
  // return axios({
  //   method: 'post',
  //   url: `${process.env.REACT_APP_API}/scheduleCall?userId=${currentUserId}`,
  //   headers: {
  //     orgId: orgId,
  //     location: location
  //   },
  //   data: callData
  // });

  return axiosCached.post(`${process.env.REACT_APP_API}/scheduleCall?userId=${currentUserId}`, callData, {
    headers: {
      orgId: orgId,
      location: location
    },
    cache: false
  });
};

const getScheduleCall = async (
  user_id: string,
  userCommunicationId: string,
  orgId: string,
  location: string,
  page: number,
  pageSize: number,
  useCache?: boolean
) => {
  // return axios({
  //   method: 'get',
  //   url: `${process.env.REACT_APP_API}/getScheduleCall?userId=${user_id}&communicationId=${userCommunicationId}&page=${page}&pageSize=${pageSize}`,
  //   headers: {
  //     orgId,
  //     location
  //   }
  // });

  // Set cache options based on the useCache parameter
  const cacheOptions = useCache
    ? {
        ttl: 1000 * 60 * 5 // cache for 5 minutes
      }
    : false; // bypass cache

  return axiosCached.get(
    `${process.env.REACT_APP_API}/getScheduleCall?userId=${user_id}&communicationId=${userCommunicationId}&page=${page}&pageSize=${pageSize}`,
    {
      headers: {
        orgId,
        location
      },
      // cache: cacheOptions
      cache: { ttl: 1000 * 2 * 1 }
    }
  );
};

const cancelScheduleCall = async (
  userId: string,
  meetingId: string,
  orgId: string,
  location: string,
  callCancelReason: any
) => {
  // return axios({
  //   method: 'post',
  //   url: `${process.env.REACT_APP_API}/cancelCall?userId=${userId}&meetingId=${meetingId}`,
  //   headers: {
  //     orgId,
  //     location
  //   },
  //   data: callCancelReason
  // });

  return axiosCached.post(
    `${process.env.REACT_APP_API}/cancelCall?userId=${userId}&meetingId=${meetingId}`,
    callCancelReason,
    {
      headers: {
        orgId,
        location
      },
      cache: false
    }
  );
};

const rejectScheduleCall = async (
  userId: string,
  meetingId: string,
  orgId: string,
  location: string,
  callRejectReason: any
) => {
  // return axios({
  //   method: 'post',
  //   url: `${process.env.REACT_APP_API}/rejectCall?userId=${userId}&meetingId=${meetingId}`,
  //   headers: {
  //     orgId,
  //     location
  //   },
  //   data: callRejectReason
  // });

  return axiosCached.post(
    `${process.env.REACT_APP_API}/rejectCall?userId=${userId}&meetingId=${meetingId}`,
    callRejectReason,
    {
      headers: {
        orgId,
        location
      },
      cache: false
    }
  );
};

const groupsListForUser = async (orgId: string) => {
  // return axios({
  //   method: 'get',
  //   url: `${process.env.REACT_APP_API}/groupsListForUser`,
  //   headers: {
  //     orgId
  //   }
  // });

  return axiosCached.get(`${process.env.REACT_APP_API}/groupsListForUser`, {
    headers: {
      orgId: orgId
    },
    cache: {
      ttl: 1000 * 60 * 5
    }
  });
};

// const updateLastseen = (data: { id: string; name: string; role: string } | {}) => {
//
// };

const updateLastseen = (data: { id: string; name: string; role: string } | {}) => {
  const { orgId, location } = getUserDetails();
  const url = `${process.env.REACT_APP_API}/groups/userInProgram`;

  // const { orgId } = getUserDetails();

  // return axios({
  //   method: 'post',
  //   url: `${process.env.REACT_APP_API}/groups/userInProgram`,
  //   headers: {
  //     orgId
  //   },
  //   data
  // });

  return axiosCached.post(`${process.env.REACT_APP_API}/groups/userInProgram`, data, {
    headers: {
      orgId
    },
    cache: false
  });
};

const checkCallParticipant = async (callMeetingId: string, currentUserId: string, orgId: string, location: string) => {
  // return axios({
  //   method: 'get',
  //   url: `${process.env.REACT_APP_API}/checkCallParticipant?meetingId=${callMeetingId}&userId=${currentUserId}`,
  //   headers: {
  //     orgId,
  //     location
  //   }
  // });

  return axiosCached.get(
    `${process.env.REACT_APP_API}/checkCallParticipant?meetingId=${callMeetingId}&userId=${currentUserId}`,
    {
      headers: {
        orgId,
        location
      },
      cache: false
    }
  );
};

const rejoinCallUpdateFlag = (userId: string, meetingId: string, orgId: string, location: string) => {
  // return axios({
  //   method: 'patch',
  //   url: `${process.env.REACT_APP_API}/updateCallRejoinStatus?meetingId=${meetingId}&userId=${userId}`,
  //   headers: {
  //     orgId: orgId,
  //     location: location
  //   }
  // });
  return axiosCached.patch(
    `${process.env.REACT_APP_API}/updateCallRejoinStatus?meetingId=${meetingId}&userId=${userId}`,
    null,
    {
      headers: {
        orgId: orgId,
        location: location
      },
      cache: false
    }
  );
};

// For goal upload Files
const goalFilesUpload = ({ orgId, grpId, data }: { orgId: string; grpId: string; data: FormData }) => {
  // return axios({
  //   method: 'POST',
  //   url: `${process.env.REACT_APP_API}/group/${grpId}/goalUpload`,
  //   headers: {
  //     orgId: orgId
  //   },
  //   data
  // });

  return axiosCached.post(`${process.env.REACT_APP_API}/group/${grpId}/goalUpload`, data, {
    headers: {
      orgId: orgId
    },
    cache: false
  });
};

const saveConcernWithEmail = ({ reasonOfConcern }: { reasonOfConcern: string }) => {
  const formData = new FormData();
  formData.append('reasonOfConcern', reasonOfConcern);
  // return axios({
  //   method: 'post',
  //   url: `${process.env.REACT_APP_API}/raisedConcernEmail`,
  //   data: formData
  // });
  return axiosCached.post(`${process.env.REACT_APP_API}/raisedConcernEmail`, formData, { cache: false });
};

const activateUser = ({ grpId, userId, isActive }: { grpId: string; userId: string; isActive: boolean }) => {
  // return axios({
  //   method: 'POST',
  //   url: `${process.env.REACT_APP_API}/group/${grpId}/user/${userId}/userToggle?activation=${isActive}`
  // });

  return axiosCached.post(
    `${process.env.REACT_APP_API}/group/${grpId}/user/${userId}/userToggle?activation=${isActive}`,
    null,
    { cache: false }
  );
};

const saveAIMentorQuiz = ({ answers }: any) => {
  // return axios({
  //   method: 'POST',
  //   url: `${process.env.REACT_APP_API}/AIMentorQuiz`,
  //   data: answers
  // });

  return axiosCached.post(`${process.env.REACT_APP_API}/AIMentorQuiz`, answers, {
    cache: false // Setting cache to false to disable caching
  });
};

const saveArticleDescriptionFiles = (data: any) => {
  // return axios({
  //   method: 'POST',
  //   url: `${process.env.REACT_APP_API}/articleDescriptionFiles`,
  //   data
  // });

  return axiosCached.post(`${process.env.REACT_APP_API}/articleDescriptionFiles`, data, { cache: false });
};
const saveTaskDescriptionFiles = (data: any) => {
  // return axios({
  //   method: 'POST',
  //   url: `${process.env.REACT_APP_API}/taskDescriptionFiles`,
  //   data
  // });

  return axiosCached.post(`${process.env.REACT_APP_API}/taskDescriptionFiles`, data, { cache: false });
};

const deleteDescriptionFile = (fileName: any, descriptionkey: 'task' | 'article', location: any) => {
  return axiosCached.delete(`${process.env.REACT_APP_API}/removeDescriptionFiles`, {
    headers: {
      location: location
    },
    data: {
      descriptionkey,
      fileName
    },
    cache: false
  });
};
const saveVotevalue = (data: any, location: any) => {
  // return axios({
  //   method: 'POST',
  //   url: `${process.env.REACT_APP_API}/addUserProfileUpvoteAndDownvote`,
  //   headers: {
  //     location: location
  //   },
  //   data
  // });

  return axiosCached.post(`${process.env.REACT_APP_API}/addUserProfileUpvoteAndDownvote`, data, {
    headers: {
      location: location
    },
    cache: false
  });
};

const getChatUsers = (query: string) => {
  // return axios({
  //   method: 'get',
  //   url: `${process.env.REACT_APP_API}/getAllUsersForChat?searchTerm=${query}`
  // });

  return axiosCached.get(`${process.env.REACT_APP_API}/getAllUsersForChat`, {
    params: {
      searchTerm: query
    },
    cache: false
  });
};

// AI Mentor API's Services

const getPrompts = () => {
  // return axios({
  //   method: 'get',
  //   url: `${process.env.REACT_APP_API}/getPrompts`
  // });

  return axiosCached.get(`${process.env.REACT_APP_API}/getPrompts`, { cache: false });
};

const newConversationHashtreeFalse = (data: any) => {
  // return axios({
  //   method: 'post',
  //   url: `${process.env.REACT_APP_API}/chat/new/from-prompt`,
  //   data
  // });

  return axiosCached.post(`${process.env.REACT_APP_API}/chat/new/from-prompt`, data, {
    cache: false
  });
};

// const response = await newConversationHashtreeFalse({
//   promptID: 3,
// });

const newConversationHashtreeTrue = (promptID: any) => {
  // return axios({
  //   method: 'get',
  //   url: `${process.env.REACT_APP_API}/prompts/${promptID}/tree`
  // });

  return axiosCached.get(`${process.env.REACT_APP_API}/prompts/${promptID}/tree`, { cache: false });
};

const aiSendMessage = (data: any) => {
  // return axios({
  //   method: 'get',
  //   url: `${process.env.REACT_APP_API}/aiSendMessage`,
  //   data
  // });

  // return axios({
  //   method: 'post',
  //   url: `${process.env.REACT_APP_API}/aiSendMessage`,
  //   data
  // })

  return axiosCached.post(`${process.env.REACT_APP_API}/aiSendMessage`, data, {
    cache: false
  });
};
// {
//   "conversationID" : 47
// }

const getConversationPreviews = () => {
  // return axios({
  //   method: 'get',
  //   url: `${process.env.REACT_APP_API}/getConversationPreviews`
  // });

  return axiosCached.get(`${process.env.REACT_APP_API}/getConversationPreviews`, {
    cache: false // Setting cache to false to disable caching
  });
};

const newConversationFromGeneral = (data: any) => {
  // return axios({
  //   method: 'post',
  //   url: `${process.env.REACT_APP_API}/newConversationFromGeneral`,
  //   data
  // });

  return axiosCached.post(`${process.env.REACT_APP_API}/newConversationFromGeneral`, data, {
    cache: {
      ttl: 0 // Setting ttl to 0 to bypass caching
    }
  });
};

// {
//   "message" : "Give me Collages list indian"
// }

// const conversationFromTree = (data: any) => {
//   console.log('data',data,typeof(data.promptID),typeof(data.selectedOptions));

//   return axios({
//     method: 'get',
//     url: `${process.env.REACT_APP_API}/conversationFromTree`,
//     data
//   });
// };

// const conversationFromTree = (data: any) => {
//   console.log('data', data, typeof(data.promptID), typeof(data.selectedOptions));

//   // Use `params` instead of `data` for a GET request
//   return axios({
//     method: 'get',
//     url: `${process.env.REACT_APP_API}/conversationFromTree`,
//     data: JSON.stringify(data),
//   });
// };

const conversationFromTree = ({
  tokens,
  orgId,
  data
}: {
  tokens: {
    access_token: string;
    id_token: string;
  };
  orgId: string;
  data: any;
}) => {
  // return fetch(`${process.env.REACT_APP_API}/conversationFromTree`, {
  //   method: 'POST', // Use POST method
  //   body: JSON.stringify(data),
  //   headers: {
  //     Authorization: `Bearer ${tokens.access_token}`,
  //     idtoken: tokens.id_token,
  //     orgId: orgId
  //   },
  // })
  //   .then(response => response.json())
  //   .then(data => {
  //     console.log('Success:', data);
  //     return data;
  //   })
  //   .catch((error) => {
  //     console.error('Error:', error);
  //     throw error;
  //   });

  return axiosCached
    .post(`${process.env.REACT_APP_API}/conversationFromTree`, data, {
      headers: {
        Authorization: `Bearer ${tokens.access_token}`,
        idtoken: tokens.id_token,
        orgId: orgId
      },
      cache: {
        ttl: 0 // Setting ttl to 0 to bypass caching
      }
    })
    .then((response) => {
      // response.data contains the parsed response body
      // console.log('Success:', response.data);
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

// const response = await getConversationFromTree({
//   promptID: 2,
//   selectedOptions: [1, 2, 3],
// });

//Response =>
// {
//   "success": true,
//   "id": 136
// }

// we need to  dispatch(setCurrentConversationID(id));

const getConversationById = (conversatioID: any) => {
  // return axios({
  //   method: 'get',
  //   url: `${process.env.REACT_APP_API}/chat/${conversatioID}`
  // });

  return axiosCached.get(`${process.env.REACT_APP_API}/chat/${conversatioID}`, { cache: false });
};

const updateBio = ({
  tokens,
  orgId,
  data
}: {
  tokens: {
    access_token: string;
    id_token: string;
  };
  orgId: string;
  data: any;
}) => {
  // return axios({
  //   method: 'post',
  //   url: `${process.env.REACT_APP_API}/updateBio`,
  //   headers: {
  //     Authorization: `Bearer ${tokens.access_token}`,
  //     idtoken: tokens.id_token,
  //     orgId: orgId
  //   },
  //   data
  // });

  return axiosCached.post(`${process.env.REACT_APP_API}/updateBio`, data, {
    headers: {
      Authorization: `Bearer ${tokens.access_token}`,
      idtoken: tokens.id_token,
      orgId: orgId
    },
    cache: false
  });
};

const createTask = (taskData: any, location: string, groupId: any, userId?: string) => {
  const urlPart = userId ? `?userId=${userId}` : ``;
  return axios({
    method: 'post',
    url: `${process.env.REACT_APP_API}/group/${groupId}/createLevel` + urlPart,
    headers: {
      location: location
    },
    data: taskData
  });

  // return axiosCached.post(`${process.env.REACT_APP_API}/scheduleCall?userId=${currentUserId}`, callData, {
  //   headers: {
  //     orgId: orgId,
  //     location: location
  //   },
  //   cache: false
  // });
};

const editTask = (taskData: any, location: string, groupId: any, levelId: any, userId?: string) => {
  const urlPart = userId ? `?userId=${userId}` : ``;
  return axios({
    method: 'post',
    url: `${process.env.REACT_APP_API}/group/${groupId}/level/${levelId}/updateLevel` + urlPart,
    headers: {
      location: location
    },
    data: taskData
  });
};

const addTaskToLevel = (taskData: any, location: string, groupId: any, levelId: any) => {
  return axios({
    method: 'post',
    url: `${process.env.REACT_APP_API}/group/${groupId}/level/${levelId}/createTask`,
    headers: {
      location: location
    },
    data: taskData
  });
};

const goalLevelDelete = (groupId: any, location: string, levelId: any, userId?: string) => {
  const urlPart = userId ? `?userId=${userId}` : ``;
  return axios({
    method: 'delete',
    url: `${process.env.REACT_APP_API}/group/${groupId}/level/${levelId}/deleteLevel` + urlPart,
    headers: {
      location: location
    }
  });
};

const goalTaskDelete = (groupId: any, location: string, levelId: any, taskId: any, userId?: string) => {
  const urlPart = userId ? `?userId=${userId}` : ``;
  return axios({
    method: 'delete',
    url: `${process.env.REACT_APP_API}/group/${groupId}/level/${levelId}/task/${taskId}/deleteTask` + urlPart,
    headers: {
      location: location
    }
  });
};

const goalTaskBulkDelete = (groupId: any, location: string, levelId: any, taskIds: any, userId?: string) => {
  const urlPart = userId ? `?userId=${userId}` : ``;
  return axios({
    method: 'POST',
    url: `${process.env.REACT_APP_API}/group/${groupId}/level/${levelId}/deleteTask` + urlPart,
    headers: {
      location: location
    },
    data: { tasks: taskIds }
  });
};

const updateTopics = ({ grpId, orgId, data }: { grpId: string; orgId: string; data: object }) => {
  // return axios({
  //   method: 'patch',
  //   url: `${process.env.REACT_APP_API}/group/${grpId}/updateMatchingSettings`,
  //   headers: {
  //     orgId: orgId
  //   },
  //   data
  // });

  return axiosCached.post(`${process.env.REACT_APP_API}/group/${grpId}/topics`, data, {
    headers: {
      orgId: orgId
    },
    cache: false
  });
};

const saveTopicsForUser = ({ orgId, data }: { orgId: string; data: object }) => {
  return axiosCached.post(`${process.env.REACT_APP_API}/saveTopicsForUser`, data, {
    headers: {
      orgId: orgId
    },
    cache: false
  });
};
const bulkMatches = ({ orgId, location }: { orgId: string; location: string }) => {
  const data = {};
  return axiosCached.post(`${process.env.REACT_APP_API}/bulk/matches`, data, {
    headers: {
      orgId,
      location
    },
    cache: false
  });
};

const basicSettingsCommunity = ({ grpId, orgId, data }: { grpId: string; orgId: string; data: object }) => {
  return axiosCached.post(`${process.env.REACT_APP_API}/group/${grpId}/basicSettingsCommunity`, data, {
    headers: {
      orgId: orgId
    },
    cache: false
  });
};

const fetchAllGroupsList = (orgId: any, groupId: any) => {
  return axiosCached.get(`${process.env.REACT_APP_API}/${orgId}/group/${groupId}/getAllGroupsList`, {
    headers: {
      location: getUserDetails().location,
      orgId
    },
    cache: false
  });
  // return axios({
  //   method: 'GET',
  //   url: `${process.env.REACT_APP_API}/${orgId}/group/${groupId}/getAllGroupsList`,
  //   headers: {
  //     location: getUserDetails().location,
  //     orgId,
  //   }
  // });
};
// const fetchAllGroupsList = (orgId: any, groupId: any) => {
//   return axios({
//     method: 'GET',
//     url: `${process.env.REACT_APP_API}/${orgId}/group/${groupId}/getAllGroupsList`,
//     headers: {
//       location: getUserDetails().location,
//       orgId,
//     }
//   });
// };

const updateIndustry = ({ grpId, orgId, data }: { grpId: string; orgId: string; data: object }) => {
  return axiosCached.post(`${process.env.REACT_APP_API}/group/${grpId}/industries`, data, {
    headers: {
      orgId: orgId
    },
    cache: false
  });
};

const saveIndustriesForUser = ({ orgId, data }: { orgId: string; data: object }) => {
  return axiosCached.post(`${process.env.REACT_APP_API}/saveIndustriesForUser`, data, {
    headers: {
      orgId: orgId
    },
    cache: false
  });
};

const getAllIndustriesForUser = (location: string) => {
  return axiosCached.get(`${process.env.REACT_APP_API}/getAllIndustriesForUser`, {
    headers: { location },
    cache: { ttl: 1000 * 60 * 2 }
  });
};

const userBasicSettings = ({ userId }: { userId: string }) => {
  return axiosCached.post(`${process.env.REACT_APP_API}/userBasicSettings/${userId}`, {
    cache: false
  });
};

const updateGroupSettings = ({ groupId, location }: { groupId: string; location: string }) => {
  return axiosCached.patch(
    `${process.env.REACT_APP_API}/group/${groupId}/groupSettings`,
    {},
    {
      cache: false
    }
  );
};

const sendMatchRequest = ({
  requestMessage,
  menteeId,
  mentorId,
  requestGroups,
  commId
}: {
  requestMessage: string;
  menteeId?: string;
  mentorId?: string;
  requestGroups?: any;
  commId?: string;
}) => {
  const data = { requestMessage, menteeId, mentorId, requestGrps: requestGroups, communicationId: commId };
  return axiosCached.post(`${process.env.REACT_APP_API}/requestMatch/`, data, {
    cache: false
  });
};

const rejectMatchRequest = ({
  grpId,
  requestId,
  rejectMessage
}: {
  grpId: string;
  requestId: string;
  rejectMessage: string;
}) => {
  const data = { requestId, rejectMessage };
  return axiosCached.post(`${process.env.REACT_APP_API}/grp/${grpId}/rejectMatch/`, data, {
    cache: false
  });
};

const acceptMatchRequest = ({
  grpId,
  mentorId,
  menteeId,
  requestId
}: {
  grpId: string;
  mentorId: string;
  menteeId: string;
  requestId: string;
}) => {
  const data = { mentorId, menteeId, requestId };
  return axiosCached.post(`${process.env.REACT_APP_API}/grp/${grpId}/acceptMatch/`, data, {
    cache: false
  });
};

const removeMatchRequest = ({ grpId, requestId }: { grpId: string; requestId: string }) => {
  return axiosCached.post(
    `${process.env.REACT_APP_API}/grp/${grpId}/requestMatch/remove/${requestId}`,
    {},
    {
      cache: false
    }
  );
};

const resetPassword = ({ orgId, data }: { orgId: string; data: object }) => {
  return axiosCached.post(`${process.env.REACT_APP_API}/resetPassword`, data, {
    headers: {
      orgId: orgId
    },
    cache: false
  });
};

const getRecommendedArticles = ({
  grpId,
  orgId,
  location,
  userId,
  taskId,
  levelId
}: {
  grpId: string;
  orgId: string;
  location: string;
  userId: string;
  taskId: string;
  levelId: string;
}) => {
  return axios({
    method: 'get',
    url: `${process.env.REACT_APP_API}/group/${grpId}/level/${levelId}/task/${taskId}/articles?userId=${userId}`,
    headers: {
      location,
      orgId
    }
  });
};

const getRecommendedMentors = ({
  grpId,
  orgId,
  location,
  userId,
  levelId,
  taskId
}: {
  grpId: string;
  orgId: string;
  location: string;
  userId: string;
  taskId: string;
  levelId: string;
}) => {
  return axios({
    method: 'get',
    url: `${process.env.REACT_APP_API}/group/${grpId}/level/${levelId}/task/${taskId}/mentors?userId=${userId}`,
    headers: {
      location,
      orgId
    }
  });
};

const getCallsCount = (location: string) => {
  return axiosCached.get(`${process.env.REACT_APP_API}/user/calls`, {
    headers: { location },
    cache: false
  });
};

const getCompanyList = (location: string) => {
  return axiosCached.get(`${process.env.REACT_APP_API}/company/list`, {
    headers: { location },
    cache: false
  });
};

const addCompany = ({ name, location }: { name: string; location: string }) => {
  return axios({
    method: 'post',
    url: `${process.env.REACT_APP_API}/company`,
    data: { name, location }
  });
};

export interface IReviewData {
  vote: number;
  score: number;
  review?: string;
  isReviewed: boolean;
  participantUsers: string[];
}

const addReview = ({ data, meetingId }: { data: IReviewData; meetingId: string }) => {
  return axios({
    method: 'post',
    url: `${process.env.REACT_APP_API}/meeting/${meetingId}/review`,
    data: data
  });
};
const getReviews = ({ grpId, category }: { grpId: string; category: string }) => {
  // return axios({
  //   method: 'get',
  //   url: `${process.env.REACT_APP_API}/group/${grpId}/insights?category=${category}`
  // });

  return axiosCached.get(`${process.env.REACT_APP_API}/group/${grpId}/insights?category=${category}`, {
    // headers: { location },
    cache: false
  });
};

const getTemplateByType = ({ programType, location }: { programType: string; location: string }) => {
  return axiosCached.get(`${process.env.REACT_APP_API}/getTemplateByType/${programType}`, {
    headers: { location },
    cache: false
  });
};

const getTemplateByTemplateId = ({
  templateId,
  location,
  grpId,
  orgId
}: {
  templateId: string;
  location: string;
  grpId: string;
  orgId: string;
}) => {
  return axiosCached.get(`${process.env.REACT_APP_API}/group/${grpId}/template/${templateId}`, {
    headers: { location, orgId },
    cache: false
  });
};
const saveGroupTasks = ({
  data,
  grpId,
  location,
  orgId
}: {
  data: ITaskData;
  grpId: string;
  location: string;
  orgId: string;
}) => {
  return axios({
    method: 'post',
    url: `${process.env.REACT_APP_API}/group/${grpId}/goal`,
    headers: {
      location,
      orgId
    },
    data: data
  });
};
const updateGroupTasks = ({
  data,
  grpId,
  location,
  orgId,
  templateId
}: {
  data: any;
  grpId: string;
  location: string;
  orgId: string;
  templateId: string;
}) => {
  return axios({
    method: 'patch',
    url: `${process.env.REACT_APP_API}/group/${grpId}/template/${templateId}/goal`,
    headers: {
      location,
      orgId
    },
    data: data
  });
};
const publishTask = ({ location, grpId, orgId }: { location: string; grpId: string; orgId: string }) => {
  return axios({
    method: 'post',
    url: `${process.env.REACT_APP_API}/group/${grpId}/template/publish`,
    headers: {
      location,
      orgId
    },
    data: {}
  });
};

const exportUsers = ({
  location,
  grpId,
  orgId,
  plan
}: {
  location: string;
  grpId: string;
  orgId: string;
  plan: boolean;
}) => {
  return axiosCached.get(`${process.env.REACT_APP_API}/group/${grpId}/export/users?plan=${plan}`, {
    headers: { location, orgId },
    cache: false
  });
};
const getPersonalThreads = ({
  acsToken,
  orgId,
  tag,
  communicationId
}: {
  acsToken: string;
  orgId: string;
  tag: string;
  communicationId: string;
}) => {
  const requestData = {
    tag,
    communicationId
  };
  return axiosCached
    .get(`${process.env.REACT_APP_API}/threads/personal`, {
      params: {
        azureToken: acsToken,
        ...requestData
      },
      headers: {
        orgId: orgId
      },
      cache: false
    })
    .then((res) => res?.data);
};
const getGroupThreads = ({
  acsToken,
  orgId,
  tag,
  communicationId
}: {
  acsToken: string;
  orgId: string;
  tag: string;
  communicationId: string;
}) => {
  const requestData = {
    tag,
    communicationId
  };
  return axiosCached
    .get(`${process.env.REACT_APP_API}/threads/group`, {
      params: {
        azureToken: acsToken,
        ...requestData
      },
      headers: {
        orgId: orgId
      },
      cache: false
    })
    .then((res) => res?.data);
};

const upadateGettingStarted = ({ data, userId }: { data: any; userId: string }) => {
  return axios.patch(`${process.env.REACT_APP_API}/user/${userId}/welcome`, data);
};

const updateLevelDate = ({ data, grpId, levelId }: { data: any; grpId: string; levelId: string }) => {
  return axios.post(`${process.env.REACT_APP_API}/group/${grpId}/level/${levelId}/editLevel?delete=false`, data);
};
export const API = {
  getPrompts,
  getConversationById,
  conversationFromTree,
  newConversationFromGeneral,
  getConversationPreviews,
  aiSendMessage,
  newConversationHashtreeTrue,
  newConversationHashtreeFalse,
  getOrganizations,
  removeOrganization,
  getUsersByOrgId,
  addOrganization,
  updateOrganization,
  inviteMember,
  inviteOrgAdmin,
  getMemberRoles,
  getACSToken,
  getACSThreads,
  deleteACSThread,
  getACSOrgUsers,
  createACSThread,
  sendMessageToACSThread,
  getACSThreadParticipants,
  getUserMetadData,
  updateUserFtue,
  getUsersMetaByOrgId,
  getOrgName,
  inviteApi,
  getOrgId,
  readMessagesFromThreads,
  addParticipantsToExistingChannel,
  updateGroupChatName,
  editMessage,
  deleteMessage,
  removeParticipantFromThread,
  getACSChatThreads,
  readMessagesFromThreadsByPage,
  createUserProfile,
  getUserProfile,
  updateUserProfile,
  getEducationAndSkills,
  addEducationAndSkills,
  updateEducationAndSkills,
  getWorkHistory,
  addWorkHistory,
  updateWorkHistory,
  deleteWorkHistory,
  deleteEducation,
  deleteAllEducationAndWorkHistory,
  getTags,
  updateSocialLinks,
  updateDisability,
  getTagsList,
  updateGroupPic,
  updateHobbies,
  updateBioData,
  getGroupByOrgId,
  getTagsListnew,
  getAllTopicsForUser,
  addUserVideoUpload,
  getAllGroups,
  readMessagesFromThreadsByNextLink,
  createGroup,
  createLevel,
  deleteVideoIntro,
  createArticle,
  deleteGroup,
  deleteMsgCount,
  getGroupDetails,
  getAllArticle,
  getAllArticleUser,
  DeleteArticle,
  editArticle,
  getGroupUsers,
  inviteUserToAGroup,
  bulkFilesUpload,
  getCommunityMembers,
  getAllArticleById,
  articleAction,
  removeUserFromGroup,
  addBasicSettings,
  updateGroupInfo,
  getAllMatches,
  updateOptionalFields,
  updateAllMatches,
  getAllMatchesSettings,
  revokeGroupInvitation,
  getUserProfileInAGroup,
  getUserCommunityPeersList,
  updateOrganizationSettings,
  getListToAssignMatch,
  assignAMatch,
  confirmAllMatches,
  confirmMatch,
  getAllMatchesSetting,
  removeMatch,
  autoMatch,
  getMatchProfile,
  getAllTemplates,
  getAllLevels,
  getMyMentors,
  getMyMentees,
  getAllTasks,
  getTask,
  saveAnswers,
  saveMentorMultiAnswers,
  createGoal,
  getScheduleCall,
  goalFilesUpload,
  groupsListForUser,
  getMenteesPeersHomePage,
  getMentorsPeersHomePage,
  createCall,
  checkCallParticipant,
  rejoinCallUpdateFlag,
  getAllGroupsByUserId,
  getCommunityGroupsUsersListNew,
  getUserDetailsById,
  getMentorTask,
  saveMentorAnswers,
  cancelScheduleCall,
  rejectScheduleCall,
  updateLastseen,
  getMentorHelpfulTips,
  saveConcernWithEmail,
  getGroupsOverview,
  getMyMentorsForTask,
  fetchMembersInsights,
  fetchChartInsights,
  fetchInsightsMetricsCount,
  activateUser,
  saveAIMentorQuiz,
  saveArticleDescriptionFiles,
  saveTaskDescriptionFiles,
  deleteDescriptionFile,
  saveVotevalue,
  getChatUsers,
  updateBio,
  createTask,
  editTask,
  addTaskToLevel,
  goalLevelDelete,
  goalTaskDelete,
  updateTopics,
  saveTopicsForUser,
  bulkMatches,
  basicSettingsCommunity,
  goalTaskBulkDelete,
  fetchAllGroupsList,
  updateIndustry,
  saveIndustriesForUser,
  getAllIndustriesForUser,
  userBasicSettings,
  getMenteeInsights,
  updateGroupSettings,
  updateInsightByArticleId,
  sendMatchRequest,
  rejectMatchRequest,
  acceptMatchRequest,
  removeMatchRequest,
  resetPassword,
  getRecommendedArticles,
  getRecommendedMentors,
  getCallsCount,
  getCompanyList,
  addCompany,
  addReview,
  getReviews,
  getCommunityGroupsUsersListNewDuplicate,
  getTemplateByType,
  saveGroupTasks,
  getTemplateByTemplateId,
  publishTask,
  updateGroupTasks,
  exportUsers,
  getPersonalThreads,
  getGroupThreads,
  upadateGettingStarted,
  updateLevelDate
};
