import React, { useState } from 'react';
import {
  Box,
  // Link,
  Grid,
  Divider,
  Typography
} from '@mui/material';
import { useParams } from 'react-router';
import EngagementMetrics from './EngagementMetrics';
import EngagementCard from '../../../home/cards/EngagementCard';
import { formatNumberWithCommas, getGoalProgressShowOrNot, showMyLoader } from '../../../../components/common';
// import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { EngagementChartLoader, MembersLoader } from '../../../../../../Loader/ProgramMetricsLoader';

const ProgramMetricsInsights = ({
  metricsValue,
  memberInsightValue
}: {
  metricsValue?: any;
  memberInsightValue?: any;
}) => {
  let grpId = useParams().id || '';
  if (grpId === 'admin') {
    grpId = '';
  }
  const loading = showMyLoader();
  const showGoalProgress = getGoalProgressShowOrNot(false);
  const formattedTotal = formatNumberWithCommas(memberInsightValue?.total ?? 0);

  const memberInsightsDesign = (data: any) => {
    if (!data || typeof data !== 'object') {
      return (
        <Box display="flex" flexDirection="column" px={1} height="100%" justifyContent="center">
          <Typography textAlign="center">No Data Avialable</Typography>
        </Box>
      ); // Return null or handle the empty data case as needed
    }
    const members = data;
    const designItems = Object.keys(members).map((key, index) => {
      if (key === 'total') {
        return null; // Skip rendering "total"
      }

      const isLastItem = index === Object.keys(members).length - 2; // Check if it's the second-to-last item
      const divider = isLastItem ? null : <Divider sx={{ my: '16px' }} key={`divider-${key}`} />;

      return (
        <Box key={key} px={1}>
          <Box display="flex" justifyContent="space-between">
            <Typography>{key}</Typography>
            <Typography variant="h6">{formatNumberWithCommas(members[key])}</Typography>
          </Box>
          {divider}
        </Box>
      );
    });

    return designItems;
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <EngagementMetrics
          showGoalProgress={showGoalProgress}
        />
      </Grid>

      <Box mt={2}>
        <Grid container spacing={2}>
          {/* Members */}
          <Grid item xs={12} lg={3}>
            {loading ? (<MembersLoader />) : 
              <Box className="CardBox" minHeight="400px">
                <Box display="flex" justifyContent="space-between" alignItems="center" p={2}>
                  <Typography variant="h6">Members</Typography>
                  {/* <Link color={'#0071A9'} variant="body2">View</Link> */}
                </Box>
                <Divider />
                <Box py={2}>
                  <Box display="flex" alignItems="center" justifyContent="center" px={2}>
                    <Typography fontSize="60px" textAlign="center" py={2} variant="h1">
                      {formattedTotal}
                    </Typography>
                  </Box>

                  <Box px={1}>
                    {/* <Box height="200px" overflow="auto" px={1}> */}
                      {memberInsightsDesign(memberInsightValue)}
                    {/* {memberInsightsDesign(gridValues[selectedGrid])} */}
                  </Box>

                  {/* Dots */}
                  {/* <Box pt={2} px={2}>
                    <Box width="100%" display="flex" alignItems="center" justifyContent="center">
                      {[0, 1, 2].map((index) => (
                        <FiberManualRecordIcon
                          onClick={() => toggleGrid(index)}
                          className='cursor-pointer fs-16'
                          color={selectedGrid === index ? 'primary' : 'secondary'}
                        />
                      ))}
                    </Box>
                  </Box> */}
                </Box>
              </Box>
            }
          </Grid>
          {/* Engagement chart */}
          <Grid item xs={12} lg={9}>
            {loading ? <EngagementChartLoader /> : <EngagementCard />}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ProgramMetricsInsights;
