import { useAuth0 } from '@auth0/auth0-react';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { getUserDetails } from '../../../utils/orgName';
import { API } from '../../../api';
import { updateXpPoints } from '../../../redux/slices/menteeXpPoints/menteeXpPointsSlice';
import _ from 'lodash';
import { toast } from 'react-toastify';
import useProfileDetails from '../../../hooks/useProfileDetails';

export function useXpPoints() {
  const { user } = useAuth0();
  const selectedGroupId = useAppSelector((state) => state.selectedGroup.data);
  const groupId = selectedGroupId.id || '';
  const { location, orgId } = getUserDetails();
  const dispatch = useAppDispatch();
  const { mentee } = useProfileDetails();

  const getMenteeInsightsData = async (orgId: string, location: string, groupId: string) => {
    try {
      console.log('groupId', groupId);
      const response = await API.getMenteeInsights(orgId, location, groupId);
      if (response) {
        dispatch(updateXpPoints(response.data?.xpPoints));
      } else {
        // Handle errors if needed
        toast.error('Error in updating XP points');
      }
    } catch (e) {
      // console.error(e);
    }
  };

  const getXpPoints = async (role: string) => {
    if (role === 'mentee') {
      await getMenteeInsightsData(orgId, location, groupId);
    }
  };

  //   useEffect(() => {
  //     getXpPoints('mentee');
  //   }, []);

  return getXpPoints;
}
