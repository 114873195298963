/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { Box, Divider, Typography } from '@mui/material';
import '../../../../assets/style/sass/profile.scss';
import Hobbies from '../../components/Hobbies';
import EducationAndSkills from '../../components/EducationAndSkills';
import WorkHistory from '../../components/WorkHistory';
import ProfileHeader from '../../components/ProfileHeader';
import DigitalIntro from '../../components/DigitalIntro';
import SocialHandles from '../../components/SocialHandles';
// import YourLanguage from '../../components/YourLanguage';
import Disability from '../../components/Disability';
import { useAppSelector } from '../../../../redux/hooks';
import { useLoader } from '../../components/useLoader';
import TextAndChipSection from '../../components/TextAndChipSection';
import { ProfilePageLoader } from '../../../../Loader/ProfilePageLoader';
import useResponsiveScreenSwitch from '../../../../utils/useResponsiveScreenSwitch';
import TextAndLineSection from '../../components/TextAndLineSection';
import { camelCaseToCapitalIndustries } from '../../../../utils/industriesMapping';
import { camelCaseToCapital } from '../../../../utils/topicsMapping';
import _ from 'lodash';
import { RDP_CREATIVE } from '../../../../utils/constants';

export interface FormValues {
  email: string;
  country: string;
  firstName: string;
  lastName: string;
  timezone: string;
  headshot: string;
  dob: string;
  phoneNumber: string;
  phonePrefix: string;
  gender: string;
  ethnicity: string;
  firstGenStudent: string;
  social: {
    facebook: string;
    instagram: string;
    youtube: string;
    twitter: string;
    linkedIn: string;
  };
  disability: string[];
  category: string;
  hobbies: string[];
  // bioData: string;
}

export const initialValues: FormValues = {
  email: '',
  firstName: '',
  country: '',
  lastName: '',
  timezone: '',
  headshot: '',
  dob: '',
  phoneNumber: '',
  phonePrefix: '',
  gender: '',
  ethnicity: '',
  firstGenStudent: '',
  social: {
    facebook: '',
    instagram: '',
    youtube: '',
    twitter: '',
    linkedIn: ''
  },
  disability: [],
  category: '',
  hobbies: []
  // bioData: ''
};

const Profile = () => {
  const userDetails = useAppSelector((state) => state.userProfile);
  const data = userDetails.data?.personal_details || initialValues;
  const userBio = userDetails.data?.personal_details?.bio;
  const [formData, setFormData] = useState<FormValues>(initialValues);
  const oid = useAppSelector((state) => state.orgDetails.data?.oid);
  /** For responsive */
  const { mobileBackBtn } = useResponsiveScreenSwitch(); // Use the hook
  const showLoader = useLoader(userDetails.loading, 1000);

  const DummyData = [
    { language: 'Bang', proficiency: 'Fluent' },
    { language: 'Hine', proficiency: 'Natural' }
  ];

  if (showLoader) {
    return <ProfilePageLoader />;
  }

  if (userDetails.error) {
    return <Box>{userDetails.errorText}</Box>;
  }

  if (userDetails.data) {
    return (
      <>
        {/* For Mobile -> details to back button */}
        {mobileBackBtn('home')}

        <Box top={'0'} maxHeight={'100%'} position={'relative'} overflow={'hidden auto'}>
          <Box className={'CardBox'}>
            <Box p={2}>
              <Typography variant="h5">Personal information</Typography>
            </Box>
            <Divider />
            <ProfileHeader formData={formData} setFormData={setFormData} />
            <Divider className="mx-20" />
            <DigitalIntro />
          </Box>

          {/* I can help you with */}
          {oid !== RDP_CREATIVE && (
            <Box mt={{ xs: 1, sm: 2 }} className={'CardBox'}>
              <TextAndChipSection
                editing
                divider
                chipType="outline"
                param="IcanHelpYouWith"
                heading="I can help you with:"
                data={_.sortBy([
                  ...camelCaseToCapitalIndustries(userDetails.data.selectedIndustries),
                  ...(camelCaseToCapital(userDetails.data.selectedTopics) || [])
                ])}
              />
            </Box>
          )}

          {/* Bio */}
          {userBio && (
            <Box my={{ xs: 1, sm: 2 }} className={'CardBox'}>
              <TextAndLineSection divider heading="Bio" data={userBio} />
            </Box>
          )}

          {/* Work history */}
          <Box mt={{ xs: 1, sm: 2 }} className={'CardBox'}>
            <WorkHistory />
          </Box>
          {/* Education and Skills */}
          <Box mt={{ xs: 1, sm: 2 }} className={'CardBox'}>
            <EducationAndSkills />
          </Box>
          {/* Hobbies and interests */}
          <Box mt={{ xs: 1, sm: 2 }} className={'CardBox'}>
            <Hobbies editing divider chipType="outline" hobbiesValue={data.hobbies} />
          </Box>
          {/* Language You Speak */}
          {/* <Box mt={{xs: 1, sm: 2}} className={'CardBox'}>
            <YourLanguage editing divider />
          </Box> */}
          {/* Social handles */}
          <Box mt={{ xs: 1, sm: 2 }} className={'CardBox'}>
            <SocialHandles editing divider socialData={data.social} />
          </Box>
          {/* Disability */}
          <Box mt={{ xs: 1, sm: 2 }} className={'CardBox'}>
            <Disability />
          </Box>
          {/* My Programs */}
          {/* <Box mt={{ xs: 1, sm: 2 }} className={'CardBox'}>
            <TextAndChipSection
              editing
              divider
              heading='My Programs'
            />
          </Box> */}
        </Box>
      </>
    );
  }

  return null;
};

export default Profile;
