/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Box,
} from "@mui/material";
import { Outlet } from 'react-router-dom';
import { useAppSelector } from '../../../redux/hooks';
import ProfileSidebar from "../components/ProfileSidebar";
import useResponsiveScreenSwitch from "../../../utils/useResponsiveScreenSwitch";

export interface FormValues {
  email: string;
  country: string;
  firstName: string;
  lastName: string;
  timezone: string;
  headshot: string;
  dob: string;
  phoneNumber: string;
  phonePrefix: string;
  gender: string;
  ethnicity: string;
  firstGenStudent: string;
  social: {
    facebook: string;
    instagram: string;
    youtube: string;
    twitter: string;
    linkedIn: string;
  };
  disability: string[];
  category: string;
  hobbies: string[];
}

export const initialValues: FormValues = {
  email: '',
  firstName: '',
  country: '',
  lastName: '',
  timezone: '',
  headshot: '',
  dob: '',
  phoneNumber: '',
  phonePrefix: '',
  gender: '',
  ethnicity: '',
  firstGenStudent: '',
  social: {
    facebook: '',
    instagram: '',
    youtube: '',
    twitter: '',
    linkedIn: ''
  },
  disability: [],
  category: '',
  hobbies: []
};

const ProfileLayout = () => {
  const userDetails = useAppSelector((state) => state.userProfile);
  /** For responsive */
  const { forMobile } = useResponsiveScreenSwitch(); // Use the hook

  if (userDetails.error) {
    return <Box>{userDetails.errorText}</Box>;
  }

  if (userDetails.data) {
    return (
      <Box className='containerBox'>
        {!forMobile ? 
          <ProfileSidebar />
        : null }
        <Box className='containerBoxRight'>
          <Outlet />
        </Box>
      </Box>
    );
  }

  return null;
};

export default ProfileLayout;
