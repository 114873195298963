/* eslint-disable react/jsx-key */
import React, { useState } from 'react';
import _ from 'lodash';
import {
  Box,
  Button,
  Popover,
  Divider,
  Typography,
} from '@mui/material';
import {
  Outlet,
  useNavigate
} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { LiveChatStyle } from './LiveChatStyle';
import ChatBotQuestions from './ChatBotQuestions';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useAppSelector } from '../../../../redux/hooks';
import {
  setHomePage,
  clearAllConversations,  
} from '../../../../redux/slices/ai-chat/aiChatMessages';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import favicon from '../../../../assets/images/favicon.svg';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';

interface chatProps {}

const ChatBot: React.FC<chatProps> = ({}) => {
  const userProfileData = useAppSelector((state) => state.userProfile.data);
  const classes = LiveChatStyle();
  const navigate = useNavigate();
  const [rightTopPop, setRightTopPop] = useState(null);
  const rightTopDotsClick = (event: any) => {
    setRightTopPop(event.currentTarget);
  };
  const handleClose = () => { setRightTopPop(null) };
  const open = Boolean(rightTopPop);
  const rightTopPopover = open ? 'simple-popover' : undefined;
  const dispatch = useDispatch();
  const clickAskDosen = () => {
    dispatch(setHomePage(true));
    navigate('/app/askDosen');
    dispatch(clearAllConversations());
  };
  const openNewChat = () => {
    navigate('/app/askDosen');
    dispatch(clearAllConversations());
    dispatch(setHomePage(true));
    setRightTopPop(null);
  };
  const openchatBothistory = () => {
    navigate('/app/aichatbot/chatBothistory');
    dispatch(clearAllConversations());
    setRightTopPop(null);
  };
  const onClickBack = () => {
    navigate('/app/aichatbot');
    dispatch(clearAllConversations());
  };

  return (
    <Box className="bodyBox">
      {userProfileData?.aiMentor ? (
        <Box className={classes.MainBox} height="100%">
          <Box className={classes.Container}>
            {/* Header */}
            <Box p={2}>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Box display="flex" alignItems="center" >
                  <ArrowBackIosIcon onClick={onClickBack} className='cursor-pointer' />
                  <Typography fontSize="28px" color="#212529" mr={'5px'}>Hi</Typography>
                  <Typography fontSize="28px" fontWeight="600" color="primary">
                    {_.get(userProfileData, 'personal_details.firstName', 'John')},
                  </Typography>
                </Box>
                <MoreVertIcon onClick={rightTopDotsClick} className='cursor-pointer' />
                <Popover
                  id={rightTopPopover}
                  open={open}
                  anchorEl={rightTopPop}
                  onClose={handleClose}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                  <Box className="PopoverListStyle" onClick={openNewChat}>
                    <OpenInNewIcon />
                    <Typography color="primary">New chat</Typography>
                  </Box>
                  <Box className="PopoverListStyle" onClick={openchatBothistory}>
                    <SettingsBackupRestoreIcon />
                    <Typography color="primary">History</Typography>
                  </Box>
                </Popover>
              </Box>
              <Typography fontSize="16px" color="#212529">
                I’m your personal Dosen. To get started, choose a prompt below or start a new chat.
              </Typography>
            </Box>
            <Divider />

            {/* Main body */}
            <Box
              height={'100%'}
              display={'flex'}
              flexDirection={'column'}
              overflow={'hidden auto'}
              // height={'calc(100% - 204px)'}
            >
              <Box
                pt={2}
                px={2}
                height={'100%'}
                overflow={'hidden auto'}
              >
                <Outlet />
              </Box>
            </Box>

            {/* Ask dosen btn */}
            <Box p={2}>
              <Button
                fullWidth
                variant={"contained"}
                onClick={clickAskDosen}
                className={classes.AskDosen}
              >
                Ask Dosen
              </Button>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box className={classes.MainBox}>
          <Box className={classes.Container}>
            <Box display="flex" gap="8px" textAlign="center" alignItems="center" p={2}>
              <Box>
                <img src={favicon} alt='' width="24" height="24" />
              </Box>
              <Typography variant="h4">Dosen</Typography>
            </Box>
            <Divider />
            {/* Chat Bot Questions section */}
            <ChatBotQuestions />
            {/* Thank you section */}
            {/* <ThankYou /> */}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ChatBot;
