/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from 'react';
import {
  Dialog,
} from '@mui/material';
import upload from '../../../assets/images/upload.svg';
import { useAuth0 } from '@auth0/auth0-react';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { API } from '../../../api';
import videoImage from '../../../assets/images/videoimage.png';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { userInfoUpdate } from '../../../redux/slices/user-profile/userProfieSlice';
import { getUserDetails } from '../../../utils/orgName';
import { LoadingButton } from '@mui/lab';
import useProfileDetails from '../../../hooks/useProfileDetails';
import { popupActions } from '../../../redux/slices/onboardingPopupsSlice';
import DigitalIntroForm from './DigitalIntroForm';
import { Close } from '@mui/icons-material';
import CommonModalHeader from '../../../components/DialogUi/CommonModalHeader';

const DigitalIntroModal = () => {
  const openDigital = useAppSelector((state) => state.onboardingPopups.data.digitalIntro);

  const dispatch = useAppDispatch();

  const handleCloseDigital = () => {
    dispatch(popupActions.updatePopups({ digitalIntro: false }));
  };

  // useEffect(() => {
  //   if (openDigital) {
  //     setShowPreview(true);
  //   }
  // }, [openDigital]);

  const { userId } = getUserDetails();
  return (
    <Dialog
      open={openDigital}
      onClose={handleCloseDigital}
    >
      {/* Dialog Header */}
      <CommonModalHeader
        handleCloseModal={handleCloseDigital}
        title={`Video introduction`}
      />
      <DigitalIntroForm />
    </Dialog>
  );
};

export default DigitalIntroModal;
