import { Box, Grid, Stack } from "@mui/material";
import MySkeleton from "../components/Skeleton";

const ProgramUserDetailsLoader = () => (
  <Box height={'100%'} overflow={'hidden'}>
    <Box display='flex' alignItems='center' justifyContent='flex-end' p={'8px'}>
      <MySkeleton variant='rounded' height={24} width={24} />
    </Box>
    <Grid container height={'calc(100% - 40px)'}>
      <Grid item xs height={'100%'}>
        <Box height={'100%'} overflow={'auto'}>
          <Box border={'1px solid #EFF0F4'} borderRadius={'8px'}>
            <Box
              p={2}
              display='flex'
              alignItems='center'
              justifyContent='space-between'
              borderRadius='8px 8px 0px 0px'
              bgcolor='#EFF0F4'
            >
              <Box display='flex' alignItems='center' columnGap='20px'>
                <Box>
                  <MySkeleton variant='rounded' height={80} width={80} bg={'#FFFFFF'}/>
                </Box>
                <Box display='flex' flexDirection='column' alignItems='flex-start'>
                  <Box display={'flex'} alignItems={'center'} gap={2}>
                    <MySkeleton width={100} bg={'#FFFFFF'}/>
                    <MySkeleton variant='rounded' height={26} width={58} bg={'#FFFFFF'}/>
                  </Box>
                  <MySkeleton width={100} bg={'#FFFFFF'}/>
                </Box>
              </Box>
              <Box>
                <MySkeleton variant='rounded' height={35} width={125} bg={'#FFFFFF'}/>
              </Box>
            </Box>
            <Box p={2} display={'flex'} alignItems={'flex-start'}>
              <Grid container spacing={2}>
                {[...Array(6).keys()].map((_, index) => (
                  <Grid key={index} item xs={6} md={3} lg={2}>
                    <MySkeleton width={100} />
                    <MySkeleton width={60} />
                  </Grid>
                ))}
              </Grid>
            </Box>
            <MySkeleton height={2}/>
            <Box p={2}>
              <MySkeleton width={100} />
              <Box pt={2}>
                <MySkeleton />
              </Box>
            </Box>
            <MySkeleton height={2}/>
            <Box p={2}>
              <MySkeleton width={150} className="mb-20"/>
              <Stack direction="row" useFlexGap flexWrap="wrap" spacing={1}>
                {[...Array(12).keys()].map((_, index) => (
                  <MySkeleton key={index} variant='rounded' height={32} width={100} />
                ))}
              </Stack>
            </Box>
          </Box>
          <Box className="CardBox" mt={2}>
            <Box p={2}>
              <MySkeleton width={150} />
            </Box>
            <MySkeleton height={2} />
            <Grid container spacing={2}>
              {[...Array(6).keys()].map((_, index) => (
                <Grid key={index} item xs={6} md={3} lg={2}>
                    <Box p={2}>
                      <MySkeleton width={100} />
                      <MySkeleton width={60} />
                    </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
          {/* <Box className="CardBox" mt={2}>
            <Box p={2}>
              <MySkeleton width={150} />
            </Box>
            <MySkeleton height={2} />
            <Box p={2}>
              {[...Array(3).keys()].map((_, Index,array) => (
                <Box key={Index} mb={Index === array.length - 1 ? 0 : 2}>
                  <MySkeleton width={'60%'} />
                  <Stack direction="row" useFlexGap flexWrap="wrap" spacing={1} mt={1}>
                    {[...Array(3).keys()].map((_, index) => (
                      <MySkeleton key={index} variant='rounded' height={32} width={100} />
                    ))}
                  </Stack>
                </Box>
              ))}
            </Box>
          </Box> */}
          <Box className="CardBox" mt={2}>
            <Box p={2}>
              <MySkeleton width={150} />
            </Box>
            <MySkeleton height={2} />
            <Box p={2} display='flex' alignItems='flex-start'>
              <Grid container spacing={2}>
                {[...Array(2).keys()].map((_, index) => (
                  <Grid key={index} item xs={6} md={3} lg={2}>
                    <MySkeleton width={100} />
                    <MySkeleton width={50} />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  </Box>
);

export default ProgramUserDetailsLoader;
