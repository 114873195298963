import React from 'react';
import MySkeleton from '../components/Skeleton';
import { Box, Grid } from '@mui/material';

/** Chips loader design */
export const TopChipsLoader = () => {
    return (
        <Box display={'flex'} gap={'8px'}>
            {[...Array(6).keys()].map((_, index) => (
                <MySkeleton variant='rounded' height={32} width={100} />
            ))}
        </Box>
    );
};

/** Cards loader design */
export const ChatCardsLoader = () => {
    return (
        <Grid container spacing={2}>
            {[...Array(3).keys()].map((_, index) => (
                <Grid item xs={12} md={4} key={index} display={'flex'} width={{ xs: 320, md: 250 }}>
                    <MySkeleton variant='rounded' height={150} width="100%" />
                </Grid>
            ))}
        </Grid>
    );
};

/** Chat history loader design */
export const ChatHistoryLoader = () => {
    return (
        <>
            {[...Array(3).keys()].map((_, index) => (
                <Box key={index}>
                    <Box pb={2}>
                        <MySkeleton variant='text' width={100}/>
                    </Box>
                    {[...Array(3).keys()].map((_, innerIndex) => ( 
                        <Box key={innerIndex} display={'flex'} gap={1} pb={2} alignItems={'center'}> 
                            <MySkeleton variant='text' width={200} />
                            <MySkeleton variant='circular' height={10} width={10} />
                        </Box>
                    ))}
                </Box>
            ))}
        </>
    );
};



