import { Box, Typography, Button } from '@mui/material';
import sadcomputer1 from '../assets/images/sad-computer1.svg';

const NotFoundv2: React.FC = () => {
  function handleRedirect() { window.location.href = '/' }

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems={{ xs: 'top', sm: 'center' }}
      height="100vh"
    >
      <Box width="480px" border={{ xs: 'none', sm: '1px solid #e7e3e3' }} borderRadius="8px" bgcolor={'#FFFFFF'}>
        <Box mx={{ xs: 3, sm: 9 }} my={6}  display="flex" justifyContent="center" flexDirection="column" alignItems="center">
          <img src={sadcomputer1} alt="error" height="124px" width="116px" />
          <Typography variant="h3" pt={3}>Sorry, the page not found</Typography>
          <Typography fontSize="16px" textAlign="center" pt={1} pb={6}>The link you followed probably broken or the page has been removed.</Typography>
          <Button color="primary" variant="contained" fullWidth sx={{ height: '50px' }} onClick={handleRedirect}>
            Back to Home          
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default NotFoundv2;