/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import { useLogout } from '../../../hooks';
import { useNavigate } from 'react-router-dom';
import ROLES from '../../../utils/roles';
import useProfileDetails from '../../../hooks/useProfileDetails';
import { useAppSelector } from '../../../redux/hooks';

const ProfileSidebar = () => {
  const { doLogout } = useLogout();
  const navigate = useNavigate();
  const url = window.location.hash;
  const role = localStorage.getItem('role') || '';
  const { mentors } = useProfileDetails();
  const selectedRole = useAppSelector((state)=>state.selectedGroup.data.role)
  const userData = useAppSelector((state) => state.userProfile.data);

  let tabs: any = [

    // {
    //   id: 'security',
    //   name: 'Password & Security',
    //   route: '/app/profile/security'
    // },
  ];

  if (userData?.connection === 'Username-Password-Authentication') {
    tabs.push({
      id: 'security',
      name: 'Password & Security',
      route: '/app/profile/security'
    });
  }

  if (role !== ROLES.platAdmin) {
    if (Array.isArray(tabs) && tabs.length > 0) {
      tabs = [
        {
          id: 'profile',
          name: 'Profile',
          route: '/app/profile'
        },
        ...tabs
      ];
    } else {
      tabs = [
        {
          id: 'profile',
          name: 'Profile',
          route: '/app/profile'
        }
        // {
        //   id: 'assessment',
        //   name: 'Assessment questions',
        //   route: '/app/profile/assessment'
        // }
      ];
    }
  }
  //  TODO: Disable this as it's in inprogress
  if (mentors.length > 0 && selectedRole === 'mentor') {
    tabs.push({
      id: 'assessment',
      name: 'Assessment questions',
      route: '/app/profile/assessment'
    });
  }

  if (role === ROLES.orgAdmin) {
    if (Array.isArray(tabs) && tabs.length > 0) {
      tabs = [
        ...tabs,
        // {
        //   id: 'settings',
        //   name: 'Password & Security',
        //   route: '/app/profile/security'
        // },
        {
          id: 'orgsettings',
          name: 'Organization Settings',
          route: '/app/profile/orgsettings'
        }
      ];
    } else {
      tabs = [
        // {
        //   id: 'settings',
        //   name: 'Password & Security',
        //   route: '/app/profile/security'
        // },
        {
          id: 'orgsettings',
          name: 'Organization Settings',
          route: '/app/profile/orgsettings'
        }
      ];
    }
  }
  if (Array.isArray(tabs) && tabs.length > 0) {
    tabs = [
      ...tabs,
      {
        id: 'logout',
        name: 'Logout',
        route: 'logout'
      }
    ];
  } else {
    tabs = [
      {
        id: 'logout',
        name: 'Logout',
        route: 'logout'
      }
    ];
  }

  const activeStyle = 'list-group-item listProfileSidebarActive text-white';
  const inActiveStyle = 'list-group-item listProfileSidebar';
  return (
    <Box className="containerBoxLeft">
      <nav aria-label="secondary mailbox folders">
        <div className="listGroup">
          <ul className="list-group">
            {Array.isArray(tabs) &&
              tabs?.length > 0 &&
              tabs?.map((tab: any) => {
                return (
                  <li
                    key={tab.id}
                    className={url === `#${tab.route}` ? activeStyle : inActiveStyle}
                    onClick={() => {
                      if (tab.route !== 'logout') {
                        navigate(tab.route);
                      } else {
                        doLogout();
                      }
                    }}
                  >
                    {tab.name}
                  </li>
                );
              })}
          </ul>
        </div>
      </nav>
    </Box>
  );
};

export default ProfileSidebar;
