import React from 'react';
import { 
    Box, 
    Table,
    TableRow,  
    TableBody, 
    TableCell, 
    TableHead, 
    Typography, 
    TableContainer,
} from "@mui/material";
import MySkeleton from '../components/Skeleton';

const StudentTableLoader: React.FC = () => {
    return (
        <Box mt={2} bgcolor="#FFFFFF" border='1px solid #EFF0F4' borderRadius='8px'>
            <Typography p={2}>
                <MySkeleton width={150} />
            </Typography>
            <MySkeleton height={2} />
            <TableContainer className='b-none'>
                <Table className="tableBody" width="100%" aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            {[...Array(2).keys()].map((_, index) => ( 
                                <TableCell key={index}>
                                    <MySkeleton width={100} />
                                </TableCell>
                            ))}
                            <TableCell>
                                <Box className='last-cell-table-Loader'>
                                    <MySkeleton width={100} />
                                </Box>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {[...Array(5).keys()].map((_, index) => ( 
                            <TableRow key={index}>
                                <TableCell>
                                    <Box display={'flex'} alignItems={'center'} gap={'8px'}>
                                        <MySkeleton variant='rounded' height={34} width={34} />
                                        <MySkeleton width={150} />
                                    </Box>
                                </TableCell>
                                <TableCell>
                                    <MySkeleton width={350} />
                                </TableCell>
                                <TableCell>
                                    <Box display="flex" gap="8px" justifyContent="end">
                                        <MySkeleton width={100} />
                                        <MySkeleton width={20} />
                                    </Box>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default StudentTableLoader;
