import { PayloadAction, createSlice, current } from '@reduxjs/toolkit';
import _ from 'lodash';

interface IInitialState {
  notifications: any[];
  chatKind: 'chat' | 'chatDetails';
}

const initialState: IInitialState = {
  notifications: [],
  chatKind: 'chat'
};

const acsMessageNotificationsSlice = createSlice({
  name: 'notification-messages',
  initialState: initialState,
  reducers: {
    // This reducer to handle responsive ness for chat
    atnSetChatKind: (state, action: PayloadAction<'chat' | 'chatDetails'>) => {
      state.chatKind = action.payload;
    },
    atnSaveNotification: (state, action) => {
      const isMessageExists = _.find(
        current(state.notifications),
        (each) => _.get(each, 'id') === _.get(action.payload, 'id')
      );
      if (_.size(isMessageExists)) {
        state.notifications = _.map(current(state.notifications), (each) =>
          _.get(each, 'id') === _.get(action.payload, 'id') ? action.payload : each
        );
      } else {
        state.notifications.push(action.payload);
      }
    },
    atnRemoveNotifications: (state, action) => {
      // console.log(current(state.notifications), action.payload)
      const filterNotificationsUsingThreadId = _.filter(
        current(state.notifications),
        (each) => _.get(each, 'threadId') !== action.payload
      );
      state.notifications = filterNotificationsUsingThreadId;
    },
    atnUpdateNotifications: (state, action) => {
      state.notifications = action.payload;
    }
  }
});
export const acsMessageNotificationActions = acsMessageNotificationsSlice.actions;
export default acsMessageNotificationsSlice.reducer;
