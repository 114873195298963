/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import {
  Box,
  LinearProgress
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ThreeDots } from 'react-loader-spinner';
import logo from '../assets/images/brand/dosen/dark-logo.png';

interface IAppLoader {
  color?: string;
  height?: string;
  type?: 'logo' | 'spinner';
}

const useStyles = makeStyles(() => ({
  progressBar: {
    width: '120px',
  },
  logo: {
    maxWidth: '100%',
    height: 'auto',
    width: '120px',
  },

}));

const AppLoader: React.FC<IAppLoader> = ({
  color = '#0071A9',
  height = '100%',
  type = 'spinner'
}) => {
  const classes = useStyles();
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      // Increase the progress value gradually
      setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 5));
    }, 500); // Adjust the interval duration to control the speed

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Box
      gap={'10px'}
      height={height}
      display={"flex"}
      bgcolor={'#FFFFFF'}
      alignItems={"center"}
      flexDirection={"column"}
      justifyContent={"center"}
    >
      {type === 'logo' ? (
        <>
          <Box textAlign="center">
            <img alt="logo" src={logo} className={classes.logo} />
          </Box>
          <LinearProgress
            value={progress}
            color={"primary"}
            variant={"determinate"}
            className={classes.progressBar}
          />
        </>
      ) : (
        <ThreeDots
          height="15"
          width="60"
          radius="7"
          color={color}
          visible={true}
          ariaLabel="three-dots-loading"
        />
      )}
    </Box>
  );
};

export default AppLoader;
