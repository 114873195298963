/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-console */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-key */
/* eslint-disable no-duplicate-imports */
import React, { FC, useEffect, useRef, useState } from 'react';
import { Box, Stack, Divider, Typography, Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { IArticleData } from '../HelpTab';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import { LibraryLeftBarLoader } from '../../Library/components/LibraryLoader';
import MySkeleton from '../../../../../components/Skeleton';
//import { FilterIcon } from '../../../../../utils/Icons';

interface articleId {
  articleId: string;
}
interface Props {
  onClick: (data?: articleId, concernData?: boolean) => void;
  allArticleData: IArticleData[];
  selectedArticleId: string;
  handleClickOpenFilter: any;
  allArticleLoading: boolean;
}

const HelpSideBar: FC<Props> = ({
  onClick,
  handleClickOpenFilter,
  allArticleData,
  selectedArticleId,
  allArticleLoading
}: Props) => {
  const [expandedIndex, setExpandedIndex] = useState(0);
  const [concernDataState, setConcernDataState] = useState(false);
  const getTotalArticleCount = (data: IArticleData[]): number => {
    let totalCount = 0;
    data.forEach((category) => {
      Object.values(category).forEach((articles) => {
        totalCount += articles.length;
      });
    });
    return totalCount;
  };

  const handleOnClick = (data: any, concernData: boolean) => {
    document.getElementsByClassName('containerBoxRight')[0]?.scrollTo(0, 0);
    if (concernData) {
      setConcernDataState(true);
    } else {
      setConcernDataState(false);
    }
    onClick(data, concernData);
  };

  // Get the total count of all articles
  const totalArticleCount = getTotalArticleCount(allArticleData);

  const articlesBySection: Record<string, IArticleData[]> = {};

  allArticleData?.length && allArticleData?.forEach((ele) => {
    const sectionName = ele?.topic || 'Uncategorized'; // Use 'Uncategorized' if no topic is present
    articlesBySection[sectionName] = [...(articlesBySection[sectionName] || []), ele];
  });

  // console.log("allArticleData--", allArticleData)

  const selectedArticleRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Check if the selected article ref exists
    if (selectedArticleRef.current) {
      // Scroll the selected article into view
      selectedArticleRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  }, [expandedIndex]); 

  useEffect(() => {
    const findSectionName = (selectedArticleId: string): string | null => {
      for (const [sectionName, articles] of Object.entries(articlesBySection)) {
        const foundArticle = articles.find(article => article.articleId === selectedArticleId);
        if (foundArticle) {
          return sectionName;
        }
      }
      return null;
    };

    const newName = findSectionName(selectedArticleId);

    const findSectionIndex = (name: string | null): number | null => {
      if (name === null) {
        return null;
      }
      const sectionEntries = Object.entries(articlesBySection);
      for (let i = 0; i < sectionEntries.length; i++) {
        const [sectionName, _] = sectionEntries[i];
        if (sectionName === name) {
          return i;
        }
      }
      return null; // If name is not found in any section
    };

    // Call the function to find the index of the section
    const newIndex = findSectionIndex(newName);

    if (newIndex !== null && newIndex !== expandedIndex) {
      setExpandedIndex(newIndex);
    }
  }, [allArticleData]); 

  return (
    <Box height="100%">
      <Box px={2} display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h5" py={2}>
          {allArticleLoading ? (<MySkeleton variant='text' height={20} width={70}/>) : 'Help'}
        </Typography>
        {/* <Box display='flex' alignItems='center' gap={'8px'}>
          <Typography>All ({totalArticleCount})</Typography>
          <FilterIcon size={34} onClick={handleClickOpenFilter}/>
        </Box> */}
      </Box>
      <Divider />
      <Box height="calc(100% - 74px)" sx={{ overflowY: 'auto' }}>
        {allArticleLoading ? (
          <LibraryLeftBarLoader />
        ) : allArticleData.length === 0 ? (
          <Typography textAlign="center" p={2}>
            No articles have been added yet
          </Typography>
        ) : (
          allArticleData?.length && Object.entries(articlesBySection)?.length &&
          Object.entries(articlesBySection)?.map(([sectionName, articles], index) => {
            // const sectionName = ele.topic;
            // const articles = ele[sectionName];

            // Check if articles exist and are of type array before mapping over them
            // if (!Array.isArray(articles)) {
            //   // console.error(`Invalid data for section ${sectionName}: ${articles}`);
            //   return null; // Skip rendering this section
            // }

            const isExpanded = expandedIndex === index;

            return (
              <Accordion
                key={index}
                sx={{ padding: '0px !important' }}
                expanded={isExpanded}
                onChange={() => setExpandedIndex(isExpanded ? -1 : index)}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="h5" color="secondary">
                    {sectionName}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className="p-0">
                  {articles.map((article: IArticleData, index: number) => (
                    <Box ref={article.articleId === selectedArticleId ? selectedArticleRef : null} key={article.articleId}>
                      <Stack
                        p={2}
                        direction="row"
                        spacing={2}
                        onClick={() => handleOnClick(article, false)}
                        sx={{
                          cursor: 'pointer',
                          background: article.articleId === selectedArticleId && !concernDataState ? '#0071A9' : ''
                        }}
                      >
                        <Box>
                          <img
                            src={article?.coverImageUrl}
                            alt="resumeimg"
                            width="118"
                            height="79"
                            style={{
                              objectFit: 'cover',
                              borderRadius: '8px',
                              minHeight: '79px',
                              minWidth: '118px'
                            }}
                          />
                        </Box>
                        <Box width="calc(100% - 138px)">
                          <Typography
                            variant="h5"
                            className="noWrap-twoLine"
                            sx={{
                              color: article?.articleId === selectedArticleId && !concernDataState ? '#FFFFFF' : '',
                              ...(article?.articleName && !article?.articleName.includes(' ')
                                ? { wordBreak: 'break-all' }
                                : {}) // Conditional word break
                            }}
                          >
                            {article?.articleName}
                          </Typography>
                          {article?.createdAt && (
                            <Typography
                              sx={{
                                color: article?.articleId === selectedArticleId && !concernDataState ? '#FFFFFF' : ''
                              }}
                            >
                              {new Date(article.createdAt * 1000).toLocaleDateString('en-US', {
                                month: 'long',
                                day: 'numeric',
                                year: 'numeric'
                              })}
                            </Typography>
                          )}
                          {/* {article?.readTime} min red */}
                        </Box>
                      </Stack>
                      {index !== articles.length - 1 && <Divider />}
                    </Box>
                  ))}
                </AccordionDetails>
              </Accordion>
            );
          })
        )}

        <Divider />
        {/* Raise a concern */}

        {/* {allArticleData.length !== 0 && ( */}
        <Box
          p={2}
          m={2}
          borderRadius="8px"
          className='cursor-pointer'
          border={concernDataState ? '1px solid transparent' : '1px solid #152536'}
          bgcolor = {concernDataState ? '#0071A9' : '#FFFFFF'}
          onClick={() => handleOnClick(undefined, true)}
        >
          <Box display="flex" gap="8px" mb={1}>
            {allArticleLoading ? (
              <MySkeleton variant='rounded' height={30} width={30}/>
            ) : (
              <FlagOutlinedIcon sx={{ color: concernDataState ? '#FFFFFF' : 'primary' }} />
            )}
            <Typography variant="h5" color={{ color: concernDataState ? '#FFFFFF' : 'primary' }}>
              {allArticleLoading ? (<MySkeleton variant='text' height={20} width={200}/>) : 'Raise a concern'}
            </Typography>
          </Box>
        </Box>
        {/* )} */}
      </Box>
    </Box>
  );
};

export default HelpSideBar;
