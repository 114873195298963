// conversationSlice.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Message {
  id: number;
  sender: string;
  content: string;
  timestamp: string;
}

interface ConversationState {
  conversations: { [conversationID: number]: Message[] };
  currentConversationID?: number; 
  flag : Boolean;
}

const initialState: ConversationState = {
  conversations: {},
  currentConversationID : undefined,
  flag : true
};

const conversationSlice = createSlice({
  name: 'conversation',
  initialState,
  reducers: {
    addMessage: (state, action: PayloadAction<{ conversationID: number; message: Message }>) => {
      console.log('action.payload',action.payload);
      const { conversationID, message } = action.payload;
      if (!state.conversations[conversationID]) {
        state.conversations[conversationID] = [];
      }
      state.conversations[conversationID].push(message);
      // state.conversations[conversationID] = [];


      // Sort messages based on their IDs in ascending order
      state.conversations[conversationID].sort((a, b) => a.id - b.id);
    },
    setConversation: (state, action: PayloadAction<{ conversationID: number; messages: Message[] }>) => {
      const { conversationID, messages } = action.payload;
      state.conversations[conversationID] = messages;

      // Sort messages based on their IDs in ascending order
      state.conversations[conversationID].sort((a, b) => a.id - b.id);
    },
    clearAllConversations: (state, ) => {
      state.conversations = {};
      state.currentConversationID = undefined;
    },
    setCurrentConversationID: (state, action: PayloadAction<number>) => {
      state.currentConversationID = action.payload;
    },
    setHomePage: (state, action: PayloadAction<Boolean>) => {
      state.flag = action.payload;
    },
  },
});

export const { addMessage, setConversation  , clearAllConversations , setCurrentConversationID , setHomePage} = conversationSlice.actions;
export default conversationSlice.reducer;