import React from 'react';
import { Chip, Stack} from '@mui/material';
import MySkeleton from '../../../components/Skeleton';

interface MyChipProps {
  label?: string;
  backgroundColor?: string;
  color?: string;
  fontSize?: number;
  maxWidth?: string;
  border?: string;
  loading?: boolean;
}

function MyChip({
  label = 'Chip Filled',
  backgroundColor = '#6C757D',
  color = '#FFFFFF',
  fontSize = 14,
  maxWidth = 'none',
  border = 'none',
  loading
}: MyChipProps) {
  return (
    <Stack direction="row" spacing={1}>
      {loading ? 
        (
          <MySkeleton variant='rounded' height={32} width={100} sx={{ borderRadius: '20px' }} />
        ) : (
          <Chip
            label={label}
            sx={{
              backgroundColor,
              color,
              fontSize,
              maxWidth,
              border,
            }}
          />
        )}
    </Stack>
  );
}

export default MyChip;
