interface IRole {
  [key: string]: string;
}
interface SideBarComponents {
  [key: string]: IRole;
}

const groupDisplayRoleMappings: SideBarComponents = {
  mentee: {
    Mentee: 'Mentee',
    Mentees: 'Mentees',
    mentee: 'mentee',
    mentees: 'mentees'
  },
  client: {
    Mentee: 'Client',
    Mentees: 'Clients',
    mentee: 'client',
    mentees: 'clients'
  },
  mentor: {
    Mentor: 'Mentor',
    Mentors: 'Mentors',
    mentor: 'mentor',
    mentors: 'mentors',
    mentorship: 'mentorship'
  },
  coach: {
    Mentor: 'Coach',
    Mentors: 'Coaches',
    mentor: 'coach',
    mentors: 'coaches',
    mentorship: 'coaching'
  },
  consultant: {
    Mentor: 'Consultant',
    Mentors: 'Consultants',
    mentor: 'consultant',
    mentors: 'consultants',
    mentorship: 'consulting'
  },
  admin: {
    Admin: 'Admin',
    Admins: 'Admins',
    admin: 'admin',
    admins: 'admins',
    adminship: 'adminship'
  },
  employee: {
    Mentee: 'Employee',
    Mentees: 'Employees',
    mentee: 'employee',
    mentees: 'employees',
    Mentor: 'Employee',
    Mentors: 'Employees',
    mentor: 'employee',
    mentors: 'employees'
  },
  buddy: {
    Mentor: 'Buddy',
    Mentors: 'Buddies',
    mentor: 'buddy',
    mentors: 'buddies'
  },
  student: {
    Mentee: 'Student',
    Mentees: 'Students',
    mentee: 'student',
    mentees: 'students',
    Mentor: 'Student',
    Mentors: 'Students',
    mentor: 'student',
    mentors: 'students'
  },
  faculty: {
    Mentor: 'Faculty',
    Mentors: 'Faculties',
    mentor: 'faculty',
    mentors: 'faculties'
  },
  manager: {
    Mentor: 'Manager',
    Mentors: 'Managers',
    mentor: 'manager',
    mentors: 'managers'
  }
};

export default groupDisplayRoleMappings;
