/* eslint-disable radix */
/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import {
  Dialog,
} from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { API } from '../../../api';
import { getUserDetails } from '../../../utils/orgName';
import { toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { fetchUserWorkHistory, userWorkHistoryActions } from '../../../redux/slices/user-work/userWorkHistorySlice';
import _, { isEmpty } from 'lodash';
import { popupActions } from '../../../redux/slices/onboardingPopupsSlice';
import WorkHistoryForm from './WorkHistoryForm';
import CommonModalHeader from '../../../components/DialogUi/CommonModalHeader';

export interface IWorkHistory {
  company_name: string;
  roleType: string[];
  title: string;
  start_date: string | null;
  end_date: string | null;
  industry: string;
  currentlyWorking: boolean;
}
export interface IWorkHistoryError {
  company_name?: string;
  roleType?: string;
  title?: string;
  start_date?: string;
  end_date?: string | null;
  industry?: string;
  currentlyWorking?: boolean;
}

export interface endDateError {
  end_date?: string | null;
}

export interface startDateError {
  start_date?: string | null;
}

const WorkHistoryModal = () => {
  const dispatch = useAppDispatch();
  const { open, action, item, workHistoryDisable } = useAppSelector((state) => state.onboardingPopups.data.workHistory);

  const handleCloseWorkHistory = () => {
    if(!workHistoryDisable){
      dispatch(popupActions.updatePopups({ workHistory: { open: false, workHistoryDisable:false, action, item } }));
    }
    
  };

  useEffect(() => {
    return () => {
      handleCloseWorkHistory();
    };
  }, []);
  return (
    <Dialog
      open={open}
      onClose={handleCloseWorkHistory}
    >
      <CommonModalHeader 
        title={`${action} Work History`}
        handleCloseModal={handleCloseWorkHistory}
      />
      <WorkHistoryForm />
    </Dialog>
  );
};

export default WorkHistoryModal;
