import {
  Dialog,
  Typography,
  DialogActions,
  DialogContent,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import CommonModalHeader from '../../../../../components/DialogUi/CommonModalHeader';

interface Props {
  matchesCount: any;
  confirmedCount: any;
  modelConfirmMatch: any;
  handleCloseConfirmMatch: any;
  handelSubmitConfirmMatch: any;
  handleConfirmAll: any;
  loaderFirstPopup: any;
  loaderFirstPopup1: any;
}

const ConfirmationPopup = ({
  matchesCount,
  confirmedCount,
  modelConfirmMatch,
  handleCloseConfirmMatch,
  handelSubmitConfirmMatch,
  handleConfirmAll,
  loaderFirstPopup,
  loaderFirstPopup1
}: Props) => {
  return (
    <Dialog
      open={modelConfirmMatch}
      onClose={handleCloseConfirmMatch}
      fullWidth
    >
      <CommonModalHeader 
        title={`Confirmation`}
        handleCloseModal={handleCloseConfirmMatch}
      />
      <DialogContent>
        <Typography variant='h5' textAlign={'center'}>
          You have {matchesCount + confirmedCount} match(es) that require confirmation. You can confirm all matches or just this one. Once you confirm all parties will be notified and their mentoring relationship will begin.
          {/* You have {matchesCount + confirmedCount} auto matches that require confirmation to become active. You can confirm them all at once or confirm each match individually. Once you confirm, both parties will be notified of the match and their mentoring relationship will begin. */}
        </Typography>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          fullWidth
          color='secondary'
          variant='outlined'
          loading={loaderFirstPopup}
          onClick={handelSubmitConfirmMatch}
        >
          Confirm this match
        </LoadingButton>

        <LoadingButton
          fullWidth
          color='primary'
          variant='contained'
          loading={loaderFirstPopup1}
          onClick={handleConfirmAll}
        >
          Confirm all matches
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationPopup;
