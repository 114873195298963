import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  count: 0,
  popuplength: 0
};

const popupsCountsSlice = createSlice({
  name: 'popups count',
  initialState,
  reducers: {
    reset: (state, action) => {
      state.count = 0;
    },
    increment: (state, action) => {
      state.count = state.count + 1;
    },
    decrement: (state, action) => {
      state.count = state.count - 1;
    },
    setPopupLength: (state, action) => {
      state.popuplength = action.payload;
    }
  }
});

export default popupsCountsSlice.reducer;
export const popupCountActions = popupsCountsSlice.actions;
