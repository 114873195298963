import moment from 'moment';

export const convertToEpoch = (date: Date) => {
  return Math.floor(date.getTime() / 1000);
};

export const convertEpochToDate = (epoch: number) => {
  return new Date(epoch * 1000);
};

export const isDueDateValid = (startDate: any, dueDate: any) => {
  // Check if both dates exist
  if (startDate && dueDate) {
    // Convert Unix timestamps to moments and compare
    return moment.unix(dueDate).isAfter(moment.unix(startDate));
  }
  // If one or both dates don't exist, return true as the condition doesn't apply
  return true;
};
