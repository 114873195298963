import { Box, Grid } from "@mui/material";
import MySkeleton from "../components/Skeleton";

export const MembersLoader = () => (
  <Box className="boxBorder" minHeight="400px">
    <Box display="flex" justifyContent="space-between" alignItems="center" p={2}>
      <MySkeleton width={100}/>
    </Box>
    <MySkeleton height={2}/>
    <Box py={2}>
      <Box display="flex" alignItems="center" justifyContent="center" px={2}>
        <Box textAlign="center" py={2} >
          <MySkeleton variant='rounded' height={70} width={70}/>
        </Box>
      </Box>
      {[...Array(3).keys()].map((_, index) => (
        <Box px={2} key={index}>
          <Box display="flex" justifyContent="space-between" py={'16px'}>
            <MySkeleton width={100}/>
            <MySkeleton width={20}/>
          </Box>
          {index !== 2 && <MySkeleton height={2}/>}
        </Box>
      ))}
    </Box>
  </Box>
);

export const EngagementChartLoader = () => (
  <Box className="boxBorder">
    <Box display="flex" justifyContent="space-between" alignItems="center" p={2}>
      <MySkeleton width={100}/>
      <Box display="flex" alignItems="center" gap="20px">
        {[...Array(4).keys()].map((_, index) => (
          <Box key={index} display="flex" alignItems="center" gap="12px">
            <MySkeleton width={60}/>
          </Box>
        ))}
      </Box>
    </Box>
    <MySkeleton height={2}/>
    <Box p={2}>
      <Box width="100%" height="165px" mb={2}>
        <MySkeleton variant='rounded' height={165} width={'100%'}/>
      </Box>
      <Box>
        <Grid container spacing={2}>
          {[...Array(4).keys()].map((_, index) => (
            <Grid item xs={12} sm={6} lg={3} key={index}>
              <Box className="boxBorder" height={'100%'} minHeight="146px">
                <MySkeleton variant='rounded' height={'100%'}/>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  </Box>
);
