import React, { FC, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  Divider,
  Accordion,
  Typography,
  DialogActions,
  DialogContent,
  AccordionDetails,
  AccordionSummary
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CommonModalHeader from '../../../../../components/DialogUi/CommonModalHeader';
import { FilterCheckField, FilterSearch } from '../../../components/filter/FilterProperty';
import { contentTypeArray, industryArray, topicArray } from '../../../components/common';
import FilterLoader from '../../../components/filter/FilterLoader';

interface Props {
  openFilter: boolean;
  handleClickApply: () => void;
  handleTypeChange: (type: string) => void;
  selectedTypes: string[];
  handleTagChange: (tag: string) => void;
  selectedTags: string[];
  handleresetFilter: () => void;
  handleClickCloseIcon: () => void;
  tagList: string[];
  industryTags: string[];
  selectedIndustryTags: string[];
  handleIndustryChange: (industry: string) => void;
  loading?: boolean;
}

const LibraryFilter: FC<Props> = ({
  openFilter,
  handleClickApply,
  selectedTypes,
  handleTypeChange,
  handleTagChange,
  selectedTags,
  handleresetFilter,
  handleClickCloseIcon,
  tagList,
  industryTags,
  selectedIndustryTags,
  handleIndustryChange,
  loading
}: Props) => {
  const uniqueTagslist = [...new Set(tagList)];
  let contentType: any[] = contentTypeArray();
  const topics = topicArray();
  const industries = industryArray();
  const [searchTopics, setSearchTopics] = useState<string>('');
  const handleSearchTopics = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTopics(event.target.value);
  };

  const industryList = industryTags.map((tag) => {
    const index = industries.findIndex((each) => each.name === tag);
    if (index !== -1) {
      return { label: industries[index].label, name: industries[index].name };
    }
    return { label: tag, name: tag };
  });

  const topicsList = uniqueTagslist.map((tag) => {
    const index = topics.findIndex((each) => each.name === tag);
    if (index !== -1) {
      return { label: topics[index].label, name: topics[index].name };
    }
    return { label: tag, name: tag };
  });
  const filteredTopicsList = topicsList.filter((tags) => tags.name.toLowerCase().includes(searchTopics.toLowerCase()));

  const handleCloseModal = () => {
    setSearchTopics('');
    handleClickCloseIcon();
  };

  return (
    <Dialog open={openFilter} fullWidth onClose={handleCloseModal}>
      <CommonModalHeader title="Filter" handleCloseModal={handleCloseModal} />
      <DialogContent>
        {loading ? (<FilterLoader/>):
          <>
            {/* Content type */}
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="content-content"
                id="content-header"
                className="px-0 filterAccordionSummary"
              >
                <Typography variant="h5">Content type</Typography>
              </AccordionSummary>
              <AccordionDetails className="px-0 pb-0">
                {contentType.map((contentType, index) => (
                  <FilterCheckField
                    key={index}
                    label={contentType}
                    onChange={handleTypeChange}
                    checked={selectedTypes.includes(contentType)}
                  />
                ))}
              </AccordionDetails>
            </Accordion>
            <Divider />
            {/* Topics */}
            {uniqueTagslist.length > 0 && (
              <>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="Tags-content"
                    id="Tags-header"
                    className="px-0 filterAccordionSummary"
                  >
                    <Typography variant="h5">Topics</Typography>
                  </AccordionSummary>
                  <AccordionDetails className="px-0 pb-0">
                    <Box pb={1}>
                      <FilterSearch
                        userTypeDate={{ placeholderText: 'Search Topics' }}
                        value={searchTopics}
                        onChange={handleSearchTopics}
                      />
                    </Box>
                    <Box>
                      {filteredTopicsList.length > 0 ? (
                        filteredTopicsList
                          .sort((a, b) => a.name.localeCompare(b.name))
                          .map((tags, index) => (
                            <FilterCheckField
                              key={index}
                              label={tags.label}
                              onChange={handleTagChange}
                              checked={selectedTags.includes(tags.name)}
                            />
                          ))
                      ) : (
                        <Typography textAlign="center" pt={1}>
                          No data found
                        </Typography>
                      )}
                    </Box>
                  </AccordionDetails>
                </Accordion>
                <Divider />
              </>
            )}
            {/* Industry */}
            {industryList.length > 0 && (
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="industry-content"
                  id="industry-header"
                  className="px-0 filterAccordionSummary"
                >
                  <Typography variant="h5">Industry</Typography>
                </AccordionSummary>
                <AccordionDetails className="px-0 pb-0">
                  {industryList
                    ?.sort((a, b) => a.name.localeCompare(b.name))
                    ?.map((industry, index) => {
                      return (
                        <FilterCheckField
                          key={index}
                          label={industry.label}
                          onChange={handleIndustryChange}
                          checked={selectedIndustryTags.includes(industry.name)}
                        />
                      );
                    })}
                </AccordionDetails>
              </Accordion>
            )}
          </>
        }
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" fullWidth onClick={handleresetFilter}>
          Reset
        </Button>
        <Button variant="contained" fullWidth onClick={handleClickApply}>
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LibraryFilter;
