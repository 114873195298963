import React from 'react';
import { Button, Dropdown, Menu } from 'antd';
import classNames from 'classnames';
import { UpOutlined } from '@ant-design/icons';
import { IconFont } from '../../../component/icon-font';
import { getAntdDropdownMenu, getAntdItem } from './video-footer-utils';
import leave from '../../../component/svgs/icon-leave.svg';
const { Button: DropdownButton } = Dropdown;
const { Item: MenuItem } = Menu;
interface LeaveButtonProps {
  onLeaveClick: () => void;
  onEndClick: () => void;
  isHost: boolean;
}

const LeaveButton = (props: LeaveButtonProps) => {
  const { onLeaveClick, onEndClick, isHost } = props;

  //   return isHost ? (
  //     <DropdownButton
  //       className="vc-dropdown-button"
  //       size="large"
  //       menu={getAntdDropdownMenu([getAntdItem('End session', 'end')], onEndClick)}
  //       trigger={['click']}
  //       type="ghost"
  //       onClick={onLeaveClick}
  //       icon={<UpOutlined />}
  //       placement="topRight"
  //     >
  //       <IconFont type="icon-leave" />
  //     </DropdownButton>
  //   ) : (
  //     <Button
  //       className={classNames('vc-button')}
  //       icon={<IconFont type="icon-leave" />}
  //       // eslint-disable-next-line react/jsx-boolean-value
  //       ghost={true}
  //       shape="circle"
  //       size="large"
  //       onClick={onLeaveClick}
  //       title="Leave session"
  //     />
  //   );
  return isHost ? (
    // <DropdownButton
    //   className="vc-dropdown-button"
    //   size="large"
    //   menu={getAntdDropdownMenu([getAntdItem('End session', 'end')], onEndClick)}
    //   trigger={['click']}
    //   type="ghost"
    //   onClick={onLeaveClick}
    //   icon={<UpOutlined />}
    //   placement="topRight"
    // >
    //   <IconFont type="icon-leave" />
    // </DropdownButton>
    <Button
      className="Leavebtn"
      ghost={true}
      onClick={onLeaveClick}
      title="Leave session"
    >
      <img src={leave} alt='' />
    </Button>
  ) : (
    <Button
      className="Leavebtn"
      // className={classNames('vc-button')}
      // icon={<LeaveIcon />}
      // eslint-disable-next-line react/jsx-boolean-value
      ghost={true}
      // shape="circle"
      // size="large"
      onClick={onLeaveClick}
      title="Leave session"
    >
      <img src={leave} alt='' />
    </Button>
  );
};

export { LeaveButton };
