/* eslint-disable react-hooks/exhaustive-deps */
import React, { SyntheticEvent, useEffect, useRef } from 'react';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
// import MoreVertIcon from '@mui/icons-material/MoreVert';
import _ from 'lodash';
import RoleTypeTag from '../components/RoleTypeTag';
import MySkeleton from '../../../components/Skeleton';
import ChatAvatarComponent from './chat/ChatAvatarComponent';

interface CommunitySidebar{
  loading?: boolean;
}

const CommunitySidebar: React.FC<any> = ({ mem, selectedMember, onClickOnUserIcon, loading, openFilter }) => {
  const isActive: any = mem?.id === selectedMember?.id;
  const cat: any = mem?.category;

  const useStyles = makeStyles({
    chatPersonWrapper: {
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: 'rgba(0,0,0,0.1)'
      }
    },

    activeChatPersonWrapper: {
      backgroundColor: '#0071A9'
    }
  });

  const refrence: any = useRef(null);
  const classes = useStyles();

  useEffect(() => {
        mem?.id === selectedMember?.id && refrence?.current?.scrollIntoView();
  }, [refrence?.current]);

  const addImageFallback = (event: SyntheticEvent<HTMLImageElement, Event>, displayName: string) => {
    event.currentTarget.src = `https://ui-avatars.com/api/?name=${displayName}`;
  };

  const formatBio = (name1: string, name2: string) => {
    if (!name1 || !name2) {
      return '';
    }
    return (
      <>
        <Typography noWrap color={isActive ? '#FFFFFF !important' : '#68717A'}>{name1.concat(',').concat(name2)}</Typography>
      </>
    );
  };

  const getBio = (mem: any) => {
    const category: any = mem?.category;
    const major: string = mem?.bio?.education?.major;
    const university: string = mem?.bio?.education?.university;
    const role: any = mem?.bio?.workHistory?.role;
    const company: any = mem?.bio?.workHistory?.companyName;

    return category === 'Student' ? formatBio(major, university) : formatBio(role, company);
  };

  // const getName = (mem: any) => {
  //   const chatProfileFirstName: any = _.get(mem, 'id.firstName', '');
  //   const chatProfileLastName: any = _.get(mem, 'id.lastName', '');

  //   return chatProfileFirstName
  //     ?.charAt(0)
  //     ?.toUpperCase()
  //     ?.concat(chatProfileFirstName?.slice(1)?.toLowerCase())
  //     ?.concat(
  //       ' ',
  //       chatProfileLastName?.charAt(0)?.toUpperCase()?.concat(chatProfileLastName?.slice(1)?.toLowerCase())
  //     );
  // };

  return (
    <Box display={'flex'} alignItems={'center'} gap={'8px'}
      sx={{
        borderBottom: '1px solid #EFF0F4',
        cursor: 'pointer',
      }}
      p={2}
      onClick={() => {
        // setMember(mem);
        onClickOnUserIcon(mem);
      }}
      className={isActive ? classes.activeChatPersonWrapper : classes.chatPersonWrapper}
      ref={refrence}
    >
      <Box>
        <ChatAvatarComponent
          image={mem?.headshot}
          type="noStatus"
          firstLetter={mem?.displayName.charAt(0)}
          width="48px"
          height="48px"
          loading={loading}
        />
      </Box>
      <Box width='calc(100% - 56px)'>
        <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} gap={'5px'}>
          <Typography variant='h5' noWrap color={isActive ? '#FFFFFF !important' : '#152536 !important'}>
            {loading ? ( <MySkeleton variant="text" height={'16px'} width={150} />  ) : ( _.get(mem, 'displayName', ''))}
          </Typography>

          <RoleTypeTag category={mem?.category} loading={loading} />
        </Box>
        <Box width={'100%'}>
          {loading ? ( <MySkeleton variant="text" height={'14px'} width={'100%'} /> ) : ( getBio(mem) )}
        </Box>
      </Box>
        
      {/* <Box>
        <MoreVertIcon color='primary' />
      </Box> */}
    </Box>
  );
};
export default CommunitySidebar;
