import { Box, Button, Typography } from '@mui/material';
import TaskAltSharpIcon from '@mui/icons-material/TaskAltSharp';
import _ from 'lodash';
import useResponsiveScreenSwitch from '../../../../../utils/useResponsiveScreenSwitch';

interface AccessingChatMsgProps {
  exitChat: () => void;
  displayName: string;
  formattedAcsOrgUsers: any;
}

const AccessingChatMsg: React.FC<AccessingChatMsgProps> = ({ exitChat, displayName, formattedAcsOrgUsers }) => {
  /** For responsive */
  const { forMobile } = useResponsiveScreenSwitch(); // Use the hook
  const getUser = _.get(formattedAcsOrgUsers, displayName);
  // console.log('fdf0', _.get(formattedAcsOrgUsers, displayName));
  return (
    <Box
      display="flex"
      flexDirection={forMobile ? 'column' : 'row'}
      gap="8px"
      alignItems="center"
      p={forMobile ? 1 : 2}
      bgcolor="#FFF9F9"
      border="1px solid #DC3545"
      borderRadius="8px"
      justifyContent="space-between"
      mx={2}
      mt={2}
    >
      <Box display="flex" gap="8px">
        <Box bgcolor="#DC3545" borderRadius="50%" display="flex" p={0.5} minWidth="36px" height="36px">
          <TaskAltSharpIcon sx={{ width: '18px', height: '18px', color: '#FFFFFF', margin: 'auto' }} />
        </Box>
        <Box>
          <Typography variant={forMobile ? 'h6' : 'h5'}>
            You are accessing the chat of {getUser?.displayName}
          </Typography>
          <Typography variant={forMobile ? 'body2' : 'body1'}>
            You have the ability to delete messages or leave groups on behalf of this member.
          </Typography>
        </Box>
      </Box>
      <Box>
        <Button
          color="error"
          variant="contained"
          sx={{
            fontSize: '14px'
          }}
          onClick={exitChat}
        >
          End access
        </Button>
        {/* <Box bgcolor={'#DC3545'} borderRadius={'8px'} py={'8px'} px={'10px'}>
                <Typography color={'#FFFFFF'} textAlign={'center'}>
                    End access
                </Typography>
            </Box> */}
      </Box>
    </Box>
  );
};

export default AccessingChatMsg;
