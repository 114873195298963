/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import { Box, Typography } from '@mui/material';
import _ from 'lodash';
import MySkeleton from '../../../../components/Skeleton';
import { ChatAvatarStyles } from '../../../../utils/muiStyle';
import { getUserDetails } from '../../../../utils/orgName';
import { alphaColorsNew } from '../../../../utils/alphabates-colors';
// import redOnlineDot from '../../../../assets/images/redOnlineDot.svg';
import greenOnlineDot from '../../../../assets/images/greenOnlineDot.svg';

/**
 * Chat avatar component
 */
interface IChatAvatarComponent {
  image?: string | null | undefined;
  firstLetter?: any;
  onClickOnUserIcon?: () => void;
  width?: string;
  height?: string;
  type?: 'self' | 'other' | 'home' | 'noStatus';
  fontSize?: string;
  border?: string;
  cursor?: string;
  loading?: boolean;
  variant?: 'circle' | 'rectangle'
}

const ChatAvatarComponent: React.FC<IChatAvatarComponent> = ({
  image,
  firstLetter,
  onClickOnUserIcon,
  width = '50px',
  height = '50px',
  type = 'other',
  fontSize,
  border = 'none',
  cursor = '',
  loading,
  variant = 'rectangle'
}) => {
  const userFirstLetter = getUserDetails()?.name?.slice(0, 1);
  const classes = ChatAvatarStyles({ variant: variant, loading: loading, border: border });

  /** if image not support */
  const addImageFallback = (event: React.SyntheticEvent<HTMLImageElement, Event>, firstLetter: string) => {
    const { currentTarget } = event;
    currentTarget.src = `https://ui-avatars.com/api/?name=${firstLetter}`;
  };

  /** Online offline status check */
  const onlineOfflineStatus = greenOnlineDot; //redOnlineDot
  /** Set type to 'noStatus' if loading is true */
  const avatarType = loading ? 'noStatus' : type;

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      position="relative"
    >
      <Box
        onClick={onClickOnUserIcon}
        className={onClickOnUserIcon || cursor === 'pointer' ? 'cursor-pointer' : ''}
        position={'relative'}
        justifyContent="center"
        alignItems="center"
        display="flex"
        bgcolor={
          image || loading ? 'transparent' : _.get(alphaColorsNew, _.capitalize(firstLetter?.charAt(0)), '')
        }
        width={width}
        height={height}
        border={image || loading ? '' : border}
        borderRadius={image || loading ? '0' : variant === 'circle' ? '50%' : '8px'}
      >
        {loading ? ( //|| !firstLetter || !image
          <MySkeleton
            width={width}
            height={height}
            border={border}
            variant={variant === 'circle' ? 'circular' : "rounded"}
          // className={!firstLetter || !image && cursor === 'pointer' ? 'cursor-pointer' : ''}
          />
        ) : (
          _.size(image) ? (
            <img
              src={image || ''}
              alt=""
              className={classes.avatarImg}
              referrerPolicy="no-referrer"
              onError={(event: any) => addImageFallback(event, firstLetter)}
            />
          ) : (
            <>
              {avatarType === 'self' ? (
                <Typography color="inherit">{_.capitalize(userFirstLetter)}</Typography>
              ) : (
                <Typography
                  color={"#FFFFFF"}
                  fontWeight={'600'}
                  fontSize={fontSize ? fontSize : '16px'}
                >
                  {firstLetter?.toUpperCase()}
                </Typography>
              )}
            </>
          )
        )}
      </Box>

      {/* Online offline Dot */}
      {avatarType !== 'noStatus' && (
        <img
          src={onlineOfflineStatus}
          alt=''
          width={'10px'}
          height={'10px'}
          className={classes.noStatus}
        />
      )}
    </Box>
  );
};

export default ChatAvatarComponent;
