/**
 * Chat all places unreade notification Box design
 * */

import { Box, Typography } from "@mui/material";

type ChatUnreadNotificationsBoxProps = { 
    count?: string | number;
    printAres?: string;
    top?: string;
    right?: string;
};

const ChatUnreadNotificationsBox: React.FC<ChatUnreadNotificationsBoxProps> = ({ count, printAres, top, right }) => {
    
    const countNumber = typeof count === 'number' ? count : parseInt(count ?? '0', 10);
    const displayNumber = countNumber > 9 ? '9+' : countNumber.toString(); // Determine the text to be displayed based on the count
    const getTop = printAres === 'sidebar' ? '-5px' : '0px';
    const getRight = printAres ===  'sidebar' ? right : '0px';

    return (
        <Box
            top={getTop}
            right={getRight}
            height='20px'
            display='flex'
            paddingX='3px'
            minWidth='20px'
            bgcolor='#DF6438'
            alignItems='center'
            borderRadius='15px'
            boxSizing='border-box'
            justifyContent='center'
            position={printAres === 'sidebar' ? 'absolute' : 'relative'}
        >
            <Typography fontSize="11px" variant="caption" lineHeight={'normal'} color={'#FFFFFF'}>
                {displayNumber}
            </Typography>
        </Box>
    );
}

export default ChatUnreadNotificationsBox;
