import React from 'react';
import { Dialog, DialogActions, DialogContent, Button, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import CommonModalHeader from '../../../../../components/DialogUi/CommonModalHeader';

interface Props {
  openDelete: boolean;
  setOpenDelete: any;
  deleteLoader: boolean;
  handeleDeleteMatch: any;
}

const MatchDeleteDialog = ({ openDelete, setOpenDelete, deleteLoader, handeleDeleteMatch }: Props) => {
  return ( 
    <Dialog
      open={openDelete}
      onClose={() => setOpenDelete(false)}
      fullWidth
    >
      <CommonModalHeader 
        title={`Confirmation`}
        handleCloseModal={() => setOpenDelete(false)}
      />
      <DialogContent>
        <Typography variant='h5' textAlign={'center'}>
          Are you sure you want to <br/> remove this match?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          fullWidth
          color='error'
          variant='outlined'
          onClick={() => setOpenDelete(false)}
        >
          Cancel
        </Button>
        <LoadingButton
          fullWidth
          color="primary"
          variant='contained'
          loading={deleteLoader}
          onClick={handeleDeleteMatch}
        >
          Remove match
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default MatchDeleteDialog;
