import React, { useState } from 'react';
import {
  Box,
  Button,
  Typography,
} from '@mui/material';
import { LiveChatStyle } from './LiveChatStyle';
import ChatAvatarComponent from '../chat/ChatAvatarComponent';
import circleicon from '../../../../assets/images/circleicon.svg';

const Livechat: React.FC = () => {
  const classes = LiveChatStyle();
  const [text, setText] = useState('');
  const chatData = [
    {
      "type": "Dosen",
      "text": `Hi, John! Let’s create a great resume together.\n\nFirst, I have a few questions to get us started.\n\nWhat is the seniority level of the type of role that you’re targeting with this resume?`,
      "suggestions": ['Associate / Junior Level', 'Mid level', 'Senior Level', 'Management level', 'Executive level']
    },
  ]

  // const [chat, setChat] = useState<any>([]);
  // const [chatTime, setChatTime] = useState<any>('');

  // useEffect(() => {
  //   // Simulate a delay in responding to member messages (like a real chatbot)
  //   const simulateChat = async () => {
  //     for (const message of chatData) {
  //       setChat((prevChat:any) => [...prevChat, message]);
  //       setChatTime(new Date().toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }))
  //       await new Promise((resolve) => setTimeout(resolve, 1000)); // Simulated delay
  //     }
  //   };

  //   simulateChat();
  // }, []);


  return (
    <>
      {chatData.map((message: any, index: any) => (
        <Box key={index} className={message.type === 'user' ? classes.msgBox : classes.msgBox}>
          <ChatAvatarComponent
            variant='circle'
            firstLetter={'Dosen'}
            image={circleicon}
            height='34px'
            width='34px'
            border='1px solid #000000'
          />
          <Box>
            <Box display='flex' gap='8px'>
              <Typography variant='h5'>{message.type === 'Dosen' ? 'Dosen' : 'User'}</Typography>
              <Typography>{new Date().toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}</Typography>
            </Box>
            <Box>
              <Typography sx={{ whiteSpace: 'pre-line' }}>{message.text}</Typography>
            </Box>
            {message.suggestions && message.suggestions.length > 0 && (
              <Box display='flex' gap='8px' flexWrap='wrap' flexDirection={'column'} id={index}>
                {message.suggestions.map((suggestion: any, index: any) => (
                  <Button key={index} className={classes.btn} sx={{ width: '300px', height: '40px', backgroundColor: '#EFF0F4', justifyContent: 'left', '&:hover': { backgroundColor: '#0071A9', color: 'white' } }}>
                    {suggestion}
                  </Button>
                ))}
              </Box>
            )}
            {message?.suggestions?.map((each: any, index: any) => {
              <Box display='flex' gap='8px' flexWrap='wrap' flexDirection={'column'} id={index}>
                <Button className={classes.btn} sx={{ width: '300px', height: '40px', backgroundColor: '#EFF0F4', justifyContent: 'left', '&:hover': { backgroundColor: '#0071A9', color: 'white' } }}>{each}</Button>
              </Box>
            })
            }
          </Box>
        </Box>
      ))}

      {/* <Box className={classes.msgBox}>
        <Box>
          <ChatAvatarComponent
            firstLetter={'Dosen'}
            image={circleicon}
            height='34px'
            width='34px'
            border='1px solid #000000'
          />
        </Box>
        <Box>
          <Box display='flex' gap='8px'>
            <Typography variant='h5'>Dosen</Typography>
            <Typography>8:46 AM</Typography>
          </Box>
          <Box>
            <Typography>What do you want's to do?</Typography>
            <Typography sx={{ whiteSpace: 'pre-line' }}>
              {`Hi, John! Let’s create a great resume together.

              First, I have a few questions to get us started.

              What is the seniority level of the type of role that you’re targeting with this resume?`}
            </Typography>
          </Box>
          <Box display='flex' gap='8px' flexWrap='wrap' flexDirection={'column'}>
            <Button className={classes.btn} sx={{
              width: '300px',
              height: '40px',
              backgroundColor: '#EFF0F4',
              justifyContent: 'left',
              '&:hover': { backgroundColor: '#0071A9', color: 'white' },
              '&:active': {
                backgroundColor: '#0071A9', color: 'white'
              }
            }}>Set a new goal</Button>
            <Button className={classes.btn} sx={{ width: '300px', height: '40px', backgroundColor: '#EFF0F4', justifyContent: 'left', '&:hover': { backgroundColor: '#0071A9', color: 'white' } }}>Help with my current goal</Button>
            <Button className={classes.btn} sx={{ width: '300px', height: '40px', backgroundColor: '#EFF0F4', justifyContent: 'left', '&:hover': { backgroundColor: '#0071A9', color: 'white' } }}>Ask a question to my community</Button>
          </Box>
        </Box>
      </Box> */}

      {text && <Box className={classes.msgBox}>
        <Box>
          <ChatAvatarComponent
            variant='circle'
            firstLetter={'Dosen'}
            image={circleicon}
            height='34px'
            width='34px'
            border='1px solid #000000'
          />
        </Box>
        <Box>
          <Box display='flex' gap='8px'>
            <Typography variant='h5'>Member</Typography>
            <Typography>8:46 AM</Typography>
          </Box>
          <Box>
            <Typography>{text}</Typography>
          </Box>
        </Box>
      </Box>}
    </>
  );
};

export default Livechat;
