import { Box, Typography } from '@mui/material'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Navigate, useNavigate } from 'react-router-dom';

export const NewChatBot = ({
}: any) => {
  const Navigate = useNavigate();
  const clickAskDosen = () => { Navigate('/app/askDosen/promtChat'); }

  return (
    <Box>
      <Typography color={'#FFFFFF'} mb={2} sx={{ opacity: '.5' }} >
        Use the prompts below to help you create a winning resume for your next job.
      </Typography>

      <Typography sx={{ color: '#FFFFFF', cursor: 'pointer' }} fontSize={'16px'} mb={2} onClick={clickAskDosen}>
        Create a résumé <ArrowForwardIcon />
      </Typography>
      <Typography sx={{ color: '#FFFFFF', cursor: 'pointer' }} fontSize={'16px'} mb={2} onClick={clickAskDosen}>
        Write a cover letter <ArrowForwardIcon />
      </Typography>
      <Typography sx={{ color: '#FFFFFF', cursor: 'pointer' }} fontSize={'16px'} mb={2} onClick={clickAskDosen}>
        Tailor your résumé for a specific job <ArrowForwardIcon />
      </Typography>
    </Box>
  )
}