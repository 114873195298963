import React from 'react';
import {
    Box,
    Divider,
    IconButton,
    Typography,
} from '@mui/material';
import { EditIcon } from '../../../utils/Icons';
import MySkeleton from '../../../components/Skeleton';

interface Props {
    heading: string;
    editing?: boolean;
    divider?: boolean;
    loading?: boolean;
    data?: string | undefined; // Changed the type to string | undefined
}

const TextAndLineSection: React.FC<Props> = ({
    heading,
    editing = false,
    loading = false,
    divider = false,
    data
}) => {

    return (
        <Box pb={divider ? 0 : 2}>
            <Box
                px={divider ? 2 : 0}
                display='flex'
                alignItems='center'
                justifyContent='space-between'
            >
                <Typography variant='h5' py={2}>
                    {loading ? (<MySkeleton variant="text" height={16} width={50} />) : heading }
                </Typography>
                {/* Edit icon */}
                {editing && (
                    <IconButton size='small'>
                        <EditIcon />
                    </IconButton>
                )}
            </Box>
            {/* Divider for profile page */}
            {divider && <Divider />}

            <Box p={divider ? 2 : 0}>
                {loading ? (
                    <>
                        <MySkeleton variant="text" height={16} width="100%" />
                        <MySkeleton variant="text" height={16} width="100%" />
                    </>
                ) : 
                    data !== undefined && data !== '' ?  data : 'Data not found'
                }
            </Box>
        </Box>
    );
};

export default TextAndLineSection;
