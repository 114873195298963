import React from 'react';
import { Badge } from '@mui/material';
import { AGILE_ALLOWED_CATEGORY_COMMUNITY } from '../../../utils/constants';
import { formattedCategory } from './common';
import MySkeleton from '../../../components/Skeleton';

interface RoleTypeTagProps {
  category?: string;
  place?: string;
  loading?: boolean;
  selected?: boolean;
  onClick?: () => void;
}

const RoleTypeTag: React.FC<RoleTypeTagProps> = ({ category, place, loading, selected, onClick }) => {
  const useColor =
    category === 'Student'
      ? '#2955C7'
      : category === 'Faculty & Staff'
        ? '#1AA179'
        : category === 'Mentor' || (category && AGILE_ALLOWED_CATEGORY_COMMUNITY.includes(category))
          ? '#E99940'
          : category === 'Alumni'
            ? '#C7A429'
            : category === 'Mentee' || category === 'Client'
              ? '#0071A9'
              : category === 'Admin'
                ? '#28A745'
                : '#152536';

  const selectedColor = '#0071A9'; // Color when selected
  const unselectedColor = '#ADB5BD'; // Color when not selected

  const useBorderColor = selected ? selectedColor : unselectedColor;
  const useTextColor = selected ? selectedColor : unselectedColor;

  const usePadding = place === 'userTable' ? '0px 8px' : '4px 8px';

  return (
    loading ?
      <MySkeleton variant='rounded' height={20} width={70} />
      :
      <Badge
        sx={{
          background: '#FFFFFF',
          lineHeight: '16.34px',
          fontSize: '12px',
          fontWeight: '600',
          padding: usePadding,
          borderRadius: '5px',
          border: `1px solid ${onClick ? useBorderColor : useColor}`,
          color: onClick ? useTextColor : useColor,
          cursor: onClick ? 'pointer' : 'default',
        }}
        onClick={onClick}
      >
        {category ? (place === 'community' ? formattedCategory(category) : category) : 'Unknown'}
      </Badge>
  );
};

export default RoleTypeTag;
