import { topicArray } from '../pages/new-designs/components/common';

const topics = topicArray();
export const camelCaseToCapital = (topicsArray: string[]) => {
  return topicsArray.map((tag: string) => {
    const index = topics.findIndex((each) => each.name === tag);
    if (index !== -1) {
      return topics[index].label;
    } else {
      return tag;
    }
  });
};

export const capitalToCamelCaseTopics = (topicsArray: string[]) => {
  return topicsArray.map((tag: string) => {
    const index = topics.find((each) => each.label === tag);
    if (index) {
      return index.name;
    } else {
      return tag;
    }
  });
};
