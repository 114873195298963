import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from '../../../api';
export interface IUserData {
  name: any;
  email: string;
  country: string;
  firstName: string;
  lastName: string;
  timezone: string;
  headshot: string;
  dob: string;
  ftue: boolean;
  phoneNumber: string;
  phonePrefix: string;
  gender: string;
  ethnicity: string;
  firstGenStudent: string;
  bio: string;
  isActive: boolean;
  communicationId: string;
  social: {
    facebook: string;
    instagram: string;
    youtube: string;
    twitter: string;
    linkedIn: string;
  };
  // social: string[];
  disability: string[];
  category: string;
  hobbies: string[];
  // photo?: File;
}

interface IData {
  personal_details: IUserData;
  gettingStarted: {
    editTasks: boolean;
    nonEditTasks: boolean;
  };
  admins: string[];
  mentees: string[];
  mentors: string[];
  connection: string;
  lastseen: undefined | { id: string; name: string; role: string; active: boolean };
  settings: {
    gender: boolean;
    country: boolean;
    firstGenerationStudent: boolean;
    ethnicity: boolean;
    dob: boolean;
    disabilityType: boolean;
    category: boolean;
    timezone: boolean;
    workHistory: boolean;
    educationHistory: boolean;
    hobbiesInterests: boolean;
    company: boolean;
  };
  role: Number;
  bio: Object;
  videoIntro: string;
  upVote: number;
  aiMentor: boolean;
  topics: any;
  selectedTopics: any;
  industries: any;
  selectedIndustries: any;
  educationHistory: any;
  workHistory: any;
}

interface IUser {
  loading: boolean;
  error: boolean;
  errorText: string;
  data: IData | undefined;
  bioTextCount: number;
}

const initialState: IUser = {
  loading: false,
  error: false,
  errorText: '',
  data: undefined,
  bioTextCount: 0
};

export const fetchUserProfile = createAsyncThunk('userprofile', () => {
  return API.getUserProfile();
});

const userProfileSlice = createSlice({
  name: 'user profile',
  initialState,
  reducers: {
    update: (state, action) => {
      state.data = action.payload;
    },
    userHobbiesUpdate: (state, action) => {
      if (state.data) {
        state.data.personal_details.hobbies = action.payload;
      }
    },
    userVideoUpdate: (state, action) => {
      if (state.data) {
        state.data.videoIntro = action.payload;
      }
    },
    userDisabilityUpdate: (state, action) => {
      if (state.data) {
        state.data.personal_details.disability = action.payload;
      }
    },
    userSocialUpdate: (state, action) => {
      if (state.data) {
        state.data.personal_details.social = action.payload;
      }
    },
    userFtueUpdate: (state, action) => {
      if (state.data) {
        state.data.personal_details.ftue = false;
      }
    },
    userBioUpdate: (state, action) => {
      if (state.data) {
        state.data.personal_details.bio = action.payload;
      }
    },
    userBioCountUpdate: (state, action) => {
      state.bioTextCount = action.payload;
    },
    userTopicsUpdate: (state, action) => {
      if (state.data) {
        state.data.selectedTopics = action.payload;
      }
    },
    userIndustriesUpdate: (state, action) => {
      if (state.data) {
        state.data.selectedIndustries = action.payload;
      }
    },
    userGettingStartedUpdate: (state, action) => {
      if (state.data) {
        state.data.gettingStarted = action.payload;
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserProfile.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.errorText = '';
        state.data = undefined;
      })
      .addCase(fetchUserProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        state.errorText = '';
        state.data = action.payload.data;
      })
      .addCase(fetchUserProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
        state.errorText = action.error.message || 'Something Went Wrong';
        state.data = undefined;
      });
  }
});

export default userProfileSlice.reducer;

export const userInfoUpdate = userProfileSlice.actions.update;

export const { userHobbiesUpdate } = userProfileSlice.actions;

export const { userDisabilityUpdate } = userProfileSlice.actions;

export const { userSocialUpdate } = userProfileSlice.actions;
export const { userBioUpdate, userBioCountUpdate } = userProfileSlice.actions;

export const userActions = userProfileSlice.actions;
