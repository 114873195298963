import { 
    Box, 
    Table,
    Divider, 
    TableRow, 
    TableBody, 
    TableCell, 
    TableHead, 
    Typography, 
    TableContainer,
 } from "@mui/material";
import MySkeleton from "../components/Skeleton";

/** Content settings loader */
export const ContentSettingsLoader = () => {
    return (
        <Box height={'100%'} overflow={'hidden'}>
            <TableContainer>
                <Table width="100%">
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={4} width="50%" align="left" >
                                <Typography>
                                    <MySkeleton width={100} />
                                </Typography>
                            </TableCell>
                            <TableCell colSpan={3} width="50%" align="right" className="py-0">
                                <Box className='last-cell-table-Loader'>
                                    <MySkeleton variant='circular' height={34} width={34} />
                                </Box>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <MySkeleton width={100} />
                            </TableCell>
                            {[...Array(5).keys()].map((_, index) => ( 
                                <TableCell>
                                    <MySkeleton width={80} />
                                </TableCell>
                            ))}
                            <TableCell>
                                <Typography className='last-cell-table-Loader'>
                                    <MySkeleton width={70} />
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {[...Array(10).keys()].map((_, index) => ( 
                            <TableRow key={index}>
                                <TableCell>
                                    <Box display={'flex'} gap={'8px'} alignItems={'center'}>
                                        <MySkeleton variant='rounded' height={25} width={43}/>
                                        <MySkeleton variant='rounded' height={47} width={70}/>
                                        <MySkeleton width={120}/>
                                    </Box>    
                                </TableCell>
                                <TableCell>
                                    <MySkeleton variant='rounded' height={20} width={40}/>
                                </TableCell>
                                <TableCell>
                                    <MySkeleton variant='rounded' height={20} width={40}/>
                                </TableCell>
                                <TableCell>
                                    <MySkeleton width={50} />
                                </TableCell>
                                <TableCell>
                                    <MySkeleton width={70} />
                                </TableCell>
                                <TableCell>
                                    <MySkeleton variant='rounded' height={25} width={70}/>
                                </TableCell>
                                <TableCell>
                                    <Typography className='last-cell-table-Loader'>
                                        <MySkeleton variant="rounded" height={15} width={3.5}/>
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}

/** Help settings loader */
export const HelpSettingsLoader = () => {
    return (
        <Box>
            <Divider />
            <TableContainer className='b-none'>
                <Table width="100%">
                    <TableHead>
                        <TableRow>
                            {[...Array(4).keys()].map((_, index) => ( 
                                <TableCell>
                                    <MySkeleton width={100} />
                                </TableCell>
                            ))}
                            <TableCell>
                                <Typography className='last-cell-table-Loader'>
                                    <MySkeleton width={70} />
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {[...Array(10).keys()].map((_, index) => ( 
                            <TableRow key={index}>
                                <TableCell>
                                    <Box display={'flex'} gap={'8px'} alignItems={'center'}>
                                        <MySkeleton variant='rounded' height={25} width={43}/>
                                        <MySkeleton variant='rounded' height={47} width={70}/>
                                        <MySkeleton width={150}/>
                                    </Box>    
                                </TableCell>
                                <TableCell>
                                    <MySkeleton width={100} />
                                </TableCell>
                                <TableCell>
                                    <MySkeleton width={100} />
                                </TableCell>
                                <TableCell>
                                    <MySkeleton width={100} />
                                </TableCell>
                                <TableCell>
                                    <Typography className='last-cell-table-Loader'>
                                        <MySkeleton variant="rounded" height={15} width={3.5}/>
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}
