/* eslint-disable no-undef */
/* eslint-disable prettier/prettier */
import { useEffect, useState } from "react";
import AppLoader from "../../../components/AppLoader";

export const useLoader = (loading: boolean, delay = 1000) => {
  const [showLoader, setShowLoader] = useState(false);
  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (loading) {
      timer = setTimeout(() => {
        setShowLoader(true);
      }, delay);
    }
    if (!loading) {
      setShowLoader(false);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [loading, delay]);

  if (showLoader) {
    return <AppLoader />;
  }

  return null;
};