import React from 'react';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

interface TypingLoaderProps {
  color?: string; // Define the color prop
}

const useStyles = makeStyles(() => ({
  typingDot: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    opacity: 0,
    animation: '$loadingFade 1s infinite',
  },
  typingDot1: {
    animationDelay: '0s',
  },
  typingDot2: {
    animationDelay: '0.2s',
  },
  typingDot3: {
    animationDelay: '0.4s',
  },
  '@keyframes loadingFade': {
    '0%': {
      opacity: 0,
    },
    '50%': {
      opacity: 0.8,
    },
    '100%': {
      opacity: 0,
    },
  },
}));

const TypingLoader: React.FC<TypingLoaderProps> = ({ color }) => {
  const classes = useStyles();

  return (
    <Box
        pl='20px'
        gap='8px'
        display='flex'
        alignItems='center'
        justifyContent='left'  
    >
      <Box bgcolor={color? color : '#DF6438'} className={`${classes.typingDot} ${classes.typingDot1}`} />
      <Box bgcolor={color? color : '#DF6438'} className={`${classes.typingDot} ${classes.typingDot2}`} />
      <Box bgcolor={color? color : '#DF6438'} className={`${classes.typingDot} ${classes.typingDot3}`} />
    </Box>
  );
};

export default TypingLoader;
