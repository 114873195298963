/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useLogout } from '../hooks';

const LogoutComponent = () => {
  const { doLogout } = useLogout();
  useEffect(() => {
    doLogout();
  }, []);
  return <div>Logging out...</div>;
};

export default LogoutComponent;
