/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-undef */
/* eslint-disable no-param-reassign */
import {
  Box,
  Grid,
  Chip,
  Dialog,
  Button,
  Divider,
  Checkbox,
  TextField,
  InputLabel,
  IconButton,
  Typography,
  Autocomplete,
  DialogContent,
  DialogActions,
  FormHelperText,
  FormControlLabel
} from '@mui/material';
import { Controller, FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import _ from 'lodash';

const EducationDailogBox = ({
  control,
  handleSubmit,
  errors,
  setValue,
  checkError,
  setUniversityValue,
  UniversityList,
  getError,
  getDeptObj,
  setMajorDegreeValue,
  majorDegreeList,
  action,
  minorList,
  ftue,
  setMinorValue,
  handleMinor,
  dept,
  uniqueDeptArr
}: any) => (
  <Grid container spacing={2}>
    <Grid item xs={12} sm={6}>
      <InputLabel>College / University *</InputLabel>
      <Controller
        name="university"
        control={control}
        render={({ field }) => (
          <>
            {!UniversityList ? (
              <p>Loading...</p>
            ) : (
              <Autocomplete
                id="collegeName"
                size="small"
                value={field.value || ''}

                onChange={(_event, newValue) => {
                  if (typeof newValue === 'string') return;
                  setValue('university', newValue || '');

                  errors['university'] = undefined;
                }}
                onInputChange={(event, newInputValue) => {
                  setUniversityValue(newInputValue || '');
                  setValue('university', newInputValue || '');
                  errors['university'] = undefined;
                }}
                filterOptions={(options, params) => {
                  if (!Array.isArray(options)) {
                    options = [];
                  }
                  const filtered = options.filter((option) =>
                    option.toLowerCase().includes(params.inputValue.toLowerCase())
                  );
                  if (params.inputValue !== '') {
                    filtered.push(`${params.inputValue}`);
                  }
                  return _.uniq(filtered);
                }}
                options={_.uniq(
                  UniversityList?.sort((a: string, b: any) => {
                    return a.localeCompare(b);
                  })
                )}
                getOptionLabel={(option) => option}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                freeSolo
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder={UniversityList ? 'College / University' : 'Loading...'}
                    className="ethnicity-field"
                    error={checkError('university')}
                  />
                )}
              />
            )}
          </>
        )}
      />
      <FormHelperText error>{getError('university')?.toString()}</FormHelperText>
    </Grid>

    <Grid item xs={12} sm={6}>
      <InputLabel>Graduation year*</InputLabel>
      <Controller
        name="graduation_date"
        control={control}
        render={({ field: { onChange, value } }) => (
          <TextField
            placeholder="YYYY"
            name="graduation_date"
            type="text"
            inputProps={{
              maxLength: 4
            }}
            variant="outlined"
            fullWidth
            value={value || ''}
            id="graduationYear"
            onChange={(e) => {
              const numericValue = e.target.value.replace(/[^0-9]/g, ''); // Keep only numeric characters
              onChange({ target: { value: numericValue, name: 'graduation_date' } });
            }}
            error={checkError('graduation_date')}
          />
        )}
      />
      <FormHelperText error>{getError('graduation_date')?.toString()}</FormHelperText>
    </Grid>

    <Grid item xs={12}>
      <InputLabel>Major / Degree*</InputLabel>
      <Controller
        name="major"
        control={control}
        render={({ field }) => (
          <Autocomplete
            id="majorName"
            size="small"
            value={field.value || ''}
            onChange={(event, newValue) => {
              setValue('major', newValue || '');
              getDeptObj(newValue);
              errors['major'] = undefined;
            }}
            onInputChange={(event, newInputValue) => {
              setValue('major', newInputValue || '');
              getDeptObj(newInputValue);
              setMajorDegreeValue(newInputValue);
              errors['major'] = undefined;
            }}
            filterOptions={(options, params) => {
              if (!Array.isArray(options)) {
                options = [];
              }
              const filtered = options.filter((option) =>
                option.toLowerCase().includes(params.inputValue.toLowerCase())
              );

              if (params.inputValue !== '') {
                filtered?.push(`${params.inputValue}`);
              }

              return _.uniq(filtered);
            }}
            options={_.uniq(
              majorDegreeList?.sort((a: string, b: any) => {
                return a.localeCompare(b);
              })
            )}
            getOptionLabel={(option) => option}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            freeSolo
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder=" Major / Degree "
                className="ethnicity-field"
                error={checkError('major')}
              />
            )}
          />
        )}
      />
      <FormHelperText error>{getError('major')?.toString()}</FormHelperText>
    </Grid>

    <Grid item xs={12}>
      <InputLabel>Minor</InputLabel>
      <Controller
        name="minor"
        control={control}
        render={({ field }) => {
          let value: any = [];
          if (action === 'Add') {
            const data = localStorage.getItem('education');
            if (ftue && data) {
              value = field.value || [];
            } else {
              value = field.value;
            }
          } else {
            value = field.value || [];
          }

          return (
            <Autocomplete
              id="minorName"
              multiple
              clearOnBlur={true}
              autoHighlight
              autoFocus
              selectOnFocus
              handleHomeEndKeys
              options={_.uniq(
                minorList?.sort((a: string, b: any) => {
                  return a.localeCompare(b);
                })
              )}
              getOptionLabel={(option) => option}
              // filterOptions={(options, state) => {
              //   return _.uniq(
              //     options.filter((option) => option.toLowerCase().includes(state.inputValue.toLowerCase()))
              //   );
              // }}
              filterOptions={(options, params) => {
                if (!Array.isArray(options)) {
                  options = [];
                }
                const filtered = options.filter((option) =>
                  option.toLowerCase().includes(params.inputValue.toLowerCase())
                );

                if (params.inputValue !== '') {
                  filtered?.push(`${params.inputValue}`);
                }

                return _.uniq(filtered);
              }}
              value={value || ['']}
              // onInputChange={(event, inputValue) => {
              //   inputValue === null ? setMinorValue('') : setMinorValue(inputValue);
              // }}
              onBlur={(event: any) => {
                const inputValue = event.target.value;
                if (inputValue && !value.includes(inputValue || '')) {
                  setValue('minor', [...value, inputValue || '']); // Add new value to the existing array
                  // setMinorValue(''); // Clear the input after adding a new value
                }
              }}
              onInputChange={(event, inputValue) => {
                const keyboardEvent = event as React.KeyboardEvent<HTMLInputElement>;
                if (keyboardEvent.key === 'Enter') {
                  // Handle Enter key press to add a new value
                  if (inputValue && !value.includes(inputValue)) {
                    setValue('minor', [...value, inputValue]); // Add new value to the existing array
                    setMinorValue(''); // Clear the input after adding a new value
                  }
                } else {
                  // Handle other cases (typing without pressing Enter)
                  setMinorValue(inputValue || ''); // Update the input value
                }
              }}
              onChange={handleMinor}
              disableCloseOnSelect
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    placeholder={field?.value?.length ? '' : 'Select Minor'}
                  />
                );
              }}
              freeSolo
              renderTags={(value, getTagProps) =>
                Array.isArray(value) &&
                value?.map((option, index) => {
                  // eslint-disable-next-line react/jsx-key
                  return <Chip label={option} {...getTagProps({ index })} />;
                })
              }
            />
          );
        }}
      />
      <FormHelperText error>{getError('minor')?.toString()}</FormHelperText>
    </Grid>

    <Grid item xs={12}>
      <InputLabel>Department / School *</InputLabel>
      <Controller
        name="department"
        control={control}
        render={({ field }) => {
          return (
            <Autocomplete
              id="departmentName"
              size="small"
              value={field.value || dept}
              onChange={(event, newValue) => {
                setValue('department', newValue || '');
                errors['department'] = undefined;
              }}
              onInputChange={(event, newInputValue) => {
                setValue('department', newInputValue || '');
                errors['department'] = undefined;
              }}
              options={_.uniq(
                uniqueDeptArr?.sort((a: string, b: any) => {
                  return a.localeCompare(b);
                })
              )}
              filterOptions={(options, state) =>
                options.filter((option) => option.toLowerCase().includes(state.inputValue.toLowerCase()))
              }
              getOptionLabel={(option) => option}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              freeSolo
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Department "
                  className="ethnicity-field"
                  error={checkError('department')}
                />
              )}
            />
          );
        }}
      />
      <FormHelperText error>{getError('department')?.toString()}</FormHelperText>
    </Grid>
  </Grid>
);

export default EducationDailogBox;
