import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { toast } from 'react-toastify';
import ROLES from '../../../utils/roles';
import { useSearchParams } from 'react-router-dom';
import CircleIcon from '@mui/icons-material/Circle';
import { utcToZonedTime, format } from 'date-fns-tz';
import { useAppSelector } from '../../../redux/hooks';
import imageCompression from 'browser-image-compression';
import { timezones } from '../../profile-page/timeZones';
import { countries } from '../../profile-page/countryData';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Trophybadge from '../../../assets/images/Trophybadge.svg';
import Trophybadge2 from '../../../assets/images/Trophybadge2.svg';
import ThrophyBadgeGreen from '../../../assets/images/ThrophyBadgeGreen.svg';
// import userIdSlice from '../../../redux/slices/userId/userIdSlice';
import {
  AGILE_ALLOWED_CATEGORY_COMMUNITY,
  AGILE_MENTOR_ROLE_TAG,
  AGILE_ORG,
  AGILE_ORG_NAME,
  LABEL_MAPPINGS,
  STAR_VENTURE,
  STAR_VENTURE_NAME
} from '../../../utils/constants';
import groupDisplayRoleMappings from '../../../utils/groupDisplayRoleMapping';
import { getUserDetails } from '../../../utils/orgName';
import { API } from '../../../api';

/** If we need any think on dev and local server */
export const isDevEnvironment = ['dev.dosen2.io', 'localhost'].includes(window?.location?.hostname);

/** Zoom meeting url */
export const createZoomMeetingUrl = (
  callmeetingId: string,
  callDate: string,
  calltime: string,
  callTimeIntervel: number,
  bookedByUser: any
) => {
  const meetingId = callmeetingId;
  const baseUrl = window.location.origin;
  const callStartTimestamp = new Date(callDate + 'T' + calltime + 'Z').getTime();
  const callStartUnixTime = Math.floor(callStartTimestamp / 1000);
  const currentTimestamp = new Date().getTime();
  const currentcallStartUnixTime = Math.floor(currentTimestamp / 1000);
  const callEndUnixTime = callStartUnixTime + callTimeIntervel * 60;
  /**
   * 1 to join as host, 0 to join as attendee. The first user must join as host to start the session.
   */

  let role = 0;
  if (bookedByUser && bookedByUser?.id === getUserDetails()?.userId) {
    role = 1;
  }

  const meetingLink =
    baseUrl + '/#/video-call/' + meetingId + '/' + role + '/20/' + callStartUnixTime + '/' + callTimeIntervel;
  return meetingLink;
};

export const callEndTime = (callDate: string, calltime: string, callTimeIntervel: number) => {
  const callStartTimestamp = new Date(callDate + 'T' + calltime + 'Z').getTime();
  const callStartUnixTime = Math.floor(callStartTimestamp / 1000);
  const currentTimestamp = new Date().getTime();
  const callEndUnixTime = callStartUnixTime + callTimeIntervel * 60;
  return callEndUnixTime;
};

export const formattedTimeSlot = (callTimeIntervel: number) => {
  let timeSlot = '';
  if (callTimeIntervel === 60) {
    timeSlot = '1 Hour';
  } else if (callTimeIntervel === 120) {
    timeSlot = '2 Hours';
  } else if (callTimeIntervel === 180) {
    timeSlot = '3 Hours';
  } else {
    timeSlot = callTimeIntervel + ' Min';
  }

  return timeSlot;
};

/** Bio formatter  */
export const bioFormatter = (string1?: string | null, string2?: string | null, string3?: string | null) => {
  const elements = [];
  const dotDesign = <CircleIcon key="icon1" color="secondary" sx={{ width: '4px', height: '4px', mx: '4px' }} />;
  let s1 = string1;
  let s2 = string2;
  let s3 = string3;

  if (s3) {
    elements.push(s3);
    if (s1) elements.push(dotDesign);
  }

  if (s1) {
    elements.push(s1);
    if (s2) elements.push(dotDesign);
  }

  if (s2) {
    if (s3 && !s2) elements.push(dotDesign);
    elements.push(s2);
  }

  return <>{elements}</>;
};

export const homepageUrl = (
  orgId: string | null,
  coId: string | null,
  grpId: string | null,
  role: string | null,
  homeUrl: string | null
) => {
  let url = '';
  if (role === ROLES.orgAdmin || role === ROLES.grpAdmin) {
    url = `/app/programdetails/${orgId}/${coId}/${grpId}/overview`;
  } else if (role === ROLES.platAdmin) {
    url = `/app/organizations`;
  } else {
    url = homeUrl ? homeUrl : '';
  }
  return url;
};

/** Meeting color */
export function meetingColor() {
  return [
    {
      value: 'Upcoming', // 'New call request + Upcoming
      title: 'Upcoming meeting',
      color: '#0071A9' // Blue
    },
    {
      value: 'Completed', // Past call
      title: 'Completed meeting',
      color: '#28A745' // Green
    },
    {
      value: 'Incomplete', // If < 2 attendees join the call
      title: 'Incomplete meeting',
      color: '#DC3545' // Red
    },
    {
      value: 'Rejected', // Rejected
      title: 'Rejected meeting',
      color: '#ABB5BE' // Gray
    }
  ];
}

/** Check call type */
export const checkCallType = (
  callStatus: string,
  callDate: string,
  callTime: { toString: () => string },
  callTimeInterval: number,
  rejectStatus: boolean | undefined,
  joinedParticipantsCount: number,
  isBookerJoined: boolean
) => {
  const colors = meetingColor();
  const currentTimestamp = new Date().getTime();
  const currentUnixTime = Math.floor(currentTimestamp / 1000);
  const callStartTimestamp = new Date(callDate + 'T' + callTime + 'Z').getTime();
  const callStartUnixTime = Math.floor(callStartTimestamp / 1000);
  const callEndUnixTime = callStartUnixTime + callTimeInterval * 60;

  let colorObject = null;

  if (rejectStatus === true || callStatus === 'Canceled') {
    colorObject = colors.find((color) => color.value === 'Rejected');
  } else if (currentUnixTime > callEndUnixTime && joinedParticipantsCount < 2) {
    colorObject = colors.find((color) => color.value === 'Incomplete');
  } else if (currentUnixTime > callEndUnixTime) {
    if (isBookerJoined) {
      colorObject = colors.find((color) => color.value === 'Completed');
    } else {
      colorObject = colors.find((color) => color.value === 'Incomplete');
    }
  } else if (currentUnixTime + 300 > callStartUnixTime) {
    colorObject = colors.find((color) => color.value === 'Upcoming');
  } else {
    colorObject = colors.find((color) => color.value === 'Upcoming');
  }

  const color_code = colorObject?.color || '';
  const call_flag = colorObject ? colorObject.title : '';
  const title = colorObject ? colorObject.title : '';

  let CallType_data = {
    color_code: color_code,
    call_flag: call_flag,
    title: title,
    reviewFlag: true
  };
  return CallType_data;
};

function getTimeZoneNameCodeByValue(value: string) {
  const timezone = timezones.find((zone: { value: string }) => zone.value === value);
  return timezone ? timezone.timeZoneNameCode : 'UTC';
}

function utcToGivenTz(call_date: string, utc_time: string, timezone: string) {
  const time = new Date(call_date + 'T' + utc_time + 'Z');
  const utc_to_tz = utcToZonedTime(time, timezone);
  const formattedTime = format(utc_to_tz, 'h:mm a');
  return formattedTime;
}

export const timeZoneWiseTime = (call_date: string, call_time: string, user_tz: string) => {
  const tz = getTimeZoneNameCodeByValue(user_tz);
  return utcToGivenTz(call_date, call_time, tz);
};

export const timeZoneAbbreviation = (user_tz: string) => {
  const tz = getTimeZoneNameCodeByValue(user_tz);
  const currentTimezoneAbbreviation = moment.tz(tz).format('z');
  return currentTimezoneAbbreviation;
};

export const timeZonWiseDateTime = (call_date: string, utc_time: string, timezone: string) => {
  const tz = getTimeZoneNameCodeByValue(timezone);
  const time = new Date(call_date + 'T' + utc_time + 'Z');
  const utc_to_tz = utcToZonedTime(time, tz);
  const formattedTime = new Date(utc_to_tz);
  return formattedTime;
};

export const formatMMDDYYYY = (call_date: string, utc_time: string, timezone: string) => {
  const tz = getTimeZoneNameCodeByValue(timezone);
  const time = new Date(call_date + 'T' + utc_time + 'Z');
  const utc_to_tz = utcToZonedTime(time, tz);
  const formattedTime = format(utc_to_tz, 'MM/dd/yyyy');
  return formattedTime;
};

const countOccurrences = (notificationArray: any, threadsArray: any) => {
  let msgCount = 0;
  notificationArray.forEach((notification: any) => {
    const { threadId } = notification;
    if (threadsArray.some((thread: any) => thread.id === threadId)) {
      msgCount = (msgCount || 0) + 1;
    }
  });
  return msgCount;
};

export const notificationCount = (acsArray: any, notificationArray: any) => {
  const counts = {
    personal: countOccurrences(notificationArray, acsArray?.personalThreadsArr),
    group: countOccurrences(notificationArray, acsArray?.groupThreads),
    total: 0
  };
  counts.total = counts.personal + counts.group;
  return counts;
};

export const stringToLower = (givenString: string) => {
  let formatted_string = givenString.toLowerCase();
  return formatted_string;
};

// Similarly we can make validation functions for other Organizations also//
export const IsAgileExecutive = () => {
  const oid = useAppSelector((state) => state.orgDetails.data?.oid);
  const [searchParams] = useSearchParams();
  const orgName = searchParams.get('org') || '';
  const role = localStorage.getItem('role') || '';
  const storedOrgName = role !== ROLES.platAdmin ? oid : orgName || '';
  if (storedOrgName === AGILE_ORG_NAME || storedOrgName === AGILE_ORG) {
    return true;
  }
  return false;
};

export const IsStarVenture = () => {
  const oid = useAppSelector((state) => state.orgDetails.data?.oid);
  const mainGroupName = useAppSelector((state) => state.allGroups.data?.mainGroupName);
  const allGroups = useAppSelector((state) => state.allGroups);
  const [searchParams] = useSearchParams();
  const orgName = searchParams.get('org') || '';
  const role = localStorage.getItem('role') || '';
  const groupRoleList = useAppSelector((state) => state?.groupRoleList);

  const includesStarVenture = groupRoleList?.data?.some((item) =>
    item?.name?.toLowerCase().includes(STAR_VENTURE_NAME)
  );

  const groupList = useAppSelector((state) => state.groupRoleList);

  const getOnlyStarVenture = groupList?.data?.find(
    (each) => each?.name?.toLowerCase() === STAR_VENTURE_NAME || each?.name === STAR_VENTURE_NAME
  );

  const hasStarVentureInGroups = allGroups?.data?.groups?.some(
    (group: any) => group?.name?.toLowerCase() === STAR_VENTURE_NAME || group?.name === STAR_VENTURE_NAME
  );

  const breadcrumbs: any = useAppSelector((state) => state.breadCrumbs.breadcrumbs);

  const updatedBreadcrumbs = breadcrumbs.map((breadcrumb: any, index: any) => {
    if (breadcrumb?.name?.toLowerCase() === STAR_VENTURE_NAME && index < breadcrumbs.length - 1) {
      return { ...breadcrumb, name: STAR_VENTURE_NAME };
    }
    return breadcrumb;
  });

  const containsStarVenture = updatedBreadcrumbs.some(
    (breadcrumb: any, index: any) =>
      breadcrumb.name?.toLowerCase() === STAR_VENTURE_NAME && index < updatedBreadcrumbs.length - 1
  );

  const storedOrgName =
    role !== ROLES.platAdmin
      ? getOnlyStarVenture?.name?.toLowerCase().replace(/[^a-z0-9]/g, '') || ''
      : mainGroupName?.toLowerCase().replace(/[^a-z0-9]/g, '') || '';
  if (
    (role !== ROLES.platAdmin && hasStarVentureInGroups) ||
    storedOrgName === STAR_VENTURE_NAME ||
    allGroups?.data?.mainGroupName?.toLowerCase() === STAR_VENTURE_NAME ||
    containsStarVenture ||
    includesStarVenture
  ) {
    return true;
  }

  return false;
};

export const roleNameFormatter = (
  roleName: string | undefined,
  formattedMentorName: string,
  formattedMenteeName: string
) => {
  if (roleName) {
    let formattedRoleName = roleName;
    if (roleName === 'Mentor' || roleName === 'mentor') {
      formattedRoleName = formattedMentorName;
    } else if (roleName === 'Mentee' || roleName === 'mentee') {
      formattedRoleName = formattedMenteeName;
    }
    return formattedRoleName;
  } else {
    return '';
  }
};

export function blobToFile(blob: BlobPart, fileName: string, fileType: any) {
  const options: any = {
    type: fileType
  };
  const file = new File([blob], fileName, options);
  return file;
}

export const handleImageCompression = async (imageFile: any) => {
  const options = {
    maxSizeMB: 1,
    // maxWidthOrHeight: 1920,
    useWebWorker: true
  };
  try {
    const compressedFile = await imageCompression(imageFile, options);
    const blobToImageFile = blobToFile(compressedFile, imageFile?.name, imageFile?.type);
    return blobToImageFile;
  } catch (error) {}
};

export const roundOfNumber = (value: any) => {
  let percent = value;
  if (typeof value !== 'number') {
    // Only round of numbers
    return percent;
  }
  percent = Math.round(percent);
  return percent;
};

export const imageSizeLimit = () => {
  const imageSize = {
    limitValue: 10 * 1024 * 1024,
    limitText: '10MB'
  };
  return imageSize;
};

/** Number format with commas ',' */
export const formatNumberWithCommas = (num?: number) => {
  return num?.toLocaleString();
};

/** Number format with everything. EG, DecimalPlaces, commas   */
export function formatNumber(number?: number, decimalPlaces: number = 0) {
  if (number) {
    const factor = Math.pow(10, decimalPlaces);
    const roundedNumber = Math.round(number * factor) / factor;

    return roundedNumber % 1 === 0
      ? roundedNumber.toLocaleString(undefined, { minimumFractionDigits: 0 })
      : roundedNumber.toLocaleString(undefined, {
          minimumFractionDigits: decimalPlaces,
          maximumFractionDigits: decimalPlaces
        });
  } else {
    return 0;
  }
}

export function showUpvoteIcon(userDetails: any) {
  // userRole 2 is for orgAdmin
  if (userDetails?.mentors?.length > 0 || userDetails?.admins?.length > 0 || userDetails?.userRole === 2) {
    return true;
  }
  return false;
}

export function categoryLabelFormatter(label: any) {
  if (!IsAgileExecutive) {
    return label;
  }
  const mappedLabel = LABEL_MAPPINGS.find((mapping) => mapping.original === label);
  return mappedLabel ? mappedLabel.formatted : label;
}

export function unixToHumanReadable(timestamp: number) {
  const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
  const diff = currentTime - timestamp;

  if (diff < 60) {
    return `${diff} Seconds ago`;
  } else if (diff < 3600) {
    const minutes = Math.floor(diff / 60);
    return `${minutes} ${minutes === 1 ? 'Min' : 'Mins'} ago`;
  } else if (diff < 86400) {
    const hours = Math.floor(diff / 3600);
    return `${hours} ${hours === 1 ? 'Hour' : 'Hours'} ago`;
  } else {
    const days = Math.floor(diff / 86400);
    return `${days} ${days === 1 ? 'Day' : 'Days'} ago`;
  }
}

export function formattedSuffixedDate(date: string) {
  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  const [year, monthStr, dayStr] = date.split('-');
  const month = monthNames[parseInt(monthStr, 10) - 1];
  const day = parseInt(dayStr, 10);

  const shortYear = year.slice(-2);

  let ordinalSuffix = 'th';
  if (day === 1 || day === 21 || day === 31) {
    ordinalSuffix = 'st';
  } else if (day === 2 || day === 22) {
    ordinalSuffix = 'nd';
  } else if (day === 3 || day === 23) {
    ordinalSuffix = 'rd';
  }

  // return `${day}${ordinalSuffix} ${month}, '${shortYear}`;
  return ` ${month} '${shortYear}`;
}

export function formattedCategory(cat: string) {
  if (!IsAgileExecutive) {
    // for other than agile just return the original value
    return cat;
  }
  let formattedCat = AGILE_ALLOWED_CATEGORY_COMMUNITY.includes(cat) ? AGILE_MENTOR_ROLE_TAG : cat;
  return formattedCat;
}

/** Character Count for input field */
export function inputCharacterCount(textValue: any, maxCharacterCount: number) {
  const data = {
    textValueLength: 0 + textValue.length,
    maxCharacterCount: maxCharacterCount
  };
  return data;
}

/** Character Count limit Warning */
export const showCharacterCountWarning = (value: string, maxCharacterCount: number) => {
  toast.dismiss();
  if (value.length === maxCharacterCount) {
    toast.warning(`Character limit of ${maxCharacterCount} characters exceeded`);
  }
};

/** Capitalize First letter */
export function capitalizeFirstLetter(string: any) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

/**
 * Roles Array
 */
export function rolesArray() {
  const roles = ['Mentee', 'Mentor', 'Admin'];
  return roles;
}

/**
 * customer Category Array
 */
export function customerCategoryArray() {
  const customerCategory = ['Business Owner', 'Entrepreneur', 'Consultant', 'Program Friend'];
  return customerCategory;
}

/**
 * Content type Array
 */
export function contentTypeArray() {
  const contentType = ['Audio', 'Video', 'Text'];
  return contentType;
}

/**
 * industry Array
 */
// export function industryArray() {
//   const industry = ['Industry 1', 'Industry 2', 'Industry 3', 'Industry 4'];
//   return industry;
// }

const bankIndustries = [
  { label: 'Advertising', name: 'advertising' },
  { label: 'Agritech', name: 'agritech' },
  { label: 'Artificial Intelligence (AI)', name: 'artificialIntelligenceAi' },
  { label: 'Machine Learning (ML)', name: 'machineLearningMl' },
  { label: 'CleanTech and Renewable Energy', name: 'cleanTechAndRenewableEnergy' },
  { label: 'Cybersecurity', name: 'cybersecurity' },
  { label: 'EdTech', name: 'edTech' },
  { label: 'E-commerce and Retail', name: 'eCommerceAndRetail' },
  { label: 'Fintech', name: 'fintech' },
  { label: 'FoodTech', name: 'foodTech' },
  { label: 'HealthTech', name: 'healthTech' },
  { label: 'HRTech', name: 'hrTech' },
  { label: 'InsurTech', name: 'insurTech' },
  { label: 'Internet of Things (IoT)', name: 'internetOfThingsIoT' },
  { label: 'LegalTech', name: 'legalTech' },
  { label: 'Marketing', name: 'marketing' },
  { label: 'Real Estate and PropTech', name: 'realEstateAndPropTech' },
  { label: 'Supply Chain and Logistics', name: 'supplyChainAndLogistics' },
  { label: 'Sustainability', name: 'sustainability' },
  { label: 'Transportation and Mobility', name: 'transportationAndMobility' },
  { label: 'Travel and Hospitality', name: 'travelAndHospitality' }
];

export function industryArray(isNotDevBank = false) {
  const industry = [
    {
      name: 'healthcare',
      label: 'Healthcare'
    },
    {
      name: 'technology',
      label: 'Technology'
    },

    {
      name: 'finance',
      label: 'Finance'
    },

    {
      name: 'manufacturing',
      label: 'Manufacturing'
    },

    {
      name: 'retail',
      label: 'Retail'
    },

    {
      name: 'energy',
      label: 'Energy'
    },

    {
      name: 'construction',
      label: 'Construction'
    },

    /* 8 */
    {
      name: 'telecommunications',
      label: 'Telecommunications'
    },

    /* 9 */
    {
      name: 'agriculture',
      label: 'Agriculture'
    },

    /* 10 */
    {
      name: 'transportation',
      label: 'Transportation'
    },

    /* 11 */
    {
      name: 'education',
      label: 'Education'
    },

    /* 12 */
    {
      name: 'hospitality',
      label: 'Hospitality'
    },

    /* 13 */
    {
      name: 'realEstate',
      label: 'Real Estate'
    },

    /* 14 */
    {
      // "_id" : ObjectId("6581dca730846f0d45970cb9"),
      name: 'mediaAndEntertainment',
      label: 'Media and Entertainment'
    },

    /* 15 */
    {
      // "_id" : ObjectId("6581dca730846f0d45970cba"),
      name: 'automotive',
      label: 'Automotive'
    },

    /* 16 */
    {
      // "_id" : ObjectId("6581dca730846f0d45970cbb"),
      name: 'aerospaceAndDefense',
      label: 'Aerospace and Defense'
    },

    /* 17 */
    {
      // "_id" : ObjectId("6581dca730846f0d45970cbc"),
      name: 'pharmaceutical',
      label: 'Pharmaceutical'
    },

    /* 18 */
    {
      // "_id" : ObjectId("6581dca730846f0d45970cbd"),
      name: 'foodAndBeverage',
      label: 'Food and Beverage'
    },

    /* 19 */
    {
      // "_id" : ObjectId("6581dca730846f0d45970cbe"),
      name: 'chemical',
      label: 'Chemical'
    },

    /* 20 */
    {
      // "_id" : ObjectId("6581dca730846f0d45970cbf"),
      name: 'environmentalServices',
      label: 'Environmental Services'
    }
  ];
  if (!isNotDevBank) {
    return [...industry, ...bankIndustries];
  }
  return industry;
}

/**
 * Group name Array
 */
export function groupNameArray() {
  const groups = ['First Year Students 2023', 'First Year Students 2024', 'Career Development'];
  return groups;
}

/**
 * Currency Array
 */
export function currencyArray() {
  const currencies = [
    { value: '$', symbol: '$' },
    { value: '€', symbol: '€' },
    { value: '£', symbol: '£' }
  ];
  return currencies;
}

/**
 * optionalFields Array
 */

const bankTopics = [
  { label: 'Product', name: 'product' },
  { label: 'Partnerships', name: 'partnerships' },
  { label: 'Growth: B2B Sales', name: 'growthB2bSales' },
  { label: 'Growth: B2C Sales', name: 'growthB2cSales' },
  { label: 'Growth: Conversion Rate', name: 'growthConversionRate' },
  { label: 'Growth: Customer Success', name: 'growthCustomerSuccess' },
  { label: 'Growth: Emailing', name: 'growthEmailing' },
  { label: 'Growth: Offline Advertising', name: 'growthOfflineAdvertising' },
  { label: 'Growth: SEO/SEM', name: 'growthSeoSem' },
  { label: 'Revenue', name: 'revenue' },
  { label: 'Legal: Data Privacy/Security', name: 'legalDataPrivacySecurity' },
  { label: 'Legal: Intellectual Property/Protection', name: 'legalIntellectualPropertyProtection' },
  { label: 'HR: Leadership', name: 'hrLeadership' },
  { label: 'HR: Objectives (OKRs)/KPIs/Goals', name: 'hrObjectivesOkrsKpisGoals' },
  { label: 'HR: Recruitment', name: 'hrRecruitment' },
  { label: 'HR: Culture', name: 'hrCulture' },
  { label: 'Operations: Quality Control', name: 'operationsQualityControl' },
  { label: 'Operations: Supply Chain & Logistics', name: 'operationsSupplyChainLogistics' },
  { label: 'Operations: Cost Reduction & Efficiency', name: 'operationsCostReductionEfficiency' },
  { label: 'Customer Support', name: 'customerSupport' },
  { label: 'Sustainability', name: 'sustainability' },
  { label: 'Strategy: Business Model/Pricing', name: 'strategyBusinessModelPricing' },
  { label: 'Strategy: International Expansion', name: 'strategyInternationalExpansion' },
  { label: 'Strategy: Market Analysis/Positioning', name: 'strategyMarketAnalysisPositioning' },
  { label: 'Tech: UI/UX', name: 'techUiUx' },
  { label: 'Tech: AI Development', name: 'techAiDevelopment' },
  { label: 'Tech: Front & Backend Technology', name: 'techFrontBackendTechnology' }
];

export function topicArray(isNotDevBank = false) {
  const topics = [
    {
      label: 'Accounting',
      name: 'accounting'
    },
    {
      label: 'Career',
      name: 'career'
    },
    {
      label: 'Construction',
      name: 'construction'
    },
    {
      label: 'Data Analysis',
      name: 'dataAnalysis'
    },
    {
      label: 'Economics',
      name: 'economics'
    },
    {
      label: 'Education',
      name: 'education'
    },
    {
      label: 'Engineering',
      name: 'engineering'
    },
    {
      label: 'Entrepreneurship',
      name: 'entrepreneurship'
    },
    {
      label: 'Finance',
      name: 'finance'
    },
    {
      label: 'FYE',
      name: 'fye'
    },
    {
      label: 'History',
      name: 'history'
    },
    {
      label: 'Human Resources',
      name: 'humanResources'
    },
    {
      label: 'Law',
      name: 'law'
    },
    {
      label: 'Logistics',
      name: 'logistics'
    },
    {
      label: 'Marketing',
      name: 'marketing'
    },
    {
      label: 'Math',
      name: 'math'
    },
    {
      label: 'Medicine',
      name: 'medicine'
    },
    {
      label: 'Music',
      name: 'music'
    },
    {
      label: 'Onboarding',
      name: 'onboarding'
    },
    {
      label: 'Philosophy',
      name: 'philosophy'
    },
    {
      label: 'Politics',
      name: 'politics'
    },
    {
      label: 'Sales',
      name: 'sales'
    },
    {
      label: 'Strategy',
      name: 'strategy'
    },
    {
      label: 'Technology',
      name: 'technology'
    },

    // new
    {
      label: 'Communications',
      name: 'communications'
    },
    {
      label: 'Corporate Social Responsibility',
      name: 'corporateSocialResponsibility'
    },
    {
      label: 'Mindset',
      name: 'mindset'
    },
    {
      label: 'Fundraising',
      name: 'fundraising'
    },
    {
      label: 'Governance',
      name: 'governance'
    },
    {
      label: 'Investor Readiness',
      name: 'investorReadiness'
    },
    {
      label: 'Lobbying/Advocacy',
      name: 'lobbyingAdvocacy'
    },
    {
      label: 'Storytelling',
      name: 'storytelling'
    }
  ];
  // condition is correct, this will be useful while mapping bank and non bank topics
  if (!isNotDevBank) {
    return [...topics, ...bankTopics];
  }

  return topics;
}

/**
 * community Fields Array
 */
export function communityFieldsArray() {
  const communityFieldsArray = [
    {
      label: 'Mentors',
      name: 'mentors'
    },
    {
      label: 'Mentees',
      name: 'mentees'
    },
    {
      label: 'Administrators',
      name: 'admins'
    }
  ];
  return communityFieldsArray;
}

/**  */
export const GroupBasedRoleFormatter = (roleType: string, targetedWord: string) => {
  const groupData = useAppSelector((state) => state.groupDetails);
  if (groupData.data) {
    const { displayRoles } = groupData.data;
    if (displayRoles) {
      const formattedRole = formatRoleFromDisplayRoles(roleType, targetedWord, displayRoles);
      return formattedRole;
    }
  }
  return targetedWord;
};

export const formatRoleFromDisplayRoles = (roleType: string, targetedWord: string, displayRoles: any) => {
  const menteeType = displayRoles.mentee;
  const mentorType = displayRoles.mentor;
  const adminType = displayRoles.admin;
  if (roleType === 'mentee' && displayRoles.mentee) {
    const groupRoleObject = groupDisplayRoleMappings[menteeType];
    let formattedWord = groupRoleObject[targetedWord];
    return formattedWord;
  }
  if (roleType === 'mentor' && displayRoles.mentor) {
    const groupRoleObject = groupDisplayRoleMappings[mentorType];
    let formattedWord = groupRoleObject[targetedWord];
    return formattedWord;
  }
  // if (roleType === 'admin') {
  //   return targetedWord;
  // }
  return targetedWord;
};

export const formatCapRoleFromDisplayRoles = (roleType: string, displayRoles: any) => {
  if (displayRoles) {
    const menteeType = displayRoles.mentee;
    const mentorType = displayRoles.mentor;
    const adminType = displayRoles.admin;
    if (roleType === 'Mentees' && displayRoles.mentee) {
      const groupRoleObject = groupDisplayRoleMappings[menteeType];
      let formattedWord = groupRoleObject[roleType];
      return formattedWord;
    }
    if (roleType === 'Mentors' && displayRoles.mentor) {
      const groupRoleObject = groupDisplayRoleMappings[mentorType];
      let formattedWord = groupRoleObject[roleType];
      return formattedWord;
    }
    // if (roleType === 'admin') {
    //   return targetedWord;
    // }
  }
  return roleType;
};

/** Get Goal Progress show Or Not show */
export function getGoalProgressShowOrNot(goalValue: any) {
  const showGoalProgress = goalValue;
  return showGoalProgress;
}

export function getAverage(numerator: any, denominator: any) {
  let avgValue: any = 0;
  if (denominator && denominator !== 0 && numerator && numerator !== 0) {
    avgValue = formatNumber(numerator / denominator);
  }
  return avgValue;
}

export function getFirstName(name: any) {
  let firstName = '';
  if (name.trim() !== '') {
    // Split the full name by space
    const parts = name.split(' ');
    if (parts.length > 0) {
      firstName = parts[0];
    }
  }
  return firstName;
}

export async function updateInsightDataByArticleId(orgId: any, articleId: any, articleLocation: any) {
  return await API.updateInsightByArticleId({
    orgId: orgId,
    articleId: articleId,
    isContentSettings: false,
    articleLocation: articleLocation ? articleLocation : ''
  });
}

/** Dummy avatar created */
const createDummyAvatarArray = (useImage = false) => {
  const letters = ['D', 'O', 'S', 'E', 'N', 'A'];

  return Array.from({ length: letters.length }, (_, index) => ({
    avatar: useImage ? `https://classic.dosen.io/images/litters-images/${letters[index].toLowerCase()}.png` : '',
    firstName: letters[index]
  }));
};

/** If avatar array is null and loader is true, use this as a dummy avatar */
export const dummyAvatar = createDummyAvatarArray();
/** Dummy avatar with image */
export const dummyAvatarImage = createDummyAvatarArray(true);

/** For loading with set time */
export function showMyLoader(time = 1500) {
  const [loader, setLoader] = useState(true);

  // setTimeout(function() {
  //   setloader(false);
  // }, time);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLoader(false);
    }, time);

    return () => clearTimeout(timeoutId);
  }, [time]);

  return loader;
}

/** Handle link clipboard */
export const handleCopyToClipboard = (value: any, successMessage: any, errorMessage?: any) => {
  toast.dismiss();
  navigator.clipboard
    .writeText(value)
    .then(() => {
      toast.success(successMessage);
    })
    .catch((error) => {
      toast.error(errorMessage || 'Copy failed');
    });
};

/** Toast warning  */
export const toastWarning = (warningMessage?: any) => {
  toast.dismiss();
  toast.warning(warningMessage || 'Warning message!');
};

/** Toast error  */
export const toastError = (errorMessage?: any) => {
  toast.dismiss();
  toast.error(errorMessage || 'Warning message!');
};

/** Lock icon */
export const lockIcon = ({
  size = '13px',
  color = '#152536',
  cursor = 'default'
}: {
  size?: string;
  color?: string;
  cursor?: string;
}) => {
  return <LockOutlinedIcon sx={{ cursor: cursor, height: size, width: size, color }} />;
};

/** Dummy myMentors list for loading */
const generateDummyMentors = (count: number) => {
  const dummyMentors = [];
  for (let i = 1; i <= count; i++) {
    dummyMentors.push({
      displayName: `Loading Mentor ${i}`,
      requestFlag: true
    });
  }
  return dummyMentors;
};

/** Use the function to generate dummy data with a specific count */
export const dummyMyMentorsList = generateDummyMentors(2);

/** Dummy and Dosen Social Data */
export const dummySocialData = {
  facebook: 'https://www.facebook.com/dosen.io/',
  instagram: 'https://www.instagram.com/dosen.io',
  twitter: 'https://twitter.com/dosen',
  linkedIn: 'https://www.linkedin.com/company/dosen/',
  youtube: 'https://www.youtube.com'
};

/** Dummy Work and Edu Data */
export const dummyWorkAndEduData = [
  {
    company_name: 'Dosen,LLC',
    roleType: ['Developer'],
    title: 'Software Engineer',
    start_date: '2023',
    end_date: 'Present',
    industry: 'IT',
    currentlyWorking: false,
    university: 'Temple University',
    major: 'BSC,Mathematics & statistics',
    minor: ['Mathematics', 'Physics'],
    graduation_date: '2016',
    department: 'Computer Science'
  },
  {
    company_name: 'ABC Company',
    roleType: ['Developer'],
    title: 'Software Engineer',
    start_date: '2020',
    end_date: '2022',
    industry: 'IT',
    currentlyWorking: false,
    university: 'Harvard University',
    major: 'Master in Design',
    minor: ['Mathematics', 'Physics'],
    graduation_date: '2018',
    department: 'Computer Science'
  }
];

/** Countries list */
export const sortedCountries = [...countries]?.sort((a, b) => {
  const labelA = a.label.toLowerCase();
  const labelB = b.label.toLowerCase();
  if (labelA < labelB) {
    return -1;
  }
  if (labelA > labelB) {
    return 1;
  }
  return 0;
});

/** Truncate Text */
export const truncateText = (text: any, maxLength: number) => {
  if (text && text.length > maxLength) {
    return `${text.slice(0, maxLength)}...`;
  } else {
    return text;
  }
};

/** Dummy Data for Topics*/
export const dummyTopics = ['Topic 5', 'Topic 6', 'Topic 7', 'Topic 8'];

/** Display name */
export const getDisplayName = (each: any) => {
  const firstName: any = each?.displayName.split(' ')[0];
  const lastName: any = each?.displayName.split(' ').slice(1).join(' ');
  return `${firstName} ${lastName}`;
  // return firstName
  //   ?.charAt(0)
  //   ?.toUpperCase()
  //   ?.concat(firstName?.slice(1)?.toLowerCase())
  //   ?.concat(
  //     ' ',
  //     lastName?.charAt(0)?.toUpperCase()?.concat(lastName?.slice(1)?.toLowerCase())
  //   );
};

/** Get Border Color */
export const getBorderColor = (levelColor: any, taskActive?: boolean) => {
  if (!taskActive) {
    return '#ABB5BE'; // Gray color when task is not active
  }

  if (levelColor === 'gold' || levelColor === 'green') {
    return '#11895E'; // Color for gold or green levels (If you need green color take it '#11895E')
  }

  return '#ABB5BE'; // Default gray color
};

/** Get Trophy Badge */
export const getTrophyBadge = (hexColor: any, taskActive?: boolean) => {
  if (!taskActive) {
    return Trophybadge2; // Return Trophybadge2 if task is not active
  }

  switch (hexColor) {
    case 'gold':
      return Trophybadge; // Gold color Trophybadge
    case 'green':
      return ThrophyBadgeGreen; // Green color ThrophyBadgeGreen
    default:
      return Trophybadge2; // Default gray color Trophybadge2
  }
};
