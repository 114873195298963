import React, { useEffect, useRef, useState } from 'react';
import { Box, Link, Divider, TextField, IconButton, Typography, Popover } from '@mui/material';
import uuid from 'react-uuid';
import TaskItem from './TaskItem';
import { Section } from './Section';
import { useDrag, useDrop } from 'react-dnd';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { useAppSelector } from '../../../../../../../redux/hooks';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { ITaskData, ITaskLevels } from '../../../../../../../redux/slices/tasksSlice';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { DeleteIcon, EditIcon, SetTimeDataIcon } from '../../../../../../../utils/Icons';
import SetDateModal from './Dialog/SetDateModal';
import EditLevelNameModal from './Dialog/EditLevelNameModal';
import ConfirmationModal from '../../../../../components/ConfirmationModal';
import { levelTypes } from '../../../../../../../utils/constants';
import { lockIcon } from '../../../../../components/common';

interface TaskItemProps {
  setSelectedTask: (task: any) => void;
  level: ITaskLevels;
  selectedTaskId: string;
  tempTasks: ITaskData | null;
  setTempTasks: any;
  levelIndex: number;
  levelRef: any;
  setLevelFlag: any;
  handleDeleteLevel: any;
  setLevelId: any;
  moveLevel: any;
}

const LevelItem: React.FC<TaskItemProps> = ({
  setSelectedTask,
  level,
  selectedTaskId,
  tempTasks,
  setTempTasks,
  levelIndex,
  levelRef,
  setLevelFlag,
  handleDeleteLevel,
  setLevelId,
  moveLevel
}) => {
  const handleAddTasks = (id: string) => {
    setFocusFlag(true);
    if (id && tempTasks && tempTasks.levels) {
      const newTasks = tempTasks.levels.map((level) => {
        if (level._id === id) {
          return { ...level, isTaskAdd: true };
        }
        return level;
      });
      setTempTasks({ ...tempTasks, levels: newTasks });
    }
    // dispatch(adminTaskActions.updateTasks({ ...data, levels: newTasks }));
  };
  const isPublished = useAppSelector((state) => state.groupDetails.data?.goalPublished);
  const [isHover, setIsHover] = useState(false);
  const [taskValue, setTaskValue] = useState('');
  const [levelValue, setLevelValue] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClickPopover = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClosePopover = () => {
    setAnchorEl(null);
  };
  const canDragDrop = !isPublished;
  const openPopover = Boolean(anchorEl); // Check if popover should be open
  const popoverId = openPopover ? 'simple-popover' : undefined;
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [focusFlag, setFocusFlag] = useState(false);
  const [openEditLevelNameModal, setopenEditLevelNameModal] = useState(false);
  const handleopenEditLevelNameModal = (flag: boolean) => setopenEditLevelNameModal(flag);
  const [openSetDateModal, setopenSetDateModal] = useState(false);
  const handleopenSetDateModal = (flag: boolean) => setopenSetDateModal(flag);
  const [openDeleteModal, setOpenDeleteModal] = useState(false); // Initialize the modal state
  const handleDeleteConfirm = (flag: boolean) => {
    setOpenDeleteModal(flag);
  };

  const handleBlur = (id: string) => {
    if (id && tempTasks) {
      if (taskValue.trim() === '') {
        const newTasks = tempTasks?.levels.map((level) => {
          if (level._id === id) {
            return { ...level, isTaskAdd: false };
          }
          return level;
        });
        setTempTasks({ ...tempTasks, levels: newTasks });
        // dispatch(adminTaskActions.updateTasks({ ...data, levels: newTasks }));
      } else {
        const newTasks = tempTasks?.levels.map((level) => {
          if (level._id === id) {
            return {
              ...level,
              isTaskAdd: false,
              tasks: [
                ...level.tasks,
                {
                  taskName: taskValue,
                  menteeAnsType: '',
                  menteeQues: '',
                  _id: uuid(),
                  isNewTask: true,
                  tags: [],
                  taskDescription: '',
                  startDate: '',
                  endDate: ''
                }
              ]
            };
          }
          return level;
        });
        setTempTasks({ ...tempTasks, levels: newTasks });
        // dispatch(adminTaskActions.updateTasks({ ...data, levels: newTasks }));
        setTaskValue('');
      }
    }
    setFocusFlag(false);
  };

  const handleLevelBlur = ({ id, isNew }: { id: string; isNew: boolean }) => {
    if (id && tempTasks) {
      if (levelValue.trim() === '') {
        if (isNew) {
          const newTasks = tempTasks?.levels.filter((level) => level.levelName !== '');
          setTempTasks({ ...tempTasks, levels: newTasks });
        } else {
          const newTasks = tempTasks?.levels.map((level) => {
            if (level._id === id) {
              return { ...level, isLevelAdd: false };
            }
            return level;
          });
          setTempTasks({ ...tempTasks, levels: newTasks });
        }
        // dispatch(adminTaskActions.updateTasks({ ...data, levels: newTasks }));
      } else {
        const newTasks = tempTasks?.levels.map((level) => {
          if (level._id === id) {
            return {
              ...level,
              isLevelAdd: false,
              levelName: levelValue
            };
          }
          return level;
        });
        setTempTasks({ ...tempTasks, levels: newTasks });
        // dispatch(adminTaskActions.updateTasks({ ...data, levels: newTasks }));
        setTaskValue('');
      }
      setLevelValue('');
    }
    setLevelFlag(false);
  };

  const handleAddLevels = (id?: string) => {
    if (id && tempTasks && tempTasks.levels) {
      const newTasks = tempTasks.levels.map((level) => {
        if (level._id === id) {
          setLevelValue(level.levelName);
          return { ...level, isLevelAdd: true };
        }
        return level;
      });
      setTempTasks({ ...tempTasks, levels: newTasks });
      setFocusFlag(true);
    } else if (!id && tempTasks) {
      const newTasks = [
        ...tempTasks.levels,
        {
          _id: uuid(),
          levelName: '',
          tasks: [],
          isLevelAdd: true,
          level: tempTasks.levels.length + 1,
          levelDescription: '',
          startDate: {
            type: levelTypes.activeNow,
            value: 0
          },
          endDate: {
            type: levelTypes.activeNow,
            value: 0
          }
        }
      ];
      setTempTasks({ ...tempTasks, levels: newTasks });
      setFocusFlag(true);
    }
  };

  const handleDeleteTask = ({ levelId, taskId }: { levelId: string; taskId: string }) => {
    // Find the current level and task index

    if (levelId && taskId && tempTasks && tempTasks.levels) {
      const newTasks =
        tempTasks?.levels.map((level) => {
          if (level._id === levelId) {
            return {
              ...level,
              tasks: level.tasks.filter((task) => task._id !== taskId)
            };
          }
          return level;
        }) || [];
      setTempTasks({ ...tempTasks, levels: newTasks });

      if (selectedTaskId === taskId) {
        setSelectedTask({});
      }
    }
  };

  const moveTask = ({
    toTaskIndex,
    toLevelIndex,
    fromTaskIndex,
    fromLevelIndex,
    levelId
  }: {
    toTaskIndex: number;
    toLevelIndex: number;
    fromTaskIndex: number;
    fromLevelIndex: number;
    levelId: any;
  }) => {
    setTempTasks((prevLevels: any) => {
      // Make a copy of the levels to avoid mutating the state directly
      const updatedLevels = [...prevLevels.levels];

      // Find the from and to levels
      const fromLevel = updatedLevels[fromLevelIndex];
      const toLevel = updatedLevels[toLevelIndex];

      // Make a copy of the tasks to avoid mutating the state directly
      const updatedFromLevelTasks = [...fromLevel.tasks];

      // Get the task to move
      const [movedTask] = updatedFromLevelTasks.splice(fromTaskIndex, 1);

      // Determine the destination index
      const destinationIndex = toTaskIndex === undefined ? toLevel.tasks.length : toTaskIndex;

      if (fromLevelIndex === toLevelIndex) {
        // If moving within the same level, update the tasks array directly
        updatedFromLevelTasks.splice(destinationIndex, 0, movedTask);
        updatedLevels[fromLevelIndex] = {
          ...fromLevel,
          tasks: updatedFromLevelTasks
        };
      } else {
        // Otherwise, update both the source and destination levels
        const updatedToLevelTasks = [...toLevel.tasks];
        updatedToLevelTasks.splice(destinationIndex, 0, movedTask);

        updatedLevels[fromLevelIndex] = {
          ...fromLevel,
          tasks: updatedFromLevelTasks
        };
        updatedLevels[toLevelIndex] = {
          ...toLevel,
          tasks: updatedToLevelTasks
        };
      }
      return { ...prevLevels, levels: updatedLevels };
    });
    // if (levelId) setLevelId(levelId);
  };

  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'LEVEL',
    item: { levelIndex },
    canDrag: () => canDragDrop,
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  }));

  const [, drop] = useDrop(() => ({
    accept: 'LEVEL',
    canDrop: () => canDragDrop,
    drop: (item: any) => {
      moveLevel(item.levelIndex, levelIndex);
    }
  }));

  useEffect(() => {
    if (inputRef.current && focusFlag) {
      inputRef.current.focus();
    }
  }, [focusFlag]);

  useEffect(() => {
    if (level.isLevelAdd) {
      levelRef.current?.focus();
    }
  }, [level.isLevelAdd]);
  const currentDate = Date.now() / 1000;
  const getLevelLocked = (level: any) => {
    if (level?.startDate?.type === levelTypes.activeNow || level?.startDate === undefined) {
      return true;
    }
    if (level?.startDate?.type === levelTypes.availableSoon) {
      return false;
    }

    if (level?.startDate?.type === levelTypes.calendarDate && currentDate >= level?.startDate?.value) {
      return true;
    }
    if (level?.startDate?.type === levelTypes.daysAfterSignup) {
      return false;
    }
    return false;
  };

  /**  */

  return isDragging ? null : (
    <>
      <Box
        className={isHover && !level.isLevelAdd && (!isPublished || level.isNewLevel) ? 'main-icon-sec' : ''}
        my={isHover && !level.isLevelAdd && (!isPublished || level.isNewLevel) ? '' : 2}
        pr={1}
        pl={2}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(true)}
        style={{ marginTop: '0px', marginBottom: '0px', cursor: 'pointer' }}
        ref={(node: any) => drag(drop(node))}
      >
        <Box flex={1}>
          <Box display="flex" gap="8px">
            <DragHandleIcon fontSize="medium" className="secondary-light cursor-pointer" />
            {level.isLevelAdd ? (
              <TextField
                variant="outlined"
                size="small"
                inputRef={levelRef}
                className="w-100"
                placeholder="Level Name"
                value={levelValue}
                onChange={(e) => setLevelValue(e.target.value)}
                onBlur={() => handleLevelBlur({ id: level?._id || '', isNew: level.isNewLevel || false })}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    event.preventDefault(); // Prevent form submission
                    handleLevelBlur({ id: level?._id || '', isNew: level.isNewLevel || false });
                  }
                }}
              />
            ) : (
              <Typography className="level-name">Level {levelIndex + 1}</Typography>
            )}
          </Box>
          {!level.isLevelAdd && (
            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
              <Typography className="your-goal-txt" pl={3} onClick={() => handleAddLevels(level._id)}>
                {level.levelName}
              </Typography>
              <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                {/* {isHover && !level.isLevelAdd && (!isPublished || level.isNewLevel) && (
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteLevel(level._id);
                    }}
                  >
                    <DeleteOutlineIcon fontSize="small" style={{ color: 'grey' }} />
                  </IconButton>
                )} */}
                <Box>
                  {/* Lock Icon */}
                  {!getLevelLocked(level) && lockIcon({ size: '16px'})}

                  <IconButton size="small" onClick={handleClickPopover}>
                    <MoreVertIcon color="primary" fontSize="small" />
                  </IconButton>
                  <Popover
                    id={popoverId}
                    open={openPopover}
                    anchorEl={anchorEl}
                    onClose={handleClosePopover}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left'
                    }}
                    transformOrigin={{
                      vertical: 'center',
                      horizontal: 'right'
                    }}
                  >
                    <Box
                      className="PopoverListStyle"
                      onClick={() => {
                        handleClosePopover();
                        handleAddLevels(level._id);
                      }}
                    >
                      <EditIcon />
                      <Typography color="primary">Edit level name</Typography>
                    </Box>
                    {/* <Box className="PopoverListStyle" onClick={() => handleopenEditLevelNameModal(true)}>
                      <EditIcon />
                      <Typography color="primary">Edit level description</Typography>
                    </Box> */}
                    <Box className="PopoverListStyle" onClick={() => handleopenSetDateModal(true)}>
                      <SetTimeDataIcon />
                      <Typography color="primary">Set a start date</Typography>
                    </Box>

                    {!level.isLevelAdd && (!isPublished || level.isNewLevel) && (
                      <Box
                        className="PopoverListStyle"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDeleteLevel(level._id);
                        }}
                      >
                        <DeleteIcon />
                        <Typography color="primary">Delete level</Typography>
                      </Box>
                    )}
                  </Popover>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </Box>

      <Box>
        <>
          {level.tasks.map((task, taskIndex) => {
            const isActive = selectedTaskId === task._id;
            const isLast = level.tasks.length - 1 === taskIndex;
            return (
              <React.Fragment key={level._id}>
                <Section moveTask={moveTask} levelIndex={levelIndex} taskIndex={taskIndex} levelId={level._id} />

                <TaskItem
                  key={taskIndex}
                  task={task}
                  isActive={isActive}
                  setSelectedTask={setSelectedTask}
                  handleDeleteTask={handleDeleteTask}
                  setLevelId={setLevelId}
                  level={level}
                  moveTask={moveTask}
                  taskIndex={taskIndex}
                  levelIndex={levelIndex}
                  isLast={isLast}
                />
                {level.tasks.length - 1 === taskIndex && (
                  <Section moveTask={moveTask} levelIndex={levelIndex} taskIndex={undefined} levelId={level._id} />
                )}
              </React.Fragment>
            );
          })}
          {level.tasks.length === 0 && <Section moveTask={moveTask} levelIndex={levelIndex} taskIndex={undefined} />}
          {level.isTaskAdd && (
            <Box pb={1}>
              <Box pl={3} pr={2} gap="5px" height="34px" display="flex" alignItems="center" className="clickable">
                <DragHandleIcon fontSize="small" className="secondary-light" />

                <TextField
                  variant="outlined"
                  size="small"
                  className="w-100"
                  placeholder="Add Task"
                  value={taskValue}
                  onChange={(e) => setTaskValue(e.target.value)}
                  onBlur={() => handleBlur(level?._id || '')}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      event.preventDefault(); // Prevent form submission
                      handleBlur(level?._id || '');
                    }
                  }}
                  inputRef={inputRef}
                />
              </Box>
            </Box>
          )}
        </>
      </Box>

      <Link
        color="#0071A9"
        variant="caption"
        pl={2}
        onClick={() => handleAddTasks(level?._id || '')}
        style={level.isLevelAdd ? { pointerEvents: 'none', color: '#ccc' } : {}}
      >
        + Add Task
      </Link>

      {levelIndex !== (tempTasks?.levels?.length ? tempTasks?.levels?.length - 1 : 0) && (
        <Divider style={{ marginBottom: 0, marginTop: 20 }} />
      )}

      {/* Edit level name modal */}
      <EditLevelNameModal
        openEditLevelNameModal={openEditLevelNameModal}
        handleopenEditLevelNameModal={handleopenEditLevelNameModal}
      />

      {/* Set a start date modal */}
      <SetDateModal
        openSetDateModal={openSetDateModal}
        handleopenSetDateModal={handleopenSetDateModal}
        level={level}
        setTempTasks={setTempTasks}
        handleClosePopover={handleClosePopover}
      />

      {/* Delete Confirmation modal */}
      <ConfirmationModal
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        onConfirm={() => {
          setOpenDeleteModal(false);
        }}
        message="Are you sure you want to delete this </br> level?"
        confirmText="Yes, Delete"
      />
    </>
  );
};

export default LevelItem;
