/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Dialog,
  Tooltip,
  Divider,
  Accordion,
  Typography,
  DialogContent,
  AccordionDetails,
  AccordionSummary
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CommonModalHeader from '../../../../../components/DialogUi/CommonModalHeader';
import QuestionAndAnswersLoader from '../../../../../Loader/QuestionAndAnswersLoader';
import { IAllTaskData, ITaskDetails } from '../../../../../redux/slices/goals-tasks/getTasksSlice';

interface LevelQuestionAnswersProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  tasksData: IAllTaskData | null;
  taskLoading: boolean;
}

const LevelQuestionAnswers: React.FC<LevelQuestionAnswersProps> = ({ open, setOpen, tasksData, taskLoading }) => {
  const handleCloseModal = () => {
    setOpen(false);
  };

  const [openAccordion, setOpenAccordion] = useState<string>("");

  useEffect(() => {
    if (open && tasksData?.data?.[0]?.levels.length) {
      setOpenAccordion(String(tasksData.data[0].levels[0].level));
    }
  }, [open, tasksData]);

  const handleAccordionChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setOpenAccordion(isExpanded ? panel : '');
  };

  const createCustomCheckbox = (text: string) => {
    return (
      <Tooltip title={text}>
        <Box className="CheckBtnChip checked">
          <CheckCircleIcon />
          <Typography noWrap variant="body1" color="white">
            {text}
          </Typography>
        </Box>
      </Tooltip>
    );
  };

  const getQuestionTypes = (type: string, task: ITaskDetails) => {
    if (task.menteeAnswers?.length > 0 ? task.menteeAnswers[0] : '') {
      return (
        <Typography pl={2} className="secondary-light">No answer</Typography>
      );
    }

    if (type === 'multiselect') {
      return (
        <Grid container spacing={2} pt={1}>
          {task.menteeAnswers.map((each) => {
            return (
              <Grid item xs={12} sm={6} md={4} key={each}>
                {createCustomCheckbox(each)}
              </Grid>
            );
          })}
        </Grid>
      );
    }

    return (
      <Typography pl={2} className="secondary-light">
        {(task.menteeAnswers?.length > 0 ? task.menteeAnswers[0] : '')}
      </Typography>
    );
  };

  return (
    <Dialog
      open={open}
      onClose={handleCloseModal}
      PaperProps={{
        sx: { height: '100%' }
      }}
    >
      {/* Dialog Header */}
      <CommonModalHeader title={`Question & Answers`} handleCloseModal={handleCloseModal} />
      <DialogContent>
        {taskLoading ? (
          <QuestionAndAnswersLoader />
        ) : (
          <Box gap={2} display={'flex'} flexDirection={'column'}>
            {tasksData?.data[0]?.levels.map((item) => (
              <Box
                key={item.level}
                border={`1px solid ${openAccordion === String(item.level) ? '#ABB5BE' : '#EFF0F4'}`}
                borderRadius="8px"
              >
                <Accordion
                  expanded={openAccordion === String(item.level)}
                  onChange={handleAccordionChange(String(item.level))}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon className='p-0' />}
                    aria-controls={String(item.level)}
                    id={String(item.level)}
                  >
                    <Box>
                      <Typography variant="h6" className="secondary-light">
                        {'Level ' + item.level}
                      </Typography>
                      <Typography variant="h5" color="black">
                        {item.levelName}
                      </Typography>
                    </Box>
                  </AccordionSummary>
                  <Divider />

                  <AccordionDetails>
                    {item.tasks.map((task, index, arr) => (
                      <React.Fragment key={task._id}>
                        <Box>
                          <Typography className="pb-10">{task.taskName}</Typography>

                          {/* Display each mentee question */}
                          {task.menteeQuestions && task.menteeQuestions.length > 0 && (
                            task.menteeQuestions.map((question: any, idx: any) => (
                              <Box key={question.quesId}>
                                <Typography variant="h5" color="black" className='pb-10'>
                                  Q: {question.menteeQues}
                                </Typography>

                                {/* Display mentee answers instead of answer options */}
                                {question.menteeAns && question.menteeAns.length > 0 ? (
                                  <Box>
                                    {question.menteeAns.map((answer: string, idx: number) => (
                                      <Typography key={idx} variant="body1" color="textSecondary" mb={2} >
                                        {answer}
                                      </Typography>
                                    ))}
                                  </Box>
                                ) : (
                                  <Typography variant="body1" color="textSecondary" mb={2} >
                                    No answer
                                  </Typography>
                                )}
                              </Box>
                            ))
                          )}
                        </Box>
                        {index !== arr.length - 1 && <Divider className="my-20" />}
                      </React.Fragment>
                    ))}
                  </AccordionDetails>
                </Accordion>
              </Box>

            ))}
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default LevelQuestionAnswers;
