import React, { useState } from 'react';
import { Box, Divider, LinearProgress, Typography } from '@mui/material';

interface AccessibilityProps {
    heading?: string;
    options?: { label: string; percentage: number }[] | undefined;
}

const getDefaultOptions = (heading: string | undefined) => {
    switch (heading) {
        case 'Accessibility':
            return [
                { label: 'Physical Disability', percentage: 90 },
                { label: 'Cognitive Disability', percentage: 5 },
                { label: 'Hearing impaired', percentage: 10 },
                { label: 'Dyslexic', percentage: 55 },
                { label: 'Vision impairments', percentage: 70 },
            ];
        case 'Gender':
            return [
                { label: 'Male', percentage: 90 },
                { label: 'Female', percentage: 5 },
                { label: 'Transgender', percentage: 10 },
                { label: 'Prefer not to identify', percentage: 55 },
                { label: 'Unknown', percentage: 90 },
            ];
        case '1st Generation':
            return [
                { label: 'Not First Generation Student', percentage: 90 },
                { label: 'First Generation Student', percentage: 5 },
            ];
        case 'Ethnicity':
            return [
                { label: 'American Indian or Alaska Native', percentage: 90 },
                { label: 'Asian', percentage: 5 },
                { label: 'Black or African American', percentage: 10 },
                { label: 'Hispanic or Latino', percentage: 55 },
                { label: 'Vision impairments', percentage: 5 },
                { label: 'Native Hawaiian or Other Pacific Islander', percentage: 5 },
            ];
        default:
            return [];
    }
};

/** Progress Bar color */
const getColorFromSet = (index: number) => {
    const colorSet = ['#6EA8FE', '#FFDA6A', '#79DFC1', '#FEB272', '#A98EDA'];
    return colorSet[index % colorSet.length];
};

const Accessibility: React.FC<AccessibilityProps> = ({ heading, options }) => {
    const defaultOptions = getDefaultOptions(heading);
    /** Total respondents */
    const totalRespondents = (options || defaultOptions).reduce((acc, option) => acc + option.percentage, 0);

    const [colorIndex ] = useState(0);

    return (
        <Box className='CardBox'>
            <Box display="flex" justifyContent="space-between" alignItems="center" p={2}>
                <Typography variant="h6">{heading}</Typography>
                <Typography fontStyle={'italic'}>{totalRespondents} Respondents</Typography>
            </Box>
            <Divider />
            <Box p={2} display={'flex'} flexDirection={'column'} gap={1} height={'450px'}>
                {(options || defaultOptions).map((option: { label: string; percentage: number }, index: number) => {
                    const color = getColorFromSet(colorIndex + index); // Adjusted to use colorIndex + index
                    return (
                        <React.Fragment key={index}>
                            <Box display="flex" justifyContent="space-between" gap={'8px'}>
                                <Typography>{option.label}</Typography>
                                <Typography variant='h6'>{`${option.percentage}%`}</Typography>
                            </Box>
                            <Box width="100%">
                                <LinearProgress
                                    variant="determinate"
                                    value={option.percentage}
                                    sx={{ '& .MuiLinearProgress-bar': { backgroundColor: color } }}
                                />
                            </Box>
                        </React.Fragment>
                    );
                })}
            </Box>
        </Box>
    );
};

export default Accessibility;
