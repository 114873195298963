import { Dialog } from '@mui/material';
import IcanHelpYouWithForm from './IcanHelpYouWithForm';
import CommonModalHeader from '../../../components/DialogUi/CommonModalHeader';

interface IcanHelpYouWithModalProps {
  openPopup: boolean;
  handleOpenPopup: (flag: boolean) => void;
}

const IcanHelpYouWithModal: React.FC<IcanHelpYouWithModalProps> = ({ openPopup, handleOpenPopup }) => {
  return (
    <Dialog open={openPopup} onClose={() => handleOpenPopup(false)} fullWidth>
      {/* Dialog Header */}
      <CommonModalHeader title={`I can help you with`} handleCloseModal={() => handleOpenPopup(false)} />
      <IcanHelpYouWithForm handleOpenPopup={handleOpenPopup} />
    </Dialog>
  );
};

export default IcanHelpYouWithModal;
