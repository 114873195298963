/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-duplicate-imports */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import ContentSettings from './ContentSettings';
import HelpSettings from '../../../Help/HelpSettings';
import ProgramSettingsSideBar from '../ProgramSettingsSideBar';
import ChatSettings from './settings components/ChatSettings';
import GoalSettings from './settings components/GoalSettings';
import { adminPanel } from '../../../../../../utils/muiStyle';
import BasicSettings from './settings components/BasicSettings';
import { useAppDispatch } from '../../../../../../redux/hooks';
import { useAppSelector } from '../../../../../../redux/hooks';
import CustomProgram from '../../../goals/components/CustomProgram';
import CommunicationSettings from './settings components/CommunicationSettings';
import { customProgramActions } from '../../../../../../redux/slices/custom-program/customProgramSlice';
import TaskPreview from '../../components/program tabs/settings components/TaskPreview';
import { IAdminTask } from '../../../../../../redux/slices/tasksSlice';

// import { fetchAllTemplates } from '../../../../../../redux/slices/goals-templates/goalsTemplatesSlice';

interface SideBarComponents {
  [key: string]: React.ReactNode;
}

const ProgramSettings = () => {
  const classs = adminPanel();
  const [activeTab, setActiveTab] = useState('basicSettings');
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState<IAdminTask>({});
  const [levelId, setLevelId] = useState('');
  const [goalName, setGoalName] = useState('');
  const data = useAppSelector((state) => state.tasks.data);
  const handlePreviewClick = ({
    task,
    levelId,
    goalName
  }: {
    task?: IAdminTask;
    levelId: string;
    goalName: string;
  }) => {
    setGoalName(goalName);
    if (data && data.levels.length > 0) {
      setSelectedTask(task?._id ? task : data.levels[0].tasks[0]);
      setLevelId(levelId ? levelId : data.levels[0]._id || '');
    } else {
      setSelectedTask({});
      setLevelId('');
    }

    setIsPreviewOpen(true);
  };

  const sideBarComponents: SideBarComponents = {
    basicSettings: <BasicSettings />,
    contentSettings: <ContentSettings />,
    chatSettings: <ChatSettings />,
    goalSettings: <GoalSettings handlePreviewClick={handlePreviewClick} />,
    communicationSettings: <CommunicationSettings />,
    helpSettings: <HelpSettings />
  };

  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(customProgramActions.updateCustomProgramFlag(false));
    };
  }, []);

  const customProgramOpen = useAppSelector((state) => state.customProgarm.open);

  return (
    <>
      {/* {customProgramOpen ? (
         <CustomProgram /> */}
      {isPreviewOpen ? (
        <Box sx={{ overflow: 'hidden' }} className={classs.mainBody}>
          <Box height="100%">
            <TaskPreview
              onClose={() => setIsPreviewOpen(false)}
              selected={selectedTask}
              selectedLevelId={levelId}
              goalName={goalName}
            />
          </Box>
        </Box>
      ) : (
        <Box sx={{ overflow: 'hidden' }} className={classs.mainBody}>
          <Box height="100%">
            <Grid container columnSpacing={2} height="100%">
              <Grid item sm={3} md={3} lg={3}>
                <ProgramSettingsSideBar activeTab={activeTab} setActiveTab={setActiveTab} />
              </Grid>
              <Grid item sm={9} md={9} lg={9} height="100%">
                <Box sx={{ overflow: 'auto' }} height="100%">
                  {sideBarComponents[activeTab]}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      )}
    </>
  );
};

export default ProgramSettings;
