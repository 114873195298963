import { 
    Box, 
    Table, 
    TableRow, 
    TableBody, 
    TableCell, 
    TableHead, 
    Typography, 
    TableContainer
 } from "@mui/material";
import MySkeleton from "../components/Skeleton";

const ProgramTableLoader = () => {
    
    return (
        <Box mt={2}>
            <TableContainer>
                <Table className="tableBody" width="100%" aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={2} width="50%" align="left">
                                <Typography>
                                    <MySkeleton width={150} />
                                </Typography>
                            </TableCell>
                            <TableCell colSpan={4} width="50%" align="left">
                                <Box className='last-cell-table-Loader'>
                                    <MySkeleton variant='circular' height={34} width={34} />
                                </Box>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <MySkeleton width={150} />
                            </TableCell>
                            <TableCell>
                                <MySkeleton width={120} />
                            </TableCell>
                            <TableCell>
                                <MySkeleton width={120} />
                            </TableCell>
                            <TableCell>
                                <MySkeleton width={100} />
                            </TableCell>
                            <TableCell>
                                <Typography className='last-cell-table-Loader'>
                                    <MySkeleton width={70} />
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {[...Array(3).keys()].map((_, index) => (
                            <TableRow key={index}>
                                <TableCell>
                                    <Box display={'flex'} gap={'8px'} alignItems={'center'}>
                                    <MySkeleton variant='rounded' height={34} width={34}/>
                                    <MySkeleton width={150}/>
                                    </Box>    
                                </TableCell>
                                <TableCell>
                                    <MySkeleton width={50} />
                                </TableCell>
                                <TableCell>
                                    <MySkeleton width={150} />
                                </TableCell>
                                <TableCell>
                                    <MySkeleton variant='rounded' height={20} width={60}/>
                                </TableCell>
                                <TableCell>
                                    <Typography className='last-cell-table-Loader'>
                                        <MySkeleton variant="rounded" height={15} width={3.5}/>
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}

export default ProgramTableLoader