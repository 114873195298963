/* eslint-disable prefer-destructuring */
/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable no-undef */
/* eslint-disable radix */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import {
  Box
  // Typography
} from '@mui/material';
import { convertToRaw, EditorState, ContentState, ContentBlock, AtomicBlockUtils } from 'draft-js';
import { toast } from 'react-toastify';
import { useAuth0 } from '@auth0/auth0-react';
import draftToHtml from 'draftjs-to-html';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useInfiniteQuery, useQuery, useQueryClient } from '@tanstack/react-query';
import { AzureCommunicationTokenCredential } from '@azure/communication-common';
import { ChatClient } from '@azure/communication-chat';
import moment from 'moment';
import { ACS_ADD_USER_TO_CHANNEL } from '../../../../constants';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import {
  filterTheAcsUsersWithPersonalThreadsWithCommId,
  formatAcsUserWithCommunicationId,
  getCurrentChannelInfo,
  getCurrentChannelName,
  getGroupChannelImage
} from '../../../../routes/helpers';
import { API } from '../../../../api';
import { acsHoldMessagesActions } from '../../../../redux/slices/acs-messages/acsHoldMessages';
import { getUserDetails } from '../../../../utils/orgName';
import { dialogActions } from '../../../../redux/slices/dialog-slice/dialogSlice';
import { chatProfileActions } from '../../../../redux/slices/chat-profile/chatProfileSlice';
import { fetchUserEducation, userEducationActions } from '../../../../redux/slices/user-education/userEducationSlice';
import { fetchUserWorkHistory, userWorkHistoryActions } from '../../../../redux/slices/user-work/userWorkHistorySlice';
import { acsChannelActions } from '../../../../redux/slices/acs-channels/acsChannels';
import { acsMessageActions } from '../../../../redux/slices/acs-message/acsMessage';
import ChatMain from './chat-main/ChatMain';
import { acsMessageNotificationActions } from '../../../../redux/slices/acs-message-notifications/acsMessageNotificationsSlice';
import useProfileDetails from '../../../../hooks/useProfileDetails';
import htmlToDraft from 'html-to-draftjs';
import useResponsiveScreenSwitch from '../../../../utils/useResponsiveScreenSwitch';
import { blobToFile, handleImageCompression, imageSizeLimit } from '../../components/common';
import { useXpPoints } from '../../components/menteeXpPoints';

/**
 * Chat
 */
let lastSeqId: any;
let threadValue = '';

interface Props {
  data: any;
  isLoading: boolean;
  error: any;
  hasNextPage: any;
  fetchNextPage: any;
  isFetchingNextPage: any;
}
const ChatBody: React.FC<Props> = ({
  data,
  isLoading,
  error,
  hasNextPage,
  fetchNextPage,
  isFetchingNextPage
}: Props) => {
  const { user } = useAuth0();
  const navigate = useNavigate();
  const chatKind = useAppSelector((state) => state.messageNotifications.chatKind);
  const { forMobile } = useResponsiveScreenSwitch(); // Use the hook
  // console.log(chatKind, forMobile);
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();
  const getXpPoints = useXpPoints();
  const selectedGroup = useAppSelector((state) => state.selectedGroup.data);
  const selectedChatUser = useAppSelector((state) => state.selectedChatUser.data);
  const timelineRef = useRef<any>();
  const dispatch = useAppDispatch();
  const [activeChat, setActiveChat] = useState<string>('');
  const [openChatInfoForm, setChatInfoForm] = useState<boolean>(false);
  const [updatingMessage, setUpdatingMessage] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [showEditCancel, setEditCancel] = useState(false);
  const [messageId, setMessageId] = useState('');
  const [updateMessageLoading, setUpdateMessageLoading] = useState(false);
  const [updateMessage, setUpdateMessage] = useState(false);
  const [file, setFile] = useState<string>('');
  const [updateFile, setUpdateFile] = useState('');
  const [disableSendButton, setDisableSendButton] = useState(false);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const acsChannels = useAppSelector((state) => state.acsChannels);
  const acsCommunityUsers = useAppSelector((state) => state.acsCommunityUsers);
  const { headshot, firstName } = useProfileDetails();
  const [openGroupDetails, setopenGroupDetails] = useState(false);
  const [openChatProfile, setOpenChatProfile] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const chatLoading = queryClient.getQueryState(['getAcsChatThreads'])?.status === 'pending';
  const threadLoading = queryClient.getQueryState(['getAcsChatThreads'])?.fetchStatus === 'fetching';

  const acsTokenLoading = useAppSelector((state) => state.acsToken.loading);
  const chatAccessed = localStorage.getItem('chatAccessed');

  const arrangedAcsCommunityMembers =
    _.isArray(acsCommunityUsers?.data) && !_.isEmpty(acsCommunityUsers?.data) ? acsCommunityUsers?.data : [];

  const userWorkHistory = useAppSelector((state) => state.userWorkHistory);
  const userEducation = useAppSelector((state) => state.userEducation);
  const chatProfile = useAppSelector((state) => state.chatProfile.chatProfileDetails);

  const acsChannelParticipants: IAcsUsers[] = useAppSelector((state) => state.acsChannelParticipants.data);

  // Extract the communicationUserId values using map
  const displayNames = acsChannelParticipants.map((participant) => participant.id.communicationUserId);

  const acsToken = useAppSelector((state) => state.acsToken.data.token);

  const formattedAcsOrgUsers: any = formatAcsUserWithCommunicationId(arrangedAcsCommunityMembers);

  const threadId = searchParams.get('threadid') || '';
  const urlSearchParams = new URLSearchParams(document.location.hash);
  const chatType = urlSearchParams.get('type') || 'Chats';
  threadValue = threadId || '';
  const commId = searchParams.get('commId') || '';
  const newEditorState = EditorState.createEmpty();
  const newStateWithCursorAtEnd = EditorState.moveFocusToEnd(newEditorState);
  const rawContentState = convertToRaw(editorState.getCurrentContent());
  const imgSize = imageSizeLimit();

  const directMessages: IThread[] = _.filter(
    _.get(acsChannels, 'personalThreadsArr'),
    (each) => !_.has(each, 'deletedOn')
  ).sort((a: any, b: any) => moment(_.get(b, 'lastMessageReceivedOn')).diff(moment(_.get(a, 'lastMessageReceivedOn'))));

  const groupMessages = _.filter(_.get(acsChannels, 'groupThreads'), (each) => !_.has(each, 'deletedOn')).sort(
    (a: any, b: any) => moment(_.get(b, 'lastMessageReceivedOn')).diff(moment(_.get(a, 'lastMessageReceivedOn')))
  );

  useEffect(() => {
    dispatch(acsMessageActions.atnClearMessage([]));
  }, []);

  useEffect(() => {
    setActiveChat(chatType);
  }, [chatType]);

  const channelUser =
    formattedAcsOrgUsers[getCurrentChannelName(acsChannels, threadValue, selectedChatUser?.communicationId)];

  // const channelUserFirstName = _.get(channelUser, 'id.firstName', '');
  // const channelUserLastName = _.get(channelUser, 'id.lastName', '');
  const channelUserName = _.get(channelUser, 'displayName', '');

  const channelUserFullName = `${channelUserName}`;
  const groupChannelImage = getGroupChannelImage(acsChannels, threadValue);
  const channelImage = chatType === 'Chats' ? _.get(channelUser, 'id.headshot') : groupChannelImage;
  const channel =
    chatType === 'Chats'
      ? channelUserFullName
      : getCurrentChannelName(acsChannels, threadValue, selectedChatUser?.communicationId);

  const groupChatInfo = getCurrentChannelInfo(acsChannels, threadValue);
  // const groupChatImg = groupChatInfo?.image;
  const cachedMessages = useAppSelector((state) =>
    _.filter(state.acsHoldMessages, (each) => _.get(each, 'threadId', '') === threadValue)
  );

  const removeBlock = (blockToRemove: any) => {};

  const updateEditor = (message: any, fileUrl: any, fileType: string) => {
    const messageContent = _.get(message, 'content.message', '');
    const { contentBlocks, entityMap } = htmlToDraft(messageContent);
    const contentStateWithEntities = ContentState.createFromBlockArray(contentBlocks, entityMap);
    const editorState = EditorState.createWithContent(contentStateWithEntities);

    if (fileUrl && fileType) {
      // Create an entity with the specified type and data
      const contentStateWithEntity = contentStateWithEntities.createEntity('EMBEDDED_LINK', 'IMMUTABLE', {
        src: fileUrl,
        type: fileType
      });

      const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

      // Insert an atomic block with the created entity
      const newEditorState = AtomicBlockUtils.insertAtomicBlock(editorState, entityKey, ' ');

      const fileName = fileUrl.substring(fileUrl.lastIndexOf('/') + 1);
      setUpdatingMessage(message);
      const createFile: any = new File([fileUrl], fileName, { type: fileType });
      setFile(fileUrl);
      setUpdateFile(createFile);

      setMessageId(message.id || _.get(message, 'contentId'));
      setUpdateMessage(true);
      setEditorState(newEditorState);
    } else {
      const messageContent = _.get(message, 'content.message', '');
      const { contentBlocks, entityMap } = htmlToDraft(messageContent);
      const contentStateWithEntities = ContentState.createFromBlockArray(contentBlocks, entityMap);
      const editorState = EditorState.createWithContent(contentStateWithEntities);
      setUpdatingMessage(message);
      setMessageId(message.id || _.get(message, 'contentId'));
      setUpdateMessage(true);
      setUpdateFile(fileUrl);
      // setFile(fileUrl);
      setEditorState(editorState);
    }
  };

  const add = () => {
    setDisabled(true);
    if (timelineRef && timelineRef?.current) {
      timelineRef.current.scrollTo(0, timelineRef.current.scrollHeight);
      setTimeout(() => {
        setDisabled(false);
      }, 200);
    }
  };

  const handleUpdateMessage = async (markup: any) => {
    setUpdateMessage(false);
    const updatingMessageId = _.get(updatingMessage, 'id');
    const updatingSeqId = _.get(updatingMessage, 'sequenceId');
    setEditorState(newStateWithCursorAtEnd);
    setEditCancel(false);
    setUpdatingMessage(null);
    const { orgId } = getUserDetails();
    setUpdateMessageLoading(true);
    setDisableSendButton(false);
    let content = markup.replace(/&nbsp;/g, '').trim();
    if (activeChat === 'Groups') {
      const newGroup = groupMessages.map((each) => {
        if (
          each.id === threadId &&
          (each.msgId === updatingMessageId || String(each.seqId) === String(updatingSeqId))
        ) {
          return {
            ...each,
            lastmessage: content,
            file
          };
        }
        return each;
      });
      dispatch(acsChannelActions.updateAcsGroupChannesls(newGroup));
    }
    if (activeChat === 'Chats') {
      const newGroup = directMessages.map((each) => {
        if (
          each.id === threadId &&
          (each.msgId === updatingMessageId || String(each.seqId) === String(updatingSeqId))
        ) {
          return {
            ...each,
            lastmessage: content,
            file
          };
        }
        return each;
      });
      dispatch(acsChannelActions.updateAcsDirectChannesls(newGroup));
    }
    queryClient.setQueryData(['recieveMessages', threadValue], (prevData: any) => {
      const value = prevData.pages[0].value;
      const newValues = value.map((each: any) => {
        if (each.id === updatingMessageId) {
          return {
            ...each,
            content: { message: content },
            metadata: { file },
            editedOn: new Date().toISOString(),
            status: 'loading'
          };
        }
        return each;
      });
      return { ...prevData, pages: [{ value: newValues }] };
    });

    try {
      const { status, data } = await API.editMessage({
        acsToken,
        threadId,
        file,
        messageId: _.get(updatingMessage, 'id', ''),
        content,
        orgId
      });
      if (status === 200) {
        queryClient.setQueryData(['recieveMessages', threadValue], (prevData: any) => {
          const value = prevData.pages[0].value;
          const newValues = value.map((each: any) => {
            if (each.id === _.get(data, 'Successfully Sent', '')) {
              return {
                ...each,
                metadata: { file: data.file },
                status: 'success'
              };
            }
            return each;
          });
          return { ...prevData, pages: [{ value: newValues }] };
        });
        setUpdateMessage(false);
        setDisableSendButton(false);
        setUpdatingMessage(null);
        setFile('');
      }
    } catch (err) {
      setDisableSendButton(false);
      setUpdateMessageLoading(false);
      toast.error('Message failed to send');
      setUpdateMessage(false);
    }
  };

  const handleSendMessageToAPI = async (content: string, file: any, contentId: number) => {
    try {
      const topic = activeChat === 'Chats' ? '#personal' : activeChat === 'Groups' ? '#group' : '';
      const { status, data } = await API.sendMessageToACSThread(
        acsToken,
        threadValue,
        content,
        file,
        contentId,
        topic,
        displayNames
      );
      if (status === 200) {
        if (selectedGroup?.role === 'mentee') {
          getXpPoints('mentee');
        }
        setDisableSendButton(false);
        setUpdatingMessage(null);
        setFile('');
        queryClient.setQueryData(['recieveMessages', threadValue], (prevData: any) => {
          const value = prevData.pages[0].value;
          const newValues = value.map((each: any) => {
            if (each.contentId === contentId) {
              return {
                ...each,
                metadata: {
                  ...each.metadata,
                  file: _.get(data, 'file', '')
                },
                status: 'success',
                id: _.get(data, 'Successfully Sent', '')
              };
            }
            return each;
          });
          return { ...prevData, pages: [{ value: newValues }] };
        });

        // queryClient.fetchQuery({ queryKey: ['getAcsChatThreads'] });
        lastSeqId = lastSeqId + 1;
        localStorage.removeItem('lastMessage');
        localStorage.removeItem('lastMessageGroup');
      }
    } catch (err) {
      // pending failed
    }
  };
  const handleFileUpload = async (event: any) => {
    let file = event.target.files[0];
    const fileName: any = file.name.toLowerCase();
    if (file && fileName.match(/\.(jpg|jpeg|png|svg)$/)) {
      if (file.size > imgSize.limitValue) {
        toast.error('We cannot upload this file, please try another.');
        return;
      }
      file = await handleImageCompression(event.target.files[0]);
    }

    if (file) {
      if (file.size > 50 * 1024 * 1024) {
        toast.error('The max file size is 50MB');
        return;
      }
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const fileDataUrl = e.target.result;
        let fileType = 'UNKNOWN';
        if (fileName.endsWith('.pdf')) {
          fileType = 'PDF';
        } else if (fileName.match(/\.(jpg|jpeg|png|gif)$/)) {
          fileType = 'IMAGE';
        } else if (fileName.match(/\.(xls|xlsx)$/i)) {
          fileType = 'EXCEL';
        } else if (fileName.match(/\.(doc|docx)$/i)) {
          fileType = 'WORD';
        } else if (fileName.match(/\.(mp4|avi|mov)$/)) {
          fileType = 'VIDEO';
        } else if (fileName.match(/\.(ppt|pptx)$/i)) {
          fileType = 'PPT';
        } else {
          fileType = 'DOCUMENT';
        }

        const contentStateWithEntity = editorState
          .getCurrentContent()
          .createEntity('EMBEDDED_LINK', 'IMMUTABLE', { src: fileDataUrl, type: fileType, name: fileName });

        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

        const newEditorState = AtomicBlockUtils.insertAtomicBlock(editorState, entityKey, ' ');

        setEditorState(newEditorState);

        // Create a FormData object and append the file to it
        setFile(file);
      };
      reader.readAsDataURL(file);
    }
  };

  const resendMessage = async (contentIdValue: number) => {
    const getContent = _.find(cachedMessages, (each) => _.get(each, 'contentId') === contentIdValue);
    setEditorState(newStateWithCursorAtEnd);
    dispatch(acsHoldMessagesActions.holdMessage({ ...getContent, status: 'loading' }));
    handleSendMessageToAPI(_.get(getContent, 'content.message', ''), _.get(getContent, 'content.file'), contentIdValue);
  };

  const handleSendMessage = async (markup: any) => {
    setEditorState(newStateWithCursorAtEnd);
    const contentId = Date.now();
    setDisableSendButton(false);
    let content: any;
    content = markup;
    localStorage.setItem('lastMessage', content);
    localStorage.setItem('lastMessageGroup', `You:`);
    if (activeChat === 'Groups') {
      const newGroup = groupMessages.map((each) => {
        if (each.id === threadId) {
          return {
            ...each,
            file,
            lastMessageReceivedOn: new Date().toISOString(),
            lastmessage: localStorage.getItem('lastMessage'),
            senderCommunicationId: localStorage.getItem('lastMessageGroup'),
            seqId: lastSeqId + 1,
            deleted: false,
            type: 'text'
          };
        }
        return each;
      });
      dispatch(acsChannelActions.updateAcsGroupChannesls(newGroup));
    }
    if (activeChat === 'Chats') {
      const newGroup = directMessages.map((each) => {
        if (each.id === threadId) {
          return {
            ...each,
            file,
            lastMessageReceivedOn: new Date().toISOString(),
            lastmessage: localStorage.getItem('lastMessage'),
            seqId: lastSeqId + 1,
            deleted: false,
            type: 'text'
          };
        }
        return each;
      });
      dispatch(acsChannelActions.updateAcsDirectChannesls(newGroup));
    }

    const senderCommunicationIdentifier = {
      rawId: getUserDetails().communicationUserId,
      communicationUser: getUserDetails().communicationUserId
    };

    queryClient.setQueryData(['recieveMessages', threadValue], (prevData: any) => {
      const value = prevData.pages[0].value;
      const sequenceId = String(Number(value[0].sequenceId) + 1);
      return {
        ...prevData,
        pages: [
          {
            value: [
              {
                communicationUserId: getUserDetails().communicationUserId,
                contentId: contentId,
                content: {
                  message: content
                },
                status: 'loading',
                metadata: {
                  file: file
                },
                senderCommunicationIdentifier,
                sequenceId,
                id: '',
                type: 'text',
                createdOn: new Date().toISOString()
              },
              ...value
            ]
          }
        ]
      };
    });

    handleSendMessageToAPI(content, file, contentId);
  };

  const handlePostMessage = async (content: any) => {
    const rawContentState = convertToRaw(content.getCurrentContent());
    let markup = draftToHtml(rawContentState);
    markup = markup.replace(/<iframe[^>]*>[\s\S]*?<\/iframe>/gi, '');
    markup = markup.replace(/<img[^>]*>/gi, '');
    add();
    // if (contentIdValue) {
    //   // this condition for resending the failed messages
    //   resendMessage(contentIdValue);
    // }
    // To Prevent from the Updation of the Same Message Again and again
    if (updateMessage) {
      // for update messages
      setUpdateMessage(false);
      handleUpdateMessage(markup);
    } else {
      handleSendMessage(markup);
    }
  };

  const handleEditCancel = () => {
    setEditCancel(false);
    setUpdatingMessage(null);
    setUpdateMessage(false);
    setEditorState(newStateWithCursorAtEnd);
  };

  const handleChatInfoForm = (flag: boolean) => {
    setChatInfoForm(flag);
  };

  const removeParticipantFromThread = async () => {
    const communicationId = getUserDetails().communicationUserId;

    try {
      await API.removeParticipantFromThread({
        threadId,
        acsToken,
        communicationId: selectedChatUser?.communicationId ? selectedChatUser?.communicationId : communicationId,
        tag: selectedChatUser.communicationId ? '#admin' : '#self'
      });
      // if (status === 200 && statusText === "OK") {
      queryClient.fetchQuery({ queryKey: ['getAcsChatThreads'] });

      const takeNewChannel = _.find(acsChannels.groupThreads, (each) => each.id !== threadId);
      if (_.size(takeNewChannel)) {
        navigate(`/app/chat/?threadid=${takeNewChannel?.id}&name=${takeNewChannel?.topic}&type=Groups`);
      } else {
        navigate('/app/chat');
      }
      // }
    } catch (err) {
      toast.error('The server timed out. Please try again');
    }
  };

  const handleAddPersonToThread = () => {
    dispatch(
      dialogActions.atnOpenDialog({
        dialogName: ACS_ADD_USER_TO_CHANNEL,
        // title: `Add Member to ${channel}`,
        title: `Add Member`,
        dialogDetails: { threadId: threadValue }
      })
    );
  };

  const onClickOnUserIcon = () => {
    if (formattedAcsOrgUsers) {
      dispatch(chatProfileActions.atnSetChatProfileState(channelUser));
      // to call user education api
      const commId = _.get(channelUser, 'id.communicationId', '');
      const userId = _.get(formattedAcsOrgUsers, `${commId}.userId`, '');
      if (activeChat === 'Chats') {
        dispatch(fetchUserEducation(userId.trim()));
        dispatch(fetchUserWorkHistory(userId.trim()));
      }
    }
  };

  const onCloseChatProfile = () => {
    dispatch(chatProfileActions.atnSetChatProfileState(null));
    dispatch(userEducationActions.clearEducation(null));
    dispatch(userWorkHistoryActions.clearWorkHistory(null));
  };

  const content = convertToRaw(editorState.getCurrentContent());

  useEffect(() => {
    setopenGroupDetails(false);
    setOpenChatProfile(false);
    onCloseChatProfile();
    setEditCancel(false);
    setUpdatingMessage(null);
    setUpdateMessage(false);
    setEditorState(newStateWithCursorAtEnd);
    // if (_.size(contentText)) {
    // }
  }, [threadId, activeChat]);

  return (
    <ChatMain
      threadValue={threadValue}
      file={file}
      handleFileUpload={handleFileUpload}
      activeChat={activeChat}
      chatType={chatType}
      setChatInfoForm={setChatInfoForm}
      channelImage={channelImage}
      channel={channel}
      setAnchorEl={setAnchorEl}
      onClickOnUserIcon={onClickOnUserIcon}
      acsChannelParticipants={acsChannelParticipants}
      formattedAcsOrgUsers={formattedAcsOrgUsers}
      handleAddPersonToThread={handleAddPersonToThread}
      disabled={disabled}
      messageId={messageId}
      setUpdateFile={setUpdateFile}
      updateMessageLoading={updateMessageLoading}
      timelineRef={timelineRef}
      acsMessagesData={data?.pages[0].value || []}
      data={data}
      setFile={setFile}
      fetchNextPage={fetchNextPage}
      chatProfile={chatProfile}
      disableSendButton={disableSendButton}
      editorState={editorState}
      error={error}
      groupChatInfo={groupChatInfo}
      handleChatInfoForm={handleChatInfoForm}
      handleEditCancel={handleEditCancel}
      handlePostMessage={handlePostMessage}
      hasNextPage={hasNextPage}
      isFetchingNextPage={isFetchingNextPage}
      isLoading={isLoading || acsTokenLoading || (chatAccessed ? threadLoading : false)}
      onCloseChatProfile={onCloseChatProfile}
      openChatInfoForm={openChatInfoForm}
      removeParticipantFromThread={removeParticipantFromThread}
      setActiveChat={setActiveChat}
      setEditCancel={setEditCancel}
      setEditorState={setEditorState}
      showEditCancel={showEditCancel}
      updateEditor={updateEditor}
      removeBlock={removeBlock}
      userEducation={userEducation}
      userWorkHistory={userWorkHistory}
      openGroupDetails={openGroupDetails}
      setopenGroupDetails={setopenGroupDetails}
      openChatProfile={openChatProfile}
      setOpenChatProfile={setOpenChatProfile}
    />
  );
};

export default ChatBody;
