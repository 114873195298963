import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from '../../api';

interface IInitialState {
  callsCount: number;
}

const initialState = {
  callsCount: 0
};

export const fetchCallsCount = createAsyncThunk('getCallsCount', (location: string) => {
  return API.getCallsCount(location).then((response) => response.data);
});

const callsCountSlice = createSlice({
  name: 'callsCount',
  initialState,
  reducers: {
    updateCount: (state, action) => {
      state.callsCount = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCallsCount.fulfilled, (state, action) => {
      state.callsCount = action.payload.data.count;
    });
  }
});

export default callsCountSlice.reducer;
export const callsCountActions = callsCountSlice.actions;
