/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable no-template-curly-in-string */
/* eslint-disable no-undef */
import { Box, CircularProgress, Grid, IconButton, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
// import downloadIcon from '../../../../../assets/images/downloadicon.svg';
// import AddReactionIcon from "@mui/icons-material/AddReaction";
import '../chat.scss';
// import { useAuth0 } from "@auth0/auth0-react";
import _ from 'lodash';
// import { API } from "../../../../api";
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { useSearchParams } from 'react-router-dom';
// import { toast } from "react-toastify";
import CachedIcon from '@mui/icons-material/Cached';
// import { useQueryClient } from "@tanstack/react-query";
// import { getUserDetails } from "../../../../utils/orgName";
import ChatAvatarComponent from '../ChatAvatarComponent';
import { formatAcsUserWithCommunicationId } from '../../../../../routes/helpers';
import { dialogActions } from '../../../../../redux/slices/dialog-slice/dialogSlice';
import { ACS_DELETE_MESSAGE } from '../../../../../constants';
import { chatProfileActions } from '../../../../../redux/slices/chat-profile/chatProfileSlice';
import { fetchUserEducation } from '../../../../../redux/slices/user-education/userEducationSlice';
import { fetchUserWorkHistory } from '../../../../../redux/slices/user-work/userWorkHistorySlice';
import { ChatStyle } from '../ChatStyling';
import useProfileDetails from '../../../../../hooks/useProfileDetails';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import pdffile from '../../../../../assets/images/pdf-file.svg';
import docfile from '../../../../../assets/images/doc-file.svg';
import textfile from '../../../../../assets/images/text-file.svg';
import excelfile from '../../../../../assets/images/excel-file.svg';
import pptfile from '../../../../../assets/images/ppt-file.svg';
import videofile from '../../../../../assets/images/video-file.svg';
import unknownfile from '../../../../../assets/images/unknown-file.svg';
import getAcsTokenDetails from '../../../../../utils/getAcsTokenDetails';
import VideoPlayer from '../../../components/VideoPlayer';
import { DeleteIcon, EditIcon } from '../../../../../utils/Icons';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';

interface IMessageComponent {
  who: string;
  message: IMessage;
  createdOn: string;
  file: string;
  editedOn: string;
  status: string;
  updateEditor: any;
  setEditCancel: any;
  handlePostMessage: (contentId: number) => void;
  senderCommunicationId: string;
}

function handleAnchorTagsInParagraph(paragraph: HTMLElement) {
  const { childNodes } = paragraph;
  let modifiedContent = '';

  childNodes.forEach((node: any) => {
    if (node.nodeName === 'A') {
      const anchorTag = node as HTMLAnchorElement;
      const originalHtml = anchorTag.outerHTML;
      const modifiedHtml = originalHtml.replace('<a', '<a target="_blank"');
      modifiedContent += modifiedHtml;
    } else {
      modifiedContent += node.outerHTML || node.textContent;
    }
  });

  return modifiedContent;
}

export const htmlText = (htmlString: string, editedOn: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');
  const elements: any = doc.body.children; // Get all child elements of the body

  let result = '';
  let olItemNumber = 1; // Initialize the ordered list item number

  for (let i = 0; i < elements.length; i++) {
    const element = elements[i];

    if (element.tagName === 'P') {
      // Use DOMParser to parse the HTML content
      const parser = new DOMParser();
      const doc: any = parser.parseFromString(element.innerHTML, 'text/html');

      // Manipulate the parsed content as needed
      const emojiRegex =
        /[\u{1F000}-\u{1F6FF}\u{1F900}-\u{1F9FF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}\u{1F100}-\u{1F1FF}]/u;
      const containsOnlyEmojis = [...doc.body.textContent].every((char) => emojiRegex.test(char));

      const isLastElement = i === elements.length - 1 && _.size(editedOn);
      const editedText = isLastElement ? `${' '}(Edited)` : '';

      // Create a new paragraph element and add content to it
      const paragraph = doc.createElement('p');
      paragraph.innerHTML = containsOnlyEmojis
        ? `${doc.body.textContent}${editedText}`
        : `${doc.body.innerHTML}${editedText}`;

      // Check if the paragraph contains specific domain extensions
      const urlRegex = /(\S+\.(com|io|net|org|co|info|ai)\S*)/g;
      const links = paragraph.innerHTML.match(urlRegex);

      if (links) {
        links.forEach((link: any) => {
          let anchorTag;
          if (/^https?:\/\//i.test(link)) {
            anchorTag = `<a href="${link}" target="_blank">${link}</a>`;
          } else {
            const sanitizedLink = link.replace(/<\/?[^>]+(>|$)/g, '');
            if (/^https?:\/\//i.test(sanitizedLink)) {
              anchorTag = `<a href="${sanitizedLink}" target="_blank">${sanitizedLink}</a>`;
            } else {
              const href = `http://${sanitizedLink}`;
              anchorTag = `<a href="${href}" target="_blank">${sanitizedLink}</a>`;
            }
          }
          paragraph.innerHTML = paragraph.innerHTML.replace(link, anchorTag);
        });
      }

      // Append the modified paragraph to the result
      result += paragraph.outerHTML;
    } else if (element.tagName === 'UL') {
      // Handle unordered (UL) lists
      element.querySelectorAll('li').forEach((listItem: any) => {
        listItem.style.listStyleType = 'disc'; // Set list-style-type to 'disc' for bullets
      });

      result += element.outerHTML;
    } else if (element.tagName === 'OL') {
      const listItems = element.querySelectorAll('li');
      let olHtml = '<ol>';

      listItems.forEach((listItem: any) => {
        olHtml += `<li>${olItemNumber}. ${listItem.textContent}</li>`;
        olItemNumber++;
      });

      olHtml += '</ol>';
      result += olHtml;
    } else if (element.tagName === 'IMG') {
      const altText = element.alt || 'image';
      result += `
        <div style="position: relative; display: inline-block;">
          <a href="${element.src}" download="${altText}" target="_blank">
            <img src="${element.src}" alt="${element.alt}" style="height: 300px; width: 300px;">
          </a>
          <a
            href="${element.src}"
            download="${altText}"
            target="_blank"
            style="position: absolute; bottom: 10px; right: 10px; font-size: 14px;"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              viewBox="0 0 24 24"
            >
              <path d="M0 0h24v24H0z" fill="none"/>
              <path
                d="M14 19h-4v-6H7.91L12 7.91l4.09 4.09H18z"
                fill="rgba(0, 0, 0, 0.54)"
              />
            </svg>
            Download
          </a>
        </div>
      `;
    } else if (element.tagName === 'IFRAME') {
      const iframeSrc = element.src;
      const iframeTitle = element.title || 'iframe';
      result += `
        <div style="position: relative; display: inline-block;">
          <a href="${iframeSrc}" download="${iframeTitle}" target="_blank">
            <iframe src="${iframeSrc}" frameborder="0" style="width: 100%; height: 300px;"></iframe>
          </a>
          <a
            href="${iframeSrc}"
            download="${iframeTitle}"
            target="_blank"
            style="position: absolute; bottom: 10px; right: 10px; font-size: 14px;"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              viewBox="0 0 24 24"
            >
              <path d="M0 0h24v24H0z" fill="none"/>
              <path
                d="M14 19h-4v-6H7.91L12 7.91l4.09 4.09H18z"
                fill="rgba(0, 0, 0, 0.54)"
              />
            </svg>
            Download
          </a>
        </div>
      `;
    }
  }

  return result;
};

/** Status indication */
const StatusIndication = ({
  status,
  message,
  handlePostMessage
}: {
  status: string;
  message?: IMessage;
  handlePostMessage?: any;
}) => {
  const classes = ChatStyle();
  switch (status) {
    case 'loading':
      return (
        <span> &nbsp; sending...</span>
        // <span />
        // <Typography
        //   color="GrayText"
        //   variant="subtitle1"
        //   sx={{
        //     fontSize: "11px",
        //     color: "#bdbcbb",
        //   }}
        //   pl={1}
        // >
        //   sending...
        // </Typography>
      );
    case 'failed':
      return (
        <Grid
          item
          p={1}
          onClick={() => {
            handlePostMessage(_.get(message, 'contentId', ''));
          }}
        >
          <CachedIcon color="error" className="cursor-pointer" />
        </Grid>
      );
    case 'edited':
      return (
        <span>
          {' '}
          &nbsp; <span className={classes.dot} /> Edited
        </span>
      );
    default:
      return null;
  }
};

/** MessageComponent */
const MessageComponent: React.FC<IMessageComponent> = ({
  who,
  message,
  file,
  createdOn,
  editedOn,
  status,
  updateEditor,
  setEditCancel,
  handlePostMessage,
  senderCommunicationId
}) => {
  // const firstLetterOfWho = _.slice(who, 0, 1);
  // const alphaColor = _.get(alphaColors, firstLetterOfWho, "");
  // const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  // console.log('messages', _.get(message, 'content.file', ''));

  // const src =
  //   useAppSelector((state) => state.userHeadshot.src) ||
  //   getUserDetails().picture;
  // const { user } = useAuth0();
  const [searchParams] = useSearchParams();
  const acsToken = useAppSelector((state) => state.acsToken.data.token);
  const threadId = searchParams.get('threadid') || '';
  const userProfile = useAppSelector((state) => state.userProfile.data?.personal_details);
  const { headshot, firstName } = useProfileDetails();
  // const loginUserProfileImage = _.get(userProfile, 'headshot');
  // const loginUserFName = _.get(userProfile, 'firstName', '');
  const loginUserCommunicationId = _.get(userProfile, 'communicationId');
  // console.log('jhaa', file);
  const [isHovered, setIsHovered] = React.useState(false);
  // const [isDeleted, setDelete] = useState(false);
  // const acsOrgUsers = useAppSelector((state) => state.acsOrgUsers.data);
  const acsCommunityUsers = useAppSelector((state) => state.acsCommunityUsers.data);
  const formattedAcsOrgUsers: any = formatAcsUserWithCommunicationId(acsCommunityUsers);
  const selectedChatUser = useAppSelector((state) => state.selectedChatUser.data);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  // const userProfile = user?.picture;

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  // const iframeTag = _.get(file, 'metadata.file', _.get(file, 'content.file', '')) as string;
  const fileOrg = _.get(file, 'metadata.file') as any | undefined;
  function getFileExtension(file: any) {
    if (file instanceof File) {
      // Check if the File object has a valid name
      if (file.name && file.name.includes('.')) {
        return file.name.split('.').pop();
      }
      // If name is not available, try to get extension from type property
      if (file.type && file.type.includes('/')) {
        return file.type.split('/').pop();
      }
    }
    return null;
  }

  let fileUrl: any;
  let fileExtension: any;
  const fileName = _.get(file, 'metadata.file', '') ? _.last(_.split(_.get(file, 'metadata.file'), '/')) : '';
  if (typeof fileOrg === 'string') {
    fileUrl = fileOrg;
  } else if (fileOrg instanceof Blob || fileOrg instanceof File) {
    fileUrl = URL.createObjectURL(fileOrg);
    fileExtension = getFileExtension(fileOrg);
  } else {
    // console.error("Invalid 'fileOrg' data type:", typeof fileOrg);
  }
  const extractSrcFromIframe = (iframeTag: string) => {
    const match = iframeTag.match(/src=["'](.*?)["']/);
    if (match && match[1]) {
      return match[1];
    }
    return '';
  };
  // const fileUrl: any = _.get(message, 'content.file', '');

  /** Get Files Details */
  let fileType: any;

  const getFileDetails = (url: string) => {
    const fileNameWithExtension: any = url.split('/').pop(); // Get the full file name with extension
    const decodedFileName = decodeURIComponent(fileNameWithExtension); // Decode any URL-encoded characters

    // Use a regular expression to remove numeric characters at the beginning
    const fileName = decodedFileName.replace(/^\d+/, '');
    const cleanedFileName =
      fileName.replace(/^_+/, '').split('.').slice(0, -1).join('') + '.' + fileName.split('.').pop();

    const lowerCaseUrl = url.toLowerCase();
    if (lowerCaseUrl.endsWith('.pdf') || fileExtension === 'pdf') {
      fileType = 'PDF';
      return {
        fileName: cleanedFileName,
        message: 'PDF Document',
        fileType: fileType,
        fileIcon: <img alt="" src={pdffile} />
      };
    } else if (
      lowerCaseUrl.endsWith('.doc') ||
      lowerCaseUrl.endsWith('.docx') ||
      fileExtension === 'docx' ||
      fileExtension === 'doc'
    ) {
      fileType = 'word';
      return {
        fileName: cleanedFileName,
        message: 'Word Document',
        fileType: fileType,
        fileIcon: <img alt="" src={docfile} />
      };
    } else if (
      lowerCaseUrl.endsWith('.xlsx') ||
      lowerCaseUrl.endsWith('.xls') ||
      lowerCaseUrl.endsWith('.csv') ||
      lowerCaseUrl.endsWith('.ods') ||
      lowerCaseUrl.endsWith('.xlsm') ||
      lowerCaseUrl.endsWith('.xltx') ||
      lowerCaseUrl.endsWith('.xltm') ||
      lowerCaseUrl.endsWith('.xlsb') ||
      lowerCaseUrl.endsWith('.xlk') ||
      lowerCaseUrl.endsWith('.xlw') ||
      lowerCaseUrl.endsWith('.xml') ||
      lowerCaseUrl.endsWith('.xla') ||
      lowerCaseUrl.endsWith('.xlam') ||
      lowerCaseUrl.endsWith('.xltx') ||
      lowerCaseUrl.endsWith('.xltm') ||
      lowerCaseUrl.endsWith('.xlt') ||
      lowerCaseUrl.endsWith('.xlts') ||
      lowerCaseUrl.endsWith('.xltm') ||
      lowerCaseUrl.endsWith('.xlt') ||
      lowerCaseUrl.endsWith('.xltm') ||
      lowerCaseUrl.endsWith('.xlt') ||
      lowerCaseUrl.endsWith('.xlsb') ||
      lowerCaseUrl.endsWith('.xlr') ||
      lowerCaseUrl.endsWith('.slk') ||
      fileExtension === 'xlsx' ||
      fileExtension === 'xls'
    ) {
      fileType = 'excel';
      return {
        fileName: cleanedFileName,
        message: 'Excel Document',
        fileType: fileType,
        fileIcon: <img alt="" src={excelfile} />
      };
    } else if (lowerCaseUrl.endsWith('.txt')) {
      fileType = 'text';
      return {
        fileName: cleanedFileName,
        message: 'Text Document',
        fileType: fileType,
        fileIcon: <img alt="" src={textfile} />
      };
    } else if (
      lowerCaseUrl.endsWith('.mp4') ||
      lowerCaseUrl.endsWith('.avi') ||
      lowerCaseUrl.endsWith('.mov') ||
      lowerCaseUrl.endsWith('.wmv') ||
      lowerCaseUrl.endsWith('.flv') ||
      lowerCaseUrl.endsWith('.mkv') ||
      lowerCaseUrl.endsWith('.webm') ||
      lowerCaseUrl.endsWith('.m4v') ||
      lowerCaseUrl.endsWith('.3gp') ||
      lowerCaseUrl.endsWith('.mpg') ||
      lowerCaseUrl.endsWith('.mpeg') ||
      lowerCaseUrl.endsWith('.ogg') ||
      lowerCaseUrl.endsWith('.qt') ||
      lowerCaseUrl.endsWith('.vob') ||
      lowerCaseUrl.endsWith('.dat') ||
      fileExtension === 'mp4' ||
      fileExtension === 'm4v'
    ) {
      fileType = 'video';
      return {
        fileName: cleanedFileName,
        fileType: fileType,

        // message: 'Video File',
        // fileIcon: <img src={videofile} />,

        message: url,
        fileIcon: ''
      };
    } else if (
      lowerCaseUrl.endsWith('.png') ||
      lowerCaseUrl.endsWith('.jpg') ||
      url.endsWith('.jpeg') ||
      lowerCaseUrl.endsWith('.gif') ||
      fileOrg.type === 'image/jpg' ||
      fileOrg.type === 'image/jpeg' ||
      fileOrg.type === 'image/png'
    ) {
      fileType = 'image';
      return {
        fileName: cleanedFileName,
        message: url,
        fileType: fileType,
        fileIcon: ''
      };
    } else if (lowerCaseUrl.endsWith('.pptx') || fileExtension === 'pptx') {
      fileType = 'PPTX';
      return {
        fileName: cleanedFileName,
        message: 'PPT Document',
        fileType: fileType,
        fileIcon: <img alt="" src={pptfile} />
      };
    } else {
      fileType = 'document';
      return {
        fileName: cleanedFileName,
        message: 'Documents File',
        fileType: fileType,
        fileIcon: <img alt="" src={unknownfile} />
      };
    }
  };

  /** File Download and loader */
  const [isDownloading, setIsDownloading] = useState(false);
  // const handleDownloadClick = () => {
  //   setIsDownloading(true);
  //   setTimeout(() => {
  //     setIsDownloading(false);
  //     // window.open(fileUrl);
  //     const anchor = document.createElement('a');
  //     anchor.href = fileUrl;
  //     anchor.download = fileName || '';
  //     anchor.click();
  //   }, 2000);
  // };

  const handleDownloadClick = () => {
    setIsDownloading(true);
    const downloadLink = document.createElement('a');
    downloadLink.href = fileUrl;
    downloadLink.download = fileName || '';
    // Check if the file type is MS Word or Excel and modify the download behavior
    if (fileType === 'word' || fileType === 'excel') {
      downloadLink.href = fileOrg; // Use fileOrg directly
    }
    downloadLink.click();
    setTimeout(() => {
      setIsDownloading(false);
    }, 2000);
  };

  const onClickOnUserIcon = () => {
    if (formattedAcsOrgUsers) {
      const channelUser = formattedAcsOrgUsers[senderCommunicationId];
      dispatch(chatProfileActions.atnSetChatProfileState(channelUser));
      const commId = _.get(channelUser, 'id.communicationId');
      const userId = _.get(formattedAcsOrgUsers, `${commId}.userId`);
      dispatch(fetchUserEducation(userId.trim()));
      dispatch(fetchUserWorkHistory(userId.trim()));
    }
  };
  const classess = ChatStyle();
  const hasImgTag = /<img.*?>/i.test(message.content.message);
  const selectedCommId = getAcsTokenDetails();

  const getOptions = () => {
    return (
      <Box
        display="flex"
        alignItems="center"
        boxShadow={1}
        borderRadius={1}
        bgcolor="#fff"
        sx={{
          position: 'absolute',
          top: -25,
          right: 20
        }}
        border="1px solid lightgray"
      >
        {/* <IconButton title="Add A Reaction">
        <AddReactionIcon
          fontSize="small"
          sx={{ padding: 0, margin: 0 }}
        />
      </IconButton> */}
        {senderCommunicationId === loginUserCommunicationId || senderCommunicationId.slice(2) === selectedCommId ? (
          <>
            {!selectedChatUser.communicationId && (
              <IconButton
                title="Edit"
                onClick={() => {
                  setEditCancel(true);
                  updateEditor(message, fileUrl, fileType);
                }}
                disabled={message.id ? false : true}
              >
                <EditIcon size={18} />
              </IconButton>
            )}
            <IconButton
              title="Delete"
              onClick={() =>
                dispatch(
                  dialogActions.atnOpenDialog({
                    dialogName: ACS_DELETE_MESSAGE,
                    title: 'Delete message',
                    dialogDetails: { message: message, threadId: threadId, acsToken: acsToken }
                  })
                )
              }
              disabled={message.id ? false : true}
            >
              <DeleteIcon size={18} />
            </IconButton>
          </>
        ) : null}
      </Box>
    );
  };

  return (
    <Grid item className={classess.chatProperties} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <Grid display="flex" alignItems="flex-start" width="100%" position="relative">
        <Grid item>
          <Box
            width="34px"
            height="34px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            position="relative"
            marginRight="8px"
          >
            {senderCommunicationId === loginUserCommunicationId ? (
              <ChatAvatarComponent
                image={headshot}
                width="34px"
                height="34px"
                type="noStatus"
                firstLetter={firstName.slice(0, 1)}
              />
            ) : (
              <ChatAvatarComponent
                onClickOnUserIcon={onClickOnUserIcon}
                image={_.get(formattedAcsOrgUsers, `[${senderCommunicationId}].id.headshot`)}
                firstLetter={who.slice(0, 1)}
                width="34px"
                height="34px"
                type="noStatus"
              />
            )}
          </Box>
        </Grid>

        <Grid item width="100%">
          <Stack direction="row" alignItems="center">
            <Typography
              color="primary"
              variant="subtitle2"
              onClick={() => {
                if (senderCommunicationId !== loginUserCommunicationId) {
                  onClickOnUserIcon();
                }
              }}
              sx={{
                cursor: senderCommunicationId !== loginUserCommunicationId ? 'pointer' : ''
              }}
            >
              {who}
            </Typography>
            <Typography fontSize="10px" color="#ABB5BE" pl={1}>
              {createdOn.toUpperCase()}
              <StatusIndication status={status} message={message} handlePostMessage={handlePostMessage} />
            </Typography>
          </Stack>
          {/* <Typography fontSize="small" color="GrayText" mt={-0.5}>active</Typography> */}

          {/* text message */}
          {message?.deletedOn ? (
            <Box display={'flex'} gap={'2px'} alignItems={'center'}>
              <DoNotDisturbIcon className='fs-14 secondary-light' />
              <Typography fontSize="14px" color="#ABB5BE" fontStyle="italic">
                This message was deleted
              </Typography>
            </Box>
          ) : (
            <>
              <Box
                className="chatContent"
                m={0}
                // sx={{
                //   margin: 0,
                //   wordBreak: 'break-word',
                //   minWidth: "200px",
                //   whiteSpace: 'pre-wrap'
                // }}
                dangerouslySetInnerHTML={{
                  __html: htmlText(_.get(message, 'content.message', ''), editedOn)
                }}
              />

              {/* File message */}
              <Box>
                {!fileUrl ? null : getFileDetails(fileUrl)?.fileType === 'image' ? (
                  <Box display="flex" alignItems="center" gap={1}>
                    <Box display="flex" flexDirection="column">
                      {/* <Typography>{getFileDetails(fileUrl)?.fileName}</Typography> */}
                      <Box width="110px" height="110px" mt={1} position="relative">
                        <img
                          src={getFileDetails(fileUrl)?.message}
                          height="110px"
                          width="110px"
                          alt="image"
                          style={{
                            objectFit: 'cover',
                            borderRadius: '8px'
                          }}
                        />

                        {isHovered && (
                          <Box borderRadius={5} bgcolor="#262738B3" position="absolute" top="5px" right="5px">
                            <IconButton sx={{ p: '5px' }}>
                              {isDownloading ? (
                                <CircularProgress disableShrink size="20px" sx={{ color: '#FFFFFF' }} />
                              ) : (
                                <FileDownloadOutlinedIcon
                                  onClick={fileUrl.startsWith('blob') ? () => { } : handleDownloadClick}
                                  sx={{
                                    color: fileUrl.startsWith('blob') ? 'grey' : '#FFFFFF',
                                    width: '20px',
                                    height: '20px'
                                  }}
                                />
                              )}
                            </IconButton>
                          </Box>
                        )}
                      </Box>
                    </Box>
                  </Box>
                ) : /** Video */
                  getFileDetails(fileUrl)?.fileType === 'video' ? (
                    <Box display="flex" alignItems="center" gap={1}>
                      <Box display="flex" flexDirection="column">
                        {/* <Typography>{getFileDetails(fileUrl)?.fileName}</Typography> */}
                        <Box width="370px" height="200px" mt={1} position="relative">
                          <VideoPlayer src={getFileDetails(fileUrl)?.message} width="370px" height="200px" />
                          {isHovered && (
                            <Box borderRadius={5} bgcolor="#262738B3" position="absolute" top="5px" right="5px">
                              <IconButton sx={{ p: '5px' }}>
                                {isDownloading ? (
                                  <CircularProgress disableShrink size="20px" sx={{ color: '#FFFFFF' }} />
                                ) : (
                                  <FileDownloadOutlinedIcon
                                    onClick={fileUrl.startsWith('blob') ? () => { } : handleDownloadClick}
                                    sx={{ color: '#FFFFFF', width: '20px', height: '20px' }}
                                  />
                                )}
                              </IconButton>
                            </Box>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  ) : (
                    <Box
                      display="flex"
                      alignItems="center"
                      border="1px solid #EFF0F4"
                      p={1}
                      min-width="200px"
                      width="300px"
                      borderRadius="8px"
                      mt={1}
                    >
                      <Box pr={1}>{getFileDetails(fileUrl)?.fileIcon}</Box>

                      <Box display="flex" flexDirection="column" width="200px">
                        {/* <Typography noWrap maxWidth="calc(100% - 10px)">
              {getFileDetails(fileUrl)?.fileName}
            </Typography> */}
                        <Typography fontSize="10px" color="#ABB5BE">
                          {getFileDetails(fileUrl)?.message}
                        </Typography>
                      </Box>

                      <Box>
                        <IconButton sx={{ padding: '0' }}>
                          {isDownloading ? (
                            <CircularProgress disableShrink color="secondary" size="24px" />
                          ) : (
                            <FileDownloadOutlinedIcon
                              onClick={fileUrl.startsWith('blob') ? () => { } : handleDownloadClick}
                            />
                          )}
                        </IconButton>
                      </Box>
                    </Box>
                  )}
              </Box>
            </>
          )}
        </Grid>

        {isHovered && !message.deletedOn && getOptions()}
      </Grid>
    </Grid>
  );
};

export default MessageComponent;
