import React from "react";
import { Box } from "@mui/material";
import MySkeleton from "../components/Skeleton";

const WorkAndEducationLoader = () => {
  const widths = [120, 80, 100, 150];

  return (
    <>
      <MySkeleton height={2} />
        <Box py={2}>
          <MySkeleton width={150} />
          <Box display="flex" gap={1} pt={2}>
            <MySkeleton variant="rounded" height={49} width={49} />
            <Box alignItems={"center"}>
              <Box display="flex" flexDirection={"column"}>
                <MySkeleton variant="text" height={25} width={200} />
                {[...Array(4).keys()].map((_, index) => (
                  <Box key={index}>
                    <MySkeleton variant="text" width={widths[index]} />
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
        </Box>
    </>
  );
};

export default WorkAndEducationLoader;
