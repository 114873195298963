import { Box, Grid } from "@mui/material";
import MySkeleton from "../components/Skeleton";

interface AdvisorCardsProps {
  minHeight: string; 
}

export const AdvisorCardsLoader: React.FC<AdvisorCardsProps> = ({ minHeight }) => {
  return (
    <Box className="boxBorder" height={'100%'} minHeight={minHeight}>
        <MySkeleton variant='rounded' height={'100%'}/> 
    </Box>
  );
}


export const ReviewTableLoader = () => {
  return (
    <Box className="boxBorder">
      <Box p={2}>
        <MySkeleton width={100}/>
      </Box>
      <MySkeleton height={2}/>
      <Box p={2} display={'flex'} flexDirection={'column'} gap={2}>
        {[...Array(2).keys()].map((_, index) => (
          <>
            <Box display="flex" alignItems="center" gap={2}>
              <MySkeleton variant='rounded' height={40} width={40}/> 
              <Box display="flex" flexDirection="column" flex="1" >
                <MySkeleton width={100}/>
                <MySkeleton width={'50%'}/>
              </Box>
              <MySkeleton width={100}/>
            </Box>
            {index < 1 && <MySkeleton height={2}/>}
          </>
        ))}
      </Box>
    </Box>
  );
}


export const EngagedTableLoader = () => {
  return (
    <Box className="boxBorder">
      <Box p={2} display="flex" alignItems="center" justifyContent={'space-between'}>
        <MySkeleton width={100}/>
        <MySkeleton width={70}/>
      </Box>
      <MySkeleton height={2}/>
      <Box p={2}>
        <Grid container width={'100%'} alignItems={'center'} spacing={2} mb={2}>
          <Grid item xs={6} />
          <Grid item xs={4} textAlign={'center'}>
            <MySkeleton width={100}/>
          </Grid>
          <Grid item xs={2} />
        </Grid>

        {[...Array(2).keys()].map((_, index) => (
            <>
                <Grid container width={'100%'} alignItems={'center'} spacing={2}>
                    <Grid item xs={6} display="flex" gap={1}>
                        <MySkeleton variant='rounded' height={40} width={40}/>
                        <Box>
                            <MySkeleton width={60}/>
                            <MySkeleton width={100}/>
                        </Box>
                    </Grid>
                    <Grid item xs={4} >
                        <Box ml={3.5}><MySkeleton variant='circular' height={24} width={24}/></Box>
                    </Grid>
                    <Grid item xs={2}>
                        <MySkeleton variant='rounded' height={20} width={49}/>
                    </Grid>
                </Grid>
                {index !== 1 && (
                    <Box py={2}>
                        <MySkeleton height={2}/>
                    </Box>
                )}
            </>
        ))}
      </Box>
    </Box>
  );
}




