import { Box, Typography } from '@mui/material';
import favicon from '../../../../assets/images/favicon.svg';
import useResponsiveScreenSwitch from '../../../../utils/useResponsiveScreenSwitch';

const AIChatThanksPage = () => {
  /** For responsive */
  const { forMobile } = useResponsiveScreenSwitch(); // Use the hook

  return (
    <Box
      className='bodyBox'
      m={'auto'}
      width={ forMobile ? '100%': '70%' }
      display='flex'
      textAlign='center'
      flexDirection='column'
      justifyContent= 'center'
    >
      <Box mb={2} width={'80px'} height={'80px'} borderRadius={'50%'} bgcolor={'#EFF0F4'} display={'flex'}>
        <img alt='' src={favicon} width="40" height="40" className='m-auto' />
      </Box>
      <Typography variant="h3" mb={2}>
        Thank you
      </Typography>
      <Typography fontSize="16px">
        Thanks for your feedback, it has been sent straight to our engineers. We’ll let you know as soon as your AI mentor is released.
      </Typography>
    </Box>
  );
};

export default AIChatThanksPage;
