/* eslint-disable no-console */
// NavigationUtils.js
import { toast } from 'react-toastify';
import { chatProfileActions } from '../../../redux/slices/chat-profile/chatProfileSlice';
import _ from 'lodash';
export const navigateToChat = (
  communicationUserId: string,
  isInCommunity: any,
  personalThreadsArr: any[],
  navigate: (arg0: string) => void,
  dispatch: (arg0: any) => void
) => {
  const channel = personalThreadsArr?.find((each: { displayNames: string | any[] }) =>
    each?.displayNames?.includes(communicationUserId)
  );
  dispatch(chatProfileActions.atnSetChatProfileState(null));

  if (communicationUserId) {
    if (channel) {
      // has a existing chat thread i.e. channel
      navigate(`/app/chat/?threadid=${_.get(channel, 'id')}&type=Chats&name=${_.get(channel, 'topic')}`);
    } else {
      // no chat thread
      if (isInCommunity === true) {
        // if member available in community page, show "start chat" page
        navigate(`/app/chat/?commId=${communicationUserId}`);
      } else {
        toast.error("You can't establish chat as the member is not listed in your community members.");
      }
    }
  } else {
    // api error
    toast.error('Please try again');
  }
};
