import React, { useEffect, useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import ProgressChart from '../ProgressChart';
import { roundOfNumber } from '../../../components/common';
import MyTooltip from '../../../components/MyTooltip';

function GoalProgress({ goalProgressValue }: { goalProgressValue: number }) {
  return (
    <Box className="CardBox" p={2} height={'100%'}>
      <Box mb={1}>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="row">
            <Typography variant='h6'>Goal Progress</Typography>
            <MyTooltip
              gapleft={.5}
              iconColor='#152536'
              title='The average progress score of all members in this group'
            />
          </Stack>
          <Stack direction="row" justifyContent="center" alignItems="baseline">
            <Typography className="CardRightTopText">{roundOfNumber(goalProgressValue)}%</Typography>
          </Stack>
        </Stack>
        {/* <Typography variant='body2' color='#808080'>Last 3 days</Typography> */}
      </Box>
      <Box sx={{ textAlign: '-webkit-center' }}>
        <ProgressChart percentage={goalProgressValue} />
      </Box>
    </Box>
  );
}

export default GoalProgress;
