import React from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, InputLabel, TextField, Typography } from '@mui/material';
import CommonModalHeader from '../../../../../../../../components/DialogUi/CommonModalHeader';

interface EditLevelNameModalProps {
  openEditLevelNameModal: boolean;
  handleopenEditLevelNameModal: (flag: boolean) => void;
}

const EditLevelNameModal: React.FC<EditLevelNameModalProps> = ({
  openEditLevelNameModal,
  handleopenEditLevelNameModal
}) => {

  return (
    <Dialog
      open={openEditLevelNameModal}
      onClose={() => handleopenEditLevelNameModal(false)}
      fullWidth
    >
      {/* Dialog Header */}
      <CommonModalHeader
        title={`Edit level name`}
        handleCloseModal={() => handleopenEditLevelNameModal(false)}
      />

      <DialogContent>
        <Typography variant="body1">
          You can set a description of each level that users will be able to see while the level is not yet available.
        </Typography>
        <Box mt={2}>
          <InputLabel>Level description</InputLabel>
          <TextField
            multiline
            minRows={3}
            name=""
            placeholder="Enter text here"
          />
        </Box>
      </DialogContent>

      <DialogActions>
        <Button fullWidth variant='contained' onClick={() => handleopenEditLevelNameModal(false)}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditLevelNameModal;
