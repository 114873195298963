import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { popupCountActions } from '../../../redux/slices/poupsCountsSlice';
import { GetMenteeOptions } from '../tabs/profile/GetMenteeOptions';
import { Box, Button, DialogActions, DialogContent, Typography } from '@mui/material';
import useProfileDetails from '../../../hooks/useProfileDetails';
import { API } from '../../../api';
import { toast } from 'react-toastify';
import { getUserDetails } from '../../../utils/orgName';
import { userActions } from '../../../redux/slices/user-profile/userProfieSlice';
import { LoadingButton } from '@mui/lab';
import { popupActions } from '../../../redux/slices/onboardingPopupsSlice';
import { companyActions } from '../../../redux/slices/companySlice';

const OnboardingQuestions = () => {
  const data = useAppSelector((state) => state.mentorTasks.data.data);
  const groupId = useAppSelector((state) => state.mentorTasks.data.grpId);
  const [index, setIndex] = useState(0);
  const [loading, setFtueLoading] = useState(false);
  const { userId, location } = getUserDetails();
  const dispatch = useAppDispatch();
  const handlePreviousClick = () => {
    if (index === 0) {
      dispatch(popupCountActions.decrement(0));
    } else {
      setIndex((prevState) => prevState - 1);
      dispatch(popupCountActions.decrement(0));
    }
  };

  const saveAnswers = async () => {
    API.saveMentorAnswers({
      groupId,
      levelId: data[index].levelId,
      taskId: data[index].taskId,
      ans: data[index].ans
    });
  };

  const updateFtue = async () => {
    setFtueLoading(true);
    try {
      const response = await API.updateUserFtue({ ftue: false, userId });
      const data = localStorage.getItem('education');
      const workData = localStorage.getItem('work');
      const workHistory = workData ? JSON.parse(workData) : workData;
      localStorage.removeItem('workFlag');
      localStorage.removeItem('edFlag');
      localStorage.removeItem('topics');
      let response2 = 200;
      let workResponse = 200;
      if (data) {
        const formData = JSON.parse(data);
        const response = await API.addEducationAndSkills({ location, userId, formData });
        response2 = response.status;
      }
      if (workHistory) {
        let { company_name, industry, end_date, title } = workHistory;
        if (workHistory.currentlyWorking) {
          end_date = null;
        }
        const formData = {
          ...workHistory,
          company_name: company_name.trim(),
          industry: industry.trim(),
          end_date,
          title: title.trim()
        };
        const response = await API.addWorkHistory({
          userId,
          location,
          formData
        });
        workResponse = response.status;
      }
      if (response.status === 200 && response2 === 200 && workResponse === 200 && response.statusText === 'OK') {
        dispatch(userActions.userFtueUpdate(0));
        setFtueLoading(false);
      }
    } catch (e) {
      toast.error(`Profile details failed to update`);
      setFtueLoading(false);
    }
  };

  const handleNextClick = () => {
    const { levelId, taskId, ans, ansType, answerOptions, maxMentorAns, minMentorAns } = data[index];
    try {
      if (ans.length > 0 && ans[0] !== '') {
        if (['List of industries', 'List of roles'].includes(ansType)) {
          if (ans.length < minMentorAns) {
            throw new Error(`Minimum ${minMentorAns} options required`);
          }
          if (ans.length > maxMentorAns) {
            throw new Error(`Maximum ${maxMentorAns} options allowed`);
          }
        }

        if (index !== data.length - 1) {
          setIndex((prevState) => prevState + 1);
          dispatch(popupCountActions.increment(0));
        }

        saveAnswers();

        if (index === data.length - 1) {
          dispatch(companyActions.update(true));
          updateFtue();
        }
      } else {
        throw new Error('Please answer the question');
      }
    } catch (e: any) {
      toast.error(e.message);
    }
  };

  if (data?.length === 0) {
    return null;
  }
  return (
    <>
      <Box px={2} pt={2} mb={2}>
        <Typography variant="h2">{data[index].question}</Typography>
      </Box>
      <DialogContent>
        <GetMenteeOptions
          type={data[index].ansType}
          ansOptions={data[index].answerOptions}
          answerGiven={data[index].ans}
          maxMenteeAns={data[index].maxMentorAns}
          minMenteeAns={data[index].minMentorAns}
          index={index}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="secondary" fullWidth onClick={handlePreviousClick}>
          Back
        </Button>
        <LoadingButton color="primary" variant="contained" fullWidth onClick={handleNextClick} loading={loading}>
          {index === data.length - 1 ? 'Finish' : 'Next'}
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default OnboardingQuestions;
