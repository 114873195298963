/* eslint-disable react/jsx-key */
/* eslint-disable no-param-reassign */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import {
  Box,
  Stack,
  Divider,
  IconButton,
  Typography,
} from '@mui/material';
import MyChip from './MyChip';
import ROLES from '../../../utils/roles';
import { EditIcon } from '../../../utils/Icons';
import MySkeleton from '../../../components/Skeleton';
import { useAppDispatch } from '../../../redux/hooks';
import { getUserDetails } from '../../../utils/orgName';
import useProfileDetails from '../../../hooks/useProfileDetails';
import { fetchAllTags } from '../../../redux/slices/tags/alltagslistSlice';
import { popupActions } from '../../../redux/slices/onboardingPopupsSlice';

interface Props {
  editing?: boolean;
  divider?: boolean,
  loading?: boolean;
  chipType?: 'fill' | 'fill-blue' | 'outline';
  hobbiesValue: string[];
}

const Hobbies = ({
  hobbiesValue,
  editing = false,
  divider = false,
  loading = false,
  chipType = 'fill',
}: Props) => {
  const { ftue } = useProfileDetails();
  const [data, setData] = useState<string[]>(hobbiesValue);
  const dispatch = useAppDispatch();

  const handleOpen = () => {
    dispatch(popupActions.updatePopups({ hobbies: true }));
  };

  const { location, orgId } = getUserDetails();
  let role = localStorage.getItem('role') || '';

  useEffect(() => {
    if (role !== ROLES.platAdmin && !ftue) {
      dispatch(
        fetchAllTags({
          orgId: orgId,
          location: location
        })
      );
    }
  }, []);

  useEffect(() => {
    setData(hobbiesValue);
  }, [hobbiesValue]);

  const getChipColor = () => {
    if (chipType === 'outline') return '#68717A';
    return undefined;
  };

  const getChipBg = () => {
    if (chipType === 'outline') return '#FFFFFF';
    if (chipType === 'fill-blue') return '#0071A9';
    return undefined;
  };

  const getChipBorder = () => {
    if (chipType === 'outline') return '1px solid #CED4DA';
    if (chipType === 'fill-blue') return '1px solid #0071A9';
    return undefined;
  };

  return role !== ROLES.platAdmin ? (
    <Box pb={divider ? 0 : 2}>
      <Box
        px={divider ? 2 : 0}
        display='flex'
        alignItems='center'
        justifyContent='space-between'
      >
        <Typography variant="h5" py={2}>
          { loading ? (<MySkeleton variant="text" height={16} width={150} />) : 'Hobbies and interests'}
        </Typography>
        {editing &&
          <IconButton size='small' onClick={handleOpen}>
            <EditIcon />
          </IconButton>
        }
      </Box>

      {/* Divider for profile page */}
      {divider && <Divider /> }
      
      <Stack
        p={divider ? 2 : 0}
        direction="row"
        useFlexGap
        flexWrap="wrap"
        spacing={1}
      >
        {loading ? (
          Array.from({ length: 4 }).map((_, index) => (
            <MyChip loading />
          ))
        ) : (
          data && data?.length > 0 ? (
            data?.map((each: any, index: any) => (
              <MyChip
                key={index}
                label={each}
                color={getChipColor()}
                border={getChipBorder()}
                backgroundColor={getChipBg()}
              />
            ))
        ) : (
          <Typography>No hobbies</Typography>
        )
        )}
      </Stack>
    </Box>
  ) : null;
};

export default Hobbies;
