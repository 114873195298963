import { useState } from 'react';
import { API } from '../../../../api';
import { useAuth0 } from '@auth0/auth0-react';
import _ from 'lodash';
import { Box, Button, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { LiveChatStyle } from './LiveChatStyle';
import ChatAvatarComponent from '../chat/ChatAvatarComponent';
import circleicon from '../../../../assets/images/circleicon.svg';
import { setCurrentConversationID } from '../../../../redux/slices/ai-chat/aiChatMessages';
import { getUserDetails } from '../../../../utils/orgName';

const ChatbotTree = ({ tree, changePopupOpen, startNewConversation, promptId }: any) => {
  const [pastResponses, setPastResponses] = useState<any[]>([]);
  const [currentNode, setCurrentNode] = useState(tree);
  const [selectedOptions, setSelectedOptions] = useState<any[]>([]);
  const { user, getIdTokenClaims, getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  const classes = LiveChatStyle();

  const startNewChat = async () => {
    try {
      const idTokenClaims = await getIdTokenClaims();
      const accessToken = await getAccessTokenSilently();
      const { orgId } = getUserDetails();

      const tokens = {
        access_token: accessToken,
        id_token: _.get(idTokenClaims, '__raw', '')
      };
      let data = {
        promptID: promptId,
        selectedOptions: selectedOptions
      };
      const response = await API.conversationFromTree({ tokens, orgId, data });
      dispatch(setCurrentConversationID(response.data.id));
      setCurrentNode([]);
      setPastResponses([]);
    } catch (error) {
      console.error(error);
    }
  };

  const handleResponseClick = (nextNode: string | number, qText: string, selectedButton: any, option_id: number) => {
    setPastResponses([...pastResponses, { questionText: qText, questionOptions: selectedButton }]);
    setCurrentNode(nextNode);
    selectedOptions.push(option_id);
  };

  const handleLeafResponseClick = (nextNode: string | number, qText: string, selectedButton: any, option_id: any) => {
    setPastResponses([...pastResponses, { questionText: qText, questionOptions: selectedButton }]);
    setCurrentNode(nextNode);
    selectedOptions.push(option_id);

    // Start a new chat when reaching the end of the tree
    startNewChat();
  };

  const renderResponses = (node: any) => {
    return (
      <Box className="button__container">
        {node?.options?.map((option: any, index: number) => (
          <Button
            variant="outlined"
            color="secondary"
            key={index}
            sx={{
              //marginTop: '3px',
              marginBottom: '10px',
              minWidth: '300px',
              justifyContent: 'flex-start'
            }}
            onClick={() => {
              option.next_question
                ? handleResponseClick(option.next_question, currentNode.message, option.option_text, option.option_id)
                : handleLeafResponseClick(option, currentNode.message, option.option_text, option.option_id);
            }}
          >
            {option.option_text}
          </Button>
        ))}
      </Box>
    );
  };

  const renderHistory = (list: any) => {
    return pastResponses.map((item, index) => (
      <Box key={index}>
        {/* <Typography>{item.questionText}</Typography> */}
        <Box className={classes.msgBox}>
          <Box>
            <ChatAvatarComponent
              variant="circle"
              firstLetter={'Dosen'}
              image={circleicon}
              height="34px"
              width="34px"
              border="1px solid #000000"
            />
          </Box>
          <Box>
            <Box display="flex" gap="8px">
              <Typography variant="h5">Dosen</Typography>
            </Box>
            <Box>
              <Typography>{item.questionText}</Typography>
            </Box>
          </Box>
        </Box>
        <Box className="button__container">
          <Button
            variant="outlined"
            disabled={true}
            color="primary"
            sx={{
              //marginTop: '3px',
              marginBottom: '10px',
              backgroundColor: '#6C757D',
              color: 'white !important',
              minWidth: '300px',
              justifyContent: 'flex-start'
            }}
          >
            {item.questionOptions}
          </Button>
        </Box>
      </Box>
    ));
  };

  return (
    <Box>
      {pastResponses.length > 0 ? renderHistory(pastResponses) : null}
      {/* <Typography>{currentNode.message}</Typography> */}

      {currentNode.message && (
        <Box className={classes.msgBox}>
          <Box>
            <ChatAvatarComponent
              variant="circle"
              firstLetter={'Dosen'}
              image={circleicon}
              height="34px"
              width="34px"
              border="1px solid #000000"
            />
          </Box>
          <Box>
            <Box display="flex" gap="8px">
              <Typography variant="h5">Dosen</Typography>
            </Box>
            <Box>
              <Typography>{currentNode.message}</Typography>
            </Box>
          </Box>
        </Box>
      )}
      {currentNode && currentNode.options ? renderResponses(currentNode) : null}
    </Box>
  );
};

export default ChatbotTree;
