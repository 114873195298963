/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/jsx-key */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useEffect
} from 'react';
import {
  Box,
  Grid,
  Button,
  Divider,
  IconButton,
  Typography,
  DialogActions
} from '@mui/material';
import _ from 'lodash';
import {
  bioFormatter,
  meetingColor,
  timeZoneWiseTime,
  formattedTimeSlot,
  timeZoneAbbreviation,
  createZoomMeetingUrl
} from '../../components/common';
import { API } from '../../../../api';
import { toast } from 'react-toastify';
import CalendarIcon from './CalendarIcon';
import CalendarLoader from './CalendarLoader';
import '../../../../assets/style/pagination.css';
import PostCallPopup from '../chat/PostCallPopup';
import CallBookingModal from '../CallBookingModal';
import { callActionFlag } from './CalendarFunctions';
import RejectionCallModal from './RejectionCallModal';
import UserinfoCard from '../../components/UserinfoCard';
import { PlusCircleIcon } from '../../../../utils/Icons';
import MySkeleton from '../../../../components/Skeleton';
import MyPagination from '../../components/MyPagination';
import CancelationCallModal from './CancelationCallModal';
import { getUserDetails } from '../../../../utils/orgName';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { BooleanLiteralTypeAnnotation } from '@babel/types';
import SeeBookingDetailsModal from './SeeBookingDetailsModal';
import useCallDetails from '../../../../queries/useCallDetails';
import VideoIcon from '../../../../assets/images/videoCall.svg';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import useProfileDetails from '../../../../hooks/useProfileDetails';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { fetchCallsCount } from '../../../../redux/slices/callCountSlice';
import useResponsiveScreenSwitch from '../../../../utils/useResponsiveScreenSwitch';
import { fetchCommunityMembers } from '../../../../redux/slices/acs-community-members/acsCommunityMembers';

export interface CallRowData {
  meetingId: string;
  _id: string;
  callDate: string;
  callTime: string;
  callTitle: string;
  callTimeInterval: number;
  participantsUser: {
    mid: string;
    callRejectStatus: boolean;
    isCallJoined: boolean; // Add the isCallJoined property
    voteFlag: boolean; // Add the voteFlag property
  }[];
  chatType?: string;
  callBookedWith?: {
    id: string;
    displayName: string;
    headshot: string;
  };
  callBookedBy?: {
    id: string;
    displayName: string;
    headshot: string;
  };
  description: string;
  callStatus: string;
  meetingType: string;
}

const Calendar = () => {
  const dispatch = useAppDispatch();
  const userData = useAppSelector((state) => state.userProfile.data);
  const timezone = userData?.personal_details?.timezone || '';
  const { userId } = getUserDetails();
  const userProfileDetails = useProfileDetails();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const userCallsData = useCallDetails(page, pageSize);
  const allTotalObjects = userCallsData.allTotelCount;
  const { forMobile } = useResponsiveScreenSwitch();
  const [isCallEditing, setIsCallEditing] = useState(false);

  useEffect(() => {
    if (page !== 0) {
      userCallsData.refetch();
    }
  }, [page]);

  const totalPages = Math.ceil(allTotalObjects / pageSize);

  const handlePageChange = (newPage: any) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setPage(newPage);
    }
  };

  const callRows = userCallsData?.data;
  const [callMeetingId, setMetingId] = useState();
  const [cancelCallMeetingId, setcancelCallMeetingId] = useState();
  const [rejectCallMeetingId, setrejectCallMeetingId] = useState();
  const [openPostCallPopup, setOpenPostCallPopup] = useState(true);
  const loading = userCallsData.isLoading; // Loading

  /** Open Rejection Reason modal */
  const [openRejectionCall, setRejectionCallOpen] = useState(false);
  const handleRejectionCall = (meetingId: any, type: string) => {
    setMetingId(meetingId);
    setRejectionCallOpen(true);
    if (type === 'modal') {
      setopenCallbookingDetailsModal(false);
    }
  };

  const handleCallCancellation = () => {
    setcancelCallMeetingId(callMeetingId);
  };

  const closeRejectionModal = () => {
    userCallsData.refetch();
    setRejectionCallOpen(false);
  };

  const handleClosePostCallPopup = () => {
    userCallsData.refetch();
    setOpenPostCallPopup(false);
  };

  /** Open Call Cancelation modal */
  const [openCancelationCall, setCancelationCallOpen] = useState(false);
  const handleCancelationCall = (meetingId: any, type: string) => {
    setMetingId(meetingId);
    setCancelationCallOpen(true);
    if (type === 'modal') {
      setopenCallbookingDetailsModal(false);
    }
  };
  const handleCallRejection = () => {
    setrejectCallMeetingId(callMeetingId);
  };
  const closeCancelModal = () => {
    userCallsData.refetch();
    setCancelationCallOpen(false);
  };

  /** See Booking Details Modal */
  const acsChannelParticipants = useAppSelector((state) => state.acsChannelParticipants.data);
  // const allReviewMeetingId: any[] = [];
  const [openCallbookingDetailsModal, setopenCallbookingDetailsModal] = useState(false);
  const [isCalendarOpen, setCalendarOpen] = useState(false);
  const [currentMeetingIndex, setCurrentMeetingIndex] = useState(0);
  const [allattendeeDetails, setallAttendeeDetails] = useState([]);
  const [bookedCallDetails, setBookedCallDetails] = useState<CallRowData[]>([]);
  const handleSeeBookingDetails = (attendeeDetails: any, callId: any) => {
    const filteredData: CallRowData[] = callRows?.filter((item: { _id: string }) => item._id === callId);
    const filteredMidValues: any[] = filteredData
      ?.map((item: CallRowData) => {
        if (item.participantsUser.some((participant) => participant.mid === userId)) {
          if (item?.chatType === 'Chats') {
            return [item?.callBookedBy?.id, item?.callBookedWith?.id];
          } else {
            return [item?.callBookedBy?.id];
          }
        } else {
          return [item?.callBookedBy?.id];
        }
      })
      .flat();
    const filteredattendeeDetails = attendeeDetails.filter(
      (item: { mid: any }) => !filteredMidValues.includes(item.mid)
    );
    setBookedCallDetails(filteredData);
    setallAttendeeDetails(filteredattendeeDetails);
    setopenCallbookingDetailsModal(true);
  };

  const closeSeeBookingDetailsModal = () => {
    userCallsData.refetch();
    setopenCallbookingDetailsModal(false);
  };

  const getChannels = () => {
    dispatch(
      fetchCommunityMembers({
        orgId: getUserDetails().orgId,
        location: getUserDetails().location
      })
    );
  };

  useEffect(() => {
    dispatch(fetchCallsCount(getUserDetails().location));
    getChannels();
  }, []);
  useEffect(() => {
    userCallsData.refetch();
  }, []);

  const attendeesImage = (participantsUserRow: { headshot: string }[], callId: any) => {
    const imageSources = participantsUserRow.slice(0, 3).map((attendee) => attendee?.headshot);
    return (
      <Box display="flex" gap="8px" alignItems="center">
        {participantsUserRow.length > 2 && (
          <>
            <Box className="CallsAttendeesImages">
              {imageSources.map((src, index) => (
                <img
                  key={index}
                  src={src}
                  alt={`Attendee ${index + 1}`}
                  className={`Image${index + 1} cursor-pointer`}
                  onClick={() => handleSeeBookingDetails(participantsUserRow, callId)}
                />
              ))}
            </Box>
            <Typography
              variant="h6"
              className="cursor-pointer"
              color="#0071A9"
              onClick={() => handleSeeBookingDetails(participantsUserRow, callId)}
              noWrap
            >
              See all Attendees
            </Typography>
          </>
        )}
      </Box>
    );
  };

  const rejectCancelCallButton = (callsData: any, type: string) => {
    const isUserInParticipants = callsData.participantsUser?.some(
      (participant: { mid: string }) => participant.mid === userId
    );

    if (!isUserInParticipants) {
      return null; // Do not render anything if the member is not in participantsUser
    }

    let flag = 0;
    if (callsData?.callBookedBy?.id === userId) {
      flag = 1;
    }
    const meetingId = callsData?.meetingId;

    return (
      <>
        {flag === 1 && meetingId !== cancelCallMeetingId && (
          <Button
            variant="outlined"
            className={type === 'page' ? 'CallsPageBtn' : ''}
            color={type === 'page' ? 'error' : 'secondary'}
            fullWidth={type !== 'page'}
            onClick={() => handleCancelationCall(meetingId, type)}
          >
            Cancel
          </Button>
        )}

        {flag !== 1 && meetingId !== rejectCallMeetingId && (
          <Button
            variant="outlined"
            className={type === 'page' ? 'CallsPageBtn' : ''}
            color={type === 'page' ? 'error' : 'secondary'}
            fullWidth={type !== 'page'}
            onClick={() => handleRejectionCall(meetingId, type)}
          >
            Reject
          </Button>
        )}
      </>
    );
  };

  const joinCallButton = (callsData: any, type: string) => {
    const meetingId = callsData?.meetingId;
    const isUserInParticipants = isUserInParticipantsArray(callsData);
    if (meetingId !== cancelCallMeetingId && meetingId !== rejectCallMeetingId && isUserInParticipants) {
      return (
        <Button
          className={type === 'page' ? 'CallsPageBtn' : ''}
          variant="contained"
          color="primary"
          fullWidth={type !== 'page'}
          key={callsData?._id}
          onClick={() =>
            handleGoToCallIconClick(
              callsData?.callTimeInterval,
              callsData?.callDate,
              callsData?.callTime,
              callsData?.meetingId,
              isRejoin(callsData),
              callsData?.callBookedBy,
              callsData?.meetingType,
              callsData?.meetingLink
            )
          }
        >
          {isRejoin(callsData) ? 'Rejoin' : 'Join'}
        </Button>
      );
    } else {
      // Return null or an empty fragment if the member is not in the participants array
      return null;
    }
  };

  const isUserInParticipantsArray = (callsData: any) => {
    const userId = getUserDetails().userId;
    return callsData.participantsUser?.some((user: { mid: string }) => user.mid === userId);
  };

  const isRejoin = (callsData: any) => {
    const data = callsData.participantsUser?.find((user: { mid: string }) => user.mid === getUserDetails().userId);
    return data ? data.isCallJoined : false;
    // true => go to call
    // false => rejoin
  };

  const handleGoToCallIconClick = (
    callTimeInterval: number,
    callDate: string,
    callTime: string,
    meetingId: string,
    isRejoin: BooleanLiteralTypeAnnotation,
    callBookedBy: any,
    meetingType: string,
    meetingLink: string
  ) => {
    const baseUrl = window.location.origin;
    // call start time
    const callStartTimestamp = new Date(callDate + 'T' + callTime + 'Z').getTime();
    const callStartUnixTime = Math.floor(callStartTimestamp / 1000);
    const currentTimestamp = new Date().getTime();
    const currentcallStartUnixTime = Math.floor(currentTimestamp / 1000);
    const currentUnixTime = Math.floor(currentTimestamp / 1000);
    const callEndUnixTime = currentUnixTime + callTimeInterval * 60;

    if (currentUnixTime + 300 >= callStartUnixTime) {
      // if current time is greater than call time
      if (currentUnixTime > callEndUnixTime) {
        toast.error('This meeting has already taken place');
      } else {
        if (!isRejoin) {
          isJoinCall(meetingId);
        }
        if (meetingType === 'Dosen') {
          const url = createZoomMeetingUrl(meetingId, callDate, callTime, callTimeInterval, callBookedBy);
          const newTab = window.open(url, '_blank');
          newTab?.focus();
        } else {
          const newTab = window.open(meetingLink, '_blank');
          newTab?.focus();
        }
      }
    } else {
      toast.warning('You can join the meeting from 5 minutes before the meeting time');
    }
  };

  const isJoinCall = async (meetingId: string) => {
    const { orgId } = getUserDetails();
    try {
      const response = await API.rejoinCallUpdateFlag(
        getUserDetails().userId,
        meetingId,
        orgId,
        getUserDetails().location
      );
    } catch (e) { }
  };

  const ModalDialogActions: React.FC<{ callrow: CallRowData[] }> = ({ callrow }) => {
    if (callrow[0].meetingType === 'InPerson') {
      return null;
    }
    return (
      <DialogActions>
        {callActionFlag(callrow[0], getUserDetails()?.userId) === 1 ? (
          rejectCancelCallButton(callrow[0], 'modal')
        ) : (
          <span />
        )}
        {callActionFlag(callrow[0], getUserDetails()?.userId) === 1 ? joinCallButton(callrow[0], 'modal') : <span />}
      </DialogActions>
    );
  };

  const userCallsDataRefetch = () => {
    userCallsData.refetch();
  };

  const callsRowDynamicDiv = (callRowData: any) => {
    const isGroupCall = callRowData?.chatType === 'Groups';

    const callBookedWith = isGroupCall ? callRowData?.callBookedWith : callRowData?.callBookedBy;
    const userWithId =
      getUserDetails().userId === callBookedWith?.id ? callRowData?.callBookedWith?.id : callBookedWith?.id;
    const matchingParticipant = callRowData?.participantsUser?.find((user: { mid: any }) => user.mid === userWithId);

    const isStudent = matchingParticipant?.category === 'Student';
    const bioData = isStudent
      ? bioFormatter(
        matchingParticipant?.bio?.education?.major,
        matchingParticipant?.bio?.education?.university,
        callRowData?.callTitle
      )
      : bioFormatter(
        matchingParticipant?.bio?.workHistory?.role,
        matchingParticipant?.bio?.workHistory?.companyName,
        callRowData?.callTitle
      );

    const UserinfoCardDetails = {
      headshot: isGroupCall ? callBookedWith?.headshot : matchingParticipant?.headshot,
      displayName: isGroupCall ? callBookedWith?.name : matchingParticipant?.displayName,
      UserType: isGroupCall ? '' : matchingParticipant?.category,
      bio: isGroupCall ? callRowData?.callTitle : bioData
    };

    return <UserinfoCard UserinfoCardDetails={UserinfoCardDetails} />;
  };

  let filteredCallRows: CallRowData[] = callRows.filter((callRow: CallRowData) => {
    const matchingParticipant = callRow.participantsUser.find((user) => user.mid === userId);
    return (
      (matchingParticipant?.isCallJoined === false || matchingParticipant?.isCallJoined === undefined) &&
      matchingParticipant?.voteFlag === false
    );
  });

  const openNextPopup = () => {
    if (currentMeetingIndex < filteredCallRows.length - 1) {
      setCurrentMeetingIndex(currentMeetingIndex + 1);
    } else {
      // when all the modals are done
      setOpenPostCallPopup(false);
      setCurrentMeetingIndex(0);
      filteredCallRows = [];
      userCallsData.refetch();
    }
  };

  useEffect(() => { }, [filteredCallRows]);

  const handleCalendar = (flag: boolean) => setCalendarOpen(flag);

  const callWithTitle = (callRow: any) => {
    let reviewModalTitle = '';
    if (callRow?.chatType === 'Chats') {
      // p2p
      reviewModalTitle =
        userId === callRow?.callBookedWith?.id
          ? callRow?.callBookedBy?.displayName
          : callRow?.callBookedWith?.displayName;
    } else {
      // group
      reviewModalTitle = callRow?.callBookedWith?.name; // group name
    }
    return reviewModalTitle;
  };

  const renderNoMeetingsMessage = () => (
    <Box p={2}>
      <Typography textAlign="center">No meetings in your calendar yet</Typography>
    </Box>
  );

  return (
    <Box className="bodyBox" p={{ xs: 0, sm: 2 }}>
      <Box className="CardBox">
        <Box px={2} display="flex" gap={1} alignItems={'center'}>
          <Typography variant="h5" py={2}>
            {loading ? <MySkeleton variant="text" height={20} width={150} /> : 'Meetings / Events'}
          </Typography>
          {!loading && (
            <IconButton size="small" id="addScheduleNewMeetingIcon" onClick={() => handleCalendar(true)}>
              <PlusCircleIcon size={30} />
            </IconButton>
          )}
        </Box>
        <Divider />
        <Box className="CallsPageTopOptions">
          {meetingColor().map((meeting, index) => (
            <Box key={index} gap="8px" display={'flex'} alignItems="center">
              {loading ? (
                <>
                  <MySkeleton variant="circular" height={forMobile ? 15 : 20} width={20} />
                  <MySkeleton variant="text" height={16} width={150} />
                </>
              ) : (
                <>
                  <FiberManualRecordIcon sx={{ color: meeting.color }} />
                  <Typography>{meeting.title}</Typography>
                </>
              )}
            </Box>
          ))}
        </Box>

        <Box className="CallsTable">
          {loading
            ? Array.from({ length: 5 }).map((_, index) => <CalendarLoader key={index} />)
            : userCallsData.error || _.size(userCallsData.data) === 0
              ? renderNoMeetingsMessage()
              : userCallsData.data.map(
                (item: {
                  [x: string]: any;
                  _id: React.Key | null | undefined;
                  callStatus: string;
                  callDate: string;
                  callTime: string;
                  callTimeInterval: number;
                  meetingType: string;
                  participantsUser: {
                    headshot: string;
                    callRejectStatus: boolean;
                    mid: string;
                    isCallJoined: boolean;
                  }[];
                }) => (
                  <Box className="CallsTableBox" key={item?._id}>
                    <Grid container spacing={{ xs: 2, md: 2 }} className="CallsTableRow">
                      {/* Calendar icon */}
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={1}
                        className="cursor-pointer"
                        onClick={() => handleSeeBookingDetails(item?.participantsUser, item?._id)}
                      >
                        {item?.callDate && typeof item?.callDate === 'string' ? (
                          <CalendarIcon callData={item} authUserId={userId} timezone={timezone} />
                        ) : null}
                      </Grid>

                      {/* Metting title and date time */}
                      <Grid
                        item
                        xs={12}
                        md={4}
                        className="cursor-pointer"
                        onClick={() => handleSeeBookingDetails(item?.participantsUser, item?._id)}
                      >
                        <Box display={'flex'} flexDirection={'column'} gap={1}>
                          <Typography variant='h5' noWrap>{item?.callTitle}</Typography>

                          <Box display={'flex'} gap={.5} flexWrap={'nowrap'}>
                            {item?.callDate && item?.callTime && timezone && (
                              <Box display="flex" gap="8px" alignItems="center">
                                <AccessTimeIcon sx={{ color: '#ABB5BE', height: '20px', width: '20px' }} />
                                <Typography variant="body1" noWrap>
                                  {timeZoneWiseTime(item.callDate.toString(), item.callTime.toString(), timezone).toLowerCase()} (
                                  {timeZoneAbbreviation(timezone)})
                                </Typography>
                              </Box>

                            )}
                            {(item?.callDate && item?.callTime && timezone && typeof item?.callTimeInterval === 'number') && (
                              <Typography>|</Typography>
                            )}
                            {typeof item?.callTimeInterval === 'number' && (
                              <Typography variant="body1" noWrap flex={1}>
                                {formattedTimeSlot(item.callTimeInterval).toLowerCase()}
                              </Typography>
                            )}
                          </Box>
                        </Box>
                      </Grid>

                      {/* Member name and Attendees */}
                      {Array.isArray(item?.participantsUser) && (
                        <Grid
                          item
                          xs={12}
                          md={4}
                          className="cursor-pointer"
                          onClick={() => handleSeeBookingDetails(item.participantsUser, item._id)}
                        >
                          {item.participantsUser.length > 2 ? (
                            <Typography noWrap variant="h6" color="#0071A9">
                              See all Attendees
                            </Typography>
                          ) : (
                            callsRowDynamicDiv(item)
                          )}
                        </Grid>
                      )}

                      {/* Add to calendar and edit btn */}
                      {/* <Grid item xs={12} md={2}>
                        <Box display={'flex'} justifyContent={'space-between'} flexDirection={'row'} alignItems={'center'}>
                          <Typography
                            // noWrap
                            variant="h6"
                            color="#0071A9"
                            className="cursor-pointer"
                          >
                            + Add to calendar
                          </Typography>
                          <IconButton>
                            <CreateOutlinedIcon />
                          </IconButton>
                        </Box>
                      </Grid> */}

                      {/* Time */}
                      {/* <Grid
                        item
                        xs={12}
                        sm={6}
                        md={1.75}
                        className="cursor-pointer"
                        onClick={() => handleSeeBookingDetails(item?.participantsUser, item?._id)}
                      >
                        <Box display="flex" gap="8px" alignItems="center">
                          <AccessTimeIcon sx={{ color: '#ABB5BE' }} />
                          {item?.callDate && item?.callTime && timezone ? (
                            <Typography variant="h6" noWrap>
                              {timeZoneWiseTime(item.callDate.toString(), item.callTime.toString(), timezone)} (
                              {timeZoneAbbreviation(timezone)})
                            </Typography>
                          ) : null}
                        </Box>
                      </Grid> */}
                      {/* Call duration */}
                      {/* <Grid
                        item
                        xs={12}
                        sm={6}
                        md={1.25}
                        className="cursor-pointer"
                        onClick={() => handleSeeBookingDetails(item?.participantsUser, item?._id)}
                      >
                        <Box display="flex" gap="8px" alignItems="center">
                          <img alt="" src={VideoIcon} />
                          {typeof item?.callTimeInterval === 'number' ? (
                            <Typography variant="h6" noWrap>
                              {formattedTimeSlot(item.callTimeInterval)}
                            </Typography>
                          ) : null}
                        </Box>
                      </Grid> */}
                      {/* Attendees */}
                      {/*  <Grid item xs={12} sm={12} md={2}>
                        {item?.participantsUser &&
                          Array.isArray(item.participantsUser) &&
                          attendeesImage(item?.participantsUser, item?._id)}
                      </Grid> */}

                      {/* Btn */}
                      {/* hide buttons for in-person meetings */}
                      {item.meetingType === 'InPerson' ? null : (
                        <Grid item xs={12} sm={12} md={3}>
                          <Box display="flex" gap="8px" alignItems="center" className="CallsTableBtnCell">
                            {callActionFlag(item, getUserDetails()?.userId) === 1 ? (
                              rejectCancelCallButton(item, 'page')
                            ) : (
                              <span />
                            )}
                            {callActionFlag(item, getUserDetails()?.userId) === 1 ? (
                              joinCallButton(item, 'page')
                            ) : (
                              <span />
                            )}
                          </Box>
                        </Grid>
                      )}
                    </Grid>
                  </Box>
                )
              )}

          {/* Pagination */}
          {/* {allTotalObjects > 10 && (
            <div id="wrapper" className="my-5">
              <ul id="border-pagination">
                <li className="mx-2">
                  <a className="mx-1 dots">{`${(page - 1) * pageSize + 1} - ${Math.min(
                    page * pageSize,
                    allTotalObjects
                  )} of ${allTotalObjects} items`}</a>
                </li>
                <li className="mx-2">
                  <a className="mx-1" onClick={() => handlePageChange(page - 1)}>
                    «
                  </a>
                </li>
                {Array.from({ length: totalPages }, (_, index) => (
                  <li key={index} className={`mx-2 ${index + 1 === page ? 'active' : ''}`}>
                    <a
                      className={`mx-2 ${index + 1 === page ? 'active' : ''}`}
                      onClick={() => handlePageChange(index + 1)}
                    >
                      {index + 1}
                    </a>
                  </li>
                ))}
                <li className="mx-2">
                  <a className="mx-1" onClick={() => handlePageChange(page + 1)}>
                    »
                  </a>
                </li>
              </ul>
            </div>
          )} */}

          {allTotalObjects > 10 && (
            <Box display={'flex'} justifyContent={'center'} className="my-20">
              <MyPagination
                counts={[{ count: totalPages }]}
                currentPage={page}
                onPageChange={handlePageChange}
                allTotalObjects={allTotalObjects}
                pageSize={pageSize}
              />
            </Box>
          )}
        </Box>

        {/* Rejection Reason modal */}
        {openRejectionCall && (
          <RejectionCallModal
            openRejectionCall={openRejectionCall}
            closeRejectionModal={closeRejectionModal}
            callMeetingId={callMeetingId}
            onRejectSuccess={handleCallRejection}
          />
        )}

        {/* Cancelation Reason modal */}
        {openCancelationCall && (
          <CancelationCallModal
            openCancelationCall={openCancelationCall}
            closeCancelModal={closeCancelModal}
            callMeetingId={callMeetingId}
            onCancelSuccess={handleCallCancellation}
          />
        )}

        {/* See Booking Details Modal */}
        {openCallbookingDetailsModal && (
          <SeeBookingDetailsModal
            openCallbookingDetailsModal={openCallbookingDetailsModal}
            closeSeeBookingDetailsModal={closeSeeBookingDetailsModal}
            bookedCallDetails={bookedCallDetails[0]}
            allattendeeDetails={allattendeeDetails}
            timezone={timezone}
            ModalDialogActions={<ModalDialogActions callrow={bookedCallDetails} />}
            userCallsDataRefetch={userCallsDataRefetch}
            isCallRejectedByAuthUser={true}
            setIsCallEditing={setIsCallEditing}
            isCallEditing={isCallEditing}
          />
        )}
        {/* {userProfileDetails?.mentee?.length > 0 && filteredCallRows.length > 0 && (
          <PostCallPopup
            key={currentMeetingIndex}
            reviewParticipant={filteredCallRows[currentMeetingIndex].participantsUser}
            openPostCallPopup={openPostCallPopup}
            handleClosePostCallPopup={openNextPopup} // Move to the next meeting after closing the popup
            meetingId={filteredCallRows[currentMeetingIndex].meetingId}
            title={`Please review your meeting with\n${callWithTitle(filteredCallRows[currentMeetingIndex])}`}
          />
        )} */}

        {/* Call Booking Modal */}
        <CallBookingModal
          handleCloseModal={() => setCalendarOpen(false)}
          openFlag={isCalendarOpen}
          chatProfileName={''}
          threadParticipantsUsers={[]}
          chatType="Chats"
          pageName="Calendar"
          bookedCallDetails={''}
        />
      </Box>
    </Box>
  );
};

export default Calendar;
