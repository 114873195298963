import React from 'react';
import _ from 'lodash';
import { Box, Typography } from '@mui/material';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import peopleSearch from '../../../../assets/images/people_search.svg';
import starEmphasis from '../../../../assets/images/star_emphasis.svg';
import chatMultiple from '../../../../assets/images/chat_multiple.svg';

type Props = {};

const ChatHome = (props: Props) => {
  return (
    <>
      <Box textAlign="center">
        <Box 
          display={'flex'} 
          flexDirection={'column'} 
          alignItems={'center'}
        >
          <Box 
            width='104px'
            height='104px'
            display={'flex'}
            borderRadius='50%'
            boxShadow={'0px 8px 20px rgba(0, 0, 0, 0.16)'}
          >
            <AutoFixHighIcon
              sx={{ 
                width: '52px', 
                height: '52px',
                margin: 'auto',
                color: '#DF6438'
              }}
            />
          </Box>
          <Typography variant="h5" color="#49525A" my={2}>
            How can I help?
          </Typography>
        </Box>
        <Typography color="#49525A" textAlign={'center'}>
          I’m Dosen, your AI-powered personal mentor. I’m here to help you make the most out of your experience by
          offering personalized advice and guidance. Let’s start this journey together!
        </Typography>
      </Box>
      <Box my={4} mx="auto" width="80%">
        <Box display="flex" gap="12px" mb={2}>
          <img src={peopleSearch} width="24" height="24" />
          <Typography variant="h5">
            We can have natural language conversations - like you do with anyone else.
          </Typography>
        </Box>
        <Box display="flex" gap="12px" mb={2}>
          <img src={starEmphasis} width="24" height="24" />
          <Typography variant="h5">Our chats are safe and secure - only between us.</Typography>
        </Box>
        <Box display="flex" gap="12px">
          <img src={chatMultiple} width="24" height="24" />
          <Typography variant="h5">
            I’m best suited to help you with topics related to the program you’re in.
          </Typography>
        </Box>
      </Box>
      <Typography color="#49525A" textAlign={'center'} mb={2}>
        Dosen is powered by generative AI technology and all responses may not be suitable for your situation. We
        encourage you to check in with your trusted mentors and coaches before taking any action.
      </Typography>
    </>
  );
};

export default ChatHome;
