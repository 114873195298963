import React from 'react';
import {
  Dialog,
  Button,
  Typography,
  DialogActions,
  DialogContent,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import CommonModalHeader from '../../../../../components/DialogUi/CommonModalHeader';

interface Props {
  openDelete: boolean;
  setDelete: any;
  deleteLoader: boolean;
  handleDelete: any;
}

const ArticleDeletDialog = ({ openDelete, setDelete, deleteLoader, handleDelete }: Props) => {
  return (
    <Dialog
      open={openDelete}
      onClose={() => setDelete(false)}
      fullWidth
    >
      <CommonModalHeader 
        title={`Confirmation`}
        handleCloseModal={() => setDelete(false)}
      />
      <DialogContent>
        <Typography variant='h5' textAlign={'center'}>
          Are you sure you want to delete this <br/> Article?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          fullWidth
          variant='outlined'
          color='secondary'
          onClick={() => setDelete(false)}
        >
          Cancel
        </Button>

        <LoadingButton
          fullWidth
          color="primary"
          variant='contained'
          loading={deleteLoader}
          onClick={handleDelete}
        >
          Remove
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ArticleDeletDialog;
