import React, { ReactNode } from 'react';
import { Box, Divider, IconButton, DialogTitle } from '@mui/material';
import { Close } from '@mui/icons-material';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';

interface CommonModalHeaderProps {
  handleCloseModal?: () => void;
  title?: string | ReactNode;
  onClickBack?: () => void;
  backBtn?: boolean;
}

const CommonModalHeader: React.FC<CommonModalHeaderProps> = ({
  title,
  backBtn,
  onClickBack,
  handleCloseModal,
}) => {
  return (
    <>
      <Box display="flex" alignItems="center" justifyContent={'space-between'}>
        <Box width={'40px'}>
          {backBtn && (
            <IconButton className="DialogClose" onClick={onClickBack}>
              <ArrowBackIosNewOutlinedIcon />
            </IconButton>
          )}
        </Box>

        <DialogTitle width={'calc(100% - 80px)'}>
          {title}
        </DialogTitle>

        <Box width={'40px'}>
          {handleCloseModal && (
            <IconButton onClick={handleCloseModal} className="DialogClose">
              <Close />
            </IconButton>
          )}
        </Box>
      </Box>

      {title ? <Divider /> : null}
    </>
  );
};

export default CommonModalHeader;
