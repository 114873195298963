import React from 'react';
import {Box, Pagination} from '@mui/material';
import { MyPaginationStyles } from '../../../utils/muiStyle';
import useResponsiveScreenSwitch from '../../../utils/useResponsiveScreenSwitch';

interface PaginationCount {
  count: number;
}

interface MyPaginationProps {
  counts: PaginationCount[];
  currentPage: number;
  onPageChange: (page: number) => void;
  allTotalObjects: number;
  pageSize: number;
}

export default function MyPagination({ 
    counts, 
    currentPage, 
    onPageChange, 
    allTotalObjects, 
    pageSize 
}: MyPaginationProps ) {
    const classes = MyPaginationStyles();
    const { forMobile } = useResponsiveScreenSwitch();

    // Calculate the range of items being displayed
    const startItem = (currentPage - 1) * pageSize + 1;
    const endItem = Math.min(currentPage * pageSize, allTotalObjects);
  

    return (
        <Box 
            gap={2} 
            display={'flex'} 
            alignItems={'center'}
            justifyContent={'center'}
            className={classes.activeColor}
            flexDirection={forMobile ? 'column' : 'row'} 
        >
        <Box>{`${startItem} - ${endItem} of ${allTotalObjects} items`}</Box>
        {counts.map((count, index) => (
            <Pagination
            key={index}
            count={count.count}
            variant="outlined"
            shape="rounded"
            page={currentPage}
            onChange={(event, page) => onPageChange(page)}
            />
        ))}
        </Box>
    );
}
