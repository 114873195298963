import React, { useEffect, useState } from "react";
import { Box, Grid, } from "@mui/material";
import OrganizationAdmins from "./OrganizationAdmins";
import OrganizationInformation from "./OrganizationInformation";
import { useAppSelector } from "../../../../redux/hooks";
import AppLoader from "../../../../components/AppLoader";
import { useLoader } from "../../components/useLoader";

const OrganizationSettings = () => {
  const orgDetails = useAppSelector((state) => state.orgDetails);

  if (orgDetails.error) {
    return <Box>{orgDetails.errorText}</Box>;
  }

  if (orgDetails.data) {
    return (
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <OrganizationInformation data={orgDetails.data} />
          </Grid>
          {/* <Grid item xs={12} lg={4}>
            <OrganizationAdmins />
          </Grid> */}
        </Grid>
      </Box>
    );
  }

  return null;
};

export default OrganizationSettings;
