import _ from 'lodash';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from '../../../api';

interface IAcsOrgUser {
  [x: string]: any;
  id: {
    [x: string]: string | undefined;
    communicationId: string;
  };
  displayName: string;
  userId: string;
}

interface IInitialState {
  loading: boolean;
  error: boolean;
  errorText: string;
  data: IAcsOrgUser[];
}

const initialState: IInitialState = {
  loading: false,
  error: false,
  errorText: '',
  data: []
};

export const modifyCommunity = (response: any) => {
  const filteredUserProfile = response.data.memberResponse.map((each: any) => ({
    id: {
      coId: each.id,
      communicationUserId: _.get(each, 'communicationId', ''),
      communicationId: _.get(each, 'communicationId', ''),
      category: each.category,
      headshot: _.get(each, 'headshot', '')
    },
    displayName: _.get(each, 'displayName', ''),
    userId: _.get(each, 'mid'),
    bio: _.get(each, 'bio', ''),
    digital_intro: _.get(each, 'digital_intro', ''),
    isPlatformAdmin: _.get(each, 'isPlatformAdmin', false),
    groups: _.get(each, 'groups', []),
    oid: _.get(each, 'oid', []),
    roleType: _.get(each, 'roleType', [])
  }));
  return filteredUserProfile;
};

export const fetchCommunityMembers = createAsyncThunk(
  'acs/getCommunityMember',
  async ({ orgId, location }: { orgId: string; location: string }) => {
    const response = await API.getCommunityGroupsUsersListNew(orgId, location);

    if (response.status === 200) {
      if (!_.isEmpty(response)) {
        return modifyCommunity(response);
      }
    } else {
      return [];
    }
  }
);

export const acsCommunitySlice = createSlice({
  name: 'getToken',
  initialState,
  reducers: {
    update: (state, action) => {
      state.data = [];
    },
    updateData: (state, action) => {
      state.data = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCommunityMembers.pending, (state, action) => {
        state.loading = true;
        state.error = false;
        state.errorText = '';
      })
      .addCase(fetchCommunityMembers.fulfilled, (state, action: { type: string; payload: any[] }) => {
        state.loading = false;
        state.data = action.payload;
        state.error = false;
        state.errorText = '';
      })
      .addCase(fetchCommunityMembers.rejected, (state, action) => {
        state.loading = false;
        state.data = [];
        state.error = true;
        state.errorText = action.error.message || '';
      });
  }
});

export default acsCommunitySlice.reducer;
export const communityUsersActions = acsCommunitySlice.actions;
