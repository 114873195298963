/* eslint-disable prettier/prettier */
/* eslint-disable react/jsx-key */
import {
  Box,
  Grid,
  Dialog,
  Divider,
  Typography,
  DialogContent,
} from '@mui/material';
import {
  topicArray,
  bioFormatter,
  industryArray,
  capitalizeFirstLetter,
  formatNumberWithCommas,
} from '../../../components/common';
import MyChip from '../../../components/MyChip';
import UserinfoCard from '../../../components/UserinfoCard';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MatchDetailsPopupLoader from './MatchDetailsPopupLoader';
import CommonModalHeader from '../../../../../components/DialogUi/CommonModalHeader';

interface MatchDetailsProps {
  openMatchDetails: any;
  handleCloseMatchDetails: any;
  matchDetails: any;
  matchDetailsLoader: any;
}

function MatchDetailsPopup({
  openMatchDetails,
  handleCloseMatchDetails,
  matchDetails,
  matchDetailsLoader
}: MatchDetailsProps) {
  const loading = matchDetailsLoader && !matchDetails;
  const topics = topicArray();
  const industries = industryArray();
  const timestamp = matchDetails?.startDate; // Unix timestamp
  const date = new Date(timestamp * 1000); // Multiply by 1000 to convert to milliseconds
  const formattedDate = date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  });

  /** Camel Case To Capital */
  const camelCaseToCapital = (topicsArray: string[]) => {
    return topicsArray.map((tag: string) => {
      const index = topics.findIndex((each) => each.name === tag);
      if (index !== -1) {
        return topics[index].label;
      } else {
        return tag;
      }
    });
  };

  /** Camel Case To Capital Industries */
  const camelCaseToCapitalIndustries = (industriesArray: string[]) => {
    return industriesArray.map((tag: string) => {
      const index = industries.findIndex((each) => each.name === tag);
      if (index !== -1) {
        return industries[index].label;
      } else {
        return tag;
      }
    });
  };

  /** Get Bio */
  const getBio = (mem: any) => {
    const major: string = mem?.bio?.education?.major;
    const university: string = mem?.bio?.education?.university;
    const role: any = mem?.bio?.workHistory?.role;
    const company: any = mem?.bio?.workHistory?.companyName;

    return bioFormatter(major, university) || bioFormatter(role, company);
  };

  /** User details */
  const UserinfoCardDetails = {
    mentee: {
      headshot: matchDetails?.mentee?.headshot,
      displayName: matchDetails?.mentee?.displayName,
      UserType: '',
      bio: getBio(matchDetails?.mentee) // Ensure this returns a string
    },
    mentor: {
      headshot: matchDetails?.mentor?.headshot,
      displayName: matchDetails?.mentor?.displayName,
      UserType: '',
      bio: getBio(matchDetails?.mentor) // Ensure this returns a string
    }
  };

  /** Match Details Info */
  const matchDetailsInfo = [
    { label: 'Past Calls', value: formatNumberWithCommas(matchDetails?.pastCall) },
    { label: 'Future Calls', value: formatNumberWithCommas(matchDetails?.futureCall)},
    { label: 'Messages Shared', value: formatNumberWithCommas(matchDetails?.messagedShared) }
    // { label: 'Total connection', value: matchDetails?.totalConnection },
    // { label: 'Last connection', value: matchDetails?.lastConnection },
  ];

  const middleBox = () => {
    return (
      <>
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
          <Box className="ManualMatchIcon">
            <ChevronLeftIcon className="ChevronLeftIconLeft" sx={{ color: '#ABB5BE' }} />
            <Box className="ManualMatchtextBox">
              <Typography variant="caption" noWrap sx={{ color: '#ABB5BE' }}>
                {matchDetails?.matchType}
              </Typography>
              <span className="lineDotted"></span>
            </Box>
            <ChevronRightIcon className="ChevronLeftIconRight" sx={{ color: '#ABB5BE' }} />
          </Box>
          <Typography variant="caption" color={'#ABB5BE'}>
            {matchDetails?.startDate !== 'No start date yet' && formattedDate}
            {matchDetails?.startDate === 'No start date yet' && matchDetails?.startDate}
          </Typography>
        </Box>
      </>
    );
  };

  /** Chip design */
  const renderChips = (values: any[], backgroundColor?: string) => (
    <Box display="flex" flexWrap="wrap" gap="8px" mt={1}>
      {values.map((value: any, idx: number) => (
        <MyChip key={idx} label={value} backgroundColor={backgroundColor}/>
      ))}
    </Box>
  );

  /** Match Details */ 
  const getMatchDetails = () => {
    return (
      <>
        <Grid container justifyContent={'space-between'}>
          <Grid item xs={4.5}>
            {/* Left Box */}
            <UserinfoCard UserinfoCardDetails={UserinfoCardDetails.mentee}/>
          </Grid>
          <Grid item xs={3} container justifyContent="center" alignItems="center">
            {/* Middle Box */}
            {middleBox()}
          </Grid>
          <Grid item xs={4.5}>
            {/* Right Box */}
            <UserinfoCard UserinfoCardDetails={UserinfoCardDetails.mentor}/>
          </Grid>
        </Grid>

        <Divider sx={{my:2}} />

        <Grid container spacing={2}>
          {matchDetailsInfo?.map((item, index) => (
            <Grid key={index} item lg={4} md={4} sm={6}>
              <Typography variant="body2" color="secondary">
                 { item.label }
              </Typography>
              <Typography color="primary">
                { item.value || '---' }
              </Typography>
            </Grid>
          ))}
        </Grid>

        <Divider sx={{my:2}} />

        <Box pb={2}>
          <Typography variant="h5" fontWeight="400">
            Matching criteria
          </Typography>
        </Box>
        <Grid container spacing={2}>
          {matchDetails?.searchQuery?.map((ele: any) => {
            if (ele.field === 'gender' || ele.field === 'firstGenStudent' || ele.field === 'ethnicity') {
              return (
                <Grid item lg={4} md={4} sm={6}>
                  <Box>
                    <Box display={'flex'} alignItems={'center'} gap={'5px'}>
                      <Box>
                      <Typography variant="body2" color="secondary">
                            {ele?.field === 'gender'
                              ? 'Gender'
                              : ele?.field === 'firstGenStudent'
                              ? '1st Generation'
                              : ele?.field === 'ethnicity'
                              ? 'Ethnicity'
                              : ''}
                      </Typography>
                      </Box>
                      <Box>
                        <CheckCircleIcon
                          sx={{
                            color: ele?.isMatched ? '#20C997' : '#6C757D',
                            width: '13px',
                            height: '13px'
                          }}
                        />
                      </Box>
                    </Box>
                    <Typography color="primary">
                      { ele?.isMatched ? (ele?.value ? ele?.value : '---') : '---' }
                    </Typography>
                  </Box>
                </Grid>
              );
            } else { return null }
          })}
        </Grid>

        <Divider sx={{my:2}} />

        {matchDetails?.searchQuery?.map((ele: any, index: number) => (
          <Box mb={1}>
            {ele?.unmatchedValues?.length > 0 || ele?.matchedValues?.length > 0 ? (
              <Typography variant="h5" fontWeight="400">
                {capitalizeFirstLetter(ele?.field === 'industry' ? 'Work History' : ele?.field)}
              </Typography>
            ) : null}

            {ele?.matchedValues?.length > 0 &&
              (ele?.field === 'topics'
                ? renderChips(camelCaseToCapital(ele?.matchedValues), '#20C997')
                : ele?.field === 'industries'
                ? renderChips(camelCaseToCapitalIndustries(ele?.matchedValues), '#20C997')
                : renderChips(ele?.matchedValues, '#20C997'))}

            {ele?.unmatchedValues?.length > 0 &&
              (ele?.field === 'topics'
                ? renderChips(camelCaseToCapital(ele?.unmatchedValues))
                : ele?.field === 'industries'
                ? renderChips(camelCaseToCapitalIndustries(ele?.unmatchedValues))
                : renderChips(ele?.unmatchedValues))}
          </Box>
        ))}
      </>
    )
  };

  return (
    <Dialog
      fullWidth
      open={openMatchDetails}
      onClose={handleCloseMatchDetails}
      PaperProps={{ sx: { height: '100%' } }}
    >
      <CommonModalHeader
        title={`Match Details`}
        handleCloseModal={handleCloseMatchDetails}
      />

      <DialogContent className={loading ? 'overflow-hidden' : ''}>
        {loading ? (
          <MatchDetailsPopupLoader />
        ) : ( 
          getMatchDetails() 
        )} 
      </DialogContent>
    </Dialog>
  );
}

export default MatchDetailsPopup;
