import React, { SyntheticEvent } from 'react';
import { Box, Typography } from '@mui/material';
import RoleTypeTag from '../../components/RoleTypeTag';
import { CommonStyle } from '../../../../utils/muiStyle';
import MySkeleton from '../../../../components/Skeleton';
// import { useNavigate } from 'react-router';

interface UserCardProps {
  mem?: any;
  bio?: string;
  loading?: boolean;
  imageUrl?: string;
  category?: string;
  cardWidth?: string;
  cardHeight?: string;
  displayName?: string;
  communityMembers?: any;
  handleOnSelectMember?:any;
  allCommunityMembersList?: any;
}

const UserCard: React.FC<UserCardProps> = ({
  mem,
  bio,
  loading,
  imageUrl,
  category,
  cardWidth,
  cardHeight,
  displayName,
  // communityMembers,
  handleOnSelectMember,
  // allCommunityMembersList,
}) => {
  // const navigate = useNavigate();
  const classes = CommonStyle();

  // const handleOnSelectMember = (mem: any) => {
  //   navigate('/app/communitymember', { state: { mem, communityMembers, allCommunityMembersList } });
  // };

  const addImageFallback = (event: SyntheticEvent<HTMLImageElement, Event>, displayName: string) => {
    event.currentTarget.src = `https://ui-avatars.com/api/?name=${displayName}`;
  };

  return (
    <Box
      className={classes.cardBgImg}
      onClick={() => !loading && handleOnSelectMember(mem)}
      onError={(event: any) => addImageFallback(event, mem?.displayName)}
    >
      {!loading && 
        <img
          className={'card-image'}
          src={imageUrl}
          alt={displayName}
        />
      }
      {/* Linear gradient BG */}
      <Box
        position={'absolute'}
        borderRadius={'8px'}
        height={loading? '100%' : cardHeight}
        width={loading? '100%' : cardWidth}
        sx={{
          background: loading ? '#EFF0F4' : 'linear-gradient(180deg, rgba(38, 39, 56, 0) 53.24%, #262738 81.49%)'
        }}
      />

      <Box p={1}>
        {loading ? (
          <MySkeleton variant='rectangular' height={16} width={70} bg={'#FFFFFF'} />
        ) : (<RoleTypeTag category={category} place="community" />)}
      </Box>
      <Box p={1} position='absolute' height={'80px'} width='100%' bottom={0}>
        <Typography noWrap textAlign="left" variant='h5' color={'#FFFFFF'} lineHeight={'normal'}>
          {loading ? (
            <MySkeleton variant='rectangular'height={20}  width={100} bg={'#FFFFFF'} />)
          : displayName}
        </Typography>
        <Typography className={classes.userCardBio} color={'#ADB5BD'} lineHeight={'normal'}>
          {loading ? (
            <Box display={'flex'} gap={.5} alignItems={'center'} pt={1}>
              <MySkeleton variant='rectangular' height={16} width={100}  bg={'#FFFFFF'} />
              <MySkeleton variant='circular' height={8} width={8} bg={'#FFFFFF'} />
              <MySkeleton variant='rectangular' height={16} width={100} bg={'#FFFFFF'} />
            </Box>
          ) : ( bio )}
        </Typography>
    </Box>
    </Box>
  );
};

export default UserCard;
