/* eslint-disable guard-for-in */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { Dialog } from '@mui/material';
import { popupActions } from '../../../redux/slices/onboardingPopupsSlice';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import SocialHandlesForm from './SocialHandlesForm';
import CommonModalHeader from '../../../components/DialogUi/CommonModalHeader';

const SocialHandlesModal = () => {
  const openSocial = useAppSelector((state) => state.onboardingPopups.data.social);
  const dispatch = useAppDispatch();

  const handleCloseSocial = () => {
    dispatch(popupActions.updatePopups({ social: false }));
    // reset(socialData);
  };

  useEffect(() => {
    return () => {
      handleCloseSocial();
    };
  }, []);
  return (
    <Dialog
      open={openSocial}
      onClose={handleCloseSocial}
    >
      <CommonModalHeader 
        title={`Social Handles`}
        handleCloseModal={handleCloseSocial}
      />
      <SocialHandlesForm />
    </Dialog>
  );
};

export default SocialHandlesModal;
