import React, { useEffect, useState } from 'react';
import {
  Box,
  Stack,
  Button,
  Divider,
} from '@mui/material';
//import ExportButton from './ExportButton';
//import ExportPopover from './ExportPopover';
import { API } from '../../../../../../api';
import { useParams } from 'react-router-dom';
// import InsightFilter from './InsightFilter';
import { adminPanel } from '../../../../../../utils/muiStyle';
import ProgramMetricsInsights from './ProgramMetricsInsights';
import UserInsightsCards from './UserInsightsCards'; // new
// import UserInsightsCardsOld from './UserInsightsCardsOld'; //old
import { useAppSelector } from '../../../../../../redux/hooks';
// import { FilterIcon } from '../../../../../../utils/Icons';
import useResponsiveScreenSwitch from '../../../../../../utils/useResponsiveScreenSwitch';

const ProgramInsights = () => {
  /** Filter open */
  // const [openFilter, setopenFilter] = useState(false);
  // const handleopenFilter = (flag: boolean) => setopenFilter(flag);
  const { forMobile } = useResponsiveScreenSwitch();
  const classs = adminPanel();

  let grpId = useParams().id || '';
  if (grpId === 'admin') {
    grpId = '';
  }
  const groupData = useAppSelector((state) => state.groupDetails);
  const [currencySymbol, setCurrencySymbol] = useState('');
  const [memberInsightValue, setMemberInsightValue] = useState<any>();
  const [metricsValue, setMetricsValue] = useState<any>([]);
  const [userTypes, setUserTypes] = useState<any>([]);
  const [activeGrid, setActiveGrid] = useState<string>('Program Metrics'); // default show 'Program Metrics'
  // const [anchorExport, setAnchorExport] = React.useState<HTMLButtonElement | null>(null);
  // const [isClicked, setIsClicked] = useState(false);

  const handleButtonClick = (tabName: string) => {
    setActiveGrid(tabName);
  };

  const fetchMembersInsightValues = async (grpId: string) => {
    try {
      const response = await API.fetchMembersInsights(grpId);
      if (response.status === 200) {
        setMetricsValue(response?.data?.data?.[0]);
        setMemberInsightValue(response?.data?.data?.[0]?.members);
        const keys = Object.keys(response?.data?.data?.[0]?.members || {}).filter(
          (key) => key !== 'total' && key !== 'Unknown'
        );
        setUserTypes(keys); // fetching tabs i.e. Member types
        // setLoading(false);
      } else {
        // setLoading(false);
      }
    } catch (e) {
      // setLoading(false);
    }
  };

  // const tabList = ['Program Metrics', ...(userTypes.length > 0 ? userTypes : [])];
  const tabList = ['Program Metrics'];

  useEffect(() => {
    if (groupData?.data) {
      const { currency } = groupData.data;
      setCurrencySymbol(currency);
    }
    fetchMembersInsightValues(grpId);
  }, [groupData.data, grpId]);

  const renderGrid = (gridId: string) => {
    if (gridId === 'Program Metrics') {
      return <ProgramMetricsInsights metricsValue={metricsValue} memberInsightValue={memberInsightValue} />;
    } else {
      return <UserInsightsCards tabName={gridId} metricsValue={metricsValue} currencySymbol={currencySymbol} />;
    }
  };

  /** Active btn style */
  const buttonStyle = (id: string) =>
    activeGrid === id
      ? {
          background: '#EFF0F4',
          fontWeight: 700
        }
      : {};

  /** Export Button Popover */  
  // const handleClickExport = (event: any) => {
  //   setAnchorExport(event.currentTarget);
  //   setIsClicked(true);
  // };

  // const handleCloseExport = () => {
  //   setAnchorExport(null);
  //   setIsClicked(false);
  // };

  // const handleClickDownloadPdf = (event: any) => {
  //   console.log("DownloadPdf")
  // };

  // const handleClickExportExcel = (event: any) => {
  //   console.log("DownloadPdf")
  // };

  // const handleClickExportPlan = (event: any) => {
  //   console.log("ExportPlan")
  // };
  // const openExport = Boolean(anchorExport);   

  return (
    <>
      <Box className={classs.mainBody}>
        <Box display="flex" alignItems="center" justifyContent="space-between" gap="8px" mb={2}>
          <Stack
            borderRadius="8px"
            border="1px solid #EFF0F4"
            direction="row"
            divider={<Divider orientation="vertical" flexItem />}
          >
            {/* {['Program Metrics'].map((tabName) => ( */}
            {tabList.map(
              (
                tabName // If we need 3 tabs
              ) => (
                <Button
                  key={tabName}
                  sx={{
                    width: '115px',
                    height: '34px',
                    fontSize: '12px',
                    fontWeight: '400',
                    ...buttonStyle(tabName)
                  }}
                  onClick={() => handleButtonClick(tabName)}
                >
                  {tabName}
                </Button>
              )
            )}
          </Stack>
          {/* filter icon */}
          {/* <IconButton sx={{p:0}}  onClick={() => handleopenFilter(true)}>
            <FilterIcon size={34} />
          </IconButton> */}
          {/* Export btn */}
          {/* <Box display="flex" alignItems="center" justifyContent="flex-end" gap={'10px'}>
            <ExportButton onClick={handleClickExport}  isClicked={isClicked} />
            <ExportPopover
              open={openExport}
              anchorEl={anchorExport}
              handleDownloadPdf={handleClickDownloadPdf}
              handleExportExcel={handleClickExportExcel}
              handleExportPlan={handleClickExportPlan}
              onClose={handleCloseExport}
            />
          </Box> */}
        </Box>

        <Box>{activeGrid !== null ? renderGrid(activeGrid) : null}</Box>
      </Box>

      {/* Filter */}
      {/* <InsightFilter
        loading={loading}
        openFilter={openFilter}
        handleopenFilter={handleopenFilter}
      /> */}
    </>
  );
};

export default ProgramInsights;
