/* eslint-disable no-console */
/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography
} from '@mui/material';
import { API } from '../../../../api';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  setHomePage,
  clearAllConversations
} from '../../../../redux/slices/ai-chat/aiChatMessages';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { ChatHistoryLoader } from '../../../../Loader/AiMentorLoader';

export const ChatBotHistoryComponent = ({}: any) => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);

  const getConversationPreviews = async () => {
    setLoader(true);
    const response = await API.getConversationPreviews();
    if (response.status === 200) {
      setData(response.data.data);
      setLoader(false);
    }
  };

  useEffect(() => {
    getConversationPreviews();
  }, []);

  const getRelativeDate = (timestamp: string) => {
    const currentDate: any = new Date();
    const targetDate: any = new Date(timestamp);
    const diffInDays = Number((currentDate - targetDate) / (1000 * 60 * 60 * 24));

    if (diffInDays < 1) {
      return 'Today';
    } else if (diffInDays < 2) {
      return 'Yesterday';
    } else {
      return `${Math.floor(diffInDays)} days ago`;
    }
  };

  let previousDate = '';

  const handleClickHistory = (ele: any) => {
    dispatch(setHomePage(false));
    const conversationID = ele?.conversation_id;
    navigate('/app/askDosen/', { state: { conversationID } });
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(clearAllConversations());
  }, []);

  return (
    <Box overflow={'hidden auto'}>
      {loader ? (
        <ChatHistoryLoader />
      ) : (
        data.map((ele: any) => {
          const currentDate = getRelativeDate(ele.timestamp);
          if (currentDate !== previousDate) {
            previousDate = currentDate;
            return (
              <Box key={currentDate}>
                <Typography color="grey.200" mb={2} fontWeight={'700'}>
                  {previousDate}
                </Typography>
                <Typography
                  mb={2}
                  color={"#0071A9"}
                  fontSize={"16px"}
                  className={'cursor-pointer'}
                  onClick={() => handleClickHistory(ele)}
                >
                  {ele.prompt} <ArrowForwardIcon />
                </Typography>
              </Box>
            );
          } else {
            return (
              <Typography
                key={ele.prompt}
                mb={2}
                color={"#0071A9"}
                fontSize={"16px"}
                className={'cursor-pointer'}
                onClick={() => handleClickHistory(ele)}
              >
                {ele.prompt} <ArrowForwardIcon />
              </Typography>
            );
          }
        })
      )}
    </Box>
  );
};
