import React, { useEffect, useState } from 'react';
import { Box, Grid, Link, Dialog, Typography, DialogContent, DialogActions } from '@mui/material';
import logo from '../../../../assets/images/brand/dosen/white-logo.svg';
import XpPoints from '../../../../assets/images/gettingStartedModals/xpPoints.svg';
import Exploring from '../../../../assets/images/gettingStartedModals/exploring.svg';
import GoalProgress from '../../../../assets/images/gettingStartedModals/goalProgress.svg';
import GettingStartedModals1 from '../../../../assets/images/gettingStartedModals/gettingStartedModals1.png';
import GettingStartedModals2 from '../../../../assets/images/gettingStartedModals/gettingStartedModals2.png';
import GettingStartedModals3 from '../../../../assets/images/gettingStartedModals/gettingStartedModals3.png';
import GettingStartedModals4 from '../../../../assets/images/gettingStartedModals/gettingStartedModals4.png';
import useProfileDetails from '../../../../hooks/useProfileDetails';
import { API } from '../../../../api';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { useAppDispatch } from '../../../../redux/hooks';
import { userActions } from '../../../../redux/slices/user-profile/userProfieSlice';
import { getUserDetails } from '../../../../utils/orgName';

interface GettingStartedModalProps {
  open: boolean;
  handleClickGettingStartedModal: any;
  allowEditing: boolean;
  selectedStep?: number;
}

const GettingStartedModal: React.FC<GettingStartedModalProps> = ({
  open,
  handleClickGettingStartedModal,
  allowEditing,
  selectedStep = 0
}) => {
  const [step, setStep] = useState(selectedStep);
  const totalSteps = 4;
  const { gettingStarted } = useProfileDetails();
  const { userId } = getUserDetails();

  const handleDotClick = (step: number) => {
    setStep(step);
  };

  const handleNext = () => {
    setStep((prev) => prev + 1);
  };

  const handlePrev = () => {
    setStep((prev) => prev - 1);
  };
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (open) {
      setStep(selectedStep);
    }
  }, [selectedStep, open]);

  /** Render dots for navigation */
  const renderDots = () => {
    return Array.from({ length: totalSteps }).map((_, index) => (
      <Box
        key={index}
        onClick={() => handleDotClick(index)}
        width={'9px'}
        height={'9px'}
        mx={'4px'}
        className="cursor-pointer"
        borderRadius={'50%'}
        border="1px solid #BBBBBB"
        bgcolor={index === step ? '#BBBBBB' : ''}
      />
    ));
  };

  const handleClose = async () => {
    if (!gettingStarted.editTasks || !gettingStarted.nonEditTasks) {
      try {
        if (!gettingStarted.editTasks && allowEditing) {
          dispatch(userActions.userGettingStartedUpdate({ ...gettingStarted, editTasks: true }));
          await API.upadateGettingStarted({ data: { editTasks: true }, userId });
        }
        if (!gettingStarted.nonEditTasks && !allowEditing) {
          dispatch(userActions.userGettingStartedUpdate({ ...gettingStarted, nonEditTasks: true }));
          await API.upadateGettingStarted({ data: { nonEditTasks: true }, userId });
        }
      } catch (e) {
        toast.error(_.get(e, 'response.data.message', `Failed to update getting started status`));
      }
    }
    handleClickGettingStartedModal(false);
  };

  /** Content */
  const stepContents = [
    // step 1
    <>
      <Box
        height={378}
        display={'flex'}
        position={'relative'}
        flexDirection={'column'}
        sx={{
          backgroundImage: `url(${GettingStartedModals1})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }}
      >
        <Box display={'flex'} justifyContent={'center'} width={'100%'} mt={2}>
          <img src={logo} alt="Dosen" />
        </Box>
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'} flex={1} justifyContent={'center'}>
          <Typography fontSize={43} fontWeight={700} color={'#FFFFFF'} lineHeight={'44.37px'}>
            Welcome to
          </Typography>
          <Typography fontSize={78} fontWeight={700} color={'#FFFFFF'} lineHeight={'80.48px'}>
            DOSEN
          </Typography>
        </Box>
      </Box>
      <Box p={2} pb={0} gap={1} display="flex" flexDirection={'column'}>
        <Typography variant="subtitle1" lineHeight={'22.56px'} color="primary">
          Dosen is where you can find meaningful connections to willing experts who are ready and waiting to guide you
          toward your goals.
        </Typography>
        <Box>
          <Typography
            variant="overline"
            style={{
              fontSize: 16,
              fontWeight: 700,
              lineHeight: '22.56px',
              color: 'primary',
              textTransform: 'none'
            }}
          >
            Chat and meet virtually with experts
          </Typography>
          {allowEditing ? (
            <Typography
              ml={0.5}
              fontWeight={400}
              fontSize={'16px'}
              color={'primary'}
              variant="overline"
              lineHeight={'22.56px'}
              textTransform={'none'}
            >
              to figure out what tasks you should add to your development plan.
            </Typography>
          ) : (
            <Typography
              ml={0.5}
              fontWeight={400}
              fontSize={'16px'}
              color={'primary'}
              variant="overline"
              lineHeight={'22.56px'}
              textTransform={'none'}
            >
              to work through the tasks in your development plan.
            </Typography>
          )}
        </Box>
        <Box>
          <Typography
            fontWeight={700}
            fontSize={'16px'}
            color={'primary'}
            variant="overline"
            lineHeight={'22.56px'}
            textTransform={'none'}
          >
            Track your Goal Progress
          </Typography>
          <Typography
            ml={0.5}
            fontWeight={400}
            fontSize={'16px'}
            color={'primary'}
            variant="overline"
            lineHeight={'22.56px'}
            textTransform={'none'}
          >
            as you complete these tasks.
          </Typography>
        </Box>
        <Box>
          <Typography
            fontWeight={700}
            fontSize={'16px'}
            color={'primary'}
            variant="overline"
            lineHeight={'22.56px'}
            textTransform={'none'}
          >
            Build personalized connection
          </Typography>
          <Typography
            ml={0.5}
            fontWeight={400}
            fontSize={'16px'}
            color={'primary'}
            variant="overline"
            lineHeight={'22.56px'}
            textTransform={'none'}
          >
            by availing of the experts who are there to help and following their advice to success.
          </Typography>
        </Box>
        <Box>
          <Typography
            fontWeight={700}
            fontSize={'16px'}
            color={'primary'}
            variant="overline"
            lineHeight={'22.56px'}
            textTransform={'none'}
          >
            24/7 access your AI mentor
          </Typography>
          <Typography
            ml={0.5}
            fontWeight={400}
            fontSize={'16px'}
            color={'primary'}
            variant="overline"
            lineHeight={'22.56px'}
            textTransform={'none'}
          >
            to help achieve your goals.
          </Typography>
        </Box>
      </Box>
    </>,
    // step2
    <>
      <Box
        height={378}
        display={'flex'}
        position={'relative'}
        flexDirection={'column'}
        sx={{
          backgroundImage: `url(${GettingStartedModals2})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }}
      >
        <Box display={'flex'} justifyContent={'center'} width={'100%'} mt={2}>
          <img src={logo} alt="Dosen" />
        </Box>
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'} flex={1} justifyContent={'center'}>
          <img src={GoalProgress} alt="GoalProgress" height={'235px'} width={'235px'} />
        </Box>
      </Box>
      <Box p={2} pb={0} gap={1} display="flex" flexDirection={'column'}>
        <Typography variant="h3" lineHeight={'28.2px'}>
          Track your progress towards achieving your goals
        </Typography>
        <Typography variant="subtitle1" color="primary" lineHeight={'22.56px'}>
          Your Goal Progress meter will help you track your progress towards achieving your goals.
        </Typography>
        {allowEditing ? (
          <>
            <Typography variant="subtitle1" color="primary" lineHeight={'22.56px'}>
              Don’t be afraid to continuously add new tasks and levels to your development plan even if it reduces your
              overall Goal Progress.
            </Typography>
            <Typography variant="subtitle1" color="primary" lineHeight={'22.56px'}>
              Administrators of your program can see how engaged and committed you are by how actively you add new tasks
              and levels to your development plan.
            </Typography>
          </>
        ) : (
          <>
            <Typography variant="subtitle1" color="primary" lineHeight={'22.56px'}>
              Your Goal Progress is visible to your Program Administrators so be sure not to fall behind the crowd and
              mark your tasks as complete as you go.
            </Typography>
          </>
        )}
      </Box>
    </>,
    // step3
    <>
      <Box
        height={378}
        display={'flex'}
        position={'relative'}
        flexDirection={'column'}
        sx={{
          backgroundImage: `url(${GettingStartedModals3})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }}
      >
        <Box display={'flex'} justifyContent={'center'} width={'100%'} mt={2}>
          <img src={logo} alt="Dosen" />
        </Box>
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'} flex={1} justifyContent={'center'}>
          <img src={XpPoints} alt="XpPoints" height={'116px'} width={'218px'} />
        </Box>
      </Box>
      <Box p={2} pb={0} gap={1} display="flex" flexDirection={'column'}>
        <Typography variant="h3" lineHeight={'28.2px'}>
          XP Points track your overall program engagement
        </Typography>
        <Typography variant="subtitle1" color="primary" lineHeight={'22.56px'}>
          Boost your XP Points by:
        </Typography>
        <ul className="listStyle-dots">
          {allowEditing && (
            <li>
              <Typography variant="subtitle1" color="primary" lineHeight={'22.56px'}>
                Creating new tasks in your development plan
              </Typography>
            </li>
          )}
          <li>
            <Typography variant="subtitle1" color="primary" lineHeight={'22.56px'}>
              Completing tasks in your development plan
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1" color="primary" lineHeight={'22.56px'}>
              Sending messages and starting new chat conversations
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1" color="primary" lineHeight={'22.56px'}>
              Scheduling meetings and video calls with experts
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1" color="primary" lineHeight={'22.56px'}>
              Viewing content in the library
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1" color="primary" lineHeight={'22.56px'}>
              Interacting with your AI Mentor
            </Typography>
          </li>
        </ul>
      </Box>
    </>,
    // step4
    <>
      <Box
        height={378}
        display={'flex'}
        position={'relative'}
        flexDirection={'column'}
        sx={{
          backgroundImage: `url(${GettingStartedModals4})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }}
      >
        <Box display={'flex'} justifyContent={'center'} width={'100%'} mt={2}>
          <img src={logo} alt="Dosen" />
        </Box>
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'} flex={1} justifyContent={'center'} px={2}>
          <img src={Exploring} alt="Exploring" className="w-100p" />
        </Box>
      </Box>
      <Box p={2} pb={0} gap={1} display="flex" flexDirection={'column'}>
        <Typography variant="h3" lineHeight={'28.2px'}>
          Time to start exploring!
        </Typography>
        <Typography variant="subtitle1" color="primary" lineHeight={'22.56px'}>
          Dosen offers a variety of features and resources that can help you achieve your goals. To get started on your
          journey:
        </Typography>
        <ul className="listStyle-dots">
          <li>
            <Typography variant="subtitle1" color="primary" lineHeight={'22.56px'}>
              Let your Administrator know that you’re here
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1" color="primary" lineHeight={'22.56px'}>
              If you have a mentor assigned, introduce yourself
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1" color="primary" lineHeight={'22.56px'}>
              If you don’t, find one in your Community
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1" color="primary" lineHeight={'22.56px'}>
              Arrange a mutually available time to meet virtually
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1" color="primary" lineHeight={'22.56px'}>
              Schedule your virtual meetings and build your plan
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1" color="primary" lineHeight={'22.56px'}>
              Tick off the tasks in your development plan
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1" color="primary" lineHeight={'22.56px'}>
              Use your Library content to help you along the way
            </Typography>
          </li>
        </ul>
      </Box>
    </>
  ];

  const renderContent = () => stepContents[step] || null;

  return (
    <Dialog open={open} onClose={() => {}} PaperProps={{ className: 'h-100p' }}>
      <DialogContent className="p-0">{renderContent()}</DialogContent>
      <DialogActions sx={{ px: 2, pt: 1 }}>
        <Grid container>
          <Grid item xs={4} textAlign={'left'}>
            {step > 0 && (
              <Link onClick={handlePrev} color="#0071A9">
                Previous
              </Link>
            )}
          </Grid>
          <Grid item xs={4} display="flex" flex="1 0 auto" justifyContent="center" alignItems="center">
            {renderDots()}
          </Grid>
          <Grid item xs={4} textAlign={'end'}>
            {step < totalSteps - 1 ? (
              <Link onClick={handleNext} color="#0071A9">
                Next
              </Link>
            ) : (
              <Link onClick={handleClose} color="#0071A9">
                Finish
              </Link>
            )}
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default GettingStartedModal;
