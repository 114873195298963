import { createSlice } from '@reduxjs/toolkit';

interface IPopups {
  data: {
    profile: { open: boolean; disable: boolean };
    digitalIntro: boolean;
    social: boolean;
    education: {
      open: boolean;
      educationPopUpDisable:boolean;
      action: string;
      item: {
        department: string;
        graduation_date: string;
        major: string;
        minor: string[];
        university: string;
        _id: string;
      };
    };

    workHistory: {
      open: boolean;
      workHistoryDisable:boolean;
      action: string;
      item: {
        company_name: string;
        roleType: string[];
        title: string;
        start_date: string | null;
        end_date: string | null;
        industry: string;
        currentlyWorking: boolean;
        _id: string;
      };
    };
    hobbies: boolean;
    hobbiesDisable: boolean;
  };
}

export const initialState: IPopups = {
  data: {
    profile: { open: false, disable: false },
    digitalIntro: false,
    social: false,
    education: {
      open: false,
      educationPopUpDisable :false,
      action: 'Add',
      item: {
        department: '',
        graduation_date: '',
        major: '',
        minor: [],
        university: '',
        _id: ''
      }
    },
    workHistory: {
      open: false,
      workHistoryDisable:false,
      action: 'Add',
      item: {
        company_name: '',
        currentlyWorking: false,
        end_date: '',
        industry: '',
        roleType: [],
        start_date: '',
        title: '',
        _id: ''
      }
    },
    hobbies: false,
    hobbiesDisable: false
  }
};

const onboardingPopupsSlice = createSlice({
  name: 'popups',
  initialState,
  reducers: {
    updatePopups: (state, action) => {
      state.data = { ...state.data, ...action.payload };
    }
  }
});

export default onboardingPopupsSlice.reducer;
export const popupActions = onboardingPopupsSlice.actions;
