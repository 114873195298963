import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from '../../api';

interface ILevel {
  levelId: string;
  taskId: string;
  question: string;
  ansType: string;
  answerOptions: string[];
  minMentorAns: number;
  maxMentorAns: number;
  ans: string[];
}

interface IInitialState {
  loading: boolean;
  data: {
    data: ILevel[];
    grpId: string;
  };
  error: boolean;
  errorText: string;
}

const initialState: IInitialState = {
  loading: false,
  data: {
    grpId: '',
    data: []
  },
  error: false,
  errorText: ''
};
export const fetchMentorTasks = createAsyncThunk('mentorTasks', (id?: any) => {
  // console.log('dvs', id);
  if(id){
    return API.getMentorTask({ id: id?.groupId }).then((response) => response?.data);

  }else{
    return;
  }
  
});

const mentorTasksSlice = createSlice({
  name: 'mentorQuestions',
  initialState,
  reducers: {
    updateAnswer: (state, action) => {
      const data = state.data.data.map((each, index) => {
        if (index === action.payload.index) {
          return { ...each, ans: action.payload.ans };
        }
        return each;
      });
      const { grpId } = state.data;
      return { ...state, data: { grpId, data } };
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMentorTasks.pending, (state, action) => {
      state.loading = true;
      state.data = { data: [], grpId: '' };
      state.error = false;
      state.errorText = '';
    });
    builder.addCase(fetchMentorTasks.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = false;
      state.errorText = '';
    });
    builder.addCase(fetchMentorTasks.rejected, (state, action) => {
      state.loading = false;
      state.data = { data: [], grpId: '' };
      state.error = true;
      state.errorText = action.error.message || 'Something went wrong';
    });
  }
});

export default mentorTasksSlice.reducer;
export const mentorAnswerActions = mentorTasksSlice.actions;
