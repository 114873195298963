import React from 'react';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { Chip } from '@mui/material';

type Props = {};

const PromptGroupChip = ({ onClick, selected, groupName }: any) => {
  return (
    <Chip
      sx={{
        fontSize: '14px',
        fontWeight: '600',
        // height: '36px',
        backgroundColor: selected ? '#44515E' : '#e5e7e94d',
        color: selected ? '#FFFFFF' : '#44515E',
        '&:hover': {
          backgroundColor: selected ? '#44515E' : '#E5E7E9',
          color: selected ? '#FFFFFF' : '#44515E',
        }
      }}
      onClick={onClick}
      label={groupName}
      icon={
        groupName === 'Featured' ? (
          <TrendingUpIcon
            style={{
              color: selected ? '#FFFFFF' : '#44515E',
            }}
          />
        ) : (
          (null as unknown as React.ReactElement)
        )
      }
    />
  );
};

export default PromptGroupChip;