import { Box } from '@mui/material';
import { he } from 'date-fns/locale';
import React from 'react';
import { useDrop } from 'react-dnd';
import { useAppSelector } from '../../../../../../../redux/hooks';

export const Section = ({ moveTask, levelIndex, taskIndex, levelId }: any) => {
  const grpData = useAppSelector((state) => state.groupDetails.data);

  const checkPublished = (item: any) => {
    return grpData?.goalPublished ? item.levelId === levelId : true;
  };
  const [{ isOver }, drop] = useDrop(() => ({
    accept: 'TASK',
    drop: (item: any) => {
      moveTask({
        toTaskIndex: taskIndex,
        toLevelIndex: levelIndex,
        fromTaskIndex: item.taskIndex,
        fromLevelIndex: item.levelIndex,
        levelId: levelId
      });
    },
    canDrop: (item) => checkPublished(item),
    hover: (item: any, monitor) => {
      item.toTaskIndex = taskIndex;
      // item.toLevelIndex = levelIndex;
    },
    collect: (monitor) => ({
      isOver: monitor.isOver()
    })
  }));
  return (
    <Box
      style={{
        height: isOver ? '30px' : '10px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center '
      }}
      ref={drop}
    >
      <span
        style={{
          border: isOver ? '1px dashed grey' : 'none',
          width: '80%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center ',
          color: 'grey'
        }}
      >
        {isOver ? 'Drop here' : ''}
      </span>
    </Box>
  );
};
