/* eslint-disable react/iframe-missing-sandbox */
import React, { useEffect, useRef, useState } from 'react';
import { Box, Link, Stack, Badge, Dialog, Divider, Tooltip, Typography, DialogContent } from '@mui/material';
import MyChip from '../../../components/MyChip';
import { FileOutlined } from '@ant-design/icons';
import { ORFIUM } from '../../../../../utils/constants';
import { useAppSelector } from '../../../../../redux/hooks';
import ChatAvatarComponent from '../../chat/ChatAvatarComponent';
import { camelCaseToCapital } from '../../../../../utils/topicsMapping';
import { LibraryRightBarLoader } from '../../Library/components/LibraryLoader';
import CommonModalHeader from '../../../../../components/DialogUi/CommonModalHeader';
import { camelCaseToCapitalIndustries } from '../../../../../utils/industriesMapping';

interface Props {
  openPreview: boolean;
  setOpenPreview: any;
  singleListData: any;
  loading?: boolean;
}

const returnArticleText = (articleDetails: any) => {
  const html: string = articleDetails ? articleDetails?.articleDescription : '';
  if (!html) {
    return '';
  }

  const urlRegex =
    /(?<!['"])(http(s)?:\/\/)?[a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}[-a-zA-Z0-9@:%_\+.~#?&=,;\/-]*(?![^<]*>)/g;

  /** Replace matched URLs with anchor tags */
  const linkifiedHTML = html.replace(urlRegex, (url: string) => {
    const link: string = url.startsWith('http') ? url : `http://${url}`;
    return `<a href="${link}" target="_blank">${url}</a>`;
  });

  return linkifiedHTML;
};

const PreviewContent = ({ openPreview, setOpenPreview, singleListData, loading }: Props) => {
  const tags = (singleListData?.tags ? camelCaseToCapital(singleListData.tags) : [])
    .concat(singleListData?.industries ? camelCaseToCapitalIndustries(singleListData.industries) : []);

  const allTags = tags.join(', ');
  const oid = useAppSelector((state) => state.orgDetails.data?.oid);
  const [divHeight, setDivHeight] = useState<number | null>(null);
  const stackRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const updateHeight = () => {
      setTimeout(() => {
        if (stackRef.current) {
          const height = stackRef.current.clientHeight;
          setDivHeight(height);
        }
      }, 0);
    };

    if (openPreview || singleListData) {
      updateHeight();
    }
  }, [openPreview, singleListData]);

  const handleDialogClose = () => {
    setOpenPreview(false);
  };

  const renderTags = () => (
    <Stack ref={stackRef} direction="row" useFlexGap flexWrap="wrap" spacing={1} alignItems="center">
      {tags.map((ele, index) => (
        <React.Fragment key={index}>
          <MyChip label={ele} backgroundColor="#EFF0F4" color="#68717A" />
        </React.Fragment>
      ))}
    </Stack>
  );

  return (
    <Dialog fullWidth maxWidth={oid === ORFIUM ? 'md' : 'sm'} scroll="body" open={openPreview} onClose={handleDialogClose}>
      <CommonModalHeader title={oid === ORFIUM ? "Content" : "Article"} handleCloseModal={handleDialogClose} />

      <DialogContent>
        {loading ? (
          <LibraryRightBarLoader display={'popup'} />
        ) : (
          <Box>
            <Typography variant="h3">{singleListData ? singleListData.articleName : ''}</Typography>
            <Badge
              sx={{
                fontFamily: 'Open Sans',
                fontSize: '12px',
                textTransform: 'capitalize',
                fontWeight: '600',
                borderRadius: '5px',
                height: 'auto',
                padding: '2px 9px',
                margin: '10px 0',
                color: '#E99940',
                border: '1px solid #E99940'
              }}
            >
              Visual indicator Text
            </Badge>
            <Typography>
              {singleListData?.createdAt && (
                <span>
                  {new Date(singleListData.createdAt * 1000).toLocaleDateString('en-US', {
                    month: 'long',
                    day: 'numeric',
                    year: 'numeric'
                  })}
                </span>
              )}
              <span> • </span> {singleListData?.readTime} read
            </Typography>

            {tags.length > 0 && (
              <Box my={1}>
                <Box
                  height={'32px'}
                  overflow={'hidden'}
                >
                  <Stack direction="row" useFlexGap flexWrap="wrap" spacing={1} flex={1}>
                    {divHeight && divHeight > 32 ? (
                      <Tooltip title={allTags} placement="top-start">
                        {renderTags()}
                      </Tooltip>
                    ) : (
                      renderTags()
                    )}
                  </Stack>
                </Box>
              </Box>
            )}

            <Box display="flex" alignItems="center" my={2} gap="8px">
              <ChatAvatarComponent
                height="48px"
                width="48px"
                type="noStatus"
                image={singleListData?.coverImageUrl}
                firstLetter={singleListData?.authorName.slice(0, 1)}
              />
              <Typography variant="h5">{singleListData?.authorName}</Typography>
            </Box>
            <Divider />
            <Box mt={2}>
              {/* Image */}
              {/* {singleListData?.type === 'Text' && (
                <img src={singleListData?.coverImageUrl} alt="resumeimg" className="LibrarycoverImage" />
              )} */}
              {/* Video */}
              {singleListData?.type === 'Video' && (
                <Box>
                  <iframe
                    title="LibraryVideo"
                    className="LibraryVideoAndImage"
                    src={singleListData?.fileUrl} // url for the video file
                    allowFullScreen
                  />
                </Box>
              )}
              {/* Audio */}
              {singleListData?.type === 'Audio' && (
                <Box width={'100%'}>
                  <audio controls>
                    <source src={singleListData?.fileUrl} type="audio/mpeg" />
                    Your browser does not support the audio element.
                  </audio>
                </Box>
              )}
              {/* Url or file */}
              {singleListData?.type === 'Text' && singleListData?.fileUrl?.includes('http') && (
                <Box mt={2}>
                  <Link
                    onClick={() =>
                      singleListData?.fileUrl?.includes('http') ? window.open(singleListData?.fileUrl, '_blank') : {}
                    }
                    className="color-blue"
                  >
                    <FileOutlined /> {singleListData.articleName}
                  </Link>
                </Box>
              )}
              {/* Text Content */}
              <Box mt={2}>
                <Box
                  className="ArticleBodyContent"
                  dangerouslySetInnerHTML={{
                    __html: returnArticleText(singleListData)
                  }}
                />
                {/* {returnArticleText(singleListData)} */}
                {/* <div className="ArticleBodyContent">
                    <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
                </div> */}
                {/* <Box
                  className="ArticleBodyContent"
                  dangerouslySetInnerHTML={{
                    __html: htmlContent
                  }}
                /> */}
              </Box>
            </Box>
            {/* Embedded link */}
            {singleListData?.embeddedLinks && (
              <Box mt={2}>
                {singleListData.embeddedLinks.split(',').map((link: any, index: any) => {
                  const trimmedLink = link.trim();
                  const extension = trimmedLink.split('.').pop()?.toLowerCase();

                  if (extension === 'jpg' || extension === 'jpeg' || extension === 'png') {
                    return (
                      <Box key={index}>
                        <img src={trimmedLink} alt={`Embedded Image ${index}`} className="LibraryVideoAndImage" />
                      </Box>
                    );
                  } else {
                    return (
                      <Box key={index}>
                        <iframe
                          title={`Embedded Document ${index}`}
                          src={trimmedLink}
                          className="LibaryEmbeddedDocument"
                        />
                      </Box>
                    );
                  }
                })}
              </Box>
            )}
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default PreviewContent;
