import React from 'react';
import Skeleton from '@mui/material/Skeleton';

interface MySkeletonProps {
  variant?: 'circular' | 'rectangular' | 'rounded' | 'text';
  height?: string | number;
  width?: string | number;
  border?: string;
  bg?: string;
  sx?: React.CSSProperties;
  className?: string;
  minHeight?: string | number;
}

const MySkeleton: React.FC<MySkeletonProps> = ({
  variant,
  height,
  width,
  border,
  bg,
  sx,
  className,
  minHeight,
  ...rest
}) => {
  const defaultStyles: React.CSSProperties = {
    cursor: 'default',
    backgroundColor: bg || '#EFF0F4',
    border: border,
    minHeight: minHeight,
  };

  return (
    <Skeleton
      variant={variant}
      height={height}
      width={width}
      className={className}
      sx={{
        ...defaultStyles,
        ...sx,
      }}
      {...rest}
    />
  );
};

export default MySkeleton;
