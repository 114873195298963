import { createSlice } from '@reduxjs/toolkit';

const groupLoaderSlice = createSlice({
  name: 'groupLoader',
  initialState: { loading: false },
  reducers: {
    updateState: (state, action) => {
      state.loading = action.payload;
    }
  }
});

export default groupLoaderSlice.reducer;
export const groupLoaderActions = groupLoaderSlice.actions;
