/* eslint-disable no-param-reassign */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable @typescript-eslint/no-invalid-this */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Grid,
  TextField,
  InputLabel,
  FormControl,
  Autocomplete,
  DialogContent,
  DialogActions,
  FormHelperText
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useEffect, useState } from 'react';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { API } from '../../../api';
import { getUserDetails } from '../../../utils/orgName';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { userActions, userDisabilityUpdate } from '../../../redux/slices/user-profile/userProfieSlice';
import _ from 'lodash';
import { disabilityPopupActions } from '../../../redux/slices/disability/disabilityPopupSlice';
import useProfileDetails from '../../../hooks/useProfileDetails';
import { popupCountActions } from '../../../redux/slices/poupsCountsSlice';
import { companyActions } from '../../../redux/slices/companySlice';

const DisabilityForm = () => {
  const { disability, settings, mentors } = useProfileDetails();
  const { ftue } = useProfileDetails();
  const { open, disable } = useAppSelector((state) => state.disabilityPopup);
  const tasksData = useAppSelector((state) => state.mentorTasks);
  const [data, setData] = useState<string[]>(disability);
  const [disabilityLoader, setDisabilityLoader] = useState(false);
  const dispatch = useAppDispatch();
  const { userId } = getUserDetails();
  const [ftueLoader, setFtueLoader] = useState(false);

  const [error, setError] = useState(false);

  const schema = yup.object({
    disability: yup
      .array()
      .of(yup.string())
      .min(0, 'Please select at least one option')
      .test('is-required', 'Please select disability type', function (value) {
        if (settings.disabilityType) {
          if (value) {
            return true; // the field is valid
          } else {
            // the field is invalid, so return a ValidationError object with an error message
            return this.createError({
              message: 'Please select disability type'
            });
          }
        } else {
          return true; // the field is not required, so it's always valid
        }
      })
  });
  const { handleSubmit, setValue } = useForm({
    resolver: yupResolver(schema)
  });

  const handleClose = () => {
    if (!disable) {
      setError(false);
      dispatch(
        disabilityPopupActions.handleDisabilityPopup({
          open: false,
          disable: false
        })
      );
    }
  };

  useEffect(() => {
    setValue('disability', disability);
    const data = localStorage.getItem('disability');
    const disabilityData = ftue && data ? JSON.parse(data) : disability;
    setData(disabilityData);
  }, [open]);

  const submitForm = async () => {
    localStorage.setItem('disability', JSON.stringify(data));
    const updateCount = () => {
      if (ftue) {
        if (mentors.length > 0) {
          if (tasksData.error) {
            toast.error(tasksData.errorText);
          } else if (tasksData.data.data.length === 0) {
            dispatch(companyActions.update(true));
            updateFtue();
          } else {
            dispatch(popupCountActions.increment(0));
          }
        } else {
          dispatch(companyActions.update(true));

          updateFtue();
        }
      }
    };
    setDisabilityLoader(true);
    const { orgId } = getUserDetails();
    try {
      const response = await API.updateDisability({
        orgId,
        data: data
      });
      if (response.status === 200 && response.statusText === 'OK') {
        setDisabilityLoader(false);
        dispatch(
          disabilityPopupActions.handleDisabilityPopup({
            open: false,
            disable: false
          })
        );
        localStorage.removeItem('disability');
        dispatch(userDisabilityUpdate(response.data));
        if (ftue) {
          updateCount();
        }
        // toast.success('Disability updated successfully');
      } else {
        toast.error('The server timed out. Please try again');
      }
    } catch (e) {
      setDisabilityLoader(false);
      toast.error('Something went wrong, please try again');
    }
  };

  const updateFtue = async () => {
    setFtueLoader(true);
    try {
      const response = await API.updateUserFtue({ ftue: false, userId });
      if (response.status === 200 && response.statusText === 'OK') {
        dispatch(userActions.userFtueUpdate(0));
      }
    } catch (e) {
      toast.error(_.get(e, 'response.data.message', 'Bio failed to update'));
    } finally {
      setFtueLoader(false);
    }
  };

  const onSubmit: SubmitHandler<FieldValues> = async (e) => {
    // e.preventDefault();

    if (settings.disabilityType) {
      if (data.length === 0) {
        setError(true);
      } else {
        setError(false);
        submitForm();
      }
    } else {
      setError(false);
      submitForm();
    }
  };

  useEffect(() => {
    return () => {
      handleClose();
    };
  }, []);

  const names = [
    'None',
    'Prefer not to say',
    'Autism Spectrum Disorder',
    'Deaf-Blindness',
    'Deafness',
    'Emotional Disturbance',
    'Hearing Impairment',
    'Intellectual Disability',
    'Multiple Disabilities',
    'Orthopedic Impairment',
    'Other Health Impairment',
    'Specific Learning Disability',
    'Speech or Language Impairment',
    'Traumatic Brain Injury',
    'Visual Impairment, including Blindness'
  ];

  const handelDisability = (event: any, value: string[]) => {
    const handle = () => {
      if (value?.includes('Prefer not to say')) {
        value = ['Prefer not to say'];
      } else if (value?.includes('None')) {
        value = ['None'];
      }
      setData(value);
    };
    if (settings.disabilityType) {
      if (value.length === 0) {
        setError(true);
      } else {
        setError(false);
      }
    } else {
      setError(false);
    }
    handle();
  };

  // const handelDisability = (event:any, newValue: string[]) => {
  //   setSelectedOption(newValue);
  // }

  return (
    <>
      <DialogContent>
        <Grid item xs>
          <InputLabel>Disability Type {settings.disabilityType && '*'}</InputLabel>
          <FormControl fullWidth>
            <Autocomplete
              multiple
              options={names}
              getOptionLabel={(option) => option}
              value={data}
              id="disabilityType"
              onChange={handelDisability}
              disableCloseOnSelect
              filterOptions={(options, state) =>
                options.filter((option) => option.toLowerCase().startsWith(state.inputValue.toLowerCase()))
              }
              getOptionDisabled={(option) =>
                (data?.includes('None') || data?.includes('Prefer not to say')) && option !== data[0]
              }
              renderInput={(params) => (
                <TextField {...params} variant="outlined" size="small" placeholder="Select Disability" />
              )}
            />
          </FormControl>
          <FormHelperText error sx={{ mt: 1 }}>
            {error && 'Please Select Disability'}
          </FormHelperText>
        </Grid>
      </DialogContent>
      <DialogActions>
        {ftue && (
          <LoadingButton
            variant="outlined"
            color="secondary"
            fullWidth
            disabled={ftueLoader || disabilityLoader}
            onClick={() => dispatch(popupCountActions.decrement(0))}
            // onClick={handleClose()}
          >
            Back
          </LoadingButton>
        )}
        <LoadingButton
          color="primary"
          variant="contained"
          fullWidth
          id="disabilitySubmit"
          // style={
          //   disabilityLoader
          //     ? { borderRadius: '8px', width: '560px', height: '50px' }
          //     : {
          //         fontFamily: 'Open Sans',
          //         textAlign: 'center',
          //         fontSize: '16px',
          //         fontWeight: '700',
          //         color: '#fff',
          //         background: '#152536',
          //         borderRadius: '8px',
          //         width: '560px',
          //         height: '50px'
          //       }
          // }
          loading={disabilityLoader || ftueLoader}
          onClick={ftueLoader || disabilityLoader ? () => {} : handleSubmit(onSubmit)}
          // onClick={handleClose()}
        >
          {ftue ? (mentors.length > 0 && tasksData.data.data.length > 0 ? 'Next' : 'Finish') : 'Save'}
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default DisabilityForm;
