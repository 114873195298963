import React from 'react';
import {
  Grid,
  InputLabel,
  FormHelperText
} from '@mui/material';
import moment from 'moment';
import MyTooltip from './MyTooltip';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { Control, Controller, FieldValues } from 'react-hook-form';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

interface BirthdayFieldProps {
  control: Control<FieldValues>;
  optionalFields: any;
  getError: (name: string) => any;
}

const BirthdayField: React.FC<BirthdayFieldProps> = ({ control, optionalFields, getError }) => {
  return (
    <Grid item xs={12} sm={6}>
      <InputLabel>
        Birthday {optionalFields.dob && '*'}
        <MyTooltip title="We capture your birthday so we can mark the occasion with you" placement="right" />
      </InputLabel>
      <Controller
        name="dob"
        control={control}
        render={({ field: { onChange, value } }) => {
          const selectedDate = value ? moment(value, 'MM-DD').toDate() : null;
          const formats = { monthAndYear: 'MMMM' };
          
          return (
            <LocalizationProvider dateAdapter={AdapterDateFns} dateFormats={formats}>
              <DesktopDatePicker
                format="MM/dd"
                views={['month', 'day']}
                value={selectedDate}
                onChange={(date, value) => {
                  const modifiedDate = date ? moment(date).set('year', 2001).toDate() : null;
                  const selectedValue = date ? moment(modifiedDate).format('MM-DD') : '';
                  onChange(modifiedDate, selectedValue);
                }}
                slotProps={{
                  actionBar: { actions: ['clear'] },
                  desktopPaper: { className: 'selectedSectionsClass' }
                }}
              />
              <FormHelperText error>{getError('dob')?.toString()}</FormHelperText>
            </LocalizationProvider>
          );
        }}
      />
    </Grid>
  );
};

export default BirthdayField;
