import { useQuery } from "@tanstack/react-query";
import { API } from "../api";
import { queryKeys } from "./queryKeys";
import { useAppSelector } from "../redux/hooks";
// import { QueryKey } from "@tanstack/react-query";

const useUserDetailsById = () =>{
const userId = useAppSelector(state => state?.userId?.data);

    // console.log("userId during api call",userId);

    // const {data, isLoading, refetch, error, isFetching} = useQuery({
    const data = useQuery({

        queryKey: [queryKeys.GET_USER_DETAILS_BY_ID, userId],
        queryFn: ()=>{
            if(userId){
                return API.getUserDetailsById(userId);
            }
            else{
                return null;
            }
            
        },
        enabled:false
    })

    // return {
    //     data: data?.data,
    //     isLoading,
    //     refetch,
    //     error,
    //     isFetching
    // }
    return data;
}
export default useUserDetailsById;


















