import { makeStyles } from '@mui/styles';

export const LiveChatStyle = makeStyles({
  MainBox: {
    borderRadius: '8px',
    '@media (min-width: 600px)': {
      width: '80%',
    },
    '@media (min-width: 1200px)': {
        width: '60%',
    },
    marginLeft: 'auto',
    marginRight: 'auto',
    backgroundColor: '#FFFFFF',
    border: '1px solid #EFF0F4',
  },
  Container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  LiveChatBody: {
    overflowY: 'auto',
    flexGrow: 1,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '20px'
  },
  msgBox: {
    gap: '8px',
    display: 'flex',
    marginBottom: '20px'
  },
  btn: {
    padding: '12px 10px'
  },
  ThankYouBody: {
    flexGrow: 1,
    height: '100vh',
    width: '70%',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center'
  },
  AskDosen: {
    background: '#DF6438 !important',
    height: '48px',
    boxShadow: 'none',
    borderRadius: '14px !important',
    '&:hover': {
      background: '#DF6438 !important'
    }
  },
  ChipMainBox: {
    overflowY: 'hidden',
    overflowX: 'auto'
  },
  TrendingChip: {
    fontSize: '14px',
    fontWeight: '600',
  }
});