import jwt_decode from 'jwt-decode';
import _ from 'lodash';
import moment from 'moment';

export const checkAzureSession = (azureToken: any) => {
  const acsToken = localStorage.getItem('acsToken');
  const decodedData: any = acsToken ? jwt_decode(acsToken) : '';
  const { exp } = decodedData;
  const currentTime = moment();
  const expiryTime = moment.unix(exp);
  return currentTime.isAfter(expiryTime);
};
