/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Divider,
  IconButton,
  Typography
} from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { disabilityPopupActions } from '../../../redux/slices/disability/disabilityPopupSlice';
import useProfileDetails from '../../../hooks/useProfileDetails';
import { EditIcon } from '../../../utils/Icons';

const Disability = () => {
  const { disability } = useProfileDetails();
  const dispatch = useAppDispatch();

  return (
    <>
      <Box
        px={2}
        display='flex'
        alignItems='center'
        justifyContent='space-between'
      >
        <Typography variant='h5' py={2}>Disability</Typography>
        <IconButton
          size='small'
          onClick={() =>
            dispatch(
              disabilityPopupActions.handleDisabilityPopup({
                open: true,
                disbale: false
              })
            )
          }
        >
          <EditIcon />
        </IconButton>
      </Box>
      <Divider />
      <Box p={2}>
        {disability && disability?.length > 0 ? (
        disability?.length === 1 ? (
          <Typography variant='subtitle2'>
            {disability[0]}
          </Typography>
        ) : (
          <Box>
            {disability?.map((each, index) => (
              <Typography variant='subtitle2'>
                {each}
              </Typography>
            ))}
          </Box>
          )
        ) : (
          <Typography>No Disabilities</Typography>
        )}
      </Box>
    </>
  );
};

export default Disability;
