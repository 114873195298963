import React from "react";
import { Box, Typography } from "@mui/material";
import ChatAvatarComponent from "../chat/ChatAvatarComponent";

interface Avatar {
  avatar?: string;
  firstName?: string;
}

const AvatarRow: React.FC<{ avatars: Avatar[]; loading?: boolean }> = ({ avatars, loading = false }) => {
  const MAX_AVATARS_DISPLAYED = loading ? 6 : 5;
  const displayedAvatars = avatars?.slice(0, MAX_AVATARS_DISPLAYED);
  const extraAvatarsCount = avatars?.length - MAX_AVATARS_DISPLAYED;
  const imageSize = '30px';
  const border = '2px solid #FFFFFF';

  return (
    <Box display={'flex'} alignItems='center' justifyContent={'center'} ml='10px'>
      {/* Printing Requerd member images */}
      {displayedAvatars?.map((avatar, index) => (
        <Box position={'relative'} ml={'-8px'}>
          <ChatAvatarComponent
            firstLetter={String(avatars[index]?.firstName?.slice(0, 1) || '')}
            width={imageSize}
            height={imageSize}
            type="noStatus"
            image={avatars[index]?.avatar}
            fontSize="12px"
            border={border}
            loading={loading}
          />
        </Box>
      ))}

      {/* Printing Extra Avatar */}
      {extraAvatarsCount > 0 && (
        <Box
          ml={'-8px'}
          width={'30px'}
          height={'30px'}
          border={border}
          display={'flex'}
          bgcolor={loading ? '#EFF0F4' : '#ABB5BE'}
          borderRadius={'8px'}
          position={'relative'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          {!loading &&
            <Typography fontSize={'10px'} fontWeight={'600'} color={'#FFFFFF'} lineHeight={'normal'}>
              +{extraAvatarsCount > 999 ? `${(extraAvatarsCount / 1000).toFixed(1)}k` : extraAvatarsCount}
            </Typography>
          }
        </Box>        
      )}
    </Box>
  );
};

export default AvatarRow;
