import React from 'react';
import { Box, Popover, Typography } from '@mui/material';
import { ExcelFileIcon, ExportPlanIcon, DownloadPdfIcon } from '../../../../../../utils/Icons';

interface ExportPopoverProps {
  open: boolean;
  anchorEl: HTMLElement | null;
  onClose: () => void;
  handleDownloadPdf?: (event: React.MouseEvent<HTMLDivElement>) => void;
  handleExportExcel?: any;
  handleExportPlan?: (event: React.MouseEvent<HTMLDivElement>) => void;
  handleMemberDevelopment?: any;
}

const ExportPopover: React.FC<ExportPopoverProps> = ({
  open,
  anchorEl,
  onClose,
  handleDownloadPdf,
  handleExportExcel,
  handleExportPlan,
  handleMemberDevelopment
}) => {
  return (
    <Popover
      id="export-popover"
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      sx={{ top: '10px' }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
    >
      {handleDownloadPdf && (
        <Box className="PopoverListStyle" onClick={handleDownloadPdf}>
          <DownloadPdfIcon />
          <Typography color="primary">Download PDF</Typography>
        </Box>
      )}
      {handleExportExcel && (
        <Box className="PopoverListStyle" onClick={() => handleExportExcel(false)}>
          <Box width={'20px'}>
            {/* <ExcelFileIcon width="18px" height="24px" /> */}
            <DownloadPdfIcon />
          </Box>
          <Typography color="primary">Member Data</Typography>
        </Box>
      )}
      {handleMemberDevelopment && (
        <Box className="PopoverListStyle" onClick={() => handleMemberDevelopment(true)}>
          <Box width={'20px'}>
            <DownloadPdfIcon />
          </Box>
          <Typography color="primary">Member Development Plan</Typography>
        </Box>
      )}
      {handleExportPlan && (
        <Box className="PopoverListStyle" onClick={handleExportPlan}>
          <ExportPlanIcon />
          <Typography color="primary">Export Plan Q&A</Typography>
        </Box>
      )}
    </Popover>
  );
};

export default ExportPopover;
